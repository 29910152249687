import classNames from 'classnames';
import { useField } from 'formik';
import React from 'react';
import {
    Form,
    FormControlProps,
    FormSelectProps,
    FormCheckProps,
    InputGroup,
} from 'react-bootstrap';
import PhoneInput, { Props as PhoneInputProps } from 'react-phone-number-input';
import {
    CountryDropdown,
    CountryDropdownProps,
    RegionDropdown,
    RegionDropdownProps,
} from 'react-country-region-selector';
import { getCurrencySign, removeNumberFormatting } from './HelperFunctions';

interface BootstrapFormLabelProps {
    className?: string;
    htmlFor: string | undefined;
    children: React.ReactNode;
}
export const BootstrapFormLabel = ({ children, className, ...props }: BootstrapFormLabelProps) => {
    return (
        <Form.Label
            className={classNames(
                'fw-bold text-uppercase text-secondary small',
                className !== undefined ? className : ''
            )}
            {...props}
        >
            {children}
        </Form.Label>
    );
};

export interface BootstrapInputProps extends FormControlProps {
    label?: string;
    inputGroupText?: string | JSX.Element;
    inputGroupSize?: 'sm' | 'lg';
    inputGroupPosition?: 'prefix' | 'suffix';
    required?: boolean;
    displayError?: boolean;
    min?: string | number | undefined;
    max?: string | number | undefined;
    step?: string | number | undefined;
    rows?: string | undefined; //only applicable for text area
    validText?: string;
}

export const BootstrapInput = ({
    label,
    inputGroupText,
    inputGroupSize,
    inputGroupPosition = 'suffix',
    required = true,
    displayError = true,
    validText = 'Looks good!',
    ...props
}: BootstrapInputProps) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input>. We can use field meta to show an error
    // message if the field is invalid and it has been touched (i.e. visited)
    const [field, meta] = useField(props.id as string);

    const formControl = (
        <Form.Control isInvalid={meta.touched && !!meta.error} {...field} {...props} />
    );

    const inputGroupContainer = !!inputGroupText && (
        <InputGroup size={inputGroupSize}>
            {inputGroupPosition === 'prefix' && <InputGroup.Text>{inputGroupText}</InputGroup.Text>}
            {formControl}
            {inputGroupPosition === 'suffix' && <InputGroup.Text>{inputGroupText}</InputGroup.Text>}
        </InputGroup>
    );

    return (
        <>
            {label && (
                <BootstrapFormLabel htmlFor={props.id}>
                    {label} {required && <span className="text-danger">*</span>}
                </BootstrapFormLabel>
            )}

            {inputGroupContainer || formControl}

            {meta.touched && meta.error === undefined && props.isValid && (
                <Form.Control.Feedback type="valid">{validText}</Form.Control.Feedback>
            )}

            {meta.touched && meta.error !== undefined && displayError && (
                <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
            )}
        </>
    );
};

export const BootstrapCheckbox = ({ children, label, ...props }: FormCheckProps) => {
    // React treats radios and checkbox inputs differently other input types, select, and textarea.
    // Formik does this too! When you specify `type` to useField(), it will
    // return the correct bag of props for you -- a `checked` prop will be included
    // in `field` alongside `name`, `value`, `onChange`, and `onBlur`
    const [field, meta] = useField({ name: String(props.id), type: 'checkbox' });
    return (
        <>
            <Form.Check
                type="checkbox"
                isInvalid={meta.touched && !!meta.error}
                feedback={meta.error}
            >
                <Form.Check.Input {...field} {...props} type="checkbox" className="me-2" />
                <Form.Check.Label htmlFor={props.id}>{label}</Form.Check.Label>
            </Form.Check>
        </>
    );
};

interface BootstrapSwitchProps extends FormCheckProps {}

export const BootstrapSwitch = ({ children, ...props }: BootstrapSwitchProps) => {
    // React treats radios and checkbox inputs differently other input types, select, and textarea.
    // Formik does this too! When you specify `type` to useField(), it will
    // return the correct bag of props for you -- a `checked` prop will be included
    // in `field` alongside `name`, `value`, `onChange`, and `onBlur`
    const [field, meta] = useField({ name: String(props.id), type: 'checkbox' });
    return (
        <>
            <Form.Check
                type="switch"
                isInvalid={meta.touched && !!meta.error}
                feedback={meta.error}
                {...field}
                {...props}
            />
        </>
    );
};

interface BootstrapRadioProps extends Omit<FormCheckProps, 'id'> {
    label?: string;
    values: number[] | string[];
    labels: string[];
    required?: boolean;
}

export const BootstrapRadio = ({
    label,
    values,
    labels,
    required = true,
    ...props
}: BootstrapRadioProps) => {
    return (
        <>
            {label && (
                <BootstrapFormLabel htmlFor={props.name}>
                    {label} {required && <span className="text-danger">*</span>}
                </BootstrapFormLabel>
            )}
            <div>
                {values.map((value, index) => (
                    <BootstrapRadioItem
                        key={value}
                        id={`${props.name}-${value}`} // id is needed to allow selection using radio label
                        value={value}
                        label={labels[index]}
                        {...props}
                    />
                ))}
            </div>
        </>
    );
};
const BootstrapRadioItem = (props: FormCheckProps) => {
    // React treats radios and checkbox inputs differently other input types, select, and textarea.
    // Formik does this too! When you specify `type` to useField(), it will
    // return the correct bag of props for you -- a `checked` prop will be included
    // in `field` alongside `name`, `value`, `onChange`, and `onBlur`
    const [field, meta] = useField({ name: String(props.name) });

    return (
        <Form.Check
            type="radio"
            isInvalid={meta.touched && !!meta.error}
            feedback={meta.error}
            {...field}
            {...props}
            checked={String(field.value) === String(props.value)}
        />
    );
};

interface BootstrapSelectProps extends FormSelectProps {
    label?: string;
    allowedKeys: string[];
    allowedLabels: string[];
    placeholderOption?: boolean | string;
    required?: boolean;
    // not included in FormSelectProps:
    disabled?: boolean;
    onChange?: (e: React.ChangeEvent<any>) => void;
    value?: string | number;
    multiple?: boolean;
}

export const BootstrapSelect = ({
    label,
    allowedKeys,
    allowedLabels,
    placeholderOption = true,
    required = true,
    ...props
}: BootstrapSelectProps) => {
    const [field, meta] = useField(props.id as string);
    return (
        <>
            {label && (
                <BootstrapFormLabel htmlFor={props.id}>
                    {label} {required && <span className="text-danger">*</span>}
                </BootstrapFormLabel>
            )}
            <Form.Select isInvalid={meta.touched && !!meta.error} {...field} {...props}>
                {placeholderOption && (
                    <option value="">{placeholderOption === true ? '-' : placeholderOption}</option>
                )}
                {allowedKeys.map((key, index) => (
                    <option key={index} value={key}>
                        {allowedLabels[index]}
                    </option>
                ))}
            </Form.Select>
            {meta.touched && meta.error ? (
                <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
            ) : null}
        </>
    );
};

interface BootstrapInputPhoneProps extends PhoneInputProps<FormControlProps> {
    id?: string;
    label?: string;
    required?: boolean;
}

export const BootstrapInputPhone = ({
    id,
    label,
    required = true,
    ...props
}: BootstrapInputPhoneProps) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input>. We can use field meta to show an error
    // message if the field is invalid and it has been touched (i.e. visited)
    const [field, meta] = useField(id as string);
    return (
        <>
            {label && (
                <BootstrapFormLabel htmlFor={id}>
                    {label} {required && <span className="text-danger">*</span>}
                </BootstrapFormLabel>
            )}
            <InputGroup hasValidation>
                <PhoneInput
                    className="w-100"
                    inputComponent={Form.Control as any}
                    numberInputProps={{
                        className:
                            meta.touched && !!meta.error ? 'form-control is-invalid' : undefined,
                    }}
                    {...field}
                    {...props}
                />
            </InputGroup>
            {meta.touched && meta.error ? (
                <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
            ) : null}
        </>
    );
};

interface BootstrapInputPriceProps extends FormControlProps {
    label?: string;
    inputGroupText?: string;
    inputGroupSize?: 'sm' | 'lg';
    inputGroupPosition?: 'prefix' | 'suffix';
    required?: boolean;
}

export const BootstrapInputPrice = ({
    label,
    inputGroupText,
    inputGroupSize,
    inputGroupPosition = 'suffix',
    required = true,
    ...props
}: BootstrapInputPriceProps) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input>. We can use field meta to show an error
    // message if the field is invalid and it has been touched (i.e. visited)
    const [field, meta, helpers] = useField(props.id as string);

    const formControl = (
        <Form.Control
            isInvalid={meta.touched && !!meta.error}
            {...field}
            onChange={(e) => {
                const [stringNum, , decimalSeparator] = removeNumberFormatting(e.target.value);

                const num = Number(stringNum);

                // if empty, set empty (?)
                if (!stringNum) {
                    helpers.setValue('');
                }
                // if number, set value
                else if (!isNaN(num)) {
                    helpers.setValue(
                        num.toLocaleString() +
                            (stringNum.endsWith(decimalSeparator) ? decimalSeparator : '')
                    );
                }
            }}
            {...props}
        />
    );

    const inputGroupContainer = !!inputGroupText && (
        <InputGroup size={inputGroupSize}>
            {inputGroupPosition === 'prefix' && (
                <InputGroup.Text>{getCurrencySign(inputGroupText)}</InputGroup.Text>
            )}
            {formControl}
            {inputGroupPosition === 'suffix' && (
                <InputGroup.Text>{getCurrencySign(inputGroupText)}</InputGroup.Text>
            )}
        </InputGroup>
    );

    return (
        <>
            {label && (
                <BootstrapFormLabel htmlFor={props.id}>
                    {label} {required && <span className="text-danger">*</span>}
                </BootstrapFormLabel>
            )}

            {inputGroupContainer || formControl}

            {meta.touched && meta.error !== undefined && (
                <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
            )}
        </>
    );
};

interface BootstrapSelectCountryProps extends Omit<CountryDropdownProps, 'value' | 'onChange'> {
    id?: string;
    label?: string;
    required?: boolean;
}

export const BootstrapSelectCountry = ({
    id,
    label,
    required = true,
    ...props
}: BootstrapSelectCountryProps) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input>. We can use field meta to show an error
    // message if the field is invalid and it has been touched (i.e. visited)
    const [field, meta, helpers] = useField(id as string);
    return (
        <>
            {label && (
                <BootstrapFormLabel htmlFor={id}>
                    {label} {required && <span className="text-danger">*</span>}
                </BootstrapFormLabel>
            )}
            <InputGroup hasValidation>
                <CountryDropdown
                    defaultOptionLabel="-"
                    valueType="short"
                    classes={classNames('w-100 form-select', {
                        'form-control is-invalid': meta.touched && !!meta.error,
                    })}
                    {...field}
                    onChange={(val) => {
                        helpers.setValue(val);
                    }}
                    {...props}
                />
            </InputGroup>
            {meta.touched && meta.error ? (
                <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
            ) : null}
        </>
    );
};

interface BootstrapSelectRegionProps extends Omit<RegionDropdownProps, 'value' | 'onChange'> {
    id?: string;
    label?: string;
    required?: boolean;
}

export const BootstrapSelectRegion = ({
    id,
    label,
    required = true,
    ...props
}: BootstrapSelectRegionProps) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input>. We can use field meta to show an error
    // message if the field is invalid and it has been touched (i.e. visited)
    const [field, meta] = useField(id as string);
    return (
        <>
            {label && (
                <BootstrapFormLabel htmlFor={id}>
                    {label} {required && <span className="text-danger">*</span>}
                </BootstrapFormLabel>
            )}
            <InputGroup hasValidation>
                <RegionDropdown
                    blankOptionLabel="-"
                    countryValueType="short"
                    valueType="short"
                    classes={classNames('w-100 form-select', {
                        'form-control is-invalid': meta.touched && !!meta.error,
                    })}
                    {...field}
                    {...props}
                />
            </InputGroup>
            {meta.touched && meta.error ? (
                <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
            ) : null}
        </>
    );
};
