import classNames from 'classnames';
import { useState } from 'react';
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Butlerr } from '../../../types/butlerr';
import { createPartnerRoute, PartnerRoutes } from '../Work';
import socialStyles from '../../social/social.module.css';

interface PartnerAvatarProps extends React.HTMLAttributes<HTMLImageElement> {
    width: number;
    height?: number;

    clickable?: boolean;
    partner: Pick<Butlerr.Work.WorkProfile, 'wkpf_profile'> &
        Partial<Pick<Butlerr.Work.WorkProfile, 'wkpf_id' | 'wkpf_name'>>;

    /**
     * If `src` is passed in props, it will always take precedence, unless there's an error
     */
    src?: string;
}
export default function PartnerAvatar({
    width,
    height,
    clickable = false,
    partner,
    style,
    className,
    src: srcFromProps,
    ...props
}: PartnerAvatarProps) {
    const [isError, setIsError] = useState(false);

    let ele: JSX.Element;
    if (isError || (partner.wkpf_profile === null && srcFromProps === undefined)) {
        ele = (
            <div
                {...props}
                className={classNames(
                    className,
                    clickable && socialStyles.userAvatar,
                    'rounded-circle fw-bold d-flex align-items-center justify-content-center text-light'
                )}
                style={{
                    ...style,
                    width,
                    height,
                    fontSize: width / 2,
                    backgroundColor: '#00a3f0',
                    filter: `hue-rotate(${((partner.wkpf_id ?? 0) % 10) * 45}deg)`,
                    userSelect: 'none',
                }}
            >
                {partner.wkpf_name?.charAt(0).toUpperCase() || 'W'}
            </div>
        );
    } else {
        ele = (
            <Image
                width={width}
                height={height}
                onError={() => setIsError(true)}
                className={classNames(
                    className,
                    clickable && socialStyles.userAvatar,
                    'rounded-circle'
                )}
                src={srcFromProps ?? partner.wkpf_profile!}
                style={{
                    objectFit: 'cover',
                    ...style,
                }}
                {...props}
            />
        );
    }

    if (!clickable || !partner.wkpf_id) return ele;

    return (
        <Link
            to={createPartnerRoute(PartnerRoutes.WORK_PROFILE, { wkpfId: partner.wkpf_id })}
            className="text-decoration-none"
        >
            {ele}
        </Link>
    );
}
