import { Card, Col, Container, Dropdown, Form, Modal, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { MoreVert } from '@mui/icons-material';
import classNames from 'classnames';
import { Formik, FormikHelpers } from 'formik';
import * as yup from 'yup';

import { Butlerr } from '../../../types/butlerr';
import { usePartnerMutations, useWorkProfileStaff } from '../../../services/partner.service';
import { useButlerrUser, useUserDetails } from '../../../services/user.service';
import styles from '../../assets/asset.module.css';
import UserAvatar from '../../social/UserAvatar';
import LineClampText from '../../utils/LineClampText';
import IconButton from '../../utils/IconButton';
import { createSocialRoute, SocialRoutes } from '../../social/SocialHome';
import { BaseModalProps } from '../../../hooks/useModal';
import { formCloseHandler } from '../../utils/HelperFunctions';
import { BootstrapSelect } from '../../utils/FormikBootstrapInputs';
import { Transition } from 'react-transition-group';
import { TeamBadge } from '../workProfileTeam/WorkProfileTeam';
import BootstrapModalFooter from '../../utils/BootstrapModalFooter';

interface PartnerMembersInterface {
    workProfile: Butlerr.Work.WorkProfile;
    staff: Butlerr.Work.WorkProfileStaff;

    dropdownItems?: React.ReactNode;
}

const PartnerMembers = ({ workProfile, staff, dropdownItems }: PartnerMembersInterface) => {
    return <CardItem workProfile={workProfile} staff={staff} dropdownItems={dropdownItems} />;
};

interface CardItemInterface {
    workProfile: Butlerr.Work.WorkProfile;
    staff: Butlerr.Work.WorkProfileStaff;

    dropdownItems?: React.ReactNode;
}

export const CardItem = ({ workProfile, staff, dropdownItems }: CardItemInterface) => {
   
    const { data: dbUser } = useButlerrUser();

    return (
        <>
            <Card
                className={classNames('border-1 flex-grow-1 flex-shrink-0', styles.convoMsgCard, dbUser?.user_id !== staff.user_id && 'shadow-sm')}
                style={{
                    boxShadow: dbUser?.user_id === staff.user_id ? '0px 0px 5px 1px var(--primary-light)' : undefined
                }}
            >
                <Card.Body
                    className="p-2 d-flex justify-content-start align-items-center flex-row"
                    as={Row}
                >
                    <Col className="align-items-center" xs={3}>
                        <Link
                            to={createSocialRoute(SocialRoutes.USER_PROFILE, {
                                id: staff.user_id,
                            })}
                            className={classNames(styles.messageUsername, styles.linkUnderline)}
                            title={staff.user_socialhandle}
                        >
                            <UserAvatar
                                user={{
                                    user_id: staff.user_id,
                                    user_socialhandle: staff.user_socialhandle,
                                    user_profile: staff.user_profile,
                                }}
                                width={50}
                                height={50}
                                className={
                                    'cursor-pointer shadow me-3 ' +
                                    styles.assetsListCardImage +
                                    'h-auto'
                                }
                            />
                        </Link>
                    </Col>
                    <Col className="align-self-start" xs={7}>
                        <div style={{ position: 'absolute', right: 10, top: 10 }}>
                            {dropdownItems !== undefined && (
                                <Dropdown className={styles.dropdown} style={{ opacity: 1 }}>
                                    <Dropdown.Toggle as="span" bsPrefix=" ">
                                        <IconButton
                                            transparent
                                            border={false}
                                            Icon={MoreVert}
                                            iconHtmlColor="var(--bs-gray)"
                                        />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="p-0">
                                        { dropdownItems }
                                    </Dropdown.Menu>
                                </Dropdown> // current staff is admin
                            )}
                        </div>
                        <LineClampText maxLines={1} className="text-left mb-0 small fw-bold">
                            {dbUser?.user_id !== staff.user_id //if the currrent user is not me vs is me
                                ? staff.user_socialhandle
                                : 'This is you'}
                        </LineClampText>
                        <LineClampText
                            maxLines={1}
                            title={staff.wpst_position ?? 'Position'}
                            className={'mb-0 small text-secondary'}
                        >
                            {staff.wpst_position ? staff.wpst_position : 'No position'}
                        </LineClampText>
                        <LineClampText
                            maxLines={1}
                            title={staff.wpst_depart ?? 'Department'}
                            className={'mb-0 small text-secondary'}
                        >
                            {staff.wpst_depart ? staff.wpst_depart : 'No department'}
                        </LineClampText>
                        {
                            staff.wpst_admin === 1 && (
                                <div style={{ position: 'absolute', right: 5, bottom: 5 }}>
                                    <TeamBadge role="Admin" />
                                </div>
                            )
                        }
                    </Col>
                </Card.Body>
            </Card>
        </>
    );
};

type DeleteStaffForm = {
    user_id: number;
};

export const DeleteStaffModal = ({
    show,
    onClose,
    workProfile,
    staff,
}: BaseModalProps & {
    workProfile: Butlerr.Work.WorkProfile;
    staff: Butlerr.Work.WorkProfileStaff;
}) => {
    const { data: dbUser } = useButlerrUser();
    const { data: dbWorkProfileOwner } = useUserDetails(workProfile.wkpf_owner);

    const { data: staffs } = useWorkProfileStaff(workProfile.wkpf_id, show);

    // filter staff to be removed
    // and current staff
    const filteredStaffs = staffs
        ?.filter((s) => s.user_id !== staff.user_id && s.user_id !== dbUser?.user_id && s.wpst_status === 1) ?? []

    const { mutate: deleteStaff, isLoading, error } = usePartnerMutations('DELETE_STAFF');

    let allowedKeys = {
        user_id: [String(dbUser?.user_id ?? -1), ...filteredStaffs.map((s) => String(s.user_id))],
    };

    let allowedValues = {
        user_id: ['Myself', ...filteredStaffs.map((s) => s.user_socialhandle)],
    };

    // If current user is not work profile owner,
    // add the work profile owner.
    if (dbUser?.user_id !== dbWorkProfileOwner?.user_id) {
        // If the admin is removing himself,
        // remove his name from the list.
        if (dbUser?.user_id === staff.user_id) {
            allowedKeys.user_id = [
                String(dbWorkProfileOwner?.user_id ?? -1),
                ...filteredStaffs.map((s) => String(s.user_id)),
            ];
            allowedValues.user_id = [
                String(
                    dbWorkProfileOwner?.user_socialhandle
                        ? dbWorkProfileOwner.user_socialhandle + ' (owner)'
                        : ''
                ),
                ...filteredStaffs.map((s) => s.user_socialhandle),
            ];
        } else {
            allowedKeys.user_id = [
                String(dbUser?.user_id ?? -1),
                String(dbWorkProfileOwner?.user_id ?? -1),
                ...filteredStaffs.map((s) => String(s.user_id)),
            ];
            allowedValues.user_id = [
                'Myself',
                String(
                    dbWorkProfileOwner?.user_socialhandle
                        ? dbWorkProfileOwner.user_socialhandle + ' (owner)'
                        : ''
                ),
                ...filteredStaffs.map((s) => s.user_socialhandle),
            ];
        }
    }

    const initialValues = {
        user_id: -1,
    };

    const schema = yup.object().shape({
        user_id: yup.number().positive('Please select a staff').required('Required'),
    });

    const onSubmit = (
        { user_id }: DeleteStaffForm,
        { resetForm }: FormikHelpers<DeleteStaffForm>
    ) => {
        deleteStaff(
            {
                wkpf_id: staff.wkpf_id,
                user_id: staff.user_id,
                newStaffId: user_id,
                newStaffProfile: staff.user_profile ?? '',
                newStaffHandle: staff.user_socialhandle
            },
            {
                onSuccess: () => {
                    handleClose();
                    resetForm();
                },
                onError: (error) => {
                    alert(error.message);
                },
            }
        );
    };

    const handleClose = () => {
        if (error !== null) error.message = '';
        if (!isLoading) onClose();
    };

    return (
        <Transition in={show} timeout={{ exit: 300 }} unmountOnExit>
            {() => (
                <Formik initialValues={initialValues} validationSchema={schema} onSubmit={onSubmit}>
                    {({ dirty, submitForm }) => {
                        return (
                            <Form noValidate>
                                <Modal
                                    centered
                                    show={show}
                                    onHide={() => formCloseHandler(handleClose, dirty)}
                                    backdrop={isLoading ? 'static' : undefined}
                                    keyboard={!isLoading}
                                >
                                    <Modal.Header>
                                        <Modal.Title>Remove staff</Modal.Title>
                                    </Modal.Header>

                                    <Modal.Body>
                                        <Container>
                                            <div className="mb-3">
                                                Before removing{' '}
                                                <strong>{staff.user_socialhandle}</strong> as a
                                                staff, please reassign the existing assets roles,
                                                reminders and conversations to the selected staff
                                                below:
                                            </div>
                                            <Row className="mb-3 g-3">
                                                <Form.Group as={Col} lg>
                                                    <BootstrapSelect
                                                        id="user_id"
                                                        label="Select a staff"
                                                        disabled={isLoading}
                                                        allowedKeys={allowedKeys.user_id}
                                                        allowedLabels={allowedValues.user_id}
                                                    />
                                                </Form.Group>
                                            </Row>
                                        </Container>
                                    </Modal.Body>

                                    <BootstrapModalFooter
                                        error={error}
                                        cancelBtnProps={{
                                            onClick: () => formCloseHandler(handleClose, dirty),
                                            disabled: isLoading,
                                        }}
                                        confirmBtnProps={{
                                            label: isLoading ? 'Loading...' : 'Confirm',
                                            onClick: () => submitForm(),
                                            disabled: isLoading,
                                        }}
                                    />

                                </Modal>
                            </Form>
                        );
                    }}
                </Formik>
            )}
        </Transition>
    );
};

export default PartnerMembers;
