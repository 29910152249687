import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { Butlerr } from '../types/butlerr';
import { DocumentQueries } from './doc.service';
import useButlerrAPI, { ButlerrMutationOptions, useButlerrMutation } from './useButlerrAPI';

export const chatBaseRoute = '/api/social/chats'

export const SocialChatQueries = {
    CHATS: () => ['user', 'chats'] as const,
    PARTICIPANTS: ['chats', 'participants'] as const,
    MESSAGES: (chatId: number) =>
        ['chat', chatId, 'messages'] as const,
    NOTIFICATIONCHATS: ['notification', 'chats'] as const,
};

export const notificationBaseRoute = '/api/notification';

export function useSocialChats(refresh = true) {
    return useButlerrAPI<Butlerr.Social.Chats[]>(
        SocialChatQueries.CHATS(),
        `${chatBaseRoute}`,
        true,
        {
            staleTime: 0, // Data is always outdated
            refetchInterval: refresh ? 1000 * 30 : undefined, // Refetch every 30 seconds
            refetchOnMount: refresh ? 'always' : undefined, // Refetch whenever user comes to the page
        }
    );
}

export const convoBaseRoute = '/api/conversations';

export function useUnreadChats() {
    return useButlerrAPI<Butlerr.Social.Chats[]>(
        SocialChatQueries.NOTIFICATIONCHATS,
        `${convoBaseRoute}`
    );
}

export function useChatMsgs(chatId: number) {
    return useButlerrAPI<Butlerr.Asset.ConversationMessage[]>(
        SocialChatQueries.MESSAGES(chatId),
        `${chatBaseRoute}/${chatId}/messages`,
        true,
        {
            staleTime: 0, // Data is always outdated
            refetchInterval: 1000 * 30, // Refetch every 30 seconds
            refetchOnMount: 'always', // Refetch whenever user comes to the page
        }
    );
}

interface ChatMutationInterface extends Butlerr.Social.Chats {
    chatId: number;
}

const ChatMutations = {
    CREATE: ({
        ...body
    }: Omit<
        ChatMutationInterface,
        | 'chatId'
        | 'ascv_id'
        | 'ascv_datetime'
        | 'latestMessage'
        | 'user_id'
        | 'user_socialhandle'
        | 'user_profile'
        | 'acla_lastAccess'
        | 'acmb_leave'
    >): ButlerrMutationOptions => ({
        url: `${chatBaseRoute}`,
        method: 'POST',
        requestBody: {
            ...body,
            members: body.members.map((m) => m.user_id),
        },
    }),
    UPDATE: ({
        chatId,
        ...body
    }: Pick<
        ChatMutationInterface,
        'chatId' | 'ascv_subject' | 'members'
    >): ButlerrMutationOptions<Butlerr.Social.Chats[]> => ({
        url: `${chatBaseRoute}/${chatId}`,
        method: 'PUT',
        requestBody: {
            ...body,
            members: body.members.map((m) => m.user_id),
        },
        updater: [{
            queryKey: SocialChatQueries.CHATS(),
            optimistic: true,
            action: (prev) => {
                return (
                    prev?.map((c) => {
                        if (c.ascv_id !== chatId) return c;
                        return {
                            ...c,
                            ...body,
                        };
                    }) ?? []
                );
            },
        }, {
            action: 'refetch',
            queryKey: SocialChatQueries.MESSAGES(chatId)
        }],
    }),
    DELETE: ({
        chatId,
    }: Pick<ChatMutationInterface, 'chatId'>): ButlerrMutationOptions<
        Butlerr.Social.Chats[]
    > => ({
        url: `${chatBaseRoute}/${chatId}/`,
        method: 'DELETE',
        updater: {
            action: (prev) => prev?.filter((c) => c.ascv_id !== chatId) ?? [],
            optimistic: true,
        },
    }),
    LEAVE: ({
        chatId,
    }: Pick<ChatMutationInterface, 'chatId'>): ButlerrMutationOptions<
        Butlerr.Social.Chats[]
    > => ({
        url: `${chatBaseRoute}/${chatId}/members`,
        method: 'DELETE',
        updater:[{
            queryKey: SocialChatQueries.CHATS(),
            action: 'refetch',
        }, {
            action: 'refetch',
            queryKey: SocialChatQueries.MESSAGES(chatId)
        }]
    }),
};

export function useSocialChatMutations<Key extends keyof typeof ChatMutations>(
    key: Key
) {
    //You won't get type suggestions in the mutate function without this assertion
    const mutationFn = ChatMutations[key] as (
        params: Parameters<typeof ChatMutations[Key]>[0]
    ) => ButlerrMutationOptions;
    return useButlerrMutation(mutationFn, SocialChatQueries.CHATS());
}

interface MessageMutationInterface extends Omit<Butlerr.Asset.ConversationMessage, 'attachments'> {
    chatId: number;
    messageId: number;
    attachments: File[];
}

const MessageMutations = {
    CREATE: ({
        chatId,
        ...message
    }: Omit<
        MessageMutationInterface,
        | 'messageId'
        | 'acms_id'
        | 'ascv_id'
        | 'acms_fromuser'
        | 'acms_datetime'
        | 'user_id'
        | 'user_socialhandle'
        | 'user_profile'
    >): ButlerrMutationOptions => ({
        url: `${chatBaseRoute}/${chatId}/messages`,
        method: 'POST',
        requestBody: message,
        useFormData: true,
        queryKey: SocialChatQueries.MESSAGES(chatId)
    }),

    DELETE: ({
        chatId,
        messageId,
    }: Pick<
        MessageMutationInterface,
        'chatId' | 'messageId'
    >): ButlerrMutationOptions<Butlerr.Asset.ConversationMessage[]> => ({
        url: `${chatBaseRoute}/${chatId}/messages/${messageId}`,
        method: 'DELETE',
        queryKey: SocialChatQueries.MESSAGES(chatId),
        updater: {
            action: (prev) => prev?.filter((m) => m.acms_id !== messageId) ?? [],
            optimistic: true,
        },
    }),
};

export function useChatMsgMutations<Key extends keyof typeof MessageMutations>(
    key: Key
) {
    //You won't get type suggestions in the mutate function without this assertion
    const mutationFn = MessageMutations[key] as (
        params: Parameters<typeof MessageMutations[Key]>[0]
    ) => ButlerrMutationOptions;
    return useButlerrMutation(
        mutationFn
    );
}

export const useChatMsgAttach = (
    chatId: number,
    messageId: number,
    attachmentId: number,
    type: 'content' | 'thumbnail' = 'content'
) => {
    // auth0
    const { getAccessTokenSilently } = useAuth0();

    // state
    const [url, setUrl] = useState<string | null>();

    useEffect(() => {
        const abort = new AbortController();

        const retrieveImage = async () => {
            try {
                if (chatId <= 0 || messageId <= 0) throw new Error();

                const token = await getAccessTokenSilently();
                const result = await fetch(
                    `/api/social/chats/${chatId}/messages/${messageId}/attachments/${attachmentId}/${type}`,
                    {
                        method: type === 'content' ? 'POST' : 'GET',
                        headers: {
                            authorization: `Bearer ${token}`,
                        },
                        signal: abort.signal,
                    }
                );

                if (!result.ok) throw new Error();

                const blob = await result.blob();
                const url = URL.createObjectURL(new Blob([blob]));
                setUrl(url);
            } catch (error) {
                if (error.name !== 'AbortError') {
                    setUrl(null);
                }
            }
        };
        retrieveImage();

        return () => {
            abort.abort();
        };
    }, [getAccessTokenSilently, chatId, messageId, attachmentId, type]);

    return [url];
};

export const useChatMsgAttachSave = () => {
    return useButlerrMutation(
        ({
            chatId,
            messageId,
            attachmentId,
            ...rest
        }: Pick<MessageMutationInterface, 'chatId' | 'messageId' > & {
            attachmentId: number;
            doct_parentid: number;
        }): ButlerrMutationOptions => ({
            url: `/api/social/chats/${chatId}/messages/${messageId}/attachments/${attachmentId}/save`,
            method: 'POST',
            requestBody: rest,
            refetchData: true,
            queryKey: DocumentQueries.DOCUMENTS(rest.doct_parentid)
        }),
    );
};
