import { Row, Button } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { useProfileCompeletion } from './OnboardingModal';

const FinalWorkPage = () => {
    const { onClose } = useProfileCompeletion();

    const history = useHistory();

    const handleProceed = () => {
        // close onboarding modal
        onClose();

        // redirect to Work (Work Profiles) module and open create work profile modal
        history.replace('/work/profile', { fromOnboardingModal: true });
    };

    return (
        <>
            <Row className="mb-3">
                <h1 className="text-muted">
                    So you are thinking of <span className="text-primary">Work</span>...
                </h1>

                <p>
                    We will now redirect you to our Work module where you can manage your work
                    profiles to <b>create your own company profile</b> to manage channels and staff
                    or <b>request to join a company</b> that is already on our platform.
                </p>

                <p>
                    Note that if the company that you are working for have yet setup a profile here
                    you can also create the profile and then invite the owner to come online before
                    transferring the ownership to him or her.
                </p>
            </Row>

            <div>
                <Button className="me-2" onClick={handleProceed}>
                    Proceed
                </Button>

                <Button variant="outline-primary" onClick={onClose}>
                    Skip to Home
                </Button>
            </div>
        </>
    );
};

export default FinalWorkPage;
