import { Route, Switch, Redirect, useHistory, useParams, useLocation } from 'react-router-dom';
import { Container, Row, Col, Form, Card, CardProps, Modal } from 'react-bootstrap';
import React, { useState } from 'react';
import {
    PriceCheckOutlined,
    DeleteOutlined,
    NotificationsOutlined,
    ForumOutlined,
    InfoOutlined,
    SellOutlined,
    DescriptionOutlined,
} from '@mui/icons-material';
import { Transition } from 'react-transition-group';
import { Formik, FormikHelpers } from 'formik';
import * as yup from 'yup';

import { formatGoogleAddress, useAsset } from '../../services/asset.service';
import BootstrapSpinner from '../utils/BootstrapSpinner';
import ErrorPage from '../utils/ErrorPage';
import { Butlerr } from '../../types/butlerr';
import useAppTitle from '../../hooks/useAppTitle';
import { useAssetMutations } from '../../services/asset.service';
// import styles from './asset.module.css';
import useModal, { BaseModalProps } from '../../hooks/useModal';
import {
    convertDateToYYYYMMDD,
    formCloseHandler,
    removeNumberFormatting,
} from '../utils/HelperFunctions';
import { BootstrapInput, BootstrapInputPrice } from '../utils/FormikBootstrapInputs';
import { AssetDocuments } from '../documents/Docs';
import DestructiveModal from '../utils/DestructiveModal';
import BootstrapModalFooter from '../utils/BootstrapModalFooter';

import Sidebar from '../utils/Sidebar';
import AssetInfo from './info/AssetInfo';
import AssetLeases from './leases/AssetLeases';
import AssetInsurances from './insurances/AssetInsurances';
import AssetLoans from './loans/AssetLoans';
import AssetValuations from './valuations/AssetValuations';
import AssetCashflows from './cashflows/AssetCashflows';
import AssetInsights from './insights/Insights';
import AssetReminders from './reminders/AssetReminders';
import { AssetConvoRoutes } from './conversations/AssetConversations';

// import { ReactComponent as AssetInfoIcon } from '../../assets/icons/asset-info.svg';
import { ReactComponent as AssetLeaseIcon } from '../../assets/icons/asset-lease.svg';
import { ReactComponent as AssetInsuranceIcon } from '../../assets/icons/asset-insurance.svg';
import { ReactComponent as AssetLoanIcon } from '../../assets/icons/asset-loan.svg';
// import { ReactComponent as AssetValuationIcon } from '../../assets/icons/asset-valuation.svg';
import { ReactComponent as AssetCashflowIcon } from '../../assets/icons/asset-cashflow.svg';
import { ReactComponent as AssetInsightsIcon } from '../../assets/icons/asset-insights.svg';

export enum AssetRoutes {
    INFO = '/assets/:assetId/info',
    LEASES = '/assets/:assetId/leases',
    INSURANCES = '/assets/:assetId/insurances',
    LOANS = '/assets/:assetId/loans',
    VALUATIONS = '/assets/:assetId/valuations',
    CASHFLOW = '/assets/:assetId/cashflow',
    INSIGHTS = '/assets/:assetId/insights',
    REMINDERS = '/assets/:assetId/reminders',
    CONVERSATIONS = '/assets/:assetId/conversations',
    DOCUMENTS = '/assets/:assetId/documents',
}

type TArgs = [AssetRoutes] | [AssetRoutes, { [param: string]: string | number }];

export function createAssetRoute(...args: TArgs) {
    const [path, params] = args;

    if (params === undefined) {
        return path;
    }
    //replace all params with the params passed
    return Object.entries(params).reduce((previousValue: string, [param, value]) => {
        return previousValue.replace(`:${param}`, '' + value);
    }, path);
}

interface AssetContentProps {
    assets: Butlerr.Asset[];
}

const AssetContent = ({ assets }: AssetContentProps) => {
    // react router dom
    let { assetId } = useParams<{ assetId: string; assetModuleName: string }>();
    const history = useHistory();
    //const { path } = useRouteMatch(); // returns `/assets/:assetId`
    const { pathname } = useLocation();

    // assets dropdown onchange
    const handleAssetChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newPathname = `/${pathname.split('/')[1]}/${e.target.value}/${
            pathname.split('/')[3]
        }`;
        history.push(newPathname);
    };

    // asset
    const { data: asset, isLoading, isError, error } = useAsset(Number(assetId));

    //checking if asset is under the partnership of current user
    let isPartner = asset?.isPartner === 1;
    let isCoOwner = asset?.isCoowner === 1;

    // check if asset status is `Off Plan` or `Flipped`
    const disableSubmodules = asset?.status === 'OP' || asset?.status === 'FL';

    // set app title
    useAppTitle(
        asset ? formatGoogleAddress(asset).firstLine : isError ? `Error ${error?.status}` : ''
    );

    // sell asset modal
    const [sellAssetModal, openSellAssetModal] = useModal(SellAssetModal, {
        asset,
    });

    // delete asset prompt
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const { mutate: deleteAsset } = useAssetMutations('DELETE');
    const handleDelete = () => {
        setShowDeleteModal(true);
    };
    const onDeleteClose = () => {
        setShowDeleteModal(false);
    };
    const onDeleteConfirm = () => {
        if (asset?.asst_id !== undefined) {
            deleteAsset(asset.asst_id, {
                onSuccess: () => {
                    history.replace('/assets');
                },
            });
        }
    };

    if (isLoading) {
        return <BootstrapSpinner />;
    }

    if (isError || !asset) {
        return <ErrorPage status={error?.status} message={error?.message} />;
    }

    const tabs = [
        [
            InfoOutlined,
            'Asset Info',
            { to: { pathname: createAssetRoute(AssetRoutes.INFO, { assetId }) } },
            AssetRoutes.INFO,
        ],
        [
            AssetLeaseIcon,
            'Leases',
            { to: createAssetRoute(AssetRoutes.LEASES, { assetId }) },
            AssetRoutes.LEASES,
        ],
        [
            AssetInsuranceIcon,
            'Insurances',
            { to: createAssetRoute(AssetRoutes.INSURANCES, { assetId }) },
            AssetRoutes.INSURANCES,
        ],
        [
            AssetLoanIcon,
            'Loans',
            { to: createAssetRoute(AssetRoutes.LOANS, { assetId }) },
            AssetRoutes.LOANS,
        ],
        [
            PriceCheckOutlined,
            'Valuations',
            { to: createAssetRoute(AssetRoutes.VALUATIONS, { assetId }) },
            AssetRoutes.VALUATIONS,
        ],
        [
            AssetCashflowIcon,
            'Cashflow',
            {
                to: createAssetRoute(AssetRoutes.CASHFLOW, {
                    assetId,
                }) /*className: styles.disabledLink*/,
            },
            AssetRoutes.CASHFLOW,
        ],
        [
            AssetInsightsIcon,
            'Insights',
            {
                to: createAssetRoute(AssetRoutes.INSIGHTS, {
                    assetId,
                }),
            },
            AssetRoutes.INSIGHTS,
        ],
        [
            NotificationsOutlined,
            'Reminders',
            { to: { pathname: createAssetRoute(AssetRoutes.REMINDERS, { assetId }) } },
            AssetRoutes.REMINDERS,
        ],
        [
            ForumOutlined,
            'Conversations',
            {
                to: {
                    pathname: createAssetRoute(AssetRoutes.CONVERSATIONS, {
                        assetId,
                    }),
                },
            },
            AssetRoutes.CONVERSATIONS,
        ],
        [
            DescriptionOutlined,
            'Documents',
            {
                to: {
                    pathname: createAssetRoute(AssetRoutes.DOCUMENTS, { assetId }),
                },
            },
            AssetRoutes.DOCUMENTS,
        ],
        'separator',
        [
            SellOutlined,
            'Sell asset',
            {
                active: false,
                onClick: () => openSellAssetModal(),
                className: 'text-danger text-uppercase',
            },
            'SELL_KEY',
        ],
        [
            DeleteOutlined,
            'Delete asset',
            {
                active: false,
                onClick: () => handleDelete(),
                className: 'text-danger text-uppercase',
            },
            'DELETE_KEY',
        ],
    ] as const;

    type TTabs = Partial<Record<AssetRoutes | 'separator' | 'SELL_KEY' | 'DELETE_KEY', boolean>>;

    const hiddenTabs: TTabs = {
        [AssetRoutes.INFO]: false,
        [AssetRoutes.LEASES]: isPartner, // hide if partner
        [AssetRoutes.INSURANCES]: isPartner, // hide if partner
        [AssetRoutes.LOANS]: isPartner, // hide if partner
        [AssetRoutes.VALUATIONS]: isPartner, // hide if partner
        [AssetRoutes.CASHFLOW]: isPartner, // hide if partner
        [AssetRoutes.INSIGHTS]: isPartner, // hide if partner
        [AssetRoutes.REMINDERS]: false,
        [AssetRoutes.CONVERSATIONS]: false,
        [AssetRoutes.DOCUMENTS]: isPartner, // hide if partner
        separator: isPartner || isCoOwner, // hide if partner or coowner
        SELL_KEY: true, // isPartner || isCoOwner, // hide if partner or coowner
        DELETE_KEY: isPartner || isCoOwner, // hide if partner or coowner
    };
    const greyedoutTabs: TTabs = {
        [AssetRoutes.LEASES]: disableSubmodules, // grey out if off plan or flipped
        [AssetRoutes.INSURANCES]: disableSubmodules, // grey out if off plan or flipped
        [AssetRoutes.LOANS]: disableSubmodules, // grey out if off plan or flipped
        [AssetRoutes.VALUATIONS]: disableSubmodules, // grey out if off plan or flipped
        [AssetRoutes.CASHFLOW]: disableSubmodules, // grey out if off plan or flipped
        [AssetRoutes.INSIGHTS]: disableSubmodules || asset.asst_insight !== 1, // grey out if off plan or flipped
        SELL_KEY: asset.asst_selldate ? true : false, // grey out if sold
    };

    const routes = [
        [AssetRoutes.INFO, AssetInfo],
        [AssetRoutes.LEASES, AssetLeases],
        [AssetRoutes.INSURANCES, AssetInsurances],
        [AssetRoutes.LOANS, AssetLoans],
        [AssetRoutes.VALUATIONS, AssetValuations],
        [AssetRoutes.CASHFLOW, AssetCashflows],
        [AssetRoutes.INSIGHTS, AssetInsights],
        [AssetRoutes.REMINDERS, AssetReminders],
        [AssetRoutes.CONVERSATIONS, AssetConvoRoutes],
        [AssetRoutes.DOCUMENTS, AssetDocuments],
    ] as const;

    return (
        <Container>
            <Row>
                {/* sidebar */}
                <Col xs="12" md="3" xl="2" className="sticky-after-navbar">
                    <div className="py-4">
                        <div className="mb-3">
                            <Form.Select
                                aria-label="Assets"
                                onChange={handleAssetChange}
                                value={asset.asst_id}
                                className="shadow rounded-4"
                            >
                                {assets.map((asset) => (
                                    <option key={asset.asst_id} value={asset.asst_id}>
                                        {asset.asst_name}
                                    </option>
                                ))}
                            </Form.Select>
                        </div>

                        <Sidebar
                            className="my-3"
                            tabs={tabs
                                .filter((t) => {
                                    if (typeof t === 'string') {
                                        return hiddenTabs[t] !== true;
                                    }
                                    return hiddenTabs[t[3]] !== true;
                                })
                                .map((t) => {
                                    if (typeof t === 'string') return t;

                                    const isGreyedOut = greyedoutTabs[t[3]] === true;
                                    const props = {
                                        ...t[2],
                                        style: {
                                            ...(t[2] as any)?.style,
                                            pointerEvents: isGreyedOut ? 'none' : undefined,
                                            opacity: isGreyedOut ? 0.6 : undefined,
                                        },
                                    };
                                    return [t[0], t[1], props];
                                })}
                        />
                    </div>
                </Col>

                {/* content */}
                <Col xs="12" md="9" xl="10">
                    <div className="py-4">
                        <Switch>
                            {routes
                                .filter(([path]) => {
                                    return (
                                        hiddenTabs[path] !== true && greyedoutTabs[path] !== true
                                    );
                                })
                                .map((r) => {
                                    const [path, Component] = r;
                                    return (
                                        <Route key={path} path={path}>
                                            <Component
                                                asset={asset}
                                                isPartner={isPartner}
                                                isCoowner={isCoOwner}
                                            />
                                        </Route>
                                    );
                                })}

                            <Redirect to={{ pathname: AssetRoutes.INFO }} />
                        </Switch>
                    </div>
                </Col>
            </Row>

            {sellAssetModal}

            <DestructiveModal
                show={showDeleteModal}
                onClose={onDeleteClose}
                onConfirm={onDeleteConfirm}
                title="Delete asset?"
                description="All information related to the asset will be deleted permanently."
                textToEnterToConfirm="DELETE"
            />
        </Container>
    );
};

export default AssetContent;

interface AssetTabContentInterface extends CardProps {
    cardBodyClassName?: string;
    style?: React.CSSProperties;
    children: React.ReactNode;
}

export const AssetTabContent = ({
    cardBodyClassName,
    style,
    children,
    ...props
}: AssetTabContentInterface) => {
    return (
        <Card
            style={{ borderRadius: '24px', borderTopLeftRadius: 0, ...style }}
            className="border-0 shadow"
            {...props}
        >
            <Card.Body className={cardBodyClassName}>{children}</Card.Body>
        </Card>
    );
};

type SellAssetForm = Pick<Butlerr.Asset, 'asst_selldate' | 'asst_sellprice'>;

const SellAssetModal = ({
    asset,
    show,
    onClose,
}: BaseModalProps & { asset: Butlerr.Asset | undefined }) => {
    const { mutate: sellAsset, isLoading, error } = useAssetMutations('SELL');

    const initialValues: SellAssetForm = {
        asst_selldate: convertDateToYYYYMMDD(new Date())!,
        asst_sellprice: 0,
    };

    const schema = yup.object().shape({
        asst_selldate: yup
            .date()
            .max(new Date(), 'Sale date cannot be in the future')
            .required('Required'),
        asst_sellprice: yup.string().required('Required'),
    });

    const onSubmit = (
        { asst_sellprice, ...values }: SellAssetForm,
        { setFieldError }: FormikHelpers<SellAssetForm>
    ) => {
        if (asset !== undefined) {
            const [sellPrice] = removeNumberFormatting(String(asst_sellprice));

            if (Number(sellPrice) < 1) {
                return setFieldError('asst_sellprice', 'Sale price must be greater than 0');
            }

            sellAsset(
                {
                    asst_id: asset.asst_id,
                    asst_sellprice: Number(sellPrice),
                    ...values,
                },
                {
                    onSuccess: () => formCloseHandler(handleClose),
                }
            );
        }
    };

    const handleClose = () => {
        if (error !== null) error.message = '';
        if (!isLoading) onClose();
    };

    return (
        <Transition in={show} timeout={{ exit: 300 }} unmountOnExit>
            {() => (
                <Formik initialValues={initialValues} validationSchema={schema} onSubmit={onSubmit}>
                    {({ submitForm, dirty }) => {
                        return (
                            <Form noValidate>
                                <Modal
                                    show={show}
                                    onHide={() => formCloseHandler(handleClose, dirty)}
                                    size="lg"
                                    backdrop={isLoading ? 'static' : undefined}
                                    keyboard={!isLoading}
                                    centered
                                >
                                    <Modal.Header>
                                        <Modal.Title>Sell Asset</Modal.Title>
                                    </Modal.Header>

                                    <Modal.Body>
                                        <Container>
                                            <Row className="mb-3 g-3">
                                                <Form.Group as={Col} lg>
                                                    <BootstrapInput
                                                        type="date"
                                                        id="asst_selldate"
                                                        label="Sale Date"
                                                        disabled={isLoading}
                                                    />
                                                </Form.Group>

                                                <Form.Group as={Col} lg>
                                                    <BootstrapInputPrice
                                                        id="asst_sellprice"
                                                        label="Sale Price"
                                                        disabled={isLoading}
                                                    />
                                                </Form.Group>
                                            </Row>

                                            <Row>
                                                <Col className="text-danger ">
                                                    <h6 className="text-uppercase">Warning:</h6>
                                                    <ul>
                                                        <li>
                                                            You and all roles will not be able to
                                                            edit or add further information to the
                                                            asset.
                                                        </li>
                                                        <li>
                                                            All ongoing leases, insurances, loans
                                                            will be terminated.
                                                        </li>
                                                    </ul>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Modal.Body>

                                    <BootstrapModalFooter
                                        error={error}
                                        cancelBtnProps={{
                                            onClick: () => formCloseHandler(handleClose, dirty),
                                            disabled: isLoading,
                                        }}
                                        confirmBtnProps={{
                                            label: isLoading ? 'Saving...' : 'Save',
                                            onClick: () => submitForm(),
                                            disabled: isLoading,
                                        }}
                                    />
                                </Modal>
                            </Form>
                        );
                    }}
                </Formik>
            )}
        </Transition>
    );
};
