import { AddTwoTone, DeleteOutline, DownloadRounded } from '@mui/icons-material';
import { useMemo } from 'react';
import { Column } from 'react-table';
import useModal from '../../hooks/useModal';
import { useDownloadWithPostToken } from '../../services/useButlerrAPI';
import { Butlerr } from '../../types/butlerr';
import IconButton from '../utils/IconButton';
import LineClampText from '../utils/LineClampText';
import ReactTable from '../utils/ReactTable';
import { DocumentSelectModal } from './DocsModal';

interface AssetDocsTableProps {
    asset: Butlerr.Asset;
    onAdd?: (file: Butlerr.Document.File) => void;
    /**
     * Must be memoized in `useCallback`
     */
    onRemove: (file: Butlerr.Document.File) => void;
    /**
     * If `undefined`, will show loading spinner
     */
    files?: Butlerr.Document.File[];

    initialFolder?: number | null;
}

export function AssetDocsTable({
    asset,
    files,
    onAdd,
    onRemove,
    initialFolder,
}: AssetDocsTableProps) {
    const download = useDownloadWithPostToken();

    // table
    const columns = useMemo<Column<Butlerr.Document.File>[]>(
        () => [
            {
                Header: 'Name',
                accessor: 'doc_name',
                width: 150,
                Cell: ({ value, row }) => (
                    <LineClampText
                        maxLines={1}
                        className="mb-0"
                        children={
                            row.original.doc_format ? `${value}.${row.original.doc_format}` : value
                        }
                    />
                ),
            },
            {
                Header: 'Description',
                accessor: 'doc_desc',
                width: 400,
                Cell: ({ value }) => value ?? '-',
            },
            {
                accessor: 'doc_id',
                disableSortBy: true,
                minWidth: 75,
                width: 75,
                maxWidth: 75,
                Cell: ({ value, row }) => (
                    <div className="d-flex align-items-center">
                        <IconButton
                            Icon={DownloadRounded}
                            title="Download"
                            transparent
                            onClick={() => download('DOCUMENTS', value)}
                            className="me-2"
                        />
                        <IconButton
                            Icon={DeleteOutline}
                            title="Remove"
                            transparent
                            onClick={() => onRemove(row.original)}
                        />
                    </div>
                ),
            },
        ],
        [download, onRemove]
    );
    //Have to memoize `files` as well, because when `files` is undefined, the identity of empty array will change on every render
    const data = useMemo(() => files ?? [], [files]);

    const filesToHideForPicker = useMemo(() => files?.map((f) => f.doc_id), [files]);

    const [picker, showPicker] = useModal(DocumentSelectModal, {
        asset: asset,
        onFileSelect: onAdd,
        className: 'modal-layer-1',
        backdropClassName: 'modal-layer-1',
        filesToHide: filesToHideForPicker,
        initialFolder,
    });

    return (
        <div>
            <div className="mb-2 d-flex align-items-center justify-content-between">
                <span className="fw-bold text-uppercase text-secondary small">Documents</span>
                {onAdd !== undefined && asset.status !== 'AR' && (
                    <IconButton
                        transparent
                        size="sm"
                        Icon={AddTwoTone}
                        title="Link document"
                        onClick={showPicker}
                        style={{ width: '1.2rem', height: '1.2rem' }}
                        iconStyles={{ fontSize: '1rem' }}
                    />
                )}
            </div>
            <ReactTable
                data={data}
                columns={columns}
                isLoading={files === undefined}
                placeholderEle={
                    <span className="text-muted text-center fw-semibold small d-block w-100">
                        No Documents Linked
                    </span>
                }
                pagination={false}
                showRowCount={false}
                flexLayout={true}
            />
            {picker}
        </div>
    );
}
