import { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { NotificationsRounded, NotificationsNoneRounded, MoreVert, MarkEmailReadRounded } from "@mui/icons-material";
import { Dropdown, NavLink } from "react-bootstrap";
import { Butlerr } from '../../types/butlerr';
import { formatDateByTimeDifference } from '../utils/HelperFunctions';
import { useNotificationMutations, useNotifications } from "../../services/notification.service";
import classNames from "classnames";
import styles from "./header.module.css";
import IconButton from "../utils/IconButton";
import DestructiveModal from "../utils/DestructiveModal";

export function NotificationsButton() {

	//control open state ourselves
	const [ isOpen, setIsOpen ] = useState(false);

	const Icon = isOpen ? NotificationsRounded : NotificationsNoneRounded;

    const { data: notifications } = useNotifications();
    
    const { mutate: readAll, isLoading } = useNotificationMutations('READ_ALL');

    //Filter toggle
    const views = ['All', 'Unread'] as const;
    const [ selectedView, setSelectedView ] = useState<typeof views[number]>('All');
    
    const unread = useMemo(() => notifications?.filter(n => n.noti_read === 0), [notifications]);
    
    const displayNotifications = selectedView === 'Unread' ? unread : notifications;

	return (
		<Dropdown
			align="end"
            autoClose="outside"
			className="mx-1"
			show={isOpen}
			onToggle={(val) => setIsOpen(val)}
		>
			<Dropdown.Toggle 
				as={NavLink}
				className="d-flex align-items-center p-2 position-relative"
				//overwrite bootstrap class to remove dropdown arrow
				bsPrefix=" "
                title="Notifications"
			>
				<Icon />
                <span className="d-lg-none ms-1">Notifications</span>
                {
                    unread !== undefined && unread.length !== 0 && (
                        <div className={classNames("rounded-pill text-center bg-danger px-1 text-white", styles.unreadContainer)}>
                            {unread.length}
                        </div>
                    )
                } 
			</Dropdown.Toggle>

			<Dropdown.Menu className="shadow rounded-4 position-fixed" style={{ top: 'var(--navbar-height)', right: '1rem' }}>
                <div
                    className="d-flex flex-column"
                    style={{
                        width: 'min(400px, calc(100vw - 2rem))',
                        maxHeight: 'calc(90vh - var(--navbar-height))'
                    }}
                >
                    <div className="h5 mb-0 px-3 fw-bold d-flex justify-content-between align-items-center">
                        <span className="mt-3 mb-2">Notifications</span>
                        <IconButton
                            transparent
                            border={false}
                            className="text-dark border-dark mt-2"
                            Icon={MarkEmailReadRounded}
                            title="Mark all as read"
                            disabled={isLoading || unread?.length === 0}
                            onClick={() => readAll(undefined)}
                        />
                    </div>
                    <div className="d-flex px-2 pb-2 shadow-sm">
                        {views.map((label, idx) => (
                            <IconButton
                                key={label}
                                label={label}
                                transparent={selectedView !== label}
                                className={classNames("px-3 py-1 rounded-pill me-2 border border-primary", selectedView !== label && "text-primary fw-normal", idx === 0 && "ms-1")}
                                onClick={() => setSelectedView(label)}
                            />
                        ))}
                    </div>
                    <div className="overflow-auto">
                        {
                            displayNotifications === undefined ? <NotificationSkeleton />
                            : displayNotifications.length === 0 ? (
                                <div className="p-3">
                                    <h6 className="text-center">No notifications yet!</h6>
                                </div>
                            ) : (
                                displayNotifications.map((n) => (
                                    <NotificationItem key={n.noti_id} notification={n} closeMenu={() => setIsOpen(false)} />
                                ))
                            )
                        }
                    </div>
                </div>
			</Dropdown.Menu>
		</Dropdown>
	)
}

interface NotificationItemProps {
	notification: Butlerr.Header.Notification;
    closeMenu: () => void;
}

function NotificationItem({ notification, closeMenu } : NotificationItemProps) {

    const { mutate: read, isLoading: rL } = useNotificationMutations('READ');
    const { mutate: unread, isLoading: uL } = useNotificationMutations('UNREAD');
    const { mutate: remove, isLoading: dL } = useNotificationMutations('DELETE');

    const isLoading = rL || uL || dL;

    const isRead = notification.noti_read === 1;

    const handleLinkClick = () => {
        //mark item as read & close menu
        read(notification.noti_id);
        closeMenu();    
    }

    //delete modal
    const [ showDelete, setShowDelete ] = useState(false);

    const content = (
        <>
			<div style={{ paddingRight: '25px' }}>
				<p className={classNames("mb-1", !isRead && "fw-semibold")}>{notification.noti_title}</p>
				<div className="d-flex align-items-center">
                    {
                        !isRead && (
                            <div
                                style={{ width: 10, height: 10 }}
                                className="bg-primary rounded-circle me-2"
                            ></div>
                        )
                    }
					<small className={isRead ? "text-muted" : "text-primary"}>{formatDateByTimeDifference(notification.noti_date)}</small>
				</div>
			</div>

            <Dropdown
                align="end"
                onClick={(e) => {
                    //to stop notification link from opening
                    e.preventDefault();
                    e.stopPropagation();
                }}
                className={styles.menu}
            >
                <Dropdown.Toggle
                    as="span"
                    bsPrefix=" "
                >
                    <IconButton
                        transparent
                        border={false}
                        Icon={MoreVert}
                        iconHtmlColor="var(--bs-gray)"
                    />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item
                        as="button"
                        disabled={isLoading}
                        onClick={() => {
                            if (isRead) unread(notification.noti_id);
                            else read(notification.noti_id);
                        }}
                    >
                        Mark as {isRead ? 'unread' : 'read'}
                    </Dropdown.Item>
                    <Dropdown.Item
                        as="button"
                        disabled={isLoading}
                        className="text-danger"
                        onClick={() => setShowDelete(true)}
                    >
                        Remove notification
                    </Dropdown.Item>
                </Dropdown.Menu>
                
                <DestructiveModal
                    show={showDelete}
                    onClose={() => setShowDelete(false)}
                    onConfirm={() => remove(notification.noti_id)}
                    title="Delete notification?"
                    description="This notification will be removed permanently"
                />
            </Dropdown>
        </>
    );
    const classes = classNames(styles.item, "text-decoration-none text-dark nav-link p-3 pe-0 d-flex align-items-center w-100");

    if (notification.noti_link === null) {
        return (
            <div className={classes}>{content}</div>
        )
    }

	return (
		<Link
            to={notification.noti_link}
            className={classes}
            onClick={handleLinkClick}
        >
            {content}
        </Link>
	)
}

function NotificationSkeleton() {
    return (
        <>
            {
                Array(3).fill(null).map((_, idx) => (
                    <div key={idx} className="p-3 d-flex align-items-center w-100">
                        <div
                            className="skeleton-box rounded-circle flex-shrink-0 me-3"
                            style={{ width: '40px', height: '40px' }}
                        ></div>
                        <div className="w-100 align-self-stretch d-flex flex-column justify-content-between">
                            <div
                                className="skeleton-box"
                                style={{ width: '100%' }}
                            ></div>
                            <div
                                className="skeleton-box"
                                style={{ width: '40%' }}
                            ></div>
                        </div>
                    </div>
                ))
            }
        </>
    )
}