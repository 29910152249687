import { useAuth0 } from '@auth0/auth0-react';
import { Formik, FormikProps } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import * as yup from 'yup';
import { useUserMutations } from '../../../services/user.service';

import { ButlerrCountries } from '../../../types/butlerr-enums';
import UserAvatar from '../../social/UserAvatar';
import { BootstrapInput, BootstrapSelect } from '../../utils/FormikBootstrapInputs';
import { ProfileForm, useProfileCompeletion } from './OnboardingModal';

const ProfilePage = () => {
    const { profileForm, setProfileForm, goPrevPage, goNextPage, isLoading } =
        useProfileCompeletion();

    // auth0
    const { user } = useAuth0();

    // profile pic
    const inputRef = useRef<HTMLInputElement>(null);
    const [image, setImage] = useState<{ file: File; url: string }>();
    const [isReadingImage, setIsReadingImage] = useState(false);
    const [imageReadError, setImageReadError] = useState<string>();

    const { mutate: checkSocialHandleAvailability, isLoading: isCheckLoading } = useUserMutations(
        'CHECK_SOCIALHANDLE_AVAILABILITY'
    );

    const handleImageSelect = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const file = ev.target.files?.[0];

        if (!file) {
            return setImageReadError("Couldn't read image");
        }
        if (!file.type.startsWith('image')) {
            return setImageReadError('Invalid image file');
        }

        setIsReadingImage(true);

        readImage(file);

        /**
         * Return false in event handler, don't change the value of underlying input
         */
        return false;
    };

    const readImage = (file: File) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            setIsReadingImage(false);

            if (e.target?.result) {
                setImage({
                    file,
                    url: e.target.result as string,
                });
            }
        };
        reader.onerror = (err) => {
            console.error(err);
            setIsReadingImage(false);
        };
        reader.readAsDataURL(file);
    };

    const allowedKeys = {
        user_country: Object.keys(ButlerrCountries),
    };

    const allowedLabels = {
        user_country: Object.values(ButlerrCountries),
    };

    const initialValues: Pick<
        ProfileForm,
        'user_firstname' | 'user_lastname' | 'user_socialhandle' | 'user_country' | 'user_state'
    > = {
        user_firstname: profileForm.user_firstname,
        user_lastname: profileForm.user_lastname,
        user_socialhandle: profileForm.user_socialhandle,
        user_country: profileForm.user_country,
        user_state: profileForm.user_state,
    };

    const validationSchema = yup.object().shape({
        user_firstname: yup
            .string()
            .max(20, ({ max }) => `Maximum ${max} characters`)
            .required('Required'),
        user_lastname: yup
            .string()
            .max(20, ({ max }) => `Maximum ${max} characters`)
            .required('Required'),
        user_socialhandle: yup
            .string()
            .min(5, ({ min }) => `Minimum ${min} characters`)
            .max(30, ({ max }) => `Maximum ${max} characters`)
            .required('Required'),
        user_country: yup.string().oneOf(allowedKeys.user_country).required('Required'),
        user_state: yup.string().max(45, ({ max }) => `Maximum ${max} characters`),
    });

    const onSubmit = (
        values: Pick<
            ProfileForm,
            'user_firstname' | 'user_lastname' | 'user_socialhandle' | 'user_country' | 'user_state'
        >
    ) => {
        setProfileForm((prev) => ({
            ...prev,
            ...values,
            user_profile: image?.file ?? null,
        }));

        goNextPage();
    };

    const formikRef =
        useRef<
            FormikProps<
                Pick<
                    ProfileForm,
                    | 'user_firstname'
                    | 'user_lastname'
                    | 'user_socialhandle'
                    | 'user_country'
                    | 'user_state'
                >
            >
        >(null);

    useEffect(() => {
        // restore profiile pic preview if saved
        if (profileForm.user_profile !== null) {
            readImage(profileForm.user_profile);
        }

        // check social handle availability
        if (profileForm.user_socialhandle) {
            if (
                profileForm.user_socialhandle.length < 5 ||
                profileForm.user_socialhandle.length > 30
            ) {
                formikRef.current?.setFieldTouched('user_socialhandle', true);
            } else {
                checkSocialHandleAvailability(
                    { user_socialhandle: profileForm.user_socialhandle },
                    {
                        onError: (error) => {
                            formikRef.current?.setFieldTouched('user_socialhandle', true, false);
                            formikRef.current?.setFieldError('user_socialhandle', error.message);
                        },
                    }
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Row>
                <h1 className="text-muted">
                    Setup Your <span className="text-primary">Profile</span>
                </h1>

                <p>
                    To start using <b>propcircle.co</b>, please tell us a bit about yourself:
                </p>
            </Row>

            <Formik
                innerRef={formikRef}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({ touched, errors, values, handleBlur, handleSubmit, setFieldError }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Row>
                            <Col md="3">
                                <div className="d-flex flex-column align-items-center">
                                    {user?.picture === undefined ? (
                                        <div
                                            className="skeleton-box rounded-circle"
                                            style={{ width: 100, height: 100 }}
                                        ></div>
                                    ) : (
                                        <UserAvatar
                                            roundedCircle
                                            src={image?.url ?? user.picture}
                                            width="100"
                                            height="100"
                                            style={{ pointerEvents: 'none' }}
                                        />
                                    )}

                                    <div className="mt-3 d-flex justify-content-center align-items-center">
                                        <button
                                            type="button"
                                            className="small link-btn"
                                            title="Upload"
                                            onClick={() => inputRef.current?.click()}
                                            disabled={isReadingImage || isLoading}
                                        >
                                            Upload
                                        </button>
                                        {image?.url !== undefined && (
                                            <button
                                                type="button"
                                                className="ms-2 small link-btn"
                                                title="Remove"
                                                onClick={() => {
                                                    if (inputRef.current !== null) {
                                                        inputRef.current.value = '';
                                                        setImage(undefined);
                                                    }
                                                }}
                                                disabled={image?.url === undefined || isLoading}
                                            >
                                                Remove
                                            </button>
                                        )}
                                    </div>

                                    <input
                                        hidden
                                        ref={inputRef}
                                        type="file"
                                        accept="image/*"
                                        onChange={handleImageSelect}
                                    />
                                </div>
                            </Col>
                            <Col>
                                <Row className="mb-3 g-3">
                                    <Form.Group as={Col} lg>
                                        <BootstrapInput
                                            id="user_firstname"
                                            label="First Name"
                                            placeholder="First Name"
                                            aria-label="First Name"
                                            disabled={isLoading}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} lg>
                                        <BootstrapInput
                                            id="user_lastname"
                                            label="Last Name"
                                            placeholder="Last Name"
                                            aria-label="Last Name"
                                            disabled={isLoading}
                                        />
                                    </Form.Group>
                                </Row>

                                <Row className="mb-3 g-3">
                                    <Form.Group as={Col} lg="7">
                                        <BootstrapInput
                                            id="user_socialhandle"
                                            label="Your Preferred Display Name"
                                            placeholder="Display Name"
                                            aria-label="Your Preferred Display Name"
                                            disabled={isCheckLoading || isLoading}
                                            displayError={false}
                                            onBlur={(e) => {
                                                // default blur handler from Formik
                                                handleBlur(e);

                                                const user_socialhandle = e.target.value;

                                                if (
                                                    !user_socialhandle ||
                                                    user_socialhandle.length < 5 ||
                                                    user_socialhandle.length > 30
                                                )
                                                    return;

                                                checkSocialHandleAvailability(
                                                    { user_socialhandle },
                                                    {
                                                        onError: (error) => {
                                                            setFieldError(
                                                                'user_socialhandle',
                                                                error.message
                                                            );
                                                        },
                                                    }
                                                );
                                            }}
                                        />
                                    </Form.Group>
                                    {touched.user_socialhandle &&
                                        errors.user_socialhandle !== undefined && (
                                            <Col>
                                                <div className="d-flex justify-content-start align-items-end h-100">
                                                    <span
                                                        style={{
                                                            fontSize: '0.81rem',
                                                            color: '#dc3545',
                                                        }}
                                                    >
                                                        {errors.user_socialhandle}
                                                    </span>
                                                </div>
                                            </Col>
                                        )}
                                </Row>

                                <Row className="mb-3 g-3">
                                    <Form.Group as={Col} lg>
                                        <BootstrapSelect
                                            id="user_country"
                                            label="Country"
                                            allowedKeys={allowedKeys.user_country}
                                            allowedLabels={allowedLabels.user_country}
                                            disabled={isLoading}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} lg>
                                        <BootstrapInput
                                            id="user_state"
                                            label="Region/State"
                                            placeholder="Region/State"
                                            aria-label="Region/State"
                                            disabled={isLoading}
                                            required={false}
                                        />
                                    </Form.Group>
                                </Row>

                                <Button
                                    variant="outline-primary"
                                    className="me-2"
                                    disabled={isLoading}
                                    onClick={() => {
                                        // save current page data
                                        setProfileForm((prev) => ({
                                            ...prev,
                                            ...values,
                                            user_profile: image?.file ?? null,
                                        }));

                                        goPrevPage();
                                    }}
                                >
                                    Back
                                </Button>

                                <Button type="submit" disabled={isLoading}>
                                    Next
                                </Button>

                                {imageReadError !== undefined && (
                                    <p className="w-100 text-danger">{imageReadError}</p>
                                )}
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default ProfilePage;
