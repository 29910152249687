import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Formik, FormikHelpers } from 'formik';
import * as yup from 'yup';

import styles from '../asset.module.css';
import { Butlerr } from '../../../types/butlerr';
import { AssetInfoEditButton, AssetInfoProps } from './AssetInfo';
import { BootstrapInput } from '../../utils/FormikBootstrapInputs';
import { initialiseAssetObject, useAssetMutations } from '../../../services/asset.service';
import RouterPrompt from '../../utils/RouterPrompt';
import { SectionHeader } from '../../utils/SectionHeader';

import { ReactComponent as Bed } from '../../../assets/icons/config-bed.svg';
import { ReactComponent as Bath } from '../../../assets/icons/config-bath.svg';
import { ReactComponent as Car } from '../../../assets/icons/config-car.svg';
import { ReactComponent as Living } from '../../../assets/icons/config-living.svg';
import { ReactComponent as Dining } from '../../../assets/icons/config-dining.svg';
import { ReactComponent as Kitchen } from '../../../assets/icons/config-kitchen.svg';
import { ReactComponent as Store } from '../../../assets/icons/config-store.svg';
import { ReactComponent as Study } from '../../../assets/icons/config-study.svg';
import { ReactComponent as Powder } from '../../../assets/icons/config-powder.svg';
import { ReactComponent as Ensuite } from '../../../assets/icons/config-ensuite.svg';

interface FormValues {
    asst_nobed?: number | null;
    asst_nobath?: number | null;
    asst_nopowder?: number | null;
    asst_nokitchen?: number | null;
    asst_nostudy?: number | null;
    asst_nostore?: number | null;
    asst_nodining?: number | null;
    asst_noliving?: number | null;
    asst_nocarspace?: number | null;
    asst_noensuite?: number | null;
}

export type AssetConfigurationIconsInterface = [
    number | null | undefined,
    React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & {
            title?: string | undefined;
        }
    >,
    string,
    string
];

const AssetInfoConfiguration = ({ asset, isPartner }: AssetInfoProps) => {
    const { mutate: editAsset } = useAssetMutations('EDIT');

    // form state
    const [editing, setEditing] = useState(false);

    const assetConfigurationIcons: AssetConfigurationIconsInterface[] = [
        [asset.asst_nobed, Bed, 'asst_nobed', 'Bedrooms'],
        [asset.asst_nobath, Bath, 'asst_nobath', 'Bathrooms'],
        [asset.asst_nocarspace, Car, 'asst_nocarspace', 'Car Spaces'],
        [asset.asst_noliving, Living, 'asst_noliving', 'Living Rooms'],
        [asset.asst_nodining, Dining, 'asst_nodining', 'Dining Rooms'],
        [asset.asst_nokitchen, Kitchen, 'asst_nokitchen', 'Kitchen'],
        [asset.asst_nostore, Store, 'asst_nostore', 'Store Rooms'],
        [asset.asst_nostudy, Study, 'asst_nostudy', 'Study Rooms'],
        [asset.asst_nopowder, Powder, 'asst_nopowder', 'Powder Rooms'],
        [asset.asst_noensuite, Ensuite, 'asst_noensuite', 'Ensuites'],
    ];

    // Formik props
    const initialiseInitialValues = (asset: Butlerr.Asset) => {
        return {
            asst_nobed: asset.asst_nobed ?? 0,
            asst_nobath: asset.asst_nobath ?? 0,
            asst_nopowder: asset.asst_nopowder ?? 0,
            asst_nokitchen: asset.asst_nokitchen ?? 0,
            asst_nostudy: asset.asst_nostudy ?? 0,
            asst_nostore: asset.asst_nostore ?? 0,
            asst_nodining: asset.asst_nodining ?? 0,
            asst_noliving: asset.asst_noliving ?? 0,
            asst_nocarspace: asset.asst_nocarspace ?? 0,
            asst_noensuite: asset.asst_noensuite ?? 0,
        };
    };

    let initialValues: FormValues = initialiseInitialValues(asset);

    const validationSchema = yup.object({
        asst_nobed: yup.number().integer().min(0, 'Minimum 0').nullable(),
        asst_nobath: yup.number().integer().min(0, 'Minimum 0').nullable(),
        asst_nopowder: yup.number().integer().min(0, 'Minimum 0').nullable(),
        asst_nokitchen: yup.number().integer().min(0, 'Minimum 0').nullable(),
        asst_nostudy: yup.number().integer().min(0, 'Minimum 0').nullable(),
        asst_nostore: yup.number().integer().min(0, 'Minimum 0').nullable(),
        asst_nodining: yup.number().integer().min(0, 'Minimum 0').nullable(),
        asst_noliving: yup.number().integer().min(0, 'Minimum 0').nullable(),
        asst_nocarspace: yup.number().integer().min(0, 'Minimum 0').nullable(),
        asst_noensuite: yup.number().integer().min(0, 'Minimum 0').nullable(),
    });

    const closeForm = (actions: FormikHelpers<FormValues>) => {
        setEditing(false);
        actions.resetForm({ values: initialValues });
    };

    const onSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) => {
        // check if any field updated
        let fieldUpdated: boolean = false;
        Object.keys(initialValues).forEach((key) => {
            let value = values[key as keyof typeof values];
            if (initialValues[key as keyof typeof initialValues] !== value) {
                fieldUpdated = true;
            }
        });

        if (fieldUpdated) {
            editAsset(
                {
                    ...initialiseAssetObject(asset),
                    asst_nobed: values.asst_nobed ? values.asst_nobed : 0,
                    asst_nobath: values.asst_nobath ? values.asst_nobath : 0,
                    asst_nopowder: values.asst_nopowder ? values.asst_nopowder : 0,
                    asst_nokitchen: values.asst_nokitchen ? values.asst_nokitchen : 0,
                    asst_nostudy: values.asst_nostudy ? values.asst_nostudy : 0,
                    asst_nostore: values.asst_nostore ? values.asst_nostore : 0,
                    asst_nodining: values.asst_nodining ? values.asst_nodining : 0,
                    asst_noliving: values.asst_noliving ? values.asst_noliving : 0,
                    asst_nocarspace: values.asst_nocarspace ? values.asst_nocarspace : 0,
                    asst_noensuite: values.asst_noensuite ? values.asst_noensuite : 0,
                },
                {
                    onSuccess: (_, updatedAsset) => {
                        initialValues = initialiseInitialValues(updatedAsset);
                        closeForm(actions);
                    },
                    onError: (error) => {
                        closeForm(actions);
                        alert(error.message);
                    },
                }
            );
        } else {
            closeForm(actions);
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({ handleSubmit, isSubmitting, resetForm, dirty }) => {
                return (
                    <Form noValidate onSubmit={handleSubmit} className="mb-4">
                        <RouterPrompt isBlocking={dirty} />

                        <SectionHeader
                            title="Configuration"
                            rightSide={
                                isPartner === false && asset.status !== 'AR' ? (
                                    <AssetInfoEditButton
                                        editing={editing}
                                        isSubmitting={isSubmitting}
                                        setEditing={setEditing}
                                        resetForm={resetForm}
                                    />
                                ) : undefined
                            }
                        />
                        {assetConfigurationIcons.reduce((prev, curr) => {
                            if (curr[0]) {
                                prev.push(curr);
                            }
                            return prev;
                        }, [] as AssetConfigurationIconsInterface[]).length !== 0 || editing ? (
                            <Row
                                className="d-inline-flex flex-row align-items-center g-2 g-lg-3"
                                xs="2"
                                md="3"
                                lg="4"
                                xl="5"
                            >
                                {assetConfigurationIcons.map(
                                    ([field, FieldIcon, id, label], index) => (
                                        <React.Fragment key={index}>
                                            {editing ? (
                                                <Col className={styles.assetIconWrapper}>
                                                    <FieldIcon
                                                        color="#A1A8B2"
                                                        className={styles.assetIcon}
                                                        title={label}
                                                    />{' '}
                                                    <AdjustedWidth>
                                                        <BootstrapInput
                                                            id={id}
                                                            type="number"
                                                            placeholder={label}
                                                            aria-label={label}
                                                            disabled={isSubmitting}
                                                            size="sm"
                                                            min="0"
                                                        />
                                                    </AdjustedWidth>
                                                </Col>
                                            ) : (
                                                <>
                                                    {field ? (
                                                        <div
                                                            style={
                                                                editing
                                                                    ? undefined
                                                                    : {
                                                                          width: 'fit-content',
                                                                      }
                                                            }
                                                            className={styles.assetIconWrapper}
                                                        >
                                                            <FieldIcon
                                                                color="#A1A8B2"
                                                                className={styles.assetIcon}
                                                                title={label}
                                                            />{' '}
                                                            {field}
                                                        </div>
                                                    ) : undefined}
                                                </>
                                            )}
                                        </React.Fragment>
                                    )
                                )}
                            </Row>
                        ) : (
                            <div>No configuration available.</div>
                        )}
                    </Form>
                );
            }}
        </Formik>
    );
};

const AdjustedWidth = ({ children }: { children: React.ReactNode }) => {
    return <div style={{ width: '40%' }}>{children}</div>;
};

export default AssetInfoConfiguration;
