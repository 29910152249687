import { useLatestValuation } from '../../../services/assetvaluation.service';
import { Butlerr } from '../../../types/butlerr';
import { formatCurrency, formatDate } from '../../utils/HelperFunctions';

interface AssetInfoValuationInterface {
    asset: Butlerr.Asset;
}

const AssetInfoValuation = ({ asset }: AssetInfoValuationInterface) => {
    const { data: valuation, isLoading, error } = useLatestValuation(asset.asst_id);

    if (error) {
        return <span className="text-danger">{error.message}</span>;
    }

    return (
        <div style={{ height: '3.5rem' }}>
            {isLoading || valuation === undefined ? (
                <>
                    <div className="skeleton-box p-3" style={{ width: '15%' }}></div>
                    <br />
                    <div className="skeleton-box w-25 p-1"></div>
                </>
            ) : (
                <>
                    {/* show purchase price if latest valuation unavailable */}
                    <h1 className="mb-0">
                        {Object.keys(valuation).length === 0 ? (
                            <>
                                {formatCurrency(asset.asst_purprice, {
                                    currency: asset.asst_currency,
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                })}
                            </>
                        ) : (
                            <>
                                {formatCurrency(valuation.valu_value, {
                                    currency: asset.asst_currency,
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                })}
                            </>
                        )}
                    </h1>
                    <span className="text-muted">
                        {Object.keys(valuation).length === 0 ? (
                            <>
                                purchase price as of <b>{formatDate(asset.asst_contractdate)}</b>
                            </>
                        ) : (
                            <>
                                valuation price as of <b>{formatDate(valuation.valu_date)}</b>
                            </>
                        )}
                    </span>
                </>
            )}
        </div>
    );
};

export default AssetInfoValuation;
