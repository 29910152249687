import { Card, Placeholder, Row, Col, RowProps, Dropdown, Form, InputGroup, FormControl } from 'react-bootstrap';
import {
    AddBusiness,
    Calculate,
    ForumOutlined,
    NotificationsOutlined,
    ContactSupport,
    FileOpen,
    HandymanRounded,
    House,
    ManageAccounts,
    MonetizationOn,
    PersonSearch,
    Policy,
    Psychology,
    SupportAgent,
    GroupsOutlined,
    PersonOutline
} from '@mui/icons-material';
import { useHistory } from 'react-router-dom';

import { Butlerr } from '../../../types/butlerr';
import ButlerrImage, { ImageRoutes } from "../../ui/ButlerrImage";
import styles from '../../assets/asset.module.css';
import { AssetRoleTypeKey, ButlerrAssetRoleType } from '../../../types/butlerr-enums';
import LineClampText from '../../utils/LineClampText';
import EmptyMessage from '../../utils/EmptyMessage';
import IconButton from '../../utils/IconButton';
import { useEffect, useMemo, useState } from 'react';
import { Pagination, SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { useAssetRoleMutations } from '../../../services/assetrole.service';
import DestructiveModal from '../../utils/DestructiveModal';
import UserAvatar from '../../social/UserAvatar';
import { FetchStaffAssetsOptions, useStaffAssets } from '../../../services/partner.service'
import ToggleButtonGroup, { ToggleItem } from '../../ui/ToggleButtonGroup';
import icbstyles from "../../ui/ui.module.scss";
import classNames from 'classnames';
import useQuery from '../../../hooks/useQuery';
import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";
import BootstrapSpinner from '../../utils/BootstrapSpinner';
import partnerStyles from "../partner.module.css"

interface assetRoleIconsInterface {
    [index: string]: (OverridableComponent<SvgIconTypeMap<{}, "svg">> & { muiName: string; });
}

const assetRoleIcons = {
    DV: Psychology,
    BD: HandymanRounded,
    CV: FileOpen,
    FI: MonetizationOn,
    PM: ManageAccounts,
    TA: Calculate,
    AG: SupportAgent,
    IA: ContactSupport,
    VL: AddBusiness,
    SV: Policy,
    IS: PersonSearch,
    MA: House,
} as assetRoleIconsInterface;

interface AssetsListInterface {
    title?: string;
    assets: Butlerr.ClientAsset[];
    rowProps?: RowProps;
    remiClick: (assetId: number) => void
    convoClick: (assetId: number) => void
    wkpfId: number
    isAdmin: boolean
}

type PageKeys = 'o' | 's'; //Owner | Staff

export type SearchTypeKeys = keyof typeof searchTypeItem; // All | Client | Address | Staff
type KeysWithType<T, V> = { [K in keyof T]-?: T[K] extends V ? K : never }[keyof T];

enum searchTypeItem {
    a = 'All',
    c = 'Client',
    ad = 'Address',
    s = 'Staff'
}

const FILTER_MAP: Record<SearchTypeKeys, KeysWithType<Required<FetchStaffAssetsOptions>, boolean | null>> = {
    a: 'All',
    c: 'Client',
    ad: 'Address',
    s: 'Staff'
} as const;

const AssetsList = ({ title, assets, rowProps, remiClick, convoClick, wkpfId, isAdmin }: AssetsListInterface) => {
    const history = useHistory();
    const search = useQuery();

    const [searchType, setSearchType] = useState<SearchTypeKeys>('a')

    const params = useMemo(() => {
        const params: FetchStaffAssetsOptions = {};

        const query = decodeURIComponent(search.get("q") || "");

        if (query) params.searchVal = query;

        const filterKey = FILTER_MAP[decodeURIComponent(search.get("f") || "All") as SearchTypeKeys];
        //if null, no filter (all posts)
        if (filterKey !== null) {
            //set matching filter key to true;
            //if undefined, default to show `explore` but only if not searching
            if (search.get("f") !== null) {
                setSearchType((search.get("f")) as SearchTypeKeys)
            }
            if (filterKey !== undefined || !query) {
                params[filterKey ?? 'All'] = true;
            }
        }
        return params;
    }, [search])

    const { data: staffAssets, fetchNextPage, refetch, isLoading: staffAssetsLoading } = useStaffAssets(wkpfId ?? -1, params)

    // Staff or Self
    type Item = Omit<ToggleItem, 'active' | 'render'>

    const filterItems: Record<PageKeys, Item> = {
        o: {
            Icon: PersonOutline,
            label: "My managed Assets"
        },
        s: {
            Icon: GroupsOutlined,
            label: "Staff managed Assets"
        }
    }

    const [filterType, setFilterType] = useState<PageKeys>('o') //false = self true = staff

    const handleFilterChange2 = (filter: PageKeys) => {
        //when switching filters, set to show all on default
        setFilterType(filter)
        if (filter === 's') {
            search.set('f', searchType);
            history.replace({
                search: search.toString()
            })
        } else {
            history.replace({
                search: ''
            })
        }
    }

    const handleFilterChange = (filter: PageKeys) => {
        //when switching filters, set to show all on default
        setFilterType(filter)
        handleSearchTypeChange('a');
        if (filter === 's') {
            search.set('f', searchType);
            history.replace({
                search: search.toString()
            })
        } else {
            history.replace({
                search: ''
            })
        }
    }

    const handleSearchTypeChange = (searchType: SearchTypeKeys) => {
        setSearchType(searchType)
    }

    const handleChange = (event: any, value: number) => {
        setPage(value)
    }

    const [page, setPage] = useState(1);

    useEffect(() => {
        if (!staffAssets?.pages[page - 1]) {
            fetchNextPage({ pageParam: page })
            window.scrollTo({ top: 0, behavior: "smooth" })
        }
    }, [staffAssets, page, fetchNextPage])

    useEffect(() => {
        if ((params.All || params.Client || params.Address || params.Staff || params.searchVal) && search.get('f') !== null) {
            handleFilterChange2('s')
            setChangingValue(search.get('q') ?? '')
            refetch();
        } else {
            handleFilterChange('o')
        }
        // eslint-disable-next-line
    }, [params.All, params.Client, params.Address, params.Staff, params.searchVal, refetch, filterType])

    const filteredStaffAssets = staffAssets?.pages[page - 1]?.result;

    // end Staff or Self

    //Search

    const [searchValue, setSearchValue] = useState<string>('')
    const [changingValue, setChangingValue] = useState<string>('')

    const handleEnterPressed = (value: string) => {
        setSearchValue(value)
        if (filterType === 's') {
            search.set('f', searchType)
            search.set('q', changingValue)
            history.replace({
                search: search.toString()
            })
        }
    }

    const handleDropdownChange = (value: SearchTypeKeys) => {
        setSearchType(value)
    }

    const filteredOwnAssets = useMemo( //own assets
        () => {
            if (filterType === 'o') { // View own's client Assets
                if (searchType === 'a') { // all
                    return (
                        assets.filter((asset) => {
                            return (
                                asset.asst_name
                                    .toLowerCase()
                                    .includes(searchValue.toLowerCase()) ||
                                asset.asst_code
                                    ?.toLowerCase()
                                    .includes(searchValue.toLowerCase()) ||
                                asset.asst_googleaddress
                                    .toLowerCase()
                                    .includes(searchValue.toLowerCase()) ||
                                asset.owner_socialhandle
                                    ?.toLowerCase()
                                    .includes(searchValue.toLowerCase())
                            );
                        })
                    )
                } else if (searchType === 'ad') {
                    return (
                        assets.filter((asset) => {
                            return (
                                asset.asst_googleaddress
                                    .toLowerCase()
                                    .includes(searchValue.toLowerCase()) ||
                                asset.asst_name
                                    .toLowerCase()
                                    .includes(searchValue.toLowerCase())
                            );
                        })
                    )
                } if (searchType === 'c') {
                    return (
                        assets.filter((asset) => {
                            return (
                                asset.owner_socialhandle
                                    ?.toLowerCase()
                                    .includes(searchValue.toLowerCase()) ||
                                asset.owner_name
                                    ?.toLowerCase()
                                    .includes(searchValue.toLowerCase())
                            );
                        })
                    )
                }
            }
        },
        [assets, searchValue, filterType, searchType]
    );
    //end of search

    return (
        <>
            {title && (
                <div className="mb-3 d-flex justify-content-between align-items-center">
                    <h4 className="mb-0">{title}</h4>
                    <span className="text-muted">
                        {assets.length} asset{assets.length > 1 && 's'}
                    </span>
                </div>
            )}

            <div className="mb-3">
                <Row>
                    <Col xs={12} sm={7}>
                        {isAdmin ? (
                            <ToggleButtonGroup
                                className="d-flex mb-1"
                                items={Object.entries(filterItems).map(([key, item]) => ({
                                    ...item,
                                    key: key as PageKeys,
                                    active: key === filterType
                                }))}
                                onSelect={(item) => {
                                    handleFilterChange(item.key)
                                }}
                            />
                        ) : (
                            <div
                                style={{
                                    display: 'flex',
                                }}
                            >
                                <IconButton
                                    key={filterItems['o'].label}
                                    title={filterItems['o'].label}
                                    label={filterItems['o'].label}
                                    Icon={filterItems['o'].Icon}
                                    iconHtmlColor={"var(--primary)"}
                                    className={classNames({
                                        [icbstyles['toggle-btn']]: true,
                                        [icbstyles.active]: true,
                                        "border flex-shrink-0 py-2": true,
                                        "me-2": true,
                                        "border-primary bg-transparent px-2": true,
                                    })}
                                />
                            </div>
                        )
                        }
                    </Col>
                    <Col xs={12} sm={5} className="my-auto">
                        <InputGroup
                            className="d-flex justify-content-end"
                        >
                            <div className={partnerStyles.searchContainer}>
                                <FormControl
                                    aria-label="Text input with dropdown button"
                                    placeholder={
                                        searchType === 'a' ? 'What are you looking for?' :
                                            'Search using ' + searchTypeItem[searchType]
                                    }
                                    value={changingValue}
                                    onKeyPress={(t) => t.key === 'Enter' ? handleEnterPressed(changingValue) : null}
                                    onChange={(e) => setChangingValue(e.target.value)}
                                ></FormControl>

                                <IconButton
                                    transparent
                                    border={false}
                                    Icon={SearchIcon}
                                    iconHtmlColor="var(--black)"
                                    onClick={() => handleEnterPressed(changingValue)}
                                />
                            </div>

                            <Form.Select
                                size="sm"
                                name="mode"
                                value={searchType}
                                onChange={(e) => handleDropdownChange(e.target.value as SearchTypeKeys)}
                                className={partnerStyles.searchDropdown}
                            >
                                {Object.keys(searchTypeItem).map(
                                    (item, index) => (
                                        filterType === 's' ?
                                            (
                                                <option
                                                    key={index}
                                                    value={String(item)}
                                                >
                                                    {searchTypeItem[item as SearchTypeKeys]}
                                                </option>
                                            ) :
                                            item === 's' ?
                                                null :
                                                (
                                                    <option
                                                        key={index}
                                                        value={String(item)}
                                                    >
                                                        {searchTypeItem[item as SearchTypeKeys]}
                                                    </option>
                                                )
                                        // if filter === view staffs, show all options. Else, dont show "search by staff" option
                                    )
                                )}
                            </Form.Select>
                        </InputGroup>
                    </Col>
                </Row>
            </div>

            {filterType === 'o' && // 
                (
                    filteredOwnAssets?.length === undefined ? (
                        <EmptyMessage message="No asset found. Change filters?" />
                    ) : (
                        <Row className="gy-3 gy-lg-4" xs="1" md="2" lg="4" xxl="4" {...rowProps}>
                            {filteredOwnAssets.map((asset, index) => (
                                <Col key={asset.asst_id}>
                                    <CardItem asset={asset} remiClick={remiClick} convoClick={convoClick} />
                                </Col>
                            ))}
                        </Row>
                    )
                )
            }

            {isAdmin && (filterType === 's') && (
                <>

                    {staffAssetsLoading ?
                        (
                            <BootstrapSpinner />
                        ) : (
                            staffAssets === undefined ?
                                (
                                    <EmptyMessage message="No staff asset found." />
                                ) : (
                                    filteredStaffAssets?.length === 0 || filteredStaffAssets === undefined ?
                                        (
                                            <EmptyMessage message="No staff asset found. Change filters?" />
                                        ) : (
                                            <Row className="gy-3 gy-lg-4" xs="1" md="2" lg="4" xxl="4" {...rowProps}>
                                                {filteredStaffAssets.map((asset, index) => (
                                                    <Col key={asset.asst_id}>
                                                        <CardItem asset={asset} />
                                                    </Col>
                                                ))}
                                            </Row>
                                        )
                                )
                        )
                    }
                </>
            )}

            {
                (staffAssets?.pages !== undefined && filteredStaffAssets?.length !== 0) && filterType === 's' && (
                    <div className='d-flex justify-content-center my-4 py-3'>
                        <Pagination count={Math.ceil(staffAssets?.pages[0]?.total / staffAssets?.pages[0].limit)}
                            page={page}
                            onChange={handleChange}
                        />
                    </div>
                )
            }
        </>
    );
};

interface CardItemInterface {
    asset: Butlerr.ClientAsset | Butlerr.staffAsset;
    remiClick?: (assetId: number) => void
    convoClick?: (assetId: number) => void
}

export const CardItem = ({ asset, remiClick, convoClick }: CardItemInterface) => {
    const assetId = asset.asst_id;

    const { mutateAsync: deleteRole } = useAssetRoleMutations('DELETEOWN', assetId);

    // react router dom
    const history = useHistory();

    const handleClick = () => {
        if ('staff' in asset) {
            // go to asset info for partner owner
            history.push(`/work/assets/staff/${assetId}/info`);
        } else {
            // go to asset info for staffs
            history.push(`/work/assets/${assetId}/info`);
        }
    };

    const [selectedRoleId, setSelectedRoleId] = useState<number>(0)
    const [selectedRoleType, setSelectedRoletype] = useState<string>('')
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)

    const onDelete = (roleId: number, roleType: string) => {
        setSelectedRoleId(roleId);
        setSelectedRoletype(roleType)
        setShowDeleteModal(true);
    };

    const onDeleteClose = () => {
        setShowDeleteModal(false);
    };

    const onDeleteConfirm = async () => {
        await deleteRole(
            { asst_id: assetId, asro_id: selectedRoleId ?? -1 }
        );
    };

    return (
        <>
            <Card
                className={'cursor-pointer border-0 shadow ' + styles.assetsListCard}
                onClick={handleClick}
            >
                <div className={'card-img-top ' + styles.assetsListCardImageContainer}>
                    <Card.Img
                        className={styles.assetsListCardImage}
                        as={ButlerrImage}
                        url={!asset.asst_profilepic ? undefined : ImageRoutes.DOCUMENT(asset.asst_profilepic)}
                        variant="top" // needed to have proper corner radius
                        loadingEle={
                            <Placeholder as="div" animation="wave">
                                <Placeholder
                                    as={Card.Img}
                                    xs={12}
                                    className={styles.assetsListCardImage}
                                    variant="top"
                                />
                            </Placeholder>
                        }
                    ></Card.Img>
                    {'roles' in asset && (
                        <div style={{top: 0, left: 0, float: 'left', position: 'absolute', zIndex: 1000}} className="w-100 mt-2 ps-2">
                            {asset.roles.map((role, index) => {
                                if (index <= 5) {
                                    return (
                                            <Dropdown
                                                className="me-2 d-inline-block"
                                                key={role.asro_id}
                                                onClick={(ev) => ev.stopPropagation()}
                                            >
                                                <Dropdown.Toggle as="span" bsPrefix=" " className='d-inline-block'>
                                                    <IconButton
                                                        className='var(--bg-blue) '
                                                        border={false}
                                                        title={ButlerrAssetRoleType[role.asro_type as AssetRoleTypeKey]}
                                                        Icon={assetRoleIcons[role.asro_type]}
                                                        iconHtmlColor="var(--bs-white)"
                                                    />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="p-0">
                                                    <Dropdown.Item onClick={(ev) => {
                                                        onDelete(role.asro_id, role.asro_type)
                                                        ev.stopPropagation()
                                                    }}>
                                                        Resign From Role
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                    )
                                } return null
                            })}
                        </div>
                    )}
                </div>

                <Card.Body className="p-3">

                    <LineClampText
                        maxLines={1}
                        title={asset.asst_googleaddress}
                        className="fw-bold"
                    >
                        {asset.asst_googleaddress}
                    </LineClampText>
                    {
                        asset.owner_name !== null && asset.owner_name !== undefined ? (
                            <div>
                                <LineClampText
                                    maxLines={1}
                                    className=" mb-0"
                                >
                                    {asset.owner_name} (
                                    <small
                                        className="text-muted"
                                    >
                                        {asset.owner_socialhandle}
                                    </small>
                                    )
                                </LineClampText>
                            </div>
                        ) : (
                            <LineClampText
                                maxLines={1}
                                className="text-center fw-bold mb-1"
                            >
                                {asset.owner_socialhandle}
                            </LineClampText>
                        )
                    }

                    <hr />

                    {'roles' in asset && (
                        <>
                            <LineClampText
                                maxLines={1}
                                title={asset.asst_desc ?? 'No description available.'}
                                className={'card-text small ' + styles.assetsListCardDescription + ' mb-0'}
                            >
                                {asset.asst_desc ?? 'No description available.'}
                            </LineClampText>
                    </>
                    )}
                    {
                        'staff' in asset && (
                            <div className="d-flex justify-content-start align-items-center mb-1 small h-auto ">
                                {asset.staff.map(
                                    (staff, idx) => {
                                        if (idx <= 4) {
                                            return (
                                                <UserAvatar
                                                    user={{
                                                        user_id: staff.staff_id,
                                                        user_socialhandle: staff.staff_socialhandle ?? '',
                                                        user_profile: staff.staff_profile
                                                    }}
                                                    width={40}
                                                    height={40}
                                                    className="cursor-pointer border"
                                                />
                                            )
                                        } return null
                                    }
                                )}
                            </div>
                        )
                    }
                </Card.Body>
                <Card.Footer className="d-flex p-0">
                    {
                        remiClick !== undefined && convoClick !== undefined && (
                            <>
                                <IconButton
                                    transparent
                                    border={false}
                                    Icon={NotificationsOutlined}
                                    iconHtmlColor="var(--primary)"
                                    onClick={(ev) => {
                                        remiClick(assetId)
                                        ev.stopPropagation()
                                    }}
                                    title={"Reminders"}
                                    className="py-3"
                                    style={{ width: "calc(50% - 0.5px)", borderRadius: 0, borderBottomLeftRadius: 12 }}
                                />
                                <div className="vr my-1" />
                                <IconButton
                                    transparent
                                    border={false}
                                    Icon={ForumOutlined}
                                    iconHtmlColor="var(--primary)"
                                    onClick={(ev) => {
                                        convoClick(assetId)
                                        ev.stopPropagation()
                                    }}
                                    title={"Conversations"}
                                    className="py-3"
                                    style={{ width: "calc(50% - 0.5px)", borderRadius: 0, borderBottomRightRadius: 12 }}
                                />
                            </>
                        )
                    }
                </Card.Footer>
            </Card>
            <DestructiveModal
                show={showDeleteModal}
                onClose={onDeleteClose}
                onConfirm={onDeleteConfirm}
                title={`Resign from role as ${ButlerrAssetRoleType[selectedRoleType as AssetRoleTypeKey]}?`}
                description={`Please confirm that you are resigning from ${ButlerrAssetRoleType[selectedRoleType as AssetRoleTypeKey]}?`}
                confirmBtnText="Confirm"
            />
        </>
    );
};

export default AssetsList;
