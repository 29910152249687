import { useEffect, useMemo, useRef, useState } from 'react';
import { Map as MuiIconMap, Streetview, StarBorder, Star, AddPhotoAlternateOutlined } from '@mui/icons-material';
import { ButtonGroup, Col, Row, ToggleButton, Image } from 'react-bootstrap';

import styles from '../asset.module.css';
import { Butlerr } from '../../../types/butlerr';
import BootstrapCarousel, { BootstrapCarouselRef } from '../../utils/BootstrapCarousel';
import asst_placeholder from '../../../assets/img_placeholder.jpg';
import IconButton from '../../utils/IconButton';
import { useAssetMutations } from '../../../services/asset.service';
import { DocumentQueries, useAssetImages, useDocumentMutations } from '../../../services/doc.service';
import { useQueryClient } from 'react-query';
import { AssetInfoProps } from './AssetInfo';
import { ImageRoutes } from '../../ui/ButlerrImage';

type GoogleMapModes = 'place' | 'streetview';

const AssetInfoMedia = ({ asset, isPartner = false }: AssetInfoProps) => {
    const assetId = asset.asst_id;

    // google map
    const [mapMode, setMapMode] = useState<GoogleMapModes>('place');
    const handleMapChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMapMode(e.target.value as GoogleMapModes);
    };
    const mapModes = [
        { Icon: MuiIconMap, value: 'place', label: 'Map' },
        { Icon: Streetview, value: 'streetview', label: 'Street View' },
    ];

    // image carousel
    const imageCarousel = useRef<BootstrapCarouselRef>(null);

    //set profile pic state
    const { mutate: editAssetProfile, isLoading: editingAssetProfile } = useAssetMutations('EDIT_PROFILE');

    //Asset images
    const queryClient = useQueryClient();
    const { data: assetImageFiles } = useAssetImages(assetId, !isPartner); //only for asset owner & coowner

    const images = useMemo(() => {
        if (isPartner) {
            if (!asset.asst_profilepic) {
                return [];
            }
            return [ ImageRoutes.DOCUMENT(asset.asst_profilepic) ];
        }
        return assetImageFiles?.map((f) => (
            ImageRoutes.DOCUMENT(f.doc_id)
        )) ?? []
    }, [asset.asst_profilepic, isPartner, assetImageFiles])

    //upload state
    const { mutate: uploadImage, isLoading: isImageUploading } = useDocumentMutations('CREATE_ASSET_IMAGE');
    const fileInput = useRef<HTMLInputElement>(null);

    //image to scroll to after upload
    const [documentIdToScrollTo , setDocumentIdToScrollTo ] = useState<number>();

    useEffect(() => {
        /**
         * Effect will run when `documents` update & when `documentIdToScrollTo` update
         * When `documentIdToScrollTo` is set, the effect will run until the document is found & sscrolled to
         * 
         * No need to consider for partners, since partners can't add photos
         */
        if(documentIdToScrollTo !== undefined) {
            const indexToScrollTo = assetImageFiles?.findIndex(d => d.doc_id === documentIdToScrollTo) ?? -1;

            if (indexToScrollTo !== -1) {
                imageCarousel.current?.setIndex(indexToScrollTo);
                setDocumentIdToScrollTo(undefined);
            }
        }
    }, [documentIdToScrollTo, assetImageFiles])

    return (
        <Row xs={1} lg={2}>
            {/* main_image */}
            <Col className={'mb-4 ' + styles.mediaContainer}>
                {images.length !== 0 ? (
                    <BootstrapCarousel
                        ref={imageCarousel}
                        srcs={images}
                        type='private'
                        lazyLoadRange={3}
                        topRight={
                            isPartner === false
                                ? (_, index) => (
                                        <div className="te-corner d-flex">
                                            <IconButton
                                                title="Add an image"
                                                Icon={AddPhotoAlternateOutlined}
                                                className="me-2"
                                                onClick={() => fileInput.current?.click()}
                                                disabled={isImageUploading}
                                                style={{ background: "rgba(0,0,0,0.65)" }}
                                            />

                                            <IconButton
                                                title="Set profile picture"
                                                Icon={assetImageFiles?.[index]?.doc_id === asset.asst_profilepic
                                                    ? Star
                                                    : StarBorder
                                                }
                                                onClick={() => {
                                                    if (assetImageFiles?.[index].doc_id !== asset.asst_profilepic) {
                                                        editAssetProfile({
                                                            asst_id: assetId,
                                                            doc_id: assetImageFiles?.[index].doc_id ?? -1
                                                        });
                                                    }
                                                }}
                                                disabled={
                                                    editingAssetProfile ||
                                                    !assetImageFiles?.[index]
                                                }
                                                style={{
                                                    background: "rgba(0,0,0,0.65)"
                                                }}
                                            />
                                        </div>
                                  )
                                : undefined
                        }
                    />
                ) : (
                    <div className="position-relative h-100">
                        <Image src={asst_placeholder} className="w-100 h-100 rounded-4" style={{ objectFit: 'cover' }} />

                        {
                            isPartner === false && asset.status !== 'AR' && (
                                <div className="te-corner">
                                    <IconButton
                                        title="Add an image"
                                        Icon={AddPhotoAlternateOutlined}
                                        onClick={() => fileInput.current?.click()}
                                        disabled={isImageUploading}
                                        style={{
                                            background: "rgba(0,0,0,0.65)"
                                        }}
                                    />
                                </div>
                            )
                        }
                    </div>
                )}
                <input
                    ref={fileInput}
                    type="file"
                    className="d-none"
                    accept="image/*"
                    onChange={(e) => {
                        if (e.target.files !== null) {
                            const file = e.target.files[0];

                            uploadImage(
                                {
                                    document: file,
                                    asst_id: assetId
                                },
                                {
                                    onSuccess: ({ response }) => {
                                        //invalidate data for asset image folder (if fetched before)
                                        const folderId = response.data?.doct_parentid
                                        if (folderId) {
                                            queryClient.invalidateQueries(DocumentQueries.DOCUMENTS(folderId));
                                        }

                                        const docId = response.data.doc_id;
                                        if (docId) setDocumentIdToScrollTo(docId);
                                    },
                                    onError: (error) => {
                                        alert(error.message);
                                    },
                                    onSettled: () => {
                                        // reset value
                                        e.target.value = '';
                                    },
                                }
                            );
                        }
                    }}
                />
            </Col>
            <Col className={'mb-4 position-relative ' + styles.mediaContainer}>
                {/* main_map */}
                <fieldset
                    className={
                        'd-flex justify-content-end shadow ' + styles.assetGoogleMapModeToggle
                    }
                >
                    <ButtonGroup size="sm" vertical>
                        {mapModes.map((radio, idx) => (
                            <ToggleButton
                                key={idx}
                                id={`radio-${idx}`}
                                type="radio"
                                name="googleMapMode"
                                value={radio.value}
                                checked={mapMode === radio.value}
                                onChange={handleMapChange}
                                variant={mapMode === radio.value ? 'dark' : 'light'}
                                title={radio.label}
                            >
                                <radio.Icon />
                            </ToggleButton>
                        ))}
                    </ButtonGroup>
                </fieldset>

                <div className="h-100">
                    <GoogleMap asset={asset ?? {}} mapMode={mapMode} />
                </div>
            </Col>
        </Row>
    );
};

// google maps
const GoogleMap = (props: { asset: Butlerr.Asset; mapMode: GoogleMapModes }) => {
    const API_KEY = String(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
    let PARAMETERS = '';

    if (props.mapMode === 'place') {
        PARAMETERS = `q=${encodeURIComponent(props.asset.asst_googleaddress ?? '')}`;
    } else if (props.mapMode === 'streetview') {
        PARAMETERS = `location=${props.asset.asst_geoloc?.x},${props.asset.asst_geoloc?.y}`;
    }

    return (
        <iframe
            title="google-map"
            width="100%"
            height="100%"
            style={{ border: 0, minHeight: '80%', borderRadius: '0.75rem' }}
            loading="lazy"
            allowFullScreen
            src={`https://www.google.com/maps/embed/v1/${props.mapMode}?key=${API_KEY}&${PARAMETERS}`}
        ></iframe>
    );
};

export default AssetInfoMedia;
