import { Add, Done, LinkOff, PostAdd } from '@mui/icons-material';
import { Formik } from 'formik';
import { useState, useEffect } from 'react';
import { Col, Container, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import { Transition } from 'react-transition-group';
import * as yup from 'yup';
import useModal from '../../../hooks/useModal';
import { useCashflowMutations } from '../../../services/assetcashflow.service';

import { Butlerr } from '../../../types/butlerr';
import {
    AssetCashflowTypeKey,
    ButlerrAssetCashflowType,
    Currency,
} from '../../../types/butlerr-enums';
//import { DocumentPickerModal } from '../../documents/DocumentsLink';
import DestructiveModal from '../../utils/DestructiveModal';
import {
    BootstrapInput,
    BootstrapInputPrice,
    BootstrapRadio,
    BootstrapSelect,
} from '../../utils/FormikBootstrapInputs';
import {
    convertDateToYYYYMMDD,
    formCloseHandler,
    removeNumberFormatting,
} from '../../utils/HelperFunctions';
import IconButton from '../../utils/IconButton';
import { DocumentSelectModal } from '../../documents/DocsModal';
import { useQueryClient } from 'react-query';
import { CashflowQueries } from '../../../services/assetcashflow.service';
import BootstrapModalFooter from '../../utils/BootstrapModalFooter';

interface CashflowForm {
    ascf_type: AssetCashflowTypeKey | '';
    ascf_date: string;
    ascf_amt: string;
    ascf_desc: string | null;
    ascf_currency: string;
    ascf_status: boolean;
    type?: number;
}

interface AssetCashflowsModalProps {
    show: boolean;
    onModalClose: () => void;
    initialValue?: Butlerr.Asset.Cashflow;
    assetId: number;
    asset: Butlerr.Asset;
}

const AssetCashflowsModal = ({
    show,
    onModalClose,
    initialValue,
    assetId,
    asset,
}: AssetCashflowsModalProps) => {
    const {
        mutate: createCashflow,
        isLoading: isCreateLoading,
        error: createError,
    } = useCashflowMutations('CREATE');
    const {
        mutate: editCashflow,
        isLoading: isEditLoading,
        error: editError,
    } = useCashflowMutations('EDIT');

    const { mutateAsync: deleteCashflow } = useCashflowMutations('DELETE');

    // delete modal
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedCashflowId, setSelectedCashflowId] = useState<number>();

    const onDelete = (cashflowId: number) => {
        setSelectedCashflowId(cashflowId);
        setShowDeleteModal(true);
    };

    const onDeleteClose = () => {
        setShowDeleteModal(false);
    };

    const onDeleteConfirm = async () => {
        await deleteCashflow({ asst_id: assetId, ascf_id: selectedCashflowId ?? -1 });
        formCloseHandler(handleClose);
    };

    const isLoading = initialValue === undefined ? isCreateLoading : isEditLoading;
    const error = initialValue === undefined ? createError : editError;

    // modal
    const handleClose = async () => {
        if (error !== null) error.message = '';
        if (!isLoading) onModalClose();
    };

    // formik
    const allowedKeys = {
        ascf_currency: Object.keys(Currency),
        ascf_type: Object.keys(ButlerrAssetCashflowType),
    };
    const allowedLabels = {
        ascf_currency: Object.values(Currency),
        ascf_type: Object.values(ButlerrAssetCashflowType),
    };
    const initialValues: CashflowForm = {
        ascf_type: initialValue?.ascf_type ?? '',
        ascf_date: initialValue?.ascf_date
            ? convertDateToYYYYMMDD(initialValue?.ascf_date)!
            : convertDateToYYYYMMDD(new Date().toString())!,
        ascf_amt:
            initialValue?.ascf_amt !== undefined
                ? Math.abs(initialValue.ascf_amt).toLocaleString()
                : '',
        ascf_desc: initialValue?.ascf_desc ?? '',
        ascf_currency: initialValue?.ascf_currency ?? asset.asst_currency,
        ascf_status:
            initialValue?.ascf_status !== undefined && initialValue?.ascf_status !== null
                ? initialValue?.ascf_status === 1
                : true,
        type: initialValue?.ascf_amt ? Math.sign(initialValue?.ascf_amt) : undefined,
    };

    const validationSchema = yup.object({
        ascf_type: yup.string().oneOf(allowedKeys.ascf_type).required('Required'),
        ascf_date: yup.date().max(new Date(), 'Cannot select a future date').required('Required'),
        ascf_amt: yup.string().required('Required'),
        ascf_desc: yup
            .string()
            .max(160, ({ max }) => `Maximum ${max} characters`)
            .nullable(),
        ascf_currency: yup.string().oneOf(allowedKeys.ascf_currency).required('Required'),
        ascf_status: yup.boolean().default(true).required(),
        type: yup.number().required('Required'),
    });

    const onSubmit = (values: CashflowForm) => {
        const { type, ascf_amt, ascf_status, ascf_type, ...data } = values;

        const [cashflowAmount] = removeNumberFormatting(String(ascf_amt));

        const form = {
            ascf_amt: Number(type) * Number(cashflowAmount),
            ascf_status: Number(ascf_status),
            ascf_type: ascf_type as keyof typeof ButlerrAssetCashflowType,
            ...data,
            asst_id: assetId,
            ascf_id: initialValue?.ascf_id,
            ascf_doclink: selectedDocument?.doc_id ?? null,
        };

        if (initialValue === undefined) {
            createCashflow(form, {
                onSuccess: () => {
                    formCloseHandler(handleClose);
                },
            });
        } else {
            editCashflow(form as typeof form & { ascf_id: number }, {
                onSuccess: () => {
                    formCloseHandler(handleClose);
                },
            });
        }
    };

    // document picker
    const [selectedDocument, setSelectedDocument] = useState<Butlerr.Document.File>();

    useEffect(() => {
        if (initialValue?.ascf_doclink) {
            setSelectedDocument({
                doc_name: initialValue?.doc_name,
                doc_created: initialValue?.doc_created,
                doc_desc: initialValue?.doc_desc,
                doc_format: initialValue?.doc_format,
                doc_id: initialValue?.doc_id,
                doct_parentid: initialValue?.doct_parentid,
            } as Butlerr.Document.File);
        } else {
            setSelectedDocument(undefined);
        }
    }, [
        initialValue?.ascf_doclink,
        initialValue?.doc_name,
        initialValue?.doc_created,
        initialValue?.doc_desc,
        initialValue?.doc_format,
        initialValue?.doc_id,
        initialValue?.doct_parentid,
    ]);

    const [picker, openPicker] = useModal(DocumentSelectModal, {
        asset: asset,
        onFileSelect: setSelectedDocument,
        className: 'modal-layer-1',
        backdropClassName: 'modal-layer-1',
        initialFolder: asset.doct_ascf_id,
    });

    /**
     * Refresh all cashflows when the modal is opened, if it's an edit modal
     * The documents might have been changed
     */
    const qc = useQueryClient();
    useEffect(() => {
        if (initialValue?.asst_id !== undefined && show) {
            qc.invalidateQueries(CashflowQueries.CASHFLOWS(initialValue.asst_id));
        }
    }, [initialValue?.asst_id, qc, show]);

    return (
        <>
            <Transition in={show} timeout={{ exit: 300 }} unmountOnExit>
                {() => (
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        {({ submitForm, dirty }) => (
                            <Form noValidate>
                                <Modal
                                    show={show}
                                    onHide={formCloseHandler.bind(undefined, handleClose, dirty)}
                                    scrollable
                                    size="lg"
                                    backdrop={isLoading ? 'static' : undefined}
                                    keyboard={!isLoading}
                                    centered
                                >
                                    <Modal.Header>
                                        <Container>
                                            <Row>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <Modal.Title>
                                                        {initialValue === undefined
                                                            ? 'Add '
                                                            : 'Edit '}
                                                        Cashflow Details
                                                    </Modal.Title>

                                                    {/* <BootstrapSwitch
                                                        id="ascf_status"
                                                        label="Verified"
                                                        disabled={isLoading}
                                                    /> */}
                                                </div>
                                            </Row>
                                        </Container>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Container>
                                            <Row className="mb-3 g-3">
                                                <Form.Group as={Col} lg>
                                                    <BootstrapSelect
                                                        id="ascf_type"
                                                        label="Category"
                                                        placeholder="Category"
                                                        disabled={
                                                            asset.status === 'AR' || isLoading
                                                        }
                                                        allowedKeys={allowedKeys.ascf_type}
                                                        allowedLabels={allowedLabels.ascf_type}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} lg>
                                                    <BootstrapInput
                                                        type="date"
                                                        id="ascf_date"
                                                        label="Date"
                                                        disabled={
                                                            asset.status === 'AR' || isLoading
                                                        }
                                                    />
                                                </Form.Group>
                                            </Row>
                                            <Row className="mb-3 g-3">
                                                <Form.Group as={Col} lg="auto">
                                                    <BootstrapRadio
                                                        inline
                                                        name="type"
                                                        label="Type"
                                                        values={[1, -1]}
                                                        labels={['Income', 'Expense']}
                                                        disabled={
                                                            asset.status === 'AR' || isLoading
                                                        }
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} lg={5}>
                                                    <BootstrapSelect
                                                        id="ascf_currency"
                                                        label="Currency"
                                                        placeholder="Currency"
                                                        disabled={
                                                            asset.status === 'AR' || isLoading
                                                        }
                                                        allowedKeys={allowedKeys.ascf_currency}
                                                        allowedLabels={allowedLabels.ascf_currency}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} lg>
                                                    <BootstrapInputPrice
                                                        id="ascf_amt"
                                                        label="Amount"
                                                        placeholder="Amount"
                                                        disabled={
                                                            asset.status === 'AR' || isLoading
                                                        }
                                                    />
                                                </Form.Group>
                                            </Row>
                                            <Row className="mb-3 g-3">
                                                <Form.Group as={Col} lg>
                                                    <BootstrapInput
                                                        as="textarea"
                                                        id="ascf_desc"
                                                        label="Description"
                                                        placeholder="Description"
                                                        disabled={
                                                            asset.status === 'AR' || isLoading
                                                        }
                                                        required={false}
                                                    />
                                                </Form.Group>
                                            </Row>

                                            <Row className="mb-3 g-3">
                                                <Form.Group as={Col} lg>
                                                    <InputGroup>
                                                        <Form.Control
                                                            readOnly
                                                            value={
                                                                selectedDocument
                                                                    ? `${
                                                                          selectedDocument.doc_name
                                                                      }${
                                                                          selectedDocument.doc_format
                                                                              ? '.' +
                                                                                selectedDocument.doc_format
                                                                              : ''
                                                                      }`
                                                                    : ''
                                                            }
                                                            placeholder="No document linked"
                                                        />
                                                        <InputGroup.Text>
                                                            {selectedDocument !== undefined ? (
                                                                <IconButton
                                                                    transparent
                                                                    border={false}
                                                                    iconHtmlColor="var(--grey)"
                                                                    Icon={LinkOff}
                                                                    title="Unlink document"
                                                                    onClick={() =>
                                                                        setSelectedDocument(
                                                                            undefined
                                                                        )
                                                                    }
                                                                    disabled={
                                                                        asset.status === 'AR' ||
                                                                        isLoading
                                                                    }
                                                                />
                                                            ) : (
                                                                <IconButton
                                                                    transparent
                                                                    border={false}
                                                                    iconHtmlColor="var(--grey)"
                                                                    Icon={PostAdd}
                                                                    title="Link a document"
                                                                    onClick={openPicker}
                                                                    disabled={
                                                                        asset.status === 'AR' ||
                                                                        isLoading
                                                                    }
                                                                />
                                                            )}
                                                        </InputGroup.Text>
                                                    </InputGroup>
                                                </Form.Group>
                                            </Row>
                                        </Container>
                                    </Modal.Body>
                                    <BootstrapModalFooter
                                        error={error}
                                        deleteBtnProps={{
                                            onClick: () => onDelete(initialValue?.ascf_id!),
                                            disabled: asset.status === 'AR' || isLoading,
                                            className: initialValue === undefined ? 'd-none' : '',
                                        }}
                                        cancelBtnProps={{
                                            onClick: () => formCloseHandler(handleClose, dirty),
                                            disabled: isLoading,
                                        }}
                                        confirmBtnProps={{
                                            Icon: initialValue === undefined ? Add : Done,
                                            label:
                                                initialValue === undefined
                                                    ? isLoading
                                                        ? 'Adding...'
                                                        : 'Add'
                                                    : isLoading
                                                    ? 'Saving...'
                                                    : 'Save',
                                            onClick:
                                                initialValue === undefined
                                                    ? submitForm
                                                    : !dirty &&
                                                      selectedDocument?.doc_id ===
                                                          initialValue.ascf_doclink
                                                    ? handleClose
                                                    : submitForm,
                                            disabled: asset.status === 'AR' || isLoading,
                                        }}
                                    />
                                </Modal>
                                {picker}
                            </Form>
                        )}
                    </Formik>
                )}
            </Transition>

            <DestructiveModal
                show={showDeleteModal}
                onClose={onDeleteClose}
                onConfirm={onDeleteConfirm}
                title="Delete cashflow?"
                description="Any document linked to this cashflow will not be affected."
                className="modal-layer-1"
                backdropClassName="modal-layer-1"
            />
        </>
    );
};

export default AssetCashflowsModal;
