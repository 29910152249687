import { Butlerr } from '../types/butlerr';
import { SocialPostReactionKey, UserGenderKey, UserSelfDescribeKey } from '../types/butlerr-enums';
import useButlerrAPI, {
    ButlerrMutationOptions,
    ButlerrPaginatedResult,
    useButlerrMutation,
    useButlerrPaginatedQuery,
} from './useButlerrAPI';
import { useAuth0 } from '@auth0/auth0-react';
import { InfiniteData, QueryKey } from 'react-query';
import { SocialQueries } from './social.service';
import { useMemo } from 'react';
import { Nullable } from '../types/custom';

export interface SearchUserOptions {
    query?: string;
    has_work_profile?: boolean;
    show_current_user?: boolean;
    following_of_user?: number;
    follower_of_user?: number;
    reacted_to_post?: number;
    follower_of_channel?: number;
    is_taggable?: boolean;
    post_reaction?: SocialPostReactionKey;
}

export const UserQueries = {
    //Your own user
    USER: ['user', 'details'] as const,
    USER_DETAILS: (userId: number) => ['user', 'profile', userId] as const,
    USER_VERIFICATION: ['user', 'verification'] as const,

    //List of users
    FOLLOWERS: ['users', 'followers'] as const,
    FOLLOWING: ['users', 'following'] as const,
    USER_SEARCH: (params: SearchUserOptions) => ['users', 'search', params] as const,
    TOP_CONTRIBUTORS: (options: Required<TopContributorOptions>) =>
        ['users', 'top_contributors', options] as const,
};

export function useButlerrUser() {
    const { isAuthenticated } = useAuth0();
    return useButlerrAPI<Butlerr.User>(UserQueries.USER, '/api/user', true, {
        enabled: isAuthenticated,
        cacheTime: Infinity,
        retry: (_, error) => error.status !== 404,
    });
}

export function useUserVerification() {
    type Verification = {
        email: string;
        email_verified: boolean;
    }
    return useButlerrAPI<Verification>(UserQueries.USER_VERIFICATION, `/api/user/verification`, true, {
        refetchInterval: 10 * 1000 //refetch every 10 seconds
    })
}

export type UserDetails = Required<Butlerr.UserInfo> & Nullable<Pick<Butlerr.User, 'user_email' | 'user_mobileno'>>;
export function useUserDetails(userId: number, enabled = true) {    
    return useButlerrAPI<UserDetails>(
        UserQueries.USER_DETAILS(userId),
        `/api/user/${userId}`,
        true,
        {
            cacheTime: 60 * 1000, //cache for only 1 min
            enabled,
        }
    );
}

export function useUserSearch(
    {
        query,
        has_work_profile,
        show_current_user,
        following_of_user,
        follower_of_user,
        reacted_to_post,
        follower_of_channel,
        is_taggable,
        post_reaction
    }: SearchUserOptions = {},
    enabled = true
) {

    const { data: currUser } = useButlerrUser();

    //Hook to use the `UserQueries.FOLLOWERS` & `UserQueries.FOLLOWING` query keys, if that's the one being fetched.
    const [ url, queryKey ] = useMemo(() => {
        const options : SearchUserOptions = {
            query,
            has_work_profile,
            show_current_user,
            following_of_user,
            follower_of_user,
            reacted_to_post,
            follower_of_channel,
            is_taggable,
            post_reaction
        };
        const searchParams = new URLSearchParams();

        let usedKeys : Array<keyof SearchUserOptions> = [];

        for (const _key in options) {
            const key = _key as keyof SearchUserOptions;
            const value = options[key];

            if (value !== undefined) {
                usedKeys.push(key);
                searchParams.set(key, String(value));
            }
        }
        //normal query key
        let queryKey : QueryKey | undefined = UserQueries.USER_SEARCH(options);

        //if a key is used that's not `follower_of_user` or `following_of_user`, OR more than one key, use normal query key!
        if (
            usedKeys.length === 1 &&
            (usedKeys[0] === 'follower_of_user' || usedKeys[0] === 'following_of_user')
        ) {
            if (currUser?.user_id === undefined) queryKey = undefined;
            else if (currUser.user_id === options.follower_of_user) queryKey = UserQueries.FOLLOWERS;
            else if (currUser.user_id === options.following_of_user) queryKey = UserQueries.FOLLOWING;
        }

        const url = `/api/user/search?${searchParams.toString()}`;
        return [ url, queryKey ] as const;
    }, [
        currUser?.user_id,
        query,
        has_work_profile,
        show_current_user,
        following_of_user,
        follower_of_user,
        reacted_to_post,
        follower_of_channel,
        is_taggable,
        post_reaction
    ])


    type SearchResult = Array<Required<Butlerr.UserInfo> & Partial<Pick<Butlerr.Work.WorkProfile, 'wkpf_name'>>>;

    return useButlerrPaginatedQuery<SearchResult>(queryKey ?? [], url, true, {
        //temporarily disable query until current user is fetched, should be fetched before anything else
        enabled: queryKey !== undefined && enabled
    });
}

export function useFollowers(enabled = true) {
    return useButlerrPaginatedQuery<Required<Butlerr.UserInfo>[]>(
        UserQueries.FOLLOWERS,
        '/api/user/follower',
        true,
        { enabled, refetchOnMount: 'always' }
    );
}
export function useFollowing(enabled = true) {
    return useButlerrPaginatedQuery<Required<Butlerr.UserInfo>[]>(
        UserQueries.FOLLOWING,
        '/api/user/following',
        true,
        { enabled, refetchOnMount: 'always' }
    );
}

export interface ITopUsers {
    users: Array<
        Required<Exclude<Butlerr.UserInfo, 'user_joined' | 'user_lastaccess' | 'user_socialbio'>>
        & {
            count: number;
        }>;
    month: number;
    year: number;
}

export interface TopContributorOptions {
    //Defaults to `top_followers`
    sort: 'top_followers' | 'top_posts' | 'top_reactions';
    time: 'all_time' | 'month';
}

export function useTopContributors(options: TopContributorOptions) {
    const searchParams = new URLSearchParams();
    searchParams.set('sort', options.sort);
    searchParams.set('time', options.time);

    return useButlerrAPI<ITopUsers>(
        UserQueries.TOP_CONTRIBUTORS(options),
        `/api/user/top_contributors?${searchParams.toString()}`
    );
}

type SocialProfileForm = Required<
    Pick<Butlerr.UserInfo, 'user_socialbio' | 'user_socialhandle' | 'user_socialoneliner'>
> & {
    user_socialselfdescribe: UserSelfDescribeKey | '';
};

type TCreateUser = Pick<
    Butlerr.User,
    | 'user_firstname'
    | 'user_lastname'
    | 'user_country'
    | 'user_state'
    | 'user_mobileno'
    | 'user_birthyear'
    | 'user_socialhandle'
    | 'user_socialoneliner'
    | 'user_socialbio'
    | 'user_preflocations'
    | 'user_prefkeywords'
> & {
    user_gender: UserGenderKey | '';
    user_profile: File | null;
    user_socialselfdescribe: UserSelfDescribeKey | '';
};

type IPaginatedUser = InfiniteData<ButlerrPaginatedResult<Required<Butlerr.UserInfo>[]>>;

const UserMutations = {
    CHECK_SOCIALHANDLE_AVAILABILITY: (body: {
        user_socialhandle: string;
    }): ButlerrMutationOptions => ({
        url: `/api/user/social/validity`,
        method: 'POST',
        requestBody: body,
        refetchData: false,
    }),
    CREATE: (body: TCreateUser): ButlerrMutationOptions => ({
        url: `/api/user`,
        method: 'POST',
        requestBody: body,
        useFormData: true,
    }),
    EDIT: (body: Partial<Butlerr.User>): ButlerrMutationOptions => ({
        url: `/api/user`,
        method: 'PUT',
        requestBody: body,
    }),
    EDIT_SOCIAL: (body: SocialProfileForm): ButlerrMutationOptions => ({
        url: `/api/user/social`,
        method: 'PUT',
        requestBody: body,
    }),
    EDIT_PHOTO: (image: Blob): ButlerrMutationOptions => ({
        url: '/api/user/profile',
        method: 'PUT',
        requestBody: {
            user_profile: image,
        },
        useFormData: true,
    }),
    FOLLOW: (userId: number): ButlerrMutationOptions => ({
        url: `/api/user/${userId}/follower`,
        method: 'POST',
        //refetch your following
        queryKey: UserQueries.FOLLOWING,
        refetchData: true,
        //update follower count in user details
        updater: [
            {
                queryKey: SocialQueries.POSTS({ following_only: true }),
                action: 'refetch',
                partialMatch: true
            },
            {
                queryKey: UserQueries.USER,
                action: (state: Butlerr.User) => ({
                    ...state,
                    following: [...state.following, userId],
                }),
            },
            {
                queryKey: UserQueries.USER_DETAILS(userId),
                action: (state: Required<Butlerr.UserInfo>) => ({
                    ...state,
                    follower: state.follower + 1,
                }),
            },
            //partial match to update all list of users
            {
                partialMatch: true,
                queryKey: UserQueries.FOLLOWERS.slice(0, -1),
                action: (state: Required<Butlerr.UserInfo>[] | IPaginatedUser | ITopUsers) => {
                    const mapFn = (u: Required<Butlerr.UserInfo>) => {
                        if (u.user_id !== userId) return u;
                        return {
                            ...u,
                            follower: u.follower + 1,
                        };
                    };

                    if ('pages' in state) {
                        return {
                            pageParams: state.pageParams,
                            pages: state.pages.map((p) => ({
                                ...p,
                                result: p.result.map(mapFn),
                            })),
                        };
                    }
                    if ('users' in state) {
                        return {
                            ...state,
                            users: state.users.map(mapFn)
                        }
                    }
                    return state.map(mapFn);
                },
            },
        ],
    }),
    UNFOLLOW: (userId: number): ButlerrMutationOptions => ({
        url: `/api/user/${userId}/follower`,
        method: 'DELETE',
        //refetch your following
        queryKey: UserQueries.FOLLOWING,
        refetchData: true,
        //update follower count in user details
        updater: [
            {
                queryKey: SocialQueries.POSTS({ following_only: true }),
                action: 'refetch',
                partialMatch: true
            },
            {
                queryKey: UserQueries.USER,
                action: (state: Butlerr.User) => ({
                    ...state,
                    following: state.following.filter((u) => u !== userId),
                }),
            },
            {
                queryKey: UserQueries.USER_DETAILS(userId),
                action: (state: Required<Butlerr.UserInfo>) => ({
                    ...state,
                    follower: state.follower - 1,
                }),
            },
            {
                partialMatch: true,
                queryKey: UserQueries.FOLLOWERS.slice(0, -1),
                action: (state: Required<Butlerr.UserInfo>[] | IPaginatedUser | ITopUsers) => {
                    const mapFn = (u: Required<Butlerr.UserInfo>) => {
                        if (u.user_id !== userId) return u;
                        return {
                            ...u,
                            follower: u.follower - 1,
                        };
                    };

                    if ('pages' in state) {
                        return {
                            pageParams: state.pageParams,
                            pages: state.pages.map((p) => ({
                                ...p,
                                result: p.result.map(mapFn),
                            })),
                        };
                    }
                    if ('users' in state) {
                        return {
                            ...state,
                            users: state.users.map(mapFn)
                        }
                    }
                    return state.map(mapFn);
                },
            },
        ],
    }),
    REMOVE_FOLLOW: (userId: number): ButlerrMutationOptions => ({
        url: `/api/user/follower/${userId}`,
        method: 'DELETE',
        //refetch your followers
        queryKey: UserQueries.FOLLOWERS,
        refetchData: true,
        //update follower count in user details
        updater: [
            {
                queryKey: UserQueries.USER,
                action: (state: Butlerr.User) => ({
                    ...state,
                    follower: state.follower.filter((u) => u !== userId),
                }),
            },
            {
                queryKey: UserQueries.USER_DETAILS(userId),
                action: (state: Required<Butlerr.UserInfo>) => ({
                    ...state,
                    following: state.following - 1,
                }),
            },
            {
                partialMatch: true,
                queryKey: UserQueries.FOLLOWERS.slice(0, -1),
                action: (state: Required<Butlerr.UserInfo>[] | IPaginatedUser | ITopUsers) => {
                    const mapFn = (u: Required<Butlerr.UserInfo>) => {
                        if (u.user_id !== userId) return u;
                        return {
                            ...u,
                            following: u.following - 1,
                        };
                    };
                    if ('pages' in state) {
                        return {
                            pageParams: state.pageParams,
                            pages: state.pages.map((p) => ({
                                ...p,
                                result: p.result.map(mapFn),
                            })),
                        };
                    }
                    if ('users' in state) {
                        return {
                            ...state,
                            users: state.users.map(mapFn)
                        }
                    }
                    return state.map(mapFn);
                },
            },
        ],
    }),
    INVITE_USER: (body: { inv_email: string[]; inv_msg?: string }): ButlerrMutationOptions => ({
        url: '/api/user/invite',
        method: 'POST',
        requestBody: body,
        refetchData: false,
    }),
    INVITE_USER_VALIDATE: (body: { inv_email: string[] }): ButlerrMutationOptions => ({
        url: '/api/user/invite/validity',
        method: 'POST',
        requestBody: body,
        refetchData: false,
    }),
    SEND_VERIFY_EMAIL: () : ButlerrMutationOptions => ({
        url: `/api/user/verification`,
        method: 'POST',
        refetchData: false
    })
};

export function useUserMutations<Key extends keyof typeof UserMutations>(key: Key) {
    //You won't get type suggestions in the mutate function without this assertion
    const mutationFn = UserMutations[key] as (
        params: Parameters<typeof UserMutations[Key]>[0]
    ) => ButlerrMutationOptions;
    return useButlerrMutation(mutationFn, UserQueries.USER);
}
