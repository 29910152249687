import { Butlerr } from '../types/butlerr';
import { AssetRemindersPriorityKey, AssetRemindersTypeKey } from '../types/butlerr-enums';
import { assetBaseRoute } from './asset.service';
import useButlerrAPI, { ButlerrMutationOptions, useButlerrMutation } from './useButlerrAPI';

export const ReminderQueries = {
    PARTNERREMINDERS: ['partner reminders'] as const,
};

export function useReminders() {
    return useButlerrAPI<Butlerr.Asset.Reminder[]>(
        ReminderQueries.PARTNERREMINDERS,
        `/api/reminders`
    );
}

type ReminderForm = {
    assetId: number;
    reminderId: number;
    remi_date: string;
    remi_msg: string;
    remi_priority: AssetRemindersPriorityKey | '';
    remi_type: AssetRemindersTypeKey | '';
    remi_completed?: number;
    owner_socialhandle?: string;
}

const PartnerReminderMutations = {
    CREATE: ({
        assetId,
        ...reminder
    }: Omit<ReminderForm, 'reminderId'>): ButlerrMutationOptions => ({
        url: `${assetBaseRoute}/${assetId}/reminders/`,
        method: 'POST',
        requestBody: reminder,
    }),

    EDIT: ({
        assetId,
        reminderId,
        ...reminder
    }: ReminderForm): ButlerrMutationOptions => ({
        url: `/api/reminders/${reminderId}`,
        method: 'PUT',
        requestBody: reminder,
        queryKey: ReminderQueries.PARTNERREMINDERS
    }),

    DELETE: ({
        reminderId,
    }: Pick<ReminderForm, 'assetId' | 'reminderId'>): ButlerrMutationOptions => ({
        url: `/api/reminders/${reminderId}`,
        method: 'DELETE',
    }),
}

export function usePartnerReminderMutations<Key extends keyof typeof PartnerReminderMutations>(
    key: Key,
) {
    //You won't get type suggestions in the mutate function without this assertion
    const mutationFn = PartnerReminderMutations[key] as (
        params: Parameters<typeof PartnerReminderMutations[Key]>[0]
    ) => ButlerrMutationOptions;
    return useButlerrMutation(mutationFn, ReminderQueries.PARTNERREMINDERS);
}

