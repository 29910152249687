import { PhotoRounded } from '@mui/icons-material';
import { Formik } from 'formik';
import React, { useRef, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import * as yup from 'yup';
import {
    AddBusinessOutlined,
    ArchitectureOutlined,
    AutoStoriesOutlined,
    BedroomParentOutlined,
    CalculateOutlined,
    CarpenterOutlined,
    ChairOutlined,
    EngineeringOutlined,
    FileOpenOutlined,
    HealthAndSafetyOutlined,
    HouseOutlined,
    LocalPoliceOutlined,
    ManageAccountsOutlined,
    MapsHomeWorkOutlined,
    MonetizationOnOutlined,
    PolicyOutlined,
    QueryStatsOutlined,
    RateReviewOutlined,
    SupervisedUserCircleOutlined,
    SupportAgentOutlined,
    SvgIconComponent,
    SwapHorizOutlined,
} from '@mui/icons-material';

import { usePartnerMutations } from '../../../services/partner.service';
import { AttachmentFile } from '../../../types/custom';
import {
    BootstrapCheckbox,
    BootstrapFormLabel,
    BootstrapInput,
    BootstrapInputPhone,
    BootstrapSelectCountry,
    BootstrapSelectRegion,
} from '../../utils/FormikBootstrapInputs';
import {
    convertBooleanToBinary,
    formCloseHandler,
    getFileWithUrl,
} from '../../utils/HelperFunctions';
import IconButton from '../../utils/IconButton';
import RouterPrompt from '../../utils/RouterPrompt';
import PartnerAvatar from './PartnerAvatar';
import styles from '../partner.module.css';
import { IProfileType } from './WorkProfileSetup';

interface FormValues {
    wkpf_name: string;
    wkpf_address: string;
    wkpf_country: string;
    wkpf_region: string;
    wkpf_city: string;
    wkpf_postcode: string;
    wkpf_phone: string;
    wkpf_email: string;
    wkpf_url: string;
    wkpf_development: boolean;
    wkpf_legal: boolean;
    wkpf_conveyancing: boolean;
    wkpf_management: boolean;
    wkpf_accounting: boolean;
    wkpf_financing: boolean;
    wkpf_fundtransfer: boolean;
    wkpf_projects: boolean;
    wkpf_interior: boolean;
    wkpf_valuation: boolean;
    wkpf_surveyance: boolean;
    wkpf_inspection: boolean;
    wkpf_salesagent: boolean;
    wkpf_advisory: boolean;
    wkpf_insurance: boolean;
    wkpf_homestaging: boolean;
    wkpf_media: boolean;
    wkpf_training: boolean;
    wkpf_renovation: boolean;
    wkpf_repair: boolean;
    wkpf_research: boolean;
    wkpf_serviceregions: string;
    wkpf_desc: string;
}

interface WorkProfileSetupCompanyProps {
    setProfileType: React.Dispatch<React.SetStateAction<IProfileType | undefined>>;
}

const WorkProfileSetupCompany = ({ setProfileType }: WorkProfileSetupCompanyProps) => {
    const { mutate: createPartner, isLoading, error } = usePartnerMutations('CREATE');

    const [file, setFile] = useState<AttachmentFile>();
    const inputRef = useRef<HTMLInputElement>(null);

    const initialValues: FormValues = {
        wkpf_name: '',
        wkpf_address: '',
        wkpf_country: '',
        wkpf_region: '',
        wkpf_city: '',
        wkpf_postcode: '',
        wkpf_phone: '',
        wkpf_email: '',
        wkpf_url: '',
        wkpf_development: false,
        wkpf_legal: false,
        wkpf_conveyancing: false,
        wkpf_management: false,
        wkpf_accounting: false,
        wkpf_financing: false,
        wkpf_fundtransfer: false,
        wkpf_projects: false,
        wkpf_interior: false,
        wkpf_valuation: false,
        wkpf_surveyance: false,
        wkpf_inspection: false,
        wkpf_salesagent: false,
        wkpf_advisory: false,
        wkpf_insurance: false,
        wkpf_homestaging: false,
        wkpf_media: false,
        wkpf_training: false,
        wkpf_renovation: false,
        wkpf_repair: false,
        wkpf_research: false,
        wkpf_serviceregions: '',
        wkpf_desc: '',
    };

    const validationSchema = yup.object({
        wkpf_name: yup
            .string()
            .required('Required')
            .max(80, ({ max }) => `Maximum ${max} characters`),
        wkpf_address: yup
            .string()
            .max(80, ({ max }) => `Maximum ${max} characters`)
            .nullable(),
        wkpf_country: yup
            .string()
            .max(2, ({ max }) => `Maximum ${max} characters for country`)
            .nullable(),
        wkpf_region: yup
            .string()
            .max(30, ({ max }) => `Maximum ${max} characters`)
            .nullable(),
        wkpf_city: yup
            .string()
            .max(30, ({ max }) => `Maximum ${max} characters`)
            .nullable(),
        wkpf_postcode: yup
            .string()
            .max(8, ({ max }) => `Maximum ${max} characters`)
            .nullable(),
        wkpf_phone: yup
            .string()
            .max(15, ({ max }) => `Maximum ${max} characters`)
            .nullable(),
        wkpf_email: yup
            .string()
            .max(80, ({ max }) => `Maximum ${max} characters`)
            .email('invalid email')
            .nullable(),
        wkpf_url: yup
            .string()
            .max(60, ({ max }) => `Maximum ${max} characters`)
            .url('invalid url')
            .nullable(),
        wkpf_development: yup.boolean().nullable(),
        wkpf_legal: yup.boolean().nullable(),
        wkpf_conveyancing: yup.boolean().nullable(),
        wkpf_management: yup.boolean().nullable(),
        wkpf_accounting: yup.boolean().nullable(),
        wkpf_financing: yup.boolean().nullable(),
        wkpf_fundtransfer: yup.boolean().nullable(),
        wkpf_projects: yup.boolean().nullable(),
        wkpf_interior: yup.boolean().nullable(),
        wkpf_valuation: yup.boolean().nullable(),
        wkpf_surveyance: yup.boolean().nullable(),
        wkpf_inspection: yup.boolean().nullable(),
        wkpf_salesagent: yup.boolean().nullable(),
        wkpf_advisory: yup.boolean().nullable(),
        wkpf_insurance: yup.boolean().nullable(),
        wkpf_homestaging: yup.boolean().nullable(),
        wkpf_media: yup.boolean().nullable(),
        wkpf_training: yup.boolean().nullable(),
        wkpf_renovation: yup.boolean().nullable(),
        wkpf_repair: yup.boolean().nullable(),
        wkpf_research: yup.boolean().nullable(),
        wkpf_serviceregions: yup
            .string()
            .max(100, ({ max }) => `Maximum ${max} characters`)
            .nullable(),
        wkpf_desc: yup
            .string()
            .max(160, ({ max }) => `Maximum ${max} characters`)
            .nullable(),
    });

    const onSubmit = (values: FormValues) => {
        const {
            wkpf_development,
            wkpf_legal,
            wkpf_conveyancing,
            wkpf_management,
            wkpf_accounting,
            wkpf_financing,
            wkpf_fundtransfer,
            wkpf_projects,
            wkpf_interior,
            wkpf_valuation,
            wkpf_surveyance,
            wkpf_inspection,
            wkpf_salesagent,
            wkpf_advisory,
            wkpf_insurance,
            wkpf_homestaging,
            wkpf_media,
            wkpf_training,
            wkpf_renovation,
            wkpf_repair,
            wkpf_research,
            ...rest
        } = values;

        createPartner(
            {
                ...rest,
                wkpf_development: convertBooleanToBinary(values.wkpf_development) ?? 0,
                wkpf_legal: convertBooleanToBinary(values.wkpf_legal) ?? 0,
                wkpf_conveyancing: convertBooleanToBinary(values.wkpf_conveyancing) ?? 0,
                wkpf_management: convertBooleanToBinary(values.wkpf_management) ?? 0,
                wkpf_accounting: convertBooleanToBinary(values.wkpf_accounting) ?? 0,
                wkpf_financing: convertBooleanToBinary(values.wkpf_financing) ?? 0,
                wkpf_fundtransfer: convertBooleanToBinary(values.wkpf_fundtransfer) ?? 0,
                wkpf_projects: convertBooleanToBinary(values.wkpf_projects) ?? 0,
                wkpf_interior: convertBooleanToBinary(values.wkpf_interior) ?? 0,
                wkpf_valuation: convertBooleanToBinary(values.wkpf_valuation) ?? 0,
                wkpf_surveyance: convertBooleanToBinary(values.wkpf_surveyance) ?? 0,
                wkpf_inspection: convertBooleanToBinary(values.wkpf_inspection) ?? 0,
                wkpf_salesagent: convertBooleanToBinary(values.wkpf_salesagent) ?? 0,
                wkpf_advisory: convertBooleanToBinary(values.wkpf_advisory) ?? 0,
                wkpf_insurance: convertBooleanToBinary(values.wkpf_insurance) ?? 0,
                wkpf_homestaging: convertBooleanToBinary(values.wkpf_homestaging) ?? 0,
                wkpf_media: convertBooleanToBinary(values.wkpf_media) ?? 0,
                wkpf_training: convertBooleanToBinary(values.wkpf_training) ?? 0,
                wkpf_renovation: convertBooleanToBinary(values.wkpf_renovation) ?? 0,
                wkpf_repair: convertBooleanToBinary(values.wkpf_repair) ?? 0,
                wkpf_research: convertBooleanToBinary(values.wkpf_research) ?? 0,
                wkpf_profile: file?.file ?? null,
            },
            {
                onSuccess: () => {
                    formCloseHandler(handleClose);
                },
            }
        );
    };

    const handleClose = () => {
        if (error !== null) error.message = '';
        if (!isLoading) {
            setProfileType(undefined);
        }
    };

    type PartnerTypesInterface = [string, SvgIconComponent, string];
    const workProfileServices: PartnerTypesInterface[] = [
        ['wkpf_development', EngineeringOutlined, 'Property Development'],
        ['wkpf_management', ManageAccountsOutlined, 'Property Management'],
        ['wkpf_valuation', AddBusinessOutlined, 'Property Valuation'],
        ['wkpf_surveyance', PolicyOutlined, 'Land Surveying'],
        ['wkpf_projects', MapsHomeWorkOutlined, 'Project Marketing'],
        ['wkpf_salesagent', SupportAgentOutlined, 'Sales Agent'],
        ['wkpf_homestaging', HouseOutlined, 'Home Staging'],
        ['wkpf_renovation', BedroomParentOutlined, 'Renovation'],
        ['wkpf_interior', ChairOutlined, 'Interior Design'],
        ['wkpf_repair', CarpenterOutlined, 'Repair/Maintenance'],
        ['wkpf_inspection', ArchitectureOutlined, 'Home Inspection'],
        ['wkpf_advisory', SupervisedUserCircleOutlined, 'Portfolio Advisory'],
        ['wkpf_insurance', HealthAndSafetyOutlined, 'Insurance Provider'],
        ['wkpf_legal', LocalPoliceOutlined, 'Legal Services'],
        ['wkpf_conveyancing', FileOpenOutlined, 'Legal Conveyance'],
        ['wkpf_fundtransfer', SwapHorizOutlined, 'Fund Transfer'],
        ['wkpf_financing', MonetizationOnOutlined, 'Financing'],
        ['wkpf_accounting', CalculateOutlined, 'Accounting/Tax'],
        ['wkpf_media', AutoStoriesOutlined, 'Media/Publication'],
        ['wkpf_training', RateReviewOutlined, 'Training/Education'],
        ['wkpf_research', QueryStatsOutlined, 'Market Research'],
    ];

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({ dirty, values, handleSubmit, setFieldValue }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <RouterPrompt isBlocking={dirty} />
                    <Row>
                        <Col
                            xs="12"
                            lg="3"
                            xl="2"
                            className="d-flex flex-column align-items-center mb-3 mb-lg-0"
                        >
                            <input
                                hidden
                                ref={inputRef}
                                type="file"
                                accept="image/*"
                                onChange={async (e) => {
                                    if (e.target.files?.[0]) {
                                        getFileWithUrl(e.target.files[0]).then(setFile);
                                    } else {
                                        setFile(undefined);
                                    }
                                    // reset value to allow reading the same file
                                    if (inputRef.current) {
                                        inputRef.current.value = '';
                                    }
                                }}
                            />

                            <PartnerAvatar
                                partner={{
                                    wkpf_id: -1,
                                    wkpf_name: 'Work Profile',
                                    wkpf_profile: null,
                                }}
                                width={140}
                                height={140}
                                src={file?.url}
                                className="mb-3 shadow-sm"
                            />

                            <IconButton
                                transparent
                                Icon={PhotoRounded}
                                label="Upload photo"
                                title="Upload photo"
                                onClick={() => inputRef.current?.click()}
                                className="text-dark"
                                disabled={isLoading}
                            />
                        </Col>

                        <Col xs="12" lg="9" xl="10">
                            <Row className="mb-3 g-3">
                                <Form.Group as={Col} lg>
                                    <BootstrapInput
                                        id="wkpf_name"
                                        label="Organisation Name"
                                        placeholder="Organisation Name"
                                        aria-label="Organisation Name"
                                        disabled={isLoading}
                                    />
                                </Form.Group>
                            </Row>
                            <Row className="mb-3 g-3">
                                <Form.Group as={Col} lg>
                                    <BootstrapInput
                                        id="wkpf_desc"
                                        label="Description"
                                        as="textarea"
                                        rows="2"
                                        placeholder="Organisation Profile Description"
                                        aria-label="Organisation Profile Description"
                                        disabled={isLoading}
                                        required={false}
                                    />
                                </Form.Group>
                            </Row>
                            <Row className="mb-3 g-3">
                                <Form.Group as={Col} lg="9">
                                    <BootstrapInput
                                        id="wkpf_address"
                                        label="Address"
                                        placeholder="Address"
                                        aria-label="Address"
                                        disabled={isLoading}
                                        required={false}
                                    />
                                </Form.Group>

                                <Form.Group as={Col} lg="3">
                                    <BootstrapInput
                                        id="wkpf_postcode"
                                        label="Postal Code"
                                        placeholder="Postal Code"
                                        aria-label="Postal Code"
                                        disabled={isLoading}
                                        required={false}
                                    />
                                </Form.Group>
                            </Row>
                            <Row className="mb-3 g-3">
                                <Form.Group as={Col} lg>
                                    <BootstrapInput
                                        id="wkpf_city"
                                        label="City"
                                        placeholder="City"
                                        aria-label="City"
                                        disabled={isLoading}
                                        required={false}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} lg>
                                    <BootstrapSelectCountry
                                        id="wkpf_country"
                                        label="Country"
                                        disabled={isLoading}
                                        required={false}
                                    />
                                </Form.Group>

                                <Form.Group as={Col} lg>
                                    <BootstrapSelectRegion
                                        id="wkpf_region"
                                        label="Region"
                                        disabled={isLoading}
                                        required={false}
                                        country={values.wkpf_country}
                                    />
                                </Form.Group>
                            </Row>
                            <Row className="mb-3 g-3">
                                <Form.Group as={Col} lg>
                                    <BootstrapInputPhone
                                        id="wkpf_phone"
                                        label="Contact Number"
                                        placeholder="Contact Number"
                                        aria-label="Contact Number"
                                        disabled={isLoading}
                                        required={false}
                                        value={values.wkpf_phone}
                                        onChange={(val) => {
                                            setFieldValue('wkpf_phone', val);
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} lg>
                                    <BootstrapInput
                                        id="wkpf_email"
                                        label="Email"
                                        placeholder="email@address.com"
                                        aria-label="Email"
                                        disabled={isLoading}
                                        required={false}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} lg>
                                    <BootstrapInput
                                        id="wkpf_url"
                                        label="Website"
                                        placeholder="https://example.com"
                                        aria-label="Website"
                                        disabled={isLoading}
                                        required={false}
                                    />
                                </Form.Group>
                            </Row>

                            <hr className="text-secondary" />

                            <Row className="mb-3 g-3">
                                <Form.Group as={Col} lg>
                                    <BootstrapInput
                                        id="wkpf_serviceregions"
                                        as="textarea"
                                        rows="2"
                                        label="State Where Your Services Are Provided (separated with commas)"
                                        placeholder="Enter up to 100 characters"
                                        aria-label="State Where Your Services Are Provided (separated with commas)"
                                        disabled={isLoading}
                                        required={false}
                                    />
                                </Form.Group>
                            </Row>

                            <div className="mb-3">
                                <BootstrapFormLabel htmlFor={undefined}>
                                    Services
                                </BootstrapFormLabel>

                                <Row xs={1} sm={2} xl={3}>
                                    {workProfileServices.map(([id, Icon, label], index) => (
                                        <Service
                                            key={index}
                                            id={id}
                                            Icon={Icon}
                                            label={label}
                                            isSubmitting={isLoading}
                                        />
                                    ))}
                                </Row>
                            </div>

                            <div className="d-flex flex-wrap justify-content-end">
                                {error !== null && (
                                    <p className="text-danger w-100 text-end">{error.message}</p>
                                )}
                                <Button
                                    title="Cancel"
                                    variant="outline-primary"
                                    onClick={() => formCloseHandler(handleClose, dirty)}
                                    className="me-2"
                                    disabled={isLoading}
                                >
                                    Cancel
                                </Button>
                                <Button title="Create" type="submit" disabled={isLoading}>
                                    {isLoading ? 'Creating...' : 'Create'}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>
    );
};

interface ServiceInterface {
    id: string;
    Icon: SvgIconComponent;
    label: string;
    isSubmitting: boolean;
}

const Service = ({ id, Icon, label, isSubmitting }: ServiceInterface) => {
    return (
        <Col className={styles.serviceColItem}>
            <BootstrapCheckbox
                id={id}
                label={<ServiceLabel Icon={Icon} label={label} />}
                disabled={isSubmitting}
                title={label}
            />
        </Col>
    );
};

interface ServiceLabelInterface {
    Icon: SvgIconComponent;
    label: string;
}

const ServiceLabel = ({ Icon, label }: ServiceLabelInterface) => {
    return (
        <Col className={styles.serviceColItem} title={label}>
            <div className="d-inline-flex align-items-center">
                <Icon color="disabled" />
                <span className="ms-1" style={{ color: 'grey' }}>
                    {label}
                </span>
            </div>
        </Col>
    );
};

export default WorkProfileSetupCompany;
