import { Butlerr } from '../types/butlerr';
import { AssetRemindersPriorityKey, AssetRemindersTypeKey } from '../types/butlerr-enums';
import { assetBaseRoute } from './asset.service';
import useButlerrAPI, { ButlerrMutationOptions, useButlerrMutation } from './useButlerrAPI';

export const ReminderQueries = {
    REMINDERS: ['reminders'] as const,
    NOTIFICATIONREMINDERS: ['notifications', 'partner'] as const,
};

export function useReminders(assetId: number) {
    return useButlerrAPI<Butlerr.Asset.Reminder[]>(
        [assetId, ReminderQueries.REMINDERS],
        `${assetBaseRoute}/${assetId}/reminders/`
    );
}

const reminderBaseRoute = '/api/reminders'

export function useNotificationReminders() {
    return useButlerrAPI<Butlerr.Asset.Reminder[]>(ReminderQueries.NOTIFICATIONREMINDERS, `${reminderBaseRoute}`)
}

type ReminderForm = {
    assetId: number;
    reminderId: number;
    remi_date: string;
    remi_msg: string;
    remi_priority: AssetRemindersPriorityKey | '';
    remi_type: AssetRemindersTypeKey | '';
    remi_completed?: number;
}

const ReminderMutations = {
    CREATE: ({
        assetId,
        ...reminder
    }: Omit<ReminderForm, 'reminderId'>): ButlerrMutationOptions => ({
        url: `${assetBaseRoute}/${assetId}/reminders/`,
        method: 'POST',
        requestBody: reminder,
    }),

    EDIT: ({
        assetId,
        reminderId,
        ...reminder
    }: ReminderForm): ButlerrMutationOptions => ({
        url: `${assetBaseRoute}/${assetId}/reminders/${reminderId}`,
        method: 'PUT',
        requestBody: reminder,
    }),

    DELETE: ({
        assetId,
        reminderId,
    }: Pick<ReminderForm, 'assetId' | 'reminderId'>): ButlerrMutationOptions => ({
        url: `${assetBaseRoute}/${assetId}/reminders/${reminderId}`,
        method: 'DELETE',
    }),

    CHANGE: ({
        assetId,
        reminderId,
        remi_completed
    }: Pick<ReminderForm, 'assetId' | 'reminderId' | 'remi_completed'>): ButlerrMutationOptions => ({
        url: `${assetBaseRoute}/${assetId}/reminders/${reminderId}/completion`,
        method: 'PUT',
        requestBody: {remi_completed},
    }),
};

export function useReminderMutations<Key extends keyof typeof ReminderMutations>(
    key: Key,
    assetId: number
) {
    //You won't get type suggestions in the mutate function without this assertion
    const mutationFn = ReminderMutations[key] as (
        params: Parameters<typeof ReminderMutations[Key]>[0]
    ) => ButlerrMutationOptions;
    return useButlerrMutation(mutationFn, [assetId, ReminderQueries.REMINDERS]);
}
