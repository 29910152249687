import { AddCircle } from '@mui/icons-material';
import { useEffect, useMemo, useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';

import { ReactComponent as AssetCashflowIcon } from '../../../assets/icons/asset-cashflow.svg';
import { Butlerr } from '../../../types/butlerr';
import ErrorPage from '../../utils/ErrorPage';
import { convertDateToYYYYMMDD } from '../../utils/HelperFunctions';
import PageHeader from '../../utils/PageHeader';
import { AssetTabContent } from '../Asset';
import AssetCashflowTable from './AssetCashflowTable';
import AssetCashflowsModal from './AssetCashflowsModal';
import IconButton from '../../utils/IconButton';
import AssetCashflowsKPI from './AssetCashflowsKPI';
import { useCashflows } from '../../../services/assetcashflow.service';

interface AssetCashflowsProps {
    asset: Butlerr.Asset;
}

const AssetCashflows = ({ asset }: AssetCashflowsProps) => {
    const assetId = asset.asst_id;
    const assetContractDate = asset.asst_contractdate;

    // filters
    const [rangeFilter, setRangeFilter] = useState<boolean>(false); //false = todate true = range

    const [dateRange, setDateRange] = useState({
        // default to contract date and current date
        start: convertDateToYYYYMMDD(assetContractDate)!,
        end: convertDateToYYYYMMDD(new Date())!,
    });

    const dateStartChange = (date: string) => {
        setDateRange((prev) => {
            return {
                ...prev,
                start: date,
            };
        });
    };

    const dateEndChange = (date: string) => {
        setDateRange((prev) => {
            return {
                ...prev,
                end: date,
            };
        });
    };

    useEffect(() => {
        if (!rangeFilter) {
            setDateRange({
                // default to contract date and current date
                start: convertDateToYYYYMMDD(assetContractDate)!,
                end: convertDateToYYYYMMDD(new Date())!,
            });
        }
    }, [rangeFilter, assetContractDate]);

    // data
    const { data: cashflows, isLoading, isError, error } = useCashflows(assetId);

    const filteredCashflows = useMemo(() => {
        return (
            cashflows
                // filter by month and year
                ?.filter((cashflow) => {
                    const startDate = new Date(dateRange.start).setHours(0, 0, 0, 0);
                    const endDate = new Date(dateRange.end).setHours(0, 0, 0, 0);
                    const cashflowDate = new Date(cashflow.ascf_date).setHours(0, 0, 0, 0);
                    if (
                        cashflowDate.valueOf() >= startDate.valueOf() &&
                        cashflowDate.valueOf() <= endDate.valueOf()
                    ) {
                        return true;
                    }
                    return false;
                }) ?? []
        );
    }, [cashflows, dateRange.start, dateRange.end]);

    // modal states
    const [selectedCashflow, setSelectedCashflow] = useState<Butlerr.Asset.Cashflow>();
    const [showModal, setShowModal] = useState(false);

    const onModalOpen = (cashflow?: Butlerr.Asset.Cashflow) => {
        setSelectedCashflow(cashflow);
        setShowModal(true);
    };
    const onModalClose = () => {
        setShowModal(false);
    };

    return (
        <>
            {/* tab header */}
            <PageHeader Icon={AssetCashflowIcon} title="Cashflow" />

            <AssetTabContent>
                {isError ? (
                    <ErrorPage status={error?.status} message={error?.message} />
                ) : (
                    <>
                        <AssetCashflowsKPI
                            cashflows={filteredCashflows}
                            isLoading={isLoading}
                            asset={asset}
                        />

                        <div
                            className="mb-3 d-flex justify-content-between align-items-center"
                            style={{ minHeight: '2.5rem' }}
                        >
                            <div className="d-flex">
                                <Row className="align-items-center">
                                    <Col
                                        className={
                                            'pe-2 mx-0 my-auto ' +
                                            (!rangeFilter
                                                ? 'text-primary fw-bold '
                                                : 'text-secondary ')
                                        }
                                        style={{ minWidth: '5rem' }}
                                    >
                                        To Date
                                    </Col>
                                    <Col className="w-auto px-0 mx-0 my-auto">
                                        <Form.Switch
                                            onChange={() => setRangeFilter((prev) => !prev)}
                                        />
                                    </Col>
                                    <Col
                                        className={
                                            'ps-0 mx-0 my-auto ' +
                                            (rangeFilter
                                                ? 'text-primary fw-bold '
                                                : 'text-secondary')
                                        }
                                    >
                                        Range
                                    </Col>
                                </Row>

                                {rangeFilter && (
                                    <Row
                                        onClick={(e) => e.stopPropagation()}
                                        className="ms-2 align-items-start"
                                    >
                                        <Col md="auto">
                                            <Form.Control
                                                type="date"
                                                onChange={(e) => dateStartChange(e.target.value)}
                                                value={convertDateToYYYYMMDD(dateRange.start)}
                                            />
                                        </Col>
                                        <Col className="my-auto" md="auto">
                                            {' '}
                                            To
                                        </Col>
                                        <Col md="auto">
                                            <Form.Control
                                                type="date"
                                                onChange={(e) => dateEndChange(e.target.value)}
                                                value={convertDateToYYYYMMDD(dateRange.end)}
                                            />
                                        </Col>
                                    </Row>
                                )}
                            </div>

                            {asset.status !== 'AR' && (
                                <div className="d-flex align-items-center">
                                    <IconButton
                                        Icon={AddCircle}
                                        title="Add A Record"
                                        onClick={() => onModalOpen()}
                                    />
                                </div>
                            )}
                        </div>

                        <AssetCashflowTable
                            assetId={assetId}
                            cashflows={filteredCashflows}
                            isLoading={isLoading}
                            onModalOpen={onModalOpen}
                        />
                    </>
                )}
            </AssetTabContent>

            <AssetCashflowsModal
                show={showModal}
                onModalClose={onModalClose}
                assetId={assetId}
                initialValue={selectedCashflow}
                asset={asset}
            />
        </>
    );
};

export default AssetCashflows;
