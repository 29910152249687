import { useCallback } from "react";
import BootstrapSpinner from "../utils/BootstrapSpinner";

export interface InfiniteScrollerProps {
    fetchNextPage: () => void;
    hasNextPage?: boolean;
    isLoading: boolean;

    children?: React.ReactNode;
    loadingEle?: React.ReactNode;
    observerOptions?: IntersectionObserverInit;
}

export default function InfiniteScroller({ fetchNextPage, hasNextPage, isLoading, children, loadingEle, observerOptions = {} } : InfiniteScrollerProps) {

    const handleFetchNextSpinnerMount = useCallback((node: HTMLDivElement | null) => {
        if (!node) return;

        const options = {
            root: observerOptions.root ?? null,
            rootMargin: observerOptions.rootMargin ?? "0px",
            threshold: observerOptions.threshold ?? 0
        };

        const observer = new IntersectionObserver((entries) => {
            if (entries[0]?.isIntersecting === true && !isLoading && hasNextPage) {
                fetchNextPage();
            }
        }, options);

        observer.observe(node);
    }, [
        isLoading,
        hasNextPage,
        fetchNextPage,
        observerOptions.root,
        observerOptions.rootMargin,
        observerOptions.threshold
    ])

    return (
        <>
            { children }
            <div hidden={isLoading || !hasNextPage} ref={handleFetchNextSpinnerMount}>
                {
                    loadingEle ?? <BootstrapSpinner />
                }
            </div>
        </>
    )
}