import { useAssetInsights } from "../../../services/asset.service";
import { Butlerr } from "../../../types/butlerr";
import BootstrapSpinner from "../../utils/BootstrapSpinner";
import ErrorPage from "../../utils/ErrorPage";
import DomainInsights from "./DomainInsights";
import WalkscoreInsights from "./WalkscoreInsights";

export default function AssetInsights({ asset } : { asset: Butlerr.Asset }) {

    const { data: insights, error } = useAssetInsights(asset.asst_id);

    if (insights === undefined) {
        if (error !== null) {
            return <ErrorPage status={error.status} message={error.message} />
        }
        return <BootstrapSpinner />
    }

    return (
        <div>
            { insights.domain !== undefined && <DomainInsights asset={asset} insights={insights.domain} insightDate={insights.asst_insightdate} /> }
            { insights.walkscore !== undefined && <WalkscoreInsights asset={asset} insights={insights.walkscore} insightDate={insights.asst_insightdate} /> }
        </div>
    )
}