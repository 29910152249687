import { HTMLAttributes } from "react";
import { Card } from "react-bootstrap";
import { Butlerr } from "../../../types/butlerr";
import LineClampText from "../../utils/LineClampText";
import ChannelAvatar from "./ChannelAvatar";

import IconButton from "../../utils/IconButton";
import { useSocialChannelMutations } from "../../../services/social-channels.service";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { createSocialRoute, SocialRoutes } from "../SocialHome";
import styles from '../social.module.css';
import useModal from "../../../hooks/useModal";
import { FollowListModal } from "../network/UserListModal";

interface ChannelItemProps extends HTMLAttributes<HTMLDivElement> {
    channel: Butlerr.Social.Channel;
    allowFollowerModal: boolean;
}

export default function ChannelItem({ channel, allowFollowerModal, ...props } : ChannelItemProps) {

    const { mutate: follow, isLoading: fl } = useSocialChannelMutations('FOLLOW');
    const { mutate: unfollow, isLoading: ul } = useSocialChannelMutations('UNFOLLOW');

    const [ followerModal, showFollowers ] = useModal(FollowListModal, {
        chan_id: channel.chan_id,
        follower: channel.followers
    })

    return (
        <Card {...props}>
            <Card.Body className="d-flex justify-content-between align-items-center p-3">
                <div className="flex-shrink-0 me-3">
                    <ChannelAvatar
                        clickable
                        channel={channel}
                        width={50}
                        height={50}
                    />
                </div>
                <div className="flex-grow-1 d-flex flex-column justify-content-between">
                    <Link
                        to={createSocialRoute(SocialRoutes.CHANNEL_DETAILS, { id: channel.chan_id })}
                        className="link-underline text-dark"
                    >
                        <h6 className="mb-0">{channel.chan_name}</h6>
                    </Link>
                    <LineClampText maxLines={2} className="mb-0 text-muted">{channel.chan_desc}</LineClampText>
                </div>
                <div className="d-flex flex-column align-items-end">
                    <button
                        className="fw-light mb-1 text-truncate text-start p-0 border-0 bg-transparent link-underline text-dark"
                        onClick={showFollowers}
                        disabled={!allowFollowerModal}
                        style={{ opacity: 1 }}
                    >
                        {channel.followers} Follower{channel.followers > 1 && 's'}
                    </button>
                    <IconButton
                        className={classNames(
                            'py-0 border-2 border-primary rounded-pill',
                            styles.followBtn,
                            channel.user_follow !== 1 && `bg-transparent ${styles.grey}`
                        )}
                        label={ channel.user_follow === 1 ? 'Following' : 'Follow' }
                        title={ channel.user_follow === 1 ? 'Following' : 'Follow' }
                        disabled={fl || ul}
                        onClick={() => {
                            if (channel.user_follow === 1) unfollow(channel.chan_id);
                            else follow(channel.chan_id);
                        }}
                    />
                </div>
                { followerModal }
            </Card.Body>
        </Card>
    )
}