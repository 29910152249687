import styles from './landing.module.scss'
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import { KeyboardArrowDownRounded } from '@mui/icons-material';
import { useMemo } from 'react';
import rsLogo from "../../assets/logo_runningstream.png";
import awsLogo from "../../assets/logo_aws.png";

const Landing = () => {

	const { loginWithRedirect } = useAuth0();

	return (
		<div>
			<div className={styles.bg}>
				<div className="w-100 h-100 p-3 d-flex justify-content-between align-items-end">
					<img alt="running stream" src={rsLogo} height={30} />
					<img alt="powered by aws" src={awsLogo} height={25} />
				</div>
			</div>
			<FirstPage />
			<Slides />
			<div className={styles.btnContainer}>
				<Button
					className={styles.signUpBtn}
					onClick={() => loginWithRedirect({ screen_hint: 'signup' })}
				>
					Sign up now!
				</Button>
			</div>
		</div>
	)
}

const Slides = () => {
	// [ image src, desc ]
	const slides = useMemo(() => [
		['/promos/slide1.jpg', <span><b>Post</b> and share your thoughts on our property-centric social space. Or simply follow friends, seasoned investors and experts to stay in touch with their thoughts!</span> ],
		['/promos/slide2.jpg', <span>Stay updated through <b>Channels</b> managed by companies such as developers, market research firms and service providers.</span> ],
		['/promos/slide3.jpg', <span>Explore <b>Network</b> of folks who you can connect to and invite your friends and service partners onto the platform as well to collaborate with you!</span>],
		['/promos/slide4.jpg', <span>Subscribe to popular <b>News</b> to stay updated about the markets around the world!</span>],
		['/promos/slide5.jpg', <span>Get on private and group <b>Chats</b> with folks that you are keen to interact with!</span>],
		['/promos/slide6.jpg', <span>When you are ready, jump off to the <b>Manage</b> section where you can start adding your assets and managing your leases, loans, insurance and much more. Get Insights on your property on estimated valuation, rental and house sale prices in the nearby surburbs.</span>],
		['/promos/slide7.jpg', <span>Do feel free to send us your feedback and questions to service@propcircle.co. Or simply type them into the <b>Support</b> section on Home and we'll chat up on propcircle.co!</span>],
	] as const, [])

	return (
		<Container>
			<Row xs={1}>
				{slides.map(([ img, text ], idx) => (
					<Col key={idx} className={styles.slide}>
						<div className={styles.image}>
							<img
								alt=""
								src={img}
							/>
						</div>
						<div className={styles.text + " text-break text-wrap"}>
							{text}
						</div>
					</Col>
				))}
			</Row>
		</Container>
	)
}

const FirstPage = () => {

	const { loginWithRedirect } = useAuth0();

	const MIN_HEIGHT = 350;

	const handleDownScroll = () => {
		window.scrollTo({
			top: Math.max(window.innerHeight - 75, MIN_HEIGHT),
			behavior: 'smooth'
		})
	}

	return (
		<div className={styles.page} style={{ minHeight: MIN_HEIGHT }}>
			<img
				alt="logo"
				src="/logo_full.png"
				className={styles.logo}
			/>
			<div className={styles.content}>
				<span>
					The world's first and leading
					<br />
					<b>real estate community platform!</b>
				</span>
				<Button
					className={styles.signUpBtn}
					onClick={() => loginWithRedirect({ screen_hint: 'signup' })}
				>
					Sign up now!
				</Button>
				<button className={styles.downArrow} onClick={handleDownScroll}>
					<KeyboardArrowDownRounded fontSize="large" />
				</button>
			</div>
		</div>
	)
}

/* <div className="d-flex flex-wrap align-items-center justify-content-center p-3" style={{ gridGap: '1rem' }}>
	<Button
		onClick={() => loginWithRedirect()}
		variant="outline-primary"
		className="border-2 text-primary"
	>
		Login now
	</Button>
	<Button
		className="mx-2"
		onClick={() => loginWithRedirect({ screen_hint: 'signup' })}
	>
		Get Started
	</Button>
</div> */

export default Landing;
