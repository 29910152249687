import { Formik } from 'formik';
import { Form, Modal, Container, Row, Col, InputGroup, Image } from 'react-bootstrap';
import { Transition } from 'react-transition-group';
import * as yup from 'yup';
import { useRef } from 'react';
import { Add, Done } from '@mui/icons-material';

import { useAssetRoleMutations } from '../../../services/assetrole.service';
import { Butlerr } from '../../../types/butlerr';
import {
    ButlerrAssetRoleType,
    AssetRoleTypeKey,
} from '../../../types/butlerr-enums';
import { formatDate, formCloseHandler } from '../../utils/HelperFunctions';
import {
    BootstrapFormLabel,
    BootstrapInput,
    BootstrapSelect,
} from '../../utils/FormikBootstrapInputs';
import UserDropdownButton from '../../utils/UserDropdownButton'
import BootstrapModalFooter from '../../utils/BootstrapModalFooter';

type RoleForm = {
    users: Pick<Butlerr.Asset.Role, 'user_id' | 'user_socialhandle' | 'user_profile' | 'wkpf_name'>[];
    role_type: AssetRoleTypeKey | ''; // 'PA' etc
    role_desc: string | null;
    role_date: string | null;
};

interface AssetInfoRolesModalProps {
    show: boolean;
    onModalClose: () => void;
    initialValue?: Butlerr.Asset.Role;
    assetId: number;
    asset: Butlerr.Asset;
}

const AssetInfoRolesModal = ({
    show,
    onModalClose,
    assetId,
    initialValue,
    asset,
}: AssetInfoRolesModalProps) => {
    const {
        mutate: createRole,
        isLoading: isCreateLoading,
        error: createError,
    } = useAssetRoleMutations('CREATE', assetId);
    const {
        mutate: editRole,
        isLoading: isEditLoading,
        error: editError,
    } = useAssetRoleMutations('EDIT', assetId);

    const isLoading = initialValue === undefined ? isCreateLoading : isEditLoading;
    const error = initialValue === undefined ? createError : editError;

    const handleClose = () => {
        if (error !== null) error.message = '';
        if (!isLoading) onModalClose();
    };

    const userDropdownButtonRef = useRef<HTMLButtonElement | null>(null);

    // formik
    const allowedKeys = {
        asro_type: Object.keys(ButlerrAssetRoleType).filter(k => k !== 'CO'),
    };
    const allowedLabels = {
        asro_type: Object.values(ButlerrAssetRoleType).filter(v => v !== 'Co-owner'),
    };

    const initialValues: RoleForm = {
        users: initialValue?.user_id && initialValue?.user_socialhandle && initialValue?.user_profile ? [{ user_id: initialValue.user_id, user_socialhandle: initialValue.user_socialhandle, user_profile: initialValue.user_profile,  wkpf_name: initialValue.wkpf_name }] : [],
        role_type: initialValue?.asro_type ?? '', // 'PA' etc
        role_desc: initialValue?.asro_desc ?? '',
        role_date: formatDate(initialValue?.asro_appointed) ?? null,
    };

    const validationSchema = yup.object().shape({
        users: yup
            .array()
            .min(1, 'Please select a user')
            .max(1, 'Please select only 1 user')
            .required('Required'),
        role_type: yup
            .string()
            .required('Required')
            .oneOf(allowedKeys.asro_type),
        role_desc: yup
            .string()
            .max(60, ({ max }) => `Maximum ${max} characters`),
    });

    const onSubmit = (values: RoleForm) => {

        const { users, role_type, role_desc } = values;

        const form = {
            asst_id: assetId,
            asro_type: role_type as AssetRoleTypeKey,
            asro_desc: role_desc
        }

        if (initialValue === undefined) {
            createRole(
                { ...form, user_id: users[0].user_id },
                {
                    onSuccess: handleClose,
                }
            );
        } else {
            editRole(
                { ...form, asro_id: initialValue.asro_id, user_id: users[0].user_id, user_socialhandle: users[0].user_socialhandle, user_profile: users[0].user_profile, wkpf_name: initialValue.wkpf_name },
                {
                    onSuccess: handleClose,
                }
            );
        }
    };

    return (
        <>
            <Transition in={show} timeout={{ exit: 300 }} unmountOnExit>
                {() => (
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        {({ submitForm, dirty, setFieldValue, values, errors, touched, }) => (
                            <Form noValidate>
                                <Modal
                                    show={show}
                                    onHide={formCloseHandler.bind(undefined, handleClose, dirty)}
                                    size="lg"
                                    backdrop={isLoading ? 'static' : undefined}
                                    keyboard={!isLoading}
                                    centered
                                >
                                    <Modal.Header>
                                        <Container>
                                            <Row>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <Modal.Title>
                                                        {initialValue === undefined ? 'Add ' : 'Edit '}
                                                        Team Member
                                                    </Modal.Title>
                                                </div>
                                            </Row>
                                        </Container>
                                    </Modal.Header>

                                    <Modal.Body>
                                        <Container>
                                            {initialValue === undefined && (
                                                <div className="mb-3">
                                                    You can control the access of asset information to team members, based on the roles you assigned: 
                                                    <ul>
                                                        <li>Co-owners: Same access as the asset owner, except for conversations where they are not added. </li>
                                                        <li>All other roles (e.g. developer, property manager) can only view information on the Asset Info, conversations that they are added to, and reminders they sent.</li>
                                                    </ul>
                                                </div>
                                            )}

                                            <Row className="mb-3 g-3">
                                                <Form.Group as={Col} lg>
                                                    <BootstrapFormLabel htmlFor={undefined}>
                                                        Select user to add to team
                                                        <span className="text-danger">*</span>
                                                    </BootstrapFormLabel>
                                                    <InputGroup>
                                                        <Form.Control
                                                            placeholder="Click on dropdown to select user"
                                                            readOnly={initialValue === undefined}
                                                            disabled={initialValue !== undefined}
                                                            value={values.users[0]?.user_socialhandle ?? ""
                                                            }
                                                            onFocus={() => {
                                                                if (initialValue === undefined && userDropdownButtonRef.current !== null) {
                                                                    userDropdownButtonRef.current.click();
                                                                }
                                                            }}
                                                            onClick={e => e.stopPropagation()}
                                                        />

                                                        <UserDropdownButton
                                                            ref={userDropdownButtonRef}
                                                            inviteOption={true}
                                                            selectedUsers={values.users}
                                                            setSelectedUsers={(newVal) => setFieldValue('users', newVal)}
                                                            disabled={initialValue !== undefined || isLoading}
                                                            limit={1}
                                                            searchOptions={{
                                                                has_work_profile: true
                                                            }}
                                                            User={(u) => (
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <Image
                                                                        src={u.user_profile ?? undefined}
                                                                        width={30}
                                                                        height={30}
                                                                        roundedCircle
                                                                        style={{ boxShadow: '0 0 2px black' }}
                                                                        className="ms-2"
                                                                    />
                                                                    <div className="ms-2">
                                                                        <p className="mb-0">{u.user_socialhandle}</p>
                                                                        <small className="text-muted">{u.wkpf_name}</small>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            searchPlaceholderText="Organisation Name or User"
                                                        />
                                                    </InputGroup>
                                                    {touched.users && errors.users !== undefined && (
                                                        <Form.Control.Feedback type="invalid">{errors.users}</Form.Control.Feedback>
                                                    )}
                                                </Form.Group>

                                                <Form.Group as={Col} lg>
                                                    <BootstrapSelect
                                                        id="role_type"
                                                        label="What is the role of the user?"
                                                        placeholder="Role"
                                                        disabled={isLoading}
                                                        allowedKeys={allowedKeys.asro_type}
                                                        allowedLabels={allowedLabels.asro_type}
                                                        required={true}
                                                    />
                                                </Form.Group>

                                                {initialValue !== undefined && (
                                                    <Form.Group as={Col} lg>
                                                        <BootstrapInput
                                                            id="role_date"
                                                            label="Date Created/Edited"
                                                            placeholder="Date"
                                                            aria-label="Date Created/Edited"
                                                            disabled={true}
                                                            required={false}
                                                        />
                                                    </Form.Group>
                                                )}
                                            </Row>
                                            <Row>
                                                <Form.Group as={Col} lg>
                                                    <BootstrapInput
                                                        id="role_desc"
                                                        label="Optional Description"
                                                        placeholder="Add role description"
                                                        aria-label="Description"
                                                        disabled={isLoading}
                                                        required={false}
                                                    />
                                                </Form.Group>
                                            </Row>
                                        </Container>
                                    </Modal.Body>

                                    <BootstrapModalFooter
                                        error={error}
                                        cancelBtnProps={{
                                            onClick: () => formCloseHandler(handleClose, dirty),
                                            disabled: isLoading,
                                        }}
                                        confirmBtnProps={{
                                            Icon: initialValue === undefined ? Add : Done,
                                            label:
                                                initialValue === undefined
                                                    ? isLoading
                                                        ? 'Adding...'
                                                        : 'Add'
                                                    : isLoading
                                                    ? 'Saving...'
                                                    : 'Save',
                                            onClick:
                                                initialValue === undefined
                                                    ? submitForm
                                                    : !dirty 
                                                    ? handleClose
                                                    : submitForm,
                                            disabled: isLoading,
                                        }}
                                    />
                                </Modal>
                            </Form>
                        )}
                    </Formik>
                )}
            </Transition>
        </>
    );
};

export default AssetInfoRolesModal;
