import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { API } from "../../../types/api";
import { Butlerr } from "../../../types/butlerr";
import ButlerrCard from "../../utils/ButlerrCard";
import { KPICard } from "../AssetKPI";

interface WalkscoreInsightsProps {
    asset: Butlerr.Asset;
    insights: API.WalkScore.Insights;
    insightDate: string;
}

export default function WalkscoreInsights({ asset, insights, insightDate } : WalkscoreInsightsProps) {

    if (insights.score === undefined && insights.stops === undefined) {
        return <></>
    }

    return (
        <ButlerrCard items={{ title: "Walkscore" }}>
            <div className="d-flex" style={{ gridGap: '1rem' }}>
                {
                    !!insights.score?.walkscore && (
                        <KPICard
                            className="flex-fill"
                            title="WalkScore"
                            tooltipText="Walk Score measures the walkability of any address based on the distance to nearby places and pedestrian friendliness."
                            data={(
                                <div className="h4">
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={
                                            <Tooltip>
                                                {(() => {
                                                    const score = insights.score.walkscore;
                                                    if (score < 25) return "Almost all errands require a car";
                                                    if (score < 50) return "Most errands require a car";
                                                    if (score < 70) return "Some errands can be accomplished on foot";
                                                    if (score < 90) return "Most errands can be accomplished on foot";
                                                    return "Daily errands do not require a car";
                                                })()}
                                            </Tooltip>
                                        }
                                    >
                                        {({ ref, ...triggerHandler }) => (
                                            <span ref={ref} {...triggerHandler}>
                                                <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href={insights.score.more_info_link}
                                                >
                                                    WalkScore
                                                </a>
                                                <span>
                                                    {'®: ' + insights.score.walkscore}
                                                </span>
                                            </span>
                                        )}
                                    </OverlayTrigger>

                                    <p className="mt-2 mb-0 fw-semibold text-dark">
                                        {insights.score.description}
                                    </p>
                                </div>
                            )}
                        />
                    )
                }
                {
                    !!insights.score?.transit?.score && (
                        <KPICard
                            className="flex-fill"
                            title="Transit Score"
                            tooltipText="Transit Score measures how well a location is served by public transit based on the distance and type of nearby transit lines."
                            data={(
                                <div className="h4">
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={
                                            <Tooltip>
                                                {(() => {
                                                    const score = insights.score.transit.score;
                                                    if (score < 25) return "It is possible to get on a bus";
                                                    if (score < 50) return "A few nearby public transportation options";
                                                    if (score < 70) return "Many nearby public transportation options";
                                                    if (score < 90) return "Transit is convenient for most trips";
                                                    return "World-class public transportation";
                                                })()}
                                            </Tooltip>
                                        }
                                    >
                                        {({ ref, ...triggerHandler }) => (
                                            <span ref={ref} {...triggerHandler}>
                                                <a
                                                    ref={ref}
                                                    {...triggerHandler}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href={insights.score.more_info_link}
                                                >
                                                    TransitScore
                                                </a>
                                                <span>{'®: ' + insights.score.transit?.score}</span>
                                            </span>
                                        )}
                                    </OverlayTrigger>

                                    <p className="mt-2 mb-0 fw-semibold text-dark">
                                        {insights.score.transit.description}{" - "}
                                        {insights.score.transit.summary}
                                    </p>
                                </div>
                            )}
                        />
                    )
                }
            </div>
        </ButlerrCard>
    )
}