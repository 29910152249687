import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { useDebouncedCallback } from 'use-debounce';
import { Formik } from 'formik';
import * as yup from 'yup';

import { useAllWorkProfiles, usePartnerMutations } from '../../../services/partner.service';
import { BootstrapFormLabel, BootstrapInput } from '../../utils/FormikBootstrapInputs';
import { formCloseHandler } from '../../utils/HelperFunctions';
import { IProfileType } from './WorkProfileSetup';
import assetStyles from '../../assets/asset.module.css';
import { Butlerr } from '../../../types/butlerr';

interface StaffForm {
    wpst_position: string;
    wpst_depart: string;
}

interface WorkProfileSetupStaffProps {
    setProfileType: React.Dispatch<React.SetStateAction<IProfileType | undefined>>;
}

const WorkProfileSetupStaff = ({ setProfileType }: WorkProfileSetupStaffProps) => {
    const [activePage, setActivePage] = useState(0);

    const pages = ['search', 'form'];

    const goPrevPage = useCallback((target?: number) => {
        if (target === undefined) {
            setActivePage((p) => Math.max(p - 1, 0));
        } else {
            setActivePage(Math.max(target, 0));
        }
    }, []);

    const goNextPage = useCallback(
        (target?: number) => {
            if (target === undefined) {
                setActivePage((p) => Math.min(p + 1, pages.length - 1));
            } else {
                setActivePage((p) => Math.min(target, pages.length - 1));
            }
        },
        [pages.length]
    );

    // selected work profile details
    const [selectedWorkProfile, setSelectedWorkProfile] = useState<Butlerr.Work.WorkProfile>();

    return (
        <div>
            {activePage === 0 ? (
                <SearchPage
                    selectedWorkProfile={selectedWorkProfile}
                    setSelectedWorkProfile={setSelectedWorkProfile}
                    setProfileType={setProfileType}
                    goNextPage={goNextPage}
                />
            ) : (
                <FormPage
                    selectedWorkProfile={selectedWorkProfile}
                    setProfileType={setProfileType}
                    goPrevPage={goPrevPage}
                />
            )}
        </div>
    );
};

interface SearchPageProps {
    selectedWorkProfile: Butlerr.Work.WorkProfile | undefined;
    setSelectedWorkProfile: React.Dispatch<
        React.SetStateAction<Butlerr.Work.WorkProfile | undefined>
    >;
    setProfileType: React.Dispatch<React.SetStateAction<IProfileType | undefined>>;
    goNextPage: (target?: number | undefined) => void;
}

const SearchPage = ({
    selectedWorkProfile,
    setSelectedWorkProfile,
    setProfileType,
    goNextPage,
}: SearchPageProps) => {
    // search
    const [searchValue, setSearchValue] = useState('');
    const {
        data: workProfiles = [],
        isLoading,
        error,
    } = useAllWorkProfiles(searchValue, !!searchValue);

    const debounced = useDebouncedCallback((value) => {
        setSearchValue(value);
    }, 1000);

    const handleClose = () => {
        if (error !== null) error.message = '';
        if (!isLoading) {
            setProfileType(undefined);
        }
    };

    return (
        <>
            <div className="mb-3">
                <Form.Group className="mb-3">
                    <BootstrapFormLabel htmlFor="setup_wkpf_name">
                        Search for a company
                    </BootstrapFormLabel>
                    <div className="position-relative">
                        <Form.Control
                            name="setup_wkpf_name"
                            placeholder="Work Profile Name"
                            onChange={(e) => debounced(e.target.value)}
                        />
                        {isLoading && (
                            <Spinner
                                animation="border"
                                variant="primary"
                                className="position-absolute"
                                style={{ top: '28%', right: '1%' }}
                                size="sm"
                            />
                        )}
                    </div>
                </Form.Group>
                {workProfiles?.map((workProfile) => (
                    <Form.Check
                        key={workProfile.wkpf_id}
                        id={String(workProfile.wkpf_id)}
                        onClick={() => setSelectedWorkProfile(workProfile)}
                        className={classNames({
                            'rounded ps-2 d-flex align-items-center cursor-pointer': true,
                            [assetStyles.assetsRadio]: true,
                            [assetStyles.checked]:
                                selectedWorkProfile?.wkpf_id === workProfile.wkpf_id,
                        })}
                    >
                        <Form.Check.Input
                            type="radio"
                            className="p-2 mt-0"
                            checked={selectedWorkProfile?.wkpf_id === workProfile.wkpf_id}
                            onChange={() => setSelectedWorkProfile(workProfile)}
                        />
                        <Form.Check.Label className="px-3 py-2 flex-grow-1 d-flex flex-column justify-content-between text-break cursor-pointer">
                            <span>{workProfile.wkpf_name}</span>
                            <span className="text-muted">{workProfile.wkpf_address}</span>
                        </Form.Check.Label>
                    </Form.Check>
                ))}
                {error !== null && <span className="text-danger small">{error.message}</span>}
            </div>

            <div className="d-flex flex-wrap justify-content-end">
                {error !== null && <p className="text-danger w-100 text-end">{error.message}</p>}
                <Button
                    title="Cancel"
                    variant="outline-primary"
                    onClick={() => formCloseHandler(handleClose, selectedWorkProfile !== undefined)}
                    className="me-2"
                    disabled={isLoading}
                >
                    Cancel
                </Button>
                <Button
                    title="Next"
                    onClick={() => goNextPage()}
                    disabled={isLoading || selectedWorkProfile === undefined}
                >
                    {isLoading ? 'Loading...' : 'Next'}
                </Button>
            </div>
        </>
    );
};

interface FormPageProps {
    selectedWorkProfile: Butlerr.Work.WorkProfile | undefined;
    setProfileType: React.Dispatch<React.SetStateAction<IProfileType | undefined>>;
    goPrevPage: (target?: number | undefined) => void;
}

const FormPage = ({ selectedWorkProfile, setProfileType, goPrevPage }: FormPageProps) => {
    const { mutate: requestToJoin, isLoading, error } = usePartnerMutations('REQUEST_JOIN');

    const initialValues: StaffForm = {
        wpst_position: '',
        wpst_depart: '',
    };

    const validationSchema = yup.object({
        wpst_position: yup.string().max(45, ({ max }) => `Maximum ${max} characters`),
        wpst_depart: yup.string().max(45, ({ max }) => `Maximum ${max} characters`),
    });

    const onSubmit = (values: StaffForm) => {
        const { wpst_position, wpst_depart } = values;

        requestToJoin({ wkpf_id: selectedWorkProfile?.wkpf_id ?? -1, wpst_position, wpst_depart });
    };

    const handleClose = () => {
        if (error !== null) error.message = '';
        if (!isLoading) {
            setProfileType(undefined);
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({ dirty, handleSubmit }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <h3>{selectedWorkProfile?.wkpf_name}</h3>
                        <span className="text-muted">{selectedWorkProfile?.wkpf_address}</span>
                    </div>

                    <Row className="mb-3 g-3">
                        <Form.Group as={Col} lg>
                            <BootstrapInput
                                id="wpst_position"
                                label="Position"
                                placeholder="e.g. Manager"
                                aria-label="Position"
                                disabled={isLoading}
                                required={false}
                            />
                        </Form.Group>
                        <Form.Group as={Col} lg>
                            <BootstrapInput
                                id="wpst_depart"
                                label="Department"
                                placeholder="e.g. IT"
                                aria-label="Department"
                                disabled={isLoading}
                                required={false}
                            />
                        </Form.Group>
                    </Row>

                    <div className="d-flex flex-wrap justify-content-end">
                        {error !== null && (
                            <p className="text-danger w-100 text-end">{error.message}</p>
                        )}
                        <Button
                            title="Cancel"
                            type="button"
                            variant="outline-primary"
                            onClick={() => formCloseHandler(handleClose, dirty)}
                            className="me-2"
                            disabled={isLoading}
                        >
                            Cancel
                        </Button>

                        <Button
                            title="Request to join"
                            type="submit"
                            disabled={isLoading || selectedWorkProfile === undefined}
                        >
                            {isLoading ? 'Loading...' : 'Request to join'}
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default WorkProfileSetupStaff;
