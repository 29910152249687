import { useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { Form } from 'react-bootstrap';
import * as yup from 'yup';
import { BootstrapInput } from '../../utils/FormikBootstrapInputs';
import { Butlerr } from '../../../types/butlerr';
import { initialiseAssetObject, useAssetMutations } from '../../../services/asset.service';
import { AssetInfoEditButton, AssetInfoProps } from './AssetInfo';
import RouterPrompt from '../../utils/RouterPrompt';
import { SectionHeader } from '../../utils/SectionHeader';

interface FormValues {
    asst_name?: string;
    asst_code?: string | null;
    asst_estatename?: string | null;
    asst_notes?: string | null;
}

const AssetInfoNotes = ({ asset, isPartner = false }: AssetInfoProps) => {
    const { mutate: editAsset } = useAssetMutations('EDIT');

    // form state
    const [editing, setEditing] = useState(false);

    // Formik props
    const initialiseInitialValues = (asset: Butlerr.Asset) => {
        return {
            asst_notes: asset.asst_notes ?? '',
        };
    };

    let initialValues: FormValues = initialiseInitialValues(asset);

    const validationSchema = yup.object({
        asst_notes: yup.string().max(255, 'Maximum 255 characters').nullable(),
    });

    const closeForm = (actions: FormikHelpers<FormValues>) => {
        setEditing(false);
        actions.resetForm({ values: initialValues });
    };

    const onSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) => {
        // check if any field updated
        let fieldUpdated: boolean = false;
        Object.keys(initialValues).forEach((key) => {
            let value = values[key as keyof typeof values];
            if (initialValues[key as keyof typeof initialValues] !== value) {
                fieldUpdated = true;
            }
        });

        if (fieldUpdated) {
            editAsset(
                {
                    ...initialiseAssetObject(asset),
                    ...values,
                },
                {
                    onSuccess: (_, updatedAsset) => {
                        initialValues = initialiseInitialValues(updatedAsset);
                        closeForm(actions);
                    },
                    onError: (error) => {
                        closeForm(actions);
                        alert(error.message);
                    },
                }
            );
        } else {
            closeForm(actions);
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({ handleSubmit, isSubmitting, resetForm, dirty }) => {
                return (
                    <Form noValidate onSubmit={handleSubmit}>
                        <RouterPrompt isBlocking={dirty} />
                        <SectionHeader
                            title="Remarks/Notes"
                            rightSide={
                                isPartner === false && asset.status !== 'AR' ? (
                                    <AssetInfoEditButton
                                        editing={editing}
                                        isSubmitting={isSubmitting}
                                        setEditing={setEditing}
                                        resetForm={resetForm}
                                    />
                                ) : undefined
                            }
                        />

                        <div title="Remarks/notes">
                            {editing ? (
                                <Form.Group>
                                    <BootstrapInput
                                        as="textarea"
                                        id="asst_notes"
                                        placeholder="Remarks/notes"
                                        aria-label="Remarks/notes"
                                        disabled={isSubmitting}
                                        style={{ height: '5.7rem' }}
                                    />
                                </Form.Group>
                            ) : asset.asst_notes ? (
                                asset.asst_notes
                            ) : (
                                'No remarks/notes available.'
                            )}
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default AssetInfoNotes;
