import { Modal } from "react-bootstrap";
import { BaseModalProps } from "../../../hooks/useModal";
import { useSocialChannelMutations } from "../../../services/social-channels.service";
import { Butlerr } from "../../../types/butlerr";
import IconButton from "../../utils/IconButton";
import { Close, CloseRounded, Delete } from "@mui/icons-material";
import { useHistory } from "react-router";
import { SocialRoutes } from "../SocialHome";

interface ChannelDeleteProps extends BaseModalProps {
    channel: Butlerr.Social.Channel;
}

export default function ChannelDelete({ show, onClose, channel } : ChannelDeleteProps) {

    const { mutate: remove, isLoading, error } = useSocialChannelMutations("DELETE");

    const history = useHistory();

    const handleRemove = () => {
        remove(channel.chan_id, {
            onSuccess: () => {
                history.push(SocialRoutes.CHANNELS);
            }
        })
    }

    return (
        <Modal show={show} onHide={onClose} backdrop={isLoading ? "static" : undefined} keyboard={!isLoading}>
            <Modal.Header>
                <Modal.Title>Delete channel <i>{channel.chan_name}</i>?</Modal.Title>
                <IconButton
                    title="Close modal"
                    Icon={CloseRounded}
                    className="ms-auto"
                    disabled={isLoading}
                    onClick={onClose}
                />
            </Modal.Header>
            <Modal.Body>
                <p>This will remove all the posts, reactions and comments under the channel.</p>
            </Modal.Body>
            <Modal.Footer>
                { error !== null && <p className="text-danger text-truncate w-100">{error.message}</p> }

                <IconButton
                    Icon={Close}
                    iconHtmlColor="var(--danger)"
                    title="Cancel"
                    label="Cancel"
                    onClick={onClose}
                    disabled={isLoading}
                    className="bg-transparent border-danger text-danger"
                />
                <IconButton
                    Icon={Delete}
                    title="Delete"
                    label="Delete"
                    disabled={isLoading}
                    className="bg-danger text-light"
                    onClick={handleRemove}
                />
            </Modal.Footer>
        </Modal>
    )
}