import { Add, Done } from '@mui/icons-material';
import { Formik } from 'formik';
import { useMemo, useState } from 'react';
import { Col, Container, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { Transition } from 'react-transition-group';
import * as yup from 'yup';

import { useAssetConversationMutations } from '../../../services/assetconversation.service';
import { useAssetRoles } from '../../../services/assetrole.service';
import { useUserDetails, useButlerrUser } from '../../../services/user.service';
import { Butlerr } from '../../../types/butlerr';
import BootstrapModalFooter from '../../utils/BootstrapModalFooter';
import DestructiveModal from '../../utils/DestructiveModal';
import { BootstrapInput } from '../../utils/FormikBootstrapInputs';
import { formCloseHandler } from '../../utils/HelperFunctions';
import UserDropdownButton from '../../utils/UserDropdownButton';

type ConversationForm = {
    ascv_subject: string;
    members: Butlerr.Asset.Conversation['members'];
};

interface AssetConversationsModalProps {
    show: boolean;
    onClose: () => void;
    initialValue?: Butlerr.Asset.Conversation;
    assetId: number;
    ownerId: number;
}

const AssetConversationsModal = ({
    show,
    onClose: onModalClose,
    assetId,
    ownerId,
    initialValue,
}: AssetConversationsModalProps) => {
    const {
        mutate: createConversation,
        isLoading: isCreateLoading,
        error: createError,
    } = useAssetConversationMutations('CREATE', assetId);
    const {
        mutate: updateConversation,
        isLoading: isEditLoading,
        error: editError,
    } = useAssetConversationMutations('UPDATE', assetId);

    const { mutateAsync: deleteChat } = useAssetConversationMutations('DELETE', assetId);

    // delete modal
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedConvoId, setSelectedConvoId] = useState<number>();

    const onDelete = (convoId: number) => {
        setSelectedConvoId(convoId);
        setShowDeleteModal(true);
    };

    const onDeleteClose = () => {
        setShowDeleteModal(false);
    };

    const onDeleteConfirm = async () => {
        handleGoBack()
        await deleteChat({ assetId, conversationId: selectedConvoId ?? -1 });
        formCloseHandler(handleClose);
    };

    const isLoading = initialValue === undefined ? isCreateLoading : isEditLoading;
    const error = initialValue === undefined ? createError : editError;

    const { data: ownerdb } = useUserDetails(ownerId, ownerId !== undefined);
    const { data: userdb } = useButlerrUser();

    const handleClose = () => {
        if (error !== null) error.message = '';
        if (!isLoading) onModalClose();
    };

    const { data: partnerUsers = [], isLoading: isPartnerUsersLoading } = useAssetRoles(assetId);

    // filter same user with mutliple roles
    // and filter out current user
    const filteredPartnerUsers = useMemo(() => {
        return partnerUsers.reduce((accu, curr) => {
            if (
                !accu.some((value) => value.user_id === curr.user_id) &&
                curr.user_id !== userdb?.user_id
            ) {
                accu.push(curr);
            }
            return accu;
        }, [] as Butlerr.Asset.Role[]);
    }, [partnerUsers, userdb?.user_id]);

    const initialValues: ConversationForm = {
        ascv_subject: initialValue?.ascv_subject ?? '',
        members: initialValue?.members ?? [],
    };

    const validationSchema = yup.object().shape({
        ascv_subject: yup
            .string()
            .required('Required')
            .max(160, ({ max }) => `Maximum ${max} characters`),
        members: yup.array(),
    });

    const onSubmit = (values: ConversationForm) => {
        if (initialValue === undefined) {
            createConversation(
                { assetId, ...values },
                {
                    onSuccess: () => {
                        handleClose();
                    },
                }
            );
        } else {
            if (initialValue.ascv_id !== undefined)
                updateConversation(
                    {
                        assetId,
                        conversationId: initialValue.ascv_id,
                        ...values,
                    },
                    {
                        onSuccess: () => {
                            handleClose();
                        },
                    }
                );
        }
    };

    const { pathname } = useLocation();
    const history = useHistory();

    const handleGoTo = () => {
        if (pathname.split('/').length === 4) {
            history.push(`/${pathname.split('/')[1]}/${assetId}/info`, { to: 'Teams' });
        } else {
            history.push(`/${pathname.split('/')[1]}/${pathname.split('/')[2]}/${assetId}/info`, {
                to: 'Teams',
            });
        }
    };
    const handleGoBack = () => {
        if (pathname.split('/').length === 4) {
            history.push(`/${pathname.split('/')[1]}/${assetId}/conversations`);
        } else {
            history.push(`/${pathname.split('/')[1]}/${pathname.split('/')[2]}/${assetId}/conversations`, {
                to: 'Teams',
            });
        }
    };

    return (
        <>
            <Transition in={show} timeout={{ exit: 300 }} unmountOnExit>
                {() => (
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        {({ submitForm, setFieldValue, dirty, values }) => (
                            <Form noValidate>
                                <Modal
                                    show={show}
                                    onHide={formCloseHandler.bind(undefined, handleClose, dirty)}
                                    size="lg"
                                    backdrop={isLoading ? 'static' : undefined}
                                    keyboard={!isLoading}
                                    centered
                                >
                                    <Modal.Header>
                                        <Container>
                                            <Row>
                                                <Modal.Title>
                                                    {initialValue === undefined
                                                        ? 'Create a conversation'
                                                        : 'Edit conversation'}
                                                </Modal.Title>
                                            </Row>
                                        </Container>
                                    </Modal.Header>

                                    <Modal.Body>
                                        <Container>
                                            <Row className="mb-3">
                                                <Form.Group as={Col} lg>
                                                    <Form.Label className="fw-bold text-secondary">
                                                        Team Member(s){' '}
                                                        <span className="text-danger">*</span>
                                                    </Form.Label>
                                                    <InputGroup>
                                                        <Form.Control
                                                            placeholder="Select members from team to chat"
                                                            readOnly
                                                            value={
                                                                initialValue === undefined
                                                                    ? // create
                                                                    `You${userdb?.user_id ===
                                                                        ownerdb?.user_id
                                                                        ? ' (Owner)'
                                                                        : `, ${ownerdb?.user_socialhandle} (Owner)`
                                                                    }${values.members.length > 0
                                                                        ? `, ${values.members
                                                                            .map(
                                                                                (m) =>
                                                                                    m.user_socialhandle
                                                                            )
                                                                            .join(', ')}`
                                                                        : ''
                                                                    }`
                                                                    : // edit
                                                                    `You${userdb?.user_id ===
                                                                        ownerdb?.user_id
                                                                        ? ' (Owner)'
                                                                        : `, ${ownerdb?.user_socialhandle} (Owner)`
                                                                    }${values.members.filter(
                                                                        (m) =>
                                                                            !(
                                                                                m.user_id ===
                                                                                userdb?.user_id ||
                                                                                m.user_id ===
                                                                                ownerdb?.user_id
                                                                            )
                                                                    ).length > 0
                                                                        ? `, ${values.members
                                                                            .filter(
                                                                                (m) =>
                                                                                    !(
                                                                                        m.user_id ===
                                                                                        userdb?.user_id ||
                                                                                        m.user_id ===
                                                                                        ownerdb?.user_id
                                                                                    )
                                                                            )
                                                                            .map(
                                                                                (m) =>
                                                                                    m.user_socialhandle
                                                                            )
                                                                            .join(', ')}`
                                                                        : ''
                                                                    }`
                                                            }
                                                        />

                                                        <UserDropdownButton
                                                            disabled={isPartnerUsersLoading}
                                                            users={filteredPartnerUsers}
                                                            selectedUsers={values.members}
                                                            setSelectedUsers={(selectedUsers) => {
                                                                setFieldValue(
                                                                    'members',
                                                                    selectedUsers
                                                                );
                                                            }}
                                                        />
                                                    </InputGroup>

                                                    {userdb?.user_id === ownerdb?.user_id ? (
                                                        <small>
                                                            Team members are created under{' '}
                                                            <u
                                                                className="cursor-pointer text-primary"
                                                                onClick={handleGoTo}
                                                            >
                                                                Asset Info
                                                            </u>
                                                        </small>
                                                    ) : (
                                                        <small>
                                                            Only conversations with{' '}
                                                            <u
                                                                className="cursor-pointer text-primary"
                                                                onClick={handleGoTo}
                                                            >
                                                                Team Members
                                                            </u>{' '}
                                                            can be created.
                                                        </small>
                                                    )}

                                                    <Row className="mt-3">
                                                        <Form.Group as={Col} lg>
                                                            <BootstrapInput
                                                                as="textarea"
                                                                rows="2"
                                                                id="ascv_subject"
                                                                label="What is this conversation about?"
                                                                placeholder="Conversation Subject"
                                                                aria-label="Subject"
                                                                disabled={isLoading}
                                                            />
                                                        </Form.Group>
                                                    </Row>
                                                </Form.Group>
                                            </Row>
                                        </Container>
                                    </Modal.Body>

                                    <BootstrapModalFooter
                                        error={error}
                                        deleteBtnProps={{
                                            onClick: () => onDelete(initialValue?.ascv_id ?? -1),
                                            disabled: isLoading,
                                            className:
                                                initialValue === undefined ? 'd-none' : '',
                                        }}
                                        cancelBtnProps={{
                                            onClick: () => formCloseHandler(handleClose, dirty),
                                            disabled: isLoading,
                                        }}
                                        confirmBtnProps={{
                                            Icon: initialValue === undefined ? Add : Done,
                                            label:
                                                initialValue === undefined
                                                    ? isLoading
                                                        ? 'Creating...'
                                                        : 'Create'
                                                    : isLoading
                                                    ? 'Saving...'
                                                    : 'Save',
                                            onClick:
                                                initialValue === undefined
                                                    ? submitForm
                                                    : !dirty
                                                    ? handleClose
                                                    : submitForm,
                                            disabled: isLoading,
                                        }}
                                    />

                                    
                                </Modal>
                            </Form>
                        )}
                    </Formik>
                )}
            </Transition>

            <DestructiveModal
                show={showDeleteModal}
                onClose={onDeleteClose}
                onConfirm={onDeleteConfirm}
                title="Delete the conversation?"
                description="You will lose all access and records related to this conversation. Are you sure you want to delete?"
                confirmBtnText="Confirm"
                className="modal-layer-1"
                backdropClassName="modal-layer-1"
            />
        </>
    );
};

export default AssetConversationsModal;
