import { useParams } from "react-router";
import PostList from "./PostList";

export default function PostLink() {

    const { id } = useParams<{ id: string }>()

    return (
        <PostList
            emptyMessage="Post not found!"
            post={Number(id) || -1}
        />
    )
}