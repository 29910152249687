import { AddCircle } from '@mui/icons-material';
import { useEffect, useState } from 'react';

import { ReactComponent as AssetInsuranceIcon } from '../../../assets/icons/asset-insurance.svg';
import { useInsurances } from '../../../services/assetinsurance.service';
import { Butlerr } from '../../../types/butlerr';
import ErrorPage from '../../utils/ErrorPage';
import IconButton from '../../utils/IconButton';
import PageHeader from '../../utils/PageHeader';
import { AssetTabContent } from '../Asset';
import AssetInsurancesModal from './AssetInsurancesModal';
import AssetInsurancesTable from './AssetInsurancesTable';

interface AssetInsuranceProps {
    asset: Butlerr.Asset;
}

const AssetInsurances = ({ asset }: AssetInsuranceProps) => {
    const assetId = asset.asst_id;

    // data
    const { data: insurancesData, isLoading, isError, error } = useInsurances(assetId);
    const insurances = insurancesData ?? [];

    // modal states
    const [selectedInsurance, setSelectedInsurance] = useState<Butlerr.Asset.Insurance>();
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        //when insurances are refreshed, update `selectedInsurance` with new data
        setSelectedInsurance((prev) => {
            if (!prev) return prev;
            return insurancesData?.find((i) => i.insu_id === prev.insu_id);
        });
    }, [insurancesData]);

    const onModalOpen = (insurance?: Butlerr.Asset.Insurance) => {
        setSelectedInsurance(insurance);
        setShowModal(true);
    };

    const onModalClose = () => {
        setShowModal(false);
    };

    return (
        <>
            {/* tab header */}
            <PageHeader Icon={AssetInsuranceIcon} title="Insurances" />

            {/* tab content */}
            <AssetTabContent>
                {isError ? (
                    <ErrorPage status={error?.status} message={error?.message} />
                ) : (
                    <>
                        {asset.status !== 'AR' && (
                            <div className="mb-3 d-flex justify-content-end">
                                <IconButton
                                    Icon={AddCircle}
                                    title="Add An Insurance"
                                    onClick={() => onModalOpen()}
                                />
                            </div>
                        )}

                        <AssetInsurancesTable
                            asset={asset}
                            insurances={insurances}
                            isLoading={isLoading}
                            onModalOpen={onModalOpen}
                        />
                    </>
                )}
            </AssetTabContent>

            <AssetInsurancesModal
                show={showModal}
                onModalClose={onModalClose.bind(undefined)}
                initialValue={selectedInsurance}
                asset={asset}
            />
        </>
    );
};

export default AssetInsurances;
