import { Form } from "react-bootstrap";
import styles from "./social.module.css";
import IconButton from "../utils/IconButton";
import { ArticleOutlined, DynamicFeed, ForumOutlined, GroupsRounded, NewspaperRounded, RssFeedRounded } from "@mui/icons-material"; //ArticleOutlined
import { useHistory, useLocation, matchPath } from "react-router-dom";
import { createSocialRoute, SocialRoutes } from "./SocialHome";

import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import React, { useEffect, useMemo, useState } from "react";
import useQuery from "../../hooks/useQuery";
import Sidebar from "../utils/Sidebar";
import UserAvatar from "./UserAvatar";
import { useButlerrUser } from "../../services/user.service";
import { TAB_MAP } from "./SocialSearch";
import LineClampText from "../utils/LineClampText";

export default function SocialSidebar() {

    const history = useHistory();
    const location = useLocation();
    const search = useQuery();

    const [ searchQuery, setSearchQuery ] = useState("")

    const handleSearchQueryChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(ev.target.value);
    }

    const currentTab = useMemo(() => {
        //If already in search path, get from params
        if (matchPath(location.pathname, SocialRoutes.SEARCH) !== null) {
            const _tab = search.get('t') || "";
            if (_tab in TAB_MAP) return _tab  as keyof typeof TAB_MAP;
            else return 'p';
        }

        const tabSearchModes : Record<keyof typeof TAB_MAP, SocialRoutes[]> = {
            p: [SocialRoutes.POSTS, SocialRoutes.POST_DETAILS],
            u: [SocialRoutes.NETWORK, SocialRoutes.PROFILE, SocialRoutes.USER_PROFILE],
            c: [SocialRoutes.CHANNELS, SocialRoutes.CHANNEL_DETAILS],
            a: [SocialRoutes.NEWS],
            l: [SocialRoutes.CHATS],
            ar: [SocialRoutes.ARTICLES]
        };
        return Object.entries(tabSearchModes).find(([, paths]) => 
            paths.some(path => matchPath(location.pathname, path) !== null)
        )?.[0] as keyof typeof TAB_MAP ?? 'p';

    }, [search, location.pathname])

    const currentParams = useQuery();
    const handleShowSearchResults = () => {
        const params = new URLSearchParams(currentParams);
        params.set('t', currentTab);

        if (searchQuery) {
            //search for hashtag
            if (searchQuery.startsWith('#')) {
                params.set('h', searchQuery.slice(1))
            }
            else {
                params.set('q', searchQuery)
            }
        }
        else {
            //remove query & hashtags from params, to redirect back
            params.delete('q');
            params.delete('h');
        }

        history.push({
            pathname: SocialRoutes.SEARCH,
            search: params.toString()
        })
    }

    //when query changes, set search term accordingly
    const query = search.get('q');

    useEffect(() => {
        if (query) setSearchQuery(query);
    }, [query])

    const hashtag = search.get('h')

    useEffect(() => {
        if (hashtag) setSearchQuery('#' + hashtag)
    }, [hashtag])

    const { data: currUser } = useButlerrUser();

    const tabs = [
        [undefined, "My Profile", {
            exact: true,
            to: createSocialRoute(SocialRoutes.PROFILE),
            children: currUser !== undefined ? (
                <>
                    <UserAvatar user={currUser} width={25} height={25} className="me-2 pe-none" />
                    <LineClampText maxLines={1} className="mb-0" style={{ wordBreak: 'break-all' }}>
                        {currUser.user_socialhandle}
                    </LineClampText>
                </>
            ) : (
                <>
                    <div className="skeleton-box rounded-circle" style={{ width: 30, height: 30 }}></div>
                </>
            )
        }],
        'separator',
        [DynamicFeed, "Posts", { exact: true, to: SocialRoutes.POSTS }],
        [RssFeedRounded, "Channels", { exact: true, to: SocialRoutes.CHANNELS }],
        [GroupsRounded, "Network", { to: SocialRoutes.NETWORK }],
        [NewspaperRounded, "News", { to: SocialRoutes.NEWS }],
        [ForumOutlined, "Chats", { to: SocialRoutes.CHATS }],
        [ArticleOutlined, "Articles", { to: SocialRoutes.ARTICLES }]
    ] as const;

    return (
        <div>
            <div className={styles.sidebarSearch}>
                <Form.Control
                    className="shadow"
                    placeholder={`${TAB_MAP[currentTab]}...`}
                    value={searchQuery}
                    onChange={handleSearchQueryChange}
                    onKeyUp={(ev) => {
                        if (ev.key === 'Enter' || ev.keyCode === 13) {
                            handleShowSearchResults()
                        }
                    }}
                />
                <IconButton
                    transparent
                    border={false}
                    Icon={SearchIcon}
                    iconHtmlColor="var(--black)"
                    iconStyles={{ width: 17, height: 17, overflow: 'visible' }}
                    onClick={handleShowSearchResults}
                />
            </div>

            <Sidebar
                tabs={tabs}
                className="my-3"
            />
        </div>
    )
}