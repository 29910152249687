import { Col, Container, Row } from 'react-bootstrap';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useMemo, useState } from 'react';
import {
    HomeWorkOutlined,
    WorkOutlineRounded,
    ArticleOutlined,
    CreditCard,
    LocalOffer,
    EmojiEvents,
    CalendarTodayRounded,
    PeopleRounded,
    RssFeedRounded,
    DeleteOutlined,
} from '@mui/icons-material';

import { usePartnerMutations, useWorkProfiles } from '../../services/partner.service';
import BootstrapSpinner from '../utils/BootstrapSpinner';
import ErrorPage from '../utils/ErrorPage';
import Sidebar from '../utils/Sidebar';
import WorkProfile from './workProfiles/WorkProfile';
import ClientAssets from './clientAssets/PartnerClientAssets';
import { Articles } from './articles/Articles';
import useAppTitle from '../../hooks/useAppTitle';
import WorkProfileTeam from './workProfileTeam/WorkProfileTeam';
import WorkProfileChannels from './workProfileChannels/WorkProfileChannels';
import DestructiveModal from '../utils/DestructiveModal';
import { isUserAdmin } from './workProfiles/AllPartnersPage';

export enum PartnerRoutes {
    WORK_PROFILE = '/work/profile',
    WORK_PROFILE_TEAM = '/work/profile/:wkpfId/team',
    WORK_PROFILE_CHANNELS = '/work/profile/:wkpfId/channels',
    CLIENTASSETS = '/work/assets',
    ARTICLES = '/work/articles',
    ARTICLE = '/articles/:articleId',
    CREATEARTICLE = '/work/articles/template/:templateId',
    EDITARTICLE = '/work/articles/:articleId/template/:templateId',

    /** WIP */
    PRODUCTS = '/work/products',
    ADVERTS = '/work/adverts',
    EVENTS = '/work/events',
    BILLINGS = '/work/events',
}

type TArgs = [PartnerRoutes] | [PartnerRoutes, { [param: string]: string | number }];

export function createPartnerRoute(...args: TArgs) {
    const [path, params] = args;

    if (params === undefined) {
        return path;
    }
    //replace all params with the params passed
    return Object.entries(params).reduce((previousValue: string, [param, value]) => {
        return previousValue.replace(`:${param}`, '' + value);
    }, path);
}

const Work = () => {
    useAppTitle('Work');

    const { data: workProfiles = [], isLoading, error } = useWorkProfiles();
    let isAdmin = false
    if (workProfiles.length !== 0) {
        isAdmin = isUserAdmin(workProfiles[0])
    }

    const currentWorkProfileId = useMemo(() => {
        if (!isLoading && workProfiles.length > 0) {
            return workProfiles[0].wkpf_id;
        }
        return -1;
    }, [isLoading, workProfiles]);

    const noWorkProfile = currentWorkProfileId === -1;

    const pendingApproval = useMemo(() => {
        if (!isLoading && workProfiles.length > 0 && workProfiles[0].wpst_status === 0) {
            return true;
        }
        return false;
    }, [isLoading, workProfiles]);

    // delete work profile
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const { mutate: deletePartner } = usePartnerMutations('DELETE');
    const handleDelete = () => {
        setShowDeleteModal(true);
    };
    const onDeleteClose = () => {
        setShowDeleteModal(false);
    };
    const onDeleteConfirm = () => {
        if (currentWorkProfileId !== -1) {
            deletePartner(
                {
                    wkpf_id: currentWorkProfileId,
                },
                {
                    onError: (error) => {
                        alert(error.message);
                    },
                }
            );
        }
    };

    if (isLoading) return <BootstrapSpinner />;

    if (error !== null) return <ErrorPage status={error.status} message={error.message} />;

    //define tabs for side bar
    const tabs = [
        [
            WorkOutlineRounded,
            'Profile',
            {
                to: PartnerRoutes.WORK_PROFILE,
                exact: true,
            },
            PartnerRoutes.WORK_PROFILE,
        ],
        [
            PeopleRounded,
            'Team',
            {
                to: createPartnerRoute(PartnerRoutes.WORK_PROFILE_TEAM, {
                    wkpfId: currentWorkProfileId,
                }),
            },
            PartnerRoutes.WORK_PROFILE_TEAM,
        ],
        [
            RssFeedRounded,
            'Channels',
            {
                to: createPartnerRoute(PartnerRoutes.WORK_PROFILE_CHANNELS, {
                    wkpfId: currentWorkProfileId,
                }),
            },
            PartnerRoutes.WORK_PROFILE_CHANNELS,
        ],
        [
            HomeWorkOutlined,
            'Client Assets',
            {
                to: createPartnerRoute(PartnerRoutes.CLIENTASSETS),
            },
            PartnerRoutes.CLIENTASSETS,
        ],
        [
            ArticleOutlined,
            'Articles',
            { to: createPartnerRoute(PartnerRoutes.ARTICLES)},
            PartnerRoutes.ARTICLES,
        ],
        [LocalOffer, 'Products', { to: '__wip' }, PartnerRoutes.PRODUCTS],
        [EmojiEvents, 'Adverts', { to: '__wip' }, PartnerRoutes.ADVERTS],
        [CalendarTodayRounded, 'Events', { to: '__wip' }, PartnerRoutes.EVENTS],
        [CreditCard, 'Billings', { to: '__wip' }, PartnerRoutes.BILLINGS],
        'separator',
        [
            DeleteOutlined,
            'Delete',
            {
                active: false,
                onClick: () => handleDelete(),
                className: 'text-danger text-uppercase',
            },
            'DELETE_KEY',
        ],
    ] as const;

    type TTabs = Partial<Record<PartnerRoutes | 'separator' | 'DELETE_KEY', boolean>>;

    const hiddenTabs: TTabs = {
        [PartnerRoutes.WORK_PROFILE]: false,
        [PartnerRoutes.WORK_PROFILE_TEAM]: false,
        [PartnerRoutes.WORK_PROFILE_CHANNELS]: false,
        [PartnerRoutes.CLIENTASSETS]: false,
        [PartnerRoutes.ARTICLES]: false,
        [PartnerRoutes.PRODUCTS]: false,
        [PartnerRoutes.ADVERTS]: false,
        [PartnerRoutes.EVENTS]: false,
        [PartnerRoutes.BILLINGS]: false,
        separator: noWorkProfile || workProfiles[0]?.isOwner !== 1,
        DELETE_KEY: noWorkProfile || workProfiles[0]?.isOwner !== 1,
    };
    const greyedoutTabs: TTabs = {
        [PartnerRoutes.WORK_PROFILE]: false,
        [PartnerRoutes.WORK_PROFILE_TEAM]: noWorkProfile || pendingApproval,
        [PartnerRoutes.WORK_PROFILE_CHANNELS]: noWorkProfile || pendingApproval,
        [PartnerRoutes.CLIENTASSETS]: noWorkProfile || pendingApproval,
        [PartnerRoutes.ARTICLES]: noWorkProfile || pendingApproval,
        [PartnerRoutes.PRODUCTS]: true,
        [PartnerRoutes.ADVERTS]: true,
        [PartnerRoutes.EVENTS]: true,
        [PartnerRoutes.BILLINGS]: true,
        separator: false,
        DELETE_KEY: false,
    };

    //switch route with side bar + content components
    return (
        <Container>
            <Row>
                <Col xs="12" md="2" className="sticky-after-navbar">
                    <Sidebar
                        tabs={tabs
                            .filter((t) => {
                                if (typeof t === 'string') {
                                    return hiddenTabs[t] !== true;
                                }
                                return hiddenTabs[t[3]] !== true;
                            })
                            .map((t) => {
                                if (typeof t === 'string') return t;

                                const isGreyedOut = greyedoutTabs[t[3]] === true;
                                const props = {
                                    ...t[2],
                                    style: {
                                        ...(t[2] as any)?.style,
                                        pointerEvents: isGreyedOut ? 'none' : undefined,
                                        opacity: isGreyedOut ? 0.6 : undefined,
                                    },
                                };
                                return [t[0], t[1], props];
                            })}
                        className="mt-4"
                    />
                </Col>
                <Col xs="12" md="10" className="my-4">
                    <Switch>
                        <Route exact path={PartnerRoutes.WORK_PROFILE}>
                            <WorkProfile wkpfId={currentWorkProfileId} />
                        </Route>
                        <Route path={PartnerRoutes.WORK_PROFILE_TEAM}>
                            <WorkProfileTeam wkpfId={currentWorkProfileId} />
                        </Route>
                        <Route path={PartnerRoutes.WORK_PROFILE_CHANNELS}>
                            <WorkProfileChannels wkpfId={currentWorkProfileId} />
                        </Route>
                        <Route path={PartnerRoutes.CLIENTASSETS}>
                            <ClientAssets isAdmin={isAdmin}/>
                        </Route>
                        <Route path={PartnerRoutes.ARTICLES}>
                            <Articles />
                        </Route>
                        <Redirect to={PartnerRoutes.WORK_PROFILE} />
                    </Switch>
                </Col>
            </Row>

            <DestructiveModal
                show={showDeleteModal}
                onClose={onDeleteClose}
                onConfirm={onDeleteConfirm}
                title="Delete work profile?"
                description="All information related to this work profile will be deleted permanently."
                textToEnterToConfirm="DELETE"
            />
        </Container>
    );
};

export default Work;
