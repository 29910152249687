import { AddOutlined, RemoveOutlined } from '@mui/icons-material';
import classNames from 'classnames';
import { useEffect } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { ITopUsers, useTopContributors } from '../../../services/user.service';
import { useButlerrUser, useUserMutations } from '../../../services/user.service';
import { Butlerr } from '../../../types/butlerr';
import UserAvatar from '../../social/UserAvatar';
import styles from '../header.module.css';
import { useProfileCompeletion } from './OnboardingModal';

const GetConnectedPage = () => {
    const { goNextPage } = useProfileCompeletion();

    const { data: users } = useTopContributors({ sort: 'top_followers', time: 'all_time' });

    const { data: dbUser } = useButlerrUser();

    //Hide current user in case
    const arr =
        users?.users.filter((u) => u.user_id !== dbUser?.user_id).slice(0, 18) ??
        Array<null>(18).fill(null);

    useEffect(() => {
        if (arr.length === 0) {
            goNextPage();
        }
    }, [arr.length, goNextPage]);

    return (
        <>
            <Row>
                <h1 className="text-muted">
                    Get <span className="text-primary">Connected!</span>
                </h1>

                <p>
                    Here are some of our most popular folks that you may be keen to follow. <br />
                    Hover over the pic to see their tagline and click to follow and unfollow them!
                </p>
            </Row>

            <Row className="mb-3 gy-3 gy-lg-4" xs="2" md="4" lg="5" xxl="6">
                {arr.map((user, idx) => {
                    return (
                        <Col key={idx}>
                            {user === null ? (
                                <div
                                    className="skeleton-box rounded-circle flex-shrink-0"
                                    style={{ width: 65, height: 65 }}
                                ></div>
                            ) : (
                                <FollowUserItem user={user} dbUser={dbUser} />
                            )}
                        </Col>
                    );
                })}
            </Row>

            <div>
                <Button onClick={() => goNextPage()}>Next</Button>
            </div>
        </>
    );
};

interface FollowUserItemProps {
    user: ITopUsers['users'][number];
    dbUser: Butlerr.User | undefined;
}

const FollowUserItem = ({ user, dbUser }: FollowUserItemProps) => {
    const { mutate: follow, isLoading: isLoadingF } = useUserMutations('FOLLOW');
    const { mutate: unfollow, isLoading: isLoadingU } = useUserMutations('UNFOLLOW');

    const isLoading = isLoadingF || isLoadingU;

    const following = dbUser?.following.includes(user.user_id) === true;

    const tagline = `${user.user_socialhandle.toUpperCase()}\n${
        user.user_socialoneliner ?? 'No tagline available.'
    }`;

    return (
        <Button
            title={tagline}
            variant="custom"
            className={classNames('border-0 p-0 position-relative', styles.followUserBtn)}
            onClick={() => {
                if (following) {
                    unfollow(user.user_id);
                } else {
                    follow(user.user_id);
                }
            }}
            disabled={isLoading || !dbUser}
        >
            <UserAvatar title={tagline} user={user} width={65} height={65} />

            {following ? (
                <RemoveOutlined
                    fontSize="small"
                    className="te-corner rounded-circle bg-primary text-white"
                />
            ) : (
                <AddOutlined
                    fontSize="small"
                    className="te-corner rounded-circle bg-primary text-white"
                />
            )}
        </Button>
    );
};

export default GetConnectedPage;
