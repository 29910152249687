import { Formik } from 'formik';
import { Form, Row, Col, Button } from 'react-bootstrap';
import * as yup from 'yup';
import { useUserMutations } from '../../../services/user.service';
import {
    ButlerrUserSelfDescribe,
    ButlerrCountries,
    ButlerrUserGender,
} from '../../../types/butlerr-enums';
import {
    BootstrapInput,
    BootstrapInputPhone,
    BootstrapRadio,
    BootstrapSelect,
} from '../../utils/FormikBootstrapInputs';
import { range } from '../../utils/HelperFunctions';
import { ProfileForm, useProfileCompeletion } from './OnboardingModal';

const SocialPage = () => {
    const {
        profileForm,
        setProfileForm,
        goPrevPage,
        goNextPage,
        isLoading: isGlobalLoading,
    } = useProfileCompeletion();

    const {
        mutate: createUser,
        isLoading: isCreateLoading,
        error: createError,
    } = useUserMutations('CREATE');

    const allowedKeys = {
        user_gender: Object.keys(ButlerrUserGender),
        user_socialselfdescribe: Object.keys(ButlerrUserSelfDescribe),
    };

    const allowedLabels = {
        user_gender: Object.values(ButlerrUserGender),
        user_socialselfdescribe: Object.values(ButlerrUserSelfDescribe),
    };

    const initialValues: Pick<
        ProfileForm,
        | 'user_mobileno'
        | 'user_birthyear'
        | 'user_gender'
        | 'user_socialselfdescribe'
        | 'user_socialoneliner'
        | 'user_socialbio'
        | 'user_preflocations'
        | 'user_prefkeywords'
    > = {
        user_mobileno: profileForm.user_mobileno,
        user_birthyear: profileForm.user_birthyear,
        user_gender: profileForm.user_gender,
        user_socialselfdescribe: profileForm.user_socialselfdescribe,
        user_socialoneliner: profileForm.user_socialoneliner,
        user_socialbio: profileForm.user_socialbio,
        user_preflocations: profileForm.user_preflocations,
        user_prefkeywords: profileForm.user_prefkeywords,
    };

    const validationSchema = yup.object().shape({
        user_mobileno: yup.string().max(15, ({ max }) => `Maximum ${max} characters`),
        user_birthyear: yup
            .number()
            .oneOf(
                [0, ...range(new Date().getFullYear() - 150, new Date().getFullYear() - 18, 1)],
                'Invalid year'
            ),
        user_gender: yup.string().oneOf(allowedKeys.user_gender),
        user_socialselfdescribe: yup
            .string()
            .oneOf(allowedKeys.user_socialselfdescribe, 'Invalid experience level'),
        user_socialoneliner: yup.string().max(80, ({ max }) => `Maximum ${max} characters`),
        user_socialbio: yup.string().max(255, ({ max }) => `Maximum ${max} characters`),
        user_preflocations: yup.string().max(255, ({ max }) => `Maximum ${max} characters`),
        user_prefkeywords: yup.string().max(255, ({ max }) => `Maximum ${max} characters`),
    });

    const onSubmit = (
        values: Pick<
            ProfileForm,
            | 'user_mobileno'
            | 'user_birthyear'
            | 'user_gender'
            | 'user_socialselfdescribe'
            | 'user_socialoneliner'
            | 'user_socialbio'
            | 'user_preflocations'
            | 'user_prefkeywords'
        >
    ) => {
        const newProfileForm = {
            ...profileForm,
            ...values,
        };

        setProfileForm(newProfileForm);

        // create user record
        const { purpose, user_country, ...form } = newProfileForm;

        createUser(
            {
                ...form,
                user_country: user_country as keyof typeof ButlerrCountries,
            },
            {
                onSuccess: () => {
                    goNextPage();
                },
            }
        );
    };

    const isLoading = isGlobalLoading || isCreateLoading;

    return (
        <>
            <Row>
                <h1 className="text-muted">
                    Setup Your <span className="text-primary">Additional Profile</span>
                </h1>

                <p>
                    You may tell us more to allow us to serve you better. This step is entirely
                    optional.
                </p>
            </Row>

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({ values, isValid, setFieldValue, handleSubmit }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Row className="mb-3 g-3">
                            <Form.Group as={Col} lg>
                                <BootstrapInputPhone
                                    id="user_mobileno"
                                    label="Mobile No"
                                    placeholder="Mobile No"
                                    aria-label="Mobile No"
                                    disabled={isLoading}
                                    required={false}
                                    value={values.user_mobileno}
                                    onChange={(val) => {
                                        setFieldValue('user_mobileno', val);
                                    }}
                                />
                            </Form.Group>
                            <Form.Group as={Col} lg>
                                <BootstrapInput
                                    id="user_birthyear"
                                    label="Birth Year"
                                    type="number"
                                    disabled={isLoading}
                                    required={false}
                                />
                            </Form.Group>
                            <Form.Group as={Col} lg>
                                <BootstrapSelect
                                    id="user_gender"
                                    label="Gender"
                                    allowedKeys={allowedKeys.user_gender}
                                    allowedLabels={allowedLabels.user_gender}
                                    disabled={isLoading}
                                    required={false}
                                />
                            </Form.Group>
                        </Row>

                        <Row className="mb-3 g-3">
                            <Form.Group as={Col} lg>
                                <BootstrapRadio
                                    inline
                                    name="user_socialselfdescribe"
                                    label="Your Property Experience"
                                    values={allowedKeys.user_socialselfdescribe}
                                    labels={allowedLabels.user_socialselfdescribe}
                                    disabled={isLoading}
                                    required={false}
                                />
                            </Form.Group>
                        </Row>

                        <Row className="mb-3 g-3">
                            <Form.Group as={Col} lg>
                                <BootstrapInput
                                    id="user_socialoneliner"
                                    label="Give Yourself A Tagline"
                                    placeholder="Tagline"
                                    aria-label="Give Yourself A Tagline"
                                    disabled={isLoading}
                                    required={false}
                                />
                            </Form.Group>
                        </Row>

                        <Row className="mb-3 g-3">
                            <Form.Group as={Col} lg>
                                <BootstrapInput
                                    id="user_socialbio"
                                    label="And A Detailed Bio If You Like"
                                    placeholder="Tell us more"
                                    aria-label="And A Detailed Bio If You Like"
                                    disabled={isLoading}
                                    required={false}
                                    as="textarea"
                                    rows="3"
                                    style={{ resize: 'none' }}
                                />
                            </Form.Group>
                        </Row>

                        <Row className="mb-3 g-3">
                            <Form.Group as={Col} lg>
                                <BootstrapInput
                                    id="user_preflocations"
                                    label="Your Preferred Markets"
                                    placeholder="Australia, UK, New Zealand, Singapore..."
                                    aria-label="Your Preferred Markets"
                                    disabled={isLoading}
                                    required={false}
                                />
                            </Form.Group>
                        </Row>

                        {/* <Row className="mb-3 g-3">
                                    <Form.Group as={Col} lg>
                                        <BootstrapInput
                                            id="user_prefkeywords"
                                            label="Your Preferred Topics"
                                            placeholder="Property Value, Rental, Employment..."
                                            aria-label="Your Preferred Topics"
                                            disabled={isLoading}
                                            required={false}
                                        />
                                    </Form.Group>
                                </Row> */}

                        <Button
                            variant="outline-primary"
                            className="me-2"
                            disabled={isLoading}
                            onClick={() => {
                                // save current page data
                                setProfileForm((prev) => ({
                                    ...prev,
                                    ...values,
                                }));

                                goPrevPage();
                            }}
                        >
                            Back
                        </Button>

                        <Button type="submit" disabled={!isValid || isLoading}>
                            Next
                        </Button>

                        {createError !== null && (
                            <p className="w-100 text-danger">{createError.message}</p>
                        )}
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default SocialPage;
