import { AddCircle, PriceCheckOutlined } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useValuations } from '../../../services/assetvaluation.service';
import { Butlerr } from '../../../types/butlerr';
import ErrorPage from '../../utils/ErrorPage';
import PageHeader from '../../utils/PageHeader';
import { AssetTabContent } from '../Asset';
import AssetValuationsTable from './AssetValuationsTable';
import AssetValuationsKPI from './AssetValuationsKPI';
import { AssetValuationsModal } from './AssetValuationsModal';
import IconButton from '../../utils/IconButton';

interface AssetValuationsInterface {
    asset: Butlerr.Asset;
}

const AssetValuations = ({ asset }: AssetValuationsInterface) => {
    const assetId = asset.asst_id;

    // data
    const { data: valuationsData, isLoading, isError, error } = useValuations(assetId);
    const valuations = valuationsData ?? [];

    useEffect(() => {
        // if valuations get updated, update the selected valuation as well
        setSelectedValuation((prev) => {
            if (!prev) return prev;
            return valuationsData?.find((v) => v.valu_id === prev.valu_id);
        });
    }, [valuationsData]);

    //modal states
    const [selectedValuation, setSelectedValuation] = useState<Butlerr.Asset.Valuation>();
    const [showModal, setShowModal] = useState(false);

    const onModalOpen = (valuation?: Butlerr.Asset.Valuation) => {
        setSelectedValuation(valuation);
        setShowModal(true);
    };
    const onModalClose = () => {
        setShowModal(false);
    };

    return (
        <>
            {/* tab header */}
            <PageHeader Icon={PriceCheckOutlined} title="Valuations" />

            <AssetTabContent>
                {isError ? (
                    <ErrorPage status={error?.status} message={error?.message} />
                ) : (
                    <>
                        <AssetValuationsKPI
                            asset={asset}
                            valuations={valuations}
                            isLoading={isLoading}
                        />

                        {asset.status !== 'AR' && (
                            <div className="mb-3 d-flex justify-content-end">
                                <IconButton
                                    Icon={AddCircle}
                                    title="Add A Valuation"
                                    onClick={() => onModalOpen()}
                                />
                            </div>
                        )}

                        <AssetValuationsTable
                            asset={asset}
                            valuations={valuations}
                            isLoading={isLoading}
                            onModalOpen={onModalOpen}
                        />
                    </>
                )}
            </AssetTabContent>

            <AssetValuationsModal
                show={showModal}
                onModalClose={onModalClose}
                assetId={assetId}
                initialValue={selectedValuation}
                asset={asset}
            />
        </>
    );
};

export default AssetValuations;
