import { AppState, useAuth0, Auth0Provider } from '@auth0/auth0-react';
import { Switch, Route, Redirect, RouteProps, useLocation, useHistory } from 'react-router-dom';
import Header from './components/header/Header';
import Loading from './components/Loading';
import { lazy, Suspense, useCallback, useEffect } from 'react';
import BootstrapSpinner from './components/utils/BootstrapSpinner';
import { PartnerRoutes } from './components/partners/Work';
import { Article } from './components/partners/articles/Article';
import ButlerrQueryProvider from './services/ButlerrQueryProvider';

import Landing from './components/landing/Landing';
import SocialHome from './components/social/SocialHome';
const Assets = lazy(() => import('./components/assets/Assets'))
const PortfolioHome = lazy(() => import('./components/portfolio/PortfolioHome'))
const Documents = lazy(() => import('./components/documents/Docs'))
const Profile = lazy(() => import('./components/profile/Profile'))
const Work = lazy(() => import('./components/partners/Work'))


const App = () => {
	const domain = String(process.env.REACT_APP_AUTH0_DOMAIN);
	const clientId = String(process.env.REACT_APP_AUTH0_CLIENT_ID);
	const audience = String(process.env.REACT_APP_AUTH0_AUDIENCE);

	const history = useHistory();

	const handleRedirectCallback = useCallback((appState?: AppState) => {
		history.replace(appState?.target || "/");
	}, [history])

	return (
		<Auth0Provider
			domain={domain}
			clientId={clientId}
			redirectUri={window.location.origin}
			audience={audience}
			scope="openid profile email"
			cacheLocation="localstorage"
			useRefreshTokens={true}
			onRedirectCallback={handleRedirectCallback}
		>
			<ButlerrQueryProvider>
				<AppRouter />
			</ButlerrQueryProvider>
		</Auth0Provider>
	)
}

export default App;

const AppRouter = () => {
	const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

	const location = useLocation();

	useEffect(() => {
		if (isLoading || isAuthenticated) return;
		//If the path isn't the base path, automatically redirect to login
		const currentPath = location?.pathname;
		if (currentPath && currentPath !== "/") {
			loginWithRedirect({
				appState: {
					target: currentPath
				}
			})
		}
	}, [isLoading, isAuthenticated, location?.pathname, loginWithRedirect])

  	if (isLoading) return <Loading />;

	return (
		<>
			<Header />

			<div style={{ paddingTop: 'var(--navbar-height)' }}>
				<Suspense fallback={(
					<BootstrapSpinner />
				)}>
					<Switch>
						{/* `/` route shows Landing for no authenticated & redirects to `/home` when authenticated */}
						<AuthenticatedRoute exact path="/">
							<Redirect to="/home" />
						</AuthenticatedRoute>

						<AuthenticatedRoute path="/home" component={SocialHome} />
						<AuthenticatedRoute path="/assets" component={Assets} />
						<AuthenticatedRoute path="/portfolios" component={PortfolioHome} />
						<AuthenticatedRoute path="/documents" component={Documents} />
						<AuthenticatedRoute path="/profile" component={Profile} />
						<AuthenticatedRoute path="/work" component={Work} />
						<AuthenticatedRoute path={PartnerRoutes.ARTICLE} component={Article} />

						{/* if no matching route found, redirect to home */}
						<AuthenticatedRoute path="*">
							<Redirect to="/" />
						</AuthenticatedRoute>
					</Switch>
				</Suspense>
			</div>
		</>
	);
}

function AuthenticatedRoute(props: RouteProps) {
	const { isAuthenticated } = useAuth0();

	//Render a new route when the state changes, to force Route to unmount and mount a new instance, instead of just updating the props
	const componentKey = Number(isAuthenticated);

	if (!isAuthenticated) {
		return (
			<Route key={componentKey} component={Landing} />
		)
	}

	return (
		<Route key={componentKey} {...props} />
	);
}

type TArgs =
	|	[string]
	|	[string, { [param: string]: string | number }]

export function createRoute(...args: TArgs) {
    const [ path, params ] = args;

    if (params === undefined) {
        return path;
    }
    //replace all params with the params passed
    return Object.entries(params).reduce((previousValue: string, [param, value]) => {
        return previousValue.replace(`:${param}`, '' + value)
    }, path);
}