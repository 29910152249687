import { useCallback, useEffect, useState } from 'react';
import { Row, Col, Card, Button } from 'react-bootstrap';
import { useSocialChats } from '../../../services/chat.service';
import { Butlerr } from '../../../types/butlerr';
import ErrorPage from '../../utils/ErrorPage';
import BootstrapSpinner from '../../utils/BootstrapSpinner';
import EmptyMessage from '../../utils/EmptyMessage';
import SocialChatsSidebar from './ChatsSidebar';
import SocialChatsContent from './ChatsContent';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import SocialChatsModal from './ChatsModal';

export const SocialChats = () => {

    const [selectedChatId, setSelectedChatId] = useState<number>();

    let { chatId } = useParams<{ chatId: string }>();
    const history = useHistory();
    const { pathname } = useLocation();

    const handleChatChange = useCallback((chatId: string) => {
        const newPathname = `/${pathname.split('/')[1]}/${pathname.split('/')[2]}/${chatId}`;
        history.push(newPathname);
    }, [history, pathname]);

    useEffect(() => {
        if (selectedChatId !== undefined) {
            handleChatChange(selectedChatId?.toString())
        }
        // eslint-disable-next-line
    }, [selectedChatId])

    useEffect(() => {
        if (chatId !== undefined) {
            setSelectedChatId(parseInt(chatId))
        }
    }, [chatId])

    return (
        <>
            {/* <IconButton Icon={AddCircle} title="Create A Chat" onClick={() => onModalOpen()} className="ms-auto m-1"/> */}

            {/* tab content */}

            <Card
                className="border-0 shadow rounded-4 overflow-hidden"
            >
                <Card.Body className="p-0" style={{ height: 'calc(100vh - var(--navbar-height) - 1.5rem - 48px - 1.5rem)' }}>
                    <SocialChatsCardContent selectedChatId={selectedChatId} setSelectedChatId={setSelectedChatId} />
                </Card.Body>
            </Card>
        </>
    );
};

interface SocialChatsCardContentProps {
    selectedChatId: number | undefined,
    setSelectedChatId: React.Dispatch<React.SetStateAction<number | undefined>>
}

export const SocialChatsCardContent = ({ selectedChatId, setSelectedChatId }: SocialChatsCardContentProps) => {

    // data
    const { data: chats = [], isLoading, error } = useSocialChats();
    
    // add modal states
    const [showAddModal, setShowAddModal] = useState(false);

    const onAddModalOpen = () => {
        setShowAddModal(true);
    };

    const onAddModalClose = () => {
        setShowAddModal(false);
    };

    return (
        <>
            {error !== null ? (
                <ErrorPage status={error.status} message={error.message} />
            ) : isLoading ? (
                <BootstrapSpinner />
            ) : (
                <>
                    {chats.length === 0 ? (
                        <>
                            <EmptyMessage className="py-3" message="No chats yet." />
                            <div className="d-flex flex-wrap align-items-center justify-content-center p-3" style={{ gridGap: '1rem' }}>
                                <Button
                                    onClick={() => onAddModalOpen()}
                                    variant="outline-primary"
                                    className="border-2 text-primary"
                                >
                                    Create a chat
                                </Button>
                            </div>
                        </>
                    ) : (
                        <ChatsContainer
                            chats={chats}
                            selectedChatId={selectedChatId}
                            setSelectedChatId={setSelectedChatId}
                        />
                    )}
                    <SocialChatsModal
                        show={showAddModal}
                        onClose={onAddModalClose}
                    />
                </>
            )}
        </>
    )
}

interface ChatsContainerProps {
    chats: Butlerr.Social.Chats[],
    selectedChatId: number | undefined,
    setSelectedChatId: React.Dispatch<React.SetStateAction<number | undefined>>
}

const ChatsContainer = ({ chats, selectedChatId, setSelectedChatId }: ChatsContainerProps) => {

    //save db time to here, pass it into SocialChatContent
    //run API to get chatId of msgs that haven't been seen, pass into SocialChatSidebar

    // This is needed for when user leaves the chat
    // and to retrieve the leave date (acmb_leave)
    useEffect(() => {
        setSelectedChatId((prev) => {
            //if previously selected, select a new copy of the chat from new data
            if (prev) {
                return chats.find((c) => c.ascv_id === prev)?.ascv_id;
            }
            return prev;
        });
        // eslint-disable-next-line
    }, [chats]);

    const selectedChat = chats.find((c) => c.ascv_id === selectedChatId)

    return (
        <>
            <Row className="h-100">
                {/* sidebar */}
                <Col xs={4} className="border-end pe-0 overflow-auto h-100">
                    <SocialChatsSidebar
                        chats={chats}
                        selectedChatId={selectedChatId}
                        setSelectedChatId={setSelectedChatId}
                    />
                </Col>

                {/* content */}
                <Col xs={8} className="ps-0 d-flex flex-column h-100">
                    {selectedChat === undefined ? (
                        <>
                            <EmptyMessage className="py-3" message="No chat selected." />
                        </>
                    ) : (
                        <SocialChatsContent
                            selectedChat={selectedChat}
                        />
                    )}
                </Col>
            </Row>
        </>
    );
};

export default SocialChats;
