import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useChannels } from '../../../services/news.service';
import { ChannelList, ChannelViews } from '../../social/news/ChannelsModal';
import EmptyMessage from '../../utils/EmptyMessage';
import ErrorPage from '../../utils/ErrorPage';
import { useProfileCompeletion } from './OnboardingModal';

const NewsChannelsPage = () => {
    const { goPrevPage, goNextPage } = useProfileCompeletion();

    const { data: channels, isLoading, error, refetch } = useChannels({ limit: 8 });

    return (
        <>
            <Row>
                <h1 className="text-muted">
                    Setup Your <span className="text-primary">News Subscriptions!</span>
                </h1>

                <p>
                    Here are some of the more popular news channels that you might be keen to follow
                    to receive their latest market news!
                </p>
            </Row>

            {channels === undefined ? (
                isLoading ? (
                    <Row className="mb-3 gy-3 gy-lg-4" xs="2" md="4">
                        {Array<null>(8)
                            .fill(null)
                            .map((_, idx) => (
                                <Col key={idx}>
                                    <div
                                        className="skeleton-box flex-shrink-0"
                                        style={{ width: 130, height: 207 }}
                                    ></div>
                                </Col>
                            ))}
                    </Row>
                ) : error ? (
                    <ErrorPage message={error.message} handleRetry={refetch} />
                ) : (
                    <EmptyMessage message="Nothing to see here" />
                )
            ) : (
                <ChannelList channels={channels} view={ChannelViews.GRID} />
            )}

            <div>
                <Button
                    variant="outline-primary"
                    className="me-2"
                    disabled={isLoading}
                    onClick={() => goPrevPage()}
                >
                    Back
                </Button>

                <Button onClick={() => goNextPage()}>Next</Button>
            </div>
        </>
    );
};

export default NewsChannelsPage;
