import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { Butlerr } from '../types/butlerr';
import { assetBaseRoute } from './asset.service';
import { DocumentQueries } from './doc.service';
import useButlerrAPI, { ButlerrMutationOptions, useButlerrMutation } from './useButlerrAPI';

export const AssetConversationQueries = {
    CONVERSATIONS: (assetId: number) => ['asset', assetId, 'conversations'] as const,
    MEMBERS: ['conversations', 'members'] as const,
    MESSAGES: (assetId: number, conversationId: number) =>
        ['asset', assetId, 'conversation', conversationId, 'messages'] as const,
    PARTNERCONVERSATIONS: () => ['partner', 'conversations'] as const,
    NOTIFICATIONCONVERSATIONS: ['notification', 'conversations'] as const,
};

export const notificationBaseRoute = '/api/notification';
// export function useNotificationConversations() {
//     return useButlerrAPI<Butlerr.Asset.Conversation[]>(
//         AssetConversationQueries.NOTIFICATIONCONVERSATIONS(),
//         `${notificationBaseRoute}/conversations`
//     );
// }

export function useAssetConversations(assetId: number) {
    return useButlerrAPI<Butlerr.Asset.Conversation[]>(
        AssetConversationQueries.CONVERSATIONS(assetId),
        `${assetBaseRoute}/${assetId}/conversations`,
        true,
        {
            staleTime: 0, // Data is always outdated
            refetchInterval: 1000 * 30, // Refetch every 30 seconds
            refetchOnMount: 'always', // Refetch whenever user comes to the page
        }
    );
}

export const convoBaseRoute = '/api/conversations';

export function useUnreadConversations() {
    return useButlerrAPI<Butlerr.Asset.Conversation[]>(
        AssetConversationQueries.NOTIFICATIONCONVERSATIONS,
        `${convoBaseRoute}`
    );
}

export function useAssetConvoMsgs(assetId: number, conversationId: number) {
    return useButlerrAPI<Butlerr.Asset.ConversationMessage[]>(
        AssetConversationQueries.MESSAGES(assetId, conversationId),
        `${assetBaseRoute}/${assetId}/conversations/${conversationId}/messages`,
        true,
        {
            staleTime: 0, // Data is always outdated
            refetchInterval: 1000 * 30, // Refetch every 30 seconds
            refetchOnMount: 'always', // Refetch whenever user comes to the page
        }
    );
}

interface ConversationMutationInterface extends Butlerr.Asset.Conversation {
    assetId: number;
    conversationId: number;
}

const ConversationMutations = {
    CREATE: ({
        assetId,
        ...body
    }: Omit<
        ConversationMutationInterface,
        | 'conversationId'
        | 'ascv_id'
        | 'asst_id'
        | 'ascv_datetime'
        | 'latestMessage'
        | 'user_id'
        | 'user_socialhandle'
        | 'user_profile'
        | 'acla_lastAccess'
        | 'acmb_leave'
    >): ButlerrMutationOptions => ({
        url: `${assetBaseRoute}/${assetId}/conversations`,
        method: 'POST',
        requestBody: {
            ...body,
            members: body.members.map((m) => m.user_id),
        },
    }),
    UPDATE: ({
        assetId,
        conversationId,
        ...body
    }: Pick<
        ConversationMutationInterface,
        'assetId' | 'conversationId' | 'ascv_subject' | 'members'
    >): ButlerrMutationOptions<Butlerr.Asset.Conversation[]> => ({
        url: `${assetBaseRoute}/${assetId}/conversations/${conversationId}`,
        method: 'PUT',
        requestBody: {
            ...body,
            members: body.members.map((m) => m.user_id),
        },
        updater: [{
            queryKey: AssetConversationQueries.CONVERSATIONS(assetId),
            optimistic: true,
            action: (prev) => {
                return (
                    prev?.map((c) => {
                        if (c.ascv_id !== conversationId) return c;
                        return {
                            ...c,
                            ...body,
                        };
                    }) ?? []
                );
            },
        }, {
            action: 'refetch',
            queryKey: AssetConversationQueries.MESSAGES(assetId, conversationId)
        }]
    }),
    DELETE: ({
        assetId,
        conversationId,
    }: Pick<ConversationMutationInterface, 'assetId' | 'conversationId'>): ButlerrMutationOptions<
        Butlerr.Asset.Conversation[]
    > => ({
        url: `${assetBaseRoute}/${assetId}/conversations/${conversationId}/`,
        method: 'DELETE',
        updater: {
            action: (prev) => prev?.filter((c) => c.ascv_id !== conversationId) ?? [],
            optimistic: true,
        },
    }),
    LEAVE: ({
        assetId,
        conversationId,
    }: Pick<ConversationMutationInterface, 'assetId' | 'conversationId'>): ButlerrMutationOptions<
        Butlerr.Asset.Conversation[]
    > => ({
        url: `${assetBaseRoute}/${assetId}/conversations/${conversationId}/members`,
        method: 'DELETE',
        updater: [
            {
                queryKey: AssetConversationQueries.CONVERSATIONS(assetId),
                action: 'refetch'
            }, 
            {
                queryKey: AssetConversationQueries.MESSAGES(assetId, conversationId),
                action: 'refetch'
            }
        ]
    }),
};

export function useAssetConversationMutations<Key extends keyof typeof ConversationMutations>(
    key: Key,
    assetId: number
) {
    //You won't get type suggestions in the mutate function without this assertion
    const mutationFn = ConversationMutations[key] as (
        params: Parameters<typeof ConversationMutations[Key]>[0]
    ) => ButlerrMutationOptions;
    return useButlerrMutation(mutationFn, AssetConversationQueries.CONVERSATIONS(assetId));
}

interface PartnerConversationMutationInterface extends Omit<Butlerr.Asset.Conversation, 'members'> {
    asst_id: number;
    conversationId: number;
    members: number[];
}

const PartnerConversationMutations = {
    CREATE: ({
        asst_id,
        ...conversation
    }: Omit<
        PartnerConversationMutationInterface,
        | 'conversationId'
        | 'ascv_id'
        | 'ascv_datetime'
        | 'latestMessage'
        | 'user_id'
        | 'user_socialhandle'
        | 'user_profile'
        | 'acla_lastAccess'
        | 'acmb_leave'
    >): ButlerrMutationOptions => ({
        url: `${assetBaseRoute}/${asst_id}/conversations`,
        method: 'POST',
        requestBody: conversation,
    }),
};
export function usePartnerAssetConversationMutations<
    Key extends keyof typeof PartnerConversationMutations
>(key: Key) {
    const mutationFn = PartnerConversationMutations[key] as (
        params: Parameters<typeof PartnerConversationMutations[Key]>[0]
    ) => ButlerrMutationOptions;
    return useButlerrMutation(mutationFn, AssetConversationQueries.PARTNERCONVERSATIONS());
}

interface MessageMutationInterface extends Omit<Butlerr.Asset.ConversationMessage, 'attachments'> {
    assetId: number;
    conversationId: number;
    messageId: number;
    attachments: File[];
}

const MessageMutations = {
    CREATE: ({
        assetId,
        conversationId,
        ...message
    }: Omit<
        MessageMutationInterface,
        | 'messageId'
        | 'acms_id'
        | 'ascv_id'
        | 'acms_fromuser'
        | 'acms_datetime'
        | 'user_id'
        | 'user_socialhandle'
        | 'user_profile'
    >): ButlerrMutationOptions => ({
        url: `${assetBaseRoute}/${assetId}/conversations/${conversationId}/messages`,
        method: 'POST',
        requestBody: message,
        useFormData: true,
    }),

    DELETE: ({
        assetId,
        conversationId,
        messageId,
    }: Pick<
        MessageMutationInterface,
        'assetId' | 'conversationId' | 'messageId'
    >): ButlerrMutationOptions<Butlerr.Asset.ConversationMessage[]> => ({
        url: `${assetBaseRoute}/${assetId}/conversations/${conversationId}/messages/${messageId}`,
        method: 'DELETE',
        updater: {
            action: (prev) => prev?.filter((m) => m.acms_id !== messageId) ?? [],
            optimistic: true,
        },
    }),
};

export function useAssetConvoMsgMutations<Key extends keyof typeof MessageMutations>(
    key: Key,
    assetId: number,
    conversationId: number
) {
    //You won't get type suggestions in the mutate function without this assertion
    const mutationFn = MessageMutations[key] as (
        params: Parameters<typeof MessageMutations[Key]>[0]
    ) => ButlerrMutationOptions;
    return useButlerrMutation(
        mutationFn,
        AssetConversationQueries.MESSAGES(assetId, conversationId)
    );
}

export const useAssetConvoMsgAttach = (
    assetId: number,
    conversationId: number,
    messageId: number,
    attachmentId: number,
    type: 'content' | 'thumbnail' = 'content'
) => {
    // auth0
    const { getAccessTokenSilently } = useAuth0();

    // state
    const [url, setUrl] = useState<string | null>();

    useEffect(() => {
        const abort = new AbortController();

        const retrieveImage = async () => {
            try {
                if (conversationId <= 0 || messageId <= 0) throw new Error();

                const token = await getAccessTokenSilently();
                const result = await fetch(
                    `/api/assets/${assetId}/conversations/${conversationId}/messages/${messageId}/attachments/${attachmentId}/${type}`,
                    {
                        method: type === 'content' ? 'POST' : 'GET',
                        headers: {
                            authorization: `Bearer ${token}`,
                        },
                        signal: abort.signal,
                    }
                );

                if (!result.ok) throw new Error();

                const blob = await result.blob();
                const url = URL.createObjectURL(new Blob([blob]));
                setUrl(url);
            } catch (error) {
                if (error.name !== 'AbortError') {
                    setUrl(null);
                }
            }
        };
        retrieveImage();

        return () => {
            abort.abort();
        };
    }, [getAccessTokenSilently, assetId, conversationId, messageId, attachmentId, type]);

    return [url];
};

export const useAssetConvoMsgAttachSave = () => {
    return useButlerrMutation(
        ({
            assetId,
            conversationId,
            messageId,
            attachmentId,
            ...rest
        }: Pick<MessageMutationInterface, 'assetId' | 'conversationId' | 'messageId'> & {
            attachmentId: number;
            doct_parentid: number;
        }): ButlerrMutationOptions => ({
            url: `/api/assets/${assetId}/conversations/${conversationId}/messages/${messageId}/attachments/${attachmentId}/save`,
            method: 'POST',
            requestBody: rest,
            refetchData: true,
            queryKey: DocumentQueries.DOCUMENTS(rest.doct_parentid)
        }),
    );
};
