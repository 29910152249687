import { AddRounded, RssFeedRounded } from '@mui/icons-material';
import { Row, Col, Card } from 'react-bootstrap';

import { useWorkProfile } from '../../../services/partner.service';
import { useSocialChannels } from '../../../services/social-channels.service';
import EmptyMessage from '../../utils/EmptyMessage';
import { isUserAdmin } from '../workProfiles/AllPartnersPage';
import ChannelItem from '../workProfiles/ChannelItem';
import ButlerrCard from '../../utils/ButlerrCard';
import styles from '../partner.module.css';
import ChannelCreate from '../../social/channels/ChannelCreate';
import useModal from '../../../hooks/useModal';
import BootstrapSpinner from '../../utils/BootstrapSpinner';
import ErrorPage from '../../utils/ErrorPage';
import InfiniteScroller from '../../ui/InfiniteScroller';

interface WorkProfileChannelsProps {
    wkpfId: number;
}

const WorkProfileChannels = ({ wkpfId }: WorkProfileChannelsProps) => {

    const { data: workProfile, isLoading, error } = useWorkProfile(Number(wkpfId));

    const isAdmin = workProfile !== undefined && isUserAdmin(workProfile);

    const { data: channels, hasNextPage, isLoading: isChannelsLoading, isFetching, fetchNextPage } = useSocialChannels(
        {
            partner: wkpfId,
            editable_only: !isAdmin
        },
        workProfile !== undefined
    );

    const [modal, open] = useModal(ChannelCreate, {
        partnerId: wkpfId
    });

    if (isLoading || isChannelsLoading || !workProfile) return <BootstrapSpinner />;

    if (error !== null) return <ErrorPage status={error.status} message={error.message} />;

    return (
        <ButlerrCard
            items={{
                Icon: RssFeedRounded,
                title: 'channel(s)',
            }}
        >
            {channels?.pages[0].result.length === 0 && isAdmin === false ? (
                <EmptyMessage
                    className="p-4"
                    message="Nothing to see here"
                />
            ) : (
                <Row className="gy-3 gy-lg-4" xs="1" lg="3">
                    <InfiniteScroller
                        hasNextPage={hasNextPage}
                        fetchNextPage={fetchNextPage}
                        isLoading={isFetching}
                    >
                        {
                            channels?.pages
                            .flatMap((d) => d.result)
                            .map((ch) => {
                                return (
                                    <Col key={ch.chan_id}>
                                        <ChannelItem channel={ch} isAdmin={isAdmin} className="" />
                                    </Col>
                                );
                            })
                        }
                        {isAdmin && (
                            <Col>
                                <Card
                                    className={'border-0 shadow-sm cursor-pointer ' + styles.assetsListCard}
                                    style={{ backgroundColor: 'whitesmoke' }}
                                    onClick={open}
                                >
                                    <Card.Body
                                        className="p-2 d-flex flex-row cursor-pointer mx-auto"
                                        as={Row}
                                    >
                                        <AddRounded
                                            className={styles.assetsListCardImage + 'h-auto mx-auto'}
                                            style={{
                                                width: '70%',
                                                height: 60,
                                                alignSelf: 'center',
                                            }}
                                        />
                                    </Card.Body>
                                </Card>
                                {modal}
                            </Col>
                        )}
                    </InfiniteScroller>
                </Row>
            )}
            
        </ButlerrCard>
    );
};

export default WorkProfileChannels;
