import classNames from "classnames";
import { useState } from "react";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Butlerr } from "../../../types/butlerr";
import { createSocialRoute, SocialRoutes } from "../SocialHome";
import styles from "../social.module.css";

interface ChannelAvatarProps extends React.HTMLAttributes<HTMLImageElement> {
    width: number;
    height?: number;

    clickable?: boolean;
    channel: Pick<Butlerr.Social.Channel, 'chan_profile'> & Partial<Pick<Butlerr.Social.Channel, 'chan_id' | 'chan_name'>>;

    /**
     * If `src` is passed in props, it will always take precedence, unless there's an error
     */
    src?: string;
}
export default function ChannelAvatar({
    width,
    height,
    clickable = false,
    channel,
    style,
    className,
    src: srcFromProps,
    ...props
} : ChannelAvatarProps) {

    const [ isError, setIsError ] = useState(false);

    let ele: JSX.Element;
    if (isError || (channel.chan_profile === null && srcFromProps === undefined)) {
        ele = (
            <div
                {...props}
                className={classNames(className, clickable && styles.userAvatar, "rounded-circle fw-bold d-flex align-items-center justify-content-center text-light")}
                style={{
                    ...style,
                    width, height,
                    fontSize: width / 2,
                    backgroundColor: "var(--primary-light)",
                    filter: `hue-rotate(${ ((channel.chan_id ?? 0) % 10) * 45 }deg)`
                }}
            >
                { channel.chan_name?.charAt(0).toUpperCase() || 'C' }
            </div>
        )
    }
    else {
        ele = (
            <Image
                width={width}
                height={height}
                onError={() => setIsError(true)}
                className={classNames(className, clickable && styles.userAvatar, "rounded-circle")}
                src={srcFromProps ?? channel.chan_profile!}
                style={{
                    objectFit: 'cover',
                    ...style
                }}
                {...props}
            />
        )
    }

    if (!clickable || !channel.chan_id) return ele;

    return (
        <Link
            to={createSocialRoute(SocialRoutes.CHANNEL_DETAILS, { id: channel.chan_id })}
            className="text-decoration-none"
        >
            { ele }
        </Link>
    )
}