import IconButton from "../utils/IconButton"
import { DeleteOutlineRounded, PhotoRounded, TagRounded, PersonAddAlt1Rounded, LinkRounded, CheckOutlined } from "@mui/icons-material"
import classNames from "classnames"
import { useState, useRef, useEffect } from "react"
import { Form, Dropdown, ButtonGroup, Button } from "react-bootstrap"
import { useSocialMutations } from "../../services/social.service"
import { Butlerr } from "../../types/butlerr"
import { useButlerrUser } from '../../services/user.service';
import styles from "./social.module.css"
import UserDropdownButton from "../utils/UserDropdownButton"
import { useSocialChannels } from "../../services/social-channels.service"
import ChannelAvatar from "./channels/ChannelAvatar"
import UserAvatar from "./UserAvatar"
import LineClampText from "../utils/LineClampText"
import EmojiPickerButton from "../ui/EmojiPicker";
import { getStringLengthWNewLine } from "../utils/HelperFunctions"

const POST_MAX_CHAR = 2048;

interface NewPostContainerProps {
    onSuccess?: () => void;
    className?: string;
    initialPostMessage?: string;
    initialPostUrl?: string;
    initialHashtags?: string[];
    
    initialChannelId?: number;
}

export default function NewPostContainer({ onSuccess, className, initialPostMessage, initialPostUrl, initialHashtags, initialChannelId } : NewPostContainerProps) {

    const { mutate: createPost, isLoading, error } = useSocialMutations('CREATE');
    const [err, setErr] = useState<string>();

    const [form, setForm] = useState({
        post_msg: initialPostMessage ?? '',
        post_url: initialPostUrl ?? '',
        post_hashtags: ''
    })

    const [hashtags, setHashtags] = useState<string[]>(initialHashtags ?? [])
    const [tagged, setTagged] = useState<Omit<Butlerr.UserInfo, 'user_profile'>[]>([])

    const onCreatePost = async () => {
        if (!form.post_msg) {
            return setErr("Please enter a message")
        }
        if (getStringLengthWNewLine(form.post_msg) > POST_MAX_CHAR) {
            return setErr(`Post can't contain more than ${POST_MAX_CHAR} characters`)
        }
        
        const hashtags = await extractHashtags()
        if (hashtags.length > 100) {
            return setErr("Hashtags can't contain more than 100 characters")
        }
        if (form.post_url.length > 384) {
            return setErr("URL can't contain more than 384 characters")
        }

        setErr(undefined)
        createPost({
            post_msg: form.post_msg,
            post_url: collapsed.link ? '' : form.post_url, //do not send url if collapsed
            post_hashtags: hashtags.join(','),
            tagged: tagged,
            post_images: files.map(f => f.file),
            chan_id: channelToPost?.chan_id
        }, {
            onSuccess: () => {
                onSuccess?.();
                //cleanup
                setFiles([])
                setHashtags([])
                setTagged([])
                setCollapsed({
                    hashtags: true,
                    link: initialPostUrl === undefined
                })
                setForm({
                    post_msg: '',
                    post_url: '',
                    post_hashtags: ''
                })
            }
        })
    }

    const extractHashtags = () => {
        return new Promise<string[]>((res) => {
            setForm((state) => {
                setHashtags((oldTags) => {
                    const tag = state.post_hashtags.replace(/\W/g, '').toLowerCase()

                    const tags = (tag && !oldTags.includes(tag)) ? [
                        ...oldTags, tag
                    ] : oldTags

                    res(tags)
                    return tags
                })
                return {
                    ...state, post_hashtags: ''
                }
            })
        })
    }

    //ref for input file button
    const addFileRef = useRef<HTMLInputElement>(null)

    //file as well as base64 url
    type MediaFile = {
        url: string;
        name?: undefined;
        file: File;
    } | {
        url?: undefined;
        name: string;
        file: File;
    }
    const [files, setFiles] = useState<MediaFile[]>([])

    // convert file to a base64 url
    const getFileWithUrl = (file: File) : Promise<MediaFile> => {
        return new Promise((res, rej) => {
            if (file.type.startsWith('image')) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    if (e.target?.result) {
                        res({
                            file,
                            url: e.target.result as string
                        })
                    }
                    else rej()
                }
                reader.onerror = (e) => rej(e);
                reader.readAsDataURL(file);
            }
            else if (file.type.startsWith('video')) {
                res({
                    file,
                    name: file.name
                })
            }
            else rej();
        });
    };

    // Image drag & drop handlers
    const onImageDrag = (e: React.DragEvent<HTMLImageElement>, index: number) => {
        e.dataTransfer.setData('text/plain', index.toString())
        e.dataTransfer.setDragImage(e.currentTarget, 0, 0)
    }

    const onImageDrop = (e: React.DragEvent<HTMLLIElement>, newIndex: number) => {
        cancelDefault(e)
        // get new and old index
        let oldIndex = parseInt(e.dataTransfer.getData('text/plain'))
        
        setFiles((oldFiles) => {
            oldFiles.splice(newIndex, 0, oldFiles.splice(oldIndex, 1)[0])
            return [...oldFiles]
        })
    }

    const cancelDefault = (e: React.DragEvent<HTMLLIElement>) => {
        e.preventDefault()
        e.stopPropagation()
        return false
    }

    const msgInputRef = useRef<HTMLTextAreaElement>(null);
    useEffect(() => {
        if (msgInputRef.current) calculateTextAreaRows(msgInputRef.current)
    }, [])

    const calculateTextAreaRows = (ele: HTMLTextAreaElement) => {
        const textareaLineHeight = 24; // set in css (px)
        const minRows = 3;
        const maxRows = 10;

        const prevRows = ele.rows;
  	    ele.rows = minRows; // reset number of rows in textarea
		
		let currentRows = ~~(ele.scrollHeight / textareaLineHeight);
		
		if (currentRows > maxRows) {
			currentRows = maxRows;
            if (currentRows !== prevRows) ele.scrollTop = ele.scrollHeight;
		}
        ele.rows = currentRows
	};

    //hidden inputs
    const [collapsed, setCollapsed] = useState({
        hashtags: true,
        link: initialPostUrl === undefined
    })

    const { data: user } = useButlerrUser();

    const { data: channels } = useSocialChannels({ editable_only: true });
    const postables = channels?.pages.flatMap(p => p.result);

    //undefined to post with user
    const [ channelToPost, setChannelToPost ] = useState<Butlerr.Social.Channel>();

    useEffect(() => {
        //Reset selected channel to inital, when data change
        const chan = !initialChannelId ? undefined : channels?.pages.flatMap(p => p.result).find(c => c.chan_id === initialChannelId);
        setChannelToPost(chan);

    }, [channels, initialChannelId]);

    if (initialChannelId !== undefined && channelToPost === undefined) {
        return <></>
    }

    const currentAvatar = (
        channelToPost !== undefined ? (
            <ChannelAvatar
                channel={channelToPost}
                width={25}
                height={25}
            />
        ) : (
            <UserAvatar
                style={{ pointerEvents: 'none' }}
                user={user}
                width={25}
                height={25}
            />
        )
    )

    return (
        <div
            className={classNames(styles.newPostContainer, className, "p-3 shadow")}
        >
            <Form.Control
                as="textarea"
                style={{ background: "#f6f6f6" }}
                className={classNames(styles.postInput, "mb-2")}
                placeholder="Share something..."
                value={form.post_msg}
                ref={msgInputRef}
                onChange={(e) => {
                    const val = e.target.value;
                    setForm(prev => ({
                        ...prev,
                        post_msg: val
                    }));
                    calculateTextAreaRows(e.target as HTMLTextAreaElement);
                }}
            />
            {(() => {
                const msgLength = getStringLengthWNewLine(form.post_msg);
                if (msgLength === 0) return false;
                return (
                    <p className={`small mb-1 text-end ${msgLength > POST_MAX_CHAR ? 'text-danger' : 'text-muted'}`}>
                        {msgLength} / {POST_MAX_CHAR} characters
                    </p>
                )
            })()}
            <Form.Control
                placeholder="Link"
                className={classNames({
                    "mb-2": !collapsed.link,
                    [styles.postInput]: true,
                    [styles.collapsed]: collapsed.link
                })}
                value={form.post_url}
                onChange={(e) => setForm(prev => ({ ...prev, post_url: e.target.value }))}
            />
            <Form.Control
                placeholder="Hashtags"
                className={classNames({
                    "form-control": true,
                    [styles.postInput]: true,
                    "mb-2": !collapsed.hashtags,
                    [styles.collapsed]: collapsed.hashtags
                })}
                value={form.post_hashtags}
                onChange={(e) => setForm(prev => ({ ...prev, post_hashtags: e.target.value }))}
                onKeyUp={(e) => {
                    //extract hashtags on space or enter key hit
                    if (e.key === ' ' || e.key === 'Enter') {
                        extractHashtags()
                    }
                }}
            />
            {
                hashtags.length > 0 && (
                    <div className="ms-2 mb-2 d-flex flex-wrap">
                        {
                            hashtags.map((str) => (
                                <mark
                                    key={str}
                                    className={[styles.hashtag, "me-2 mb-1"].join(' ')}
                                    onClick={() => setHashtags(state => state.filter(tag => tag !== str))}
                                >
                                    {str}
                                </mark>
                            ))
                        }
                    </div>
                )
            }
            {
                tagged.length > 0 && (
                    <div className="ms-2 mb-2 d-flex flex-wrap">
                        {
                            tagged.map((tag) => (
                                <span
                                    key={tag.user_id}
                                    className={[styles.personTag, "me-2 mb-1"].join(' ')}
                                    onClick={() => setTagged(state => state.filter(str => str !== tag))}
                                >
                                    {tag.user_socialhandle}
                                </span>
                            ))
                        }
                    </div>
                )
            }
            <ul className="d-flex p-0 mt-2 mb-0 overflow-auto">
                {
                    files.map((file, index) => (
                        <li
                            key={file.file.name + file.file.size}
                            className={styles.imagePreview + " px-2 mb-2 position-relative"}
                            draggable={true}
                            onDrop={(e) => onImageDrop(e, index)}
                            onDragEnter={cancelDefault}
                            onDragOver={cancelDefault}
                        >
                            {
                                file.url !== undefined ? (
                                    <img
                                        src={file.url}
                                        alt=""
                                        onDragStart={(e) => onImageDrag(e, index)}
                                        className="border"
                                    ></img>
                                ) : (
                                    <div className="p-2 text-wrap text-light fw-bold small text-center d-flex align-items-center">
                                        { file.name }
                                    </div>
                                )
                            }
                            <button
                                className={styles.removeBtn + " d-flex justify-content-center align-items-center"}
                                onClick={() => {
                                    setFiles(oldFiles => oldFiles.filter((_, i) => i !== index))
                                }}
                            >
                                <DeleteOutlineRounded />
                            </button>
                        </li>
                    ))
                }
            </ul>
            {
                (err !== undefined || error !== null) && <p className="text-danger w-100">{err ?? error?.message}</p>
            }
            <div className="d-flex align-items-center flex-wrap">
                <input
                    ref={addFileRef}
                    type="file"
                    className="d-none"
                    accept="image/*,video/*"
                    multiple={true}
                    onChange={async (e) => {
                        if (e.target.files !== null) {
                            const files = Array.from(e.target.files)
                            //turn files into ImageFile objects
                            const images = await Promise.allSettled(
                                files.map((f) => getFileWithUrl(f))
                            )
                            setFiles((prev) => {
                                return [
                                    ...prev,
                                    ...images
                                        .flatMap(i => i.status === 'fulfilled' ? i.value : [])
                                        .filter(i => prev.findIndex(p => (i.url && p.url === i.url) || (i.name && p.name === i.name)) === -1)
                                ].slice(0, 5)
                            })
                        }
                        else {
                            setFiles([])
                        }
                        return false //don't store file in input (to allow readding the same file)
                    }}
                ></input>
                <div className="d-flex flex-wrap">
                    <EmojiPickerButton
                        onEmojiSelect={(em) => {
                            setForm(f => ({
                                ...f,
                                post_msg: f.post_msg + em.native
                            }))
                        }}
                    />
                    <IconButton
                        transparent
                        Icon={PhotoRounded}
                        title="Add photo"
                        disabled={files.length >= 5}
                        onClick={() => addFileRef.current?.click()}
                        className="text-dark ms-2"
                    />
                    <IconButton
                        transparent
                        Icon={TagRounded}
                        title="Add hashtags"
                        className="text-dark ms-2"
                        onClick={() => {
                            setCollapsed((prev) => ({...prev, hashtags: !prev.hashtags}))
                        }}
                    />
                    <UserDropdownButton
                        className="ms-2 d-block"
                        bsPrefix=" "
                        as="span"
                        limit={10}
                        selectedUsers={tagged}
                        setSelectedUsers={setTagged}
                        searchOptions={{
                            is_taggable: true
                        }}
                    >
                        <IconButton
                            transparent
                            Icon={PersonAddAlt1Rounded}
                            title="Tag people"
                            className="text-dark"
                        />
                    </UserDropdownButton>
                    <IconButton
                        transparent
                        Icon={LinkRounded}
                        title="Add Link"
                        className="text-dark ms-2"
                        onClick={() => {
                            setCollapsed((prev) => ({...prev, link: !prev.link}))
                        }}
                    />
                </div>

                <Dropdown as={ButtonGroup} className="ms-auto rounded-4">
                    <Button
                        onClick={onCreatePost}
                        disabled={isLoading}
                        className={classNames(
                            (postables !== undefined && postables.length !== 0) ? "px-3 py-1" : "px-4",
                            "rounded-4 d-flex align-items-center justify-content-between"
                        )}
                        title="Post"
                    >
                        <span className={initialChannelId !== undefined ? "me-2" : undefined}>
                            Post
                        </span>
                        { initialChannelId !== undefined && currentAvatar }
                    </Button>
                    {
                        initialChannelId === undefined && postables !== undefined && postables.length !== 0 && (
                            <>
                                <Dropdown.Toggle
                                    split
                                    disabled={isLoading}
                                    className="rounded-4 p-1 d-flex align-items-center"
                                    style={{ borderLeft: "1px solid var(--primary-dark)" }}
                                >
                                    { currentAvatar }
                                </Dropdown.Toggle>
                                <Dropdown.Menu style={{ width: '200px' }}>
                                    {
                                        user !== undefined && (
                                            <Dropdown.Item
                                                className="d-flex align-items-center"
                                                onClick={() => setChannelToPost(undefined)}
                                                disabled={channelToPost === undefined}
                                            >
                                                <UserAvatar
                                                    style={{ pointerEvents: 'none' }}
                                                    user={user}
                                                    width={25}
                                                    height={25}
                                                    className="flex-shrink-0 me-2"
                                                />
                                                <LineClampText className="mb-0 text-wrap text-dark text-break" maxLines={2}>{user.user_socialhandle}</LineClampText>

                                                { channelToPost === undefined && (
                                                    <CheckOutlined fontSize="small" className="text-primary ms-auto" />
                                                )}
                                            </Dropdown.Item>
                                        )
                                    }
                                    {
                                        postables.map((chan) => (
                                            <Dropdown.Item
                                                key={chan.chan_id}
                                                className="d-flex align-items-center"
                                                onClick={() => setChannelToPost(chan)}
                                                disabled={channelToPost?.chan_id === chan.chan_id}
                                            >
                                                <ChannelAvatar
                                                    channel={chan}
                                                    width={25}
                                                    height={25}
                                                    className="flex-shrink-0 me-2"
                                                />
                                                <LineClampText className="mb-0 text-wrap text-dark text-break" maxLines={2}>{chan.chan_name}</LineClampText>
                                                
                                                { channelToPost?.chan_id === chan.chan_id && (
                                                    <CheckOutlined fontSize="small" className="text-primary ms-auto" />
                                                )}
                                            </Dropdown.Item>
                                        ))
                                    }
                                </Dropdown.Menu>
                            </>
                        )
                    }
                </Dropdown>
            </div>
        </div>
    )
}