import ReactDOM from 'react-dom';
import App from './App';
import { Chart, registerables } from 'chart.js';
import { BrowserRouter as Router } from 'react-router-dom';

import smoothscroll from 'smoothscroll-polyfill';
import './App.scss';

Chart.register(...registerables);

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
);


//polyfills
smoothscroll.polyfill();