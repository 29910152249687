import { createContext, useCallback, useContext, useState } from 'react';
import { Modal, Image, Container, Row, Col } from 'react-bootstrap';
import { BaseModalProps } from '../../../hooks/useModal';
// import { useButlerrCountries } from '../../services/asset.service';
import { CountriesKey, UserGenderKey, UserSelfDescribeKey } from '../../../types/butlerr-enums';
import WelcomePage, { UserPurposeKey } from './WelcomePage';
import ProfilePage from './ProfilePage';
import SocialPage from './SocialPage';
import GetConnectedPage from './GetConnectedPage';
import SocialChannelsPage from './SocialChannelsPage';
import NewsSubscriptionsPage from './NewsSubscriptionsPage';
import InviteFriendsPage from './InviteFriendsPage';
import FinalWorkPage from './FinalWorkPage';
import FinalManagePage from './FinalManagePage';
import FinalSocialPage from './FinalSocialPage';
import FinalSharingPage from './FinalSharingPage';
import VerificationPage from './VerificationPage';

export interface ProfileForm {
    /* Welcome page */
    user_firstname: string;
    user_lastname: string;
    user_country: CountriesKey | '';
    user_state: string;
    user_mobileno: string;
    user_birthyear: number;
    user_gender: UserGenderKey | '';
    purpose: UserPurposeKey | '';
    /* Social Presence */
    user_profile: File | null;
    user_socialhandle: string;
    user_socialselfdescribe: UserSelfDescribeKey | '';
    user_socialoneliner: string;
    user_socialbio: string;
    user_preflocations: string;
    user_prefkeywords: string;
}

interface IProfileCompeletionContext {
    email: string;

    profileForm: ProfileForm;
    setProfileForm: React.Dispatch<React.SetStateAction<IProfileCompeletionContext['profileForm']>>;

    isLoading: boolean;
    setIsLoading: React.Dispatch<React.SetStateAction<IProfileCompeletionContext['isLoading']>>;

    goPrevPage: (target?: number) => void;
    goNextPage: (target?: number) => void;

    onClose: () => void;
}

const ProfileCompeletionContext = createContext<IProfileCompeletionContext | undefined>(undefined);
ProfileCompeletionContext.displayName = 'ProfileCompeletionContext';

export function useProfileCompeletion() {
    const context = useContext(ProfileCompeletionContext);

    if (context === undefined) {
        throw new Error('Context error: `useProfileCompeletion` used outside of provider!');
    }
    return context;
}

interface CompleteProfileModalProps extends BaseModalProps {
    email: string;
}

const OnboardingModal = ({ show, onClose, email }: CompleteProfileModalProps) => {
    const [profileForm, setProfileForm] = useState<ProfileForm>({
        user_firstname: '',
        user_lastname: '',
        user_country: '',
        user_state: '',
        user_mobileno: '',
        user_birthyear: 0,
        user_gender: '',
        purpose: '',
        user_profile: null,
        user_socialhandle: '',
        user_socialselfdescribe: '',
        user_socialoneliner: '',
        user_socialbio: '',
        user_preflocations: '',
        user_prefkeywords: '',
    });

    const [isLoading, setIsLoading] = useState(false);

    const [activePage, setActivePage] = useState(0);

    const pages = [
        [VerificationPage],
        [WelcomePage],
        [ProfilePage],
        [SocialPage],
        [GetConnectedPage],
        [SocialChannelsPage],
        [NewsSubscriptionsPage],
        [InviteFriendsPage],
        [FinalWorkPage],
        [FinalManagePage],
        [FinalSocialPage],
        [FinalSharingPage],
    ] as const;
    // for testing
    // const pages = [[WelcomePage]] as const;

    const SelectedPage = pages[activePage][0];

    const goPrevPage = useCallback((target?: number) => {
        if (target === undefined) {
            setActivePage((p) => Math.max(p - 1, 0));
        } else {
            setActivePage(Math.max(target, 0));
        }
    }, []);

    const goNextPage = useCallback(
        (target?: number) => {
            if (target === undefined) {
                setActivePage((p) => Math.min(p + 1, pages.length - 1));
            } else {
                setActivePage((p) => Math.min(target, pages.length - 1));
            }
        },
        [pages.length]
    );

    return (
        <Modal show={show} onHide={onClose} size="xl" backdrop="static" keyboard={false} centered>
            <Modal.Body className="p-3 p-lg-5">
                <Container>
                    <Row>
                        <Col xs="12" lg="4">
                            <div className="d-flex justify-content-center align-items-center h-100 py-3">
                                <Image
                                    alt="img"
                                    src="/logo_full.png"
                                    width="300"
                                    className="mx-3"
                                    style={{ objectFit: 'contain' }}
                                />
                            </div>
                        </Col>
                        <Col xs="12" lg="8">
                            <Container className="d-flex flex-column justify-content-center h-100">
                                <ProfileCompeletionContext.Provider
                                    value={{
                                        email,
                                        profileForm,
                                        setProfileForm,
                                        isLoading,
                                        setIsLoading,
                                        goPrevPage,
                                        goNextPage,
                                        onClose,
                                    }}
                                >
                                    <SelectedPage />
                                </ProfileCompeletionContext.Provider>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
    );
};

export default OnboardingModal;
