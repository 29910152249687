import { Butlerr } from '../../../types/butlerr';
import { formatCurrency, getCurrencySign } from '../../utils/HelperFunctions';
import AssetKPI, { KPICardInterface } from '../AssetKPI';

interface AssetCashflowsKPIInterface {
    cashflows: Butlerr.Asset.Cashflow[];
    isLoading: boolean;
    asset: Butlerr.Asset;
}

const AssetCashflowsKPI = ({ cashflows, isLoading, asset }: AssetCashflowsKPIInterface) => {
    const calculateTotalEarnings = () => {
        const _cashflows = cashflows.filter((cashflow) => Math.sign(cashflow.ascf_amt) === 1);
        const totalEarnings = _cashflows.reduce((prev, curr) => {
            prev += Number(curr.ascf_amt);
            return prev;
        }, 0);
        return totalEarnings;
    };

    const calculateTotalExpense = () => {
        const _cashflows = cashflows.filter((cashflow) => Math.sign(cashflow.ascf_amt) === -1);
        const totalExpense = _cashflows.reduce((prev, curr) => {
            prev += Math.abs(Number(curr.ascf_amt));
            return prev;
        }, 0);
        return totalExpense;
    };

    const calculateNetCashflow = () => {
        const netCashflow = calculateTotalEarnings() - calculateTotalExpense();

        if (netCashflow === 0) return `${getCurrencySign(asset.asst_currency)}0.00`;

        const sign = Math.sign(netCashflow);
        const _netCashflow = Math.abs(netCashflow);
        return `${sign === 1 ? '' : '-'}${getCurrencySign(asset.asst_currency)}${formatCurrency(
            _netCashflow
        )}`;
    };

    const kpis: KPICardInterface[] = [
        {
            title: 'Earnings',
            data: isLoading
                ? undefined
                : `${getCurrencySign(asset.asst_currency)}${formatCurrency(
                      calculateTotalEarnings()
                  )}`,
            tooltipText: 'Total amount of earnings gained from asset for the selected month',
        },
        {
            title: 'Expense',
            data: isLoading
                ? undefined
                : `${getCurrencySign(asset.asst_currency)}${formatCurrency(
                      calculateTotalExpense()
                  )}`,
            tooltipText: 'Total expenditure incurred from asset for the selected month',
        },
        {
            title: 'Nett Cashflow',
            data: isLoading ? undefined : calculateNetCashflow(),
            tooltipText:
                'Nett cashflow after deducting expenses from earning for the selected month',
        },
    ];

    return (
        <>
            <AssetKPI data={kpis} xs={1} lg={2} xl={3} />
        </>
    );
};

export default AssetCashflowsKPI;
