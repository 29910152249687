import { DriveFileRenameOutlineRounded, StarsRounded, SentimentVerySatisfiedRounded, RemoveOutlined, AddOutlined } from "@mui/icons-material";
import { ITopUsers, TopContributorOptions, useButlerrUser, useUserMutations } from "../../../services/user.service";
import { useMemo, useState } from "react";
import { useTopContributors } from "../../../services/user.service";
import styles from '../../header/header.module.css';
import { Card } from "react-bootstrap";
import { createSocialRoute, SocialRoutes } from "../SocialHome";
import UserAvatar from "../UserAvatar";
import ToggleButtonGroup from "../../ui/ToggleButtonGroup";
import { Link } from "react-router-dom";

export default function HallOfFame() {

    type Key = TopContributorOptions['sort'];

    const [ sort, setSort ] = useState<Key>('top_followers');

    const items = [
        { Icon: StarsRounded, label: "By followers", key: "top_followers" as Key },
        { Icon: DriveFileRenameOutlineRounded, label: "By activity", key: "top_posts" as Key },
        { Icon: SentimentVerySatisfiedRounded, label: "By reactions", key: "top_reactions" as Key }
    ];

    const { data: users } = useTopContributors({ sort, time: "month" });

    const monthName = useMemo(() => {
        if (!users) return "";
        const date = new Date();
        date.setUTCMonth(users.month);
        return date.toLocaleString('en-US', { month: 'long' });

    }, [users])

    return (
        <div className="mb-3">
            <div className="mb-2 d-flex align-items-center justify-content-between">
                <span className="fw-bold text-uppercase" style={{ color: "var(--bs-gray-600)" }}>
                    Hall of Fame &bull; { monthName }
                </span>
                <ToggleButtonGroup
                    items={items.map(i => ({ ...i, active: i.key === sort }))}
                    onSelect={({ key }) => setSort(key)}
                />
            </div>
            <div className="w-100 d-flex align-items-stretch flex-wrap" style={{ gridGap: '0.5rem' }}>
                {
                    users?.users?.length === 0 ? (
                        <p className="w-100 fw-semibold text-center text-muted">
                            Nothing to see here
                        </p>
                    ) : users === undefined ? (
                        Array(5).fill(null).map((_, idx) => (
                            <Card key={idx} style={{ minWidth: '80px', maxWidth: '150px', flexBasis: 0 }} className='flex-grow-1'>
                                <div className="w-100 p-3 card-img-top">
                                    <div className="w-100 h-100 rounded-circle skeleton-box" style={{ aspectRatio: '1 / 1' }}></div>
                                </div>
                                <Card.Body className="px-2 pb-2 pt-0 d-flex flex-column align-items-center">
                                    <div className="w-100 skeleton-box"></div>
                                </Card.Body>
                            </Card>
                        ))
                    ) : users.users.map((u) => (
                        <HallOfFameItem
                            key={u.user_id}
                            user={u}
                            note={
                                sort === 'top_followers' ? `${u.count} follower${u.count > 1 ? 's' : ''} gained`
                                : sort === 'top_posts' ? `${u.count} post${u.count > 1 ? 's' : ''} created`
                                : `${u.count} user${u.count > 1 ? 's' : ''} reacted`
                            }
                        />
                    ))
                }
            </div>
        </div>
    )
}

function HallOfFameItem({ user, note } : { user: ITopUsers['users'][number], note: string }) {

    const { data: ownUser } = useButlerrUser();

    const { mutate: follow, isLoading: isLoadingF } = useUserMutations('FOLLOW');
    const { mutate: unfollow, isLoading: isLoadingU } = useUserMutations('UNFOLLOW');

    const isMutating = isLoadingF || isLoadingU;

    const isFollowing = ownUser?.following.includes(user.user_id) === true;


    return (
        <Card key={user.user_id} style={{ minWidth: '80px', maxWidth: '150px', flexBasis: 0 }} className="flex-grow-1">
            <div className="w-100 p-3 card-img-top">
                <button
                    className={"border-0 p-1 position-relative w-100 bg-white " + styles.followUserBtn}
                    onClick={() => {
                        if (isFollowing) {
                            unfollow(user.user_id);
                        } else {
                            follow(user.user_id);
                        }
                    }}
                    disabled={isMutating || !ownUser || ownUser.user_id === user.user_id}
                >
                    <UserAvatar
                        user={user}
                        className="w-100"
                        style={{ aspectRatio: '1 / 1' }}
                    />

                    {ownUser && ownUser.user_id !== user.user_id && (
                        <div className="position-absolute" style={{ top: 0, right: 0 }}>
                            {
                                isFollowing ? (
                                    <RemoveOutlined
                                        fontSize="small"
                                        className="rounded-circle bg-primary text-white"
                                    />
                                ) : (
                                    <AddOutlined
                                        fontSize="small"
                                        className="rounded-circle bg-primary text-white"
                                    />
                                )
                            }
                        </div>
                    )}
                </button>
            </div>
            <Card.Body className="px-2 pb-2 pt-0 d-flex flex-column align-items-center">
                <Link
                    to={createSocialRoute(SocialRoutes.USER_PROFILE, { id: user.user_id })}
                    className="mb-1 link-underline text-dark fw-semibold text-center"
                >
                    {user.user_socialhandle}
                </Link>
                <span className="w-100 text-muted small text-center text-truncate">
                    { note }
                </span>
            </Card.Body>
        </Card>
    )
}