import { FavoriteBorder, Bookmark, BookmarkBorderOutlined, MoreVert, LinkRounded, PlayArrowRounded } from "@mui/icons-material"
import { useMemo, useRef, useState } from "react"
import { Dropdown, OverlayTrigger, Popover } from "react-bootstrap";
import { useSocialMutations, usePostComments } from "../../services/social.service"
import { SocialPostReactionKey } from "../../types/butlerr-enums"
import { Butlerr } from "../../types/butlerr";
import BootstrapCarousel from "../utils/BootstrapCarousel";
import { formatDate } from "../utils/HelperFunctions"
import IconButton from "../utils/IconButton"
import styles from "./social.module.css"
import { useButlerrUser } from '../../services/user.service';
import DestructiveModal from "../utils/DestructiveModal";
import { Link, useHistory } from "react-router-dom";
import { createSocialRoute, SocialRoutes } from "./SocialHome";
import classNames from "classnames";
import PostComments from "./PostComments";
import EditPostModal from "./EditPost";

import { ReactComponent as CommentIcon } from "../../assets/icons/comment.svg";
import { ReactComponent as LikeIcon } from "../../assets/icons/reaction-thumbsup.svg";
import { ReactComponent as LoveIcon } from "../../assets/icons/reaction-heart.svg";
import { ReactComponent as DislikeIcon } from "../../assets/icons/reaction-thumbsdown.svg";
import { ReactComponent as HighFiveIcon } from "../../assets/icons/reaction-highfive.svg";
import { ReactComponent as ClapIcon } from "../../assets/icons/reaction-clap.svg";
import UserAvatar from "./UserAvatar";
import ChannelAvatar from "./channels/ChannelAvatar";
import { useSocialChannels } from "../../services/social-channels.service";
import LineClampText from "../utils/LineClampText";
import useModal from "../../hooks/useModal";
import useQuery from "../../hooks/useQuery";
import { ReactionListModal } from "./network/UserListModal";

interface PostProps {
    post: Butlerr.Social.Post;
}

export default function Post({ post } : PostProps) {

    const history = useHistory();

    const { mutate: react } = useSocialMutations('REACT');
    const { mutate: removeReact } = useSocialMutations('REMOVE_REACT');

    const { mutate: savePost } = useSocialMutations('SAVE');
    const { mutate: unsavePost } = useSocialMutations('UNSAVE');


    const handlePostReact = (reaction: SocialPostReactionKey) => {
        if (post.user_reaction === reaction) removeReact(post.post_id);
        else react({ postId: post.post_id, reac_reaction: reaction });
    }

    const handlePostSave = () => {
        if (post.user_saved === 1) unsavePost(post.post_id)
        else savePost(post.post_id)
    }

    const { data: user } = useButlerrUser();

    //modals
    const [ showEditModal, setShowEditModal ] = useState(false)
    const [ showDeleteModal, setShowDeleteModal ] = useState(false)
    const [ showCommentsModal, setShowCommentsModal ] = useState(false)

    const handleShowComments = () => {
        setShowCommentsModal(true)
    }
    
    const { mutateAsync: deletePost } = useSocialMutations('DELETE');

    const currentParams = useQuery();
    const handleHashtagSearch = (tag: string) => {
        const params = new URLSearchParams(currentParams);
        params.set('h', tag);
        params.set('t', 'p');

        history.push({
            pathname: SocialRoutes.SEARCH,
            search: params.toString()
        })
    }

    //Only need to fetch channels if the post is a channel post (Not a big concern as this query will be executed by the new post container anyways)
    const { data: editables } = useSocialChannels({ editable_only: true }, post.post_chanid !== null);

    const isEditable = useMemo(() => {
        //If not a channel post & user id matches
        if (post.post_chanid === null && post.user_id === user?.user_id) {
            return true;
        }
        //If channel post & channel id in editable channel list
        if (post.post_chanid !== null && editables !== undefined && editables.pages.flatMap(p => p.result).some(c => c.chan_id === post.post_chanid)) {
            return true;
        }
        return false;

    }, [post.post_chanid, post.user_id, user?.user_id, editables]);

    return (
        <div className={styles.postContainer + " px-3 pt-3 mb-2 shadow-sm"}>
            <div>
                <div className={styles.postHeader + " d-flex flex-row align-items-center mb-2"}>
                    {
                        post.post_chanid === null ? (
                            <Link to={createSocialRoute(SocialRoutes.USER_PROFILE, { id: post.user_id })}>
                                <UserAvatar
                                    width={50}
                                    height={50}
                                    user={post}
                                />
                            </Link>
                        ) : (
                            <ChannelAvatar
                                channel={{ chan_id: post.post_chanid, chan_name: post.chan_name ?? undefined, chan_profile: post.chan_profile }}
                                clickable={true}
                                width={50}
                                height={50}
                            />
                        )
                    }
                    
                    <div className="ms-3 text-truncate">
                        <Link
                            to={post.post_chanid === null
                                ? createSocialRoute(SocialRoutes.USER_PROFILE, { id: post.user_id })
                                : createSocialRoute(SocialRoutes.CHANNEL_DETAILS, { id: post.post_chanid })
                            }
                            className={styles.linkUnderline + " mb-2 text-dark fw-bold"}
                        >
                            {post.chan_name ?? post.user_socialhandle}
                        </Link>
                        <Link
                            to={createSocialRoute(SocialRoutes.POST_DETAILS, { id: post.post_id })}
                            className={styles.linkUnderline + " text-secondary"}
                        >
                            <p
                                className="mb-0"
                                title={formatDate(post.post_update ?? post.post_created, "long", "long", true) ?? undefined}
                            >
                                {
                                    post.wkpf_name !== null && <small>{post.wkpf_name} &bull; </small>
                                }
                                <small>
                                    {formatDate(post.post_update ?? post.post_created) ?? undefined}
                                </small>
                                {
                                    post.post_update !== null && (
                                        <small> &bull; Edited</small>
                                    )
                                }
                            </p>
                        </Link>
                    </div>
                    {
                        isEditable && (
                            <Dropdown
                                className="ms-auto"
                                autoClose='outside'
                            >
                                <Dropdown.Toggle
                                    as="span"
                                    bsPrefix=" "
                                    variant="clear"
                                >
                                    <IconButton
                                        transparent
                                        border={false}
                                        Icon={MoreVert}
                                        iconHtmlColor="var(--bs-gray)"
                                    />
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="p-0">
                                    <Dropdown.Item
                                        onClick={() => setShowEditModal(true)}
                                    >
                                        Edit
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => setShowDeleteModal(true)}
                                    >
                                        Delete
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        )
                    }
                </div>
                <div className="text-break">
                    <p className="text-whitespace mb-3">
                        {post.post_msg}
                    </p>
                    {
                        post.tagged.length > 0 && (
                            <div className="mt-2 d-flex flex-wrap small">
                                {
                                    post.tagged.map((tag) => (
                                        <Link
                                            key={tag.user_id}
                                            to={createSocialRoute(SocialRoutes.USER_PROFILE, { id: tag.user_id })}
                                            className={[styles.personTag, "me-2 mb-1 link-underline"].join(' ')}
                                        >
                                            {tag.user_socialhandle}
                                        </Link>
                                    ))
                                }
                            </div>
                        )
                    }
                    {
                        post.post_hashtags !== null && (
                            <div className="my-2 d-flex flex-wrap small">
                                {
                                    post.post_hashtags.split(',').map((hashtag) => (
                                        <button
                                            key={hashtag}
                                            className={[styles.hashtag, " me-2 mb-1 p-0 border-0 text-dark bg-transparent fw-bold"].join(' ')}
                                            onClick={() => handleHashtagSearch(hashtag)}
                                        >
                                            {hashtag}
                                        </button>
                                    ))
                                }
                            </div>
                        )
                    }
                    {
                        post.post_url !== null && (
                            <PostLinkPreview post={post} url={post.post_url} />
                        )
                    }
                    {
                        post.media.length > 0 && (
                            <div className={"mb-2 overflow-hidden w-100 " + styles.postCarousel}>
                                <BootstrapCarousel
                                    srcs={post.media.map(img => ({
                                        src: `/api/social/post/${post.post_id}/image/${img.pimg_id}`,
                                        mediaType: img.pimg_type,
                                        type: 'private'
                                    }))}
                                />
                            </div>
                        )
                    }
                    <ReactionAndCommentCount post={post} handleShowComments={handleShowComments} />

                    <hr className="mt-2 mb-0" />
                    <div className="d-flex my-1">
                        <ReactButton
                            post={post}
                            handleReaction={handlePostReact}
                        />

                        <IconButton
                            Icon={CommentIcon}
                            iconStyles={{ width: 20 }}
                            title="Show Comments"
                            label="Comment"
                            transparent
                            border={false}
                            iconHtmlColor="var(--black)"
                            className="flex-fill"
                            onClick={handleShowComments}
                        />
                        <IconButton
                            Icon={post.user_saved === 0 ? BookmarkBorderOutlined : Bookmark}
                            label={post.user_saved === 0 ? "Bookmark" : "Bookmarked"}
                            title={post.user_saved === 0 ? "Bookmark" : "Remove Bookmark"}
                            transparent
                            border={false}
                            iconHtmlColor="var(--black)"
                            className="flex-fill"
                            onClick={handlePostSave}
                        />
                    </div>
                </div>
                <DestructiveModal
                    title="Delete post?"
                    description={"The images, tags & comments in the post will also be deleted"}
                    show={showDeleteModal}
                    onClose={() => setShowDeleteModal(false)}
                    onConfirm={() => deletePost(post.post_id)}
                />
                <EditPostModal
                    show={showEditModal}
                    onClose={() => setShowEditModal(false)}
                    post={post}
                />
                <PostComments
                    show={showCommentsModal}
                    onClose={() => setShowCommentsModal(false)}
                    postId={post.post_id}
                />
            </div>
        </div>
    )
}
interface ReactButtonProps {
    post: Butlerr.Social.Post;
    handleReaction: (reaction: SocialPostReactionKey) => void;
}
function ReactButton({ post, handleReaction } : ReactButtonProps) {

    const [ showOverlay, setShowOverlay ] = useState(false);
    const timeoutRef = useRef<NodeJS.Timeout>()

    const handleShowToggle = (toggle: boolean) => {
        if (timeoutRef.current !== undefined) {
            //set timeout
            clearTimeout(timeoutRef.current);
        }
        const DELAY = 300
        timeoutRef.current = setTimeout(() => setShowOverlay(toggle), DELAY)
    }

    const reactions = [
        [LoveIcon, "Love", "O"],
        [LikeIcon, "Like", "L"],
        [DislikeIcon, "Dislike", "D"],
        [HighFiveIcon, "High Five", "H"],
        [ClapIcon, "Clap", "C"]
    ] as const;

    const reactionsOverlay = (
        <Popover
            placement="top"
            className={classNames("shadow rounded border-0", styles.reactionOverlay)}
            arrowProps={{
                style: { display: 'none' }
            }}
            onFocus={() => handleShowToggle(true)}
            onBlur={() => handleShowToggle(false)}
            onMouseOver={() => handleShowToggle(true)}
            onMouseOut={() => handleShowToggle(false)}
        >
            <Popover.Body className="px-3 py-2 d-flex">
                {(() => {
                    return reactions.map(([ Icon, label, reaction ], idx) => (
                        <button
                            key={idx}
                            title={label}
                            onClick={() => handleReaction(reaction)}
                            className={classNames(styles.reactionIconBtn)}
                        >
                            <Icon />
                        </button>
                    ))
                })()}
            </Popover.Body>
        </Popover>
    )

    const userReaction = reactions.find((reac) => reac[2] === post.user_reaction)

    return (
        <OverlayTrigger
            placement="top"
            overlay={reactionsOverlay}
            show={showOverlay}
            onToggle={handleShowToggle}
        >
            {({ ref, ...triggerHandler }) => (
                <IconButton
                    transparent
                    border={false}
                    iconHtmlColor="var(--black)"
                    className="flex-fill"

                    Icon={userReaction?.[0] ?? FavoriteBorder}
                    label={userReaction ? (
                        userReaction[1].endsWith('e') ? userReaction[1] + 'd' : userReaction[1] + 'ped'
                    ) : "React"}
                    onClick={() => {
                        const reaction = userReaction?.[2] ?? "O";
                        /**
                         * The function will remove reactions if the reaction passed is user's current reaction.
                         * Or will update the reaction.
                         * If a reaction is present, we want to remove it onClick. Else, we want to react with the default reaction "Love".
                         * So, pass in user's current reaction (if present to remove) or the default reaction.
                         */
                        handleReaction(reaction)
                    }}
                    ref={ref}
                    {...triggerHandler}
                />
            )}
        </OverlayTrigger>
    )
}

function ReactionAndCommentCount({ post, handleShowComments } : { post: Butlerr.Social.Post, handleShowComments: () => void }) {

    const totalReactionCount = post.likes + post.loves + post.dislikes + post.claps + post.high_fives;

    const topThreeReactions = useMemo(() => {
        const iconCountObj = [
            [ LikeIcon, post.likes, "like" ],
            [ LoveIcon, post.loves, "love" ],
            [ DislikeIcon, post.dislikes, "dislike" ],
            [ ClapIcon, post.claps, "clap" ],
            [ HighFiveIcon, post.high_fives, "high-five" ]
        ] as const;
        
        //filter icons to ones with count higher than 0, sort by count, then get the top 3 icons
        return iconCountObj.filter(([, count]) => count > 0)
            .sort((a, b) => b[1] - a[1])
            .slice(0, 3)

    }, [ post.likes, post.loves, post.dislikes, post.claps, post.high_fives ]);

    //Set enabled to false - don't trigger the fetch, just get if already present
    //This will let us get a more accurate count of comments - count of comments array + replies count on comment obj
    //If data is not there, just use comment count on post obj
    const { data: comments } = usePostComments(post.post_id, undefined, false);

    //from comments array
    const _commentCount = useMemo(() => {
        if (!comments) return 0;
        
        return comments.reduce((total, curr) => {
            return total + (curr.replies ?? 0);
        }, comments.length);
    }, [comments])

    const commentCount = _commentCount || post.comments;

    const [ reactionsModal, openReactions ] = useModal(ReactionListModal, { post });

    if (!(totalReactionCount + commentCount)) {
        return <></>
    }
    
    return (
        <div className="my-2 d-flex align-items-center">
            {
                totalReactionCount > 0 && (
                    <button onClick={openReactions} className="d-flex align-items-center p-0 border-0 text-start bg-transparent">
                        <div className="d-flex me-2">
                            {
                                topThreeReactions.map(([ Icon, count, label ]) => (
                                    <Icon
                                        key={label}
                                        fontSize="small"
                                        title={count + " " + label + (count > 1 ? "s" : "")}
                                        style={{
                                            width: 20,
                                            height: 20,
                                            marginRight: "2px"
                                        }}
                                    />
                                ))
                            }
                        </div>
                        <small className={styles.linkUnderline}>{totalReactionCount}</small>
                    </button>
                )
            }
            {
                post.comments > 0 && (
                    <div className="ms-auto">
                        <small onClick={handleShowComments} className={styles.linkUnderline + " cursor-pointer"}>
                            {commentCount} comment{commentCount !== 1 ? 's' : ''}
                        </small>
                    </div>
                )
            }
            { reactionsModal }
        </div>
    )
}

function PostLinkPreview({ post, url } : { post: Butlerr.Social.Post; url: string; }) {

    const title = post.post_url_title;
    const desc = post.post_url_desc;
    const image = post.post_url_image;

    const hasPreview = (title || desc);

    const youtubeId = useMemo(() => {
        const regex = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(\?\S*)?$/

        if (url.match(regex)) {
            return url.match(regex)?.[1] ?? null;
        }
        return null;
    }, [url])

    const [ showIframe, setShowIframe ] = useState(false);
    const handleOnIframeOpen = () => {
        setShowIframe(true);
    }

    const textContent = (
        <div className="px-3 py-2 py-lg-3 text-truncate d-flex flex-column" style={{ maxHeight: (hasPreview && youtubeId === null) ? '150px' : undefined }}>
            {title && (
                <>
                    <h6 className="mb-1 text-break text-wrap flex-shrink-0">
                        {title}
                    </h6>
                    <span className="d-block mb-2 small fw-light text-truncate flex-shrink-0" style={{ color: 'var(--bs-gray-700)' }}>
                        <LinkRounded fontSize="small" className="me-1" />
                        {url}
                    </span>
                </>
            )}
            {desc && <LineClampText maxLines={youtubeId === null ? 4 : 2} className="mb-1 text-wrap fw-normal flex-shrink-1" style={{ color: 'var(--bs-gray-700)' }}>{desc}</LineClampText>}
        </div>
    )

    if (!hasPreview || youtubeId === null) {
        return (
            <a
                className={classNames(
                    "mb-3 d-block fw-bold small",
                    hasPreview && "text-reset text-decoration-none"
                )}
                href={url.startsWith('http') ? url : 'https://' + url}
                target="_blank"
                rel="noopener noreferrer"
            >
                { !hasPreview ? url : (
                    <div
                        className={`rounded overflow-hidden d-flex flex-row ${styles.linkPreview} ${styles.linkBorder}`}
                    >
                        {
                            (image !== null && youtubeId === null) && (
                                <img
                                    alt="preview"
                                    src={image}
                                    className="border-end"
                                    width={150}
                                    height={150}
                                ></img>
                            )
                        }
                        { textContent }
                    </div>
                )}
            </a>
        )
    }

    return (
        <div className={`mb-3 small rounded overflow-hidden d-flex flex-column ${styles.linkBorder}`}>
            {
                youtubeId !== null && (
                    <div
                        className="w-100 position-relative"
                        style={{ height: '300px' }}
                    >
                        {
                            showIframe ? (
                                <iframe
                                    src={`https://www.youtube.com/embed/${youtubeId}?autoplay=1&mute=1`}
                                    frameBorder={0}
                                    loading="lazy"
                                    title="youtube-player"
                                    className="w-100 h-100"
                                    style={{ borderTopLeftRadius: 6, borderTopRightRadius: 6 }}
                                ></iframe>
                            ) : (
                                <button
                                    tabIndex={-1}
                                    className={`d-flex align-items-center justify-content-center position-absolute p-0 border-0 overflow-hidden w-100 h-100 ${styles.linkPreview}`}
                                    onClick={handleOnIframeOpen}
                                >
                                    {
                                        image !== null && (
                                            <img
                                                alt="url preview"
                                                src={image}
                                                className="position-absolute w-100 h-100"
                                            />
                                        )
                                    }
                                    <div tabIndex={0} className={`border border-3 border-light rounded-circle p-2 ${styles.playBtn}`}>
                                        <PlayArrowRounded />
                                    </div>
                                </button>
                            )
                        }
                    </div>
                )
            }
            <a
                href={url.startsWith('http') ? url : 'https://' + url}
                target="_blank"
                rel="noopener noreferrer"
                className={"text-reset text-decoration-none " + styles.linkPreview}
            >
                { textContent }
            </a>
        </div>
    )
}