import { useState, useEffect, useMemo, Fragment } from "react";
import { Container, Row, Col, Dropdown, Form } from "react-bootstrap";
import styles from './news.module.css';

import { formatDate } from "../../utils/HelperFunctions";
import { Card } from 'react-bootstrap';
import { FetchNewsOptions, useNews, useNewsMutations } from "../../../services/news.service";
import { Bookmark, BookmarkBorder, ShareRounded, CloseRounded, BookmarkBorderRounded, TimelapseRounded, TimelineRounded, RssFeedRounded, CheckOutlined, SettingsRounded, FormatListBulletedRounded, GridViewRounded, AccessTimeRounded, CollectionsBookmarkRounded } from "@mui/icons-material";
//import { ReactComponent as GridIcon } from "../../assets/icons/grid.svg";
//import { ReactComponent as ListIcon } from "../../assets/icons/list.svg";
import IconButton from "../../utils/IconButton";

import asst_placeholder from "../../../assets/img_placeholder.jpg";
import BootstrapSpinner from "../../utils/BootstrapSpinner";

import EmptyMessage from "../../utils/EmptyMessage";
import useQuery from "../../../hooks/useQuery";
import ErrorPage from "../../utils/ErrorPage";
import classNames from "classnames";
import LineClampText from "../../utils/LineClampText";
import { Modal } from "react-bootstrap";
import { Butlerr } from "../../../types/butlerr";
import NewPostContainer from "../NewPostContainer";
import { useHistory } from "react-router-dom";
import useModal from "../../../hooks/useModal";
import ChannelsModal from "./ChannelsModal";
import NewsHistory from "./NewsHistory";
import ToggleButtonGroup, { ToggleItem } from "../../ui/ToggleButtonGroup";
import InfiniteScroller from "../../ui/InfiniteScroller";

enum NewsViews {
    LIST, GRID
}

type KeysWithType<T, V> = {[K in keyof T]-?: T[K] extends V ? K : never}[keyof T];
//Maps for item keys in URL search
type SortKeys = 'n' | 't' | 'bd';
const SORT_MAP : Record<SortKeys, FetchNewsOptions['sort']> = {
    n: "new",
    t: "trending",
    bd: "bookmark_date"
} as const;

type FilterKeys = 's' | 'b' | 'h';
/**
 * If undefined, we select `subscribed_only` by default.
 */
const FILTER_MAP : Record<FilterKeys, KeysWithType<Required<FetchNewsOptions>, boolean> | "history"> = {
    s: "subscribed_only",
    b: "bookmarked_only",
    h: "history"
} as const;

export default function NewsList({ emptyMessage } : { emptyMessage?: string }) {

    const search = useQuery();
    const routerHistory = useHistory();

    const { history, ...params } = useMemo(() => {

        const params : FetchNewsOptions & { history: boolean } = {
            history: false
        };

        //Note: `t` search key is used in SocialSearch component to determine which tab the search is in.
        const query = decodeURIComponent(search.get("q") || "");

        if (query) params.query = query;

        const filterKey = FILTER_MAP[decodeURIComponent(search.get("f") || "") as FilterKeys];
        if (filterKey === "history") {
            params.history = true;
        }
        else {
            //set matching filter key to true;
            //if undefined, show following only
            params[filterKey ?? 'subscribed_only'] = true;
        }

        const sortKey = SORT_MAP[decodeURIComponent(search.get("s") || "") as SortKeys] ?? "new";
        //If sort key specified is 'bookmark_date' & it's not `bookmarked_only`, use `latest` instead
        if (sortKey === 'bookmark_date' && !params.bookmarked_only) {
            params.sort = 'new';
            //replace in url
            search.set('s', 'n');
            routerHistory.replace({
                search: search.toString()
            })
        }
        else params.sort = sortKey;

        return params;
    }, [routerHistory, search])

    //Query States
    const { news, query } = useNews(params);
    const { isLoading, error, refetch, hasNextPage, fetchNextPage } = query;

    // Speical case: Always refetch when bookmarked_only becomes `true`
    useEffect(() => {
        if (params.bookmarked_only) {
            refetch();
        }
    }, [params.bookmarked_only, refetch]);

    const [viewMode, setViewMode] = useState<NewsViews>(NewsViews.LIST);

    const [ historySearch, setHistorySearch ] = useState("");

    return (
        <Container fluid={true} className="p-0">
            <Row className="mb-3">
                <Col>
                    <NewsHeader
                        view={viewMode}
                        setView={setViewMode}
                        glow={news?.length === 0}
                        historySearch={historySearch}
                        setHistorySearch={setHistorySearch}
                    />
                </Col>
            </Row>
            <Row className={classNames({
                [styles.list]: viewMode === NewsViews.LIST,
                [styles.grid]: viewMode === NewsViews.GRID
            })}>
                {
                    history === true ? (
                        <NewsHistory searchValue={historySearch} />
                    )
                    : (news === undefined || news.length === 0) ? (
                        isLoading ? <BootstrapSpinner /> :
                        error ? <ErrorPage message={error.message} handleRetry={refetch} /> :
                        <EmptyMessage className="fs-5" message={emptyMessage ?? (
                            params.subscribed_only ? "Add subscriptions and get the latest property news now"
                            : "Nothing to see here"
                        )} />
                    ) : (
                        <InfiniteScroller
                            hasNextPage={hasNextPage}
                            fetchNextPage={fetchNextPage}
                            isLoading={isLoading}
                        >
                            {
                                viewMode === NewsViews.LIST ? (
                                    news.map((item) => (
                                        <NewsListItem
                                            key={item.news_id}
                                            news={item}
                                        />
                                    ))
                                ) : (
                                    news.map((item) => (
                                        <NewsGridItem
                                            key={item.news_id}
                                            news={item}
                                        />
                                    ))
                                )
                            }
                        </InfiniteScroller>
                    )
                }
            </Row>
        </Container>
    )
}

interface NewsHeaderProps {
    view: NewsViews;
    setView: (state: NewsViews) => void;

    glow: boolean;

    historySearch: string;
    setHistorySearch: (val: string) => void;
}

function NewsHeader({ view, setView, glow, historySearch, setHistorySearch } : NewsHeaderProps) {

    const search = useQuery();
    const history = useHistory();

    const handleFilterChange = (filter: FilterKeys) => {
        search.set('f', filter);
        history.replace({
            search: search.toString()
        })

        //when switching to `bookmarked_only`, set default sort
        if (filter === 'b') {
            handleSortChange('bd');
        }
        //when switching away AND the active sort is bookmarked date, change to latest
        else if (activeSort === 'bd') {
            handleSortChange('n');
        }
    }
    const handleSortChange = (sort: SortKeys) => {
        search.set('s', sort);
        history.replace({
            search: search.toString()
        })
    }

    type Item = Omit<ToggleItem, 'active' | 'render'>;

    let activeFilter = decodeURIComponent(search.get('f') || "") as FilterKeys;
    if (!(activeFilter in FILTER_MAP)) activeFilter = 's';

    const filterItems : Record<FilterKeys, Item> = {
        s: {
            Icon: RssFeedRounded,
            label: "Subscribed"
        },
        b: {
            Icon: BookmarkBorderRounded,
            label: "Bookmarked"
        },
        h: {
            Icon: AccessTimeRounded,
            label: "History"
        }
    }

    let activeSort = decodeURIComponent(search.get('s') || "") as SortKeys;
    if (!(activeSort in SORT_MAP)) activeSort = 'n';

    const sortItems : Record<SortKeys, Item> = {
        bd: {
            Icon: CollectionsBookmarkRounded,
            label: "Latest bookmarks"
        },
        n: {
            Icon: TimelapseRounded,
            label: "Latest articles",
        },
        t: {
            Icon: TimelineRounded,
            label: "Trending recently"
        }
    };

    const [ modal, open ] = useModal(ChannelsModal)

    const rightSide = activeFilter === 'h' ? (
        <Form.Control
            value={historySearch}
            onChange={(e) => setHistorySearch(e.target.value)}
            className="ms-auto rounded-pill"
            style={{ maxWidth: '300px', width: '50%' }}
            type="text"
            placeholder="title, feed, date, month, year..."
        />
    ) : (
        <div className="ms-auto d-flex">
            <div className="d-flex align-items-center my-auto me-3" style={{ height: 'fit-content' }}>
                <IconButton
                    transparent
                    border={false}
                    Icon={FormatListBulletedRounded}
                    title="List view"
                    className={classNames(view === NewsViews.LIST ? "text-black" : "text-secondary")}
                    onClick={() => setView(NewsViews.LIST)}
                />
                {/* separator */}
                <div
                    style={{
                        width: '1px'
                    }}
                    className="mx-2 my-1 align-self-stretch bg-secondary"
                ></div>
                <IconButton
                    transparent
                    border={false}
                    Icon={GridViewRounded}
                    title="Grid view"
                    className={classNames(view === NewsViews.GRID ? "text-black" : "text-secondary")}
                    onClick={() => setView(NewsViews.GRID)}
                />
            </div>

            <Dropdown align="end" className="me-2">
                <Dropdown.Toggle
                    as="span"
                    bsPrefix=" "
                >
                    <IconButton
                        transparent
                        Icon={ sortItems[activeSort].Icon }
                        title="Sort"
                        label=""
                        className="rounded-circle p-2 bg-light text-primary"
                        iconHtmlColor="var(--primary)"
                    />
                </Dropdown.Toggle>
                
                <Dropdown.Menu>
                    {
                        Object.entries(sortItems).map(([ key, { Icon, label } ]) => {
                            if (key === 'bd' && activeFilter !== 'b') return <Fragment key={key} />

                            const isActive = key === activeSort;
                            return (
                                <Dropdown.Item
                                    key={key}
                                    disabled={isActive}
                                    className={classNames("p-2 d-flex align-items-center", isActive && "text-primary")}
                                    onClick={() => handleSortChange(key as SortKeys)}
                                >
                                    <Icon fontSize="small" className="me-2" />
                                    {label}

                                    { isActive && (
                                        <CheckOutlined fontSize="small" className="text-primary ms-auto" />
                                    ) }
                                </Dropdown.Item>
                            )
                        })
                    }
                </Dropdown.Menu>
            </Dropdown>

            <IconButton
                transparent
                Icon={SettingsRounded}
                label=""
                title="Manage subscriptions"
                className={classNames("rounded-circle p-2 border-primary", glow && styles.glowing)}
                iconHtmlColor="var(--primary)"
                onClick={open}
            />
            { modal }
        </div>
    );

    return (
        <div className="d-flex flex-wrap align-items-center justify-content-between" style={{ rowGap: '0.5rem' }}>
            <ToggleButtonGroup
                className="d-flex"
                items={Object.entries(filterItems).map(([ key, item ]) => ({
                    ...item,
                    key: key as FilterKeys,
                    active: key === activeFilter
                }))}
                onSelect={(item) => {
                    handleFilterChange(item.key)
                }}
            />
            { rightSide }
        </div>
    )
}

interface NewsItemProps {
    news: Butlerr.News;
}

function NewsGridItem({ news } : NewsItemProps) {

    const [ imageError, setImageError ] = useState(false);

    //mutations
    const { mutate: bookmarkNews } = useNewsMutations('BOOKMARK');
    const { mutate: unbookmarkNews } = useNewsMutations('REMOVE_BOOKMARK');

    const { mutate: handleNewsClick } = useNewsMutations('ADD_HISTORY');

    //share modal
    const [ showShare, setShowShare ] = useState(false);

    const imageContent = (
        <img
            alt=""
            className={classNames({
                [styles.image]: true,
                "shadow-sm": (news.news_mediaurl && !imageError) || !news.ncha_imageurl,
                //either no news image OR image load error & there's a channel image
                [styles.channelImage]: (!news.news_mediaurl || imageError) && news.ncha_imageurl
            })}
            src={
                //If no news image OR image load error, display channel image OR placeholder
                (!news.news_mediaurl || imageError) ?
                    (news.ncha_imageurl ?? asst_placeholder)
                : news.news_mediaurl
            }
            onError={() => setImageError(true)}
        />
    );

    const image = (!news.news_mediaurl || imageError) && news.ncha_imageurl ? (
        <div className={classNames(styles.imageContainer, "p-2 shadow-sm rounded")}>
            { imageContent }
        </div>
    ) : imageContent;

    return (
        <Col xs={6} lg={4} className="mb-3 pe-0">
            <Card className={classNames(styles.news, "shadow border-0 h-100")}>
                { image }

                <Card.Body className="py-3 d-flex flex-column">
                    <LineClampText maxLines={2} className={"mb-2 me-2 fw-bold text-wrap"}>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: 'black' }}
                            href={news.news_url}
                            onClick={() => handleNewsClick(news.news_id)}
                            title={news.news_title}
                        >
                            {news.news_title}
                        </a>
                    </LineClampText>
                    <Card.Text as={LineClampText} maxLines={3} className="text-muted small mb-2">
                        {news.news_synopsis}
                    </Card.Text>

                    <div style={{ fontSize: "small" }} className="text-truncate mt-auto">
                        <p className="mb-0 text-wrap">
                            <span>{news.news_author}</span>
                            <span>
                                { news.news_author && <span>&nbsp;&bull; </span> }
                                { news.ncha_name }
                            </span>
                            <span>
                                { news.ncha_name && <span> &bull; </span> }
                                {formatDate(news.news_date)}
                            </span>
                        </p>
                    </div>
                </Card.Body>
                <Card.Footer className="d-flex p-0">
                    <IconButton
                        transparent
                        border={false}
                        Icon={news.bookmarked ? Bookmark : BookmarkBorder}
                        iconHtmlColor="var(--primary)"
                        onClick={() => {
                            if (news.bookmarked) unbookmarkNews(news.news_id);
                            else bookmarkNews(news.news_id);
                        }}
                        title={news.bookmarked ? "Remove bookmark" : "Bookmark"}
                        className="py-3"
                        style={{ width: "calc(50% - 0.5px)", borderRadius: 0, borderBottomLeftRadius: 12 }}
                    />
                    <div className="vr my-1" />
                    <IconButton
                        transparent
                        border={false}
                        Icon={ShareRounded}
                        iconHtmlColor="var(--primary)"
                        onClick={() => setShowShare(true)}
                        title="Share"
                        className="py-3"
                        style={{ width: "calc(50% - 0.5px)", borderRadius: 0, borderBottomRightRadius: 12 }}
                    />
                </Card.Footer>
            </Card>

            <ShareNewsModal
                show={showShare}
                onClose={() => setShowShare(false)}
                content={news.news_title}
                link={news.news_url}
            />
        </Col>
    )
}

function NewsListItem({ news } : NewsItemProps) {

    const [ imageError, setImageError ] = useState(false);

    //mutations
    const { mutate: bookmarkNews } = useNewsMutations('BOOKMARK');
    const { mutate: unbookmarkNews } = useNewsMutations('REMOVE_BOOKMARK');

    const { mutate: handleNewsClick } = useNewsMutations('ADD_HISTORY');

    //share modal
    const [ showShare, setShowShare ] = useState(false);

    const imageContent = (
        <img
            alt=""
            className={classNames({
                [styles.image]: true,
                "shadow-sm": (news.news_mediaurl && !imageError) || !news.ncha_imageurl,
                //either no news image OR image load error & there's a channel image
                [styles.channelImage]: (!news.news_mediaurl || imageError) && news.ncha_imageurl
            })}
            src={
                //If no news image OR image load error, display channel image OR placeholder
                (!news.news_mediaurl || imageError) ?
                    (news.ncha_imageurl ?? asst_placeholder)
                : news.news_mediaurl
            }
            onError={() => setImageError(true)}
        />
    );

    const image = (!news.news_mediaurl || imageError) && news.ncha_imageurl ? (
        <div className={classNames(styles.imageContainer, "p-2 shadow-sm rounded")}>
            { imageContent }
        </div>
    ) : imageContent;

    return (
        <Col xs={12}>
            <Card as={Container} className={classNames(styles.news, "shadow mb-2 border-0 px-4 py-3")}>
                <Card.Body as={Row} className="p-0">
                    <Col xs={4}>
                        { image }
                        <div className="mt-1 small text-muted">
                            <span>{news.news_author}</span>
                            <br />
                            <span>
                                { formatDate(news.news_date) }
                            </span>
                        </div>
                    </Col>

                    <Col xs={8}>
                        <div className="h-100 d-flex flex-column">
                            <LineClampText maxLines={3} className="d-flex flex-row justify-content-md-start">
                                <a
                                    className="text-start fw-bold justify-content-md-start"
                                    target="_blank" rel="noopener noreferrer"
                                    style={{ color: 'black' }}
                                    href={news.news_url}
                                    onClick={() => handleNewsClick(news.news_id)}
                                >
                                    {news.news_title}
                                </a>
                            </LineClampText>
                            <Card.Text as={LineClampText} maxLines={3} className={styles.description}>
                                {news.news_synopsis}
                            </Card.Text>

                            <div className="small mt-auto d-flex flex-wrap">
                                <span>
                                    { news.ncha_name }
                                </span>
                                <IconButton
                                    transparent
                                    Icon={news.bookmarked ? Bookmark : BookmarkBorder}
                                    onClick={() => {
                                        if (news.bookmarked) unbookmarkNews(news.news_id);
                                        else bookmarkNews(news.news_id);
                                    }}
                                    className="ms-auto me-2"
                                    title={news.bookmarked ? "Remove bookmark" : "Bookmark"}
                                />
                                <IconButton
                                    transparent
                                    Icon={ShareRounded}
                                    onClick={() => setShowShare(true)}
                                    title="Share"
                                />
                            </div>
                        </div>
                    </Col>
                </Card.Body>
            </Card>

            <ShareNewsModal
                show={showShare}
                onClose={() => setShowShare(false)}
                content={news.news_title}
                link={news.news_url}
            />
        </Col>
    )
}

interface ShareNewsModalProps {
    show: boolean;
    onClose: () => void;

    content: string;
    link: string;
}

export function ShareNewsModal({ show, onClose, content, link } : ShareNewsModalProps) {

    link = new URL(link, window.location.origin).toString();

    return (
        <Modal show={show} centered onHide={onClose} size="lg">
            <Modal.Header>
                <Modal.Title>Share with community</Modal.Title>
                <IconButton
                    Icon={CloseRounded}
                    title="Close"
                    className="ms-auto"
                    onClick={onClose}
                />
            </Modal.Header>
            <Modal.Body className="p-0">
                <NewPostContainer
                    onSuccess={onClose}
                    initialPostMessage={content}
                    initialPostUrl={link}
                    initialHashtags={['news']}
                />
            </Modal.Body>
        </Modal>
    )
}