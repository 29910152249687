import { Table } from 'react-bootstrap';

interface BootstrapTableInterface {
    headerNames: string[];
    children: React.ReactNode;
    className?: string;
}

const BootstrapTable = ({ headerNames, children, className }: BootstrapTableInterface) => {
    return (
        <Table borderless hover responsive className={className}>
            <colgroup>
                {headerNames.map((_, index) => (
                    <col key={index} span={1}></col>
                ))}
            </colgroup>
            <thead>
                <tr className="text-secondary text-uppercase">
                    {headerNames.map((name, index) => (
                        <th key={index}>{name}</th>
                    ))}
                </tr>
            </thead>
            <tbody>{children}</tbody>
        </Table>
    );
};

export default BootstrapTable;
