import { useParams } from "react-router"
import { useSocialChannelMutations, useSocialChannels } from "../../../services/social-channels.service";
import { Butlerr } from "../../../types/butlerr.d";
import BootstrapSpinner from "../../utils/BootstrapSpinner";
import ErrorPage from "../../utils/ErrorPage";
import ChannelAvatar from "./ChannelAvatar";
import IconButton from "../../utils/IconButton";
import { formatDateByTimeDifference } from "../../utils/HelperFunctions";
import classNames from "classnames";
import PostList from "../PostList";
import useModal from "../../../hooks/useModal";
import { FollowListModal } from "../network/UserListModal";

export default function ChannelDetails() {

    const { id: channelId } = useParams<{ id: string }>();

    const { data: channels, error } = useSocialChannels({ channel: Number(channelId) ?? -1 });
    const channel = channels?.pages?.[0]?.result?.[0];

    if (channel === undefined) {
        if (error) return <ErrorPage status={error.status} message={error.message} />
        return <BootstrapSpinner />
    }

    return (
        <div>
            <ChannelHeader channel={channel} className="mb-3" />
            <PostList channel={channel.chan_id} />
        </div>
    )
}

interface ChannelProps extends React.HTMLAttributes<HTMLDivElement> {
    channel: Butlerr.Social.Channel;
}

function ChannelHeader({ channel, className, ...props } : ChannelProps) {

    const { mutate: follow, isLoading: fl } = useSocialChannelMutations('FOLLOW');
    const { mutate: unfollow, isLoading: ul } = useSocialChannelMutations('UNFOLLOW');

    const [ followerModal, showFollowers ] = useModal(FollowListModal, {
        chan_id: channel.chan_id,
        follower: channel.followers
    })

    return (
        <div className={classNames(className, "d-flex flex-row align-items-center p-4 bg-light shadow-sm rounded-4")} {...props}>
            <ChannelAvatar
                channel={channel}
                width={120}
                height={120}
                className="me-4 flex-shrink-0"
            />
            <div className="w-100">
                <div className="mb-2 d-flex flex-row align-items-center fw-bold">
                    <h2 className="mb-0 text-break">{channel.chan_name}</h2>
                </div>
                <div className="d-flex flex-column text-break">
                    <p className="mb-1 text-muted">
                        <small>{channel.wkpf_name} &bull; </small>
                        <small> Joined: {formatDateByTimeDifference(channel.chan_created)}</small>
                    </p>
                    <div className="d-flex flex-column">
                        {
                            channel.chan_desc !== null && (
                                <p className="mb-0">{channel.chan_desc}</p>
                            )
                        }
                    </div>
                </div>
            </div>
            <div className="ms-auto d-flex flex-column align-items-end flex-shrink-0">
                <IconButton
                    className={classNames(
                        'rounded-4 px-3 py-1 mb-2',
                        channel.user_follow === 1 && 'bg-transparent border-2 border-dark text-dark',
                        channel.user_follow !== 1 && 'border-2 border-primary'
                    )}
                    label={ channel.user_follow === 1 ? 'Unfollow' : 'Follow' }
                    title={ channel.user_follow === 1 ? 'Unfollow' : 'Follow' }
                    disabled={fl || ul}
                    onClick={() => {
                        if (channel.user_follow === 1) unfollow(channel.chan_id);
                        else follow(channel.chan_id);
                    }}
                />
                <button onClick={showFollowers} className="text-secondary p-0 border-0 bg-transparent text-start link-underline">
                    {channel.followers} Follower{channel.followers > 1 && 's'}
                </button>
                { followerModal }
            </div>
        </div>
    )
}