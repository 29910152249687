import { DeleteOutlined, NoteAltRounded, PublicRounded, ShareRounded, ThumbDown, ThumbUp, Visibility, VisibilityOff } from '@mui/icons-material';
import { useEffect, useMemo, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { Route, Switch, useHistory } from 'react-router-dom';
import { articleQueries, FetchArticlesOptions, useArticleMutations, useArticles } from '../../../services/partnerArticle.service';
import { Butlerr } from '../../../types/butlerr';
import LineClampText from '../../utils/LineClampText';
import { createPartnerRoute, PartnerRoutes } from '../Work';
import { CreateEditArticleForm } from './ArticleForm';
import ArticleTemplatesModal from './ArticleTemplatesModal';
import styles from '../../assets/asset.module.css';
import { formatDate } from '../../utils/HelperFunctions';
import { useButlerrUser } from '../../../services/user.service';
import butlerrPlaceholder from '../../../assets/img_placeholder.jpg';
import IconButton from '../../utils/IconButton';
import BootstrapSpinner from '../../utils/BootstrapSpinner';
import ToggleButtonGroup, { ToggleItem } from "../../ui/ToggleButtonGroup";
import ButlerrListCardAdd from '../../utils/ButlerrListCardAdd';
import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";
import socialStyles from '../../social/social.module.css'
import { isUserAdmin } from '../workProfiles/AllPartnersPage';
import { useWorkProfiles, useWorkProfile } from '../../../services/partner.service';
import { ShareArticleModal } from './ShareArticleModal';
import { useQueryClient } from 'react-query';
import useQuery from '../../../hooks/useQuery';
import { Pagination } from '@mui/material';
import PreviewArticleModal from './PreviewArticleModal';
import DestructiveModal from '../../utils/DestructiveModal';

type FilterKeys = 'd' | 'p'
const FILTER_MAP : Record<FilterKeys, FetchArticlesOptions['filter']> = {
    d: "draft",
    p: "published"
} as const;
export const Articles = () => {
    return (
        <Switch>
            <Route path={PartnerRoutes.EDITARTICLE}>
                <CreateEditArticleForm />
            </Route>
            <Route path={PartnerRoutes.CREATEARTICLE}>
                <CreateEditArticleForm />
            </Route>
            <Route path={PartnerRoutes.ARTICLES}>
                <ArticlesList />
            </Route>
        </Switch>
    );
};

const ArticlesList = () => {
    
    const {data: workProfile} = useWorkProfiles();
    const [showModal, setShowModal] = useState(false);
    // const {data: dbUser} = useButlerrUser();

    const history = useHistory();
    const search = useQuery()
    const page = parseInt(search.get('page') ?? '1');

    const params = useMemo(() => {
        const params : FetchArticlesOptions = {};

        const query = decodeURIComponent(search.get("q") || "");

        if (query) params.search = query;

        const filterKey = FILTER_MAP[decodeURIComponent(search.get("f") || "") as FilterKeys] ?? "draft"; //if there are no filters, default to draft
        params.filter = filterKey;
        return params;
    }, [search])

    const { data: _articles, isLoading, refetch, isRefetching, fetchNextPage } = useArticles(params);
    const articles = _articles?.pages[page-1]?.result

    const handleFilterChange = (filter: FilterKeys) => {
        search.set('page', '1')
        search.set('f', filter);
        history.replace({
            search: search.toString()
        })
    }

    const handleChange = (event: any, value: number) => {
        search.set('page', value.toString())
        history.replace({
            search: search.toString()
        })
        fetchNextPage({pageParam: value})
        window.scrollTo({top: 0, behavior: "smooth"})
    }

    useEffect(() => {
        if(!_articles?.pages[page - 1]) {
            fetchNextPage({pageParam: page})
            window.scrollTo({top: 0, behavior: "smooth"})
        }
    }, [_articles, page, fetchNextPage])

    useEffect(() => {
        if (params.filter || params.search) {
            refetch();
        }
    }, [params.filter, params.search, refetch])

    useEffect(() => {
        if (search.get('f') === null) {
            setActiveFilter('d')
            search.set('page', '1')
        }
    }, [search])

    const handleSearchConfirm = (input: string) => {
        search.set('q', input);
        search.set('page', '1')
        history.replace({
            search: search.toString()
        })
    }

    type Item = Omit<ToggleItem, 'active' | 'render'>
    type FilterKeys = 'p' | 'd'
    const [activeFilter, setActiveFilter] = useState<FilterKeys>((search.get("f") || "d") as FilterKeys);
    const filterItems : Record<FilterKeys, Item> = {
        d: {
            Icon: NoteAltRounded,
            label: "Drafts"
        },
        p: {
            Icon: PublicRounded,
            label: "Published"
        },
    }
    const [searchString, setSearchString] = useState(search.get("q") || '');

    if (isLoading || isRefetching) return <BootstrapSpinner />
    return (
        <>
            <div className="d-flex justify-content-between mb-4">
                <div className="align-items-end d-flex">
                    <ToggleButtonGroup
                        items={Object.entries(filterItems).map(([ key, item ]) => ({
                            ...item,
                            key: key as FilterKeys,
                            active: key === activeFilter
                        }))}
                        onSelect={(item) => {
                            setActiveFilter(item.key)
                            handleFilterChange(item.key)
                        }}
                    />
                </div>
                <div className={socialStyles.sidebarSearch}>
                    <Form.Control placeholder='search keywords...'
                        className="shadow"
                        value={searchString}
                        onChange={(e) => {setSearchString(e.target.value)}}
                        onKeyUp={(ev) => {
                            if (ev.key === 'Enter' || ev.keyCode === 13) {
                                handleSearchConfirm(searchString)
                            }
                        }}
                        />
                    <IconButton
                        transparent
                        border={false}
                        Icon={SearchIcon}
                        iconHtmlColor="var(--black)"
                        iconStyles={{ width: 17, height: 17, overflow: 'visible' }}
                        onClick={() => {handleSearchConfirm(searchString)}}
                    />
                </div>
            </div>

            <ArticleTemplatesModal
                show={showModal}
                onModalClose={() => {setShowModal(false)}}
            />

            <Row className="gy-3 gy-lg-4" md={3} lg={3} xl={4}>
                {articles?.map((article, index) => {
                        return (
                            <Col key={index} md={4} lg={4} xl={3}>
                                <CardItem article={article} />
                            </Col>
                        );
                    })}
                {(workProfile !== undefined && workProfile.length !== 0) && (
                    <Col md="4" xl="3" lg="4">
                    <ButlerrListCardAdd
                        title="Add article"
                        style={{ minWidth: '102px', minHeight: '382px' }}
                        handleClick={() => {setShowModal(true)}}
                        text="Add article"
                    />
                </Col>
                )}
                
            </Row>
            {
                // && (Math.ceil(_articles?.pages[0]?.total  / _articles?.pages[0].limit) > 1) <- this is for if page = 1 then don't show pagination
                (_articles?.pages !== undefined && articles?.length !== 0 && _articles?.pages[0]?.total > 11) && ( //articles exists and pages exist
                    <div className='d-flex justify-content-center my-3'>
                        <Pagination count={Math.ceil(_articles?.pages[0]?.total  / _articles?.pages[0].limit)}
                            page={page}
                            onChange={handleChange}
                        />
                    </div>
                )
            }
        </>
    );
};

interface CardItemProps {
    article: Butlerr.Article;
}

const CardItem = ({ article }: CardItemProps) => {
    const history = useHistory();
    const {data: workProfile} = useWorkProfile(article.wkpf_id);
    const [showShare, setShowShare] = useState(false);
    const url = window.location.href.split('/work')[0] + "/articles/" + article.arti_id
    const {data: dbUser} = useButlerrUser();
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const {
        mutate: editArticle,
        isLoading: isEditLoading,
    } = useArticleMutations('EDIT_ARTICLE');
    const queryClient = useQueryClient();

    const {
        mutate: deleteArticle,
        isLoading: isDeleteLoading
    } = useArticleMutations('DELETE_ARTICLE');

    const onDeleteConfirm = () => {
        if (article.arti_id === undefined) {
            history.push('/work/articles')
        } else {
            deleteArticle(article.arti_id);
        }
        
    }

    const handleUnpublish = () => {
        editArticle({
            arti_keywords: article.arti_keywords ?? '',
            arti_synopsis: article.arti_synopsis ?? '',
            arti_text: article.arti_text ?? '',
            arti_title: article.arti_title ?? '',
            arti_templateID: article.arti_templateID ?? '', 
            arti_imageKey: undefined, 
            arti_status: 0, 
            arti_id: article.arti_id
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(articleQueries.ARTICLES)
            }
        }) // will only update the status of article
    }

    const handlePublish = () => {
        editArticle({
            arti_keywords: article.arti_keywords ?? '',
            arti_synopsis: article.arti_synopsis ?? '',
            arti_text: article.arti_text ?? '',
            arti_title: article.arti_title ?? '',
            arti_templateID: article.arti_templateID ?? '', 
            arti_imageKey: undefined, 
            arti_status: 1, 
            arti_id: article.arti_id
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(articleQueries.ARTICLES)
            }
        }) // will only update the status of article
    }
    const [showPreview, setShowPreview] = useState(false)
    if (workProfile === undefined || isEditLoading || isDeleteLoading) {
        return <BootstrapSpinner />
    }
    const isAdmin = isUserAdmin(workProfile)
    return (
        <>
        <PreviewArticleModal 
            show={showPreview}
            onClose={() => {setShowPreview(false)}}
            article={article}
            arti_imagekey={article.arti_imagekey ?? ''}
            file={undefined}
        />
            <Card
                className={
                    'cursor-pointer border-0 shadow ' + styles.assetsListCard
                }
            >
                <div
                    className={
                        'card-img-top ' + styles.assetsListCardImageContainer
                    }
                >
                    <Card.Img
                    onClick={() => {
                        setShowPreview(true)
                        if (article.arti_status === 1) { //if the article is published
                            history.push(createPartnerRoute(PartnerRoutes.ARTICLE).replace(':articleId', (article.arti_id).toString()))
                        } else if (article.arti_status === 0 && (isAdmin || dbUser?.user_id === article.user_id)) { //if the article is not published && the user is authorized
                            history.push(createPartnerRoute(PartnerRoutes.EDITARTICLE).replace(':articleId', (article.arti_id).toString()).replace(':templateId', (article.arti_templateID ?? 0).toString()))
                        } else if (article.arti_status === 0 && !(isAdmin || dbUser?.user_id === article.user_id)) { //if user is normal staff && article is draft
                            setShowPreview(true)
                        }
                    }}
                    className={styles.assetsListCardImage}
                    src={article.arti_imagekey ?? butlerrPlaceholder}
                    variant="top"
                />
                </div>

                <Card.Body className="p-3"
                    onClick={() => {
                        setShowPreview(true)
                        if (article.arti_status === 1) { //if the article is published
                            history.push(createPartnerRoute(PartnerRoutes.ARTICLE).replace(':articleId', (article.arti_id).toString()))
                        } else if (article.arti_status === 0 && (isAdmin || dbUser?.user_id === article.user_id)) { //if the article is not published && the user is authorized
                            history.push(createPartnerRoute(PartnerRoutes.EDITARTICLE).replace(':articleId', (article.arti_id).toString()).replace(':templateId', (article.arti_templateID ?? 0).toString()))
                        } else if (article.arti_status === 0 && !(isAdmin || dbUser?.user_id === article.user_id)) { //if user is normal staff && article is draft
                            setShowPreview(true)
                        }
                    }}
                    >
                    <LineClampText
                        title={(article.arti_lastedited && article.arti_lastedited !== null
                            ? 'Modified: ' +
                              formatDate(article.arti_lastedited)
                            : formatDate(article.arti_date)) ?? undefined}
                        maxLines={1}
                        className="small text-muted mb-1"
                    >
                        {article.arti_lastedited
                            ? 'Modified: ' +
                              formatDate(article.arti_lastedited)
                            : formatDate(article.arti_date)}
                    </LineClampText>

                    <LineClampText
                        title={(article.arti_title?.length === 0 && article.arti_title !== null ? ("Untitled") : (article.arti_title)) ?? undefined}
                        maxLines={2}
                        className={
                            'card-subtitle fw-semibold mb-1 '
                        }
                        style={{ fontSize: 16, height: '48px' }}
                    >
                        {article.arti_title?.length === 0 || article.arti_title === null ? ("Untitled") : (article.arti_title)}
                    </LineClampText>

                    <LineClampText maxLines={1} className="small muted text-secondary mb-1">
                        Author: {article?.user_socialhandle}
                    </LineClampText>

                    <hr className='my-1' />

                    <LineClampText
                        maxLines={3}
                        className={
                            'card-text small ' +
                            styles.assetsListCardDescription
                        }
                        title={(article.arti_synopsis?.length === 0 && article.arti_synopsis !== null) || article.arti_synopsis === null ? ('No synopsis available') : (article.arti_synopsis)}
                    >
                        {(article.arti_synopsis?.length === 0 && article.arti_synopsis !== null) || article.arti_synopsis === null ? ('No synopsis available') : (article.arti_synopsis)}
                    </LineClampText>
                    {article.arti_status === 1 && (
                        <>
                            <LineClampText maxLines={1}
                                className={
                                    'card-text small mb-0 text-start'
                                    }>
                                <span className='me-2 text-secondary'><Visibility color='disabled' style={{fontSize: '13.5'}} /> {article.viewCounts} </span>
                                <span className='me-2 text-secondary'><ThumbUp color='disabled' style={{fontSize: '13.5'}} /> {article.likeCount ?? 0} </span>
                                <span className='text-secondary'><ThumbDown color='disabled' style={{fontSize: '13.5'}} /> {article.dislikeCount ?? 0} </span>
                            </LineClampText>
                        </>
                    )}
                </Card.Body>
                        <Card.Footer className='d-flex p-0'>
                        {article.arti_status === 1 ? (
                            <>
                                <IconButton
                                    transparent
                                    border={false}
                                    Icon={VisibilityOff}
                                    iconHtmlColor="var(--primary)"
                                    onClick={() => {
                                        handleUnpublish()
                                    }}
                                    title="Unpublish"
                                    className="w-100"
                                    style={{ borderRadius: 12, height: "34px" }} //width: "calc(50% - 0.5px)", 
                                />
                                <div className='vr my-1'></div>
                                <IconButton
                                    transparent
                                    border={false}
                                    Icon={ShareRounded}
                                    iconHtmlColor="var(--primary)"
                                    onClick={() => {setShowShare(true)}}
                                    title="Share"
                                    className="w-100"
                                    style={{ borderRadius: 12, height: "34px" }} //width: "calc(50% - 0.5px)", 
                                />
                            </>
                        ) : (
                            <>
                                <IconButton
                                    transparent
                                    border={false}
                                    Icon={DeleteOutlined}
                                    iconHtmlColor="var(--danger)"
                                    onClick={() => {
                                        setShowDeleteModal(true)
                                    }}
                                    title="Delete"
                                    className="w-100"
                                    style={{ borderRadius: 12, height: "34px" }} //width: "calc(50% - 0.5px)", 
                                />
                                <IconButton
                                    transparent
                                    border={false}
                                    Icon={Visibility}
                                    iconHtmlColor="var(--primary)"
                                    onClick={() => {
                                        handlePublish()
                                    }}
                                    title="Publish"
                                    className="w-100"
                                    style={{ borderRadius: 12, height: "34px" }} //width: "calc(50% - 0.5px)", 
                                />
                            </>
                        )}
                        </Card.Footer>
                        <DestructiveModal
                            show={showDeleteModal}
                            onClose={() => {setShowDeleteModal(false)}}
                            onConfirm={onDeleteConfirm}
                            title="Delete Article?"
                            description="Any information linked to this article will be removed."
                            className="modal-layer-1"
                            backdropClassName="modal-layer-1"
                        />
                        <ShareArticleModal
                            show={showShare}
                            onClose={() => {setShowShare(false)}}
                            content={article.arti_title ?? ''}
                            link={url}
                        />
            </Card>
        </>
    );
};
