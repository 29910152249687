import { AddRounded, PersonOutline, SvgIconComponent } from '@mui/icons-material';
import { Row, Col, Card, Dropdown } from 'react-bootstrap';

import styles from '../partner.module.css';
import { createSocialRoute, SocialRoutes } from '../../social/SocialHome';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useWorkProfile, usePartnerMutations, useWorkProfileStaff } from '../../../services/partner.service';
import BootstrapSpinner from '../../utils/BootstrapSpinner';
import ErrorPage from '../../utils/ErrorPage';
import UserAvatar from '../../social/UserAvatar';
import LineClampText from '../../utils/LineClampText';
import PartnerMembers, { DeleteStaffModal } from '../workProfiles/PartnerMembers';
import AddPartnerStaffModal from '../workProfiles/AddPartnerStaffModal';
import { useMemo } from 'react';
import { isUserAdmin } from '../workProfiles/AllPartnersPage';
import { Butlerr } from '../../../types/butlerr';
import { useButlerrUser } from '../../../services/user.service';
import ButlerrCard from '../../utils/ButlerrCard';
import useModal from '../../../hooks/useModal';
import EditPartnerStaffModal from '../workProfiles/EditPartnerStaffModal';
import DestructiveModal from "../../utils/DestructiveModal";

interface WorkProfileTeamProps {
    wkpfId: number;
}

const WorkProfileTeam = ({ wkpfId }: WorkProfileTeamProps) => {
    const { data: workProfile, isLoading, error } = useWorkProfile(wkpfId, !!wkpfId);
    const { data: staffs } = useWorkProfileStaff(wkpfId);

    const { approved, pendingAdminApproval, pendingUserApproval } = useMemo(() => {
        if (!staffs) {
            return {
                approved: undefined,
                pendingAdminApproval: undefined,
                pendingUserApproval: undefined
            }
        }

        const approved : Butlerr.Work.WorkProfileStaff[] = [];
        const pendingAdminApproval : Butlerr.Work.WorkProfileStaff[] = [];
        const pendingUserApproval : Butlerr.Work.WorkProfileStaff[] = [];

        for (const staff of staffs) {
            if (staff.wpst_status === 0) {
                if (staff.wpst_requestedby === 'S') {
                    pendingAdminApproval.push(staff);
                }
                else {
                    pendingUserApproval.push(staff);
                }
            }
            else {
                approved.push(staff);
            }
        }
        return { approved, pendingAdminApproval, pendingUserApproval }

    }, [ staffs ]);

    if (isLoading || !workProfile) return <BootstrapSpinner />;

    if (error !== null) return <ErrorPage status={error.status} message={error.message} />;

    return (
        <div>
            <TeamList
                title="Staff(s)"
                Icon={PersonOutline}
                workProfile={workProfile}
                staffs={approved}
                showAdd={workProfile !== undefined && isUserAdmin(workProfile)}
                showOwner={true}
                actions={[ 'Edit', 'Delete' ]}
            />
            <TeamList
                title="Pending your approval"
                workProfile={workProfile}
                staffs={pendingAdminApproval}
                actions={[ 'Accept', 'Reject' ]}
            />
            <TeamList
                title="Pending staff acceptance"
                workProfile={workProfile}
                staffs={pendingUserApproval}
                actions={[ 'Cancel' ]}
            />
        </div>
    );
};

type Actions = 'Edit' | 'Delete' | 'Accept' | 'Cancel' | 'Reject';

interface TeamListProps {
    actions?: Actions[];

    Icon?: SvgIconComponent;
    title: string;

    workProfile: Butlerr.Work.WorkProfile;
    staffs?: Butlerr.Work.WorkProfileStaff[];

    showOwner?: boolean;
    showAdd?: boolean;
}

const TeamList = ({ actions = [], staffs, showOwner = false, showAdd = false, workProfile, Icon, title } : TeamListProps) => {

    const { data: dbUser } = useButlerrUser();

    const [ addModal, add ] = useModal(AddPartnerStaffModal, {
        partner: workProfile
    })

    if (staffs?.length === 0 && !showOwner && !showAdd) return <></>

    return (
        <ButlerrCard
            items={{ Icon, title }}
            style={{ borderRadius: '24px' }}
            className="border-0 mb-3"
        >
            <Row className="gy-3 gy-lg-4" xs="1" lg="3">
                {showOwner && (
                    <Col>
                        <Card className={'border-primary shadow-sm ' + styles.convoMsgCard}>
                            <Card.Body
                                className="p-2 d-flex justify-content-start align-items-center flex-row"
                                as={Row}
                            >
                                <Col className="align-items-center" xs={3}>
                                    <Link
                                        to={createSocialRoute(SocialRoutes.USER_PROFILE, {
                                            id: workProfile.owner_user_id,
                                        })}
                                        className={classNames(
                                            styles.messageUsername,
                                            styles.linkUnderline
                                        )}
                                        title={workProfile.owner_user_socialhandle}
                                    >
                                        <UserAvatar
                                            user={{
                                                user_id: workProfile.owner_user_id,
                                                user_socialhandle: workProfile.owner_user_socialhandle,
                                                user_profile: workProfile.owner_user_profile
                                            }}
                                            width={50}
                                            height={50}
                                            className={'cursor-pointer shadow me-3'}
                                        />
                                    </Link>
                                </Col>
                                <Col className="align-self-start" xs={7}>
                                    <span className="text-left small fw-bold">
                                        {dbUser?.user_id !== workProfile.owner_user_id
                                            ? workProfile.owner_user_socialhandle
                                            : 'You'}
                                    </span>
                                    <LineClampText
                                        maxLines={1}
                                        title="Owner"
                                        className={'mb-0 small text-secondary'}
                                    >
                                        Owner
                                    </LineClampText>
                                    <LineClampText maxLines={1} className={'mb-0 small'}>
                                        &nbsp;
                                    </LineClampText>
                                    <div
                                        style={{
                                            position: 'absolute',
                                            right: 5,
                                            bottom: 5,
                                        }}
                                    >
                                        <TeamBadge role="Owner" />
                                    </div>
                                </Col>
                            </Card.Body>
                        </Card>
                    </Col>
                )}
                {staffs === undefined ? (
                    Array<null>(5).fill(null).map((_, idx) => (
                        <Col key={idx}>
                            <Card className={'shadow-sm ' + styles.convoMsgCard}>
                                <Card.Body
                                    className="p-2 d-flex justify-content-start align-items-stretch flex-row"
                                    as={Row}
                                >
                                    <Col className="align-items-center" xs={3}>
                                        <div className="rounded-circle me-3 skeleton-box" style={{ width: 50, height: 50 }}></div>
                                    </Col>
                                    <Col xs={9} className="d-flex flex-column align-items-stretch justify-content-around">
                                        <div className="skeleton-box" style={{ width: '80%' }}></div>
                                        <div className="skeleton-box" style={{ width: '50%' }}></div>
                                    </Col>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))
                ) : staffs.map((staff) => (
                    <StaffItem key={staff.user_id} staff={staff} workProfile={workProfile} actions={actions} />
                ))}
                {/* add staff card */}
                {showAdd && (
                    <Col>
                        <Card
                            className={
                                'border-0 shadow-sm cursor-pointer ' + styles.assetsListCard
                            }
                            style={{ backgroundColor: 'whitesmoke' }}
                            onClick={add}
                        >
                            <Card.Body
                                className="p-2 d-flex flex-row cursor-pointer mx-auto"
                                as={Row}
                            >
                                <AddRounded
                                    className={styles.assetsListCardImage + 'h-auto mx-auto'}
                                    style={{
                                        width: '70%',
                                        height: 60,
                                        alignSelf: 'center',
                                    }}
                                />
                            </Card.Body>
                        </Card>
                        { addModal }
                    </Col>
                )}
            </Row>
        </ButlerrCard>
    )
}

interface StaffItemProps {
    staff: Butlerr.Work.WorkProfileStaff;
    workProfile: Butlerr.Work.WorkProfile;

    actions: Actions[];
}

function StaffItem({ staff, workProfile, actions } : StaffItemProps) {

    const isAdmin = isUserAdmin(workProfile);
    if (!isAdmin) actions = [];

    // edit staff modal
    const [ editModal, openEdit ] = useModal(EditPartnerStaffModal, {
        initialValue: staff
    })

    const { mutateAsync: accept } = usePartnerMutations('ACCEPT_STAFF');
    const [ acceptModal, openAccept ] = useModal(DestructiveModal, {
        title: "Accept staff request",
        description: `Accept ${staff.user_socialhandle}'s request to be a staff member`,
        btnVariant: "primary",
        onConfirm: async () => {
            await accept({
                wkpf_id: staff.wkpf_id,
                user_id: staff.user_id
            })
        },
        confirmBtnText: "Accept"
    })

    const { mutateAsync: reject } = usePartnerMutations('DELETE_STAFF');
    const onReject = async () => {
        await reject({
            wkpf_id: staff.wkpf_id,
            user_id: staff.user_id,
            newStaffId: -1
        })
    }

    const [ rejectModal, openReject ] = useModal(DestructiveModal, {
        title: "Reject staff request",
        description: `Reject ${staff.user_socialhandle}'s request to be a staff member`,
        onConfirm: onReject,
        confirmBtnText: "Reject"
    })
    const [ cancelModal, openCancel ] = useModal(DestructiveModal, {
        title: "Remove staff invite",
        description: `Retract staff invitation from ${staff.user_socialhandle}`,
        onConfirm: onReject,
        confirmBtnText: "Remove"
    })
    const [ deleteNoAssetModal, openDeleteNoAsset ] = useModal(DestructiveModal, {
        title: "Remove staff",
        description: 'All information linked to this staff will be deleted.',
        onConfirm: onReject
    });
    const [ deleteWAssetModal, openDeleteWAsset ] = useModal(DeleteStaffModal, {
        workProfile,
        staff
    });

    return (
        <Col className="d-flex">
            <PartnerMembers
                workProfile={workProfile}
                staff={staff}
                dropdownItems={actions.length === 0 ? undefined : (
                    <>
                        {actions.map((action, idx) => (
                            <Dropdown.Item
                                key={idx}
                                onClick={() => {
                                    if (action === 'Delete') {
                                        if (staff.hasAssets === 0) return openDeleteNoAsset();
                                        return openDeleteWAsset();
                                    }
                                    if (action === 'Edit') return openEdit();
                                    if (action === 'Accept') return openAccept();
                                    if (action === 'Reject') return openReject();
                                    if (action === 'Cancel') return openCancel();
                                }}
                            >
                                { action }
                            </Dropdown.Item>
                        ))}
                    </>
                )}
            />
            { isAdmin && (
                <>
                    {editModal} {acceptModal} {rejectModal} {cancelModal} {deleteNoAssetModal} {deleteWAssetModal}
                </>
            ) }
        </Col>
    )
}

export function TeamBadge({ role } : { role: "Admin" | "Owner"}) {
    return (
        <div
            className={styles.badge + " d-flex align-items-center justify-content-center text-center fw-semibold"}
            title={role}
            style={{
                ["--badge-color" as any]: role === "Owner" ? "var(--primary)" : "var(--bs-gray-500)",
                boxShadow: role === "Owner" ? "0 0 5px 0px var(--primary)" : undefined,
            }}
        >
            {role.charAt(0)}
        </div>
    )
}

export default WorkProfileTeam;