import { CheckOutlined, ExploreOutlined, GroupsOutlined, ShareRounded, StarRounded, ThumbDown, ThumbUp, TimelapseRounded, TimelineRounded, Visibility } from '@mui/icons-material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, Col, Dropdown, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { FetchPublishedArticlesOptions, usePublishedArticles } from '../../services/partnerArticle.service';
import { Butlerr } from '../../types/butlerr';
import LineClampText from '../utils/LineClampText';
import styles from '../assets/asset.module.css';
import { ShareArticleModal } from '../partners/articles/ShareArticleModal';
import BootstrapSpinner from '../utils/BootstrapSpinner';
import { formatDate } from '../utils/HelperFunctions';
import IconButton from '../utils/IconButton';
import butlerrPlaceholder from '../../assets/img_placeholder.jpg';
import useQuery from '../../hooks/useQuery';
import EmptyMessage from '../utils/EmptyMessage';
import ToggleButtonGroup, { ToggleItem } from '../ui/ToggleButtonGroup';
import classNames from 'classnames';
import { Pagination } from '@mui/material';

interface ArticleListProps {
    emptyMessage: string;
}

type KeysWithType<T, V> = {[K in keyof T]-?: T[K] extends V ? K : never}[keyof T];

type SortKeys = 'n' | 't' | 'a'
const SORT_MAP : Record<SortKeys, FetchPublishedArticlesOptions['sort']> = {
    n: "newest",
    t: "trending",
    a: "popular"
} as const;

type FilterKeys = 'a' | 'f'
const FILTER_MAP : Record<FilterKeys, KeysWithType<Required<FetchPublishedArticlesOptions>, boolean | null>> = {
    a: "all",
    f: "following_only",
} as const;

export const ArticlesList = ({emptyMessage}: ArticleListProps) => {
    const history = useHistory();
    const search = useQuery();

    const params = useMemo(() => {
        const params : FetchPublishedArticlesOptions = {};

        const query = decodeURIComponent(search.get("q") || "");

        if (query) params.search = query;

        const sortKey = SORT_MAP[decodeURIComponent(search.get("s") || "") as SortKeys] ?? "newest"; //if there are no sorts, default to newest
        params.sort = sortKey;

        const filterKey = FILTER_MAP[decodeURIComponent(search.get("f") || "all") as FilterKeys];
        //if null, no filter (all posts)
        if (filterKey !== null) {
            //set matching filter key to true;
            //if undefined, default to show `all` but only if not searching
            if (filterKey !== undefined || !query) {
                params[filterKey ?? 'all'] = true;
            }
        }
        return params;
    }, [search])
    const { data: _articles, isLoading, isRefetching, fetchNextPage, isFetchingNextPage } = usePublishedArticles(params);
    const [spin, setSpin] = useState(false);

    useEffect(() =>  {
        if (!isRefetching) {
            setSpin(false)
        }
    }, [isRefetching])

    const handleFilterChange = (filter: FilterKeys) => {
        search.set('f', filter);
        search.set('t', 'ar')
        search.set('page', '1')
        history.replace({
            search: search.toString()
        })
        setSpin(true)
    }

    const handleSortChange = (sort: SortKeys) => {
        search.set('s', sort);
        search.set('t', 'ar')
        history.replace({
            search: search.toString()
        })
        setSpin(true)
    }

    const handleChange = (event: any, value: number) => {
        search.set('page', value.toString())
        history.replace({
            search: search.toString()
        })
        document.documentElement.scrollTop = 0
    }

    const getCurrentPage = useCallback((page: number) => {
        return _articles?.pages.find(p => p.page === page)
    }, [_articles])

    const tab = useQuery().get('t') || 'ar';
    const page = parseInt(search.get('page') ?? '1');
    useEffect(() => {
        if(!getCurrentPage(page) && tab === 'ar') { //check if next page exists
            fetchNextPage({pageParam: page})
        }
    }, [getCurrentPage, page, fetchNextPage, tab])

    const articles = getCurrentPage(page)?.result;

    type Item = Omit<ToggleItem, 'active' | 'render'>
    
    const [activeFilter, setActiveFilter] = useState<FilterKeys>(search.get('f') as FilterKeys ?? 'a' as FilterKeys);
    const filterItems : Record<FilterKeys, Item> = {
        a: {
            Icon: ExploreOutlined,
            label: "All"
        },
        f: {
            Icon: GroupsOutlined,
            label: "Following"
        },
    }

    const [activeSort, setActiveSort] = useState<SortKeys>(search.get('s') as SortKeys ?? 'n' as SortKeys);
    const sortItems : Record<SortKeys, Item> = {
        n: {
            Icon: TimelapseRounded,
            label: "Most recent"
        },
        t: {
            Icon: TimelineRounded,
            label: "Trending"
        },
        a: {
            Icon: StarRounded,
            label: "Popularity"
        },
    }

    useEffect(() => {
        if (search.get('f') === null) {
            setActiveFilter('a')
            
        }
        if (search.get('s') === null) {
            setActiveSort('n')
            
        }
    }, [search, history])

    //reactions also cause a refetch, but having a spinner everytime a react is clicked is damn annoying.
    //so round about method of spinning is my solution
    if ((isLoading || isFetchingNextPage) || (spin && isRefetching)) { //if spin is true (sort/filter clicked) and published articles is being refetched
        return <BootstrapSpinner />
    }
    return (
        <>
        {search.get('q') === null && (
        <div className='d-flex flex-wrap mb-3'>
            
                <ToggleButtonGroup
                items={Object.entries(filterItems).map(([ key, item ]) => ({
                    ...item,
                    key: key as FilterKeys,
                    active: key === activeFilter
                }))}
                onSelect={(item) => {
                    setActiveFilter(item.key)
                    handleFilterChange(item.key)
                }}
            />
            
            <Dropdown align="end" className='ms-auto d-flex'>
                    <Dropdown.Toggle
                        as="span"
                        bsPrefix=" "
                    >
                        <IconButton
                            transparent
                            Icon={ sortItems[activeSort].Icon }
                            title="Sort"
                            label=""
                            className="rounded-circle p-2 bg-light text-primary"
                            iconHtmlColor="var(--primary)"
                            iconStyles={{ fontSize: '1.5rem' }}
                        />
                    </Dropdown.Toggle>
                    
                    <Dropdown.Menu>
                        {
                            Object.entries(sortItems).map(([ key, { Icon, label } ]) => {
                                const isActive: boolean = key === activeSort;

                                return (
                                    <Dropdown.Item
                                        key={key}
                                        disabled={isActive}
                                        className={classNames("p-2 d-flex align-items-center", isActive && "text-primary")}
                                        onClick={() => {setActiveSort(key as SortKeys); handleSortChange(key as SortKeys)}}
                                    >
                                        <Icon fontSize="small" className="me-2" />
                                        {label}

                                        { isActive && (
                                            <CheckOutlined fontSize="small" className="text-primary ms-auto" />
                                        ) }
                                    </Dropdown.Item>
                                )
                            })
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            )}
        {articles?.length === 0 && (<EmptyMessage message={emptyMessage} />)}
        <Row className="gy-3 gy-lg-4" md={2} lg={2} xl={2}>
            {articles?.map((article, index) => {
                return (
                    <Col key={index} md={6} lg={6} xl={4}>
                        <CardItem article={article}/>
                    </Col>
                );
            })}
        </Row>
            {
                (_articles?.pages !== undefined && articles?.length !== 0) && ( //filtered articles exists and pages exist
                    <div className='d-flex justify-content-center my-3'>
                        <Pagination count={Math.ceil(_articles?.pages[0]?.total  / _articles?.pages[0].limit)} 
                            page={page}
                            onChange={handleChange}
                        />
                    </div>
                )
            }
        
        </>
    );
};

interface CardItemProps {
    article: Butlerr.Article;
}

const CardItem = ({ article }: CardItemProps) => {
    const history = useHistory();
    const [showShare, setShowShare] = useState(false);
    const url = "/articles/" + article.arti_id

    const search = useQuery();

    return (
        <>
            <Card
                className={
                    'cursor-pointer border-0 shadow ' + styles.assetsListCard
                }
            >
                <div
                    className={
                        'card-img-top ' + styles.assetsListCardImageContainer
                    }
                >
                    <Card.Img
                    className={styles.assetsListCardImage}
                    src={article.arti_imagekey ?? butlerrPlaceholder}
                    variant="top"
                    onClick={() => {history.push(url + `?${search.toString()}`)}}
                />
                </div>

                <Card.Body className="p-3 pb-1"
                    onClick={() => {history.push(url + `?${search.toString()}`)}}
                >
                    <LineClampText
                        title={(article.arti_lastedited && article.arti_lastedited !== null
                            ? 'Modified: ' +
                              formatDate(article.arti_lastedited)
                            : formatDate(article.arti_date)) ?? undefined}
                        maxLines={1}
                        className="small text-muted mb-1"
                    >
                        {article.arti_lastedited
                            ? 'Modified: ' +
                              formatDate(article.arti_lastedited)
                            : formatDate(article.arti_date)}
                    </LineClampText>

                    <LineClampText
                        title={((article.arti_title?.length === 0 && article.arti_title !== null) || article.arti_title === null ? ("Untitled") : (article.arti_title))}
                        maxLines={2}
                        className={
                            'card-subtitle fw-semibold mb-1 '
                        }
                        style={{ fontSize: 16, height: '48px' }}
                    >
                        {((article.arti_title?.length === 0 && article.arti_title !== null) || article.arti_title === null ? ("Untitled") : (article.arti_title))}
                    </LineClampText>

                    <LineClampText maxLines={1} className="small muted text-secondary mb-1">
                        Author: {article?.user_socialhandle}
                    </LineClampText>

                    <hr className='my-1' />

                    <LineClampText
                        maxLines={3}
                        className={
                            'card-text small m-0 ' +
                            styles.assetsListCardDescription
                        }
                        title={(article.arti_synopsis?.length === 0 && article.arti_synopsis !== null) || article.arti_synopsis === null ? ('No synopsis available') : (article.arti_synopsis)}
                    >
                        {(article.arti_synopsis?.length === 0 && article.arti_synopsis !== null) || article.arti_synopsis === null ? ('No synopsis available') : (article.arti_synopsis)}
                    </LineClampText>
                    
                </Card.Body>
                <Card.Footer className="d-flex p-0">
                    <LineClampText maxLines={1}
                        style={{ width: "calc(70% - 0.5px)", borderRadius: 0, borderBottomRightRadius: 12 }}
                            className={
                                'card-text small mb-0 d-flex align-items-center justify-content-center'
                                }>
                            <span className='me-2 text-secondary'><Visibility color='disabled' style={{fontSize: '13.5px'}} /> {article.viewCounts} </span>
                            <span className='me-2 text-secondary'><ThumbUp color='disabled' style={{fontSize: '13.5px'}} /> {article.likeCount ?? 0} </span>
                            <span className='text-secondary'><ThumbDown color='disabled' style={{fontSize: '13.5px'}} /> {article.dislikeCount ?? 0} </span>
                        </LineClampText>
                    <div className="vr my-1" />
                    <IconButton
                            transparent
                            border={false}
                            Icon={ShareRounded}
                            iconHtmlColor="var(--primary)"
                            onClick={() => {setShowShare(true)}}
                            title="Share"
                            className="py-3"
                            style={{ width: "calc(30% - 0.5px)", borderRadius: 0, borderBottomRightRadius: 12 }}
                    />
                </Card.Footer>
                        <ShareArticleModal
                            show={showShare}
                            onClose={() => {setShowShare(false)}}
                            content={article.arti_title ?? ''}
                            link={url}
                        />
            </Card>
        </>
    );
};
