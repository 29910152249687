import { AddCircle, NotificationsOutlined } from '@mui/icons-material';
import { useMemo, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useReminders } from '../../../services/assetreminder.service';
import { Butlerr } from '../../../types/butlerr';
import ErrorPage from '../../utils/ErrorPage';
import IconButton from '../../utils/IconButton';
import PageHeader from '../../utils/PageHeader';
import { AssetTabContent } from '../Asset';
import AssetRemindersModal from './AssetRemindersModal';
import AssetRemindersTable from './AssetRemindersTable';

interface AssetRemindersProps {
    asset: Butlerr.Asset;
}

export enum ReminderModes {
    A = 'All',
    U = 'Upcoming',
    N = 'Pending',
    D = 'Done',
}

const AssetReminders = ({ asset }: AssetRemindersProps) => {
    return (
        <>
            {/* tab header */}
            <PageHeader Icon={NotificationsOutlined} title="Reminders" />

            {/* tab content */}
            <AssetTabContent>
                <AssetRemindersCardContent asset={asset} />
            </AssetTabContent>
        </>
    );
};

export const AssetRemindersCardContent = ({ asset }: AssetRemindersProps) => {

    const assetId = asset.asst_id;

    // data
    const { data: reminders, isLoading, error } = useReminders(assetId);

    // mode
    const [mode, setMode] = useState<keyof typeof ReminderModes>('A');
    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setMode(e.target.value as keyof typeof ReminderModes);
    };
    const filteredReminders = useMemo(() => {
        return (
            reminders?.filter((reminder) => {
                if (mode === 'U') {
                    const today = new Date().setHours(0, 0, 0, 0);
                    const threeDays = new Date().setHours(0, 0, 0, 0) + 3 * 24 * 60 * 60 * 1000; //3 days
                    const reminderDate = new Date(reminder.remi_date).setHours(0, 0, 0, 0);

                    if (reminderDate <= threeDays && reminderDate >= today) return true;
                    return false;
                } else if (mode === 'D') {
                    if (reminder.remi_completed === 1) return true;
                    return false;
                } else if (mode === 'N') {
                    if (reminder.remi_completed === 0) return true;
                    return false;
                } else {
                    return true;
                }
            }) ?? []
        );
    }, [reminders, mode]);

    // modal states
    const [selectedReminder, setSelectedReminder] = useState<Butlerr.Asset.Reminder>();
    const [showModal, setShowModal] = useState(false);

    const onModalOpen = (reminder?: Butlerr.Asset.Reminder) => {
        setSelectedReminder(reminder);
        setShowModal(true);
    };

    const onModalClose = () => {
        setShowModal(false);
    };

    return (
        <>
            {error !== null ? (
                <ErrorPage status={error.status} message={error.message} />
            ) : (
                <>
                    <div className="mb-3 d-flex justify-content-end align-items-center">
                        <div className="me-3">
                            {isLoading ? (
                                <>
                                    {Array(1)
                                        .fill(null)
                                        .map((v, i) => (
                                            <Row key={i}>
                                                <Col>
                                                    <Form.Select size="sm" />
                                                </Col>
                                            </Row>
                                        ))}
                                </>
                            ) : (
                                <>
                                    <Form.Group as={Row} controlId="mode">
                                        <Col sm>
                                            <Form.Select
                                                size="sm"
                                                name="mode"
                                                value={String(mode)}
                                                onChange={handleChange}
                                            >
                                                {Object.keys(ReminderModes).map(
                                                    (mode, index) => (
                                                        <option
                                                            key={index}
                                                            value={String(mode)}
                                                        >
                                                            {
                                                                Object.values(ReminderModes)[
                                                                    index
                                                                ]
                                                            }
                                                        </option>
                                                    )
                                                )}
                                            </Form.Select>
                                        </Col>
                                    </Form.Group>
                                </>
                            )}
                        </div>

                        {asset.status !== 'AR' && (
                            <IconButton
                                Icon={AddCircle}
                                title="Add A Reminder"
                                onClick={() => onModalOpen()}
                            />
                        )}
                    </div>

                    <AssetRemindersTable
                        asset={asset}
                        reminders={filteredReminders}
                        isLoading={isLoading}
                        onModalOpen={onModalOpen}
                        mode={mode}
                    />
                </>
            )}
            <AssetRemindersModal
                show={showModal}
                onModalClose={onModalClose.bind(undefined)}
                initialValue={selectedReminder}
                asset={asset}
            />
        </>
    )
}

export default AssetReminders;
