import { BaseModalProps } from "../../hooks/useModal";
import * as yup from "yup";
import { Close, CloseRounded, Done, Upload } from "@mui/icons-material";
import classNames from "classnames";
import { Formik } from "formik";
import { useMemo, useRef, useState } from "react";
import { Modal, Form, Container, Row, Col, ModalProps } from "react-bootstrap";
import { useDocumentMutations, folderFromAsset, folderFromUser } from "../../services/doc.service";
import { extractFileExtension, extractFileNameFromPath, extractFileSize } from "../utils/HelperFunctions";
import { DocumentThumbnailPlaceholder } from "./DocsUI";
import IconButton from "../utils/IconButton";
import styles from "./documents.module.css";
import cloudUploadIcon from "../../assets/icons/cloud-upload.png";
import { Butlerr } from "../../types/butlerr";
import { BootstrapInput } from "../utils/FormikBootstrapInputs";
import { DocumentsTree } from "./Docs";

export const fileSizeLimit = 1e+7 //10 mb in bytes
export const allowedFileTypes = ["pdf", "doc", "docx", "odt", "xls", "xlsx", "ods", "ppt", "pptx", "txt", "rtf",
"bmp", "heic", "jpg", "jpeg", "png", "raw", "svg", "tiff", "webp", "psd", "ai", "cdr", "dwg", "dxf",
"gif", "mp4", "mov", "avi", "flv", "mkv", "webm", "3gp", "aac", "flac", "m4a", "mp3", "ogg", "wav", "wma"
]
interface UploadModalProps extends BaseModalProps {
    parentTreeId: number;
    onClose: (uploadedFile?: Butlerr.Document.File) => void;
}

export function UploadModal({ parentTreeId, show, onClose, ...props } : UploadModalProps) {

    const schema = yup.object().shape({
        document: yup.string().required("Please select a document"),
        document_size: yup.number().required("Please select a document").max(fileSizeLimit, "File can't be larger than 10 MB"),
        document_type: yup.string().oneOf(allowedFileTypes, (obj) => `'${obj.value}' files aren't allowed`),
        doc_name: yup.string().max(60, "Name must be less than 60 characters"),
        doc_desc: yup.string().max(128, "Description must be less than 128 characters")
    })

    //const file form element
    const fileInput = useRef<HTMLInputElement>(null)
    const fileRef = useRef<File | null>(null)

    const [thumb, setThumb] = useState<JSX.Element>();

    const setFile = (files: File[]) => {
        if (files[0]) {
            fileRef.current = files[0];

            if (files[0].type.startsWith('image')) {
                //read image and set thumb
                const reader = new FileReader();
                reader.onloadend = () => {
                    if (typeof reader.result === 'string') {
                        setThumb((
                            <>
                                <img
                                    alt="thumbnail"
                                    className="mb-2"
                                    src={reader.result}
                                    width={100}
                                    height={100}
                                />
                                <span className="fw-bold">{files[0].name}</span>
                            </>
                        ))
                    }
                }
                reader.readAsDataURL(files[0]); 
            }
            else {
                setThumb((
                    <>
                        <DocumentThumbnailPlaceholder className="mb-2" format={extractFileExtension(files[0].name) || 'UNK'} />
                        <span className="fw-bold">{files[0].name}</span>
                    </>
                ))
            }

            return files[0];
        }
        setThumb(undefined);
    }

    const [ showDropOverlay, setShowDropOverlay ] = useState(false);


    type UploadForm = { document: string, document_size?: number, document_type?: string, doc_name?: string, doc_desc?: string }

    const { mutate: createDocument, isLoading, error, progress } = useDocumentMutations('CREATE')

    const onSubmit = ({ document, document_size, document_type, ...values } : UploadForm) => {
        const file = fileRef.current
        
        if (file) {
            createDocument({
                document: file,
                doct_parentid: parentTreeId,
                ...values
            }, {
                onSuccess: ({ response }) => onModalClose(response.data)
            })
        }
    }

    const onModalClose = (file?: Butlerr.Document.File) => {
        setThumb(undefined);
        onClose(file);
    }

    return (
        <Modal {...props} centered show={show} backdrop={isLoading ? "static" : undefined} keyboard={!isLoading} onHide={onModalClose}>
            <Modal.Header>
                <Modal.Title>Upload document</Modal.Title>
                <IconButton
                    title="Close modal"
                    Icon={CloseRounded}
                    className="ms-auto"
                    disabled={isLoading}
                    onClick={() => onModalClose()}
                />
            </Modal.Header>
            <Formik
                initialValues={{
                    document: "",
                    document_size: 0,
                    document_type: "",
                    doc_name: "",
                    doc_desc: "",
                }}
                validationSchema={schema}
                onSubmit={onSubmit}
            >
                {({ setFieldValue,
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors}) => {
                        return (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Modal.Body>
                                    <Container className="small">
                                        {/* Upload Area */}
                                        <div
                                            style={{ border: '1px dashed' }}
                                            className={classNames({
                                                "mt-2 mb-3 p-3 rounded border-secondary d-flex flex-column align-items-center justify-content-center": true,
                                                [styles.dropZone]: showDropOverlay
                                            })}
                                            onDragEnter={(e) => {
                                                if (e.dataTransfer.types.includes('Files')) {
                                                    setShowDropOverlay(true)
                                                }
                                            }}
                                            onDragLeave={() => setShowDropOverlay(false)}
                                            onDragOver={(e) => {
                                                e.preventDefault()
                                                if (e.dataTransfer.types.includes('Files')) {
                                                    e.dataTransfer.dropEffect = 'copy'
                                                }
                                            }}
                                            onDrop={(ev) => {
                                                // Prevent default behavior (Prevent file from being opened)
                                                ev.preventDefault();
                                                setShowDropOverlay(false);

                                                const file = Array.from(ev.dataTransfer.items).find(i => i.kind === 'file')?.getAsFile() || ev.dataTransfer.files.item(0)

                                                if (file) {
                                                    setFile([file])
                                                    setFieldValue('document_size', file.size ?? 0, true)
                                                    const extension = extractFileExtension(file.name ?? '')
                                                    setFieldValue('document_type', extension, true)
                                                    setFieldValue('document', file.name ?? '', true)
                                                }
                                            }}
                                        >
                                            <input
                                                onChange={(ev) => {
                                                    const file = setFile(Array.from(ev.currentTarget.files ?? []))

                                                    if (file) {
                                                        setFieldValue('document_size', file.size ?? 0, true)
                                                        const extension = extractFileExtension(file.name ?? '')
                                                        setFieldValue('document_type', extension, true)
                                                        setFieldValue('document', file.name ?? '', true)    
                                                    }
                                                    return false;
                                                }}
                                                ref={fileInput}
                                                className="d-none"
                                                type="file"
                                            ></input>

                                            <div className="mt-2 d-flex flex-column align-items-center">
                                                {
                                                    thumb ?? (
                                                        <img
                                                            alt=""
                                                            src={cloudUploadIcon}
                                                            draggable={false}
                                                            width={60}
                                                            className="mt-2"
                                                        ></img>
                                                    )
                                                }
                                            </div>

                                            <p style={{ fontSize: 'large' }} className="my-3">Drag &amp; Drop your file here or</p>
                                            <IconButton
                                                title="Browse for files"
                                                label="Browse file"
                                                onClick={() => fileInput.current?.click()}
                                            />
                                        </div>
                                        {/* Inputs */}
                                        <Row className="my-2">
                                            <Form.Group as={Col}>
                                                <Form.Label className="fw-bold">
                                                    File Name{' '}
                                                    <span className="text-danger">*</span>
                                                </Form.Label>
                                                <Form.Control
                                                    onChange={handleChange}
                                                    name="doc_name"
                                                    value={values.doc_name}
                                                    isInvalid={touched.document && (!!errors.document || !!errors.document_size || !!errors.document_type)}
                                                    type="text"
                                                    placeholder="File Name (Optional)"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.document || errors.document_size || errors.document_type}
                                                </Form.Control.Feedback>
                                                <Form.Label className="mt-1 text-muted">{
                                                    values.document_size ?
                                                    <>
                                                        {extractFileNameFromPath(values.document)}
                                                        <span style={{whiteSpace: 'nowrap'}}> ({extractFileSize(values.document_size)})</span>
                                                    </>
                                                    : '16 MB Limit'
                                                }</Form.Label>
                                            </Form.Group>
                                        </Row>
                                        <Row className="my-2">
                                            <Form.Group as={Col}>
                                                <Form.Label className="fw-bold">
                                                    Description
                                                </Form.Label>
                                                <Form.Control
                                                    onChange={handleChange}
                                                    name="doc_desc"
                                                    value={values.doc_desc}
                                                    isInvalid={touched.doc_desc && !!errors.doc_desc}
                                                    as="textarea"
                                                    type="text"
                                                    placeholder="Description (Optional)"
                                                    style={{height: '100px'}}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Description must be less than 80 characters
                                                </Form.Control.Feedback>
                                                <small className="text-muted">
                                                    Alternatively, you can send your documents to "
                                                    <i className="fw-semibold cursor-pointer user-select-all">
                                                        filekeeper@propcircle.co
                                                    </i>
                                                    " and we'll save them here for you.
                                                </small>
                                            </Form.Group>
                                        </Row>
                                    </Container>
                                </Modal.Body>
                                <Modal.Footer className="overflow-hidden">
                                    {
                                        error !== null && <p className="text-danger w-100 text-truncate">{error.message}</p>
                                    }
                                    <IconButton
                                        transparent
                                        Icon={Close}
                                        iconHtmlColor="var(--primary)"
                                        className="px-4 me-3 border border-primary text-primary"
                                        label="Cancel"
                                        disabled={isLoading}
                                        onClick={() => onModalClose()}
                                    />
                                    <IconButton
                                        Icon={Upload}
                                        type="submit"
                                        className="px-4"
                                        disabled={isLoading}
                                        label={!isLoading ? 'Upload' : 'Uploading...'}
                                    />
                                    {
                                        isLoading && (
                                            <div
                                                className="bg-primary m-0"
                                                style={{
                                                    position: 'absolute',
                                                    left: '0',
                                                    bottom: '0',
                                                    width: `${progress}%`,
                                                    height: '5px'
                                                }}
                                            ></div>
                                        )
                                    }
                                </Modal.Footer>
                            </Form>
                        )
                }}
            </Formik>
        </Modal>
    )
}

interface EditModalProps extends BaseModalProps {
    document: Butlerr.Document.File;
}
export function EditModal({ show, onClose, document, ...props } : EditModalProps) {
    const schema = yup.object().shape({
        doc_name: yup.string().required("Please enter a document name").max(60, "Name must be less than 60 characters"),
        doc_desc: yup.string().max(128, "Description must be less than 128 characters")
    })

    type UploadForm = { doc_name?: string, doc_desc?: string };

    const { mutate: edit, isLoading, error } = useDocumentMutations('EDIT_FILE')

    const onSubmit = (values : UploadForm) => {
        edit({
            doc_id: document.doc_id,
            doct_parentid: document.doct_parentid,
            doc_name: values.doc_name,
            doc_desc: values.doc_desc
        }, {
            onSuccess: onClose
        })
    }

    return (
        <Modal {...props} centered show={show} backdrop={isLoading ? "static" : undefined} keyboard={!isLoading} onHide={onClose}>
            <Modal.Header>
                <Modal.Title>Edit</Modal.Title>
                <IconButton
                    title="Close modal"
                    Icon={CloseRounded}
                    className="ms-auto"
                    disabled={isLoading}
                    onClick={onClose}
                />
            </Modal.Header>
            <Formik
                initialValues={{
                    doc_name: document?.doc_name ?? "",
                    doc_desc: document?.doc_desc ?? "",
                }}
                validationSchema={schema}
                onSubmit={onSubmit}
            >
                {({ handleSubmit }) => {
                        return (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Modal.Body className="small">
                                    {/* Inputs */}
                                    <div className="mb-2">
                                        <BootstrapInput
                                            id="doc_name"
                                            required
                                            placeholder="file"
                                            label="Name"
                                            inputGroupText={!document?.doc_format ? undefined : `.${document.doc_format}`}
                                            inputGroupPosition="suffix"
                                        />
                                    </div>
                                    <div>
                                        <BootstrapInput
                                            id="doc_desc"
                                            required={false}
                                            placeholder="Notes..."
                                            label="Description"
                                        />
                                    </div>
                                </Modal.Body>
                                <Modal.Footer className="overflow-hidden">
                                    {
                                        error !== null && <p className="text-danger w-100 text-truncate">{error.message}</p>
                                    }
                                    <IconButton
                                        transparent
                                        Icon={Close}
                                        iconHtmlColor="var(--primary)"
                                        className="px-4 me-3 border border-primary text-primary"
                                        label="Cancel"
                                        disabled={isLoading}
                                        onClick={onClose}
                                    />
                                    <IconButton
                                        Icon={Done}
                                        type="submit"
                                        className="px-4"
                                        disabled={isLoading}
                                        label={!isLoading ? 'Save' : 'Saving...'}
                                    />
                                </Modal.Footer>
                            </Form>
                        )
                }}
            </Formik>
        </Modal>
    )
}

interface BaseDocumentSelectModalProps extends BaseModalProps {
    onFileSelect?: (file: Butlerr.Document.File, tree: Butlerr.Document.Folder[]) => void;
    onFolderSelect?: (folder: Butlerr.Document.Folder, tree: Butlerr.Document.Folder[]) => void;
    selectBtnText?: string;
    /**
     * Must be memoized if `number[]`
     */
    foldersToHide?: number | number[];
    /**
     * Must be memoized if `number[]`
     */
    filesToHide?: number | number[];

    /**
     * Initial folder to select
     */
    initialFolder?: number | null;
}

interface DocumentSelectWAssetProps extends BaseDocumentSelectModalProps {
    asset: Parameters<typeof folderFromAsset>[0];
    user?: never;
    rootFolder?: never;
}
interface DocumentSelectWUserProps extends BaseDocumentSelectModalProps {
    asset?: never;
    user?: Parameters<typeof folderFromUser>[0];
    rootFolder?: never;
}
interface DocumentSelectWFolderProps extends BaseDocumentSelectModalProps {
    asset?: never;
    user?: never;
    rootFolder?: Butlerr.Document.Folder;
}

type DocumentSelectModalProps =  ModalProps & (
    |   DocumentSelectWAssetProps
    |   DocumentSelectWUserProps
    |   DocumentSelectWFolderProps
)

export function DocumentSelectModal({ asset, user, rootFolder, initialFolder, onFileSelect, onFolderSelect, show, onClose, selectBtnText, foldersToHide, filesToHide, ...props } : DocumentSelectModalProps) {

    //We have to do this, as selectedRoot needs to be memoized
    const selectedRoot = useMemo(() => {
        if (rootFolder?.doct_id !== undefined) {
            return {
                user_id: rootFolder.user_id,
                asst_id: rootFolder.asst_id,
                doct_name: rootFolder.doct_name,
                doct_id: rootFolder.doct_id,
                doct_parentid: rootFolder.doct_parentid,
                doct_sys: rootFolder.doct_sys
            }
        }
        else if (asset?.asst_id !== undefined) {
            return folderFromAsset({
                user_id: asset.user_id,
                asst_id: asset.asst_id,
                asst_name: asset.asst_name,
                doct_id: asset.doct_id
            })
        }
        /**
         * We test `undefined` for `asset.asst_id` instead of `asset`, to avoid having to include `asset` in dependencies.
         * But with this, typescript doesn't recognize that `user` won't be undefined here. So we have to assert.
         */
        else if (user?.user_id !== undefined) {
            return folderFromUser({
                user_id: user.user_id,
                user_socialhandle: user.user_socialhandle,
                doct_id: user.doct_id
            })
        }
        return undefined;
    }, [
        rootFolder?.doct_id,
        rootFolder?.user_id,
        rootFolder?.asst_id,
        rootFolder?.doct_name,
        rootFolder?.doct_parentid,
        rootFolder?.doct_sys,
        asset?.asst_id,
        asset?.user_id,
        asset?.asst_name,
        asset?.doct_id,
        user?.user_id,
        user?.user_socialhandle,
        user?.doct_id
    ])

    return (
        <Modal
            {...props}
            show={show}
            onHide={onClose}
            size="lg"
        >
            <DocumentsTree
                selectedRoot={selectedRoot}
                onFileSelect={onFileSelect ? (...args) => {
                    onClose();
                    onFileSelect(...args);
                } : undefined}
                onFolderSelect={onFolderSelect ? (...args) => {
                    onClose();
                    onFolderSelect?.(...args);
                } : undefined}
                onCancel={onClose}
                selectBtnText={selectBtnText}
                foldersToHide={foldersToHide}
                filesToHide={filesToHide}
                uploadModalProps={{
                    className: props.backdropClassName?.includes("modal-layer-1") ? "modal-layer-2" : "modal-layer-1",
                    backdropClassName: props.className?.includes("modal-layer-1") ? "modal-layer-2" : "modal-layer-1"
                }}
                initialFolder={initialFolder ?? undefined}
            />
        </Modal>
    )
}