import { Butlerr } from '../types/butlerr';
import { assetBaseRoute } from './asset.service';
import useButlerrAPI, { ButlerrMutationOptions, useButlerrMutation } from './useButlerrAPI';

const LeaseQueries = {
    LEASES: (assetId: number) => ['assets', assetId, 'leases'] as const,
    LEASE_ADJUSTMENTS: (assetId: number, leaseId: number) => ['assets', assetId, 'leases', leaseId, 'adjustments'],
    LEASE_DOCUMENTS: (assetId: number, leaseId: number) => ['assets', assetId, 'leases', leaseId, 'documents']
};

export function useLeases(assetId: number) {
    return useButlerrAPI<Butlerr.Asset.Lease[]>(
        LeaseQueries.LEASES(assetId),
        `${assetBaseRoute}/${assetId}/leases/`
    );
}

export function useLeaseAdjustments(assetId: number, leaseId: number, enabled = true) {
    return useButlerrAPI<Butlerr.Asset.LeaseAdjustment[]>(
        LeaseQueries.LEASE_ADJUSTMENTS(assetId, leaseId),
        `${assetBaseRoute}/${assetId}/leases/${leaseId}/adjustments`,
        true,
        { enabled }
    )
}
export function useLeaseDocuments(assetId: number, leaseId: number, enabled = true) {
    return useButlerrAPI<Butlerr.Document.File[]>(
        LeaseQueries.LEASE_DOCUMENTS(assetId, leaseId),
        `${assetBaseRoute}/${assetId}/leases/${leaseId}/documents`,
        true,
        { enabled, staleTime: 0 }
    )
}

interface LeaseMutationInterface extends Omit<Butlerr.Asset.Lease, 'leas_id' | 'asst_id' | 'leas_bybillcycle' | 'leas_billday' | 'leas_status' | 'active_adj'> {
    assetId: number;
    leaseId: number;
}

interface LeaseDocForm extends Butlerr.Document.File {
    assetId: number;
    leaseId: number;
}

const LeaseMutations = {
    CREATE: ({
        assetId,
        ...lease
    }: Omit<LeaseMutationInterface, 'leaseId'>): ButlerrMutationOptions => ({
        url: `${assetBaseRoute}/${assetId}/leases/`,
        method: 'POST',
        requestBody: lease,
        queryKey: LeaseQueries.LEASES(assetId)
    }),
    EDIT: ({
        assetId,
        leaseId,
        ...lease
    }: LeaseMutationInterface): ButlerrMutationOptions => ({
        url: `${assetBaseRoute}/${assetId}/leases/${leaseId}`,
        method: 'PUT',
        requestBody: lease,
        queryKey: LeaseQueries.LEASES(assetId)
    }),
    DELETE: ({
        assetId,
        leaseId,
    }: {
        assetId: number;
        leaseId: number;
    }): ButlerrMutationOptions<Butlerr.Asset.Lease[]> => ({
        url: `${assetBaseRoute}/${assetId}/leases/${leaseId}`,
        method: 'DELETE',
        queryKey: LeaseQueries.LEASES(assetId),
        updater: {
            optimistic: true,
            action: (state) => state.filter(l => l.leas_id !== leaseId)
        }
    }),
    ADD_DOCUMENT: ({ assetId, leaseId, ...doc } : LeaseDocForm) : ButlerrMutationOptions<Butlerr.Document.File[]> => ({
        url: `${assetBaseRoute}/${assetId}/leases/${leaseId}/documents`,
        method: 'POST',
        requestBody: { doc_id: doc.doc_id },
        queryKey: LeaseQueries.LEASE_DOCUMENTS(assetId, leaseId),
        updater: {
            optimistic: true,
            action: (state) => [ ...state, doc ]
        }
    }),
    REMOVE_DOCUMENT: ({ assetId, leaseId, doc_id } : LeaseDocForm) : ButlerrMutationOptions<Butlerr.Document.File[]> => ({
        url: `${assetBaseRoute}/${assetId}/leases/${leaseId}/documents/${doc_id}`,
        method: 'DELETE',
        queryKey: LeaseQueries.LEASE_DOCUMENTS(assetId, leaseId),
        updater: {
            optimistic: true,
            action: (state) => state.filter(d => d.doc_id !== doc_id)
        }
    })
};

export function useLeaseMutations<Key extends keyof typeof LeaseMutations>(
    key: Key
) {
    //You won't get type suggestions in the mutate function without this assertion
    const mutationFn = LeaseMutations[key] as (
        params: Parameters<typeof LeaseMutations[Key]>[0]
    ) => ButlerrMutationOptions;
    return useButlerrMutation(mutationFn);
}

// Lease Adjustment
interface LeaseAdjMeta {
    assetId: number;
    leaseId: number;
    adjustmentId: number;
}
interface LeaseAdjData {
    ladj_from: string;
    ladj_to: string;
    ladj_amt: number;
    ladj_doclink?: number;
}
const LeaseAdjustmentMutations = {
    CREATE: ({
        assetId,
        leaseId,
        ...leaseAdjustment
    }: LeaseAdjData & Omit<LeaseAdjMeta, 'adjustmentId'>): ButlerrMutationOptions => ({
        url: `${assetBaseRoute}/${assetId}/leases/${leaseId}/adjustment`,
        method: 'POST',
        requestBody: leaseAdjustment,
        queryKey: LeaseQueries.LEASE_ADJUSTMENTS(assetId, leaseId),
        refetchData: true,
        //Update the active amount of the lease
        updater: {
            queryKey: LeaseQueries.LEASES(assetId),
            action: (state: Butlerr.Asset.Lease[]) => state.map(l => {
                if (l.leas_id !== leaseId) return l;

                const fromDate = new Date(leaseAdjustment.ladj_from).setHours(0,0,0,0);
                const toDate = new Date(leaseAdjustment.ladj_from).setHours(0,0,0,0);
                const now = new Date().setHours(0,0,0,0);

                //We only need to update the active amount if there isn't already an active adjustment
                if (l.active_adj === null && fromDate < now && now < toDate) {
                    return {
                        ...l,
                        active_adj: leaseAdjustment.ladj_amt
                    }
                }
                return l;
            })
        }
    }),
    EDIT: ({
        assetId,
        leaseId,
        adjustmentId,
        ...leaseAdjustment
    }: LeaseAdjData & LeaseAdjMeta): ButlerrMutationOptions => ({
        url: `${assetBaseRoute}/${assetId}/leases/${leaseId}/adjustment/${adjustmentId}`,
        method: 'PUT',
        requestBody: leaseAdjustment,
        queryKey: LeaseQueries.LEASE_ADJUSTMENTS(assetId, leaseId),
        //Update the active amount of the lease
        updater: {
            queryKey: LeaseQueries.LEASES(assetId),
            action: (state: Butlerr.Asset.Lease[]) => state.map(l => {
                if (l.leas_id !== leaseId) return l;
                const fromDate = new Date(leaseAdjustment.ladj_from);
                fromDate.setHours(0,0,0,0);
                const toDate = new Date(leaseAdjustment.ladj_from);
                toDate.setHours(0,0,0,0);

                const now = new Date();
                now.setHours(0,0,0,0);

                //We only need to update the active amount if there isn't already an active adjustment
                if (l.active_adj === null && fromDate.valueOf() < now.valueOf() && now.valueOf() < toDate.valueOf()) {
                    return {
                        ...l,
                        active_adj: leaseAdjustment.ladj_amt
                    }
                }
                return l;
            })
        }
    }),
    DELETE: ({
        assetId,
        leaseId,
        adjustmentId,
    }: LeaseAdjMeta): ButlerrMutationOptions => ({
        url: `${assetBaseRoute}/${assetId}/leases/${leaseId}/adjustment/${adjustmentId}`,
        method: 'DELETE',
        queryKey: LeaseQueries.LEASE_ADJUSTMENTS(assetId, leaseId)
    }),
};

export function useLeaseAdjustmentMutations<Key extends keyof typeof LeaseAdjustmentMutations>(
    key: Key
) {
    //You won't get type suggestions in the mutate function without this assertion
    const mutationFn = LeaseAdjustmentMutations[key] as (
        params: Parameters<typeof LeaseAdjustmentMutations[Key]>[0]
    ) => ButlerrMutationOptions;
    return useButlerrMutation(mutationFn);
}
