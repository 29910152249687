import React, { Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDocumentMutations, useDocuments, folderFromAsset, folderFromUser, Doc, DocumentQueries } from "../../services/doc.service";
import styles from "./documents.module.css";
import classNames from "classnames";
import { ChevronRightRounded, FolderRounded, FolderOpenRounded, MapsHomeWorkRounded, MapsHomeWorkOutlined, PersonRounded, PersonOutlineRounded, AddRounded, UploadFileOutlined, CreateNewFolderOutlined, GridViewRounded, FormatListBulletedRounded, DownloadRounded, DeleteOutlineRounded, MoreVert, EditOutlined, DriveFileMoveOutlined, DeleteSweepOutlined, DriveFileRenameOutlineOutlined, FolderOpen } from "@mui/icons-material";
import { Container, Row, Col, Button, Dropdown, ModalProps } from "react-bootstrap";
import IconButton from "../utils/IconButton";
import { Butlerr } from "../../types/butlerr";
import { useButlerrUser } from "../../services/user.service";
import { useAssets } from "../../services/asset.service";
import useModal from "../../hooks/useModal";
import { DocumentSelectModal, EditModal, UploadModal } from "./DocsModal";
import Sidebar from "../utils/Sidebar";
import { GridFolderItem, GridFileItem, DocumentThumbnailPlaceholder, DocumentThumbnail, GridSkeleton } from "./DocsUI";
import { Transition } from "react-transition-group";
import EmptyMessage from "../utils/EmptyMessage";
import folderIcon from "../../assets/icons/folder.png";
import ReactTable from "../utils/ReactTable";
import { Column } from "react-table";
import DestructiveModal from "../utils/DestructiveModal";
import { useDownloadWithPostToken } from "../../services/useButlerrAPI";
import { useQueryClient } from "react-query";
import useAppTitle from "../../hooks/useAppTitle";

type DocumentsView = 'list' | 'grid';

interface IDocumentsContext {
    selectedRoot: Butlerr.Document.Folder | undefined;

    tree: Butlerr.Document.Folder[];
    setTree: React.Dispatch<React.SetStateAction<this['tree']>>;

    selection?: 'file' | 'folder';
    view: DocumentsView;
}

const DocumentsContext = React.createContext<IDocumentsContext | null>(null);
export const useDocumentsContext = () => {
    const val = useContext(DocumentsContext);

    if (val === null) {
        throw new Error("Context used outside of provider!");
    }
    return val;
}

export default function Documents() {

    useAppTitle('Documents')

    const { data: assets } = useAssets();
    const { data: user } = useButlerrUser();

    //When user or asset has changed, update the state to match new values
    useEffect(() => {
        if (!user) return;
        setSelectedRoot((state) => {
            //if root has not been set, or has been set as user (update root to new data)
            if (
                state === undefined ||
                (state.asst_id === null && state.user_id === user.user_id)
            ) {
                return folderFromUser(user);
            }
            //if root has been set to asset
            else if (state.asst_id !== null) {
                const asset = assets?.find(a => a.asst_id === state.asst_id);
                //If asset can't be found, go back to user root (maybe asset deleted?)
                if (asset === undefined) {
                    return folderFromUser(user);
                }
                return folderFromAsset(asset);
            }
            return state;
        })
    }, [user, assets]);

    const [ selectedRoot, setSelectedRoot ] = useState<Butlerr.Document.Folder>();

    //sidebar tabs
    const sidebarTabs = useMemo(() => {
        //get root folders from assets
        const isUserActive = selectedRoot?.asst_id === null && user?.user_id === selectedRoot.user_id
        return [
            [
                isUserActive ? PersonRounded : PersonOutlineRounded,
                user?.user_socialhandle ?? '-',
                {
                    onClick: () => {               
                        if (user) setSelectedRoot(folderFromUser(user));
                    },
                    active: isUserActive
                }
            ] as const,
            ...assets?.map((a) => {
                const isActive = a.asst_id === selectedRoot?.asst_id;
                return [
                    isActive ? MapsHomeWorkRounded : MapsHomeWorkOutlined,
                    a.asst_name,
                    {
                        onClick: () => {
                            setSelectedRoot(folderFromAsset(a));
                        },
                        active: isActive
                    }
                ] as const;
            }) ?? []
        ]

    }, [assets, selectedRoot?.asst_id, selectedRoot?.user_id, user]);

    return (
        <Container className="mt-3">
            <Row>
                <Col xs={12} lg={3}>
                    <Sidebar
                        tabs={sidebarTabs}
                        className="mt-1 mb-3"
                    />
                </Col>
                <Col xs={12} lg={9}>
                    <DocumentsTree selectedRoot={selectedRoot} />
                </Col>
            </Row>
        </Container>
        
    )
}

export function AssetDocuments({ asset } : { asset : Butlerr.Asset }) {

    const selectedRoot = useMemo(() => {
        return folderFromAsset({
            asst_id: asset.asst_id,
            doct_id: asset.doct_id,
            user_id: asset.user_id,
            asst_name: asset.asst_name
        })
    }, [asset.asst_id, asset.asst_name, asset.doct_id, asset.user_id])

    return <DocumentsTree selectedRoot={selectedRoot} />
}

interface DocumentsTreeProps {
    /**
     * The identity of `selectedRoot` needs to be stable. state value or memoized
     */
    selectedRoot?: Butlerr.Document.Folder;
    initialFolder?: number;

    onCancel?: () => void;
    onFileSelect?: (file: Butlerr.Document.File, tree: Butlerr.Document.Folder[]) => void;
    onFolderSelect?: (folder: Butlerr.Document.Folder, tree: Butlerr.Document.Folder[]) => void;

    uploadModalProps?: ModalProps;
    selectBtnText?: string;

    foldersToHide?: number | number[];
    filesToHide?: number | number[];
}

export function DocumentsTree({
    selectedRoot,
    initialFolder,
    onFileSelect,
    onFolderSelect,
    uploadModalProps,
    onCancel,
    selectBtnText = "Select",
    foldersToHide: _foldersToHide,
    filesToHide: _filesToHide
} : DocumentsTreeProps) {

    const [ view, setView ] = useState<DocumentsView>('grid');
    const [ dragTarget, setDragTarget ] = useState<number>();

    const qc = useQueryClient();

    const { mutate: moveFolder } = useDocumentMutations('MOVE_FOLDER');
    const { mutate: moveFile } = useDocumentMutations('MOVE_FILE');

    //fetch for initial tree OR the user's root tree (using user_id)
    const [ tree, setTree ] = useState<Butlerr.Document.Folder[]>([]);

    const isInitialRender = useRef(true);
    //Don't run this on initial render
    useEffect(() => {
        if (isInitialRender.current) {
            isInitialRender.current = false;
            return;
        }
        //empty tree when root folder changes
        setTree([]);
    }, [selectedRoot])

    const currentFolder : Butlerr.Document.Folder | undefined = tree[tree.length - 1] ?? selectedRoot;

    const [ uploadModal, upload ] = useModal(UploadModal, {
        show: selectedRoot !== undefined,
        parentTreeId: currentFolder?.doct_id ?? -1,
        onClose: (uploadedFile?: Butlerr.Document.File) => {
            if (uploadedFile && onFileSelect) {
                onFileSelect(uploadedFile, tree);
            }
        },
        ...uploadModalProps
    });

    //folder creation states
    const [ creatingFolder, setCreatingFolder ] = useState(false);
    const onCreateDone = useCallback(() => setCreatingFolder(false), []);

    //Left side states
    //to fetch, we need the parent id of the current tree. (which is also the item that comes before current tree in array)
    const { data: leftSideItems } = useDocuments(
        selectedRoot?.doct_id ?? -1,
        selectedRoot !== undefined
    );

    const showLeftSide = leftSideItems !== undefined && leftSideItems.folders.length > 0;

    //Show parent of current tree on left side
    const leftSide = useMemo(() => (
        <Transition
            in={showLeftSide}
            timeout={400}
            mountOnEnter
            unmountOnExit
        >
            {(state) => (
                <div
                    className={classNames(
                        "flex-shrink-0 d-flex flex-column align-items-stretch bg-white rounded-4 text-truncate border-end pb-2",
                        styles.sidepanel,
                        view === 'list' ? 'pt-2' : 'pt-4'
                    )}
                    style={{
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        transition: 'width 400ms ease-out',
                        width: (state === 'entering' || state === 'entered') ? 200 : 0,
                    }}
                >
                    {
                        leftSideItems?.folders.map((folder) => {
                            const isActive = currentFolder?.doct_id === folder.doct_id;
                            const Icon = isActive ? FolderRounded : FolderOpenRounded;
                            
                            return (
                                <Button
                                    key={folder.doct_id}
                                    style={{
                                        background: isActive ? 'var(--bs-gray-200)' : 'transparent',
                                        color: "var(--bs-gray-600)"
                                    }}
                                    className={classNames(
                                        "px-3 py-0 rounded-3 border-0 fw-semibold small d-flex align-items-center",
                                        styles.item,
                                        dragTarget === folder.doct_id && styles.dragTarget
                                    )}
                                    onClick={() => {
                                        //replace the current left active folder with the selected one
                                        setTree([ folder ])
                                    }}
                                    onDragOver={(ev) => {
                                        ev.preventDefault();
                                        //If the folder is its own, return
                                        if (ev.dataTransfer.types.includes(`text/doct_id_${folder.doct_id}`)) {
                                            return;
                                        }
                                        if (ev.dataTransfer.types.includes('text/doc_id') || ev.dataTransfer.types.includes('text/doct_id')) {
                                            ev.dataTransfer.dropEffect = "copy";
                                            setDragTarget(folder.doct_id);
                                        }
                                    }}
                                    onDragLeave={() => setDragTarget(undefined)}
                                    onDrop={(ev) => {
                                        ev.preventDefault();
                                        setDragTarget(undefined);
                    
                                        //get parentid so we can get the query & the source file or folder from it
                                        if (ev.dataTransfer.types.includes('text/doct_parentid')) {
                                            const parentFolderId = Number(ev.dataTransfer.getData('text/doct_parentid'));
                                            //If already in the folder, no need to move
                                            if (parentFolderId === folder.doct_id) return;

                                            const data = qc.getQueryData<Doc>(DocumentQueries.DOCUMENTS(parentFolderId));

                                            if (ev.dataTransfer.types.includes('text/doc_id')) {
                                                const fileId = Number(ev.dataTransfer.getData('text/doc_id'));
                                                
                                                const fileToMove = data?.files.find(f => f.doc_id === fileId);
                                                if (fileToMove) {
                                                    moveFile({
                                                        file: fileToMove,
                                                        destinationFolder: folder.doct_id
                                                    })
                                                }
                                            }
                                            else if (ev.dataTransfer.types.includes('text/doct_id')) {
                                                const folderId = Number(ev.dataTransfer.getData('text/doct_id'));
                                                if (folderId === folder.doct_id) return;
                    
                                                const folderToMove = data?.folders.find(f => f.doct_id === folderId);
                                                if (folderToMove) {
                                                    moveFolder({
                                                        folder: folderToMove,
                                                        destinationFolder: folder.doct_id
                                                    })
                                                }
                                            }
                                        }
                                    }}
                                >
                                    <Icon fontSize="small" className="me-2" />
                                    <span className="text-truncate">{folder.doct_name}</span>
                                </Button>
                            )
                        })
                    }
                </div>
            )}
        </Transition>
    ), [currentFolder?.doct_id, dragTarget, leftSideItems?.folders, moveFile, moveFolder, qc, showLeftSide, view]);
    
    const selectionMode =
        onFileSelect !== undefined ?
            'file' :
        onFolderSelect !== undefined ?
            'folder' :
        undefined;

    const insideModal = selectionMode !== undefined;

    //Memoize filesToHide & foldersToHide for a stable version
    const filesToHide = useMemo(() => {
        return _filesToHide === undefined ? []
            : typeof _filesToHide === 'number' ? [ _filesToHide ]
            : _filesToHide
    }, [_filesToHide]);
    const foldersToHide = useMemo(() => {
        return _foldersToHide === undefined ? []
            : typeof _foldersToHide === 'number' ? [ _foldersToHide ]
            : _foldersToHide
    }, [_foldersToHide]);

    const [ selectedFile, setSelectedFile ] = useState<Butlerr.Document.File>();

    return (
        <DocumentsContext.Provider value={{
            selectedRoot, tree, setTree, selection: selectionMode, view
        }}>
            <div className="d-flex flex-column rounded-4" style={{ minWidth: 600, backgroundColor: 'var(--bs-gray-200)' }}>
                <div className="d-flex align-items-stretch justify-content-between rounded-4">
                    <BreadCrumbs />
                    
                    <div className="px-3 py-2 d-flex align-items-center flex-grow-0" style={{ height: 'fit-content' }}>
                        <IconButton
                            transparent
                            border={false}
                            Icon={FormatListBulletedRounded}
                            title="List view"
                            className={classNames(view === 'list' ? "text-black" : "text-secondary")}
                            onClick={() => setView('list')}
                        />
                        {/* separator */}
                        <div
                            style={{
                                width: '1px'
                            }}
                            className="mx-2 my-1 align-self-stretch bg-secondary"
                        ></div>
                        <IconButton
                            transparent
                            border={false}
                            Icon={GridViewRounded}
                            title="Grid view"
                            className={classNames("me-2", view === 'grid' ? "text-black" : "text-secondary")}
                            onClick={() => setView('grid')}
                        />

                        <Dropdown className="flex-shrink-0">
                            <Dropdown.Toggle as="span" bsPrefix=" ">
                                <IconButton
                                    transparent
                                    border={false}
                                    className="text-dark"
                                    label="New"
                                    Icon={AddRounded}
                                />
                            </Dropdown.Toggle>

                            <Dropdown.Menu
                                style={{
                                    pointerEvents: selectedRoot === undefined ? 'none' : undefined
                                }}
                            >
                                {
                                    selectionMode !== 'folder' && (
                                        <Dropdown.Item onClick={upload}>
                                            <UploadFileOutlined fontSize="small" className="me-2" />
                                            <span>File</span>
                                        </Dropdown.Item>
                                    )
                                }
                                <Dropdown.Item onClick={() => setCreatingFolder(true)}>
                                    <CreateNewFolderOutlined fontSize="small" className="me-2" />
                                    <span>Folder</span>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
                <div className="d-flex">
                    { leftSide }

                    <div
                        className={classNames("flex-grow-1 rounded-4 bg-white", view !== 'list' && 'p-3')}
                        style={{
                            borderTopLeftRadius: showLeftSide ? 0 : undefined,
                            borderBottomLeftRadius: showLeftSide ? 0 : undefined,
                            width: "calc(100% - 200px)"
                        }}
                    >
                        <RightSide
                            showNewFolder={creatingFolder}
                            onCreateDone={onCreateDone}
                            //onFileClick is used in useMemo for list view, so make sure it's stable
                            onFileClick={selectionMode === 'file' ? setSelectedFile : undefined}
                            foldersToHide={foldersToHide}
                            filesToHide={filesToHide}
                            initialFolder={initialFolder}
                        />
                    </div>
                </div>
                {
                    insideModal && (
                        <div className="px-3 py-2 d-flex justify-content-end align-items-center">
                            <small className="fw-semibold me-3">
                                {(
                                    selectionMode === 'file' ?
                                        selectedFile?.doc_name
                                    : selectionMode === 'folder' ?
                                        currentFolder?.doct_name
                                    : null
                                ) ?? ''}
                            </small>
                            <IconButton
                                label="Cancel"
                                title="Cancel"
                                onClick={onCancel}
                                transparent
                                className="border-primary text-primary"
                            />
                            <IconButton
                                label={selectBtnText}
                                title="Select"
                                disabled={
                                    (selectionMode === 'folder' && currentFolder === undefined) ||
                                    (selectionMode === 'file' && selectedFile === undefined)
                                }
                                onClick={() => {
                                    if (selectionMode === 'folder') {
                                        onFolderSelect?.(currentFolder, tree);
                                    }
                                    else if (selectedFile !== undefined) {
                                        onFileSelect?.(selectedFile, tree);
                                    }
                                }}
                                className="ms-3"
                            />
                        </div>
                    )
                }
            </div>

            { uploadModal }
        </DocumentsContext.Provider>
    )
}

function BreadCrumbs() {

    const { selectedRoot, tree, setTree } = useDocumentsContext();

    const { mutate: moveFolder } = useDocumentMutations('MOVE_FOLDER');
    const { mutate: moveFile } = useDocumentMutations('MOVE_FILE');

    const qc = useQueryClient();

    const [ dragTarget, setDragTarget ] = useState<number>();

    const content = useMemo(() => {
        const folders = [ ...tree ];
        if (selectedRoot) {
            folders.unshift(selectedRoot);
        }
        return (
            <>
                {folders.map((tree, idx) => (
                    <Fragment key={tree.doct_id}>
                        {
                            idx !== 0 && (
                                <ChevronRightRounded
                                    fontSize="small"
                                    className="mx-1"
                                    style={{ color: 'var(--bs-gray-500)' }}
                                />
                            )
                        }
                        
                        <IconButton
                            Icon={
                                idx !== 0 ? undefined :
                                tree.asst_id === null ?
                                PersonRounded :
                                MapsHomeWorkRounded
                            }
                            label={tree.doct_name}
                            transparent
                            border={false}
                            style={{ color: 'var(--bs-gray-500)', height: 'calc(100% - 1rem)' }}
                            className={classNames(styles.breadcrumb, "px-2 my-2", dragTarget === tree.doct_id && styles.dragTarget)}
                            onClick={() => {
                                setTree(state => {
                                    //cut tree to the index of the folder clicked
                                    const idx = state.findIndex(t => t.doct_id === tree.doct_id);
                                    return state.slice(0, idx + 1);
                                })
                            }}
                            onDragOver={(ev) => {
                                ev.preventDefault();
                                //If the folder is its own, return
                                if (ev.dataTransfer.types.includes(`text/doct_id_${tree.doct_id}`)) {
                                    return;
                                }
                                if (ev.dataTransfer.types.includes('text/doc_id') || ev.dataTransfer.types.includes('text/doct_id')) {
                                    ev.dataTransfer.dropEffect = "copy";
                                    setDragTarget(tree.doct_id);
                                }
                            }}
                            onDragLeave={() => setDragTarget(undefined)}
                            onDrop={(ev) => {
                                ev.preventDefault();
                                setDragTarget(undefined);
            
                                //get parentid so we can get the query & the source file or folder from it
                                if (ev.dataTransfer.types.includes('text/doct_parentid')) {
                                    const parentFolderId = Number(ev.dataTransfer.getData('text/doct_parentid'));
                                    if (parentFolderId === tree.doct_id) return;

                                    const data = qc.getQueryData<Doc>(DocumentQueries.DOCUMENTS(parentFolderId));
                                    
                                    if (ev.dataTransfer.types.includes('text/doc_id')) {
                                        const fileId = Number(ev.dataTransfer.getData('text/doc_id'));
                                        
                                        const fileToMove = data?.files.find(f => f.doc_id === fileId);
                                        if (fileToMove) {
                                            moveFile({
                                                file: fileToMove,
                                                destinationFolder: tree.doct_id
                                            })
                                        }
                                    }
                                    else if (ev.dataTransfer.types.includes('text/doct_id')) {
                                        const folderId = Number(ev.dataTransfer.getData('text/doct_id'));
                                        if (folderId === tree.doct_id) return;
            
                                        const folderToMove = data?.folders.find(f => f.doct_id === folderId);
                                        if (folderToMove) {
                                            moveFolder({
                                                folder: folderToMove,
                                                destinationFolder: tree.doct_id
                                            })
                                        }
                                    }
                                }
                            }}
                        />
                    </Fragment>
                ))}
            </>
        )
    }, [dragTarget, moveFile, moveFolder, qc, selectedRoot, setTree, tree])

    if (selectedRoot === undefined) {
        return (
            <div className="ms-3 d-flex flex-wrap align-items-center">
                <IconButton
                    label="-"
                    transparent
                    border={false}
                    style={{ color: 'var(--bs-gray-500)', height: 'calc(100% - 1rem)' }}
                    className="px-2 my-2"
                />
            </div>
        )
    }

    return (
        <div className="ms-3 d-flex flex-wrap align-items-center">
            {content}
        </div>
    )
}

interface RightSideProps {
    showNewFolder?: boolean;
    onCreateDone: () => void;

    onFileClick?: (file: Butlerr.Document.File) => void;
    foldersToHide: number[];
    filesToHide: number[];

    initialFolder?: number;
}

function RightSide({ showNewFolder = false, onCreateDone, onFileClick, foldersToHide, filesToHide, initialFolder } : RightSideProps) {

    const [ folderToSelect, setFolderToSelect ] = useState(initialFolder);
    useEffect(() => {
        setFolderToSelect(initialFolder);
    }, [initialFolder])

    const { tree, setTree, selectedRoot, selection, view } = useDocumentsContext();

    const currentTreeId = tree[tree.length - 1]?.doct_id ?? selectedRoot?.doct_id;

    const { data: documents, isLoading } = useDocuments(
        currentTreeId ?? -1,
        !!currentTreeId
    );
    useEffect(() => {
        if (folderToSelect !== undefined && documents) {
            const folder = documents.folders.find(d => d.doct_id === folderToSelect);
            if (folder) {
                setTree(t => [ ...t, folder ])
                setFolderToSelect(undefined);
            }
        }
    }, [selectedRoot, documents, folderToSelect, setTree]);

    //Create folder states
    useEffect(() => {
        //when create is done & documents refresh, call create done.
        //this will also be called when the current tree changes but that shouldn't be a problem.
        onCreateDone();
    }, [documents, onCreateDone])

    const { mutate : createFolder, isLoading: isCreateLoading } = useDocumentMutations('CREATE_FOLDER');

    const handleCreateDone = useCallback((name: string, source: 'blur' | 'key') => {
        //If nothing entered, and input lost focus (click out), cancel
        if (!name && source === 'blur') {
            return onCreateDone();
        }

        const currentTreeId = tree[tree.length - 1]?.doct_id ?? selectedRoot?.doct_id ?? -1;
        createFolder({
            doct_parentid: currentTreeId,
            doct_name: name
        })
    }, [createFolder, onCreateDone, selectedRoot?.doct_id, tree])

    //edit folder state
    const { mutate: editFolder } = useDocumentMutations('EDIT_FOLDER');

    const [ editFolderId, setEditFolderId ] = useState<number>();
    const handleEditDone = useCallback((folder: Butlerr.Document.Folder, newName: string, source: 'blur' | 'key') => {
        //If nothing entered, and input lost focus (click out), cancel
        if (!newName && source === 'blur') {
            return setEditFolderId(undefined);
        }
        editFolder({
            doct_id: folder.doct_id,
            doct_parentid: folder.doct_parentid,
            doct_name: newName
        }, {
            onSuccess: () => setEditFolderId(undefined)
        })
    }, [editFolder])

    const folders = useMemo(() => {
        if (!foldersToHide.length) return documents?.folders ?? [];

        return documents?.folders.filter(f => !foldersToHide.includes(f.doct_id)) ?? []
    }, [documents?.folders, foldersToHide]);

    const hideFiles = selection === 'folder';
    const files = useMemo(() => {
        if (hideFiles) return [];
        if (!filesToHide.length) return documents?.files ?? [];

        return documents?.files.filter(f => !filesToHide.includes(f.doc_id)) ?? []
    }, [documents?.files, filesToHide, hideFiles]);

    //Return Components
    if (
        folders.length === 0 &&
        files.length === 0 &&
        !showNewFolder
    ) {
        return <EmptyMessage message="Nothing to see here" className="fs-6" style={{ minHeight: '400px' }} />
    }

    const props : ListProps = {
        files,
        folders,
        isLoading: isLoading || documents === undefined,
        showNewFolder,
        isCreateLoading,
        onCreateDone: handleCreateDone,
        editFolderId,
        setEditFolderId,
        onEditDone: handleEditDone,
        onFileClick,
    };

    if (view === 'grid') {
        return <GridList {...props} />
    }
    return <ListList {...props} />
}

interface ListProps {
    files: Butlerr.Document.File[];
    folders: Butlerr.Document.Folder[];

    isLoading: boolean;

    showNewFolder: boolean;
    isCreateLoading: boolean;
    onCreateDone: (name: string, source: 'key' | 'blur') => void;

    editFolderId: number | undefined;
    setEditFolderId: React.Dispatch<React.SetStateAction<ListProps['editFolderId']>>;
    onEditDone: (folder: Butlerr.Document.Folder, newName: string, source: 'key' | 'blur') => void;

    onFileClick?: (file: Butlerr.Document.File) => void;
}

function ListList({
    files,
    folders,
    isLoading,
    showNewFolder,
    isCreateLoading,
    onCreateDone,
    editFolderId,
    setEditFolderId,
    onEditDone,
    onFileClick
} : ListProps) {

    const { selectedRoot, setTree, selection } = useDocumentsContext();

    const [ selectedFolder, setSelectedFolder ] = useState<Butlerr.Document.Folder>();
    const [ selectedFile, setSelectedFile ] = useState<Butlerr.Document.File>();

    //FOLDER states
    //delete state
    const { mutateAsync: deleteFolder } = useDocumentMutations('DELETE_FOLDER');
    const [ deleteFolderModal, openDeleteFolderModal ] = useModal(DestructiveModal, {
        title: <span>Delete <i>{selectedFolder?.doct_name ?? ''}</i>?</span>,
        description: "This will delete all the files & subfolders under this directory",
        show: selectedFolder !== undefined,
        onConfirm: () => deleteFolder({
            doct_id: selectedFolder?.doct_id ?? -1,
            doct_parentid: selectedFolder?.doct_parentid ?? -1
        }),
        textToEnterToConfirm: "DELETE",
        className: "modal-layer-2",
        backdropClassName: "modal-layer-2",
        //Key to re-mount the modal when the selected folder changes, so the `DELETE` text doesn't come pre-entered
        key: selectedFolder?.doct_id
    })

    //move state
    //move states
    const { mutate: moveFolder } = useDocumentMutations('MOVE_FOLDER');
    const [ moveFolderModal, openMoveFolderModal ] = useModal(DocumentSelectModal, {
        rootFolder: selectedRoot,
        selectBtnText: "Move",
        show: selectedFolder !== undefined,
        onFolderSelect: (destination: Butlerr.Document.Folder, tree: Butlerr.Document.Folder[]) => {
            if (selectedFolder) {
                moveFolder({
                    folder: selectedFolder,
                    destinationFolder: destination.doct_id
                });
                setTree(tree);
            }
        },
        foldersToHide: selectedFolder?.doct_id
    })

    const download = useDownloadWithPostToken();
    const [ editFileModal, openEditFileModal ] = useModal(EditModal, {
        show: selectedFile !== undefined,
        document: selectedFile!,
        className: "modal-layer-2",
        backdropClassName: "modal-layer-2"
    });

    //FILE states
    //delete state
    const { mutateAsync: deleteFile } = useDocumentMutations('DELETE_FILE');
    const [ deleteFileModal, openDeleteFileModal ] = useModal(DestructiveModal, {
        title: <span>Delete <i>{selectedFile?.doc_name}</i>?</span>,
        description: "This file will be permanently deleted",
        show: selectedFile !== undefined,
        onConfirm: () => deleteFile({
            doct_parentid: selectedFile?.doct_parentid ?? -1,
            doc_id: selectedFile?.doc_id ?? -1
        }),
        className: "modal-layer-2",
        backdropClassName: "modal-layer-2"
    })

    //move states
    const { mutate: moveFile } = useDocumentMutations('MOVE_FILE');
    const [ moveFileModal, openMoveFileModal ] = useModal(DocumentSelectModal, {
        rootFolder: selectedRoot,
        selectBtnText: "Move",
        show: selectedFile !== undefined,
        onFolderSelect: (folder: Butlerr.Document.Folder, tree: Butlerr.Document.Folder[]) => {
            if (selectedFile) {
                moveFile({
                    file: selectedFile,
                    destinationFolder: folder.doct_id
                });
                setTree(tree);
            }
        },
    })

    type D =
    |   (Butlerr.Document.File & Partial<Butlerr.Document.Folder>)
    |   (Butlerr.Document.Folder & Partial<Butlerr.Document.File>);

    const tableData = useMemo(() => {
        const arr = [
            ...folders.map(f => ({
                ...f,
                doc_id: undefined,
                doc_name: undefined,
                doc_format: undefined,
                doc_desc: undefined,
                doc_created: undefined
            })),
            ...files.map(f => ({
                ...f,
                doct_id: undefined,
                doct_name: undefined,
                doct_sys: undefined,
                items: undefined
            }))
        ] as D[];

        if (showNewFolder) {
            arr.splice(folders.length, 0, {
                //We will use this id to see if it's a new folder
                doct_id: -1,
                doct_name: "New folder",
                doct_sys: 0,
                doct_parentid: -1,
                items: 0,
                asst_id: null,
                user_id: null,
                doc_id: undefined,
                doc_name: undefined,
                doc_format: undefined,
                doc_desc: undefined,
                doc_created: undefined
            })
        }
        return arr;

    }, [files, folders, showNewFolder]);

    const [ openDropdown, setOpenDropdown ] = useState<[ 'file' | 'folder', number ]>();

    const columns = useMemo<Column<D>[]>(() => [
        {
            Header: 'Name',
            accessor: 'doc_name',
            width: 250,
            minWidth: 100,
            sortType: (a, b, _, desc) => {
                /**
                 * React table reverse the sort results automatically for descending sort.
                 * But we always want to show folders first & files last
                 * So, when desc, invert the return val for folder & file sort
                 */
                const aIsFile = a.original.doc_id !== undefined;
                const bIsFile = b.original.doc_id !== undefined;

                if (aIsFile && !bIsFile) {
                    // A is file, B is folder, B should come first
                    return desc ? -1 : 1;
                }
                if (!aIsFile && bIsFile) {
                    // B is file, A is folder, A should come first
                    return desc ? 1 : -1;
                }

                const aStr = a.original.doct_name ?? a.original.doc_name!;
                const bStr = b.original.doct_name ?? b.original.doc_name!;

                //Both are either files OR folders, sort by name string
                if (aStr > bStr) return 1;
                if (bStr > aStr) return -1;
                return 0;
            },
            Cell: ({ row }) => (
                <div className="d-flex align-items-center">
                    { row.original.doct_id !== undefined ? (
                        <img src={folderIcon} alt="folder" draggable={false} />
                    ) : (
                        <DocumentThumbnail format={row.original.doc_format ?? 'UNK'} id={row.original.doc_id} />
                    )}
                    
                    <ListFolderName
                        name={row.original.doc_name ?? row.original.doct_name ?? "New folder"}
                        isEditing={
                            row.original.doct_id !== undefined &&
                            ( (row.original.doct_id === -1 && !isCreateLoading) || row.original.doct_id === editFolderId )
                        }
                        onEditDone={(name, src) => {
                            if (row.original.doct_id === -1) {
                                onCreateDone(name, src);
                            }
                            else {
                                onEditDone(row.original as Butlerr.Document.Folder, name, src);
                            }
                        }}
                    />
                </div>
            )
        },
        {
            Header: 'Description',
            accessor: 'doc_desc',
            width: 400,
            sortType: (a, b, _, desc) => {
                /**
                 * React table reverse the sort results automatically for descending sort.
                 * But we always want to show folders first & files last
                 * So, when desc, invert the return val for folder & file sort
                 */
                const aIsFile = a.original.doc_id !== undefined;
                const bIsFile = b.original.doc_id !== undefined;

                if (aIsFile) {
                    if (bIsFile) {
                        //Both are files, sort by description
                        const aDesc = a.original.doc_desc;
                        const bDesc = b.original.doc_desc;

                        if (!aDesc) return -1;
                        if (!bDesc) return 1;

                        if (aDesc > bDesc) return 1;
                        if (bDesc > aDesc) return -1;
                        return 0;
                    }
                    // A is file, B is folder, B should come first
                    return desc ? -1 : 1;
                }
                if (bIsFile) {
                    // B is file, A is folder, A should come first
                    return desc ? 1 : -1;
                }
                //Both folders, sort by item count
                const aItems = a.original.items ?? 0;
                const bItems = b.original.items ?? 0;
                if (aItems > bItems) return 1;
                if (bItems > aItems) return -1;
                return 0;
            },
            Cell: ({ value, row }) => (
                <span className="small text-muted">
                    {
                        value ??
                        (row.original.items !== undefined ?
                            `${!row.original.items ? "No" : row.original.items} item${row.original.items === 1 ? '' : 's'}`
                        : "-")
                    }
                </span>
            )
        },
        {
            accessor: 'doc_id',
            disableSortBy: true,
            minWidth: 45,
            width: 45,
            maxWidth: 45,
            Cell: ({ value, row }) => {
                if (selection !== undefined) return <></>

                //value is document id;
                const isFile = value !== undefined;
                const DeleteIcon = isFile ? DeleteOutlineRounded : DeleteSweepOutlined;
                return (
                    <Dropdown
                        className={styles.dropdown}
                        show={
                            openDropdown?.[0] === (isFile ? 'file' : 'folder') &&
                            (openDropdown[1] === (isFile ? row.original.doc_id : row.original.doct_id))
                        }
                        onToggle={(val, ev) => {
                            ev.originalEvent?.stopPropagation()
                            if (!val) setOpenDropdown(undefined);
                            else if (isFile) setOpenDropdown([ 'file', row.original.doc_id! ]);
                            else setOpenDropdown([ 'folder', row.original.doct_id! ]);
                        }}
                    >
                        <Dropdown.Toggle
                            as="span"
                            bsPrefix=" "
                            variant="clear"
                        >
                            <IconButton
                                transparent
                                border={false}
                                title="More"
                                Icon={MoreVert}
                                iconHtmlColor="var(--black)"
                                className="fw-normal"
                            />
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="p-0">
                            {
                                isFile ? (
                                    <>
                                        <Dropdown.Item
                                            className="small"
                                            onClick={() => {
                                                download('DOCUMENTS', value)
                                            }}
                                        >
                                            <DownloadRounded fontSize="small" className="me-2" />
                                            <span>Download</span>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            className="small"
                                            onClick={(ev) => {
                                                setSelectedFile(row.original as Butlerr.Document.File);

                                                if (onFileClick) onFileClick(row.original as Butlerr.Document.File);
                                                else openEditFileModal();
                                            }}
                                        >
                                            <EditOutlined fontSize="small" className="me-2" />
                                            <span>Edit</span>
                                        </Dropdown.Item>
                                    </>
                                ) : (
                                    <>
                                        <Dropdown.Item
                                            className="small"
                                            onClick={() => {
                                                setTree(t => [ ...t, row.original as Butlerr.Document.Folder ])
                                            }}
                                        >
                                            <FolderOpen fontSize="small" className="me-2" />
                                            <span>Open</span>
                                        </Dropdown.Item>
                                        {
                                            row.original.doct_sys !== 1 && (
                                                <Dropdown.Item
                                                    className="small"
                                                    onClick={() => setEditFolderId(row.original.doct_id!)}
                                                >
                                                    <DriveFileRenameOutlineOutlined fontSize="small" className="me-2" />
                                                    <span>Rename</span>
                                                </Dropdown.Item>
                                            )
                                        }
                                    </>
                                )
                            }

                            {
                                row.original.doct_sys !== 1 && (
                                    <>
                                        <Dropdown.Item
                                            className="small"
                                            onClick={() => {
                                                if (isFile) {
                                                    setSelectedFile(row.original as Butlerr.Document.File);
                                                    openMoveFileModal();
                                                }
                                                else {
                                                    setSelectedFolder(row.original as Butlerr.Document.Folder);
                                                    openMoveFolderModal();
                                                }
                                            }}
                                        >
                                            <DriveFileMoveOutlined fontSize="small" className="me-2" />
                                            <span>Move</span>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            className="small text-danger"
                                            onClick={(e) => {
                                                if (isFile) {
                                                    setSelectedFile(row.original as Butlerr.Document.File);
                                                    openDeleteFileModal();
                                                }
                                                else {
                                                    setSelectedFolder(row.original as Butlerr.Document.Folder);
                                                    openDeleteFolderModal();
                                                }
                                            }}
                                        >
                                            <DeleteIcon fontSize="small" className="me-2" />
                                            <span>Delete</span>
                                        </Dropdown.Item>
                                    </>
                                )
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                )
            }
        }
    ], [
        editFolderId,
        isCreateLoading,
        openDropdown,
        selection,
        download,
        onCreateDone,
        onEditDone,
        onFileClick,
        openDeleteFileModal,
        openDeleteFolderModal,
        openEditFileModal,
        openMoveFileModal,
        openMoveFolderModal,
        setEditFolderId,
        setTree
    ]);

    const [ dragTarget, setDragTarget ] = useState<number>();
    const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

    const Row = useCallback((props: React.HTMLAttributes<HTMLTableRowElement>, data: D) => {
        const isFile = data.doc_id !== undefined;
        const file = data as Butlerr.Document.File;
        const folder = data as Butlerr.Document.Folder;

        return (
            <tr
                {...props}
                className={classNames(
                    props.className,
                    styles.item,
                    'cursor-pointer rounded',
                    !isFile && dragTarget === folder.doct_id && styles.dragTarget
                )}
                onClick={() => {
                    if (!isFile) setTree(t => [...t, folder])
                    else {
                        setSelectedFile(file);
                        openEditFileModal()
                    }
                }}
                //Drag & drop
                draggable={selection === undefined && folder.doct_sys !== 1}
                onDragStart={(ev) => {
                    if (ev.dataTransfer) {
                        if (isFile) {
                            setOpenDropdown(undefined);
                            ev.dataTransfer.setData("text/doc_id", String(file.doc_id));
                        }
                        else {
                            setOpenDropdown(undefined);
                            ev.dataTransfer.setData("text/doct_id", String(folder.doct_id));
                        }
                        ev.dataTransfer.setData("text/doct_parentid", String(data.doct_parentid));
                        //We will add this one, just so we can test if the folder being dragged over is itself (in onDragOver)
                        ev.dataTransfer.setData(`text/doct_id_${folder.doct_id}`, '-');
                        ev.dataTransfer.effectAllowed = "copy";
                    }
                }}
                onDragOver={!folder ? undefined : (ev) => {
                    ev.preventDefault();
                    if (timeoutRef.current) clearTimeout(timeoutRef.current);

                    //If the folder is its own, return
                    if (ev.dataTransfer.types.includes(`text/doct_id_${folder.doct_id}`)) {
                        return;
                    }
                    if (ev.dataTransfer.types.includes('text/doc_id') || ev.dataTransfer.types.includes('text/doct_id')) {
                        ev.dataTransfer.dropEffect = "copy";
                        setDragTarget(folder.doct_id);
                    }
                }}
                onDragLeave={() => {
                    /**
                     * In List view, `dragleave` and `dragover` events gets repeatedly triggered infinitely, resulting in a loop until the user lets the drag go.
                     * Set a timeout to changet the dragTarget & cancel the timeout in `dragover` as a fix for now.
                     */
                    timeoutRef.current = setTimeout(() => {
                        setDragTarget(undefined);
                    }, 100);
                }}
                onDrop={(ev) => {
                    ev.preventDefault();
                    setDragTarget(undefined);
                    //can only drop on folders
                    if (!isFile) {
                        if (ev.dataTransfer.types.includes('text/doc_id')) {
                            const fileId = Number(ev.dataTransfer.getData('text/doc_id'));
                            const file = files.find(f => f.doc_id === fileId);
                            if (file) {
                                moveFile({
                                    file, destinationFolder: folder.doct_id
                                })
                            }
                        }
                        else if (ev.dataTransfer.types.includes('text/doct_id')) {
                            const folderId = Number(ev.dataTransfer.getData('text/doct_id'));
                            const folderToMove = folders.find(f => f.doct_id === folderId);
                            if (folderToMove) {
                                moveFolder({
                                    folder: folderToMove,
                                    destinationFolder: folder.doct_id
                                })
                            }
                        }
                    }
                }}
            />
        )
    }, [dragTarget, selection, files, folders, moveFile, moveFolder, openEditFileModal, setTree])

    return (
        <div className={styles.list}>
            <ReactTable
                isLoading={isLoading}
                pagination={false}
                showRowCount={false}
                flexLayout
                data={tableData}
                columns={columns}
                Row={Row}
            />
            { editFileModal }
            { moveFileModal }
            { moveFolderModal }
            { deleteFileModal }
            { deleteFolderModal }
        </div>
    )
}

interface ListFolderNameCellProps {
    name: string;
    isEditing: boolean;
    onEditDone: (value: string, source: 'key' | 'blur') => void;
}

function ListFolderName({ name: initialName, isEditing, onEditDone } : ListFolderNameCellProps) {

    const [ name, setName ] = useState(initialName);

    useEffect(() => {
        setName(initialName);
    }, [initialName])

    const inputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        //focus on input & select text on edit
        if (isEditing) {
            inputRef.current?.focus();
            inputRef.current?.setSelectionRange(0, inputRef.current.value.length);
        }
    }, [isEditing])

    return (
        !isEditing ? <span title={name} className="ms-2" style={{ border: '2px solid transparent' }}>{name}</span> : (
            <input
                ref={inputRef}
                value={name}
                className="ms-2 w-100"
                onChange={(e) => setName(e.target.value)}
                onBlur={() => onEditDone(name, 'blur')}
                onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                        onEditDone(name, 'key');
                    }
                }}
            />
        )
    )
}

function GridList({
    files,
    folders,
    isLoading,
    showNewFolder,
    isCreateLoading,
    onCreateDone,
    editFolderId,
    setEditFolderId,
    onEditDone,
    onFileClick
} : ListProps) {

    return (
        <div className={styles.grid}>
            {
                isLoading ? <GridSkeleton /> :
                (
                    <>
                        {
                            folders.map((folder) => (
                                <GridFolderItem
                                    key={folder.doct_id}
                                    name={folder.doct_name}
                                    isEditing={editFolderId === folder.doct_id}
                                    onEditDone={(name, src) => onEditDone(folder, name, src)}
                                    onEditStart={() => setEditFolderId(folder.doct_id)}
                                    folder={folder}
                                />
                            ))
                        }
                        { showNewFolder && (
                            <GridFolderItem
                                name="New folder"
                                isEditing={!isCreateLoading}
                                onEditDone={onCreateDone}
                            />
                        ) }
                        {
                            files.map((file) => (
                                <GridFileItem
                                    key={file.doc_id}
                                    document={file}
                                    name={`${file.doc_name}${file.doc_format ? '.' + file.doc_format : ''}`}
                                    thumbnail={
                                        <DocumentThumbnailPlaceholder format={file.doc_format ?? 'UNK'}  />
                                    }
                                    onClick={onFileClick?.bind(null, file)}
                                />
                            ))
                        }
                    </>
                )
            }
        </div>
    )
}