import { Container, Row, Col } from "react-bootstrap"
import useAppTitle from "../../hooks/useAppTitle"
import SocialSidebar from "./SocialSidebar";
import { Switch, Route, Redirect } from "react-router";
import PostLink from "./PostLink";
import SocialProfile from "./SocialProfile";
import SocialSearch from "./SocialSearch";
import SocialSubSidebar from "./SocialSubSidebar";
import SocialChannels from "./channels/ChannelList";
import NewsList from "./news/News";
import ChannelDetails from "./channels/ChannelDetails";
import Network from "./network/Network";
import PostList from "./PostList";
import SocialChats from "./chats/Chats";
import { ArticlesList } from "./ArticlesList";

export enum SocialRoutes {
    POSTS = "/home/posts",
    CHANNELS = "/home/channels",

    POST_DETAILS = "/home/posts/:id",
    CHANNEL_DETAILS = "/home/channels/:id",

    PROFILE = "/home/profile",
    USER_PROFILE = "/home/profile/:id",

    NEWS = "/home/news",
    
    NETWORK = "/home/network",

    CHATS = "/home/chats",

    ARTICLES = "/home/articles",

    /**
     * Search URL Params:
     * q =  search query
     * h = hashtag search
     * t = tab to search in ( user or posts )
     */
    SEARCH = "/home/search"
}

type TArgs =
  | [SocialRoutes]
  | [SocialRoutes, { [param: string]: string | number }]

export function createSocialRoute(...args: TArgs) {
    const [ path, params ] = args;

    if (params === undefined) {
        return path;
    }
    //replace all params with the params passed
    return Object.entries(params).reduce((previousValue: string, [param, value]) => {
        return previousValue.replace(`:${param}`, '' + value)
    }, path);
}

export default function SocialHome() {

    useAppTitle("Home")

    return (
        <Container>
            <Row>
                <Col
                    xs={{
                        span: 12,
                        order: 1
                    }}
                    md={{
                        span: 6,
                        order: 1
                    }}
                    lg={{
                        span: 2,
                        order: 1
                    }}
                    className="sticky-after-navbar-lg pt-4"
                >
                    <SocialSidebar />
                </Col>
                <Col
                    className="sticky-after-navbar-lg pt-4"
                    xs={{
                        span: 12,
                        order: 2
                    }}
                    md={{
                        span: 6,
                        order: 2
                    }}
                    lg={{
                        span: 3,
                        order: 3
                    }}
                >
                    <SocialSubSidebar />
                </Col>
                <Col
                    xs={{
                        span: 12,
                        order: 3
                    }}
                    md={{
                        span: 12,
                        order: 3
                    }}
                    lg={{
                        span: 7,
                        order: 2
                    }}
                    className="pt-4"
                >
                    <Switch>
                        {/* Post Links */ }
                        <Route
                            exact
                            path={SocialRoutes.POSTS}
                        >
                            <PostList />
                        </Route>
                        <Route
                            path={SocialRoutes.POST_DETAILS}
                        >
                            <PostLink />
                        </Route>

                        {/* Channel Links */}
                        <Route
                            exact
                            path={SocialRoutes.CHANNELS}
                        >
                            <SocialChannels />
                        </Route>
                        <Route
                            path={SocialRoutes.CHANNEL_DETAILS}
                        >
                            <ChannelDetails />
                        </Route>

                        <Route path={SocialRoutes.NEWS}>
                            <NewsList />
                        </Route>

                        <Route path={SocialRoutes.ARTICLES}>
                            <ArticlesList emptyMessage="No results" />
                        </Route>

                        <Route path={SocialRoutes.NETWORK}>
                            <Network />
                        </Route>

                        {/* Profile Links */}
                        <Route
                            exact
                            path={[ SocialRoutes.USER_PROFILE, SocialRoutes.PROFILE ]}
                        >
                            <SocialProfile />
                        </Route>

                        <Route
                            path={SocialRoutes.SEARCH}
                        >
                            <SocialSearch />
                        </Route>

                        <Route
                            path={SocialRoutes.CHATS    }
                        >
                            <Switch>
                                <Route
                                    path={SocialRoutes.CHATS + '/:chatId'}
                                >
                                    <SocialChats />
                                </Route>
                                <Route
                                    path={SocialRoutes.CHATS}
                                    exact={true}
                                >
                                    <SocialChats />
                                </Route>
                                <Redirect to={SocialRoutes.CHATS} />
                            </Switch>
                        </Route>

                        {/* Redirect if no match */}
                        <Redirect to={SocialRoutes.POSTS} />
                    </Switch>
                </Col>
            </Row>
        </Container>
    )
}
