import { HTMLAttributes } from 'react';
import { Card, Row, Col, Dropdown } from 'react-bootstrap';
import { Butlerr } from '../../../types/butlerr';
import LineClampText from '../../utils/LineClampText';
import ChannelAvatar from '../../social/channels/ChannelAvatar';
import styles from '../../assets/asset.module.css';
import { MoreVert } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { createSocialRoute, SocialRoutes } from '../../social/SocialHome';
import IconButton from '../../utils/IconButton';
import EditModal from '../../social/channels/ChannelEdit';
import DeleteModal from '../../social/channels/ChannelDelete';
import useModal from '../../../hooks/useModal';

interface ChannelItemProps extends HTMLAttributes<HTMLDivElement> {
    channel: Butlerr.Social.Channel;
    isAdmin: Boolean;
}

export default function ChannelItem({ channel, isAdmin }: ChannelItemProps) {
    const [editModal, openEdit] = useModal(EditModal, {
        channel: channel,
    });

    const [deleteModal, openDelete] = useModal(DeleteModal, {
        channel: channel,
    });
    return (
        <Card className={'border-1 shadow-sm ' + styles.convoMsgCard}>
            <Card.Body
                className="p-2 d-flex justify-content-start align-items-center flex-row"
                as={Row}
            >
                <Col className="align-items-center" xs={3}>
                    {isAdmin && (
                        <>
                            <div
                                style={{
                                    position: 'absolute',
                                    right: 10,
                                    top: 10,
                                }}
                            >
                                <Dropdown
                                    className={styles.dropdown}
                                    style={{ opacity: 1 }}
                                >
                                    <Dropdown.Toggle as="span" bsPrefix=" ">
                                        <IconButton
                                            transparent
                                            border={false}
                                            Icon={MoreVert}
                                            iconHtmlColor="var(--bs-gray)"
                                        />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="p-0">
                                        <Dropdown.Item onClick={openEdit}>
                                            Edit
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={openDelete}>
                                            Delete
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            {editModal}
                            {deleteModal}
                        </>
                    )}
                    <ChannelAvatar
                        clickable
                        channel={channel}
                        width={50}
                        height={50}
                        className="cursor-pointer shadow me-3"
                    />
                </Col>

                <Col className="align-self-start" xs={8}>
                    <div className="flex-grow-1 d-flex flex-column">
                        <Link
                            to={createSocialRoute(
                                SocialRoutes.CHANNEL_DETAILS,
                                { id: channel.chan_id }
                            )}
                            className="link-underline text-dark"
                        >
                            <LineClampText
                                className="mb-0 text-left small fw-bold"
                                maxLines={1}
                            >
                                {channel.chan_name}
                            </LineClampText>
                        </Link>
                        <LineClampText maxLines={2} className="mb-0 small text-muted">
                            {channel.chan_desc
                                ? channel.chan_desc
                                : 'No channel description'}
                        </LineClampText>
                    </div>
                </Col>
            </Card.Body>
        </Card>
    );
}
