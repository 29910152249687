import { HTMLAttributes, useEffect } from "react";
import { Image, ImageProps, Placeholder } from "react-bootstrap";
import butlerrPlaceholder from "../../assets/img_placeholder.jpg";
import useButlerrAPI from "../../services/useButlerrAPI";
import { Butlerr } from "../../types/butlerr";

/**
 * Routes for images to fetch, it should return an object of type `Butlerr.SignedURL`
 */
export const ImageRoutes = {
    DOCUMENT: (documentId: number) => `/api/documents/${documentId}/content`,
} as const;

interface ButlerrImageProps extends Omit<ImageProps, 'src'> {
    url?: string;
    placeholderImg?: string;
    loadingEle?: JSX.Element;
    enabled?: boolean;
    render?: (url: string, props: HTMLAttributes<HTMLElement>) => JSX.Element;
}

export default function ButlerrImage({
    url,
    placeholderImg = butlerrPlaceholder,
    loadingEle,
    enabled = true,
    render,
    ...props
} : ButlerrImageProps) {

    const isEnabled = enabled && !!url;

    const { data: image, isLoading, refetch } = useButlerrAPI<Butlerr.SignedURL>([ 'images', { url } ], url ?? "", true, {
        retry: false,
        enabled: isEnabled
    })

    useEffect(() => {
        if (isEnabled && image?.expiresIn) {
            const timeout = setTimeout(() => {
                refetch();
            }, image?.expiresIn)

            return () => {
                clearTimeout(timeout);
            }
        }

    }, [ isEnabled, image?.expiresIn, refetch ])
    
    const loadingSpinner = loadingEle ?? (
        <Placeholder className="h-100" as="div" animation="wave">
            <Placeholder xs={12} {...props} />
        </Placeholder>
    )

    if (!image?.url) {
        if (isLoading) return loadingSpinner;

        return (
            <Image
                src={placeholderImg}
                {...props}
            />
        )
    }

    if (render) {
        return render(image.url, props);
    }

    return (
        <Image
            src={image.url}
            {...props}
        />
    )
}