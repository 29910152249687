import DOMPurify from 'dompurify';
import { Form, Modal, Image, Col, Row, Container } from 'react-bootstrap';
import { BaseModalProps } from '../../../hooks/useModal';
import { convertImages } from './ArticleForm';
import socialStyles from '../../social/social.module.css';
import imgPlaceHolder from '../../../assets/img_placeholder.jpg'
import { useButlerrUser } from '../../../services/user.service';
import { Transition } from 'react-transition-group';
import { formatDate } from '../../utils/HelperFunctions';

interface AttachmentFile {
    url: string;
    file: File;
}

interface PreViewArticleProps {
    article: {
        wkpf_id: number;
        arti_title: string | null;
        arti_synopsis: string | null;
        arti_text: string;
        arti_keywords: string | null;
        arti_status: 0 | 1 | Boolean;
        arti_templateID: 0 | 1 | 2;
        arti_date?: string | null;
        arti_lastedited?: string | null;
    };
    file: AttachmentFile | undefined;
    arti_imagekey: string | null;
}

const PreviewArticleModal = ({ show, onClose, article, file, arti_imagekey }: PreViewArticleProps & BaseModalProps) => {
    const {data: dbUser} = useButlerrUser();
    return (
        <Transition in={show} timeout={{ exit: 300 }} unmountOnExit>
            <Form noValidate>
                <Modal show={show} onHide={() => onClose()} size="xl" centered scrollable>
                    <Modal.Header>
                        <Modal.Title>Preview</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='p-0'>
                    <Container style={{padding: '4rem'}}>
                    {article.arti_templateID === 2 ? ( // this is alr correct. this is image on left, title on right and text full stretch
                    <>
                        <Row className="mb-3">
                        <Col md={4}>
                        {file?.url ? (<div className="w-100 h-100 text-center">
                            <Image className={'shadow-none rounded border border-secondary ' + socialStyles.userAvatar}
                                style={{ width: '100%', maxWidth: '100%',aspectRatio: '153/100', objectFit: 'cover' }} src={file.url ?? ''}></Image>
                        </div>) : (
                            arti_imagekey) ? (
                                <Image src={arti_imagekey}
                                className={'shadow-none rounded border border-secondary ' + socialStyles.userAvatar}
                                style={{ width: '100%', maxWidth: '100%',aspectRatio: '153/100', objectFit: 'cover' }}
                            />
                            ) : (
                            <Image src={imgPlaceHolder}
                                className={'shadow-none rounded border border-secondary ' + socialStyles.userAvatar}
                                style={{ width: '100%', maxWidth: '100%',aspectRatio: '153/100', objectFit: 'cover' }}
                                title='Image placeholder'
                            />
                        )}
                        </Col>
                        <Col className="d-flex flex-column justify-content-end">
                        <h1 className="fw-bold">{article?.arti_title?.length === 0 && article?.arti_title !== null ? ('Untitled') : (article?.arti_title)}</h1>
                        <span className="fst-italic">{article?.arti_synopsis?.length === 0 ? ('No synopsis available') : (article?.arti_synopsis)}</span>
                        <small className="mt-2 fw-bold">{dbUser?.user_socialhandle}-{article.arti_lastedited
                                    ? 'Modified: ' +
                                    formatDate(article.arti_lastedited)
                                    : (article.arti_date ? (formatDate(article.arti_date)) : ('day/month/year'))}</small>
                        </Col>
                    </Row>
                    <Row className="mt-3" dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(convertImages(article?.arti_text ?? '')),
                                }}>

                    </Row>
                    </>
                    ) : (article.arti_templateID === 1 ? ( // image on the left and text on the right
                    <>
                        <Row className="d-flex mb-3">
                            <Col md={4}>
                                {file?.url ? (
                                    <div className="w-100 h-100 text-center">
                                        <Image className={'shadow-none rounded border border-secondary ' + socialStyles.userAvatar}
                                            style={{ width: '100%', maxWidth: '100%',aspectRatio: '153/100', objectFit: 'cover' }} src={file.url ?? ''}
                                        />
                                    </div>) : (
                                arti_imagekey) ? (
                                    <Image src={arti_imagekey}
                                        className={'shadow-none rounded border border-secondary ' + socialStyles.userAvatar}
                                        style={{ width: '100%', maxWidth: '100%',aspectRatio: '153/100', objectFit: 'cover' }}
                                    />
                                ) : (
                                    <Image src={imgPlaceHolder}
                                        className={'shadow-none rounded border border-secondary ' + socialStyles.userAvatar}
                                        style={{ width: '100%', maxWidth: '100%',aspectRatio: '153/100', objectFit: 'cover' }}
                                        title='Image placeholder'
                                    />
                                )}
                            </Col>
                            <Col className="d-flex flex-column justify-content-end">
                                <h1 className="fw-bold">{article?.arti_title?.length === 0 && article?.arti_title !== null ? ('Untitled') : (article?.arti_title)}</h1>
                                <span className="fst-italic">{article?.arti_synopsis?.length === 0 ? ('No synopsis available') : (article?.arti_synopsis)}</span>
                                <small className="mt-2 fw-bold">{dbUser?.user_socialhandle}-day/month/year</small>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col md={4} />
                            <Col>
                            <div
                                    dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(convertImages(article?.arti_text ?? '')),
                                }}>
                            </div>
                            </Col>
                        </Row>
                    </>
                    ): (
                        article.arti_templateID === 0 && ( //image in the middle
                        <>
                            <Row className="d-flex flex-column justify-content-center align-items-center">
                                <Col>
                                    {file?.url ? (
                                        <div className="w-100 h-100 text-center">
                                            <Image className={'shadow-none rounded border border-secondary w-100 h-100 ' + socialStyles.userAvatar}
                                                style={{ width: '100%', maxWidth: '100%',aspectRatio: '153/100', objectFit: 'cover' }} src={file.url ?? ''}
                                            />
                                        </div>) : (
                                    arti_imagekey) ? (
                                        <Image src={arti_imagekey}
                                            className={'shadow-none rounded border border-secondary w-100 h-100 ' + socialStyles.userAvatar}
                                            style={{ width: '100%', maxWidth: '100%',aspectRatio: '153/100', objectFit: 'cover' }}
                                        />
                                    ) : (
                                        <Image src={imgPlaceHolder}
                                            className={'shadow-none rounded border border-secondary w-100 h-100 ' + socialStyles.userAvatar}
                                            style={{ width: '100%', maxWidth: '100%',aspectRatio: '153/100', objectFit: 'cover' }}
                                            title='Image placeholder'
                                        />
                                    )}
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <h1 className="fw-bold text-center mt-3">{article?.arti_title?.length === 0 && article?.arti_title !== null ? ('Untitled') : (article?.arti_title)}</h1>
                                <span className="fst-italic text-center">{article?.arti_synopsis?.length === 0 ? ('No synopsis available') : (article?.arti_synopsis)}</span>
                                <small className="text-center fw-bold mt-3">{dbUser?.user_socialhandle}-day/month/year</small>
                                    
                            </Row>
                            <div className="mt-3"
                            dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(convertImages(article?.arti_text ?? '')),
                            }}>
                            </div>
                        </>
                        )
                    ))} 
                    </Container>
                    </Modal.Body>
                </Modal>
            </Form>
        </Transition>
        
    )
};

export default PreviewArticleModal;
