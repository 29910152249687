import useButlerrAPI, { ButlerrMutationOptions, useButlerrMutation } from "./useButlerrAPI";
import { Butlerr } from "../types/butlerr";

export const NotificationQueries = {
    LIST: ['notifications', 'list'] as const,
    STATS: ['notifications', 'stats'] as const
};

export function useNotifications() {
    return useButlerrAPI<Butlerr.Header.Notification[]>(NotificationQueries.LIST, `/api/notifications`, true, {
        refetchInterval: 60 * 1000
    });
}

export function useButlerrStats() {
    interface Stats {
        users: number;
        countries: number;
        companies: number;
        assets: number;
    }
    
    return useButlerrAPI<Stats>(NotificationQueries.STATS, `/api/notifications/stats`, true, {
        cacheTime: Infinity
    })
}

export const NotificationMutations = {
    READ: (noti_id: number) : ButlerrMutationOptions<Butlerr.Header.Notification[]> => ({
        url: `/api/notifications/${noti_id}/read`,
        method: "POST",
        updater: {
            optimistic: true,
            action: (prev) => prev.map(n => n.noti_id !== noti_id ? n : ({
                ...n,
                noti_read: 1
            }))
        }
    }),
    READ_ALL: () : ButlerrMutationOptions<Butlerr.Header.Notification[]> => ({
        url: `/api/notifications/read`,
        method: "POST",
        updater: {
            optimistic: true,
            action: (prev) => prev.map(n => ({
                ...n,
                noti_read: 1
            }))
        }
    }),
    UNREAD: (noti_id: number) : ButlerrMutationOptions<Butlerr.Header.Notification[]> => ({
        //same mutation for both read all & read individual
        url: `/api/notifications/${noti_id}/read`,
        method: "DELETE",
        updater: {
            optimistic: true,
            action: (prev) => prev.map(n => n.noti_id !== noti_id ? n : ({
                ...n,
                noti_read: 0
            }))
        }
    }),
    DELETE: (noti_id: number) : ButlerrMutationOptions<Butlerr.Header.Notification[]> => ({
        //same mutation for both read all & read individual
        url: `/api/notifications/${noti_id}`,
        method: "DELETE",
        updater: {
            optimistic: true,
            action: (prev) => prev.filter(n => n.noti_id !== noti_id)
        }
    }),
}

export function useNotificationMutations<Key extends keyof typeof NotificationMutations>(key: Key) {
    //You won't get type suggestions in the mutate function without this assertion
    const mutationFn = NotificationMutations[key] as (params: Parameters<typeof NotificationMutations[Key]>[0]) => ButlerrMutationOptions;
    return useButlerrMutation(mutationFn, NotificationQueries.LIST);
}