import React from 'react';
import { Row, Button } from 'react-bootstrap';
import { useProfileCompeletion } from './OnboardingModal';

const FinalSharingPage = () => {
    const { onClose } = useProfileCompeletion();

    return (
        <>
            <Row className="mb-3">
                <h1 className="text-muted">
                    Great! Let's get you <span className="text-primary">Sharing!</span>
                </h1>

                <p>
                    We will now redirect you to our Home module where you can{' '}
                    <b>start making your first post</b> to share your investing experience, or{' '}
                    <b>start creating your own channel</b> so that fellow investors can follow you.
                </p>
            </Row>

            <div>
                <Button onClick={onClose}>Proceed</Button>
            </div>
        </>
    );
};

export default FinalSharingPage;
