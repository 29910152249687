import { Formik, FormikErrors, FormikHelpers, FormikTouched } from 'formik';
import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import * as yup from 'yup';
import { useUserMutations } from '../../../services/user.service';
import BootstrapSpinner from '../../utils/BootstrapSpinner';
import { BootstrapInput, BootstrapInputProps } from '../../utils/FormikBootstrapInputs';

import { useProfileCompeletion } from './OnboardingModal';

interface InviteForm {
    user1: string;
    user2: string;
    user3: string;
    user4: string;
    user5: string;
    user6: string;
}

const InviteFriendsPage = () => {
    const { profileForm, goNextPage } = useProfileCompeletion();

    const handleNext = () => {
        let pageIndex = null;
        switch (profileForm.purpose) {
            case 'WORK':
                pageIndex = 8;
                break;
            case 'MANAGE':
                pageIndex = 9;
                break;
            case 'SOCIAL':
                pageIndex = 10;
                break;
            case 'SHARE':
                pageIndex = 11;
                break;
            default:
                pageIndex = 10;
        }
        goNextPage(pageIndex);
    };

    const { mutate: invite, isLoading, error } = useUserMutations('INVITE_USER');

    const initialValues = {
        user1: '',
        user2: '',
        user3: '',
        user4: '',
        user5: '',
        user6: '',
    };

    const validationSchema = yup.object().shape({
        user1: yup
            .string()
            .email('Invalid email')
            .max(100, ({ max }) => `Maximum ${max} characters`)
            .required('Required'),
        user2: yup
            .string()
            .email('Invalid email')
            .max(100, ({ max }) => `Maximum ${max} characters`),
        user3: yup
            .string()
            .email('Invalid email')
            .max(100, ({ max }) => `Maximum ${max} characters`),
        user4: yup
            .string()
            .email('Invalid email')
            .max(100, ({ max }) => `Maximum ${max} characters`),
        user5: yup
            .string()
            .email('Invalid email')
            .max(100, ({ max }) => `Maximum ${max} characters`),
        user6: yup
            .string()
            .email('Invalid email')
            .max(100, ({ max }) => `Maximum ${max} characters`),
    });

    const [sentEmails, setSentEmails] = useState<string[]>([]);

    const onSubmit = (values: InviteForm, helpers: FormikHelpers<InviteForm>) => {
        if (error !== null) error.message = '';
        const emails = Object.values(values);

        let inviteEmails = [];
        for (const email of emails) {
            if (email && !sentEmails.includes(email.trim())) {
                inviteEmails.push(email.trim());
            }
        }

        if (inviteEmails.length > 0) {
            invite(
                { inv_email: inviteEmails },
                {
                    onSuccess: ({ response }) => {
                        type Response = Array<{
                            email: string;
                            success: boolean;
                            error: string | null;
                        }>;
                        const data: Response = response.data;

                        const sentEmails: string[] = [];
                        const errors: FormikErrors<InviteForm> = {};

                        for (let i = 0; i < data.length; i++) {
                            const { error, success, email } = data[i];

                            if (success) {
                                sentEmails.push(email);
                            }
                            if (error !== null) {
                                const key = `user${i + 1}` as keyof InviteForm;
                                errors[key] = error;
                            }
                        }

                        // add new list of successful sent emails
                        setSentEmails((s) => [...s, ...sentEmails]);

                        // set errors
                        if (Object.keys(errors).length > 0) {
                            return helpers.setErrors(errors);
                        }

                        // if no errors, go next page
                        handleNext();
                    },
                }
            );
        }
    };

    return (
        <>
            <Row>
                <h1 className="text-muted">
                    Invite Your <span className="text-primary">Friends!</span>
                </h1>

                <p>
                    Feel free to invite friends and contacts to <b>propcircle.co</b>! <br />
                    We will send them a nice email invite and you will be notified when they sign
                    up!
                </p>
            </Row>

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({
                    values,
                    touched,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldError,
                }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <div className="position-relative">
                            <Row className="mb-3 g-3">
                                <FormInputColumn
                                    id="user1"
                                    label="Friend 1"
                                    isLoading={isLoading}
                                    values={values}
                                    touched={touched}
                                    errors={errors}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    setFieldError={setFieldError}
                                    sent={sentEmails.includes(values.user1)}
                                />
                                <FormInputColumn
                                    id="user2"
                                    label="Friend 2"
                                    isLoading={isLoading}
                                    values={values}
                                    touched={touched}
                                    errors={errors}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    setFieldError={setFieldError}
                                    sent={sentEmails.includes(values.user2)}
                                />
                            </Row>

                            <Row className="mb-3 g-3">
                                <FormInputColumn
                                    id="user3"
                                    label="Friend 3"
                                    isLoading={isLoading}
                                    values={values}
                                    touched={touched}
                                    errors={errors}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    setFieldError={setFieldError}
                                    sent={sentEmails.includes(values.user3)}
                                />
                                <FormInputColumn
                                    id="user4"
                                    label="Friend 4"
                                    isLoading={isLoading}
                                    values={values}
                                    touched={touched}
                                    errors={errors}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    setFieldError={setFieldError}
                                    sent={sentEmails.includes(values.user4)}
                                />
                            </Row>

                            <Row className="mb-3 g-3">
                                <FormInputColumn
                                    id="user5"
                                    label="Friend 5"
                                    isLoading={isLoading}
                                    values={values}
                                    touched={touched}
                                    errors={errors}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    setFieldError={setFieldError}
                                    sent={sentEmails.includes(values.user5)}
                                />
                                <FormInputColumn
                                    id="user6"
                                    label="Friend 6"
                                    isLoading={isLoading}
                                    values={values}
                                    touched={touched}
                                    errors={errors}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    setFieldError={setFieldError}
                                    sent={sentEmails.includes(values.user6)}
                                />
                            </Row>
                            {isLoading && (
                                <div
                                    className="opacity-75 position-absolute w-100 h-100 bg-white d-flex flex-column align-items-center justify-content-center"
                                    style={{ top: 0, left: 0 }}
                                >
                                    <BootstrapSpinner />
                                </div>
                            )}
                        </div>

                        <div className="mb-2">
                            <Button type="submit" className="me-2" disabled={isLoading}>
                                Send Invite
                            </Button>

                            <Button
                                variant="outline-primary"
                                onClick={handleNext}
                                disabled={isLoading}
                            >
                                {sentEmails.length > 0 ? 'Next' : 'Skip for now'}
                            </Button>
                        </div>

                        <p className="mb-0 w-100 text-danger">
                            {error !== null ? error.message : ''}
                        </p>
                    </Form>
                )}
            </Formik>
        </>
    );
};

interface FormInputColumnProps extends BootstrapInputProps {
    id: string;
    isLoading: boolean;
    values: InviteForm;
    touched: FormikTouched<InviteForm>;
    errors: FormikErrors<InviteForm>;
    handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    handleBlur: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    setFieldError: (field: string, message: string | undefined) => void;

    sent: boolean;
}

const FormInputColumn = ({
    isLoading,
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    setFieldError,
    sent,
    ...props
}: FormInputColumnProps) => {
    const key = props.id as keyof InviteForm;
    // const email = values[key];

    const { mutate: validateInviteEmail, isLoading: isValidating } =
        useUserMutations('INVITE_USER_VALIDATE');

    const [valid, setValid] = useState(false);

    return (
        <Form.Group as={Col} lg>
            <BootstrapInput
                placeholder="abc@def.com"
                disabled={isLoading || isValidating || sent}
                required={false}
                isValid={touched[key] && !errors[key] && valid && sent}
                onChange={(e) => {
                    // default change handler from Formik
                    handleChange(e);

                    // set valid to false if no value
                    if (!e.target.value) {
                        setValid(false);
                    }
                }}
                onBlur={(e) => {
                    const email = e.target.value;

                    if (!email) return;

                    // default blur handler from Formik
                    handleBlur(e);

                    const validEmail = validateEmail(email);

                    if (email && validEmail) {
                        validateInviteEmail(
                            { inv_email: [email] },
                            {
                                onSuccess: () => {
                                    setValid(true);
                                },
                                onError: (error) => {
                                    setFieldError(key, error.message);
                                },
                            }
                        );
                    }
                }}
                validText={sent ? 'Email sent!' : undefined}
                {...props}
            />
        </Form.Group>
    );
};

const validateEmail = (email: string) => {
    return email
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export default InviteFriendsPage;
