import { useEffect, useRef, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { convertToRaw, EditorState, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './articlestyles.css';
import { Alert, Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import {
    articleQueries,
    useArticleById,
    useArticleMutations,
} from '../../../services/partnerArticle.service';
import BootstrapSpinner from '../../utils/BootstrapSpinner';
import IconButton from '../../utils/IconButton';
import { Butlerr } from '../../../types/butlerr';
import { Formik } from 'formik';
import * as yup from 'yup';
import { BootstrapInput } from '../../utils/FormikBootstrapInputs';
import { Cancel, PhotoRounded, PreviewRounded, PublishRounded, SaveRounded } from '@mui/icons-material';
import { getFileWithUrl } from '../../assets/conversations/AssetConversationsContent';
import { extractFileSize, getStringLengthWNewLine } from '../../utils/HelperFunctions'; //getStringLengthWNewLine
import { Image } from 'react-bootstrap';
import socialStyles from '../../social/social.module.css';
import RouterPrompt from '../../utils/RouterPrompt';
import { useHistory, useParams } from 'react-router-dom';
import { useWorkProfiles } from '../../../services/partner.service';
import EmptyMessage from '../../utils/EmptyMessage';
import PreviewArticleModal from './PreviewArticleModal';
import imgPlaceHolder from '../../../assets/img_placeholder.jpg'
import htmlToDraft from 'html-to-draftjs';
import {useQueryClient} from 'react-query'
import { SvgIconComponent } from "@mui/icons-material"
import classNames from "classnames"
import React from "react"
import { CSSProperties } from "react"
import { ButtonProps } from "react-bootstrap"
import styles from "../../utils/utils.module.css"
import ErrorPage from '../../utils/ErrorPage';
import { useButlerrUser } from '../../../services/user.service';

interface BaseButtonProps extends ButtonProps {
    transparent?: boolean,
    iconHtmlColor?: string,
    iconStyles?: CSSProperties,
    border?: boolean;
}
interface ButtonWithIconProps extends BaseButtonProps {
    Icon: SvgIconComponent | React.FC<React.SVGProps<SVGSVGElement>>;
    label?: string;
}
interface ButtonWithLabelProps extends BaseButtonProps {
    Icon?: SvgIconComponent | React.FC<React.SVGProps<SVGSVGElement>>;
    label: string;
}
export type IconButtonProps = ButtonWithIconProps | ButtonWithLabelProps

export const CustomIconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(({ className, Icon, style, transparent, iconHtmlColor, iconStyles, label, border, ...props }, ref) => {
    return (
        <Button
            variant='clear'
            className={classNames(styles.icon, className, transparent && styles.transparentIcon, (border ?? transparent) && styles.border)}
            style={{
                color: iconHtmlColor,
                borderRadius: label !== undefined ? undefined : '6px',
                width: label !== undefined ? undefined : '1.6rem',
                height: label !== undefined ? undefined : '1.6rem',
                ...style,
            }}
            ref={ref}
            {...props}
        >
            {
                Icon !== undefined && (
                    <Icon
                        style={{
                            fontSize: '1.25rem',
                            color: iconHtmlColor,
                            ...iconStyles
                        }}
                        className={label ? 'me-2' : undefined}
                    />
                )
            }
            {
                label !== undefined && <span>{label}</span>
            }
        </Button>
    )
});
interface FormValues {
    wkpf_id: number;
    arti_title: string;
    arti_synopsis: string | null;
    arti_text: string;
    arti_keywords: string | null;
    arti_status: Boolean;
    arti_templateID: 0 | 1 | 2;
}

export const convertImages = (htmlText: string) => {
    const regex = /<div.*><img\s[^>]*?style\s*=\s*['"]height([^'"]*?)['"][^>]*?><\/div>/g;
    const regex2 = /<img\s[^>]*?style\s*=\s*['"]height([^'"]*?)['"][^>]*?>/g;
    const regex3 = /<div.*><div.*><img\s[^>]*?style\s*=\s*['"]height([^'"]*?)['"][^>]*?><\/div><\/div>/g;
    let imgElement;
    do {
        imgElement = regex2.exec(htmlText);
        let divElement = regex.exec(htmlText);
        let divDivElement = regex3.exec(htmlText);

        if (divDivElement && divDivElement[0] !== undefined) { 
            // removing 1 of the divs; editor adds a div in when aligning, 
            //but i put a div for imgs without div. ending up with 2 divs when editors does aligning
            let innerImgElement = regex2.exec(divDivElement[0])
            if (innerImgElement !== null) {
                htmlText.replaceAll(divDivElement[0], innerImgElement[0] + '</div>')
            }
        }
        htmlText = htmlText.replaceAll('text-align:none', 'text-align:center')
        if (imgElement !== null && imgElement[0] !== undefined && !(divElement?.[0]?.includes(imgElement[0]))) {
            htmlText = htmlText.replaceAll(imgElement[0], "<div style='text-align:center'>" + imgElement[0] + "</div>")
        }

    } while (imgElement) //go through all of the img elements
   return htmlText;
}

export const CreateEditArticleForm = () => {
    
    interface AttachmentFile {
        url: string;
        file: File;
    }
    
    const { articleId } = useParams<{ articleId: string | undefined }>();
    
    const { data: article, isLoading, error } = useArticleById(parseInt(articleId ?? '0'), articleId !== undefined);

    const { templateId } = useParams<{ templateId: string }>();
    const template = Number(templateId) as 0 | 1 | 2;
    const {data: workProfile} = useWorkProfiles();
    const {
        mutate: createArticle,
        isLoading: isCreateLoading,
    } = useArticleMutations('CREATE_ARTICLE');

    const {
        mutate: editArticle,
        isLoading: isEditLoading,
    } = useArticleMutations('EDIT_ARTICLE');

    const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());

    useEffect(() => {
        if (article?.arti_text) {
            const state = EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(article?.arti_text ?? '').contentBlocks));
            setEditorState(state);
        }
    }, [ article?.arti_text ])

    const [file, setFile] = useState<AttachmentFile>();
    const inputRef = useRef<HTMLInputElement>(null);
    // const editorReference = useRef<Editor>(null);
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    // const [editorRef, setEditorRef] = useState<Editor>();
    const history = useHistory();
    const queryClient = useQueryClient();
    const {data: dbUser} = useButlerrUser();
    const [isFocus, setIsFocus] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    // const setEditorReference = (ref: Editor) => {
    //     console.log(ref)
    //     // ref.context.onEditorStateChange((e: any) => {console.log(e)})
    //     if (ref !== null)
    //         console.log(ref)
    //         if (ref !== null && ref !== undefined) {
    //             setEditorRef(ref)
    //             console.log(ref.context)
    //         }
    // }

    // const handleEditorChange = (newState: EditorState) => {
    //     setEditorState(newState);
    //     if (editorRef === undefined || editorRef.current === undefined || editorRef.current?.props === undefined || editorRef.current.props.onEditorStateChange === undefined) return
    //     console.log(editorRef.current?.props.onEditorStateChange(newState))
    // }

    if (!workProfile) {
        return <EmptyMessage message="A work profile is required to create/edit an article" />
    }
    
    const initialiseInitialValues = (article?: Butlerr.Article) => {
        return {
            arti_title: article?.arti_title ?? '',
            arti_synopsis: article?.arti_synopsis ?? '',
            arti_text: article?.arti_text ?? '',
            arti_keywords: article?.arti_keywords ?? '',
            arti_status: (article?.arti_status !== undefined && article?.arti_status !== null) ? article?.arti_status === 1 ? true : false : false,
            wkpf_id: workProfile?.[0].wkpf_id,
            arti_templateID: template ?? 0,
        } as FormValues;
    };
    
    let initialValues: FormValues;

    if (article) {
        initialValues = initialiseInitialValues(article)
    }
    else {initialValues = initialiseInitialValues()}
    
    const onSubmit = (values: FormValues) => {
        let fieldUpdated: boolean = false;
        Object.keys(initialValues).forEach((key) => {
            let value = values[key as keyof typeof values];
            if (initialValues[key as keyof typeof initialValues] !== value) {
                fieldUpdated = true;
            }
            if (file?.file) {
                fieldUpdated = true;
            }
        });
        if (values.arti_text.length !== 7 && (article === undefined || article === null || article.arti_text === undefined || article.arti_text === null)) {
            createArticle(
                {
                arti_keywords: values.arti_keywords,
                arti_imageKey: file?.file,
                arti_synopsis: values.arti_synopsis,
                arti_text: values.arti_text,
                arti_title: values.arti_title,
                wkpf_id: values.wkpf_id,
                arti_templateID: template,
                arti_status: Number(values.arti_status) as 0 | 1,
                },
                {
                    onError: (error) => {
                        alert(error.message);
                    },
                    onSuccess: () => {
                        // reset the editor state
                        setEditorState(EditorState.createEmpty());
                        history.push('/work/articles')
                    }
                });
        } else if (fieldUpdated && values.arti_text.length !== 7 && article !== undefined && articleId !== undefined) {
            editArticle({
                arti_keywords: values.arti_keywords,
                arti_imageKey: file?.file,
                arti_synopsis: values.arti_synopsis,
                arti_text: values.arti_text,
                arti_title: values.arti_title,
                arti_templateID: template,
                arti_status: Number(values.arti_status) as 0 | 1,
                arti_id: parseInt(articleId)
            },
            {
                onError: (error: { message: any; }) => {
                    alert(error.message);
                },
                onSuccess: () => {
                    history.push('/work/articles')
                    queryClient.invalidateQueries(articleQueries.ARTICLES);
                }
            })
        }
    };

    const validationSchema = yup.object({
        //wkpf_id: yup.number().required(),
        arti_title: yup.string().required('Title is required').max(120, 'Maximum 120 characters').default('').nullable(),
        arti_synopsis: yup
            .string()
            .max(512, ({ max }) => `maximum ${max} characters`)
            .default('')
            .nullable(),
        arti_text: yup.string().required('Content is required').min(9,'Content is required').max(65535, 'Maximum number of characters reached.').default('').nullable(),
        arti_keywords: yup.string().max(120, 'Maximum 120 characters').default('').nullable(),
        arti_status: yup.boolean().default(false).required('status is required'),
    })

    if (!(dbUser?.user_id === article?.user_id) && article !== undefined) return <EmptyMessage message="Unauthorized access." /> //if the (author's id is not the current user's id) and article is not undefined
    if (error) return <ErrorPage status={error?.status} message={error?.message} />
    if (article?.arti_status === 1) return <EmptyMessage message="Unable to edit selected article as it is currently published" />
    if (workProfile === undefined || workProfile.length === 0) return <EmptyMessage message={"Unable to create blog due to no work profiles"} />
    if (isCreateLoading || isEditLoading || isLoading) return <BootstrapSpinner />;
    if ((article === undefined || article?.arti_text === undefined) && articleId !== undefined) {
        return <BootstrapSpinner />
    }
    return (
        <Card>
            <Container className='p-4'>
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({ handleSubmit, setFieldValue, dirty, errors, values }) => {
                const setFieldValueText = () => {
                    setFieldValue('arti_text', draftToHtml(convertToRaw(editorState.getCurrentContent())));
                }
                return (
                    <>
                <Form className="p-0" noValidate onSubmit={handleSubmit}>
                    <RouterPrompt isBlocking={dirty} />
                    <Row className='mb-5'>
                        <Col md={4}>
                        <input hidden ref={inputRef} type="file" accept="image/*"
                        onChange={async (e) => {
                            if (e.target.files?.[0]) { await getFileWithUrl(e.target.files[0]).then(setFile);}
                            else { setFile(undefined); }// reset value to allow reading the same file
                            if (inputRef.current) { inputRef.current.value = ''; }
                        }}>
                    </input>
                    <div>
                    {file !== undefined ? (
                        <Image src={file.url} title={file.file.name + ' ' + extractFileSize(file.file.size)}
                            className={'shadow-none rounded border border-secondary ' + socialStyles.userAvatar}
                            style={{ maxWidth: '100%',aspectRatio: '153/100', objectFit: 'cover' }}
                        />
                    ) : (article?.arti_imagekey) ? (
                        <Image src={article.arti_imagekey}
                            className={'shadow-none rounded border border-secondary ' + socialStyles.userAvatar}
                            style={{ maxWidth: '100%',aspectRatio: '153/100', objectFit: 'cover' }}
                        />
                    ) : (
                        <Image src={imgPlaceHolder}
                            className={'shadow-none rounded border border-secondary ' + socialStyles.userAvatar}
                            style={{ width: '100%', maxWidth: '100%',aspectRatio: '153/100', objectFit: 'cover' }}
                            title='Image placeholder'
                        />
                    )}
                        <IconButton
                            transparent
                            Icon={PhotoRounded}
                            label="Upload"
                            title="Add photo"
                            onClick={() => inputRef.current?.click()}
                            style={{
                                width: '100%',
                                maxWidth: '100%',
                            }}
                            className=" text-dark mt-3"
                        />
                    </div>
                        </Col>
                        <Col md={8}>
                            <Form.Group className='mb-3'>
                                <BootstrapInput label='Title' id='arti_title' placeholder='Title your article...' />
                            </Form.Group>
                            <Form.Group>
                            <BootstrapInput
                                id="arti_synopsis"
                                type="text"
                                as="textarea"
                                rows="3"
                                required={false}
                                value={values.arti_synopsis ?? ''}
                                placeholder="Summarise your article..."
                                label="Synopsis"
                            />
                            </Form.Group>
                            <p className='mb-0 mt-1 text-end text-secondary small'>{getStringLengthWNewLine(values.arti_synopsis ?? '')}/512 characters</p>
                            <Form.Group className='my-3'>
                                <BootstrapInput label='Keywords' required={false} id='arti_keywords' placeholder='Keywords for your article such as vacancy, capital growth, house price' />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group>
                        {/* for lighter weight, remove editorstate & onchangeeditorstate and use editorref instead. BUT i dk how the fuck to do it. i spent 1 whole day trying and it was all in vain */}
                        <Editor
                            onFocus={() => {setFieldValueText(); setIsFocus(true); setIsClicked(true)}}
                            onBlur={() => {setFieldValueText(); setIsFocus(false)}}
                            // editorRef={ref => setEditorReference(ref as Editor)}
                            placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam tristique aliquet nibh vitae posuere. Quisque magna arcu, pulvinar eu tempus eget, posuere id ex. In hac habitasse platea dictumst. Cras non arcu dictum risus laoreet iaculis. Integer posuere odio id pharetra semper. Vivamus mauris est, pulvinar at quam sit amet, malesuada dictum ex. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Sed aliquet orci nulla, vel volutpat tellus pharetra at. Nullam elementum, justo sit amet euismod vestibulum, elit orci commodo odio, posuere rhoncus erat nulla tincidunt dolor. Phasellus in nibh ac quam blandit consequat. Nunc feugiat id nisl at fermentum."
                            wrapperClassName="card"
                            toolbarClassName='rounded border-0 border-bottom'
                            editorClassName="card-body pt-0"
                            editorStyle={{paddingLeft: '0.75rem', paddingRight: '0.75rem', minHeight: '25vh', maxHeight: '70vh'}}
                            editorState={editorState}
                            onEditorStateChange={(e) => setEditorState(e)}
                            toolbar={
                                {options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'image', 'history']}}
                        />
                        {(errors.arti_text && isFocus === false && isClicked === true) && (
                            <span className="invalid-feedback d-block">{errors.arti_text}</span>
                        )}
                    </Form.Group>
                    {/* THIS IS VERY VERY HEAVY DO NOT USE UNLESS DATA STORED IS REDUCED TO AT LEAST 1/5 */}
                    {/* {(editorState !== EditorState.createEmpty()) && (
                        <span className='text-end text-secondary small'>{(( (draftToHtml(convertToRaw(editorState.getCurrentContent()))).length /65535) * 100).toFixed(2)}% memory used</span>
                    )} */}

                    <div className='mt-3'>
                        <CustomIconButton className='d-inline-block' variant='danger' color='danger' style={{backgroundColor: 'red'}}
                            onClick={() => {
                                    history.goBack()
                                }}
                                label="Cancel"
                                Icon={Cancel}
                                />
                            <div className='d-inline-block float-end'>
                                <div className='d-flex flex-row'>
                                    <IconButton label='Save draft' Icon={SaveRounded} className="ms-3" type='submit' onClick={() => {setFieldValue('arti_status', 0); setFieldValueText()}} style={{maxHeight: "35px"}} />
                                    <IconButton label='Preview' Icon={PreviewRounded} className='ms-3' type='button' onClick={() => {setFieldValueText(); setShowPreviewModal(true)}} />
                                    <IconButton label='Publish' Icon={PublishRounded} className="ms-3" type='submit' onClick={() => {setFieldValue('arti_status', 1); setFieldValueText()}} style={{maxHeight: "35px"}} />
                                </div>
                            </div>
                        </div>
                </Form>
                <PreviewArticleModal
                    show={showPreviewModal}
                    onClose={() => {setShowPreviewModal(false)}}
                    article={values}
                    file={file}
                    arti_imagekey={article?.arti_imagekey ?? ''}
                />
                <Alert className='mt-3 mb-0' variant='info' show={showAlert} onClick={() => {setShowAlert(false)}} onClose={() => {setShowAlert(false)}}>Users can now search and view your article (click anywhere to remove this message)</Alert>
                </>
            )}}
            </Formik>
            </Container>
        </Card>
    );
};
