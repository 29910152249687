import { useEffect, useMemo } from "react";
import { FetchChannelOptions, useSocialChannels } from "../../../services/social-channels.service";
import ChannelItem from "./Channel";
import useQuery from "../../../hooks/useQuery";
import { useHistory } from "react-router";
import ToggleButtonGroup, { ToggleItem } from "../../ui/ToggleButtonGroup";
import { Dropdown } from "react-bootstrap";
import IconButton from "../../utils/IconButton";
import classNames from "classnames";
import { GroupsOutlined, CheckOutlined, TimelineRounded, TimelapseRounded, StarRounded, PublicRounded, Timelapse } from "@mui/icons-material";
import EmptyMessage from "../../utils/EmptyMessage";
import { Link } from "react-router-dom";
import { PartnerRoutes } from "../../partners/Work";
import { SocialRoutes } from "../SocialHome";
import InfiniteScroller from "../../ui/InfiniteScroller"

export default function ChannelList({ emptyMessage } : { emptyMessage?: string }) {

    const search = useQuery();

    const params = useMemo(() => {
        const params : FetchChannelOptions = {};

        //Note: `t` search key is used in SocialSearch component to determine which tab the search is in.
        const query = decodeURIComponent(search.get("q") || "");

        if (query) params.query = query;

        const sortKey = SORT_MAP[decodeURIComponent(search.get("s") || "") as SortKeys] ?? (query ? "top_followers" : "trending"); //default to `top_followers` if searching, `trending` else
        params.sort = sortKey;

        const filterKey = FILTER_MAP[decodeURIComponent(search.get("f") || "") as FilterKeys];
        //if null, no filter (all posts)
        if (filterKey !== null) {
            //set matching filter key to true;
            //if undefined, default to show `following_only` but only if not searching
            if (filterKey !== undefined || !query) {
                params[filterKey ?? 'following_only'] = true;
            }
        }

        return params;
    }, [search])

    const { data: _channels, isLoading, hasNextPage, fetchNextPage, refetch } = useSocialChannels(params);

    //Special case: refetch when navigating to following_only page
    useEffect(() => {
        if (params.following_only) {
            refetch();
        }
    }, [params.following_only, refetch])

    const channels = _channels?.pages.flatMap(p => p.result);

    let content: JSX.Element;

    if (channels === undefined) {
        content = <ChannelSkeleton />
    }
    else if (channels.length === 0) {
        if (params.editable_only) {
            content = (
                <>
                    <EmptyMessage
                        className="fs-5"
                        message={emptyMessage ?? "You're not a part of any channels yet"}
                    />
                    <div className="mt-3 d-flex flex-column align-items-center justify-content-center">

                        <Link to={PartnerRoutes.WORK_PROFILE}>
                            Create a work profile and start your own channel now
                        </Link>
                    </div>
                </>
            )
        }
        else {
            content = (
                <EmptyMessage
                    className="fs-5"
                    message={emptyMessage ?? (
                        !params.following_only ? "Nothing to see here" : (
                            <span>
                                Start following some channels&nbsp;
                                <Link to={SocialRoutes.CHANNELS + "?f=a"}>
                                    here
                                </Link>
                            </span>
                        )
                    )}
                />
            )
        }
    }
    else {
        content = (
            <InfiniteScroller
                hasNextPage={hasNextPage}
                fetchNextPage={fetchNextPage}
                isLoading={isLoading}
                loadingEle={<ChannelSkeleton />}
            >
                {
                    channels.map((ch) => (
                        <ChannelItem key={ch.chan_id} channel={ch} className="mb-2" allowFollowerModal={true} />
                    ))
                }
            </InfiniteScroller>
        )
    }

    return (
        <div>
            <ChannelHeader />
            { content }
        </div>
    )
}

type KeysWithType<T, V> = {[K in keyof T]-?: T[K] extends V ? K : never}[keyof T];
//Maps for item keys in URL search
type SortKeys = 't' | 'tf' | 'tc';
const SORT_MAP : Record<SortKeys, FetchChannelOptions['sort']> = {
    t: "trending",
    tf: "top_followers",
    tc: "top_contributors"
} as const;

type FilterKeys = 'f' | 'a' | 'l';
/**
 * If undefined, we select `following_only` by default.
 * If null, we select public posts (no filter).
 */
const FILTER_MAP : Record<FilterKeys, KeysWithType<Required<FetchChannelOptions>, boolean> | null> = {
    f: "following_only",
    l: "latest_only",
    a: null
} as const;

function ChannelHeader() {

    const search = useQuery();
    const history = useHistory();

    const handleFilterChange = (filter: FilterKeys) => {
        search.set('f', filter);
        history.replace({
            search: search.toString()
        })
    }
    const handleSortChange = (sort: SortKeys) => {
        search.set('s', sort);
        history.replace({
            search: search.toString()
        })
    }

    type Item = Omit<ToggleItem, 'active' | 'render'>

    let activeFilter = decodeURIComponent(search.get('f') || "") as FilterKeys;
    if (!(activeFilter in FILTER_MAP)) activeFilter = 'f';

    const filterItems : Record<FilterKeys, Item> = {
        f: {
            Icon: GroupsOutlined,
            label: "Following"
        },
        l: {
            Icon: Timelapse,
            label: "Latest"
        },
        a: {
            Icon: PublicRounded,
            label: "All channels"
        }
    }

    let activeSort = decodeURIComponent(search.get('s') || "") as SortKeys;
    if (!(activeSort in SORT_MAP)) activeSort = 't';

    const sortItems : Record<SortKeys, Item> = {
        t: {
            Icon: TimelineRounded,
            label: "Trending recently"
        },
        tc: {
            Icon: TimelapseRounded,
            label: "Active recently"
        },
        tf: {
            Icon: StarRounded,
            label: "All-time popular"
        }
    };

    return (
        <div className="mb-3 d-flex justify-content-between flex-wrap">
            <ToggleButtonGroup
                items={Object.entries(filterItems).map(([ key, item ]) => ({
                    ...item,
                    key: key as FilterKeys,
                    active: key === activeFilter
                }))}
                onSelect={(item) => {
                    handleFilterChange(item.key)
                }}
            />
            <div className="d-flex ms-auto">
                <Dropdown align="end">
                    <Dropdown.Toggle
                        as="span"
                        bsPrefix=" "
                    >
                        <IconButton
                            transparent
                            Icon={ sortItems[activeSort].Icon }
                            title="Sort"
                            label=""
                            className="rounded-circle p-2 bg-light text-primary"
                            iconHtmlColor="var(--primary)"
                            iconStyles={{ fontSize: '1.5rem' }}
                        />
                    </Dropdown.Toggle>
                    
                    <Dropdown.Menu>
                        {
                            Object.entries(sortItems).map(([ key, { Icon, label } ]) => {
                                const isActive = key === activeSort;

                                return (
                                    <Dropdown.Item
                                        key={key}
                                        disabled={isActive}
                                        className={classNames("p-2 d-flex align-items-center", isActive && "text-primary")}
                                        onClick={() => handleSortChange(key as SortKeys)}
                                    >
                                        <Icon fontSize="small" className="me-2" />
                                        {label}

                                        { isActive && (
                                            <CheckOutlined fontSize="small" className="text-primary ms-auto" />
                                        ) }
                                    </Dropdown.Item>
                                )
                            })
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    )
}

export function ChannelSkeleton() {
    return (
        <>
            {Array<null>(3).fill(null).map((_, idx) => (
                <div key={idx} className="shadow-sm rounded-4 mb-2 d-flex justify-content-between align-items-center bg-light p-3">
                    <div
                        className="rounded-circle skeleton-box me-2"
                        style={{ width: 50, height: 50 }}
                    ></div>
                    <div className="flex-grow-1 align-self-stretch py-1 d-flex flex-column justify-content-between">
                        <div
                            className="skeleton-box"
                            style={{ width: '60%' }}
                        ></div>
                        <div
                            className="skeleton-box"
                            style={{ width: '30%', height: 14 }}
                        ></div>
                    </div>
                </div>
            ))}
        </>
    )
}