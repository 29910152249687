import { useCallback, useEffect, useMemo, useState } from 'react';
import {
    Add,
    Close,
    DeleteOutline,
    Done,
    DoNotDisturb,
    EditOutlined,
    NavigateNext,
    Save,
} from '@mui/icons-material';
import {
    Alert,
    Col,
    Container,
    Form,
    InputGroup,
    Modal,
    OverlayTrigger,
    Row,
    Tooltip,
} from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Transition } from 'react-transition-group';
import classNames from 'classnames';

import { Butlerr } from '../../../types/butlerr';
import {
    convertDateToYYYYMMDD,
    formatDate,
    formCloseHandler,
    removeNumberFormatting,
} from '../../utils/HelperFunctions';
import IconButton from '../../utils/IconButton';
import {
    Currency,
    ButlerrLoanPayPeriod,
    ButlerrLoanType,
    LoanTypeKey,
    LoanPayPeriodKey,
} from '../../../types/butlerr-enums';
import {
    BootstrapFormLabel,
    BootstrapInput,
    BootstrapSelect,
} from '../../utils/FormikBootstrapInputs';
import {
    useLoanAdjustmentMutations,
    useLoanMutations,
    useLoanAdjustments,
    useLoanDocuments,
} from '../../../services/assetloan.service';
import BootstrapTable from '../../utils/BootstrapTable';
import DestructiveModal from '../../utils/DestructiveModal';
import LineClampText from '../../utils/LineClampText';
import { TableBodySkeleton } from '../../utils/TableSkeleton';
import useModal from '../../../hooks/useModal';
import { useDownloadWithPostToken } from '../../../services/useButlerrAPI';
import { DocumentSelectModal } from '../../documents/DocsModal';
import { AssetDocsTable } from '../../documents/AssetDocLink';
import BootstrapModalFooter from '../../utils/BootstrapModalFooter';

interface LoanForm {
    loan_desc: string | null;
    loan_principal: string;
    loan_start: string;
    loan_tenure: number;
    loan_lender: string | null;
    loan_currency: string;
    loan_type: LoanTypeKey | '';
    loan_interestrate: number;
    loan_offset: number | null;
    loan_payperiod: LoanPayPeriodKey | '';
    loanPercentage: string;
}

interface AssetLoansModalProps {
    show: boolean;
    onModalClose: () => void;
    initialValue?: Butlerr.Asset.Loan;
    assetId: number;
    asset: Butlerr.Asset;
}

const AssetLoansModal = ({
    show,
    onModalClose,
    initialValue,
    assetId,
    asset,
}: AssetLoansModalProps) => {
    const {
        mutate: createLoan,
        isLoading: isCreateLoading,
        error: createError,
    } = useLoanMutations('CREATE');
    const {
        mutate: editLoan,
        isLoading: isEditLoading,
        error: editError,
    } = useLoanMutations('EDIT');

    const { mutateAsync: deleteLoan } = useLoanMutations('DELETE');
    const { mutateAsync: terminateLoan } = useLoanMutations('TERMINATE');

    // delete modal
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showTerminateModal, setTerminateDeleteModal] = useState(false);
    const [selectedLoanId, setSelectedLoanId] = useState<number>();

    const onDelete = (loanId: number) => {
        setSelectedLoanId(loanId);
        setShowDeleteModal(true);
    };

    const onDeleteClose = () => {
        setSelectedLoanId(undefined);
        setShowDeleteModal(false);
    };

    const onDeleteConfirm = async () => {
        await deleteLoan({ assetId, loanId: selectedLoanId ?? -1 });
        formCloseHandler(handleClose);
    };

    const onTerminate = (loanId: number) => {
        if (loanId > 0) {
            setSelectedLoanId(loanId);
            setTerminateDeleteModal(true);
        }
    };

    const onTerminateClose = () => {
        setSelectedLoanId(undefined);
        setTerminateDeleteModal(false);
    };

    const onTerminateConfirm = async () => {
        await terminateLoan({ assetId, loanId: selectedLoanId ?? -1 });
        formCloseHandler(handleClose);
    };

    const [showDocModal, setShowDocModal] = useState(false);
    const [docModalValues, setDocModalValues] = useState<Pick<Butlerr.Asset.Loan, 'loan_id'>>();

    const isLoading = initialValue === undefined ? isCreateLoading : isEditLoading;
    const error = initialValue === undefined ? createError : editError;

    // loan principal amount mode toggle
    type PrincipalAmtModes = '$' | '%';
    const [principalAmtMode, setPrincipalAmtMode] = useState<PrincipalAmtModes>('$');

    // modal
    const handleClose = () => {
        if (error !== null) error.message = '';
        if (!isLoading) {
            setPrincipalAmtMode('$');
            onModalClose();
        }
    };

    // formik stuff
    const allowedKeys = {
        loan_currency: Object.keys(Currency),
        loan_type: Object.keys(ButlerrLoanType),
        loan_payperiod: Object.keys(ButlerrLoanPayPeriod),
    };
    const allowedLabels = {
        loan_currency: Object.values(Currency),
        loan_type: Object.values(ButlerrLoanType),
        loan_payperiod: Object.values(ButlerrLoanPayPeriod),
    };

    const initialValues: LoanForm = {
        loan_desc: initialValue?.loan_desc ?? '',
        loan_principal:
            initialValue?.loan_principal !== undefined
                ? Number(initialValue?.loan_principal ?? 0).toLocaleString()
                : '',
        loan_start: initialValue?.loan_start
            ? convertDateToYYYYMMDD(initialValue?.loan_start)!
            : '',
        loan_tenure: initialValue?.loan_tenure ?? 0,
        loan_lender: initialValue?.loan_lender ?? '',
        loan_currency: initialValue?.loan_currency ?? asset.asst_currency,
        loan_type: initialValue?.loan_type ?? '',
        loan_interestrate: initialValue?.loan_interestrate ?? 0,
        loan_offset: initialValue?.loan_offset ?? 0,
        loan_payperiod: initialValue?.loan_payperiod ?? '',
        loanPercentage:
            initialValue?.loan_principal !== undefined
                ? String(
                      convertLoanAmtToPercentage(Number(initialValue?.loan_principal ?? 0), asset)
                  )
                : '',
    };

    const validationSchema = yup.object({
        loan_desc: yup
            .string()
            .max(60, ({ max }) => `Maximum ${max} characters`)
            .nullable(),
        loan_principal: yup.string().required('Required'),
        loan_start: yup.date().required('Required'),
        loan_tenure: yup
            .number()
            .integer()
            .min(1, ({ min }) => `Minimum ${min}`)
            .required('Required'),
        loan_lender: yup
            .string()
            .max(60, ({ max }) => `Maximum ${max} characters`)
            .nullable(),
        loan_currency: yup.string().oneOf(allowedKeys.loan_currency).required('Required'),
        loan_type: yup.string().oneOf(allowedKeys.loan_type).required('Required'),
        loan_interestrate: yup
            .number()
            .max(100, `Interest rate should not be more than 100%.`)
            .required('Required'),
        loan_offset: yup.number().nullable(),
        loan_payperiod: yup.string().oneOf(allowedKeys.loan_payperiod).required('Required'),
        loanPercentage: yup.string(),
    });

    const onDocSubmit = () => {
        formCloseHandler(handleClose);
        setShowDocModal(false);
    };

    const onSubmit = (values: LoanForm) => {
        const { loanPercentage, loan_principal, loan_type, loan_payperiod, ...data } = values;

        const [principalAmount] = removeNumberFormatting(String(loan_principal));

        if (initialValue === undefined) {
            const form = {
                loan_principal: principalAmount,
                loan_outstanding: principalAmount,
                loan_type: loan_type as keyof typeof ButlerrLoanType,
                loan_payperiod: loan_payperiod as keyof typeof ButlerrLoanPayPeriod,
                ...data,
            };

            createLoan(
                { asst_id: assetId, ...form },
                {
                    onSuccess: ({ response }) => {
                        setDocModalValues({ ...form, loan_id: response.data.loan_id });
                        setShowDocModal(true);
                    },
                }
            );
        } else {
            const form = {
                loan_principal: principalAmount,
                loan_type: loan_type as keyof typeof ButlerrLoanType,
                loan_payperiod: loan_payperiod as keyof typeof ButlerrLoanPayPeriod,
                ...data,
            };

            editLoan(
                { asst_id: assetId, loan_id: initialValue.loan_id, ...form },
                {
                    onSuccess: () => {
                        formCloseHandler(handleClose);
                    },
                }
            );
        }
    };

    const terminated = initialValue !== undefined && !!initialValue.loan_termdate;

    //Lease document linker states
    const loanIdForDoc = initialValue?.loan_id ?? docModalValues?.loan_id;

    const { data: files } = useLoanDocuments(
        assetId,
        loanIdForDoc ?? -1,
        show && loanIdForDoc !== undefined
    );
    const { mutate: addDoc } = useLoanMutations('ADD_DOCUMENT');
    const { mutate: _removeDoc } = useLoanMutations('REMOVE_DOCUMENT');

    const removeDoc = useCallback(
        (doc: Butlerr.Document.File) => {
            _removeDoc({
                assetId,
                loanId: loanIdForDoc ?? -1,
                ...doc,
            });
        },
        [_removeDoc, assetId, loanIdForDoc]
    );

    return (
        <>
            <Transition in={show} timeout={{ exit: 300 }} unmountOnExit>
                {showDocModal === false ? (
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        {({
                            handleBlur,
                            submitForm,
                            setFieldValue,
                            values,
                            dirty,
                            touched,
                            errors,
                        }) => {
                            return (
                                <Form noValidate>
                                    <Modal
                                        show={show}
                                        onHide={formCloseHandler.bind(
                                            undefined,
                                            handleClose,
                                            dirty
                                        )}
                                        scrollable
                                        size="lg"
                                        backdrop={isLoading ? 'static' : undefined}
                                        keyboard={!isLoading}
                                        centered
                                    >
                                        <Modal.Header>
                                            <Container>
                                                <Row>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <Modal.Title>
                                                            {initialValue === undefined
                                                                ? 'Add '
                                                                : 'Edit '}
                                                            Loan Details
                                                        </Modal.Title>
                                                    </div>
                                                </Row>
                                            </Container>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <Container>
                                                {terminated && (
                                                    <Alert variant="info">
                                                        <h6>Note:</h6>
                                                        <span>
                                                            This loan has already been terminated
                                                            and can no longer be edited. But,
                                                            documents can still be linked.
                                                        </span>
                                                    </Alert>
                                                )}

                                                <Row className="mb-3 g-3">
                                                    <Form.Group as={Col} lg>
                                                        <BootstrapInput
                                                            id="loan_lender"
                                                            label="Lender"
                                                            placeholder="Lender"
                                                            aria-label="Lender"
                                                            disabled={
                                                                asset.status === 'AR' || isLoading
                                                            }
                                                            required={false}
                                                            readOnly={terminated}
                                                        />
                                                    </Form.Group>
                                                </Row>

                                                <Row className="mb-3 g-3">
                                                    <Form.Group as={Col} lg>
                                                        <BootstrapSelect
                                                            id="loan_type"
                                                            label="Type"
                                                            placeholder="Type"
                                                            aria-label="Type"
                                                            disabled={
                                                                asset.status === 'AR' ||
                                                                terminated ||
                                                                isLoading
                                                            }
                                                            allowedKeys={allowedKeys.loan_type}
                                                            allowedLabels={allowedLabels.loan_type}
                                                            onChange={(e) => {
                                                                setFieldValue(
                                                                    'loan_type',
                                                                    e.target.value
                                                                );
                                                            }}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group as={Col} lg>
                                                        <BootstrapInput
                                                            id="loan_start"
                                                            label="Start Date"
                                                            type="date"
                                                            placeholder="Start Date"
                                                            aria-label="Start Date"
                                                            disabled={
                                                                asset.status === 'AR' ||
                                                                terminated ||
                                                                isLoading
                                                            }
                                                        />
                                                    </Form.Group>
                                                    <Form.Group as={Col} lg>
                                                        <BootstrapInput
                                                            id="loan_tenure"
                                                            label="Tenure (months)"
                                                            type="number"
                                                            placeholder="Tenure"
                                                            aria-label="Tenure"
                                                            disabled={
                                                                asset.status === 'AR' ||
                                                                terminated ||
                                                                isLoading
                                                            }
                                                            value={values.loan_tenure.toString()} // Needed to remove leading zeros
                                                            onChange={(e) => {
                                                                setFieldValue(
                                                                    'loan_tenure',
                                                                    Number(e.target.value)
                                                                );
                                                            }}
                                                        />
                                                    </Form.Group>
                                                </Row>

                                                <Row className="mb-3 g-3">
                                                    <Form.Group as={Col} lg={3}>
                                                        <BootstrapSelect
                                                            id="loan_currency"
                                                            label="Currency"
                                                            placeholder="Currency"
                                                            aria-label="Currency"
                                                            disabled={
                                                                asset.status === 'AR' ||
                                                                terminated ||
                                                                isLoading
                                                            }
                                                            allowedKeys={allowedKeys.loan_currency}
                                                            allowedLabels={
                                                                allowedLabels.loan_currency
                                                            }
                                                        />
                                                    </Form.Group>

                                                    {/* custom form components for loan principal amount*/}
                                                    <Form.Group as={Col} lg={3}>
                                                        <BootstrapFormLabel htmlFor="loan_principal">
                                                            Principal Amount
                                                            <span className="text-danger"> *</span>
                                                        </BootstrapFormLabel>

                                                        <InputGroup>
                                                            {principalAmtMode === '$' ? (
                                                                <Form.Control
                                                                    id="loan_principal"
                                                                    placeholder="Principal Amount"
                                                                    aria-label="Principal Amount"
                                                                    disabled={
                                                                        asset.status === 'AR' ||
                                                                        terminated ||
                                                                        isLoading
                                                                    }
                                                                    isInvalid={
                                                                        touched.loan_principal &&
                                                                        !!errors.loan_principal
                                                                    }
                                                                    value={values.loan_principal}
                                                                    onChange={(e) => {
                                                                        const [
                                                                            stringNum,
                                                                            ,
                                                                            decimalSeparator,
                                                                        ] = removeNumberFormatting(
                                                                            e.target.value
                                                                        );

                                                                        const num =
                                                                            Number(stringNum);

                                                                        // if empty, set empty (?)
                                                                        if (!stringNum) {
                                                                            setFieldValue(
                                                                                'loan_principal',
                                                                                ''
                                                                            );
                                                                            setFieldValue(
                                                                                'loanPercentage',
                                                                                ''
                                                                            );
                                                                        }
                                                                        // if number, set value
                                                                        else if (!isNaN(num)) {
                                                                            setFieldValue(
                                                                                'loan_principal',
                                                                                num.toLocaleString() +
                                                                                    (stringNum.endsWith(
                                                                                        decimalSeparator
                                                                                    )
                                                                                        ? decimalSeparator
                                                                                        : '')
                                                                            );
                                                                            setFieldValue(
                                                                                'loanPercentage',
                                                                                String(
                                                                                    convertLoanAmtToPercentage(
                                                                                        num,
                                                                                        asset
                                                                                    )
                                                                                )
                                                                            );
                                                                        }
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                />
                                                            ) : (
                                                                <Form.Control
                                                                    id="loanPercentage"
                                                                    min="0"
                                                                    placeholder="% of purchase price"
                                                                    aria-label="Principal Amount"
                                                                    disabled={
                                                                        asset.status === 'AR' ||
                                                                        terminated ||
                                                                        isLoading
                                                                    }
                                                                    isInvalid={
                                                                        touched.loanPercentage &&
                                                                        !!errors.loanPercentage
                                                                    }
                                                                    value={values.loanPercentage}
                                                                    onChange={(e) => {
                                                                        const value =
                                                                            e.target.value;

                                                                        if (isNaN(Number(value))) {
                                                                            setFieldValue(
                                                                                'loanPercentage',
                                                                                ''
                                                                            );
                                                                            setFieldValue(
                                                                                'loan_principal',
                                                                                ''
                                                                            );

                                                                            return;
                                                                        }

                                                                        setFieldValue(
                                                                            'loanPercentage',
                                                                            value
                                                                        );

                                                                        const amount =
                                                                            (Number(value) / 100) *
                                                                            asset.asst_purprice;

                                                                        const [
                                                                            stringNum,
                                                                            ,
                                                                            decimalSeparator,
                                                                        ] = removeNumberFormatting(
                                                                            amount.toLocaleString()
                                                                        );

                                                                        const num =
                                                                            Number(stringNum);

                                                                        // if empty, set empty (?)
                                                                        if (!stringNum) {
                                                                            setFieldValue(
                                                                                'loan_principal',
                                                                                ''
                                                                            );
                                                                        }
                                                                        // if number, set value
                                                                        else if (!isNaN(num)) {
                                                                            setFieldValue(
                                                                                'loan_principal',
                                                                                num.toLocaleString() +
                                                                                    (stringNum.endsWith(
                                                                                        decimalSeparator
                                                                                    )
                                                                                        ? decimalSeparator
                                                                                        : '')
                                                                            );
                                                                        }
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                />
                                                            )}

                                                            <InputGroup.Text
                                                                title="Change mode"
                                                                className="cursor-pointer"
                                                                onClick={() => {
                                                                    if (principalAmtMode === '$') {
                                                                        setPrincipalAmtMode('%');
                                                                    } else if (
                                                                        principalAmtMode === '%'
                                                                    ) {
                                                                        setPrincipalAmtMode('$');
                                                                    }
                                                                }}
                                                            >
                                                                {principalAmtMode}
                                                            </InputGroup.Text>
                                                        </InputGroup>

                                                        {principalAmtMode === '$' ? (
                                                            touched.loan_principal &&
                                                            errors.loan_principal !== undefined ? (
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.loan_principal}
                                                                </Form.Control.Feedback>
                                                            ) : (
                                                                <></>
                                                            )
                                                        ) : touched.loanPercentage &&
                                                          errors.loanPercentage !== undefined ? (
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.loanPercentage}
                                                            </Form.Control.Feedback>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </Form.Group>
                                                    {/* <Form.Group as={Col} lg={3}>
                                                        <BootstrapInputPrice
                                                            id="loan_principal"
                                                            label="Principal Amount"
                                                            placeholder="Principal Amount"
                                                            aria-label="Principal Amount"
                                                            disabled={
                                                                asset.status === 'AR' ||
                                                                terminated ||
                                                                isLoading
                                                            }
                                                        />
                                                    </Form.Group> */}
                                                    <Form.Group as={Col} lg={3}>
                                                        <BootstrapInput
                                                            id="loan_interestrate"
                                                            label="Interest Rate (%)"
                                                            type="number"
                                                            step="0.01"
                                                            placeholder="Interest Rate"
                                                            aria-label="Interest Rate"
                                                            disabled={
                                                                asset.status === 'AR' ||
                                                                terminated ||
                                                                isLoading
                                                            }
                                                            value={values.loan_interestrate.toString()} // Needed to remove leading zeros
                                                            onChange={(e) => {
                                                                setFieldValue(
                                                                    'loan_interestrate',
                                                                    Number(e.target.value)
                                                                );
                                                            }}
                                                        />
                                                    </Form.Group>

                                                    <Form.Group as={Col} lg={3}>
                                                        <BootstrapSelect
                                                            id="loan_payperiod"
                                                            label="Repayment Due"
                                                            placeholder="Repayment Due"
                                                            aria-label="Repayment Due"
                                                            disabled={
                                                                asset.status === 'AR' ||
                                                                terminated ||
                                                                isLoading
                                                            }
                                                            allowedKeys={allowedKeys.loan_payperiod}
                                                            allowedLabels={
                                                                allowedLabels.loan_payperiod
                                                            }
                                                            onChange={(e) => {
                                                                setFieldValue(
                                                                    'loan_payperiod',
                                                                    e.target.value
                                                                );
                                                            }}
                                                        />
                                                    </Form.Group>
                                                </Row>

                                                {/* <Row className="mb-3 g-3">
                                                        <Form.Group as={Col} lg>
                                                            <BootstrapInput
                                                                id="loan_offset"
                                                                label="Interest Offset"
                                                                type="number"
                                                                placeholder="Offset"
                                                                aria-label="Offset"
                                                                disabled={isLoading}
                                                                value={values.loan_offset!.toString()} // Needed to remove leading zeros
                                                                onChange={(e) => {
                                                                    setFieldValue(
                                                                        'loan_offset',
                                                                        Number(e.target.value)
                                                                    );
                                                                   
                                                                }}
                                                                required={false}
                                                            />
                                                        </Form.Group>
                                                        
                                                    </Row> */}
                                                <Row className="mb-3 g-3">
                                                    <Form.Group as={Col} lg>
                                                        <BootstrapInput
                                                            id="loan_desc"
                                                            label="Description"
                                                            as="textarea"
                                                            placeholder="Description"
                                                            aria-label="Description"
                                                            disabled={
                                                                asset.status === 'AR' ||
                                                                terminated ||
                                                                isLoading
                                                            }
                                                            required={false}
                                                        />
                                                    </Form.Group>
                                                </Row>

                                                {initialValue !== undefined && (
                                                    <>
                                                        <Row className="mb-3">
                                                            <Col>
                                                                <LoanAdjustments
                                                                    asset={asset}
                                                                    loan={initialValue}
                                                                    terminated={terminated}
                                                                />
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col>
                                                                <AssetDocsTable
                                                                    files={files}
                                                                    asset={asset}
                                                                    onAdd={
                                                                        loanIdForDoc
                                                                            ? (f) =>
                                                                                  addDoc({
                                                                                      assetId,
                                                                                      loanId: loanIdForDoc,
                                                                                      ...f,
                                                                                  })
                                                                            : undefined
                                                                    }
                                                                    onRemove={removeDoc}
                                                                    initialFolder={
                                                                        asset.doct_loan_id
                                                                    }
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </>
                                                )}
                                            </Container>
                                        </Modal.Body>

                                        <BootstrapModalFooter
                                            error={error}
                                            deleteBtnProps={{
                                                onClick: () =>
                                                    onDelete(initialValue?.loan_id ?? -1),
                                                disabled: asset.status === 'AR' || isLoading,
                                                className:
                                                    initialValue === undefined ? 'd-none' : '',
                                            }}
                                            besideDeleteBtn={
                                                <OverlayTrigger
                                                    overlay={
                                                        terminated ? (
                                                            <Tooltip>
                                                                This loan is already terminated.
                                                            </Tooltip>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                >
                                                    {({ ref, ...triggerHandler }) => (
                                                        <span {...triggerHandler}>
                                                            <IconButton
                                                                ref={ref}
                                                                transparent
                                                                Icon={DoNotDisturb}
                                                                iconHtmlColor="var(--danger)"
                                                                label="Terminate"
                                                                variant="danger"
                                                                border={false}
                                                                className={classNames(
                                                                    'ms-2 border-danger text-danger',
                                                                    initialValue === undefined &&
                                                                        'd-none'
                                                                )}
                                                                onClick={() =>
                                                                    onTerminate(
                                                                        initialValue?.loan_id ?? -1
                                                                    )
                                                                }
                                                                disabled={
                                                                    asset.status === 'AR' ||
                                                                    terminated ||
                                                                    isLoading
                                                                }
                                                            />
                                                        </span>
                                                    )}
                                                </OverlayTrigger>
                                            }
                                            cancelBtnProps={{
                                                onClick: () => formCloseHandler(handleClose, dirty),
                                                disabled: isLoading,
                                            }}
                                            confirmBtnProps={{
                                                Icon:
                                                    initialValue === undefined
                                                        ? NavigateNext
                                                        : Done,
                                                label:
                                                    initialValue === undefined
                                                        ? isLoading
                                                            ? 'Loading...'
                                                            : 'Next'
                                                        : isLoading
                                                        ? 'Saving...'
                                                        : 'Save',
                                                onClick:
                                                    initialValue === undefined
                                                        ? submitForm
                                                        : !dirty
                                                        ? handleClose
                                                        : submitForm,
                                                disabled:
                                                    asset.status === 'AR' ||
                                                    terminated ||
                                                    isLoading,
                                            }}
                                        />
                                    </Modal>
                                </Form>
                            );
                        }}
                    </Formik>
                ) : (
                    <Formik initialValues={{ key: null }} onSubmit={onDocSubmit}>
                        {({ submitForm }) => (
                            <Form noValidate>
                                <Modal
                                    show={show}
                                    onHide={() => {
                                        handleClose();
                                        setShowDocModal(false);
                                    }}
                                    scrollable
                                    size="lg"
                                    centered
                                >
                                    <Modal.Header>
                                        <Container>
                                            <Row>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <Modal.Title>Add Loan Document</Modal.Title>
                                                </div>
                                            </Row>
                                        </Container>
                                    </Modal.Header>

                                    <Modal.Body>
                                        <Container>
                                            <Row className="mt-2">
                                                <Col>
                                                    <AssetDocsTable
                                                        files={files}
                                                        asset={asset}
                                                        onAdd={
                                                            loanIdForDoc
                                                                ? (f) =>
                                                                      addDoc({
                                                                          assetId,
                                                                          loanId: loanIdForDoc,
                                                                          ...f,
                                                                      })
                                                                : undefined
                                                        }
                                                        onRemove={removeDoc}
                                                        initialFolder={asset.doct_loan_id}
                                                    />
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Modal.Body>
                                    <BootstrapModalFooter
                                        error={null}
                                        confirmBtnProps={{
                                            Icon: Close,
                                            label: 'Close',
                                            onClick: () => submitForm(),
                                        }}
                                    />
                                </Modal>
                            </Form>
                        )}
                    </Formik>
                )}
            </Transition>
            <DestructiveModal
                show={showDeleteModal}
                onClose={onDeleteClose}
                onConfirm={onDeleteConfirm}
                title="Delete loan?"
                description="Please note that this loan and all related data will be deleted from the system."
                className="modal-layer-1"
                backdropClassName="modal-layer-1"
            />
            <DestructiveModal
                show={showTerminateModal}
                onClose={onTerminateClose}
                onConfirm={onTerminateConfirm}
                title="Terminate loan?"
                description="Please note that this loan will be terminated but all information will still be retained."
                className="modal-layer-1"
                backdropClassName="modal-layer-1"
                confirmBtnText="Terminate"
            />
        </>
    );
};

interface LoanAdjustmentsProps {
    asset: Butlerr.Asset;
    loan: Butlerr.Asset.Loan;
    terminated: boolean;
}

function LoanAdjustments({ asset, loan, terminated }: LoanAdjustmentsProps) {
    const assetId = asset.asst_id;
    const loanId = loan.loan_id;

    const { data: adjustments } = useLoanAdjustments(assetId, loanId);

    const [addingAdjustment, setAddingAdjustment] = useState(false);

    const headerNames = useMemo(() => ['START DATE', 'NEW INTEREST RATE', 'DOCUMENT', ''], []);

    return (
        <>
            <div className="mb-2 d-flex justify-content-between align-items-center">
                <BootstrapFormLabel htmlFor={undefined} className="mb-0">
                    Interest Rate Adjustments
                </BootstrapFormLabel>
                {!addingAdjustment && asset.status !== 'AR' && (
                    <IconButton
                        transparent
                        size="sm"
                        Icon={Add}
                        title="Add adjustment"
                        onClick={() => setAddingAdjustment(true)}
                        className={classNames(terminated && 'd-none')}
                        style={{ width: '1.2rem', height: '1.2rem' }}
                        iconStyles={{ fontSize: '1rem' }}
                    />
                )}
            </div>

            <BootstrapTable headerNames={headerNames} className="mb-0">
                {adjustments === undefined ? (
                    <TableBodySkeleton cellCount={headerNames.length} />
                ) : adjustments.length === 0 && !addingAdjustment ? (
                    <tr>
                        <td
                            colSpan={headerNames.length}
                            className="text-muted text-center fw-semibold small"
                        >
                            No Adjustments
                        </td>
                    </tr>
                ) : (
                    adjustments.map((adj) => (
                        <LoanAdjustmentItem
                            key={adj.lich_id}
                            adjustment={adj}
                            asset={asset}
                            loan={loan}
                            terminated={terminated}
                        />
                    ))
                )}
                {addingAdjustment && (
                    <LoanAdjustmentItem
                        asset={asset}
                        loan={loan}
                        onAddDone={setAddingAdjustment.bind(undefined, false)}
                        terminated={terminated}
                    />
                )}
            </BootstrapTable>
        </>
    );
}

interface LoanAdjustmentItemProps {
    adjustment?: Butlerr.Asset.LoanInterestChange;
    asset: Butlerr.Asset;
    loan: Butlerr.Asset.Loan;
    onAddDone?: () => void;
    terminated: boolean;
}

function LoanAdjustmentItem({
    adjustment,
    asset,
    loan,
    onAddDone,
    terminated,
}: LoanAdjustmentItemProps) {
    const assetId = asset.asst_id;
    const loanId = loan.loan_id;

    const { mutate: createLoanAdjustment } = useLoanAdjustmentMutations('CREATE');
    const { mutate: editLoanAdjustment, isLoading: isEditLoading } =
        useLoanAdjustmentMutations('EDIT');
    const { mutateAsync: deleteLoanAdjustment, isLoading: isDeleting } =
        useLoanAdjustmentMutations('DELETE');

    // form states
    const [isEditing, setIsEditing] = useState(adjustment === undefined);

    const schema = yup.object().shape({
        lich_date: yup
            .date()
            .min(
                new Date(new Date(loan.loan_start).setHours(0, 0, 0, 0)),
                'Must be after the loan start date'
            )
            .required('Required'),
        lich_interest: yup
            .number()
            .max(100, `Interest rate should not be more than 100%.`)
            .required('Required'),
    });

    type LichForm = {
        lich_date: string;
        lich_interest: number;
    };

    const handleSave = async (values: LichForm) => {
        //if nothing changed, return
        if (
            adjustment !== undefined &&
            adjustment.lich_interest === values.lich_interest &&
            convertDateToYYYYMMDD(adjustment.lich_date) === values.lich_date &&
            adjustment.lich_doclink === (selectedDocument?.doc_id ?? null)
        ) {
            setIsEditing(false);
            return;
        }

        const form = {
            assetId,
            loanId,
            adjustmentId: adjustment?.lich_id,
            lich_doclink: selectedDocument?.doc_id,
            ...values,
        };

        //if no initial value, add new adjustment, else edit
        if (adjustment === undefined) {
            createLoanAdjustment(form, {
                onSuccess: () => {
                    setIsEditing(false);
                    onAddDone?.();
                },
            });
        } else {
            //assert since `adjustmentId` won't be undefined in this block
            const _form = form as typeof form & { adjustmentId: number };
            editLoanAdjustment(_form, {
                onSuccess: () => {
                    setIsEditing(false);
                },
            });
        }
    };

    const onDelete = async () => {
        if (adjustment !== undefined) {
            try {
                await deleteLoanAdjustment({ assetId, loanId, adjustmentId: adjustment.lich_id });
            } catch (err) {
                console.error(err);
            }
        } else {
            onAddDone?.();
        }
    };

    //document states
    const [selectedDocument, setSelectedDocument] = useState<Butlerr.Document.File>();

    useEffect(() => {
        if (adjustment?.lich_doclink) {
            const { doc_name, doc_created, doc_desc, doc_format, doc_id, doct_parentid } =
                adjustment;
            setSelectedDocument({
                doc_name,
                doc_created,
                doc_desc,
                doc_format,
                doc_id,
                doct_parentid,
            } as Butlerr.Document.File);
        }
    }, [adjustment]);

    const [picker, openPicker] = useModal(DocumentSelectModal, {
        asset: asset,
        onFileSelect: setSelectedDocument,
        className: 'modal-layer-1',
        backdropClassName: 'modal-layer-1',
        initialFolder: asset.doct_loan_id,
    });

    const download = useDownloadWithPostToken();

    return (
        <Formik
            enableReinitialize
            initialValues={{
                lich_date: convertDateToYYYYMMDD(adjustment?.lich_date) ?? '',
                lich_interest: adjustment?.lich_interest ?? 0,
            }}
            validationSchema={schema}
            onSubmit={handleSave}
        >
            {({ values, handleSubmit }) => (
                <tr>
                    <td>
                        {isEditing ? (
                            <BootstrapInput id="lich_date" type="date" />
                        ) : (
                            <div>{formatDate(values.lich_date) ?? '-'}</div>
                        )}
                    </td>
                    <td>
                        {isEditing ? (
                            <BootstrapInput
                                id="lich_interest"
                                type="number"
                                inputGroupText="%"
                                inputGroupPosition="suffix"
                                min="0"
                                max="100"
                                step="0.01"
                            />
                        ) : (
                            <span>{values.lich_interest + '%'}</span>
                        )}
                    </td>
                    <td>
                        <Row className="d-flex align-items-center">
                            <Col>
                                {(() => {
                                    const fileName = !selectedDocument
                                        ? isEditing
                                            ? 'Select Document'
                                            : '-'
                                        : `${selectedDocument.doc_name}${
                                              !selectedDocument.doc_format
                                                  ? ''
                                                  : '.' + selectedDocument.doc_format
                                          }`;

                                    return (
                                        <LineClampText
                                            maxLines={isEditing ? 2 : 1}
                                            role={isEditing ? 'button' : undefined}
                                            onClick={() => {
                                                if (isEditing) {
                                                    return openPicker();
                                                }
                                                if (selectedDocument) {
                                                    download('DOCUMENTS', selectedDocument.doc_id);
                                                }
                                            }}
                                            className={classNames({
                                                'mb-0 small': true,
                                                'link-primary cursor-pointer': !isEditLoading,
                                                'link-secondary pe-none': isEditLoading,
                                            })}
                                            title={
                                                isEditing
                                                    ? fileName
                                                    : selectedDocument
                                                    ? 'Download'
                                                    : undefined
                                            }
                                        >
                                            {fileName}
                                        </LineClampText>
                                    );
                                })()}
                            </Col>
                            {isEditing && (
                                <Col>
                                    {selectedDocument !== undefined && (
                                        <span title="Unlink document">
                                            <DeleteOutline
                                                className={classNames({
                                                    'link-primary cursor-pointer': !isEditLoading,
                                                    'link-secondary pe-none': isEditLoading,
                                                })}
                                                fontSize="small"
                                                onClick={() => setSelectedDocument(undefined)}
                                            />
                                        </span>
                                    )}
                                </Col>
                            )}
                        </Row>
                    </td>

                    <td className="py-0 align-middle tcol-actions">
                        {asset.status !== 'AR' && (
                            <div className="d-flex justify-content-center">
                                <IconButton
                                    transparent
                                    title="Edit"
                                    className={classNames('me-2', terminated && 'd-none')}
                                    disabled={isEditLoading}
                                    Icon={
                                        isEditing || adjustment === undefined ? Save : EditOutlined
                                    }
                                    onClick={
                                        isEditing
                                            ? handleSubmit.bind(null, undefined)
                                            : () => setIsEditing((prev) => !prev)
                                    }
                                />
                                <IconButton
                                    transparent
                                    title="Delete"
                                    disabled={isDeleting}
                                    Icon={DeleteOutline}
                                    onClick={onDelete}
                                    className={classNames(terminated && 'd-none')}
                                />
                            </div>
                        )}
                    </td>

                    {picker}
                </tr>
            )}
        </Formik>
    );
}

// formulas taken from https://www.thebalance.com/calculate-mortgage-315668
// function calculatePaymentAmount(
//     P: number,
//     r: number,
//     t: number,
//     n: number,
//     offset: number,
//     type: LoanTypeKey | ''
// ) {
//     // P = principal amount
//     // r = interest rate (%)
//     // t = term
//     // n = number of payments per year
//     r = r / 100;
//     t = t / 12;
//     let paymentAmount = 0;

//     switch (type) {
//         case 'I': // interest only
//             paymentAmount = (P - offset) * (r / n);
//             break;
//         case 'P': // interest and principal
//             paymentAmount = (P * (r / n) * (1 + r / n) ** (n * t)) / ((1 + r / n) ** (n * t) - 1);
//             break;
//         default:
//             paymentAmount = 0;
//             break;
//     }

//     // check if infinity (happens when pay period is not set)
//     if (!isFinite(paymentAmount)) {
//         return 0;
//     }

//     return Number(paymentAmount.toFixed(2));
// }

function convertLoanAmtToPercentage(loanAmount: number, asset: Butlerr.Asset) {
    return Number(Number((loanAmount / asset.asst_purprice) * 100).toFixed(2));
}

export default AssetLoansModal;
