import { Add, Done, LinkOff, PostAdd } from '@mui/icons-material';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Col, Container, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import { Transition } from 'react-transition-group';
import * as yup from 'yup';
import { useValuationMutations, ValuationQueries } from '../../../services/assetvaluation.service';
import { Butlerr } from '../../../types/butlerr';
import { BootstrapInput, BootstrapInputPrice } from '../../utils/FormikBootstrapInputs';
import {
    convertDateToYYYYMMDD,
    formCloseHandler,
    removeNumberFormatting,
} from '../../utils/HelperFunctions';
import IconButton from '../../utils/IconButton';
import DestructiveModal from '../../utils/DestructiveModal';
import useModal from '../../../hooks/useModal';
import { DocumentSelectModal } from '../../documents/DocsModal';
import { useQueryClient } from 'react-query';
import BootstrapModalFooter from '../../utils/BootstrapModalFooter';

interface ValuationForm {
    valu_valuer: string | null;
    valu_date: string;
    valu_value: string;
    valu_note: string | null;
}

interface ValuationModalInterface {
    show: boolean;
    onModalClose: (changed?: boolean, message?: string) => void;
    initialValue?: Butlerr.Asset.Valuation;
    assetId: number;
    asset: Butlerr.Asset;
}

export function AssetValuationsModal({
    show,
    onModalClose,
    initialValue,
    assetId,
    asset,
}: ValuationModalInterface) {
    const {
        mutate: createValuation,
        isLoading: isCreateLoading,
        error: createError,
    } = useValuationMutations('CREATE');
    const {
        mutate: editValuation,
        isLoading: isEditLoading,
        error: editError,
    } = useValuationMutations('EDIT');
    const { mutateAsync: deleteValuation } = useValuationMutations('DELETE');

    const isLoading = initialValue === undefined ? isCreateLoading : isEditLoading;
    const error = initialValue === undefined ? createError : editError;

    // modal
    const handleClose = () => {
        if (error !== null) error.message = '';
        if (!isLoading) onModalClose();
    };

    const initialValues: ValuationForm = {
        valu_valuer: initialValue?.valu_valuer ?? '',
        valu_date: convertDateToYYYYMMDD(initialValue?.valu_date) ?? '',
        valu_value:
            initialValue?.valu_value !== undefined
                ? Number(initialValue.valu_value).toLocaleString()
                : '',
        valu_note: initialValue?.valu_note ?? '',
    };

    const validationSchema = yup.object().shape({
        valu_valuer: yup.string(),
        valu_date: yup.date().required('Required'),
        valu_value: yup.string().required('Required'),
        valu_note: yup.string().max(256, ({ max }) => `Maximum ${max} characters`),
    });

    const onSubmit = (values: ValuationForm) => {
        const { valu_value, ...data } = values;

        const [valuationAmount] = removeNumberFormatting(String(valu_value));

        const form = {
            ...data,
            valu_value: Number(valuationAmount),
            asst_id: assetId,
            valu_id: initialValue?.valu_id,
            valu_doclink: selectedDocument?.doc_id ?? null,
        };

        if (initialValue === undefined) {
            createValuation(form, {
                onSuccess: () => {
                    formCloseHandler(handleClose);
                },
            });
        } else {
            editValuation(form as typeof form & { valu_id: number }, {
                onSuccess: () => {
                    formCloseHandler(handleClose);
                },
            });
        }
    };

    // delete modal
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedValuationId, setSelectedValuationId] = useState<number>();

    const onDelete = (valuationId: number) => {
        setSelectedValuationId(valuationId);
        setShowDeleteModal(true);
    };

    const onDeleteClose = () => {
        setShowDeleteModal(false);
    };

    const onDeleteConfirm = async () => {
        await deleteValuation({ asst_id: assetId, valu_id: selectedValuationId ?? -1 });
        formCloseHandler(handleClose);
    };

    // document picker
    const [selectedDocument, setSelectedDocument] = useState<Butlerr.Document.File>();

    useEffect(() => {
        if (initialValue?.valu_doclink) {
            setSelectedDocument({
                doc_name: initialValue?.doc_name,
                doc_created: initialValue?.doc_created,
                doc_desc: initialValue?.doc_desc,
                doc_format: initialValue?.doc_format,
                doc_id: initialValue?.doc_id,
                doct_parentid: initialValue?.doct_parentid,
            } as Butlerr.Document.File);
        } else {
            setSelectedDocument(undefined);
        }
    }, [
        initialValue?.valu_doclink,
        initialValue?.doc_name,
        initialValue?.doc_created,
        initialValue?.doc_desc,
        initialValue?.doc_format,
        initialValue?.doc_id,
        initialValue?.doct_parentid,
    ]);

    const [picker, openPicker] = useModal(DocumentSelectModal, {
        asset: asset,
        onFileSelect: setSelectedDocument,
        className: 'modal-layer-1',
        backdropClassName: 'modal-layer-1',
        initialFolder: asset.doct_valu_id,
    });

    /**
     * Refresh all cashflows when the modal is opened, if it's an edit modal
     * The documents might have been changed
     */
    const qc = useQueryClient();
    useEffect(() => {
        if (initialValue?.asst_id !== undefined && show) {
            qc.invalidateQueries(ValuationQueries.VALUATIONS(initialValue.asst_id));
        }
    }, [initialValue?.asst_id, qc, show]);

    return (
        <>
            <Transition in={show} timeout={{ exit: 300 }} unmountOnExit>
                {() => (
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        {({ submitForm, setFieldValue, values, dirty }) => {
                            return (
                                <Form noValidate>
                                    <Modal
                                        show={show}
                                        onHide={formCloseHandler.bind(
                                            undefined,
                                            handleClose,
                                            dirty
                                        )}
                                        scrollable
                                        size="lg"
                                        backdrop={isLoading ? 'static' : undefined}
                                        keyboard={!isLoading}
                                        centered
                                    >
                                        <Modal.Header>
                                            <Container>
                                                <Row>
                                                    <Modal.Title>
                                                        {initialValue === undefined
                                                            ? 'Add '
                                                            : 'Edit '}
                                                        Valuation Details
                                                    </Modal.Title>
                                                </Row>
                                            </Container>
                                        </Modal.Header>

                                        <Modal.Body>
                                            <Container>
                                                <Row className="mb-3 g-3">
                                                    <Form.Group as={Col} lg>
                                                        <BootstrapInput
                                                            id="valu_valuer"
                                                            label="Valuer"
                                                            placeholder="Valuer"
                                                            aria-label="Valuer"
                                                            disabled={
                                                                asset.status === 'AR' || isLoading
                                                            }
                                                            required={false}
                                                        />
                                                    </Form.Group>

                                                    <Form.Group as={Col} lg>
                                                        <BootstrapInput
                                                            id="valu_date"
                                                            label="Date"
                                                            type="date"
                                                            placeholder="Date"
                                                            aria-label="Date"
                                                            disabled={
                                                                asset.status === 'AR' || isLoading
                                                            }
                                                        />
                                                    </Form.Group>

                                                    <Form.Group as={Col} lg>
                                                        <BootstrapInputPrice
                                                            id="valu_value"
                                                            label="Price"
                                                            placeholder="Price"
                                                            aria-label="Price"
                                                            disabled={
                                                                asset.status === 'AR' || isLoading
                                                            }
                                                            inputGroupText={asset.asst_currency}
                                                        />
                                                    </Form.Group>
                                                </Row>

                                                <Row className="mb-3 g-3">
                                                    <Form.Group as={Col} lg>
                                                        <BootstrapInput
                                                            as="textarea"
                                                            id="valu_note"
                                                            label="Notes"
                                                            placeholder="Notes"
                                                            disabled={
                                                                asset.status === 'AR' || isLoading
                                                            }
                                                            required={false}
                                                        />
                                                    </Form.Group>
                                                </Row>

                                                <Row className="mb-3 g-3">
                                                    <Form.Group as={Col} lg>
                                                        <InputGroup>
                                                            <Form.Control
                                                                readOnly
                                                                value={
                                                                    selectedDocument
                                                                        ? `${
                                                                              selectedDocument.doc_name
                                                                          }${
                                                                              selectedDocument.doc_format
                                                                                  ? '.' +
                                                                                    selectedDocument.doc_format
                                                                                  : ''
                                                                          }`
                                                                        : ''
                                                                }
                                                                placeholder="No document linked"
                                                            />
                                                            <InputGroup.Text>
                                                                {selectedDocument !== undefined ? (
                                                                    <IconButton
                                                                        transparent
                                                                        border={false}
                                                                        iconHtmlColor="var(--grey)"
                                                                        Icon={LinkOff}
                                                                        title="Unlink document"
                                                                        onClick={() =>
                                                                            setSelectedDocument(
                                                                                undefined
                                                                            )
                                                                        }
                                                                        disabled={
                                                                            asset.status === 'AR' ||
                                                                            isLoading
                                                                        }
                                                                    />
                                                                ) : (
                                                                    <IconButton
                                                                        transparent
                                                                        border={false}
                                                                        iconHtmlColor="var(--grey)"
                                                                        Icon={PostAdd}
                                                                        title="Link a document"
                                                                        onClick={openPicker}
                                                                        disabled={
                                                                            asset.status === 'AR' ||
                                                                            isLoading
                                                                        }
                                                                    />
                                                                )}
                                                            </InputGroup.Text>
                                                        </InputGroup>
                                                    </Form.Group>
                                                </Row>
                                            </Container>
                                        </Modal.Body>
                                        <BootstrapModalFooter
                                            error={error}
                                            deleteBtnProps={{
                                                onClick: () => onDelete(initialValue?.valu_id!),
                                                disabled: asset.status === 'AR' || isLoading,
                                                className:
                                                    initialValue === undefined ? 'd-none' : '',
                                            }}
                                            cancelBtnProps={{
                                                onClick: () => formCloseHandler(handleClose, dirty),
                                                disabled: isLoading,
                                            }}
                                            confirmBtnProps={{
                                                Icon: initialValue === undefined ? Add : Done,
                                                label:
                                                    initialValue === undefined
                                                        ? isLoading
                                                            ? 'Adding...'
                                                            : 'Add'
                                                        : isLoading
                                                        ? 'Saving...'
                                                        : 'Save',
                                                onClick:
                                                    initialValue === undefined
                                                        ? submitForm
                                                        : !dirty &&
                                                          selectedDocument?.doc_id ===
                                                              initialValue.valu_doclink
                                                        ? handleClose
                                                        : submitForm,
                                                disabled: asset.status === 'AR' || isLoading,
                                            }}
                                        />
                                    </Modal>
                                    {picker}
                                </Form>
                            );
                        }}
                    </Formik>
                )}
            </Transition>

            <DestructiveModal
                show={showDeleteModal}
                onClose={onDeleteClose}
                onConfirm={onDeleteConfirm}
                title="Delete valuation?"
                description="Any document linked to this valuation will not be affected."
                className="modal-layer-1"
                backdropClassName="modal-layer-1"
            />
        </>
    );
}
