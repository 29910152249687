import { useCallback, useEffect, useMemo, useState } from "react";
import { Container, Card } from "react-bootstrap";
import { useAsset, usePartnerAssets } from "../../../services/asset.service";
import AssetsList from "./ClientAssetList";
import BootstrapSpinner from "../../utils/BootstrapSpinner";
import { AssetConversationsCardContent } from "../../assets/conversations/AssetConversations";
import { Redirect, Route, Switch, useHistory, useParams, useLocation, matchPath } from "react-router-dom";
import EmptyMessage from "../../utils/EmptyMessage";
import { Butlerr } from "../../../types/butlerr";
import { AssetInfoCardContent } from "../../assets/info/AssetInfo";
import ButlerrCard from "../../utils/ButlerrCard";
import { NotificationsOutlined, ForumOutlined, InfoOutlined, AddCircle } from "@mui/icons-material";
import useModal from "../../../hooks/useModal";
import AssetConversationsModal from "../../assets/conversations/AssetConversationsModal";
import IconButton from "../../utils/IconButton";
import classNames from "classnames";
import styles from "../partner.module.css"
import PartnerReminders from "../reminders/PartnerReminders";
import { useWorkProfiles } from "../../../services/partner.service";
import PageHeader from "../../utils/PageHeader";

enum ClientAssetRoutes {
    CLIENTASSETS = '/work/assets',
    ASSET_DETAILS = '/work/assets/:assetId',
    STAFF_ASSET_DETAILS = '/work/assets/staff/:assetId/info',
    INFO = '/work/assets/:assetId/info',
    REMINDERS = '/work/assets/:assetId/reminders',
    CONVERSATIONS = '/work/assets/:assetId/conversations',
    CONVERSATIONSID = '/work/assets/:assetId/conversations/:convoId',
}

type TArgs =
    | [ClientAssetRoutes]
    | [ClientAssetRoutes, { [param: string]: string | number }];

export function createClientAssetRoute(...args: TArgs) {
    const [path, params] = args;

    if (params === undefined) {
        return path;
    }
    //replace all params with the params passed
    return Object.entries(params).reduce(
        (previousValue: string, [param, value]) => {
            return previousValue.replace(`:${param}`, '' + value);
        },
        path
    );
}

interface ClientAssetsInterface {
    isAdmin: boolean
}

const ClientAssets = ({isAdmin}: ClientAssetsInterface) => {
    const { data: partnerAssets = [], isLoading } = usePartnerAssets();
    const { data: workProfiles = [] } = useWorkProfiles();

    const wkpfId = workProfiles.length !== 0 ? workProfiles[0].wkpf_id : -1

    const history = useHistory()

    if (isLoading) return <BootstrapSpinner />

    if (partnerAssets.length === 0) return (<EmptyMessage message="No client assets yet.
     You will see client assets when they start adding you as a team member under MANAGE - Asset Info (Team)"></EmptyMessage>)

    return (
        <Container className="p-0">
            <Switch>
                <Route exact path={ClientAssetRoutes.CLIENTASSETS}>
                    <AssetsList
                        assets={partnerAssets}
                        remiClick={(assetId: number) => {
                            history.push(createClientAssetRoute(ClientAssetRoutes.REMINDERS).replace(':assetId', String(assetId)))
                        }}
                        convoClick={(assetId: number) => {
                            history.push(createClientAssetRoute(ClientAssetRoutes.CONVERSATIONS).replace(':assetId', String(assetId)))
                        }}
                        wkpfId={wkpfId}
                        isAdmin={isAdmin}
                    />
                </Route>
                <Route path={ClientAssetRoutes.STAFF_ASSET_DETAILS}>
                    <StaffAssetComponents wkpfId={wkpfId}/>
                </Route>
                <Route path={ClientAssetRoutes.ASSET_DETAILS}>
                    <AssetComponents partnerAssets={partnerAssets} />
                </Route>
                <Redirect to={ClientAssetRoutes.CLIENTASSETS} />
            </Switch>
        </Container>
    )
}

interface AssetConvoIdCard {
    asset: Butlerr.Asset,
    selectedConvoId: number | undefined,
    setSelectedConvoId: React.Dispatch<React.SetStateAction<number | undefined>>
}

export const AssetConversationsIdCard = ({
    asset,
    selectedConvoId,
    setSelectedConvoId,
    onModalClose
}: AssetConvoIdCard & { onModalClose: () => void }) => {

    const { convoId } = useParams<{ convoId: string }>();

    useEffect(() => {
        if (convoId !== undefined) {
            setSelectedConvoId(parseInt(convoId))
        }
        // eslint-disable-next-line
    }, [convoId])

    return (
        <AssetConversationsCardContent
            asset={asset}
            onModalClose={onModalClose}
            selectedConvoId={selectedConvoId}
            setSelectedConvoId={setSelectedConvoId}
        />
    )
}

interface StaffAssetComponentsInterface {
    wkpfId: number
}

const StaffAssetComponents = ({wkpfId}: StaffAssetComponentsInterface) => {
    const { assetId: selectedAssetId } = useParams<{ assetId: string }>();
    const { data: selectedAsset } = useAsset(parseInt(selectedAssetId))

    return (
        <>
            <PageHeader Icon={InfoOutlined} title="Asset Info" />

            <Card
                style={{ borderRadius: '24px', borderTopLeftRadius: 0 }}
                className="border-0 shadow"
            >
                <Card.Body>
                    {selectedAsset === undefined ?
                        <EmptyMessage message="Resource not found!" /> :
                        <AssetInfoCardContent asset={selectedAsset} isPartner={true} isCoowner={false} />}
                </Card.Body>
            </Card>
        </>
    )


}

interface AssetComponentsInterface {
    partnerAssets: Butlerr.ClientAsset[]
}

const AssetComponents = ({ partnerAssets }: AssetComponentsInterface) => {

    const { assetId: selectedAsset } = useParams<{ assetId: string }>();
    const asset = partnerAssets.find(asset => asset.asst_id.toString() === selectedAsset);

    const items = useMemo(() => [
        { Icon: InfoOutlined, title: "Asset Info" as const, route: ClientAssetRoutes.INFO },
        { Icon: NotificationsOutlined, title: "Reminders" as const, route: ClientAssetRoutes.REMINDERS },
        { Icon: ForumOutlined, title: "Conversations" as const, route: ClientAssetRoutes.CONVERSATIONS }
    ], []);

    //Get current active route
    const location = useLocation();
    const history = useHistory();

    const selectedTab = useMemo(() => {
        const activeRoute = matchPath(location.pathname, items.map(i => i.route));
        //return the index of active route
        return items.findIndex(i => i.route === activeRoute?.path);

    }, [items, location]);

    const ownerId = asset?.owner_id ?? asset?.user_id ?? -1;

    //conversations modal state
    const [modal, open, close] = useModal(AssetConversationsModal, {
        assetId: Number(selectedAsset),
        ownerId,
    })

    const [selectedConvoId, setSelectedConvoId] = useState<number>();

    const { pathname } = useLocation();

    const handleChatChange = useCallback((convoId: string) => {
        const newPathname = `/${pathname.split('/')[1]}/${pathname.split('/')[2]}/${asset?.asst_id}/conversations/${convoId}`;
        history.push(newPathname);
    }, [history, pathname, asset]);

    useEffect(() => {
        if (selectedConvoId !== undefined) {
            handleChatChange(selectedConvoId?.toString())
        }
        // eslint-disable-next-line
    }, [selectedConvoId])


    return (
        <ButlerrCard
            items={items}
            toggleType='tabs'
            activeIndex={selectedTab}
            rightSide={({ title }) => {
                if (title === "Conversations") {
                    return (
                        <IconButton Icon={AddCircle} title="Create A Conversation" onClick={open} />
                    )
                }
            }}
            cardProps={({ title }) => ({
                className: classNames("shadow-sm bg-white", title === "Conversations" ? "p-0 " + styles.convoContainer : "rounded-4 p-4")
            })}
            onActiveItemChange={({ route }) => {
                //push url on change
                history.push(createClientAssetRoute(route, { assetId: selectedAsset }))
            }}
            style={{ wordBreak: 'break-word' }}

        >
            <Switch>
                <Route path={ClientAssetRoutes.REMINDERS}>
                    {() => {
                        if (asset === undefined) {
                            return <EmptyMessage message="Resource not found!" />
                        }
                        return <PartnerReminders asset={asset} />
                    }}
                </Route>
                <Route path={`${ClientAssetRoutes.CONVERSATIONSID}`}>
                    {() => {
                        if (asset === undefined) {
                            return <EmptyMessage message="Resource not found!" />
                        }
                        return (
                            <>
                                <AssetConversationsIdCard
                                    asset={asset}
                                    onModalClose={close}
                                    selectedConvoId={selectedConvoId}
                                    setSelectedConvoId={setSelectedConvoId}
                                />
                                {modal}
                            </>
                        )
                    }}
                </Route>
                <Route path={`${ClientAssetRoutes.CONVERSATIONS}`}>
                    {() => {
                        if (asset === undefined) {
                            return <EmptyMessage message="Resource not found!" />
                        }
                        return (
                            <>
                                <AssetConversationsCardContent
                                    asset={asset}
                                    onModalClose={close}
                                    selectedConvoId={selectedConvoId}
                                    setSelectedConvoId={setSelectedConvoId}
                                />
                                {modal}
                            </>
                        )
                    }}
                </Route>
                <Route path={ClientAssetRoutes.INFO}>
                    {() => {
                        if (asset === undefined) {
                            return <EmptyMessage message="Resource not found!" />
                        }
                        return <AssetInfoCardContent asset={asset} isPartner={true} isCoowner={false} />
                    }}
                </Route>
                <Redirect to={createClientAssetRoute(ClientAssetRoutes.INFO, { assetId: selectedAsset })} />
            </Switch>
        </ButlerrCard>
    )
}

export default ClientAssets;
