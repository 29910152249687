import { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useUserMutations, useUserVerification } from "../../../services/user.service";
import BootstrapSpinner from "../../utils/BootstrapSpinner";
import { useProfileCompeletion } from "./OnboardingModal";
import { useAuth0 } from "@auth0/auth0-react";

const VerificationPage = () => {
    const { logout } = useAuth0();
    const { goNextPage } = useProfileCompeletion();

    const { data: status } = useUserVerification();

    const emailMutation = useUserMutations('SEND_VERIFY_EMAIL');
    const [ emailTimeout, setEmailTimeout ] = useState<number>();

    const handleResendEmail = () => {
        emailMutation.mutate(undefined, {
            onSuccess: () => {
                const TIMER_LENGTH = 30;
                setEmailTimeout(TIMER_LENGTH);
                
                const timer = setInterval(() => {
                    setEmailTimeout((remainingSeconds) => {
                        if (remainingSeconds && remainingSeconds > 0) {
                            return remainingSeconds - 1;
                        }
                        clearInterval(timer);
                        return undefined;
                    });
                }, 1000);
            },
            onError: () => {
                setEmailTimeout(undefined)
            }
        });
    }

    useEffect(() => {
        if (status?.email_verified) {
            goNextPage();
        }
    }, [status, goNextPage])

    if (status === undefined) {
        return (
            <Row>
                <Col>
                    <div className="px-3 py-5 d-flex align-items-center justify-content-center">
                        <BootstrapSpinner
                            style={{
                                animationDuration: '0.85s'
                            }}
                        />
                    </div>
                </Col>
            </Row>
        )
    }

    return (
        <>
            <Row>
                <Col>
                    <h1>
                        Verify your email!
                    </h1>
                    <p className="text-muted">You will need to verify your email to complete registration</p>
        
                    <div className="px-3 py-5 d-flex align-items-center justify-content-around">
                        <h6>Follow the link sent to your email to proceed.</h6>
                    </div>

                    <p className="text-muted">
                        An email has been sent to <span className="fw-semibold">{status.email}</span> with a link to verify your account.
                        If you have not received the email after a few minutes, please check your spam folder.
                    </p>
                </Col>
            </Row>
            
            <Row className="mb-3">
                <Col className="d-flex justify-content-between align-items-center">
                    <Button
                        className="px-4 py-1"
                        variant="primary"
                        disabled={emailTimeout !== undefined || emailMutation.isLoading}
                        onClick={handleResendEmail}
                    >
                        {emailTimeout ? 'Email sent!' : 'Resend Email'}
                        {
                            emailTimeout !== undefined && (
                                <small>&nbsp;({emailTimeout}s)</small>
                            )
                        }
                    </Button>
                    <Button
                        variant="outline-primary"
                        className="px-4 py-1"
                        onClick={() => logout({ returnTo: window.location.origin })}
                    >
                        Log out
                    </Button>
                </Col>
            </Row>
            {
                emailMutation.error !== null && (
                    <Row className="mb-3">
                        <Col className="text-danger text-wrap small">
                            {emailMutation.error.message}
                        </Col>
                    </Row>
                )
            }
        </>
    );
};
export default VerificationPage;