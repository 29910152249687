import { useCallback, useMemo } from 'react';
import { Column } from 'react-table';
import { Butlerr } from '../../../types/butlerr';
import { ButlerrAssetCashflowType } from '../../../types/butlerr-enums';
import { formatCurrency, formatDate } from '../../utils/HelperFunctions';
import LineClampText from '../../utils/LineClampText';
import ReactTable from '../../utils/ReactTable';

interface AssetCashflowTableInterface {
    assetId: number;
    cashflows: Butlerr.Asset.Cashflow[];
    isLoading: boolean;
    onModalOpen: (cashflow?: Butlerr.Asset.Cashflow | undefined) => void;
}

const AssetCashflowTable = ({ cashflows, isLoading, onModalOpen }: AssetCashflowTableInterface) => {
    // edit modal
    const onEdit = useCallback(
        (cashflow: Butlerr.Asset.Cashflow) => {
            onModalOpen(cashflow);
        },
        [onModalOpen]
    );

    // columns
    const columns = useMemo<Column<Butlerr.Asset.Cashflow>[]>(
        () => [
            {
                Header: 'date',
                accessor: 'ascf_date',
                minWidth: 121,
                Cell: ({ value }) => {
                    return <>{formatDate(value?.toString())}</>;
                },
            },
            {
                Header: 'category',
                accessor: 'ascf_type',
                minWidth: 121,
                Cell: ({ value }) => {
                    return (
                        <LineClampText
                            maxLines={1}
                            style={{ maxWidth: '139px' }}
                            className="mb-0"
                            title={
                                value
                                    ? ButlerrAssetCashflowType[
                                          value as keyof typeof ButlerrAssetCashflowType
                                      ]
                                    : undefined
                            }
                        >
                            {value
                                ? ButlerrAssetCashflowType[
                                      value as keyof typeof ButlerrAssetCashflowType
                                  ]
                                : '-'}
                        </LineClampText>
                    );
                },
            },
            {
                Header: 'description',
                accessor: 'ascf_desc',
                minWidth: 157,
                Cell: ({ value }) => (
                    <LineClampText className="mb-0" title={value ?? undefined} maxLines={1}>
                        {value ?? '-'}
                    </LineClampText>
                ),
            },
            {
                Header: 'amount',
                accessor: 'ascf_amt',
                minWidth: 123,
                Cell: ({ row, value }) => {
                    const cashflow = row.original;
                    const sign = Math.sign(value);
                    const amount = Math.abs(value);
                    return `${sign === 1 ? '' : '-'}${formatCurrency(amount, {
                        currency: cashflow.ascf_currency,
                    })}`;
                },
            },
            // {
            //     Header: 'status',
            //     accessor: 'ascf_status',
            //     minWidth: 113,
            //     Cell: ({ value }) => {
            //         return (
            //             <span className={value === 1 ? 'text-success' : 'text-secondary'}>
            //                 {value === 1 ? 'Verified' : 'Pending'}
            //             </span>
            //         );
            //     },
            // },
            // {
            //     Header: 'document',
            //     accessor: 'doc_name',
            //     minWidth: 144,
            //     Cell: ({ row }) => {
            //         let name = row.original.doc_name;
            //         if (!name) return "-"

            //         if (row.original.doc_format) {
            //             name += `.${row.original.doc_format}`
            //         }
            //         return name;
            //     },
            // },
        ],
        []
    );

    return (
        <>
            <ReactTable
                data={cashflows}
                columns={columns}
                isLoading={isLoading}
                placeholderMessage="No cashflow record found for the selected month and year."
                Row={(props, data) => (
                    <tr
                        {...props}
                        className="cursor-pointer"
                        onClick={onEdit.bind(undefined, data)}
                    />
                )}
            />
        </>
    );
};

export default AssetCashflowTable;
