import { Butlerr } from '../../../types/butlerr';
import * as yup from 'yup';
import {
    formCloseHandler,
} from '../../utils/HelperFunctions';
import { Transition } from 'react-transition-group';
import { Formik } from 'formik';
import { Form, Modal, Image, Row, Col } from 'react-bootstrap';
import { createPartnerRoute, PartnerRoutes } from '../Work';
import { useHistory } from 'react-router-dom';
import template1 from '../../../assets/article-template1.jpeg';
import template2 from '../../../assets/article-template2.jpeg';
import template3 from '../../../assets/article-template3.jpeg';

interface FormValues {
    arti_templateID: number;
}

interface CreatePartnerModalProps {
    show: boolean;
    onModalClose: () => void;
    initialValue?: Butlerr.Article;
}

const ArticleTemplatesModal = ({ show, onModalClose, initialValue }: CreatePartnerModalProps) => {

    const history = useHistory();
    const handleClose = async () => {onModalClose()};
    const validationSchema = yup.object({});
    const onSubmit = () => {};

    const initialValues: FormValues = {
        arti_templateID: initialValue?.arti_templateID ?? 0
    }

    return (
        <>
            <Transition in={show} timeout={{ exit: 300 }} unmountOnExit>
                {() => (
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        {({ dirty, values, setFieldValue }) => (
                            <Form noValidate>
                                <Modal
                                    show={show}
                                    onHide={formCloseHandler.bind(undefined, handleClose, dirty)}
                                    scrollable
                                    size="lg"
                                    centered
                                >
                                    <Modal.Header>
                                        <Modal.Title>Pick a template for your article</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Row>
                                            <Col className='d-flex flex-column justify-content-center align-items-center'>
                                                <small className='fw-bold text-secondary text-uppercase'>Classic/Default</small>
                                                <Image src={template1} className="rounded-4" style={{maxHeight: '280px', maxWidth: '230px'}}
                                                    onClick={() => {
                                                        history.push(createPartnerRoute(PartnerRoutes.CREATEARTICLE, { 'templateId': '0' }));
                                                        onModalClose();}} />
                                               
                                            </Col>
                                            <Col className='d-flex flex-column justify-content-center align-items-center'>
                                                <small className='fw-bold text-secondary text-uppercase'>Template 2</small>
                                                <Image src={template2} className="rounded-4" style={{maxHeight: '280px', maxWidth: '230px'}}
                                                    onClick={() => {
                                                        history.push(createPartnerRoute(PartnerRoutes.CREATEARTICLE, { 'templateId': '1' }));
                                                        onModalClose();}} />
                                               
                                            </Col>
                                            <Col className='d-flex flex-column justify-content-center align-items-center'>
                                                <small className='fw-bold text-secondary text-uppercase'>Template 3</small>
                                                <Image src={template3} className="rounded-4" style={{maxHeight: '280px', maxWidth: '230px'}}
                                                    onClick={() => {
                                                        history.push(createPartnerRoute(PartnerRoutes.CREATEARTICLE, { 'templateId': '2' }));
                                                        onModalClose();}}
                                                    />
                                                
                                            </Col>
                                        </Row>
                                    </Modal.Body>
                                </Modal>
                            </Form>
                        )}
                    </Formik>
                )}
            </Transition>
        </>
    );
};


export default ArticleTemplatesModal;
