import WorkProfileInfo from './WorkProfileInfo';
import WorkProfileSetup from './WorkProfileSetup';

interface WorkProfileProps {
    wkpfId: number;
}

const WorkProfile = ({ wkpfId }: WorkProfileProps) => {
    if (wkpfId === -1) return <WorkProfileSetup />;

    return <WorkProfileInfo wkpfId={wkpfId} />;
};

export default WorkProfile;
