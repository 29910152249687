import { Butlerr } from '../types/butlerr';
import { assetBaseRoute } from './asset.service';
import useButlerrAPI, { ButlerrMutationOptions, useButlerrMutation } from './useButlerrAPI';

export const InsuranceQueries = {
    INSURANCES: (assetId: number) => ['assets', assetId, 'insurances'] as const,
};

export function useInsurances(assetId: number) {
    return useButlerrAPI<Butlerr.Asset.Insurance[]>(
        InsuranceQueries.INSURANCES(assetId),
        `${assetBaseRoute}/${assetId}/insurances/`
    );
}

type InsuranceMutationInterface = Omit<Butlerr.Asset.Insurance, keyof Butlerr.Document.File>

const InsuranceMutations = {
    CREATE: ({
        asst_id,
        ...insurance
    }: Omit<InsuranceMutationInterface, 'insu_id'>): ButlerrMutationOptions => ({
        url: `${assetBaseRoute}/${asst_id}/insurances/`,
        method: 'POST',
        requestBody: insurance,
        queryKey: InsuranceQueries.INSURANCES(asst_id),
    }),
    EDIT: ({
        asst_id,
        insu_id,
        ...insurance
    }: InsuranceMutationInterface): ButlerrMutationOptions => ({
        url: `${assetBaseRoute}/${asst_id}/insurances/${insu_id}`,
        method: 'PUT',
        requestBody: insurance,
        queryKey: InsuranceQueries.INSURANCES(asst_id),
    }),
    DELETE: ({
        asst_id,
        insu_id,
    }: Pick<InsuranceMutationInterface, 'asst_id' | 'insu_id'>): ButlerrMutationOptions => ({
        url: `${assetBaseRoute}/${asst_id}/insurances/${insu_id}`,
        method: 'DELETE',
        queryKey: InsuranceQueries.INSURANCES(asst_id),
    }),
};

export function useInsuranceMutations<Key extends keyof typeof InsuranceMutations>(
    key: Key
) {
    //You won't get type suggestions in the mutate function without this assertion
    const mutationFn = InsuranceMutations[key] as (
        params: Parameters<typeof InsuranceMutations[Key]>[0]
    ) => ButlerrMutationOptions;
    return useButlerrMutation(mutationFn);
}
