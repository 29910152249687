import React from 'react';
import { Table, TableProps } from 'react-bootstrap';
import { TableRowProps, TableState, usePagination, useSortBy, useTable, Column, useFlexLayout, PluginHook } from 'react-table';
import { ArrowDropUp, ArrowDropDown } from '@mui/icons-material';
import classNames from 'classnames';
import IconButton from './IconButton';
import { TableBodySkeleton } from './TableSkeleton';
import styles from './utils.module.css';
import { ReactComponent as TablePlaceHolder } from '../../assets/placeholder_table.svg';

interface ReactTableInterface<D extends object> extends TableProps {
    data: D[];
    columns: Column<D>[];
    isLoading?: boolean;
    /**
     * Default='false'. Pass in 'true' if last column is an actions column. This will make the column not be sortable and will have a fixed width.
     */
    actions?: boolean;
    /**
     * Enable pagination
     */
    pagination?: boolean;
    /**
     * Placeholder message for no items
     */
    placeholderMessage?: string;
    /**
     * Placeholder element for no items, takes precedence over `placeholderMessage`
     */
    placeholderEle?: React.ReactNode;
    /**
     * Default='undefined'. Render factory for table row
     */
    Row?: (
        props: TableRowProps & { children: React.ReactNode },
        data: D
    ) => JSX.IntrinsicElements['tr'];
    /**
     * Display number of rows at the bottom, Setting this to `false` will also hide the pagination nav buttons
     */
    showRowCount?: boolean;
    flexLayout?: boolean;
}

const ReactTable = <D extends object>({
    actions = false,
    pagination = true,
    placeholderMessage,
    placeholderEle,
    Row,
    showRowCount = true,
    flexLayout = false,
    ...props
}: ReactTableInterface<D>) => {
    const initialState: Partial<TableState<D>> | undefined = {
        pageSize: 10, // change to 1 or 2 for demo
    };

    const columns = props.columns;
    const data = props.data;
    
    const plugins : PluginHook<D>[] = [];
    if (pagination) {
        plugins.push(usePagination);
    }
    if (flexLayout) {
        plugins.push(useFlexLayout);
    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        page,
        //canPreviousPage,
        //canNextPage,
        pageOptions, // array of available pages
        pageCount, // total number of pages
        gotoPage,
        state: { pageIndex },
    } = useTable(
        {
            columns,
            data,
            initialState,
            autoResetPage: false,
        },
        useSortBy,
        ...plugins
    );

    return (
        <>
            <Table borderless hover responsive className={props.className} {...getTableProps()}>
                {/* <colgroup>
                {columns.map((_, index) => (
                    <col key={index} span={1}></col>
                ))}
            </colgroup> */}
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr
                            className="text-secondary text-uppercase"
                            {...headerGroup.getHeaderGroupProps()}
                        >
                            {headerGroup.headers.map((column, idx) => {
                                const defaultProps = column.getHeaderProps(
                                    (actions && idx === headerGroup.headers.length - 1) ||
                                        column.disableSortBy
                                        ? undefined
                                        : column.getSortByToggleProps()
                                )

                                return (
                                    <th
                                        {...defaultProps}
                                        title={
                                            (actions && idx === headerGroup.headers.length - 1) ||
                                            column.disableSortBy
                                                ? undefined
                                                : 'Sort'
                                        }
                                        className={classNames({
                                            'tcol-actions':
                                                actions && idx === headerGroup.headers.length - 1,
                                            'cursor-pointer':
                                                !column.disableSortBy &&
                                                !(actions && idx === headerGroup.headers.length - 1),
                                        }, defaultProps.className, styles.tableHeader)}
                                        style={{
                                            minWidth: column.minWidth,
                                            ...defaultProps.style
                                        }}
                                    >
                                        {column.render('Header')}
                                        <span
                                            className={
                                                // only apply the styles if sorted
                                                column.isSorted ? undefined : styles.tableHeaderIcon
                                            }
                                        >
                                            {/* Add a sort direction indicator */}
                                            {!column.disableSortBy &&
                                                !(actions && idx === headerGroup.headers.length - 1) &&
                                                (column.isSortedDesc ? (
                                                    <ArrowDropDown fontSize="small" />
                                                ) : (
                                                    <ArrowDropUp fontSize="small" />
                                                ))}
                                        </span>
                                    </th>
                                )
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {(!data || props.isLoading) ? (
                        <TableBodySkeleton cellCount={columns.length} />
                    ) :
                    data.length === 0 && props.isLoading === false ? (
                        <tr>
                            <td colSpan={columns.length}>
                                {placeholderEle ?? (
                                    <div className="my-5 d-flex justify-content-center align-items-center flex-column">
                                        <TablePlaceHolder />
                                        <div className="mt-3">
                                            {placeholderMessage ?? "You don't have any item."}
                                        </div>
                                    </div>
                                )}
                            </td>
                        </tr>
                    ) : (
                        (pagination ? page : rows).map((row) => {
                            prepareRow(row);

                            const props = {
                                ...row.getRowProps(),
                                children: row.cells.map((cell) => (
                                    <td {...cell.getCellProps()}>
                                        {cell.render('Cell')}
                                    </td>
                                ))
                            };

                            if (Row) {
                                return Row(props, row.original);
                            }
                            return <tr {...props} />;
                        })
                    )}
                </tbody>
            </Table>
            <div className="d-flex justify-content-between align-items-center">
                {rows.length > 0 && (
                    //put an empty span, so the pagination buttons are right aligned
                    !showRowCount ? <span></span> : (
                        <div className="small text-secondary">
                            Showing {pagination ? page.length : rows.length} of {rows.length} item
                            {rows.length > 1 ? 's' : ''}
                        </div>
                    )
                )}
                {pagination && (
                    <div className="d-flex justify-content-evenly align-items-center">
                        {pageOptions.length > 1 &&
                            pageOptions.map((key) => (
                                <IconButton
                                    key={key}
                                    label={String(key + 1)}
                                    className={pageCount !== key + 1 ? 'me-2' : undefined}
                                    style={
                                        pageIndex === key
                                            ? undefined
                                            : {
                                                backgroundColor: 'transparent',
                                                borderColor: 'var(--grey)',
                                                color: 'var(--black)',
                                            }
                                    }
                                    onClick={() => gotoPage(key)}
                                />
                            ))}
                    </div>
                )}
            </div>
        </>
    );
};

export default ReactTable;
