import { Butlerr } from '../types/butlerr';
import { partnerBaseRoute } from './partner.service';
import useButlerrAPI, { ButlerrMutationOptions, useButlerrMutation, useButlerrPaginatedQuery } from './useButlerrAPI';

export const articleQueries = {
    ARTICLE: (articleId: number) => ['work', 'articles', articleId] as const,
    ARTICLES: ['work', 'articles'] as const,
    PUBLISHED: (params: FetchPublishedArticlesOptions) => ['work', 'articles', 'published', params] as const,
};

export function useArticleById(articleId: number, enabled = true) {
    return useButlerrAPI<Butlerr.Article>(
        articleQueries.ARTICLE(articleId),
        `${partnerBaseRoute}/articles/${articleId}`,
        true,
        {
            enabled
        }
    );
}

export interface FetchArticlesOptions {
    filter?: "draft" | "published";
    search?: string;
    limit?: number;
}

export function useArticles(params: FetchArticlesOptions = {}, enabled = true) {
    const { limit = 11, ...rest } = params;

    const options = {
        limit, ...rest
    }

    const searchParams = new URLSearchParams();
    for (const key in options) {
        const value = options[key as keyof typeof options]
        if (value !== undefined) {
            searchParams.set(key, String(value))
        }
    }

    return useButlerrPaginatedQuery<Butlerr.Article[]>(
        articleQueries.ARTICLES,
        `${partnerBaseRoute}/articles?${searchParams.toString()}`,
        true,
        { enabled }
    );
}

export interface FetchPublishedArticlesOptions {
    following_only?: boolean;
    editable_only?: boolean;
    all?: boolean;

    sort?: "newest" | "trending" | "popular";

    search?: string;
    limit?: number;
}

export function usePublishedArticles(params: FetchPublishedArticlesOptions = {}, enabled = true) {
    const { limit = 9, ...rest } = params;

    const options = {
        limit, ...rest
    }

    const searchParams = new URLSearchParams();
    for (const key in options) {
        const value = options[key as keyof typeof options]
        if (value !== undefined) {
            searchParams.set(key, String(value))
        }
    }
    return useButlerrPaginatedQuery<Butlerr.Article[]>(
        articleQueries.PUBLISHED(params),
        `${partnerBaseRoute}/articles/published?${searchParams.toString()}`,
        true,
        { enabled }
    );
}

interface CreateArticleInterface {
    wkpf_id: number;
    arti_imageKey: File | undefined;
    arti_title: string | null;
    arti_text: string;
    arti_synopsis: string | null;
    arti_keywords: string | null;
    arti_templateID: number;
    arti_status: 0 | 1;
}

interface EditArticleInterface {
    arti_id: number;
    arti_imageKey: File | undefined;
    arti_title: string | null;
    arti_text: string;
    arti_synopsis: string | null;
    arti_keywords: string | null;
    arti_templateID: 0 | 1 | 2;
    arti_status: 0 | 1;
}
interface DeleteReactArticleInterface {
    arti_id: number;
}

interface ReactArticleInterface {
    arti_id: number;
    atra_reaction: 0 | 1 | null;
}

const ArticleMutations = {
    CREATE_ARTICLE: ({ wkpf_id, ...article}: CreateArticleInterface): ButlerrMutationOptions => ({
        url: `${partnerBaseRoute}/${wkpf_id}/articles`,
        method: 'POST',
        requestBody: article,
        queryKey: articleQueries.ARTICLES,
        useFormData: true
    }),

    EDIT_ARTICLE: ({arti_id, ...article}: EditArticleInterface): ButlerrMutationOptions => ({
        url: `${partnerBaseRoute}/articles/${arti_id}`,
        method: 'PUT',
        requestBody: article,
        useFormData: true,
        queryKey: articleQueries.ARTICLE(arti_id),
    }),
    DELETE_ARTICLE: (arti_id: number): ButlerrMutationOptions => ({
        url: `${partnerBaseRoute}/articles/${arti_id}`,
        method: 'DELETE',
        queryKey: articleQueries.ARTICLES,
    }),
    REACT_ARTICLE: ({arti_id, ...data}: ReactArticleInterface): ButlerrMutationOptions => ({
        url: `${partnerBaseRoute}/articles/${arti_id}/react`,
        method: 'POST',
        requestBody: data,
        queryKey: articleQueries.ARTICLE(arti_id),
    }),
    DELETE_REACT_ARTICLE: ({arti_id}: DeleteReactArticleInterface): ButlerrMutationOptions => ({
        url: `${partnerBaseRoute}/articles/${arti_id}/react`,
        method: 'DELETE',
        queryKey: articleQueries.ARTICLE(arti_id),
    }),
    ADD_VIEW_ARTICLE: (arti_id: number): ButlerrMutationOptions => ({
        url: `${partnerBaseRoute}/articles/${arti_id}`,
        method: 'POST',
        queryKey: articleQueries.ARTICLE(arti_id),
    }),
}

export function useArticleMutations<Key extends keyof typeof ArticleMutations>(key: Key) {
    //You won't get type suggestions in the mutate function without this assertion
    const mutationFn = ArticleMutations[key] as (
        params: Parameters<typeof ArticleMutations[Key]>[0]
    ) => ButlerrMutationOptions;
    return useButlerrMutation(mutationFn);
}

export function initialiseArticleObject(article: Butlerr.Article) {
    
    const requiredFields = ['wkpf_id', 'user_id', 'arti_date', 'arti_title', 'arti_text', 'arti_imagekey', 'arti_synopsis', 'arti_keywords', 'arti_lastedited', 'arti_status', 'arti_templateID'];
    const originalArticle: Butlerr.Article = requiredFields.reduce(
        (accumulator, currentValue) => {
            return {
                ...accumulator,
                ...{
                    [currentValue]: article[currentValue as keyof typeof article],
                },
            };
        },
        {} as Butlerr.Article
    );

    return originalArticle;
}