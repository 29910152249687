import {
    AttachmentOutlined,
    SendRounded,
    DeleteOutlineRounded,
    FileDownloadOutlined,
    ForumOutlined,
    MoreVert,
    SaveOutlined,
} from '@mui/icons-material';
import classNames from 'classnames';
import { Formik, FormikHelpers } from 'formik';
import React, { Fragment, HTMLAttributes, useEffect, useMemo, useRef, useState } from 'react';
import { Card, Dropdown, Form } from 'react-bootstrap';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import useModal from '../../../hooks/useModal';

import {
    useChatMsgs,
    useChatMsgAttach,
    useChatMsgMutations,
    useChatMsgAttachSave,
} from '../../../services/chat.service';
import { ConversationQueries } from '../../../services/conversation.service';
import { useButlerrUser } from '../../../services/user.service';
import { Butlerr } from '../../../types/butlerr';
import { DocumentThumbnailPlaceholder } from '../../documents/DocsUI';
import { createSocialRoute, SocialRoutes } from '../../social/SocialHome';
import UserAvatar from '../../social/UserAvatar';
import BootstrapSpinner from '../../utils/BootstrapSpinner';
import DestructiveModal from '../../utils/DestructiveModal';
import ErrorPage from '../../utils/ErrorPage';
import {
    extractFileExtension,
    formatDate,
    formatDateByTimeDifference
} from '../../utils/HelperFunctions';
import IconButton from '../../utils/IconButton';
import LineClampText from '../../utils/LineClampText';
import styles from '../../assets/asset.module.css';
import SocialChatsModal from './ChatsModal';
import { useDownloadWithPostToken } from '../../../services/useButlerrAPI';
import { DocumentSelectModal } from '../../documents/DocsModal';
import EmojiPickerButton from '../../ui/EmojiPicker';

interface SocialChatContentProps {
    selectedChat: Butlerr.Social.Chats;
}

const SocialChatContent = ({
    selectedChat,
}: SocialChatContentProps) => {
    const chatId = selectedChat.ascv_id;

    // messages
    const { data: messages = [], isLoading, error } = useChatMsgs(chatId);

    const queryClient = useQueryClient();
    useEffect(() => {
        // update unread count notification
        queryClient.invalidateQueries(ConversationQueries.ASSETS_UNREAD_COUNT, { exact: true });
    });

    // delete message mutation
    const { mutateAsync: deleteMessage } = useChatMsgMutations(
        'DELETE'
    );

    // delete modal
    const [selectedMessage, setSelectedMessage] = useState<Butlerr.Asset.ConversationMessage>();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const onDeleteShow = (message: Butlerr.Asset.ConversationMessage) => {
        setSelectedMessage(message);
        setShowDeleteModal(true);
    };
    const onDeleteClose = () => {
        setShowDeleteModal(false);
    };
    const onDeleteConfirm = async () => {
        await deleteMessage({ chatId, messageId: selectedMessage?.acms_id ?? -1 });
    };

    //chats modal
    const [showDetailsModal, setShowDetailsModal] = useState(false);

    const onModalClose = () => {
        setShowDetailsModal(false);
    };

    const onShowDetails = () => {
        setShowDetailsModal(true);
    };

    if (error !== null) return <ErrorPage status={error.status} message={error.message} />;

    if (isLoading) return <BootstrapSpinner />;

    return (
        <>
            {/* header */}
            <div
                title={selectedChat.ascv_subject}
                className="border-bottom bg-light d-flex cursor-pointer flex-column fw-bold"
                style={{ padding: '0.75rem' }}
                onClick={onShowDetails}
            >
                <LineClampText className="m-0 fs-6" maxLines={1}>
                    {selectedChat.ascv_subject}
                </LineClampText>
                {selectedChat.acmb_leave === null && (<LineClampText className="mb-0 fs-15 text-muted fw-normal" maxLines={1}>
                    {selectedChat.members.map((m) => m.user_socialhandle).join(', ')}
                </LineClampText>)}
            </div>

            {/* messages container */}
            <MessagesContainer
                chat={selectedChat}
                messages={messages}
                onDeleteShow={onDeleteShow}
            />

            <DestructiveModal
                show={showDeleteModal}
                onClose={onDeleteClose}
                onConfirm={onDeleteConfirm}
                title="Delete message?"
                description="Any attachment will be deleted as well."
            />
            <SocialChatsModal
                show={showDetailsModal}
                onClose={onModalClose}
                initialValue={selectedChat}
            />
        </>
    );
};

interface MessagesContainerProps {
    chat: Butlerr.Social.Chats;
    messages: Butlerr.Asset.ConversationMessage[];
    onDeleteShow: (message: Butlerr.Asset.ConversationMessage) => void;
}

const MessagesContainer = ({
    chat,
    messages,
    onDeleteShow,
}: MessagesContainerProps) => {
    const chatId = chat.ascv_id;
    const lastAccess = chat.acla_lastAccess;

    const { data: user } = useButlerrUser();

    // tabs
    const tabs = [
        [ForumOutlined, 'Messages'],
        [AttachmentOutlined, 'Attachments'],
    ] as const;
    const [selectedView, setSelectedView] = useState<typeof tabs[number][1]>('Messages');
    const handleViewChange = (label: typeof tabs[number][1]) => {
        setSelectedView(label);
    };

    // forward to documents modal
    const { mutate: forwardToDocuments } = useChatMsgAttachSave();
    const handleAttachSaveSubmit = async (destinationFolder: Butlerr.Document.Folder) => {
        forwardToDocuments({
            chatId,
            messageId: selectedAttachment?.acms_id ?? -1,
            attachmentId: selectedAttachment?.acma_id ?? -1,
            doct_parentid: destinationFolder.doct_id,
        });
    };
    const [saveModal, openSaveModal] = useModal(DocumentSelectModal, {
        onFolderSelect: handleAttachSaveSubmit,
        selectBtnText: 'Save',
        user
    });
    const [selectedAttachment, setSelectedAttachment] =
        useState<Butlerr.Asset.ConversationMessageAttachment>();
    const handleAttachSave = (attachment: Butlerr.Asset.ConversationMessageAttachment) => {
        setSelectedAttachment(attachment);
        openSaveModal();
    };

    // reset view upon another chat click
    useEffect(() => {
        setSelectedView('Messages');
    }, [chatId]);

    const { data: dbUser } = useButlerrUser();

    //scroll to element
    const firstUnreadIndex = useMemo(() => {
        return messages.findIndex((message) => {
            if (message.acms_datetime > lastAccess && message.acms_fromuser !== dbUser?.user_id) {
                return true;
            }
            return false;
        });
    }, [dbUser?.user_id, lastAccess, messages]);

    return (
        <>
            <div className={'w-100 d-flex justify-content-center ' + styles.convoMsgsContainer}>
                {tabs.map(([Icon, label]) => (
                    <IconButton
                        key={label}
                        transparent
                        border={false}
                        Icon={Icon}
                        label={label}
                        style={{
                            backgroundColor: selectedView === label ? 'transparent' : undefined,
                        }}
                        className={classNames({
                            'flex-grow-1 rounded-0 text-dark': true,
                            'bg-light': selectedView !== label,
                        })}
                        onClick={() => handleViewChange(label)}
                    />
                ))}
            </div>

            {/* messages */}
            {selectedView === 'Messages' && (
                <>
                    <div
                        className={
                            'p-3 overflow-auto flex-grow-1 flex-shrink-1 ' +
                            styles.convoMsgsContainer
                        }
                    >
                        {messages.length === 0 ? (
                            <div className="h-100 d-flex justify-content-center align-items-center">
                                No message yet.
                            </div>
                        ) : (
                            <>
                                {messages.map((message, idx) => {
                                    return (
                                        <Fragment key={message.acms_id}>
                                            {idx === firstUnreadIndex && (
                                                <div
                                                    ref={(ele) => {
                                                        if (ele) ele.scrollIntoView();
                                                    }}
                                                >
                                                    <hr />
                                                </div>
                                            )}
                                            <Message
                                                key={message.acms_id}
                                                chatId={chatId}
                                                message={message}
                                                onDeleteShow={onDeleteShow}
                                                handleAttachSave={handleAttachSave}
                                            />
                                        </Fragment>
                                    );
                                })}
                                {firstUnreadIndex === -1 && (
                                    <div
                                        ref={(ele) => {
                                            if (ele) ele.scrollIntoView();
                                        }}
                                    />
                                )}
                            </>
                        )}
                    </div>
                    {/* send message container */}
                    <div className="border-top p-3 bg-light">
                        {chat.acmb_leave ? (
                            <span>You have left the chat.</span>
                        ) : (
                            <SendMessageContainer
                                chatId={chatId}
                            />
                        )}
                    </div>
                </>
            )}

            {selectedView === 'Attachments' && (
                <div
                    className={
                        'p-3 overflow-auto flex-grow-1 flex-shrink-1 ' + styles.convoMsgsContainer
                    }
                >
                    {(() => {
                        const filteredMessages = messages.filter(
                            (message) => message.attachments.length > 0
                        );

                        return (
                            <>
                                {filteredMessages.length === 0 ? (
                                    <div className="h-100 d-flex justify-content-center align-items-center">
                                        No attachment yet.
                                    </div>
                                ) : (
                                    <>
                                        {filteredMessages.map((message) => (
                                            <Message
                                                key={message.acms_id}
                                                chatId={chatId}
                                                message={message}
                                                onDeleteShow={onDeleteShow}
                                                attachmentOnly
                                                handleAttachSave={handleAttachSave}
                                            />
                                        ))}
                                    </>
                                )}
                            </>
                        );
                    })()}
                </div>
            )}

            {saveModal}
        </>
    );
};

interface MessageProps {
    chatId: number;
    message: Butlerr.Asset.ConversationMessage;
    onDeleteShow: (message: Butlerr.Asset.ConversationMessage) => void;
    attachmentOnly?: boolean;
    handleAttachSave: (attachment: Butlerr.Asset.ConversationMessageAttachment) => void;
}

const Message = ({
    chatId,
    message,
    onDeleteShow,
    attachmentOnly = false,
    handleAttachSave,
}: MessageProps) => {
    const messageId = message.acms_id;

    const { data: user } = useButlerrUser();
    const downloadAttachment = useDownloadWithPostToken();

    return (
        <div
            className={classNames('my-2 d-flex', {
                'flex-row-reverse': user?.user_id === message.user_id,
                'justify-content-center': message.user_id === 0
            })}
        >
            {message.user_id !== 0 && (
                <Link
                    to={createSocialRoute(SocialRoutes.USER_PROFILE, { id: message.user_id })}
                    className={classNames({
                        'me-3': user?.user_id !== message.user_id,
                        'ms-3': user?.user_id === message.user_id,
                    })}
                    style={{ height: 'fit-content' }}
                >
                    <UserAvatar
                        roundedCircle
                        width={32}
                        height={32}
                        className="shadow-sm"
                        user={message}
                    />
                </Link>
            )}
            <div style={{ maxWidth: '70%' }}>
                <Card
                    className={classNames(
                        'px-3 py-2 border-0 shadow-sm small',
                        styles.convoMsgCard
                    )}
                >
                    <div className="d-flex justify-content-between align-items-center">
                        {message.user_id !== 0 && (
                            <div>
                                <Link
                                    to={createSocialRoute(SocialRoutes.USER_PROFILE, {
                                        id: message.user_id,
                                    })}
                                    className={classNames(styles.messageUsername, styles.linkUnderline)}
                                    title={message.user_socialhandle}
                                >
                                    {message.user_socialhandle}
                                </Link>
                                <span
                                    className={classNames(
                                        'mx-3 text-muted small',
                                        styles.linkUnderline
                                    )}
                                    title={
                                        formatDate(message.acms_datetime, 'long', 'long', true) ??
                                        undefined
                                    }
                                >
                                    {formatDateByTimeDifference(message.acms_datetime)}
                                </span>
                            </div>
                        )}

                        {!attachmentOnly && user?.user_id === message.user_id && (
                            <Dropdown className={styles.dropdown}>
                                <Dropdown.Toggle as="span" bsPrefix=" ">
                                    <IconButton
                                        transparent
                                        border={false}
                                        Icon={MoreVert}
                                        iconHtmlColor="var(--bs-gray)"
                                    />
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="p-0">
                                    <Dropdown.Item onClick={() => onDeleteShow(message)}>
                                        Delete
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        )}
                    </div>

                    {!attachmentOnly && (
                        <span style={{ whiteSpace: 'pre-wrap' }} className={classNames( {'text-muted': message.user_id === 0} )}>{message.acms_msg}</span>
                    )}

                    <div>
                        {message.attachments.map((attachment) => (
                            <AttachmentItem
                                key={attachment.acma_id}
                                attachId={attachment.acma_id}
                                name={`${attachment.acma_name}.${attachment.acma_format}`}
                                thumbnail={
                                    attachment.acma_thumbnail === null ? (
                                        <DocumentThumbnailPlaceholder
                                            className={styles.convoMsgAttachPlaceholder}
                                            format={attachment.acma_format}
                                        />
                                    ) : (
                                        <AttachmentThumbnail
                                            chatId={chatId}
                                            messageId={messageId}
                                            attachmentId={attachment.acma_id}
                                            format={attachment.acma_format}
                                        />
                                    )
                                }
                                onDownload={() =>
                                    downloadAttachment(
                                        'CHATS',
                                        chatId,
                                        messageId,
                                        attachment.acma_id
                                    )
                                }
                                onSave={() => handleAttachSave(attachment)}
                            />
                        ))}
                    </div>
                </Card>
            </div>
        </div>
    );
};

interface AttachmentItemProps extends HTMLAttributes<HTMLElement> {
    attachId: number;
    name: string;
    thumbnail: React.ReactNode;
    onDownload: () => void;
    onSave: () => void;
}

function AttachmentItem({
    attachId,
    name,
    thumbnail,
    onDownload,
    onSave,
    ...props
}: AttachmentItemProps) {
    return (
        <div
            className={
                'mt-3 d-flex align-items-center justify-content-between ' +
                styles.convoMsgAttachItem
            }
            {...props}
        >
            <div className="d-flex align-items-center">
                {thumbnail}
                <span className="ms-2 mb-0 text-break">{name}</span>
            </div>

            <Dropdown className="ms-auto">
                <Dropdown.Toggle as="span" bsPrefix=" ">
                    <IconButton
                        title="More"
                        transparent
                        border={false}
                        Icon={MoreVert}
                        iconHtmlColor="var(--bs-gray)"
                    />
                </Dropdown.Toggle>

                <Dropdown.Menu className="p-0">
                    <Dropdown.Item onClick={onDownload} className="d-flex align-items-center">
                        <FileDownloadOutlined
                            className="me-1"
                            fontSize="small"
                            htmlColor="var(--bs-secondary)"
                        />
                        Download
                    </Dropdown.Item>

                    <Dropdown.Item onClick={onSave} className="d-flex align-items-center">
                        <SaveOutlined
                            className="me-1"
                            fontSize="small"
                            htmlColor="var(--bs-secondary)"
                        />
                        Save to Document Store
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
}

interface ThumbnailProps {
    chatId: number;
    messageId: number;
    attachmentId: number;
    format: string;
}

function AttachmentThumbnail({
    chatId,
    messageId,
    attachmentId,
    format,
}: ThumbnailProps) {
    const [src] = useChatMsgAttach(
        chatId,
        messageId,
        attachmentId,
        'thumbnail'
    );

    if (!src) {
        return (
            <DocumentThumbnailPlaceholder
                className={styles.convoMsgAttachPlaceholder}
                format={format}
            />
        );
    }

    return (
        <img src={src} className={styles.convoMsgAttachThumbnail} draggable={false} alt=""></img>
    );
}

interface AttachmentFile {
    url: string;
    file: File;
}

type MessageForm = {
    acms_msg: string;
};

// convert file to a base64 url
export const getFileWithUrl = (file: File): Promise<AttachmentFile> => {
    return new Promise((res, rej) => {
        // dont convert if not image
        if (!file.type.startsWith('image/')) {
            return res({ file, url: '' });
        }

        const reader = new FileReader();
        reader.onload = (e) => {
            if (e.target?.result) {
                res({
                    file,
                    url: e.target.result as string,
                });
            } else rej();
        };
        reader.onerror = (e) => rej(e);
        reader.readAsDataURL(file);
    });
};

const calculateTextAreaRows = (ele: HTMLTextAreaElement) => {
    const textareaLineHeight = 19; // set in css (px)
    const minRows = 1;
    const maxRows = 5;

    ele.rows = minRows; // reset number of rows in textarea

    // scrollHeight is the height of an element
    // ~~ floors the number (~ is called `Bitwise NOT`)
    let currentRows = ~~(ele.scrollHeight / textareaLineHeight);

    // scrollTop is the number of pixels the content of an element has scrolled
    if (currentRows > maxRows) {
        currentRows = maxRows;
        ele.scrollTop = ele.scrollHeight;
    }
    ele.rows = currentRows;
};

interface SendMessageContainerProps {
    chatId: number;
}

const SendMessageContainer = ({ chatId }: SendMessageContainerProps) => {
    const fileInput = useRef<HTMLInputElement>(null);
    const [files, setFiles] = useState<AttachmentFile[]>([]);

    const messageInput = useRef<HTMLTextAreaElement>(null);

    const {
        mutate: createMessage,
        isLoading,
        error,
    } = useChatMsgMutations('CREATE');

    const initialValues: MessageForm = {
        acms_msg: '',
    };

    const schema = yup.object().shape({
        acms_msg: yup.string().max(512, ({ max }) => `Maximum ${max} characters`),
    });

    const onSubmit = ({ acms_msg }: MessageForm, { resetForm }: FormikHelpers<MessageForm>) => {
        createMessage(
            {
                chatId,
                acms_msg,
                attachments: files.map((a) => a.file),
            },
            {
                onSuccess: () => {
                    setFiles([]);
                    resetForm();
                    calculateTextAreaRows(messageInput?.current!); // reset textarea rows
                },
            }
        );
    };

    return (
        <Formik initialValues={initialValues} validationSchema={schema} onSubmit={onSubmit}>
            {({ setFieldValue, submitForm, values, errors }) => (
                <Form noValidate>
                    {files.length > 0 && (
                        <div className="d-flex mb-2">
                            {files.map((file, index) => {
                                return (
                                    <div
                                        key={file.file.name + file.file.size}
                                        title={file.file.name}
                                        className={
                                            styles.convoMsgAttachPreview + ' position-relative'
                                        }
                                    >
                                        {file.url === '' ? (
                                            <DocumentThumbnailPlaceholder
                                                className="w-100 h-100"
                                                format={extractFileExtension(file.file.name)}
                                            />
                                        ) : (
                                            <img src={file.url} alt="" />
                                        )}
                                        <button
                                            title={`Remove "${file.file.name}"`}
                                            className={
                                                styles.convoMsgAttachRemoveBtn +
                                                ' d-flex justify-content-center align-items-center'
                                            }
                                            onClick={() => {
                                                setFiles((oldFiles) =>
                                                    oldFiles.filter((_, i) => i !== index)
                                                );
                                            }}
                                        >
                                            <DeleteOutlineRounded />
                                        </button>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                    <div className="d-flex align-items-center">
                        <input
                            ref={fileInput}
                            type="file"
                            className="d-none"
                            multiple
                            onChange={async (e) => {
                                // TODO: add validation for file name, size (?)
                                if (e.target.files !== null) {
                                    const files = Array.from(e.target.files);
                                    //turn files into AttachmentFile objects
                                    const previewFiles = await Promise.allSettled(
                                        files.map((f) => getFileWithUrl(f))
                                    );
                                    setFiles((prev) => {
                                        return [
                                            ...prev,
                                            ...previewFiles.flatMap((i) =>
                                                i.status === 'fulfilled' ? i.value : []
                                            ),
                                        ].slice(0, 30);
                                    });
                                } else {
                                    setFiles([]);
                                }
                                // reset value to allow reading the same file
                                if (fileInput.current) {
                                    fileInput.current.value = '';
                                }
                            }}
                        />
                        <IconButton
                            title="Attach a file"
                            Icon={AttachmentOutlined}
                            transparent
                            border={false}
                            iconHtmlColor="var(--black)"
                            className="me-2"
                            onClick={() => fileInput.current?.click()}
                            disabled={files.length > 30}
                        />
                        <EmojiPickerButton
                            className='me-2 border-0'
                            onEmojiSelect={(emoji) => {
                                setFieldValue('acms_msg', values.acms_msg + emoji.native)
                            }}
                        />
                        <Form.Control
                            ref={messageInput}
                            as="textarea"
                            size="sm"
                            rows={1}
                            placeholder="Type a message"
                            className={styles.convoMsgInput}
                            disabled={isLoading}
                            value={values.acms_msg}
                            onChange={(e) => {
                                setFieldValue('acms_msg', e.target.value.trimStart());
                                calculateTextAreaRows(e.target as HTMLTextAreaElement);
                            }}
                        />
                        <IconButton
                            title="Send"
                            Icon={SendRounded}
                            transparent
                            border={false}
                            iconHtmlColor="var(--primary)"
                            className="ms-2"
                            onClick={() => {
                                if (values.acms_msg || files.length > 0) {
                                    submitForm();
                                }
                            }}
                            disabled={isLoading || files.length > 30}
                        />
                    </div>

                    <div style={{ marginLeft: '2.125rem' }}>
                        {error !== null || errors.acms_msg ? (
                            <small className="text-danger">
                                {error?.message || errors.acms_msg}
                            </small>
                        ) : null}
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default SocialChatContent;
