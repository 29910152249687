import React from 'react';
import { Row, Button } from 'react-bootstrap';
import { useProfileCompeletion } from './OnboardingModal';

const FinalSocialPage = () => {
    const { onClose } = useProfileCompeletion();

    return (
        <>
            <Row className="mb-3">
                <h1 className="text-muted">
                    Let's get <span className="text-primary">Socialising!</span>
                </h1>

                <p>
                    Join our experts and companies on our social platform, follow their channels and
                    start learning. Make sure you also subscribe to the various news channels to get
                    the latest market updates. Happy learning!
                </p>
            </Row>

            <div>
                <Button onClick={onClose}>Proceed</Button>
            </div>
        </>
    );
};

export default FinalSocialPage;
