import classNames from 'classnames';
import React, { useState } from 'react';
import { Card, Image } from 'react-bootstrap';
import WorkProfileSetupCompany from './WorkProfileSetupCompany';
import WorkProfileSetupStaff from './WorkProfileSetupStaff';
import businessOwnerIcon from '../../../assets/icons/work-business-owner.png';
import staffIcon from '../../../assets/icons/work-staff.png';

export type IProfileType = 'C' | 'S';

const WorkProfileSetup = () => {
    const [profileType, setProfileType] = useState<IProfileType>();

    return (
        <div>
            {profileType === undefined ? (
                <>
                    <h3>I am a...</h3>

                    <div className="d-flex">
                        <CardItem
                            title="Business Owner"
                            iconSrc={businessOwnerIcon}
                            titleText="Business Owner"
                            descriptionText="I would like to create a work profile."
                            handleClick={() => setProfileType('C')}
                        />
                        <CardItem
                            className="ms-3"
                            title="Staff"
                            iconSrc={staffIcon}
                            titleText="Staff"
                            descriptionText="I would like to join a work profile."
                            handleClick={() => setProfileType('S')}
                        />
                    </div>
                </>
            ) : (
                <>
                    <Card body>
                        {profileType === 'C' && (
                            <WorkProfileSetupCompany setProfileType={setProfileType} />
                        )}

                        {profileType === 'S' && (
                            <WorkProfileSetupStaff setProfileType={setProfileType} />
                        )}
                    </Card>
                </>
            )}
        </div>
    );
};

interface CardItemProps extends React.HTMLAttributes<HTMLButtonElement> {
    iconSrc: string;
    titleText: string;
    descriptionText: string;
    handleClick: () => void;
    className?: string;
}

const CardItem = ({
    iconSrc,
    titleText,
    descriptionText,
    handleClick,
    className,
    ...props
}: CardItemProps) => {
    return (
        <Card
            as="button"
            className={classNames('card-clickable', className && className)}
            onClick={handleClick}
            {...props}
        >
            <Card.Body>
                <div className="mb-2 d-flex flex-column justify-content-center align-items-center">
                    <Image className="mb-3" src={iconSrc} width={80} height={80} />
                    <h6 className="mb-0 ms-1">{titleText}</h6>
                </div>

                <span className="text-muted">{descriptionText}</span>
            </Card.Body>
        </Card>
    );
};

export default WorkProfileSetup;
