import { SvgIconComponent } from '@mui/icons-material';
import React from 'react';

interface PageHeaderInterface {
    Icon?: React.FC<React.SVGProps<SVGSVGElement>> | SvgIconComponent;
    title: string;
    rightSide?: JSX.Element;
}

const PageHeader = ({ Icon, title, rightSide }: PageHeaderInterface) => {
    return (
        <div className="d-flex justify-content-between align-items-center text-uppercase">
            <div className="h-100 me-4 page-header d-flex align-items-center justify-content-start text-white fw-bold position-relative">
                {Icon && (
                    <Icon
                        className="me-2"
                        fontSize="small"
                    ></Icon>
                )}
                <span>{title}</span>
            </div>
            {rightSide && (
                <div className="h-100">
                    {rightSide}
                </div>
            )}
        </div>
    );
};

export default PageHeader;
