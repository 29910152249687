import { Butlerr } from '../../../types/butlerr';
import { monthDiff } from '../../utils/HelperFunctions';

export function checkAdjustedInterestRate(loan: Butlerr.Asset.Loan) {
    let r = loan.active_adj_interestrate ?? loan.loan_interestrate; // interest rate (%)
    let t = loan.loan_tenure; // term (months)

    if (loan.active_adj_date) {
        const loanStartDate = new Date(loan.loan_start).setHours(0, 0, 0, 0);
        const adjStartDate = new Date(loan.active_adj_date).setHours(0, 0, 0, 0);

        t = loan.loan_tenure - monthDiff(new Date(loanStartDate), new Date(adjStartDate));
    }
    r = r / 100; // convert from percentage to decimal
    t = t / 12; // convert from months to years

    return { r, t };
}

// const payperiodMap = {
//     A: 1,
//     Q: 4,
//     M: 12,
// };

export function calculateMonthlyRepaymentAmount(loan: Butlerr.Asset.Loan) {
    const P = Number(loan.loan_principal); // principal amount
    const n = 12; // number of payments per year (payperiodMap[loan.loan_payperiod])
    const offset = Number(loan.loan_offset ?? 0);
    const type = loan.loan_type;

    const { r, t } = checkAdjustedInterestRate(loan);

    let paymentAmount = 0;

    switch (type) {
        case 'I': // interest only
            paymentAmount = (P - offset) * (r / n);
            break;
        case 'P': // interest and principal
            // check if interest rate is 0%
            if (r === 0) {
                paymentAmount = P / (t * 12); // convert from years to months
            } else {
                paymentAmount =
                    (P * (r / n) * Math.pow(1 + r / n, n * t)) / (Math.pow(1 + r / n, n * t) - 1);
            }
            break;
        default:
            paymentAmount = 0;
            break;
    }

    return Number(paymentAmount.toFixed(2));
}

export function calculateMonthlyRepaymentAmountDetailed(loan: Butlerr.Asset.Loan) {
    const amt = Math.abs(calculateMonthlyRepaymentAmount(loan));
    //console.log('calculated monthly amt: ' + amt);

    let interestAmount = 0;
    let principalAmount = 0;

    if (loan.loan_type === 'I') {
        interestAmount = amt;
    } else if (loan.loan_type === 'P') {
        const { r } = checkAdjustedInterestRate(loan);
        const outstandingAmount = Number(loan.loan_outstanding ?? 0);
        const interestOffsetAmount = Number(loan.loan_offset ?? 0);
        interestAmount = (outstandingAmount - interestOffsetAmount) * (r / 12); // Interest amount = (Outstanding amount - Interest Offset amount) * (Interest rate / Number of payments per year)
        principalAmount = Math.abs(amt) - interestAmount; // Principal = Monthly - Interest
    }

    return [interestAmount.toFixed(2), principalAmount.toFixed(2)];
}
