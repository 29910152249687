import { useMemo } from "react";
import useButlerrAPI, { useButlerrPaginatedQuery, ButlerrMutationOptions, useButlerrMutation } from "./useButlerrAPI";
import { Butlerr } from "../types/butlerr";

export interface FetchNewsOptions {
    query?: string;
    sort?: "new" | "trending" | "bookmark_date";
    bookmarked_only?: boolean;
    subscribed_only?: boolean;
}

export const NewsQuery = {
    NEWS: (params: FetchNewsOptions) => ['news', params] as const,
    CHANNELS: (params: FetchChannelsOptions) => ['channels', params] as const,
    BOOKMARKED_NEWS: ['news', 'bookmarked'] as const,
    HISTORY: ['news', 'history'] as const
}

export function useNews(params: FetchNewsOptions) {
    const { data: bookmarked = [] } = useButlerrAPI<number[]>(NewsQuery.BOOKMARKED_NEWS, `/api/news/bookmarked`);

    const searchParams = new URLSearchParams();
    for (const key in params) {
        const value = params[key as keyof typeof params]
        if (value !== undefined) {
            searchParams.set(key, String(value))
        }
    }

    const query = useButlerrPaginatedQuery<Butlerr.News[]>(NewsQuery.NEWS(params), `/api/news?${searchParams.toString()}`);

    const news = useMemo(() => {
        const data = query.data?.pages.flatMap((d) => d.result)
        //remove duplicates
        return data?.filter((d, i) => data.findIndex(d2 => d.news_id === d2.news_id) === i)
            .map((n) => ({
                ...n,
                bookmarked: bookmarked.includes(n.news_id)
            }))

    }, [bookmarked, query.data?.pages])

    return {
        news, query
    }
}

export interface FetchChannelsOptions {
    query?: string;
    limit?: number;
}

export function useChannels(params: FetchChannelsOptions = {}) {
    const searchParams = new URLSearchParams();
    for (const key in params) {
        const value = params[key as keyof typeof params]
        if (value !== undefined) {
            searchParams.set(key, String(value))
        }
    }

    return useButlerrAPI<Butlerr.News.Channel[]>(NewsQuery.CHANNELS(params), `/api/news/channels?${searchParams.toString()}`, true, { refetchOnMount: 'always' });
}
export function useNewsHistory() {

    const { data: bookmarked = [] } = useButlerrAPI<number[]>(NewsQuery.BOOKMARKED_NEWS, `/api/news/bookmarked`);

    const query = useButlerrAPI<Butlerr.News.History[]>(NewsQuery.HISTORY, `/api/news/clicked`, true, { refetchOnMount: "always" });

    //add bookmark status
    const history = useMemo(() => query.data?.map((n) => ({
        ...n,
        bookmarked: bookmarked.includes(n.news_id)
    })), [bookmarked, query.data])

    return {
        history,
        query
    }
}

export const NewsMutations = {
    BOOKMARK: (newsId: number) : ButlerrMutationOptions<number[]> => ({
        url: `/api/news/${newsId}/bookmark`,
        method: 'POST',
        updater: [{
            queryKey: NewsQuery.BOOKMARKED_NEWS,
            optimistic: true,
            action: (prev: number[]) => [...prev ?? [], newsId]
        }],
        refetchData: true
    }),
    REMOVE_BOOKMARK: (newsId: number) : ButlerrMutationOptions<number[]> => ({
        url: `/api/news/${newsId}/bookmark`,
        method: 'DELETE',
        updater: [{
            queryKey: NewsQuery.BOOKMARKED_NEWS,
            optimistic: true,
            action: (prev: number[]) => prev?.filter(n => n !== newsId) ?? []
        }],
        refetchData: true
    }),
    ADD_HISTORY: (newsId: number) : ButlerrMutationOptions<number[]> => ({
        url: `/api/news/${newsId}/read`,
        method: 'POST',
        queryKey: NewsQuery.HISTORY
    })
};

export function useNewsMutations<Key extends keyof typeof NewsMutations>(key: Key) {
    //You won't get type suggestions in the mutate function without this assertion
    const mutationFn = NewsMutations[key] as (params: Parameters<typeof NewsMutations[Key]>[0]) => ButlerrMutationOptions;
    return useButlerrMutation(mutationFn);
}

export const ChannelMutations = {
    SUBSCRIBE: (channelId: number) : ButlerrMutationOptions<Butlerr.News[]> => ({
        url: `/api/news/channels/${channelId}/subscribe`,
        method: 'POST',
        queryKey: NewsQuery.NEWS({ subscribed_only: true }),
        refetchData: "all",
        updater: {
            queryKey: NewsQuery.CHANNELS({}),
            optimistic: true,
            partialMatch: true,
            action: (prev) => prev?.map((ch) => {
                if (ch.ncha_id !== channelId) return ch;
                return {
                    ...ch,
                    user_subscribe: 1
                }
            }) ?? []
        }
    }),
    UNSUBSCRIBE: (channelId: number) : ButlerrMutationOptions<Butlerr.News[]> => ({
        url: `/api/news/channels/${channelId}/subscribe`,
        method: 'DELETE',
        queryKey: NewsQuery.NEWS({ subscribed_only: true }),
        refetchData: "all",
        updater: {
            queryKey: NewsQuery.CHANNELS({}),
            optimistic: true,
            partialMatch: true,
            action: (prev) => prev?.map((ch) => {
                if (ch.ncha_id !== channelId) return ch;
                return {
                    ...ch,
                    user_subscribe: 0
                }
            }) ?? []
        }
    })
};

export function useChannelMutations<Key extends keyof typeof ChannelMutations>(key: Key) {
    //You won't get type suggestions in the mutate function without this assertion
    const mutationFn = ChannelMutations[key] as (params: Parameters<typeof ChannelMutations[Key]>[0]) => ButlerrMutationOptions;
    return useButlerrMutation(mutationFn);
}
