import { useState } from 'react';

import { Butlerr } from '../../../types/butlerr';
import AssetKPI from '../AssetKPI';
import { formatCurrency, getCurrencySign } from '../../utils/HelperFunctions';
import { calculateMonthlyRepaymentAmountDetailed } from './AssetLoansFunctions';
import LineClampText from '../../utils/LineClampText';
import {
    LoansKpiLvrInterface,
    useLoansKpiLVR,
    useLoansKpiTotalRepayment,
} from '../../../services/assetloan.service';

type LoanToValueRatioModes = '%' | '$';

interface AssetLoansKPIInterface {
    loans: Butlerr.Asset.Loan[];
    isLoading: boolean;
    asset: Butlerr.Asset;
}

const AssetLoansKPI = ({ loans, isLoading, asset }: AssetLoansKPIInterface) => {
    const assetId = asset.asst_id;

    // Filter loans to loans that have started (today >= loan start date) and have not ended (today <= loan end date)
    const today = new Date().setHours(0, 0, 0, 0);
    const activeLoans = loans.filter(
        (loan) =>
            today >= new Date(loan.loan_start).setHours(0, 0, 0, 0) && // today should be after the start date of the loan
            today <=
                new Date(
                    new Date(loan.loan_start).getFullYear(),
                    new Date(loan.loan_start).getMonth() + loan.loan_tenure,
                    new Date(loan.loan_start).getDate()
                ).setHours(0, 0, 0, 0) // and today should be before the end date of the loan
    );

    // loan to value ratio menu
    const [loanToValueRatioMode, setLoanToValueRatioMode] = useState<LoanToValueRatioModes>('%');
    const handleLoanToValueRatioModeChange = (mode: LoanToValueRatioModes) => {
        setLoanToValueRatioMode(mode);
    };

    // loan to valuation ratio
    const { data: kpiLVR, isLoading: isKpiLvrLoading } = useLoansKpiLVR(assetId);

    // total repayment
    const { data: kpiTotalRepayment, isLoading: isKpiTotalRepaymentLoading } =
        useLoansKpiTotalRepayment(assetId);

    const kpis = [
        {
            title: `Loan to Valuation Ratio (${
                loanToValueRatioMode === '%'
                    ? loanToValueRatioMode
                    : getCurrencySign(asset.asst_currency)
            })`,
            dropdownItems: [
                { label: 'Percentage (%)', value: '%' as LoanToValueRatioModes },
                {
                    label: `Amount (${getCurrencySign(asset.asst_currency)})`,
                    value: '$' as LoanToValueRatioModes,
                },
            ],
            dropdownValue: loanToValueRatioMode,
            dropdownChange: handleLoanToValueRatioModeChange,
            data:
                isLoading || isKpiLvrLoading || kpiLVR === undefined ? undefined : (
                    <LoanToValueRatioData
                        asset={asset}
                        kpiLVR={kpiLVR}
                        mode={loanToValueRatioMode}
                    />
                ),
            tooltipText:
                'Calculated as a ratio or percentage, of the amount of loan taken for the property, over the latest valuation of the property (or the purchase price if valuation is unavailable).',
        },
        {
            title: `Total Monthly Repayment`,
            data: isLoading ? undefined : totalMonthlyRepaymentsData(activeLoans, asset),
            tooltipText: 'Calculates the total monthly repayment for all the active loans.',
        },
        {
            title: 'Repayment to Date',
            data: isKpiTotalRepaymentLoading
                ? undefined
                : `${getCurrencySign(asset.asst_currency)}${formatCurrency(
                      kpiTotalRepayment?.amount ?? 0
                  )}`,
            tooltipText:
                'Total amount of actual repayment made to date based on the cashflow records.',
        },
    ];
    return <AssetKPI data={kpis} xs={1} xl={3} />;
};

interface LoanToValueRatioDataInterface {
    asset: Butlerr.Asset;
    kpiLVR: LoansKpiLvrInterface;
    mode: LoanToValueRatioModes;
}

const LoanToValueRatioData = ({ asset, kpiLVR, mode }: LoanToValueRatioDataInterface) => {
    return (
        <>
            {mode === '%' && <>{String(kpiLVR.percentage) + '%'}</>}

            {mode === '$' && (
                <>
                    {(() => {
                        const displayText = `${getCurrencySign(
                            asset.asst_currency
                        )}${formatCurrency(kpiLVR.amount)}`;

                        return (
                            <LineClampText title={displayText} maxLines={1} className="mb-0">
                                {displayText}
                            </LineClampText>
                        );
                    })()}
                </>
            )}
        </>
    );
};

const totalMonthlyRepaymentsData = (activeLoans: Butlerr.Asset.Loan[], asset: Butlerr.Asset) => {
    // 1. For each loan, calculate the interest and principal based on the loan type
    // and sum all interest amount
    // and sum all principal amount
    let monthlyInterest = 0;
    let monthlyPrincipal = 0;

    for (let i = 0; i < activeLoans.length; i++) {
        const [interest, principal] = calculateMonthlyRepaymentAmountDetailed(activeLoans[i]);
        //console.log('interest: ' + interest);
        //console.log('principal: ' + principal);
        monthlyInterest += Number(interest);
        monthlyPrincipal += Number(principal);
    }

    // 2. Calculate monthly amount
    const monthlyAmount = monthlyInterest + monthlyPrincipal;

    return `${getCurrencySign(asset.asst_currency)}${formatCurrency(monthlyAmount)}`;
};

export default AssetLoansKPI;
