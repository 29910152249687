import { Card } from "react-bootstrap"
import useModal from "../../../hooks/useModal"
import { useButlerrUser, useUserMutations } from "../../../services/user.service"
import IconButton from "../../utils/IconButton"
import UserAvatar from "../UserAvatar"
import { Link, useHistory } from "react-router-dom"
import classNames from "classnames"
import LineClampText from "../../utils/LineClampText"
import { createSocialRoute, SocialRoutes } from "../SocialHome"
import { formatDateByTimeDifference } from "../../utils/HelperFunctions"
import EmptyMessage from "../../utils/EmptyMessage"
import socialStyles from '../social.module.css';
import { Butlerr } from "../../../types/butlerr"
import { CreateChatWSubjectModal } from "../chats/ChatsModal";
import { FollowListModal } from "./UserListModal"
import InfiniteScroller from "../../ui/InfiniteScroller"

export interface UserListProps {
    users?: Butlerr.UserInfo[];

    isLoading: boolean;
    hasNextPage?: boolean;
    fetchNextPage: () => void;

    allowFollowerFollowingModal?: boolean;
    emptyMessage?: string;
}
const UserList = ({ users, isLoading, hasNextPage, fetchNextPage, allowFollowerFollowingModal = true, emptyMessage } : UserListProps) => {

    if (users === undefined) return <UserListSkeleton />
    if (users.length === 0) {
        return (
            <EmptyMessage
                className="fs-5 text-center p-5"
                message={emptyMessage ?? "Nothing to see here"}
            />
        )
    }
    return (
        <InfiniteScroller
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            isLoading={isLoading}
            loadingEle={<UserListSkeleton />}
        >
            {
                users.map((user) => (
                    <UserListItem
                        key={user.user_id}
                        user={user}
                        allowFollowerFollowingModal={allowFollowerFollowingModal}
                    />
                ))
            }
        </InfiniteScroller>
    )
}
export default UserList;

interface UserListItemProps {
    user: Butlerr.UserInfo;
    allowFollowerFollowingModal: boolean;
}
const UserListItem = ({ user, allowFollowerFollowingModal } : UserListItemProps) => {

    //User Query
    const { data: ownUser } = useButlerrUser();

    const { mutate: follow, isLoading: fl } = useUserMutations('FOLLOW');
    const { mutate: unfollow, isLoading: ul } = useUserMutations('UNFOLLOW');

    const isMutating = fl || ul;

    const isFollowing = ownUser?.following.includes(user.user_id) === true;
    const isFollower = ownUser?.follower.includes(user.user_id) === true;

    //Message state
    const history = useHistory();

    const [ chatModal, startChat ] = useModal(CreateChatWSubjectModal, {
        user,
        onClose: (chatId?: number) => {
            if (chatId) history.push(SocialRoutes.CHATS + "/" + chatId);
        }
    })

    const button = ownUser !== undefined && user.user_id !== ownUser.user_id && (
        <>
            <IconButton
                className={classNames(
                    'py-0 mb-1 border-2 border-primary rounded-pill',
                    socialStyles.followBtn,
                    !isFollowing && `bg-transparent ${socialStyles.grey}`,
                )}
                label={ isFollowing ? 'Following' : 'Follow' }
                title={ isFollowing ? 'Following' : 'Follow' }
                disabled={isMutating}
                onClick={() => {
                    if (isFollowing) unfollow(user.user_id);
                    else follow(user.user_id);
                }}
            />
            <IconButton
                className={"py-0 border-2 border-primary rounded-pill " + socialStyles.followBtn}
                title="Message"
                label="Message"
                onClick={startChat}
            />
        </>
    )

    //Follower & following modals
    //follower & following modals
    const [ followerModal, openFollowers ] = useModal(FollowListModal, {
        initialSelectedView: "Follower" as const,
        user_id: user.user_id,
        follower: user.follower,
        following: user.following
    })
    const [ followingModal, openFollowing ] = useModal(FollowListModal, {
        initialSelectedView: "Following" as const,
        user_id: user.user_id,
        follower: user.follower,
        following: user.following
    })

    return (
        <Card key={user.user_id} className="mb-2">
            <Card.Body className="d-flex justify-content-between align-items-center p-3">
                <div className="flex-shrink-0 me-3">
                    <Link
                        to={createSocialRoute(SocialRoutes.USER_PROFILE, { id: user.user_id })}
                        className="link-underline text-dark"
                    >
                        <UserAvatar
                            user={user}
                            width={50}
                            height={50}
                        />
                    </Link>
                </div>
                <div className="flex-grow-1 d-flex flex-column flex-wrap justify-content-between text-break">
                    <div className="d-flex align-items-center">
                        <Link
                            to={createSocialRoute(SocialRoutes.USER_PROFILE, { id: user.user_id })}
                            className="link-underline text-dark"
                        >
                            <h6 className="mb-0">
                                {user.user_socialhandle}
                            </h6>
                        </Link>
                        { chatModal }

                        {
                            isFollower && (
                                <span className="ms-2 text-muted fw-semibold small">
                                    follows you
                                </span>
                            )
                        }
                    </div>
                    { user.user_socialoneliner && <LineClampText maxLines={2} className="mb-0 text-muted">{user.user_socialoneliner}</LineClampText> }

                    <small className="mt-2 d-flex flex-wrap">
                        {user.follower !== undefined &&
                            <span>
                                <button
                                    className="p-0 border-0 text-start bg-transparent link-underline text-dark"
                                    onClick={openFollowers}
                                    disabled={!allowFollowerFollowingModal}
                                    style={{ opacity: 1 }}
                                >
                                    {user.follower}
                                    &nbsp;Follower{user.follower > 1 && 's'}
                                </button>
                                &nbsp;&bull;&nbsp;
                            </span>
                        }
                        {user.following !== undefined &&
                            <span>
                                <button
                                    className="p-0 border-0 text-start bg-transparent link-underline text-dark"
                                    onClick={openFollowing}
                                    disabled={!allowFollowerFollowingModal}
                                    style={{ opacity: 1 }}
                                >
                                    {user.following}
                                    &nbsp;Following
                                </button>
                                &nbsp;&bull;&nbsp;
                            </span>
                        }
                        {user.user_lastaccess &&
                            <span>Last seen: {formatDateByTimeDifference(user.user_lastaccess)} &bull;&nbsp;</span>
                        }
                        {user.user_joined &&
                            <span>Joined: {formatDateByTimeDifference(user.user_joined)}</span>
                        }
                    </small>
                    { followerModal }
                    { followingModal }
                </div>
                <div className="d-flex flex-column align-items-stretch">
                    { button }
                </div>
            </Card.Body>
        </Card>
    )
}

export function UserListSkeleton() {
    return (
        <>
            {Array<null>(3).fill(null).map((_, idx) => (
                <div key={idx} className="shadow-sm rounded-4 mb-2 d-flex justify-content-between align-items-center bg-light p-3">
                    <div
                        className="rounded-circle skeleton-box me-2"
                        style={{ width: 50, height: 50 }}
                    ></div>
                    <div className="flex-grow-1 align-self-stretch py-1 d-flex flex-column justify-content-between">
                        <div
                            className="skeleton-box"
                            style={{ width: '60%' }}
                        ></div>
                        <div
                            className="skeleton-box"
                            style={{ width: '30%', height: 14 }}
                        ></div>
                    </div>
                </div>
            ))}
        </>
    )
}