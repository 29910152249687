import { Add, Done, SendRounded } from '@mui/icons-material';
import { Formik } from 'formik';
import { useState } from 'react';
import { Col, Container, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Transition } from 'react-transition-group';
import * as yup from 'yup';
import { BaseModalProps } from '../../../hooks/useModal';

import { useSocialChatMutations } from '../../../services/chat.service';
import { useButlerrUser } from '../../../services/user.service';
import { Butlerr } from '../../../types/butlerr';
import BootstrapModalFooter from '../../utils/BootstrapModalFooter';
import DestructiveModal from '../../utils/DestructiveModal';
import { BootstrapFormLabel, BootstrapInput } from '../../utils/FormikBootstrapInputs';
import { formCloseHandler } from '../../utils/HelperFunctions';
import UserDropdownButton from '../../utils/UserDropdownButton';

type ChatForm = {
    ascv_subject: string;
    members: Butlerr.Social.Chats['members'];
};

interface SocialChatsModalProps {
    show: boolean;
    onClose: () => void;
    initialValue?: Butlerr.Social.Chats;
}

const SocialChatsModal = ({
    show,
    onClose: onModalClose,
    initialValue,
}: SocialChatsModalProps) => {
    const {
        mutate: createChat,
        isLoading: isCreateLoading,
        error: createError,
    } = useSocialChatMutations('CREATE');
    const {
        mutate: updateChat,
        isLoading: isEditLoading,
        error: editError,
    } = useSocialChatMutations('UPDATE');

    const { mutateAsync: deleteChat } = useSocialChatMutations('DELETE');

    // delete modal
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedChatId, setSelectedChatId] = useState<number>();

    const onDelete = (chatId: number) => {
        setSelectedChatId(chatId);
        setShowDeleteModal(true);
    };

    const onDeleteClose = () => {
        setShowDeleteModal(false);
    };

    const history = useHistory();
    // /home/chats/id
    const onDeleteConfirm = async () => {
        await deleteChat({ chatId: selectedChatId! });
        history.push(`/home/chats`);
        formCloseHandler(handleClose);
    };

    const isLoading = initialValue === undefined ? isCreateLoading : isEditLoading;
    const error = initialValue === undefined ? createError : editError;

    const { data: userdb } = useButlerrUser();

    const handleClose = () => {
        if (error !== null) error.message = '';
        if (!isLoading) onModalClose();
    };

    const initialValues: ChatForm = {
        ascv_subject: initialValue?.ascv_subject ?? '',
        members: initialValue?.members ?? [],
    };

    const validationSchema = yup.object().shape({
        ascv_subject: yup
            .string()
            .required('Required')
            .max(160, ({ max }) => `Maximum ${max} characters`),
        members: yup.array(),
    });

    const onSubmit = (values: ChatForm) => {
        if (initialValue === undefined) {
            createChat(
                { ...values },
                {
                    onSuccess: (e) => {
                        history.push(`/home/chats/${e.response.data.ascv_id}`);
                        handleClose();
                    },
                }
            );
        } else {
            if (initialValue.ascv_id !== undefined)
                updateChat(
                    {

                        chatId: initialValue.ascv_id,
                        ...values,
                    },
                    {
                        onSuccess: (e) => { 
                            history.push(`/home/chats/${e.response.data.ascv_id}`);
                            handleClose();
                        },
                    }
                );
        }
    };

    return (
        <>
            <Transition in={show} timeout={{ exit: 300 }} unmountOnExit>
                {() => (
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        {({ submitForm, setFieldValue, dirty, values }) => (
                            <Form noValidate>
                                <Modal
                                    show={show}
                                    onHide={formCloseHandler.bind(undefined, handleClose, dirty)}
                                    size="lg"
                                    backdrop={isLoading ? 'static' : undefined}
                                    keyboard={!isLoading}
                                    centered
                                >
                                    <Modal.Header>
                                        <Container>
                                            <Row>
                                                <Modal.Title>
                                                    {initialValue === undefined
                                                        ? 'Create a chat'
                                                        : 'Edit chat'}
                                                </Modal.Title>
                                            </Row>
                                        </Container>
                                    </Modal.Header>

                                    <Modal.Body>
                                        <Container>
                                            <Row className="mb-3">
                                                <Form.Group as={Col} lg>
                                                    <Form.Label className="fw-bold text-secondary">
                                                        Member(s){' '}
                                                        <span className="text-danger">*</span>
                                                    </Form.Label>
                                                    <InputGroup>
                                                        <Form.Control
                                                            placeholder="Select members from users to chat"
                                                            readOnly
                                                            value={
                                                                initialValue === undefined
                                                                    ? // create
                                                                    `You ${values.members.length > 0
                                                                        ? `, ${values.members
                                                                            .map(
                                                                                (m) =>
                                                                                    m.user_socialhandle
                                                                            )
                                                                            .join(', ')}`
                                                                        : ''
                                                                    }`
                                                                    : // edit
                                                                    `You${values.members.filter(
                                                                        (m) =>
                                                                            !(
                                                                                m.user_id ===
                                                                                userdb?.user_id
                                                                            )
                                                                    ).length > 0
                                                                        ? `, ${values.members
                                                                            .filter(
                                                                                (m) =>
                                                                                    !(
                                                                                        m.user_id ===
                                                                                        userdb?.user_id
                                                                                    )
                                                                            )
                                                                            .map(
                                                                                (m) =>
                                                                                    m.user_socialhandle
                                                                            )
                                                                            .join(', ')}`
                                                                        : ''
                                                                    }`
                                                            }
                                                        />

                                                        <UserDropdownButton
                                                            selectedUsers={values.members}
                                                            disabled={isLoading}
                                                            setSelectedUsers={(selectedUsers) => {
                                                                setFieldValue(
                                                                    'members',
                                                                    selectedUsers
                                                                );
                                                            }}
                                                        />
                                                    </InputGroup>

                                                    <Row className="mt-3">
                                                        <Form.Group as={Col} lg>
                                                            <BootstrapInput
                                                                as="textarea"
                                                                rows="2"
                                                                id="ascv_subject"
                                                                label="What is this chat about?"
                                                                placeholder="Chat Subject"
                                                                aria-label="Subject"
                                                                disabled={isLoading}
                                                            />
                                                        </Form.Group>
                                                    </Row>
                                                </Form.Group>
                                            </Row>
                                        </Container>
                                    </Modal.Body>

                                    <BootstrapModalFooter
                                        error={error}
                                        deleteBtnProps={{
                                            onClick: () => onDelete(initialValue?.ascv_id!),
                                            disabled: isLoading,
                                            className:
                                                initialValue === undefined || initialValue.user_id !== userdb?.user_id ? 'd-none' : '',
                                        }}
                                        cancelBtnProps={{
                                            onClick: () => formCloseHandler(handleClose, dirty),
                                            disabled: isLoading,
                                        }}
                                        confirmBtnProps={{
                                            Icon: initialValue === undefined ? Add : Done,
                                            label:
                                                initialValue === undefined
                                                    ? isLoading
                                                        ? 'Creating...'
                                                        : 'Create'
                                                    : isLoading
                                                    ? 'Saving...'
                                                    : 'Save',
                                            onClick:
                                                initialValue === undefined
                                                    ? submitForm
                                                    : !dirty
                                                    ? handleClose
                                                    : submitForm,
                                            disabled: isLoading,
                                        }}
                                    />

                                    
                                </Modal>
                            </Form>
                        )}
                    </Formik>
                )}
            </Transition>

            <DestructiveModal
                show={showDeleteModal}
                onClose={onDeleteClose}
                onConfirm={onDeleteConfirm}
                title="Delete chat?"
                description="You will lose all access and records related to this chat. Are you sure you want to delete?"
                className="modal-layer-1"
                backdropClassName="modal-layer-1"
            />
        </>
    );
};

export default SocialChatsModal;

interface CreateChatWSubjectModalProps extends BaseModalProps {
    initialSubject?: string;
    onClose: (chatId?: number) => void;
    user?: Pick<Butlerr.UserInfo, 'user_id' | 'user_socialhandle' | 'user_profile'>;
}

export const CreateChatWSubjectModal = ({ show, onClose, initialSubject, user } : CreateChatWSubjectModalProps) => {

    const [ subject, setSubject ] = useState(initialSubject ?? "");

    const { mutate: create, error, isLoading, reset } = useSocialChatMutations('CREATE');

    const handleCreate = () => {
        if (!user) return;
        create({
            ascv_subject: subject,
            members: [{
                user_id: user.user_id,
                user_socialhandle: user.user_socialhandle,
                user_profile: user.user_profile
            }]
        }, {
            onSuccess: ({ response }) => {
                onClose(response.data.ascv_id)
            }
        })
    }

    return (
        <Modal
            show={show && user !== undefined}
            onHide={onClose}
            centered
        >
            <Modal.Header>
                <Modal.Title>Start chat</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <BootstrapFormLabel htmlFor="chat-subject-form">
                    Subject
                </BootstrapFormLabel>
                <Form.Control
                    id="chat-subject-form"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    placeholder={`Let ${user?.user_socialhandle} know what this is about`}
                />
            </Modal.Body>
            <BootstrapModalFooter
                error={error}
                cancelBtnProps={{
                    onClick: () => {
                        onClose();
                        reset();
                        setSubject(initialSubject ?? "");
                    },
                    disabled: isLoading,
                }}
                confirmBtnProps={{
                    Icon: SendRounded,
                    label: isLoading ? 'Sending...' : 'Send',
                    onClick: handleCreate,
                    disabled: isLoading,
                }}
            />
        </Modal>
    )
}