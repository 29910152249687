import { Butlerr } from '../types/butlerr';
import { assetBaseRoute } from './asset.service';
import useButlerrAPI, { ButlerrMutationOptions, useButlerrMutation } from './useButlerrAPI';

export const LoanQueries = {
    // kpis
    LOANS_KPI_LVR: (assetId: number) => ['assets', assetId, 'loans', 'kpi', 'lvr'] as const,
    LOANS_KPI_TOTAL_REPAYMENT: (assetId: number) =>
        ['assets', assetId, 'loans', 'kpi', 'total_repayment'] as const,

    LOANS: (assetId: number) => ['assets', assetId, 'loans'] as const,
    LOAN_INTCHANGE: (assetId: number, loanId: number) =>
        ['assets', assetId, 'loans', loanId, 'interest-change'] as const,
    LOAN_DOCUMENTS: (assetId: number, loanId: number) =>
        ['assets', assetId, 'loans', loanId, 'documents'] as const,
};

export interface LoansKpiLvrInterface {
    amount: number;
    percentage: number;
}

export function useLoansKpiLVR(assetId: number) {
    return useButlerrAPI<LoansKpiLvrInterface>(
        LoanQueries.LOANS_KPI_LVR(assetId),
        `${assetBaseRoute}/${assetId}/loans/kpi/lvr`,
        true,
        {
            refetchOnMount: 'always',
        }
    );
}

export function useLoansKpiTotalRepayment(assetId: number) {
    return useButlerrAPI<{ amount: number }>(
        LoanQueries.LOANS_KPI_TOTAL_REPAYMENT(assetId),
        `${assetBaseRoute}/${assetId}/loans/kpi/totalRepayment`,
        true,
        {
            refetchOnMount: 'always',
        }
    );
}

export function useLoans(assetId: number) {
    return useButlerrAPI<Butlerr.Asset.Loan[]>(
        LoanQueries.LOANS(assetId),
        `${assetBaseRoute}/${assetId}/loans/`,
        true,
        {
            refetchOnMount: 'always',
        }
    );
}
export function useLoanAdjustments(assetId: number, loanId: number, enabled = true) {
    return useButlerrAPI<Butlerr.Asset.LoanInterestChange[]>(
        LoanQueries.LOAN_INTCHANGE(assetId, loanId),
        `${assetBaseRoute}/${assetId}/loans/${loanId}/adjustment`,
        true,
        { enabled }
    );
}

export function useLoanDocuments(assetId: number, loanId: number, enabled = true) {
    return useButlerrAPI<Butlerr.Document.File[]>(
        LoanQueries.LOAN_DOCUMENTS(assetId, loanId),
        `${assetBaseRoute}/${assetId}/loans/${loanId}/documents`,
        true,
        { enabled, staleTime: 0 }
    );
}

type LoanMutationInterface = Omit<
    Butlerr.Asset.Loan,
    'active_adj_interestrate' | 'active_adj_date' | 'loan_termdate'
>;

interface LoanDocForm extends Butlerr.Document.File {
    assetId: number;
    loanId: number;
}

const LoanMutations = {
    CREATE: ({
        asst_id,
        ...loan
    }: Omit<LoanMutationInterface, 'loan_id'>): ButlerrMutationOptions<Butlerr.Asset.Loan[]> => ({
        url: `${assetBaseRoute}/${asst_id}/loans/`,
        method: 'POST',
        requestBody: loan,
        updater: [
            {
                queryKey: LoanQueries.LOANS(asst_id),
                action: 'refetch',
            },
            {
                queryKey: LoanQueries.LOANS_KPI_LVR(asst_id),
                action: 'refetch',
            },
        ],
    }),
    EDIT: ({
        asst_id,
        loan_id,
        ...loan
    }: Omit<LoanMutationInterface, 'loan_outstanding'>): ButlerrMutationOptions<
        Butlerr.Asset.Loan[]
    > => ({
        url: `${assetBaseRoute}/${asst_id}/loans/${loan_id}`,
        method: 'PUT',
        requestBody: loan,
        updater: [
            {
                queryKey: LoanQueries.LOANS(asst_id),
                action: 'refetch',
            },
            {
                queryKey: LoanQueries.LOANS_KPI_LVR(asst_id),
                action: 'refetch',
            },
        ],
    }),
    TERMINATE: ({
        assetId,
        loanId,
    }: Pick<LoanDocForm, 'assetId' | 'loanId'>): ButlerrMutationOptions<Butlerr.Asset.Loan[]> => ({
        url: `${assetBaseRoute}/${assetId}/loans/${loanId}/termination`,
        method: 'PUT',
        updater: [
            {
                queryKey: LoanQueries.LOANS(assetId), // refetch from db as termination date `new Date()` might be different
                action: 'refetch',
            },
            {
                queryKey: LoanQueries.LOANS_KPI_LVR(assetId),
                action: 'refetch',
            },
        ],
    }),
    DELETE: ({
        assetId,
        loanId,
    }: {
        assetId: number;
        loanId: number;
    }): ButlerrMutationOptions<Butlerr.Asset.Loan[]> => ({
        url: `${assetBaseRoute}/${assetId}/loans/${loanId}`,
        method: 'DELETE',
        updater: [
            {
                queryKey: LoanQueries.LOANS(assetId),
                optimistic: true,
                action: (prev) => prev?.filter((l) => l.loan_id !== loanId) ?? [],
            },
            {
                queryKey: LoanQueries.LOANS_KPI_LVR(assetId),
                action: 'refetch',
            },
        ],
    }),
    ADD_DOCUMENT: ({
        assetId,
        loanId,
        ...doc
    }: LoanDocForm): ButlerrMutationOptions<Butlerr.Document.File[]> => ({
        url: `${assetBaseRoute}/${assetId}/loans/${loanId}/documents`,
        method: 'POST',
        requestBody: { doc_id: doc.doc_id },
        queryKey: LoanQueries.LOAN_DOCUMENTS(assetId, loanId),
        updater: {
            optimistic: true,
            action: (state) => [...state, doc],
        },
    }),
    REMOVE_DOCUMENT: ({
        assetId,
        loanId,
        doc_id,
    }: LoanDocForm): ButlerrMutationOptions<Butlerr.Document.File[]> => ({
        url: `${assetBaseRoute}/${assetId}/loans/${loanId}/documents/${doc_id}`,
        method: 'DELETE',
        queryKey: LoanQueries.LOAN_DOCUMENTS(assetId, loanId),
        updater: {
            optimistic: true,
            action: (state) => state.filter((d) => d.doc_id !== doc_id),
        },
    }),
};

export function useLoanMutations<Key extends keyof typeof LoanMutations>(key: Key) {
    //You won't get type suggestions in the mutate function without this assertion
    const mutationFn = LoanMutations[key] as (
        params: Parameters<typeof LoanMutations[Key]>[0]
    ) => ButlerrMutationOptions;
    return useButlerrMutation(mutationFn);
}

export interface LoanIntChgForm {
    lich_date: string;
    lich_interest: number;
    lich_doclink?: number;
}

interface LoanAdjustmentMutationInterface extends LoanIntChgForm {
    assetId: number;
    loanId: number;
    adjustmentId: number;
}

const LoanAdjustmentMutations = {
    CREATE: ({
        assetId,
        loanId,
        ...loanAdjustment
    }: Omit<LoanAdjustmentMutationInterface, 'adjustmentId'>): ButlerrMutationOptions => ({
        url: `${assetBaseRoute}/${assetId}/loans/${loanId}/adjustment`,
        method: 'POST',
        requestBody: loanAdjustment,
        queryKey: LoanQueries.LOAN_INTCHANGE(assetId, loanId),
        //Change the active interest adjustment, if any change
        updater: {
            queryKey: LoanQueries.LOANS(assetId),
            action: (state: Butlerr.Asset.Loan[]) =>
                state.map((l) => {
                    if (l.loan_id !== loanId) return l;

                    const adjStartDate = new Date(loanAdjustment.lich_date).setHours(0, 0, 0, 0);
                    /**
                     * We only need to update the active interest & date if:
                     * -The start of date of adjustment is lower than today (already started)
                     * -AND ( there's no current active OR current active is lower than new active )
                     */
                    if (
                        adjStartDate < Date.now() &&
                        (l.active_adj_date === null ||
                            new Date(l.active_adj_date).setHours(0, 0, 0, 0) < adjStartDate)
                    ) {
                        return {
                            ...l,
                            active_adj_date: new Date(loanAdjustment.lich_date).toISOString(),
                            active_adj_interestrate: loanAdjustment.lich_interest,
                        };
                    }
                    return l;
                }),
        },
    }),
    EDIT: ({
        assetId,
        loanId,
        adjustmentId,
        ...loanAdjustment
    }: LoanAdjustmentMutationInterface): ButlerrMutationOptions => ({
        url: `${assetBaseRoute}/${assetId}/loans/${loanId}/adjustment/${adjustmentId}`,
        method: 'PUT',
        requestBody: loanAdjustment,
        queryKey: LoanQueries.LOAN_INTCHANGE(assetId, loanId),
        //Change the active interest adjustment, if any change
        updater: {
            queryKey: LoanQueries.LOANS(assetId),
            action: (state: Butlerr.Asset.Loan[]) =>
                state.map((l) => {
                    if (l.loan_id !== loanId) return l;

                    const adjStartDate = new Date(loanAdjustment.lich_date).setHours(0, 0, 0, 0);
                    /**
                     * We only need to update the active interest & date if:
                     * -The start of date of adjustment is lower than today (already started)
                     * -AND ( there's no current active OR current active is lower than new active )
                     */
                    if (
                        adjStartDate < Date.now() &&
                        (l.active_adj_date === null ||
                            new Date(l.active_adj_date).setHours(0, 0, 0, 0) < adjStartDate)
                    ) {
                        return {
                            ...l,
                            active_adj_date: new Date(loanAdjustment.lich_date).toISOString(),
                            active_adj_interestrate: loanAdjustment.lich_interest,
                        };
                    }
                    return l;
                }),
        },
    }),
    DELETE: ({
        assetId,
        loanId,
        adjustmentId,
    }: {
        assetId: number;
        loanId: number;
        adjustmentId: number;
    }): ButlerrMutationOptions<Butlerr.Asset.LoanInterestChange[]> => ({
        url: `${assetBaseRoute}/${assetId}/loans/${loanId}/adjustment/${adjustmentId}`,
        method: 'DELETE',
        updater: {
            queryKey: LoanQueries.LOAN_INTCHANGE(assetId, loanId),
            optimistic: true,
            action: (prev) => prev.filter((i) => i.lich_id !== adjustmentId),
        },
    }),
};

export function useLoanAdjustmentMutations<Key extends keyof typeof LoanAdjustmentMutations>(
    key: Key
) {
    //You won't get type suggestions in the mutate function without this assertion
    const mutationFn = LoanAdjustmentMutations[key] as (
        params: Parameters<typeof LoanAdjustmentMutations[Key]>[0]
    ) => ButlerrMutationOptions;
    return useButlerrMutation(mutationFn);
}
