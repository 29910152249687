import { Butlerr } from '../types/butlerr';
import { assetBaseRoute } from './asset.service';
import useButlerrAPI, { ButlerrMutationOptions, useButlerrMutation } from './useButlerrAPI';

export const ValuationQueries = {
    // kpis
    LATEST_VALUATION: (assetId: number) => ['assets', assetId, 'valuations', 'latest'] as const,

    VALUATIONS: (assetId: number) => ['assets', assetId, 'valuations'] as const,
};

export function useLatestValuation(assetId: number) {
    return useButlerrAPI<Butlerr.Asset.Valuation>(
        ValuationQueries.LATEST_VALUATION(assetId),
        `${assetBaseRoute}/${assetId}/valuations/latest`
    );
}

export function useValuations(assetId: number) {
    return useButlerrAPI<Butlerr.Asset.Valuation[]>(
        ValuationQueries.VALUATIONS(assetId),
        `${assetBaseRoute}/${assetId}/valuations/`
    );
}

type ValuationMutationInterface = Omit<
    Butlerr.Asset.Valuation,
    keyof Butlerr.Document.File | 'valu_lowrange' | 'valu_highrange' | 'valu_confidence'
>;

const ValuationMutations = {
    CREATE: ({
        asst_id,
        ...valuation
    }: Omit<ValuationMutationInterface, 'valu_id'>): ButlerrMutationOptions => ({
        url: `${assetBaseRoute}/${asst_id}/valuations/`,
        method: 'POST',
        requestBody: valuation,
        queryKey: ValuationQueries.VALUATIONS(asst_id),
    }),
    EDIT: ({
        asst_id,
        valu_id,
        ...valuation
    }: ValuationMutationInterface): ButlerrMutationOptions => ({
        url: `${assetBaseRoute}/${asst_id}/valuations/${valu_id}`,
        method: 'PUT',
        requestBody: valuation,
        queryKey: ValuationQueries.VALUATIONS(asst_id),
    }),
    DELETE: ({
        asst_id,
        valu_id,
    }: Pick<ValuationMutationInterface, 'asst_id' | 'valu_id'>): ButlerrMutationOptions => ({
        url: `${assetBaseRoute}/${asst_id}/valuations/${valu_id}`,
        method: 'DELETE',
        queryKey: ValuationQueries.VALUATIONS(asst_id),
    }),
};

export function useValuationMutations<Key extends keyof typeof ValuationMutations>(key: Key) {
    //You won't get type suggestions in the mutate function without this assertion
    const mutationFn = ValuationMutations[key] as (
        params: Parameters<typeof ValuationMutations[Key]>[0]
    ) => ButlerrMutationOptions;
    return useButlerrMutation(mutationFn);
}
