import { Row, Button } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { useProfileCompeletion } from './OnboardingModal';

const FinalManagePage = () => {
    const { onClose } = useProfileCompeletion();

    const history = useHistory();

    const handleProceed = () => {
        // close onboarding modal
        onClose();

        // redirect to Manage module and open create asset modal
        history.replace('/assets', { fromOnboardingModal: true });
    };

    return (
        <>
            <Row className="mb-3">
                <h1 className="text-muted">
                    Let's get started on your <span className="text-primary">Properties</span>
                </h1>

                <p>
                    We will now direct you to our Manage module where you can start adding your
                    assets.
                </p>

                <p>
                    Begin by <b>adding your address</b> and then add your{' '}
                    <b>income/expenses, loan and insurance</b> information to the asset.
                </p>

                <p>
                    You can also add / invite co-owners and other service providers to manage the
                    property together by defining their roles under the asset.
                </p>
            </Row>

            <div>
                <Button className="me-2" onClick={handleProceed}>
                    Proceed
                </Button>

                <Button variant="outline-primary" onClick={onClose}>
                    Skip to Home
                </Button>
            </div>
        </>
    );
};

export default FinalManagePage;
