import { MoreVert, AddRounded } from '@mui/icons-material';
import IconButton from '../../utils/IconButton';
import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import { Dropdown, Form, ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Butlerr } from '../../../types/butlerr';
import { createSocialRoute, SocialRoutes } from '../../social/SocialHome';
import UserAvatar from '../../social/UserAvatar';
import LineClampText from '../../utils/LineClampText';
import styles from '../asset.module.css';
import AssetConversationsModal from './AssetConversationsModal';
import { useButlerrUser } from '../../../services/user.service';
import DestructiveModal from '../../utils/DestructiveModal';
import {
    AssetConversationQueries,
    useAssetConversationMutations,
} from '../../../services/assetconversation.service';
import { useQueryClient } from 'react-query';

interface AssetConversationsSidebarProps {
    asset: Butlerr.Asset | Butlerr.ClientAsset;
    conversations: Butlerr.Asset.Conversation[];
    selectedConvoId: number | undefined;
    setSelectedConvoId: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const AssetConversationsSidebar = ({
    asset,
    conversations,
    selectedConvoId,
    setSelectedConvoId,
}: AssetConversationsSidebarProps) => {
    const assetId = asset.asst_id;

    const [selectedConvoIdOptions, setSelectedConvoIdOptions] = useState<number>(-1);

    const { data: dbUser } = useButlerrUser();

    const queryClient = useQueryClient();
    const onSelect = (conversation: Butlerr.Asset.Conversation | undefined) => {
        setSelectedConvoId(conversation?.ascv_id ?? undefined);

        // update conversations to retrieve last updated
        queryClient.invalidateQueries(AssetConversationQueries.CONVERSATIONS(assetId));
    };

    const [searchValue, setSearchValue] = useState<string>('');

    const filteredConvos = useMemo(
        () =>
            conversations.filter((convo) => {
                return (
                    convo.ascv_subject.toLowerCase().includes(searchValue.toLowerCase()) ||
                    convo.members.some((user) =>
                        user.user_socialhandle.toLowerCase().includes(searchValue.toLowerCase())
                    )
                );
            }),
        [conversations, searchValue]
    );

    //asset convo modal
    const [showModal, setShowModal] = useState(false);
    const [selectedModalConversation, setSelectedModalConversation] =
        useState<Butlerr.Asset.Conversation>();

    // destructive modal
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [leaveConversation, setLeaveConversation] = useState<boolean | null>(null); //true = Leave, false = Delete

    const { mutateAsync: leaveConvo } = useAssetConversationMutations('LEAVE', assetId);
    const { mutateAsync: deleteConvo } = useAssetConversationMutations('DELETE', assetId);

    const handleLeave = (convoId: number) => {
        setShowDeleteModal(true);
        setLeaveConversation(true);
        setSelectedConvoIdOptions(convoId)
    };

    const handleDelete = (convoId: number) => {
        setShowDeleteModal(true);
        setLeaveConversation(false);
        setSelectedConvoIdOptions(convoId)
    };

    const onDeleteClose = () => {
        setShowDeleteModal(false);
    };

    const onDeleteConfirm = async () => {
        if (leaveConversation === null) return;

        if (leaveConversation) {
            await leaveConvo({ assetId, conversationId: selectedConvoIdOptions });
        } else {
            await deleteConvo(
                { assetId, conversationId: selectedConvoIdOptions },
                {
                    onSuccess: () => {
                        onSelect(undefined);
                    },
                }
            );
        }
    };

    // add modal states
    const [showAddModal, setShowAddModal] = useState(false);

    const onAddModalOpen = () => {
        setShowAddModal(true);
    };

    const onAddModalClose = () => {
        setShowAddModal(false);
    };

    return (
        <>
            <div className="border-bottom bg-light d-flex p-2" style={{ minHeight: 68 }}>
                <Form.Control
                    className="shadow rounded-4"
                    placeholder="Search subjects/members..."
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                />
            </div>
            <ListGroup variant="flush">
                <ListGroup.Item
                    as="div"
                    action
                    // onClick={() => {
                    //     createChat()
                    // }}
                    className={classNames({
                        'cursor-pointer': true,
                        [styles.conversationsSidebarItem]: true
                    })}
                    onClick={() => onAddModalOpen()}
                >
                    <div className="d-flex align-items-center p-1">
                        <AddRounded
                            className='mx-auto'
                            style={{ width: 35, height: 35, alignSelf: 'center', maxHeight: '2rem' }}
                        />
                    </div>
                </ListGroup.Item>
                {filteredConvos.map((conversation) => (
                    <ConvoSidebarItem
                        key={conversation.ascv_id}
                        asset={asset}
                        conversation={conversation}
                        selectedConversationId={selectedConvoId ?? -1}
                        onSelect={onSelect}
                        dbUser={dbUser}
                        handleEdit={() => {
                            setShowModal(true);
                            setSelectedModalConversation(conversation);
                        }}
                        handleDelete={() => handleDelete(conversation.ascv_id)}
                        handleLeave={() => handleLeave(conversation.ascv_id)}
                    />
                ))}
            </ListGroup>

            <AssetConversationsModal
                show={showModal}
                onClose={() => setShowModal(false)}
                assetId={asset.asst_id}
                ownerId={'owner_id' in asset ? asset.owner_id ?? -1 : asset.user_id}
                initialValue={selectedModalConversation}
            />
            <DestructiveModal
                show={showDeleteModal}
                onClose={onDeleteClose}
                onConfirm={onDeleteConfirm}
                title={leaveConversation ? `Leave the conversation?` : `Delete the conversation?`}
                description={
                    leaveConversation
                        ? `You will lose all access and records related to this conversation. Are you sure you want to leave?`
                        : `You will lose all access and records related to this conversation. Are you sure you want to delete?`
                }
                confirmBtnText={`Confirm`}
            />
            <AssetConversationsModal
                show={showAddModal}
                onClose={onAddModalClose}
                assetId={asset.asst_id}
                ownerId={'owner_id' in asset ? asset.owner_id ?? -1 : asset.user_id}
            />
        </>
    );
};

interface ConvoSidebarItemProps {
    asset: Butlerr.Asset;
    conversation: Butlerr.Asset.Conversation;
    selectedConversationId: number;
    onSelect: (conversation: Butlerr.Asset.Conversation | undefined) => void;
    dbUser: Butlerr.User | undefined;
    handleEdit: () => void;
    handleDelete: () => void;
    handleLeave: () => void;
}

const ConvoSidebarItem = ({
    asset,
    conversation,
    selectedConversationId,
    onSelect,
    dbUser,
    handleEdit,
    handleDelete,
    handleLeave,
}: ConvoSidebarItemProps) => {
    // sort the members array so that the conversation creator is the first item in the array
    conversation.members.sort((a) => (a.user_id === conversation.user_id ? -1 : 0));

    const [canBold, setCanBold] = useState(true);

    const ownerDetails = conversation.members.filter((member) => member.asro_type.length === 0)[0] //owner has no role

    const assetOwnerOrConvoStarter = useMemo(
        () =>
            dbUser?.user_id === asset.user_id ||
            dbUser?.user_id === conversation.user_id ||
            conversation.members.find((m) => dbUser?.user_id === m.user_id)?.asro_type === 'CO',
        [asset.user_id, conversation.members, conversation.user_id, dbUser?.user_id]
    );

    return (
        <>
            <ListGroup.Item
                as="div"
                key={conversation.ascv_id}
                action
                onClick={() => {
                    onSelect(conversation);
                    setCanBold(false);
                }}
                className={classNames({
                    'cursor-pointer': true,
                    [styles.conversationsSidebarItem]: true,
                })}
                style={{
                    backgroundColor:
                        conversation.ascv_id === selectedConversationId
                            ? 'var(--bs-gray-200)'
                            : undefined,
                }}
            >
                <div className="mb-2 d-flex justify-content-between align-items-center">
                    <LineClampText
                        maxLines={2}
                        title={conversation.ascv_subject}
                        className={
                            'mb-0 small ' +
                            ((conversation.latestMessage.acms_datetime >
                                conversation.acla_lastAccess ||
                                conversation.acla_lastAccess === null) &&
                                canBold === true
                                ? 'fw-bold'
                                : '')
                        }
                    >
                        {conversation.ascv_subject}
                    </LineClampText>

                    {!conversation.acmb_leave && (
                        <Dropdown
                            className={styles.dropdown}
                            onToggle={(_, meta) => {
                                meta.originalEvent?.stopPropagation();
                                meta.originalEvent?.preventDefault();
                            }}
                        >
                            <Dropdown.Toggle as="span" bsPrefix=" ">
                                <IconButton
                                    transparent
                                    border={false}
                                    title="More"
                                    Icon={MoreVert}
                                    iconHtmlColor="var(--bs-gray)"
                                />
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="p-0">
                                {assetOwnerOrConvoStarter ? (
                                    <>
                                        <Dropdown.Item onClick={() => handleEdit()}>
                                            Edit
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={handleDelete}>Delete</Dropdown.Item>
                                    </>
                                ) : (
                                    <Dropdown.Item onClick={handleLeave}>Leave</Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                    )}
                </div>

                <div className="d-flex justify-content-between align-items-center">

                    {conversation.acmb_leave === null ? (
                        <div>
                            {conversation.members.map((member) => (
                                <Link
                                    key={member.user_id}
                                    to={createSocialRoute(SocialRoutes.USER_PROFILE, {
                                        id: member.user_id,
                                    })}
                                    className={styles.conversationMemberIconContainer}
                                    onClick={(ev) => ev.stopPropagation()}
                                >
                                    <UserAvatar
                                        roundedCircle
                                        width={member.user_id === conversation.user_id ? 28 : 21}
                                        height={member.user_id === conversation.user_id ? 28 : 21}
                                        className={
                                            'shadow-sm border-secondary ' +
                                            styles.conversationMemberIcon
                                        }
                                        user={member}
                                        title={member.user_socialhandle}
                                    />
                                </Link>
                            ))}
                        </div>
                    ) : (
                        //asset owner img here
                        <Link 
                            to={createSocialRoute(SocialRoutes.USER_PROFILE, {
                                id: asset.user_id,
                            })}
                            className={styles.conversationMemberIconContainer}
                            onClick={(ev) => ev.stopPropagation()}
                        >
                            <UserAvatar
                                roundedCircle
                                width={28}
                                height={28}
                                className={
                                    'shadow-sm border-secondary ' +
                                    styles.conversationMemberIcon
                                }
                                user={ownerDetails}
                                title={ownerDetails?.user_socialhandle}
                            />
                        </Link>
                    )}

                    {/* TODO: put unread count */}
                    {/* <small>
                            <Badge bg="secondary" pill>
                                3
                            </Badge>
                            <span className="visually-hidden">unread messages</span>
                        </small> */}
                </div>
            </ListGroup.Item>
        </>
    );
};

export default AssetConversationsSidebar;
