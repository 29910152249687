import { useCallback, useEffect, useState } from 'react';
import { AddCircle, ForumOutlined } from '@mui/icons-material';
import { Row, Col, Container, Button } from 'react-bootstrap';
import { useAssetConversations } from '../../../services/assetconversation.service';
import { Butlerr } from '../../../types/butlerr';
import PageHeader from '../../utils/PageHeader';
import { AssetTabContent } from '../Asset';
import AssetConversationsModal from './AssetConversationsModal';
import styles from '../asset.module.css';
import ErrorPage from '../../utils/ErrorPage';
import BootstrapSpinner from '../../utils/BootstrapSpinner';
import EmptyMessage from '../../utils/EmptyMessage';
import AssetConversationsSidebar from './AssetConversationsSidebar';
import AssetConversationsContent from './AssetConversationsContent';
import IconButton from '../../utils/IconButton';
import DestructiveModal from '../../utils/DestructiveModal';
import { useAssetRoles } from '../../../services/assetrole.service';
import { Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom';

interface AssetConversationsProps {
    asset: Butlerr.Asset;
}

export const AssetConvoRoutes = ({ asset }: AssetConversationsProps) => {
    return (
        <Container>
            <Switch>
                <Route path={'/assets/:assetId/conversations/:convoId'}>
                    <AssetConversations asset={asset} />
                </Route>
                <Route path={'/assets/:assetId/conversations'} exact={true}>
                    <AssetConversations asset={asset} />
                </Route>
            </Switch>
        </Container>
    )
}

export const AssetConversations = ({ asset }: AssetConversationsProps) => {

    // data
    const assetId = asset.asst_id;
    const ownerId = asset.user_id;
    const { data: conversations = [], isLoading, error } = useAssetConversations(assetId);


    const [selectedConvoId, setSelectedConvoId] = useState<number>();

    let { convoId } = useParams<{ convoId: string }>();
    const history = useHistory();
    const { pathname } = useLocation();

    const handleChatChange = useCallback((convoId: string) => {
        const newPathname = `/${pathname.split('/')[1]}/${assetId}/conversations/${convoId}`;
        history.push(newPathname);
    }, [history, pathname, assetId]);

    useEffect(() => {
        if (selectedConvoId !== undefined) {
            handleChatChange(selectedConvoId?.toString())
        }
        // eslint-disable-next-line
    }, [selectedConvoId])

    useEffect(() => {
        if (convoId !== undefined) {
            setSelectedConvoId(parseInt(convoId))
        }
    }, [convoId])

    // modal states
    const [showModal, setShowModal] = useState(false);

    const onModalOpen = () => {
        setShowModal(true);
    };

    const onModalClose = () => {
        setShowModal(false);
    };

    const [showNoMembersModal, setShowNoMembersModal] = useState(false);
    const { data: roles = [] } = useAssetRoles(assetId);

    //Asset Info
    const onNoMembersClose = () => {
        setShowNoMembersModal(false)
    }

    const onNoMembersConfirm = () => {
        setShowNoMembersModal(false)
        history.push(`/${pathname.split('/')[1]}/${assetId}/info`, { to: "Teams" });
    }

    useEffect(() => {
        if ((roles.length === 0) && (conversations.length === 0)) {
            setShowNoMembersModal(true)
        } else {
            setShowNoMembersModal(false)
        }
    }, [conversations, ownerId, roles])


    return (
        <>
            {error !== null ? (
                <ErrorPage status={error.status} message={error.message} />
            ) : isLoading ? (
                <BootstrapSpinner />
            ) : (
                <>
                    {/* tab header */}
                    <PageHeader
                        Icon={ForumOutlined}
                        title="Conversations"
                        rightSide={
                            roles.length !== 0
                                ? <IconButton Icon={AddCircle} title="Create A Conversation" onClick={() => onModalOpen()} />
                                : <IconButton Icon={AddCircle} title="Create A Conversation" onClick={() => setShowNoMembersModal(true)} />
                        }
                    />

                    {/* tab content */}
                    <AssetTabContent
                        className={styles.conversationsContainer}
                        style={{ borderRadius: 0 }}
                        cardBodyClassName="p-0 h-100"
                    >
                        <AssetConversationsCardContent
                            asset={asset}
                            selectedConvoId={selectedConvoId}
                            setSelectedConvoId={setSelectedConvoId}
                            onModalClose={onModalClose}
                            onModalOpen={onModalOpen}
                            rolesNum={roles.length}
                        />
                    </AssetTabContent>
                    <AssetConversationsModal
                        show={showModal}
                        onClose={onModalClose}
                        assetId={assetId}
                        ownerId={ownerId}
                    />
                    <DestructiveModal
                        show={showNoMembersModal}
                        onClose={onNoMembersClose}
                        onConfirm={onNoMembersConfirm}
                        title={`No Team Members`}
                        description={`It seems like you do not have any team members to start a conversation. Would you like to proceed to Asset Info to add a team member?`}
                        btnVariant="primary"
                        cancelBtnText="No"
                        confirmBtnText="Yes"
                    />
                </>
            )}
        </>
    );
};

interface AssetConversationsCardContentProps {
    asset: Butlerr.Asset,
    selectedConvoId: number | undefined,
    setSelectedConvoId: React.Dispatch<React.SetStateAction<number | undefined>>,
    onModalOpen?: () => void,
    rolesNum?: number
}

export const AssetConversationsCardContent = ({
    asset,
    selectedConvoId,
    setSelectedConvoId,
    onModalOpen,
    rolesNum,
    onModalClose
}: AssetConversationsCardContentProps & { onModalClose: () => void }) => {

    const assetId = asset.asst_id;

    // data
    const { data: conversations = [], isLoading, error } = useAssetConversations(assetId);

    return (
        <>
            {error !== null ? (
                <ErrorPage status={error.status} message={error.message} />
            ) : isLoading ? (
                <BootstrapSpinner />
            ) : (
                <>
                    {conversations.length === 0 ? (
                        <>
                            <EmptyMessage className="py-3" message="No conversations yet." />
                            {onModalOpen && rolesNum !== 0 && (
                                <div className="d-flex flex-wrap align-items-center justify-content-center p-3" style={{ gridGap: '1rem' }}>
                                    <Button
                                        onClick={() => onModalOpen()}
                                        variant="outline-primary"
                                        className="border-2 text-primary"
                                    >
                                        Create a conversation
                                    </Button>
                                </div>
                            )
                            }
                        </>
                    ) : (
                        <ConversationsContainer
                            asset={asset}
                            selectedConvoId={selectedConvoId}
                            setSelectedConvoId={setSelectedConvoId}
                            conversations={conversations}
                        />
                    )}
                </>
            )}
        </>
    )
}

interface ConversationsContainerProps {
    asset: Butlerr.Asset;
    selectedConvoId: number | undefined,
    setSelectedConvoId: React.Dispatch<React.SetStateAction<number | undefined>>,
    conversations: Butlerr.Asset.Conversation[];
}

const ConversationsContainer = ({ asset, selectedConvoId, setSelectedConvoId, conversations }: ConversationsContainerProps) => {

    // This is needed for when user leaves the conversation
    // and to retrieve the leave date (acmb_leave)
    useEffect(() => {
        setSelectedConvoId((prev) => {
            //if previously selected, select a new copy of the conversation from new data
            if (prev) {
                return conversations.find((c) => c.ascv_id === prev)?.ascv_id;
            }
            return prev;
        });
        // eslint-disable-next-line
    }, [conversations]);

    const selectedConvo = conversations.find((c) => c.ascv_id === selectedConvoId)

    return (
        <>
            <Row className="h-100">
                {/* sidebar */}
                <Col xs={3} className="border-end pe-0 overflow-auto h-100">
                    <AssetConversationsSidebar
                        asset={asset}
                        conversations={conversations}
                        selectedConvoId={selectedConvoId}
                        setSelectedConvoId={setSelectedConvoId}
                    />
                </Col>

                {/* content */}
                <Col xs={9} className="ps-0 d-flex flex-column h-100">
                    {selectedConvo === undefined ? (
                        <>
                            <EmptyMessage className="py-3" message="No conversation selected." />
                        </>
                    ) : (
                        <AssetConversationsContent
                            asset={asset}
                            selectedConversation={selectedConvo}
                        />
                    )}
                </Col>
            </Row>
        </>
    );
};

export default AssetConversations;
