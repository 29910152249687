import { ArrowBack, ArrowForward } from '@mui/icons-material';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Carousel, Modal } from 'react-bootstrap';

import styles from './utils.module.css';
import ButlerrImage from '../ui/ButlerrImage';

export interface CarouselMedia {
    /**
     * `public` urls will be directly put in the image's src.
     * `private` urls will be fetched with the auth token & should return an object of type `Butlerr.Image`
     */
    src: string;
    type: 'public' | 'private';
    /**
     * Defaults to `image`
     */
     mediaType?: 'image' | 'video';
}

interface BaseCarouselProps extends React.HTMLAttributes<HTMLDivElement> {
    topRight?:
    |   JSX.Element
    |   ((image: CarouselMedia, index: number) => JSX.Element);
    /**
     * `0` or `undefined` will load all images
     * Otherwise, will load all images with index in the range +- of the active index
     */
    lazyLoadRange?: number;
    imageProps?: React.HTMLAttributes<HTMLImageElement>;
}

interface BootstrapCarouselWithSrcProps extends BaseCarouselProps {
    srcs: string[];
    type: 'public' | 'private';
    /**
     * Defaults to `image`
     */
    mediaType?: 'image' | 'video';
}
interface BootstrapCarouselWithObjProps extends BaseCarouselProps {
    srcs: CarouselMedia[];
    type?: never;
}

type BootstrapCarouselInterface =
|   BootstrapCarouselWithSrcProps
|   BootstrapCarouselWithObjProps;

export type BootstrapCarouselRef = { setIndex: (target: number) => void };
const BootstrapCarousel = React.forwardRef<BootstrapCarouselRef, BootstrapCarouselInterface>(
    ({ srcs: propSrcs, type, topRight, lazyLoadRange = 0, imageProps, ...props }, ref) => {

        const srcs = propSrcs.map((img) => {
            if (typeof img === 'string') {
                return {
                    src: img,
                    type: type!
                }
            }
            return img;
        })

        const [showModal, setShowModal] = useState(false);
        const handleImageClick = () => {
            setShowModal(true);
        };


        const [index, setIndex] = useState(0);
        useImperativeHandle<BootstrapCarouselRef, BootstrapCarouselRef>(ref, () => ({
            setIndex: (target: number) => setIndex(target),
        }));

        if (srcs.length === 0) {
            return <></>;
        }

        const carousel = (
            <InnerCarousel
                srcs={srcs}
                onImageClick={handleImageClick}
                showModal={showModal}
                topRight={topRight}
                index={index}
                setIndex={setIndex}
                lazyLoadRange={lazyLoadRange}
                imageProps={imageProps}
                {...props}
            />
        );

        return (
            <>
                {carousel}

                <Modal
                    show={showModal}
                    onHide={() => setShowModal(false)}
                    size="lg"
                    centered
                    dialogClassName="modal-66w"
                    contentClassName="bg-transparent border-0"
                >
                    <div style={{ height: '66vh' }}>
                        {carousel}
                    </div>
                </Modal>
            </>
        );
    }
);

interface InnerCarouselProps extends React.HTMLAttributes<HTMLDivElement> {
    srcs: CarouselMedia[];
    onImageClick: (image: CarouselMedia, index: number) => void;

    index: number;
    setIndex: React.Dispatch<React.SetStateAction<this['index']>>;

    topRight?: JSX.Element | ((image: CarouselMedia, index: number) => JSX.Element);
    showModal: boolean;
    lazyLoadRange: number;
    imageProps?: React.HTMLAttributes<HTMLImageElement>;
}

const InnerCarousel = React.forwardRef<HTMLDivElement, InnerCarouselProps>(
    (
        {
            srcs,
            onImageClick,
            index: indexFromProps,
            setIndex,
            topRight,
            showModal,
            lazyLoadRange,
            imageProps,
            className,
            ...props
        },
        ref
    ) => {
        /**
         * If the index is invalid, the carousel acts weird, even after the index is changed back correctly.
         * Make sure the index is always within range
         */
        const activeIndex = srcs[indexFromProps] ? indexFromProps : srcs.length - 1;

        return (
            <div
                {...props}
                ref={ref}
                className={['position-relative h-100 rounded overflow-hidden', className ?? '', styles.carousel]
                    .join(' ')
                    .trimEnd()}
            >
                <Carousel
                    activeIndex={activeIndex}
                    onSelect={setIndex}
                    interval={null}
                    indicators={false}
                    controls={srcs.length > 1}
                    prevIcon={
                        <span title="Previous">
                            <ArrowBack fontSize="small" />
                        </span>
                    }
                    nextIcon={
                        <span title="Next">
                            <ArrowForward fontSize="small" />
                        </span>
                    }
                >
                    {srcs.map((src, idx) => (
                        <Carousel.Item
                            className={!showModal ? 'cursor-pointer ' + styles.item : ''}
                            onClick={onImageClick.bind(this, src, idx)}
                            key={src.src}
                        >
                            {
                                src.mediaType === 'video' ? (
                                    <InnerVideo
                                        src={src.src}
                                        type={src.type}
                                        enabled={
                                            lazyLoadRange === 0 ||
                                            (activeIndex - lazyLoadRange < idx && idx < activeIndex + lazyLoadRange)
                                        }
                                        active={activeIndex === idx}
                                    />
                                ) : (
                                    <InnerImage
                                        src={src.src}
                                        type={src.type}
                                        enabled={
                                            lazyLoadRange === 0 ||
                                            (activeIndex - lazyLoadRange < idx && idx < activeIndex + lazyLoadRange)
                                        }
                                        imageProps={imageProps}
                                    />
                                )
                            }
                        </Carousel.Item>
                    ))}
                </Carousel>

                {srcs.length > 1 && (
                    <div className={'ts-corner ' + styles.counter}>
                        <span>
                            {activeIndex + 1} of {srcs.length}
                        </span>
                    </div>
                )}

                {topRight !== undefined &&
                    (typeof topRight === 'function'
                        ? topRight(srcs[activeIndex], activeIndex)
                        : topRight)}
            </div>
        );
    }
);

interface InnerImageProps {
    src: string;
    type: 'public' | 'private';
    enabled: boolean;
    imageProps?: React.HTMLAttributes<HTMLImageElement>;
}
const InnerImage = ({ src, type, enabled, imageProps }: InnerImageProps) => {

    const currProps = {
        className: "w-100 h-100 image",
        loading: type === 'private' ? undefined : "lazy" as const
    }

    const image = type === 'private' ? (
        <ButlerrImage
            url={src}
            enabled={enabled}
            {...currProps}
            {...imageProps}
        />
    ) : (
        <img
            alt=""
            src={src}
            {...currProps}
            {...imageProps}
        />
    )

    return (
        <div className={'w-100 h-100 rounded-4 ' + styles.imageContainer}>
            {image}
        </div>
    )
};

interface InnerVideoProps {
    src: string;
    type: 'public' | 'private';
    enabled: boolean;
    active: boolean;
    videoProps?: React.HTMLAttributes<HTMLVideoElement>;
}
const InnerVideo = ({ src, type, enabled, active, videoProps }: InnerVideoProps) => {

    const videoRef = useRef<HTMLVideoElement>(null);
    const lastState = useRef<'play' | 'pause'>();

    useEffect(() => {
        if (!videoRef.current) return;

        if (active) {
            if (lastState.current === 'play') {
                videoRef.current.play();
            }
        }
        else {
            lastState.current = videoRef.current.paused ? 'pause' : 'play';
            videoRef.current.pause();
        }
    }, [active])

    const currProps = {
        className: "w-100 h-100",
        autoPlay: false,
        controls: true
    }

    const content = type === 'private' ? (
        <ButlerrImage
            url={src}
            enabled={enabled}
            {...currProps}
            render={(url, props) => (
                <video
                    ref={videoRef}
                    src={url}
                    {...props}
                    {...currProps}
                    {...videoProps}
                />
            )}
        />
    ) : (
        <video
            ref={videoRef}
            src={src}
            {...currProps}
            {...videoProps}
        />
    )

    return (
        <div className={'w-100 h-100 rounded-4 ' + styles.imageContainer}>
            {content}
        </div>
    )
};

export default BootstrapCarousel;
