import {} from '@auth0/auth0-react';
import { useCallback, useMemo } from 'react';
import { Column } from 'react-table';
import { Butlerr } from '../../../types/butlerr';
import { ButlerrInsuranceType } from '../../../types/butlerr-enums';
import { formatDate, formatCurrency } from '../../utils/HelperFunctions';
import ReactTable from '../../utils/ReactTable';

interface AssetInsurancesTableInterface {
    asset: Butlerr.Asset;
    insurances: Butlerr.Asset.Insurance[];
    isLoading: boolean;
    onModalOpen: (insurance?: Butlerr.Asset.Insurance | undefined) => void;
}

const AssetInsurancesTable = ({
    asset,
    insurances,
    isLoading,
    onModalOpen,
}: AssetInsurancesTableInterface) => {

    // edit modal
    const onEdit = useCallback(
        (insurance: Butlerr.Asset.Insurance) => {
            onModalOpen(insurance);
        },
        [onModalOpen]
    );

    // table
    const columns = useMemo<Column<Butlerr.Asset.Insurance>[]>(
        () => [
            {
                Header: 'type',
                accessor: 'insu_type',
                Cell: ({ value }) => {
                    return <>{ButlerrInsuranceType[value as keyof typeof ButlerrInsuranceType]}</>;
                },
            },
            {
                Header: 'carrier',
                accessor: 'insu_carrier',
                minWidth: 120,
            },
            {
                Header: 'start date',
                accessor: 'insu_from',
                minWidth: 147,
                Cell: ({ value }) => <>{formatDate(value) ?? '-'}</>,
            },
            {
                Header: 'amount',
                accessor: 'insu_premium',
                minWidth: 120,
                Cell: ({ value }) => <>{formatCurrency(value, { currency: asset.asst_currency})}</>,
            },
            {
                Header: 'months covered',
                accessor: 'insu_period',
                minWidth: 200,
            },
            // {
            //     Header: 'document',
            //     accessor: 'insu_doclink',
            //     Cell: ({ row }) => {
            //         let name = row.original.doc_name;
            //         if (!name) return "-"

            //         if (row.original.doc_format) {
            //             name += `.${row.original.doc_format}`
            //         }
            //         return name;
            //     },
            // },
        ],
        [asset.asst_currency]
    );

    return (
        <>
            <ReactTable 
                data={insurances} 
                columns={columns} 
                isLoading={isLoading} 
                Row={(props, data) => (
                    <tr
                        {...props}
                        className="cursor-pointer"
                        onClick={ onEdit.bind(undefined, data) }
                    />
                )}
            />
        </>
    );
};

export default AssetInsurancesTable;
