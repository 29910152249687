import { InfiniteData } from "react-query";
import { Butlerr } from "../types/butlerr"
import { SocialPostReactionKey } from "../types/butlerr-enums"
import useButlerrAPI, { ButlerrMutationOptions, ButlerrPaginatedResult, Updater, useButlerrMutation, useButlerrPaginatedQuery } from "./useButlerrAPI";

type TData = ButlerrPaginatedResult<Butlerr.Social.Post[]>;
type TSocialData = InfiniteData<TData>;

export interface FetchPostOptions {
    /** Bookmarked posts only */
    bookmarked_only?: boolean;
    /** Only show posts from your following list */
    following_only?: boolean;
    /** Post id */
    post?: number;
    /** User id */
    user?: number;
    /** Channel id */
    channel?: number;
    /** Sort method (Sorted by `latest` by default) */
    sort?: "latest" | "top_reactions" | "trending" | "top_contributors" | "bookmark_date";
    /** Hashtags to filter by (comma separated) */
    hashtags?: string;
    /** Query to search in message and links */
    query?: string;
}

export const SocialQueries = {
    //Optionally, hash the query with list of following
    POSTS: (params: FetchPostOptions) => ['social', 'posts', params] as const,
    COMMENTS: (postId: number, parentCommentId?: number) => ['social', 'comments', postId, parentCommentId] as const,
}

export function useSocialPosts(params: FetchPostOptions = {}) {

    const searchParams = new URLSearchParams();
    for (const key in params) {
        const value = params[key as keyof typeof params]
        if (value !== undefined) {
            searchParams.set(key, String(value))
        }
    }
    return useButlerrPaginatedQuery<Butlerr.Social.Post[]>(SocialQueries.POSTS(params), `/api/social/feed?${searchParams.toString()}`, true);
}

type PostForm = {
    tagged: Omit<Butlerr.UserInfo, 'user_profile'>[];
    post_msg: string;
    post_url: string;
    post_hashtags: string;
    post_images: Blob[];
    chan_id?: number;
}

type EditPostForm =
    &  Omit<PostForm, 'chan_id'>
    &   {
        post_id: number;
        pimg_id: number[];
        url_changed: boolean;
    }

const SocialMutations = {
    CREATE: ({ tagged, ...body }: PostForm) => ({
        url: '/api/social/post',
        method: 'POST',
        requestBody: {
            ...body,
            tagged: tagged.map(t => t.user_id)
        },
        useFormData: true,
        refetchData: 'all',
    }),
    EDIT: ({ post_id, tagged, url_changed, ...body }: EditPostForm) : ButlerrMutationOptions<TSocialData> => ({
        url: `/api/social/post/${post_id}${!url_changed ? '' : '?url_changed=true'}`,
        method: 'PUT',
        requestBody: {
            ...body,
            tagged: tagged.map(u => u.user_id)
        },
        useFormData: true,
        refetchData: 'all',
        updater: {
            optimistic: true,
            partialMatch: true,
            action: (prev) => ({
                pageParams: prev?.pageParams ?? [],
                pages: prev?.pages.map((res) => ({
                    ...res,
                    result: res.result.map((post) => post_id !== post.post_id ? post : ({
                        ...post,
                        post_msg: body.post_msg || null,
                        post_url: body.post_url || null,
                        post_hashtags: body.post_hashtags || null,
                        post_update: new Date().toISOString(),
                        tagged
                    }))
                })) ?? []
            })
        }
    }),
    DELETE: (postId: number) : ButlerrMutationOptions<TSocialData> => ({
        url: `/api/social/post/${postId}`,
        method: 'DELETE',
        updater: {
            partialMatch: true,
            action: (prev) => ({
                pageParams: prev?.pageParams ?? [],
                pages: prev?.pages.map(res => ({
                    ...res,
                    result: res.result.filter(p => p.post_id !== postId)
                })) ?? []
            })
        }
    }),
    SAVE: (postId: number) : ButlerrMutationOptions<TSocialData> => ({
        url: `/api/social/post/${postId}/saved`,
        method: 'POST',
        updater: {
            optimistic: true,
            partialMatch: true,
            action: (prev) => ({
                pageParams: prev?.pageParams ?? [],
                pages: prev?.pages.map(res => ({
                    ...res,
                    result: res.result.map(p => p.post_id !== postId ? p : ({
                        ...p,
                        user_saved: 1
                    }))
                })) ?? []
            })
        }
    }),
    UNSAVE: (postId: number) : ButlerrMutationOptions<TSocialData> => ({
        url: `/api/social/post/${postId}/saved`,
        method: 'DELETE',
        updater: {
            optimistic: true,
            partialMatch: true,
            action: (prev) => ({
                pageParams: prev?.pageParams ?? [],
                pages: prev?.pages.map(res => ({
                    ...res,
                    result: res.result.map(p => p.post_id !== postId ? p : ({
                        ...p,
                        user_saved: 0
                    }))
                })) ?? []
            })
        }
    }),
    REACT: ({ postId, ...body } : { postId: number; reac_reaction: SocialPostReactionKey; }) : ButlerrMutationOptions<TSocialData> => ({
        url: `/api/social/post/${postId}/reaction`,
        method: 'PUT',
        requestBody: body,
        updater: {
            optimistic: true,
            partialMatch: true,
            action: (prev) => ({
                pageParams: prev?.pageParams ?? [],
                pages: prev?.pages.map(res => ({
                    ...res,
                    result: res.result.map(p => {
                        if (p.post_id !== postId) return p;

                        //remove count for current reaction
                        const user_reac_key = p.user_reaction === "C" ? 'claps'
                            : p.user_reaction === "D" ? 'dislikes'
                            : p.user_reaction === "O" ? 'loves'
                            : p.user_reaction === "L" ? 'likes'
                            : p.user_reaction === "H" ? 'high_fives'
                            : null;
                        
                        if (user_reac_key !== null) p[user_reac_key] = p[user_reac_key] - 1;

                        //increment new reaction count
                        const reac_key = body.reac_reaction === "C" ? 'claps'
                            : body.reac_reaction === "D" ? 'dislikes'
                            : body.reac_reaction === "O" ? 'loves'
                            : body.reac_reaction === "L" ? 'likes'
                            : 'high_fives';
                        
                        p[reac_key] = p[reac_key] + 1;

                        return {
                            ...p,
                            user_reaction: body.reac_reaction
                        }
                    })
                })) ?? []
            })
        }
    }),
    REMOVE_REACT: (postId: number) : ButlerrMutationOptions<TSocialData> => ({
        url: `/api/social/post/${postId}/reaction`,
        method: 'DELETE',
        updater: {
            optimistic: true,
            partialMatch: true,
            action: (prev) => ({
                pageParams: prev?.pageParams ?? [],
                pages: prev?.pages.map(res => ({
                    ...res,
                    result: res.result.map(p => {
                        if (p.post_id !== postId) return p;
                        //remove count for current reaction
                        const reac_key = p.user_reaction === "C" ? 'claps'
                            : p.user_reaction === "D" ? 'dislikes'
                            : p.user_reaction === "O" ? 'loves'
                            : p.user_reaction === "L" ? 'likes'
                            : p.user_reaction === "H" ? 'high_fives'
                            : null;

                        if (reac_key !== null) p[reac_key] = p[reac_key] - 1;

                        return {
                            ...p,
                            user_reaction: null
                        }
                    })
                })) ?? []
            })
        }
    })
}

export function useSocialMutations<Key extends keyof typeof SocialMutations>(key: Key) {
    //You won't get type suggestions in the mutate function without this assertion
    const mutationFn = SocialMutations[key] as (params: Parameters<typeof SocialMutations[Key]>[0]) => ButlerrMutationOptions;
    return useButlerrMutation(mutationFn, SocialQueries.POSTS({}).slice(0, -1))
}

export function usePostComments(postId: number, parentCommentId?: number, enabled = true) {
    let url = `/api/social/post/${postId}/comment`;
    if (parentCommentId) url += `/${parentCommentId}/reply`

    return useButlerrAPI<Butlerr.Social.Comment[]>(SocialQueries.COMMENTS(postId, parentCommentId), url, true, {
        staleTime: 0,
        enabled
    });
}

type CommentForm = {
    postId: number;
    commentId: number;
    parentCommentId?: number;
    comm_text: string;
}

const CommentMutations = {
    CREATE: ({ postId, parentCommentId, ...body } : Omit<CommentForm, 'commentId'>) : ButlerrMutationOptions<Butlerr.Social.Comment[]> => {
        //update comment count in parent post
        const updaters : Updater[] = [{
            optimistic: true,
            queryKey: SocialQueries.POSTS({}).slice(0, -1),
            partialMatch: true,
            action: (prev : TSocialData) => ({
                pageParams: prev?.pageParams ?? [],
                pages: prev?.pages.map(res => ({
                    ...res,
                    result: res.result.map(p => p.post_id !== postId ? p : ({
                        ...p,
                        comments: p.comments + 1
                    }))
                })) ?? []
            })
        }];
        //update replies count in parent comment
        if (parentCommentId !== undefined) {
            updaters.push({
                optimistic: true,
                queryKey: SocialQueries.COMMENTS(postId),
                action: (prev : Butlerr.Social.Comment[]) => prev?.map(c => c.comm_id !== parentCommentId ? c : ({
                    ...c,
                    replies: (c.replies ?? 0) + 1
                })) ?? []
            })
        }

        return {
            url: parentCommentId === undefined ? `/api/social/post/${postId}/comment` : `/api/social/post/${postId}/comment/${parentCommentId}/reply`,
            method: 'POST',
            requestBody: body,
            refetchData: true,
            queryKey: SocialQueries.COMMENTS(postId, parentCommentId),
            updater: updaters
        };
    },
    EDIT: ({ postId, commentId, parentCommentId, ...body }: CommentForm) : ButlerrMutationOptions<Butlerr.Social.Comment[]> => ({
        url: `/api/social/post/${postId}/comment/${commentId}`,
        method: 'PUT',
        requestBody: body,
        queryKey: SocialQueries.COMMENTS(postId, parentCommentId),
        updater: {
            optimistic: true,
            action: (prev) => prev?.map(c => c.comm_id !== commentId ? c : ({
                ...c,
                comm_text: body.comm_text
            })) ?? []
        }
    }),
    DELETE: ({ commentId, postId, parentCommentId }: Omit<CommentForm, 'comm_text'>) => ({
        url: `/api/social/post/${postId}/comment/${commentId}`,
        method: 'DELETE',
        refetchData: true,
        queryKey: SocialQueries.COMMENTS(postId, parentCommentId),
        updater: [{
            optimistic: true,
            action: (prev: Butlerr.Social.Comment[]) => prev?.filter(c => c.comm_id !== commentId) ?? []
        }, {
            optimistic: true,
            queryKey: SocialQueries.COMMENTS(postId),
            action: (prev: Butlerr.Social.Comment[]) => prev?.map(c => c.comm_id !== parentCommentId ? c : ({
                ...c,
                replies: (c.replies ?? 1) - 1
            })) ?? []
        }, {
            optimistic: true,
            queryKey: SocialQueries.POSTS({}).slice(0, -1),
            partialMatch: true,
            action: (prev : TSocialData) => ({
                pageParams: prev?.pageParams ?? [],
                pages: prev?.pages.map(res => ({
                    ...res,
                    result: res.result.map(p => p.post_id !== postId ? p : ({
                        ...p,
                        comments: p.comments - 1
                    }))
                })) ?? []
            })
        }]
    })
}

export function usePostCommentMutations<Key extends keyof typeof CommentMutations>(key: Key) {
    //You won't get type suggestions in the mutate function without this assertion
    const mutationFn = CommentMutations[key] as (params: Parameters<typeof CommentMutations[Key]>[0]) => ButlerrMutationOptions;
    return useButlerrMutation(mutationFn);
}