import { Done, HelpOutlined } from '@mui/icons-material';
import classNames from 'classnames';
import { CSSProperties } from 'react';
import { Col, Dropdown, OverlayTrigger, Row, RowProps, Tooltip } from 'react-bootstrap';
import styles from './asset.module.css';

interface AssetKPIInterface extends RowProps {
    data: Array<KPICardInterface>;
}

const AssetKPI = ({ data, ...props }: AssetKPIInterface) => {
    return (
        <Row className="d-flex justify-content-evenly g-4 mb-3" {...props}>
            {data.map((kpi, index) => (
                <Col key={index}>
                    <KPICard
                        title={kpi.title}
                        dropdownItems={kpi.dropdownItems}
                        dropdownValue={kpi.dropdownValue}
                        dropdownChange={kpi.dropdownChange}
                        data={kpi.data}
                        tooltipText={kpi.tooltipText}
                    />
                </Col>
            ))}
        </Row>
    );
};

interface DropdownItem {
    label: string;
    value: string;
}

export interface KPICardInterface {
    title: string;
    dropdownItems?: Array<DropdownItem>;
    dropdownValue?: string;
    dropdownChange?: (value: any) => void;
    data?: string | number | React.ReactNode | (() => React.ReactNode);
    tooltipText?: string;
    className?: string;
    style?: CSSProperties;
}

export const KPICard = ({
    title,
    dropdownItems,
    dropdownValue,
    dropdownChange,
    data,
    tooltipText,
    className,
    style,
}: KPICardInterface) => {
    return (
        <div className={classNames(styles.assetKPI, className)} style={style}>
            <div
                className={
                    'd-flex justify-content-center align-items-center text-center ' +
                    styles.assetKPITitle
                }
            >
                {dropdownChange ? (
                    <>
                        <Dropdown align="end" className="me-1">
                            <Dropdown.Toggle as="span" className="cursor-pointer">
                                <span title={title}>{title}</span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="assetKPIDropdownMenu">
                                {dropdownItems?.map((item, index) => {
                                    return (
                                        <Dropdown.Item
                                            key={item.value}
                                            onClick={() => dropdownChange(item.value)}
                                            className="d-flex justify-content-between align-items-center"
                                        >
                                            <span>{item.label}</span>
                                            <Done
                                                fontSize="small"
                                                className="ms-2 text-success"
                                                style={{
                                                    opacity: item.value === dropdownValue ? 1 : 0,
                                                }}
                                            />
                                        </Dropdown.Item>
                                    );
                                })}
                            </Dropdown.Menu>
                        </Dropdown>
                        {tooltipText !== undefined ? (
                            <KPITooltip tooltipText={tooltipText} />
                        ) : null}
                    </>
                ) : (
                    <>
                        <span className="me-1">{title}</span>
                        {tooltipText !== undefined ? (
                            <KPITooltip tooltipText={tooltipText} />
                        ) : null}
                    </>
                )}
            </div>

            <div className={styles.assetKPIData}>
                <h2 className="mb-0 text-center text-primary">
                    {data === undefined ? (
                        <span className="skeleton-box w-25"></span>
                    ) : typeof data === 'function' ? (
                        data()
                    ) : (
                        data
                    )}
                </h2>
            </div>
        </div>
    );
};

const KPITooltip = ({ tooltipText }: { tooltipText: string }) => {
    return (
        <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={<Tooltip>{tooltipText}</Tooltip>}
        >
            {({ ref, ...triggerHandler }) => (
                <div {...triggerHandler} ref={ref}>
                    <HelpOutlined fontSize="small" style={{ fontSize: '16px' }} />
                </div>
            )}
        </OverlayTrigger>
    );
};

export default AssetKPI;
