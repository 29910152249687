import ButlerrCard from "../utils/ButlerrCard";
import { Link, useHistory } from "react-router-dom";
import { useAssets } from "../../services/asset.service";
import { useButlerrStats } from "../../services/notification.service";
import { useSocialChatMutations, useChatMsgMutations, useSocialChats } from "../../services/chat.service";
import { createAssetRoute, AssetRoutes } from "../assets/Asset";
import ButlerrImage, { ImageRoutes } from "../ui/ButlerrImage";
import styles from "./social.module.css";
import classNames from "classnames";
import { MoreHorizRounded, GroupsRounded, PublicRounded, BusinessRounded, MapsHomeWorkRounded, CloseRounded } from "@mui/icons-material";
import { Form, Modal } from "react-bootstrap";
import { Fragment, useMemo, useState } from "react";
import IconButton from "../utils/IconButton";
import { SocialRoutes } from "./SocialHome";
import { useButlerrUser } from "../../services/user.service";
import awsLogo from "../../assets/logo_aws.png";

export default function SocialSubSidebar() {
    return (
        <div>
            <PinnedAssets />
            <Statistics />
            <Feedback />
            <Footer />
        </div>
    )
}

function Footer() {

    const links = useMemo(() => [
        ["Disclaimer" as const, "All Information are provided “AS IS” and we do not warranty as to their accuracy. We are not responsible or liable for any claims, damages, losses, expenses, costs or liabilities whatsoever. Please seek professional advice before relying on the Information provided. The Information are based on the data available at the date of publication and may be subject to further revision as and when more data is made available. We reserve the rights to modify, alter, delete or withdraw all Information at any time without notice. All contents and materials displayed on propcircle.co are for your general information purpose only and are no substitute for independent research and/or verifications and should not be regarded as a substitute for professional, legal, financial or real estate advice."],
        ["Privacy" as const, null],
        ["User Agreement" as const, null],
        ["Copyright" as const, null],
    ], []);

    const [ selectedLink, setSelectedLink ] = useState<typeof links[number]>();

    return (
        <div className="mb-3">
            <div className="d-flex flex-wrap justify-content-around">
                <img className="mb-2" alt="powered by aws" src={awsLogo} height={25} />
            </div>
            <div className="d-flex flex-wrap justify-content-center">
                {links.map((link, idx) => (
                    <Fragment key={idx}>
                        { idx !== 0 && <span className="text-muted">&nbsp;&bull;&nbsp;</span> }
                        <button
                            disabled={link[1] === null}
                            className="link-underline text-muted p-0 bg-transparent border-0"
                            style={{ fontSize: "0.75rem" }}
                            onClick={() => setSelectedLink(link)}
                        >
                            {link[0]}
                        </button>
                    </Fragment>
                ))}
                <Modal
                    show={selectedLink !== undefined}
                    onHide={() => setSelectedLink(undefined)}
                >
                    <Modal.Header>
                        <Modal.Title>
                            {selectedLink?.[0]}
                        </Modal.Title>
                        <IconButton
                            Icon={CloseRounded}
                            title="Close"
                            className="ms-auto"
                            onClick={() => setSelectedLink(undefined)}
                        />
                    </Modal.Header>
                    <Modal.Body>
                        {selectedLink?.[1]}
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )
}

function PinnedAssets() {

    const { data: assets } = useAssets();

    if (assets === undefined || assets.length === 0) {
        return <></>
    }

    return (
        <ButlerrCard
            items={{ title: "Your asset shortcuts" }}
            className="mb-3"
            cardProps={{
                className: "d-flex flex-wrap"
            }}
        >
            {assets?.slice().reverse().slice(0, 5).map((a) => (
                <Link
                    key={a.asst_id}
                    to={createAssetRoute(AssetRoutes.INFO, { assetId: a.asst_id })}
                    className={classNames(styles.assetCard, "p-1 d-block rounded-circle bg-white shadow-sm flex-shrink-0")}
                    title={a.asst_name}
                >
                    <ButlerrImage
                        url={!a.asst_profilepic ? undefined : ImageRoutes.DOCUMENT(a.asst_profilepic)}
                        alt="asset profile"
                        width={60}
                        height={60}
                        className="rounded-circle"
                        style={{ objectFit: 'cover' }}
                    />
                </Link>
            ))}

            <Link
                to="/assets"
                title="All assets"
                className={classNames(styles.assetCard, "p-1 bg-transparent d-block rounded-circle flex-shrink-0")}
            >
                <div style={{ width: 60, height: 60, background: "var(--bs-gray-200)" }} className="d-flex align-items-center justify-content-center rounded-circle">
                    <MoreHorizRounded fontSize="large" />
                </div>
            </Link>
        </ButlerrCard>
    )
}

function Statistics() {

    const { data: stats } = useButlerrStats();

    if (!stats) return <></>

    return (
        <ButlerrCard items={{ title: "Announcement" }} className="mb-3">
            <div className="d-flex flex-column">
                <p className="fw-semibold small">
                    Welcome to this pilot version of propcircle.co!
                    Stay tuned and we will be launching more features in the months to come!
                </p>
                <div className="d-flex flex-wrap" style={{ gap: '0.5rem' }}>
                    <span className="flex-grow-1 d-flex align-items-center justify-content-center">
                        <GroupsRounded fontSize="small" className="text-secondary" />
                        <span className="text-secondary">&nbsp;{stats.users}</span>
                    </span>
                    <span className="flex-grow-1 d-flex align-items-center justify-content-center">
                        <PublicRounded fontSize="small" className="text-secondary" />
                        <span className="text-secondary">&nbsp;{stats.countries}</span>
                    </span>
                    <span className="flex-grow-1 d-flex align-items-center justify-content-center">
                        <BusinessRounded fontSize="small" className="text-secondary" />
                        <span className="text-secondary">&nbsp;{stats.companies}</span>
                    </span>
                    <span className="flex-grow-1 d-flex align-items-center justify-content-center">
                        <MapsHomeWorkRounded fontSize="small" className="text-secondary" />
                        <span className="text-secondary">&nbsp;{stats.assets}</span>
                    </span>
                </div>
            </div>
        </ButlerrCard>
    )
}

function Feedback() {

    const getSubject = () => user ? `Support for ${user?.user_socialhandle}` : "Support";

    const history = useHistory();

    const [ val, setVal ] = useState("");

    const SUPPORT_USER_ID = 3;

    const { mutate: createChat, isLoading: isLoadingChat, error: errorChat } = useSocialChatMutations('CREATE');
    const { mutate: createMsg, isLoading: isLoadingMsg, error: errorMsg } = useChatMsgMutations('CREATE');

    const isLoading = isLoadingChat || isLoadingMsg;
    const error = errorChat ?? errorMsg;

    const { data: user } = useButlerrUser();
    const { data: chats } = useSocialChats(false);

    const handleSubmit = (ev: React.FormEvent) => {
        ev.preventDefault();

        const subject = getSubject();
        const existingChatId = chats?.find(c => c.ascv_subject === subject)?.ascv_id;

        if (existingChatId) {
            createMsg({
                chatId: existingChatId,
                attachments: [],
                acms_msg: val
            }, {
                onSuccess: () => {
                    history.push(SocialRoutes.CHATS + '/' + existingChatId);
                    setVal("");
                }
            })
        }
        else {
            createChat({
                ascv_subject: subject,
                members: [{
                    user_id: SUPPORT_USER_ID,
                    user_profile: null,
                    user_socialhandle: ""
                }]
            }, {
                onSuccess: ({ response }) => {
                    const chatId = response.data.ascv_id;
                    if (chatId) {
                        createMsg({
                            chatId,
                            attachments: [],
                            acms_msg: val
                        }, {
                            onSuccess: () => {
                                history.push(SocialRoutes.CHATS + '/' + chatId);
                                setVal("");
                            }
                        })
                    }
                }
            })
        }
    }

    return (
        <ButlerrCard items={{ title: "Support" }} className="mb-3">
            <Form
                onSubmit={handleSubmit}
                className="d-flex flex-column align-items-center"
            >
                <Form.Control
                    value={val}
                    onChange={(e) => setVal(e.target.value)}
                    placeholder="We appreciate any feedback or suggestions"
                    as="textarea"
                    style={{ minHeight: '100px' }}
                    disabled={isLoading}
                />

                <IconButton
                    label="Submit"
                    className="mt-3 px-4"
                    disabled={isLoading}
                    type="submit"
                />
                { error !== null && (
                    <p className="my-2 text-danger">
                        {error.message}
                    </p>
                ) }
            </Form>
        </ButlerrCard>
    )
}