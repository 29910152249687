import { BaseModalProps } from "../../../hooks/useModal";
import { Butlerr } from "../../../types/butlerr";
import * as yup from "yup";
import { useEffect, useState, useRef, useMemo } from "react";
import { Formik } from "formik";
import { useChannelEditors, useSocialChannelMutations } from "../../../services/social-channels.service";
import { Form, InputGroup, Modal } from "react-bootstrap";
import { BootstrapInput } from "../../utils/FormikBootstrapInputs";
import ChannelAvatar from "./ChannelAvatar";
import { formCloseHandler, imageThumbnailParse } from "../../utils/HelperFunctions";
import IconButton from "../../utils/IconButton";
import { Close, CloseRounded, Done, PhotoRounded } from "@mui/icons-material";
import { useWorkProfileStaff } from "../../../services/partner.service";
import UserDropdownButton from "../../utils/UserDropdownButton";
export interface ChannelEditProps extends BaseModalProps {
    channel: Butlerr.Social.Channel;
}

export default function ChannelEdit({ show, onClose, channel } : ChannelEditProps) {

    useEffect(() => {
        setFile(undefined);
    }, [show])

    const schema = yup.object().shape({
        chan_name: yup.string().required("Channel name is required").max(80, ({max}) => `Name cannot be more than ${max} characters`),
        chan_desc: yup.string().max(200, ({max}) => `Description cannot be more than ${max} characters`),
    })

    const { mutate, isLoading, error } = useSocialChannelMutations('EDIT');

    interface AttachmentFile {
        url: string;
        file: File;
    }
    const [file, setFile] = useState<AttachmentFile>();
    const inputRef = useRef<HTMLInputElement>(null);

    const { data: initialEditors } = useChannelEditors(channel.chan_id);
    const [selectedUsers, setSelectedUsers] = useState<Pick<Butlerr.UserInfo, 'user_id' | 'user_socialhandle' | 'user_profile'>[]>([]);

    useEffect(() => {
        setSelectedUsers(initialEditors ?? []);
    }, [initialEditors])

    const dropdownRef = useRef<HTMLButtonElement>(null);

    const { data: staffs } = useWorkProfileStaff(channel.chan_partid)

    const selectableUsers = useMemo(() => [
        ...selectedUsers,
        ...(staffs?.filter(u => u.wpst_status === 1 && selectedUsers.findIndex(u2 => u2.user_id === u.user_id) === -1) ?? [])
    ], [ selectedUsers, staffs ])

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                chan_name: channel.chan_name ?? "",
                chan_desc: channel.chan_desc ?? ""
            }}
            validationSchema={schema}
            onSubmit={(val, { resetForm }) => {
                mutate({
                    ...val,
                    chan_id: channel.chan_id,
                    chan_profile: file?.file,
                    users: selectedUsers
                }, {
                    onSuccess: () => {
                        onClose();
                        resetForm();
                    }
                })
            }}
        >
            {({ dirty, values, handleSubmit, setFieldValue }) => (
                <Modal
                    show={show}
                    onHide={() => formCloseHandler(onClose, dirty || (initialEditors?.length ?? 0) !== selectedUsers.length)}
                    backdrop={isLoading ? "static" : undefined}
                    keyboard={!isLoading}
                >
                    <form onSubmit={handleSubmit}>
                        <Modal.Header>
                            <Modal.Title>Edit channel</Modal.Title>
                            <IconButton
                                title="Close modal"
                                Icon={CloseRounded}
                                className="ms-auto"
                                disabled={isLoading}
                                onClick={() => formCloseHandler(onClose, dirty || (initialEditors?.length ?? 0) !== selectedUsers.length)}
                            />
                        </Modal.Header>
                        <Modal.Body>
                            <div className="mb-2 d-flex flex-column align-items-center">
                                <input
                                    hidden
                                    ref={inputRef}
                                    accept="image/*"
                                    type="file"
                                    onChange={(e) => {
                                        imageThumbnailParse(e.target.files?.[0])
                                        .then(setFile)
                                        return false;
                                    }}
                                />
                                <ChannelAvatar
                                    channel={{ chan_id: channel.chan_id, chan_name: values.chan_name || channel.chan_name, chan_profile: channel.chan_profile }}
                                    width={100}
                                    height={100}
                                    src={file?.url}
                                    className="mb-3 shadow-sm"
                                />
                                <IconButton
                                    Icon={PhotoRounded}
                                    transparent
                                    className="text-black"
                                    title="Add Image"
                                    label="Upload"
                                    onClick={() => inputRef.current?.click()}
                                />
                            </div>
                            <div className="mb-2">
                                <BootstrapInput
                                    id="chan_name"
                                    label="Name"
                                    placeholder="Name"
                                    required
                                />
                            </div>
                            <div className="mb-2">
                                <BootstrapInput
                                    id="chan_desc"
                                    label="Description"
                                    placeholder="Description"
                                    required={false}
                                    as="textarea"
                                    rows="3"
                                />
                            </div>
                            <label
                                style={{ fontSize: 12.96 }}
                                className="text-secondary mb-2 fw-bold text-uppercase"
                            >
                                Editors
                            </label>
                            {selectableUsers.length !== 0 ? (
                                <InputGroup className="mb-2">
                                    <Form.Control
                                        placeholder="Select user to add to Editor"
                                        readOnly
                                        value={selectedUsers.map(u => u.user_socialhandle).join(', ')}
                                        tabIndex={0}
                                        onClick={() => dropdownRef.current?.click()}
                                    />
                                    <UserDropdownButton
                                        users={selectableUsers}
                                        selectedUsers={selectedUsers}
                                        setSelectedUsers={setSelectedUsers}
                                        dropdownProps={{ placement: 'top' }}
                                        ref={dropdownRef}
                                    />
                                </InputGroup>
                             ) : ( 
                                 <p className='text-secondary'>
                                    There are no Staff(s) in the current work
                                    profile
                                </p> 
                             )} 
                        </Modal.Body>
                        <Modal.Footer>
                            { error !== null && <p className="text-danger text-truncate w-100">{error.message}</p> }

                            <IconButton
                                transparent
                                Icon={Close}
                                iconHtmlColor="var(--primary)"
                                className="border border-primary text-primary"
                                label="Cancel"
                                title="Cancel"
                                disabled={isLoading}
                                onClick={() => formCloseHandler(onClose, dirty || (initialEditors?.length ?? 0) !== selectedUsers.length)}
                            />
                            <IconButton
                                Icon={Done}
                                label={isLoading ? "Saving..." : "Save"}
                                disabled={isLoading}
                                title="Save"
                                type="submit"
                            />
                        </Modal.Footer>
                    </form>
                </Modal>
            )}
        </Formik>
    )
}