import { useMemo, useState } from "react";
import { BaseModalProps } from "../../../hooks/useModal";
import { Modal, Button, ButtonProps } from "react-bootstrap";
import UserList from "./UserList";
import { SearchUserOptions, useUserSearch } from "../../../services/user.service";
import { CloseRounded, GroupsRounded, ThumbUpRounded } from "@mui/icons-material";
import IconButton from "../../utils/IconButton";
import { Butlerr } from "../../../types/butlerr";

import { ReactComponent as LikeIcon } from "../../../assets/icons/reaction-thumbsup.svg";
import { ReactComponent as LoveIcon } from "../../../assets/icons/reaction-heart.svg";
import { ReactComponent as DislikeIcon } from "../../../assets/icons/reaction-thumbsdown.svg";
import { ReactComponent as HighFiveIcon } from "../../../assets/icons/reaction-highfive.svg";
import { ReactComponent as ClapIcon } from "../../../assets/icons/reaction-clap.svg";
import classNames from "classnames";
import { SocialPostReactionKey } from "../../../types/butlerr-enums";
import styles from '../social.module.css';

interface FollowListModalProps extends BaseModalProps {
    initialSelectedView?: 'Follower' | 'Following';

    //Hidden if undefined or 0
    follower?: number;
    following?: number;

    user_id?: number;
    chan_id?: number;
}

export function FollowListModal({ show, onClose, initialSelectedView, user_id, chan_id, follower = 0, following = 0 } : FollowListModalProps) {

    const [ activeHeader, setActiveHeader ] = useState<'Follower' | 'Following'>(initialSelectedView ?? 'Follower');


    const [ searchOptions, headers ] = useMemo(() => {
        const options: SearchUserOptions = {
            show_current_user: true
        };

        if (user_id !== undefined) {
            if (activeHeader === 'Follower') {
                options.follower_of_user = user_id;
            }
            else {
                options.following_of_user = user_id;
            }
        }
        else if (chan_id !== undefined) {
            options.follower_of_channel = chan_id;
        }

        const arr = [ 'Follower', 'Following' ] as const;
        const followCounts = { Follower: follower, Following: following };

        return [
            options,
            chan_id === undefined && (
                <div className="overflow-x-auto d-flex align-items-stretch justify-content-start mb-3" style={{ gridGap: '0.5rem' }}>
                    {arr.map((key) => (
                        <HeaderBtn
                            key={key}
                            onClick={() => setActiveHeader(key)}
                            active={activeHeader === key}
                            hidden={followCounts[key] === 0}
                            label={(
                                <>
                                    <span className="fw-bold me-2">{ key }</span>
                                    &nbsp;{ followCounts[key] }
                                </>
                            )}
                            style={{ maxWidth: '150px' }}
                        />
                    ))}
                </div>
            )
        ];
    }, [activeHeader, chan_id, user_id, follower, following]);

    //Query
    const { data: _users, isLoading, hasNextPage, fetchNextPage } = useUserSearch(searchOptions, show && ( user_id !== undefined || chan_id !== undefined ));

    const users = useMemo(() => _users?.pages.flatMap(p => p.result), [_users]);

    return(
        <Modal show={show} onHide={onClose} size="lg" scrollable>
            <Modal.Header>
                <Modal.Title className="d-flex align-items-center">
                    <GroupsRounded className="me-2" fontSize="small" />
                    { activeHeader }{activeHeader === 'Follower' && (users === undefined || users.length > 1) && 's'}
                </Modal.Title>
                <IconButton
                    Icon={CloseRounded}
                    onClick={onClose}
                    title="Close"
                    transparent={true}
                    border={false}
                />
            </Modal.Header>

            <Modal.Body>
                { headers }
                <UserList
                    users={users}
                    isLoading={isLoading || Object.keys(searchOptions).length === 1}
                    hasNextPage={hasNextPage}
                    fetchNextPage={fetchNextPage}
                    allowFollowerFollowingModal={false}
                />
            </Modal.Body>
        </Modal>
    )
}

interface ReactionListModalProps extends BaseModalProps {
    post: Butlerr.Social.Post;
}

export function ReactionListModal({ show, onClose, post } : ReactionListModalProps) {

    const [ activeHeader, setActiveHeader ] = useState<SocialPostReactionKey>();
    const reactionHeaders = useMemo(() => {
        const arr = [
            [
                undefined,
                <><span className="fw-bold">All</span> ({post.loves + post.likes + post.dislikes + post.high_fives + post.claps})</>
            ],
            [ LoveIcon, post.loves, 'O' ],
            [ LikeIcon, post.likes, 'L' ],
            [ DislikeIcon, post.dislikes, 'D' ],
            [ HighFiveIcon, post.high_fives, 'H' ],
            [ ClapIcon, post.claps, 'C' ],
        ] as const;

        return (
            <div className="overflow-x-auto d-flex align-items-stretch justify-content-start mb-3" style={{ gridGap: '0.5rem' }}>
                {arr.map(([ Icon, label, key ], idx) => (
                    <HeaderBtn
                        key={idx}
                        onClick={() => setActiveHeader(key)}
                        active={activeHeader === key}
                        Icon={Icon}
                        label={label}
                        hidden={label === 0}
                    />
                ))}
            </div>
        )
    }, [ post.loves, post.likes, post.dislikes, post.high_fives, post.claps, activeHeader ]);

    //Query
    const { data: _users, isLoading, hasNextPage, fetchNextPage } = useUserSearch({
        reacted_to_post: post.post_id,
        show_current_user: true,
        post_reaction: activeHeader
    }, show);

    const users = useMemo(() => _users?.pages.flatMap(p => p.result), [_users]);

    return(
        <Modal show={show} onHide={onClose} size="lg" scrollable>
            <Modal.Header>
                <Modal.Title className="d-flex align-items-center">
                    <ThumbUpRounded className="me-2" fontSize="small" />
                    Reaction{(users === undefined || users.length > 1) && 's'}
                </Modal.Title>
                <IconButton
                    Icon={CloseRounded}
                    onClick={onClose}
                    title="Close"
                    transparent={true}
                    border={false}
                />
            </Modal.Header>

            <Modal.Body>
                { reactionHeaders }
                <UserList
                    users={users}
                    isLoading={isLoading}
                    hasNextPage={hasNextPage}
                    fetchNextPage={fetchNextPage}
                    allowFollowerFollowingModal={false}
                />
            </Modal.Body>
        </Modal>
    )
}

interface HeaderBtnProps extends ButtonProps {
    label: React.ReactNode;
    active: boolean;
    Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    hidden?: boolean;
}

const HeaderBtn = ({ label, active, Icon, onClick, hidden, ...props }: HeaderBtnProps) => {
    if (hidden) {
        return <></>
    }
    return (
        <Button
            onClick={onClick}
            className={classNames(
                "flex-fill px-4 py-2 text-dark border-0 d-flex align-items-center justify-content-center",
                styles.modalHeaderBtn,
                active && styles.active,
                hidden && styles.hidden
            )}
            variant="clear"
            {...props}
        >
            { Icon && <Icon className="me-3" /> }
            <span className="fw-light">
                {label}
            </span>
        </Button>
    )
}