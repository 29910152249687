import { Col, Container, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import { formCloseHandler } from '../../utils/HelperFunctions';
import { BootstrapInput, BootstrapSwitch } from '../../utils/FormikBootstrapInputs';
import { Formik } from 'formik';
import { Transition } from 'react-transition-group';
import * as yup from 'yup';
import { Add } from '@mui/icons-material';

import { Butlerr } from '../../../types/butlerr';
import { usePartnerMutations } from '../../../services/partner.service';
import UserDropdownButton from '../../utils/UserDropdownButton';
import BootstrapModalFooter from '../../utils/BootstrapModalFooter';

interface PartnerStaffForm {
    wpst_position: string;
    wpst_depart: string;
    wpst_admin: boolean;
    users: Pick<Butlerr.User, 'user_id' | 'user_socialhandle' | 'user_profile'>[];
}

interface AddPartnerStaffModalProps {
    show: boolean;
    onClose: () => void;
    initialValue?: Butlerr.Work.WorkProfileStaff;
    partner: Butlerr.Work.WorkProfile;
}

const AddPartnerStaffModal = ({
    show,
    onClose: onModalClose,
    initialValue,
    partner,
}: AddPartnerStaffModalProps) => {
    const {
        mutate: createPartnerStaff,
        isLoading,
        error,
    } = usePartnerMutations('CREATE_STAFF');

    const handleClose = async () => {
        if (error !== null) error.message = '';
        if (!isLoading) onModalClose();
    };

    const initialValues: PartnerStaffForm = {
        wpst_position: initialValue?.wpst_position ?? '',
        wpst_depart: initialValue?.wpst_depart ?? '',
        wpst_admin:
            initialValue?.wpst_admin !== undefined && initialValue?.wpst_admin !== null
                ? initialValue?.wpst_admin === 1
                : false,
        users: [],
    };

    const validationSchema = yup.object({
        wpst_position: yup
            .string()
            .max(45, ({ max }) => `Maximum ${max} characters`)
            .nullable(),
        wpst_depart: yup
            .string()
            .max(45, ({ max }) => `Maximum ${max} characters`)
            .nullable(),
        wpst_admin: yup.boolean().default(false).required(),
        users: yup
            .array()
            .min(1, 'Please select a user')
            .max(1, 'Select only 1 user')
            .required('Please select a user'),
    });

    const onSubmit = (values: PartnerStaffForm) => {
        const { wpst_admin, users, ...data } = values;
        const form = {
            wkpf_id: partner.wkpf_id,
            user_id: users[0].user_id,
            wpst_admin: Number(wpst_admin),
            ...data,
        };

        if (initialValue === undefined) {
            createPartnerStaff(form, {
                onSuccess: () => {
                    formCloseHandler(handleClose);
                },
            });
        }
    };

    return (
        <>
            <Transition in={show} timeout={{ exit: 300 }} unmountOnExit>
                {() => (
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        {({ submitForm, setFieldValue, values, dirty }) => (
                            <Form noValidate>
                                <Modal
                                    show={show}
                                    onHide={formCloseHandler.bind(undefined, handleClose, dirty)}
                                    size="lg"
                                    backdrop={isLoading ? 'static' : undefined}
                                    keyboard={!isLoading}
                                    centered
                                >
                                    <Modal.Header>
                                        <Container>
                                            <Row>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <Modal.Title>Add Staff</Modal.Title>
                                                </div>
                                            </Row>
                                        </Container>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Container>
                                            <Row className="g-3">
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label className="fw-bold text-uppercase text-secondary small undefined form-label">
                                                            Select a user
                                                            <span className="text-danger">*</span>
                                                        </Form.Label>

                                                        <InputGroup>
                                                            <Form.Control
                                                                placeholder="User"
                                                                readOnly
                                                                value={values.users
                                                                    .map((u) => u.user_socialhandle)
                                                                    .toString()}
                                                            />

                                                            <UserDropdownButton
                                                                inviteOption={true}
                                                                limit={1}
                                                                selectedUsers={values.users}
                                                                setSelectedUsers={(selectedUsers) => {
                                                                    setFieldValue(
                                                                        'users',
                                                                        selectedUsers
                                                                    );
                                                                }}
                                                            />
                                                        </InputGroup>
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <BootstrapSwitch
                                                            id="wpst_admin"
                                                            className="text-secondary text-uppercase mt-1 d-flex justify-content-start"
                                                            style={{ fontSize: 12.96 }}
                                                            label="&nbsp;&nbsp;Admin user"
                                                            disabled={isLoading}
                                                        />
                                                    </Form.Group>
                                                </Col>

                                                <Form.Group as={Col} lg>
                                                    <BootstrapInput
                                                        id="wpst_position"
                                                        type="text"
                                                        required={false}
                                                        value={values.wpst_position}
                                                        placeholder="Position"
                                                        label="Position"
                                                        disabled={isLoading}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} lg>
                                                    <BootstrapInput
                                                        id="wpst_depart"
                                                        type="text"
                                                        required={false}
                                                        value={values.wpst_depart}
                                                        placeholder="Department"
                                                        label="Department"
                                                        disabled={isLoading}
                                                    />
                                                </Form.Group>
                                            </Row>
                                        </Container>
                                    </Modal.Body>

                                    <BootstrapModalFooter
                                        error={error}
                                        cancelBtnProps={{
                                            onClick: () => formCloseHandler(handleClose, dirty),
                                            disabled: isLoading,
                                        }}
                                        confirmBtnProps={{
                                            Icon: Add,
                                            label: isLoading ? 'Adding...' : 'Add',
                                            onClick: () => submitForm(),
                                            disabled: isLoading || values.users.length === 0,
                                        }}
                                    />
                                    
                                </Modal>
                            </Form>
                        )}
                    </Formik>
                )}
            </Transition>
        </>
    );
};

export default AddPartnerStaffModal;
