import {
    AddTwoTone,
    Close,
    DeleteOutline,
    Done,
    EditOutlined,
    NavigateNext,
    Save,
} from '@mui/icons-material';
import { Formik } from 'formik';
import { useState, useMemo, useEffect, useCallback } from 'react';
import { Form, Modal, Container, Row, Col } from 'react-bootstrap';
import { Transition } from 'react-transition-group';
import * as yup from 'yup';

import { useCashflowMutations } from '../../../services/assetcashflow.service';
import {
    useLeaseAdjustmentMutations,
    useLeaseAdjustments,
    useLeaseDocuments,
    useLeaseMutations,
} from '../../../services/assetlease.service';
import { Butlerr } from '../../../types/butlerr';
import { ButlerrLeaseAmountPeriod, LeaseAmountPeriodKey } from '../../../types/butlerr-enums';
import IconButton from '../../utils/IconButton';
import styles from '../asset.module.css';
import {
    convertDateToYYYYMMDD,
    formatDate,
    formCloseHandler,
    removeNumberFormatting,
} from '../../utils/HelperFunctions';
//import DocumentAssetModuleTable, { DocumentPickerModal } from '../../documents/DocumentsLink';
import {
    BootstrapFormLabel,
    BootstrapInput,
    BootstrapInputPhone,
    BootstrapInputPrice,
    BootstrapSelect,
} from '../../utils/FormikBootstrapInputs';
import BootstrapTable from '../../utils/BootstrapTable';
import { isValidPhoneNumber, Country as CountryCode } from 'react-phone-number-input';
//import { useDocumentDownload } from '../../../services/documents.service';
import LineClampText from '../../utils/LineClampText';
import DestructiveModal from '../../utils/DestructiveModal';
import { TableBodySkeleton } from '../../utils/TableSkeleton';
import { DocumentSelectModal } from '../../documents/DocsModal';
import classNames from 'classnames';
import { useDownloadWithPostToken } from '../../../services/useButlerrAPI';
import useModal from '../../../hooks/useModal';
import { AssetDocsTable } from '../../documents/AssetDocLink';
import BootstrapModalFooter from '../../utils/BootstrapModalFooter';

type LeaseForm = {
    leas_start: string;
    leas_end: string;
    // leas_bybillcycle: boolean;
    leas_amt: string;
    leas_amtperiod: LeaseAmountPeriodKey | ''; // Allow '' to allow the user to select
    leas_billcycle: LeaseAmountPeriodKey | ''; // Allow '' to allow the user to select
    // leas_billday: LeaseDueDayKey;
    leas_tenantname: string;
    leas_tenantemail: string;
    leas_tenantphone: string;
    leas_deposit: string;
    leas_notes: string;
    // leas_status: boolean;
};

interface AssetLeasesModalProps {
    show: boolean;
    onModalClose: () => void;
    initialValue?: Butlerr.Asset.Lease;
    assetId: number;
    asset: Butlerr.Asset;
}

const AssetLeasesModal = ({
    show,
    onModalClose,
    assetId,
    initialValue,
    asset,
}: AssetLeasesModalProps) => {
    const { mutate: createCashflow } = useCashflowMutations('CREATE');
    const {
        mutate: createLease,
        isLoading: isCreateLoading,
        error: createError,
    } = useLeaseMutations('CREATE');
    const {
        mutate: editLease,
        isLoading: isEditLoading,
        error: editError,
    } = useLeaseMutations('EDIT');

    const { mutateAsync: deleteLease } = useLeaseMutations('DELETE');

    // delete modal
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedLeaseId, setSelectedLeaseId] = useState<number>();

    const onDelete = (leaseId: number) => {
        setSelectedLeaseId(leaseId);
        setShowDeleteModal(true);
    };

    const onDeleteClose = () => {
        setShowDeleteModal(false);
    };

    const onDeleteConfirm = async () => {
        await deleteLease({ assetId, leaseId: selectedLeaseId ?? -1 });
        handleClose();
    };

    const [showDocModal, setShowDocModal] = useState(false);
    const [docModalValues, setDocModalValues] =
        useState<
            Omit<
                Butlerr.Asset.Lease,
                'asst_id' | 'leas_bybillcycle' | 'leas_billday' | 'leas_status' | 'active_adj'
            >
        >();

    const isLoading = initialValue === undefined ? isCreateLoading : isEditLoading;
    const error = initialValue === undefined ? createError : editError;

    const handleClose = () => {
        if (error !== null) error.message = '';
        if (!isLoading) onModalClose();
    };

    const initialValues: LeaseForm = {
        leas_start: initialValue?.leas_start
            ? convertDateToYYYYMMDD(initialValue?.leas_start) ?? new Date().toString()
            : '',
        leas_end: initialValue?.leas_end
            ? convertDateToYYYYMMDD(initialValue?.leas_end) ?? new Date().toString()
            : '',
        // leas_bybillcycle: initialValue?.leas_bybillcycle === 1,
        leas_amt:
            initialValue?.leas_amt !== undefined
                ? Number(initialValue.leas_amt).toLocaleString()
                : '',
        leas_amtperiod: initialValue?.leas_amtperiod ?? '',
        leas_billcycle: initialValue?.leas_billcycle ?? '',
        // leas_billday: initialValue?.leas_billday?.toString() ?? '',
        leas_tenantname: initialValue?.leas_tenantname ?? '',
        leas_tenantemail: initialValue?.leas_tenantemail ?? '',
        leas_tenantphone: initialValue?.leas_tenantphone ?? '',
        leas_deposit:
            initialValue?.leas_deposit !== undefined
                ? Number(initialValue.leas_deposit).toLocaleString()
                : '',
        leas_notes: initialValue?.leas_notes ?? '',
        // leas_status: initialValue?.leas_status !== 0,
    };

    //const phoneRegExp =
    //    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
    const validationSchema = yup.object().shape({
        leas_start: yup
            .date()
            .min(
                new Date(new Date(asset.asst_settledate ?? new Date()).setHours(0, 0, 0, 0)),
                'Must be after the asset settlement date (inclusive)'
            )
            .required('Required'),
        leas_end: yup
            .date()
            .min(yup.ref('leas_start'), 'Must be after the start date')
            .required('Required'),
        // leas_bybillcycle: yup.boolean(),
        leas_amt: yup.string().required('Required'),
        leas_amtperiod: yup
            .string()
            .required('Required')
            .oneOf(Object.keys(ButlerrLeaseAmountPeriod)),
        leas_billcycle: yup
            .string()
            .required('Required')
            .oneOf(Object.keys(ButlerrLeaseAmountPeriod)),
        // leas_billday: yup.string().required('Required').oneOf(Object.keys(ButlerrLeaseDueDay)),
        leas_tenantname: yup
            .string()
            .required('Required')
            .max(100, ({ max }) => `Maximum ${max} characters`),
        leas_tenantemail: yup
            .string()
            .email('Invalid email')
            .max(45, ({ max }) => `Maximum ${max} characters`),
        leas_tenantphone: yup
            .string()
            .test('is-valid-phone-number', 'Invalid phone number', (value: string | undefined) => {
                if (!value) return true; // remove this line if field is required
                return isValidPhoneNumber(value);
            }),
        //.matches(/^\d+$/, 'Invalid phone number')
        //.matches(phoneRegExp, "That doesn't look like a phone number!")
        //.max(15, '15 character limit'),
        leas_deposit: yup.string().nullable(),
        leas_notes: yup.string().max(100, ({ max }) => `Maximum ${max} characters`),
        // leas_status: yup.boolean().required(),
    });
    const onDocSubmit = (
        form: Omit<
            Butlerr.Asset.Lease,
            'asst_id' | 'leas_bybillcycle' | 'leas_billday' | 'leas_status' | 'active_adj'
        >
    ) => {
        const [_depositAmount] = removeNumberFormatting(String(form.leas_deposit));
        const depositAmount = Number(_depositAmount);

        if (depositAmount > 0) {
            //ask if want to save to cashflow
            const addToCashflow = window.confirm(
                'Do you want to add the deposit of this lease into cashflow?'
            );

            if (addToCashflow) {
                //save to db
                createCashflow(
                    {
                        asst_id: assetId,
                        ascf_type: 'TN',
                        ascf_date: form.leas_start,
                        ascf_amt: depositAmount,
                        ascf_desc: `Deposit from ${form.leas_tenantname}`,
                        ascf_currency: asset.asst_currency,
                        ascf_doclink: null,
                        ascf_status: 1, //verified by default
                    },
                    {
                        onSuccess: () => {
                            alert('Cashflow created successfully.');
                        },
                    }
                );
            }
        }
        formCloseHandler(handleClose);
        setShowDocModal(false);
    };

    const onSubmit = ({ leas_amt, leas_deposit, ...data }: LeaseForm) => {
        const [leaseAmount] = removeNumberFormatting(String(leas_amt));
        const [depositAmount] = removeNumberFormatting(String(leas_deposit));
        const form = {
            ...data,
            // leas_bybillcycle: Number(leas_bybillcycle),
            // leas_billday: Number(leas_billday) || 0,
            // leas_status: Number(leas_status),
            leas_amt: Number(leaseAmount),
            leas_deposit: Number(depositAmount),
        };

        if (initialValue === undefined) {
            createLease(
                { assetId, ...form },
                {
                    onSuccess: ({ response }) => {
                        //Open add doc modal
                        setDocModalValues({ ...form, leas_id: response.data.leas_id });
                        setShowDocModal(true);
                    },
                }
            );
        } else {
            editLease(
                { assetId, leaseId: initialValue.leas_id, ...form },
                {
                    onSuccess: () => formCloseHandler(handleClose),
                }
            );
        }
    };

    const leaseIdForDoc = initialValue?.leas_id ?? docModalValues?.leas_id;

    const { data: files } = useLeaseDocuments(
        assetId,
        leaseIdForDoc ?? -1,
        show && leaseIdForDoc !== undefined
    );
    const { mutate: addDoc } = useLeaseMutations('ADD_DOCUMENT');
    const { mutate: _removeDoc } = useLeaseMutations('REMOVE_DOCUMENT');

    const removeDoc = useCallback(
        (doc: Butlerr.Document.File) => {
            _removeDoc({
                assetId,
                leaseId: leaseIdForDoc ?? -1,
                ...doc,
            });
        },
        [_removeDoc, assetId, leaseIdForDoc]
    );

    return (
        <>
            <Transition in={show} timeout={{ exit: 300 }} unmountOnExit>
                {showDocModal === false ? (
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        {({ submitForm, values, setFieldValue, dirty }) => (
                            <Form noValidate>
                                <Modal
                                    show={show}
                                    onHide={formCloseHandler.bind(undefined, handleClose, dirty)}
                                    scrollable
                                    size="lg"
                                    backdrop={isLoading ? 'static' : undefined}
                                    keyboard={!isLoading}
                                    centered
                                >
                                    <Modal.Header>
                                        <Container>
                                            <Row>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <Modal.Title>
                                                        {initialValue === undefined
                                                            ? 'Add '
                                                            : 'Edit '}
                                                        Lease Details
                                                    </Modal.Title>
                                                </div>
                                            </Row>
                                        </Container>
                                    </Modal.Header>

                                    <Modal.Body>
                                        <Container>
                                            <Row className="mb-3 g-3">
                                                <Form.Group as={Col} lg>
                                                    <BootstrapInput
                                                        id="leas_tenantname"
                                                        label="Tenant Name"
                                                        placeholder="Name"
                                                        aria-label="Name"
                                                        disabled={
                                                            asset.status === 'AR' || isLoading
                                                        }
                                                    />
                                                </Form.Group>

                                                <Form.Group as={Col} lg>
                                                    <BootstrapInputPhone
                                                        id="leas_tenantphone"
                                                        label="Contact Number"
                                                        placeholder="Contact Number"
                                                        disabled={
                                                            asset.status === 'AR' || isLoading
                                                        }
                                                        required={false}
                                                        defaultCountry={asset.asst_country as CountryCode}
                                                        value={values.leas_tenantphone ?? ''}
                                                        onChange={(val) => {
                                                            setFieldValue('leas_tenantphone', val);
                                                        }}
                                                    />
                                                </Form.Group>

                                                <Form.Group as={Col} lg>
                                                    <BootstrapInput
                                                        id="leas_tenantemail"
                                                        label="Contact Email"
                                                        placeholder="Email"
                                                        aria-label="Email"
                                                        disabled={
                                                            asset.status === 'AR' || isLoading
                                                        }
                                                        required={false}
                                                    />
                                                </Form.Group>
                                            </Row>

                                            <Row className="mb-3 g-3">
                                                <Form.Group as={Col} lg>
                                                    <BootstrapInput
                                                        type="date"
                                                        id="leas_start"
                                                        label="Start Date"
                                                        placeholder="Start Date"
                                                        aria-label="Start Date"
                                                        disabled={
                                                            asset.status === 'AR' || isLoading
                                                        }
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} lg>
                                                    <BootstrapInput
                                                        type="date"
                                                        id="leas_end"
                                                        label="End Date"
                                                        placeholder="End Date"
                                                        aria-label="End Date"
                                                        disabled={
                                                            asset.status === 'AR' || isLoading
                                                        }
                                                    />
                                                </Form.Group>
                                            </Row>

                                            <Row className="g-3">
                                                <Col
                                                    md="auto"
                                                    className="d-flex align-items-center"
                                                >
                                                    Renting for
                                                </Col>
                                                <Form.Group as={Col}>
                                                    <BootstrapInputPrice
                                                        id="leas_amt"
                                                        placeholder="Rental Amount"
                                                        aria-label="Amount"
                                                        disabled={
                                                            asset.status === 'AR' || isLoading
                                                        }
                                                        inputGroupText={asset.asst_currency}
                                                    />
                                                </Form.Group>
                                                <Col
                                                    md="auto"
                                                    className="d-flex align-items-center"
                                                >
                                                    per
                                                </Col>
                                                <Form.Group as={Col}>
                                                    <BootstrapSelect
                                                        id="leas_amtperiod"
                                                        allowedKeys={Object.keys(
                                                            ButlerrLeaseAmountPeriod
                                                        )}
                                                        allowedLabels={['Month', 'Week']}
                                                        disabled={
                                                            asset.status === 'AR' || isLoading
                                                        }
                                                    />
                                                </Form.Group>
                                                <Col
                                                    md="auto"
                                                    className="d-flex align-items-center"
                                                >
                                                    payable
                                                </Col>
                                                {(() => {
                                                    //only allow selection of values greater than value of amount period
                                                    const allowedKeys = Object.keys(
                                                        ButlerrLeaseAmountPeriod
                                                    ).filter((_, idx) => {
                                                        const index = Object.keys(
                                                            ButlerrLeaseAmountPeriod
                                                        ).findIndex(
                                                            (v) => v === values.leas_amtperiod
                                                        );
                                                        return index === -1 || index >= idx;
                                                    });
                                                    const allowedValues = allowedKeys.map(
                                                        (key) =>
                                                            ButlerrLeaseAmountPeriod[
                                                                key as LeaseAmountPeriodKey
                                                            ]
                                                    );
                                                    if (
                                                        values.leas_billcycle &&
                                                        !allowedKeys.includes(
                                                            String(values.leas_billcycle)
                                                        )
                                                    ) {
                                                        /**
                                                         * reset value if value is not empty & the selected value is no longer allowed. Without this, causes issue when the selected value is not allowed anymore.
                                                         * The UI changes to empty value (since the option is no longer there), but the value in state remains as the invalid billcycle value. So, the backend will throw validation error
                                                         */
                                                        setFieldValue('leas_billcycle', '', false);
                                                    }

                                                    return (
                                                        <Form.Group as={Col}>
                                                            <BootstrapSelect
                                                                id="leas_billcycle"
                                                                allowedKeys={allowedKeys}
                                                                allowedLabels={allowedValues}
                                                                disabled={
                                                                    asset.status === 'AR' ||
                                                                    isLoading
                                                                }
                                                            />
                                                        </Form.Group>
                                                    );
                                                })()}
                                            </Row>

                                            <Row className="mb-3 g-3 mt-1">
                                                <Col
                                                    md="auto"
                                                    className="d-flex align-items-center"
                                                >
                                                    With a deposit of:
                                                </Col>
                                                <Form.Group as={Col}>
                                                    <BootstrapInputPrice
                                                        id="leas_deposit"
                                                        placeholder="Deposit"
                                                        aria-label="Deposit"
                                                        disabled={
                                                            asset.status === 'AR' || isLoading
                                                        }
                                                        required={false}
                                                        inputGroupText={asset.asst_currency}
                                                    />
                                                </Form.Group>
                                            </Row>

                                            <Row className="mb-3 g-3">
                                                <Form.Group as={Col} lg>
                                                    <BootstrapInput
                                                        as="textarea"
                                                        id="leas_notes"
                                                        label="Notes"
                                                        placeholder="Notes"
                                                        aria-label="Notes"
                                                        disabled={
                                                            asset.status === 'AR' || isLoading
                                                        }
                                                        required={false}
                                                    />
                                                </Form.Group>
                                            </Row>

                                            {initialValue !== undefined && (
                                                <>
                                                    <Row className="mb-3">
                                                        <Col>
                                                            <LeaseAdjustments
                                                                asset={asset}
                                                                lease={initialValue}
                                                            />
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col>
                                                            <AssetDocsTable
                                                                files={files}
                                                                asset={asset}
                                                                onAdd={
                                                                    leaseIdForDoc
                                                                        ? (f) =>
                                                                              addDoc({
                                                                                  assetId,
                                                                                  leaseId:
                                                                                      leaseIdForDoc,
                                                                                  ...f,
                                                                              })
                                                                        : undefined
                                                                }
                                                                onRemove={removeDoc}
                                                                initialFolder={asset.doct_leas_id}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </>
                                            )}
                                        </Container>
                                    </Modal.Body>
                                    <BootstrapModalFooter
                                        error={error}
                                        deleteBtnProps={{
                                            onClick: () => onDelete(initialValue?.leas_id!),
                                            disabled: asset.status === 'AR' || isLoading,
                                            className: initialValue === undefined ? 'd-none' : '',
                                        }}
                                        cancelBtnProps={{
                                            onClick: () => formCloseHandler(handleClose, dirty),
                                            disabled: isLoading,
                                        }}
                                        confirmBtnProps={{
                                            Icon: initialValue === undefined ? NavigateNext : Done,
                                            label:
                                                initialValue === undefined
                                                    ? isLoading
                                                        ? 'Loading...'
                                                        : 'Next'
                                                    : isLoading
                                                    ? 'Saving...'
                                                    : 'Save',
                                            onClick:
                                                initialValue === undefined
                                                    ? submitForm
                                                    : !dirty
                                                    ? handleClose
                                                    : submitForm,
                                            disabled: asset.status === 'AR' || isLoading,
                                        }}
                                    />
                                </Modal>
                            </Form>
                        )}
                    </Formik>
                ) : (
                    <Formik initialValues={docModalValues!} onSubmit={onDocSubmit}>
                        {({ submitForm }) => (
                            <Form noValidate>
                                <Modal
                                    show={show}
                                    onHide={() => {
                                        handleClose();
                                        setShowDocModal(false);
                                    }}
                                    scrollable
                                    size="lg"
                                    centered
                                >
                                    <Modal.Header>
                                        <Container>
                                            <Row>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <Modal.Title>Add Lease Document</Modal.Title>
                                                </div>
                                            </Row>
                                        </Container>
                                    </Modal.Header>

                                    <Modal.Body>
                                        <Container>
                                            <Row className="mt-2">
                                                <Col>
                                                    <AssetDocsTable
                                                        files={files}
                                                        asset={asset}
                                                        onAdd={
                                                            leaseIdForDoc
                                                                ? (f) =>
                                                                      addDoc({
                                                                          assetId,
                                                                          leaseId: leaseIdForDoc,
                                                                          ...f,
                                                                      })
                                                                : undefined
                                                        }
                                                        onRemove={removeDoc}
                                                        initialFolder={asset.doct_leas_id}
                                                    />
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Modal.Body>
                                    <BootstrapModalFooter
                                        error={null}
                                        confirmBtnProps={{
                                            Icon: Close,
                                            label: 'Close',
                                            onClick: () => submitForm(),
                                        }}
                                    />
                                </Modal>
                            </Form>
                        )}
                    </Formik>
                )}
            </Transition>

            <DestructiveModal
                show={showDeleteModal}
                onClose={onDeleteClose}
                onConfirm={onDeleteConfirm}
                title="Delete lease?"
                description="All document(s) linked to this lease will not be affected."
                className="modal-layer-1"
                backdropClassName="modal-layer-1"
            />
        </>
    );
};

interface LeaseAdjustmentsProps {
    lease: Butlerr.Asset.Lease;
    asset: Butlerr.Asset;
}

function LeaseAdjustments({ asset, lease }: LeaseAdjustmentsProps) {
    const assetId = asset.asst_id;
    const { data: adjustments } = useLeaseAdjustments(assetId, lease.leas_id);

    const [addingAdjustment, setAddingAdjustment] = useState(false);

    const headerNames = useMemo(() => ['FROM', 'TO', 'AMOUNT', 'DOCUMENT', ''], []);

    return (
        <>
            <div className="mb-2 d-flex justify-content-between align-items-center">
                <BootstrapFormLabel htmlFor={undefined} className="mb-0">
                    Adjustments
                </BootstrapFormLabel>
                {!addingAdjustment && asset.status !== 'AR' && (
                    <IconButton
                        transparent
                        size="sm"
                        Icon={AddTwoTone}
                        title="Add adjustment"
                        onClick={setAddingAdjustment.bind(undefined, true)}
                        style={{ width: '1.2rem', height: '1.2rem' }}
                        iconStyles={{ fontSize: '1rem' }}
                    />
                )}
            </div>
            <BootstrapTable headerNames={headerNames} className={styles.leaseTable + ' mb-0'}>
                {adjustments === undefined ? (
                    <TableBodySkeleton cellCount={headerNames.length} />
                ) : adjustments.length === 0 && !addingAdjustment ? (
                    <tr>
                        <td
                            colSpan={headerNames.length}
                            className="text-muted text-center fw-semibold small"
                        >
                            No Adjustments
                        </td>
                    </tr>
                ) : (
                    adjustments?.map((adj) => (
                        <LeaseAdjustmentItem
                            key={adj.ladj_id}
                            adjustment={adj}
                            lease={lease}
                            asset={asset}
                        />
                    ))
                )}
                {addingAdjustment && (
                    <LeaseAdjustmentItem
                        lease={lease}
                        onAddDone={() => setAddingAdjustment(false)}
                        asset={asset}
                    />
                )}
            </BootstrapTable>
        </>
    );
}

interface LeaseAdjustmentItemProps {
    adjustment?: Butlerr.Asset.LeaseAdjustment;
    lease: Butlerr.Asset.Lease;
    asset: Butlerr.Asset;
    onAddDone?: () => void;
}
function LeaseAdjustmentItem({ adjustment, asset, lease, onAddDone }: LeaseAdjustmentItemProps) {
    const leaseId = lease.leas_id;
    const assetId = asset.asst_id;

    const { mutate: createLeaseAdjustment } = useLeaseAdjustmentMutations('CREATE');
    const { mutate: editLeaseAdjustment, isLoading: isEditLoading } =
        useLeaseAdjustmentMutations('EDIT');
    const { mutateAsync: deleteLeaseAdjustment, isLoading: isDeleting } =
        useLeaseAdjustmentMutations('DELETE');

    const [isEditing, setIsEditing] = useState(adjustment === undefined);

    const schema = yup.object().shape({
        ladj_from: yup
            .date()
            .min(
                new Date(new Date(lease.leas_start).setHours(0, 0, 0, 0)),
                'Must be within the lease period'
            )
            .max(
                !lease.leas_end
                    ? Infinity
                    : new Date(new Date(lease.leas_end).setHours(0, 0, 0, 0)),
                'Must be within the lease period'
            )
            .required('Required'),
        ladj_to: yup
            .date()
            .min(yup.ref('ladj_from'), 'Must be after the start date')
            .max(
                !lease.leas_end
                    ? Infinity
                    : new Date(new Date(lease.leas_end).setHours(0, 0, 0, 0)),
                'Must be within the lease period'
            )
            .required('Required'),
        ladj_amt: yup.number().required('Required').min(1, 'Must be positive'),
    });

    type AdjForm = {
        ladj_from: string;
        ladj_to: string;
        ladj_amt: number;
    };

    const handleSubmit = async (values: AdjForm) => {
        //if nothing changed, return
        if (
            adjustment !== undefined &&
            adjustment.ladj_amt === values.ladj_amt &&
            convertDateToYYYYMMDD(adjustment.ladj_from) === values.ladj_from &&
            convertDateToYYYYMMDD(adjustment.ladj_to) === values.ladj_to &&
            adjustment.ladj_doclink === (selectedDocument?.doc_id ?? null)
        ) {
            setIsEditing(false);
            return;
        }

        const form = {
            assetId,
            leaseId,
            adjustmentId: adjustment?.ladj_id,
            ladj_doclink: selectedDocument?.doc_id,
            ...values,
        };

        //if no initial value, add new adjustment, else edit
        if (adjustment === undefined) {
            createLeaseAdjustment(form, {
                onSuccess: () => {
                    setIsEditing(false);
                    onAddDone?.();
                },
            });
        } else {
            //assert since `adjustmentId` won't be undefined in this block
            const _form = form as typeof form & { adjustmentId: number };
            editLeaseAdjustment(_form, {
                onSuccess: () => {
                    setIsEditing(false);
                },
            });
        }
    };

    const onDelete = async () => {
        if (adjustment !== undefined) {
            try {
                await deleteLeaseAdjustment({ assetId, leaseId, adjustmentId: adjustment.ladj_id });
            } catch (err) {
                console.error(err);
            }
        } else {
            onAddDone?.();
        }
    };

    //document states
    const [selectedDocument, setSelectedDocument] = useState<Butlerr.Document.File>();

    useEffect(() => {
        if (adjustment?.ladj_doclink) {
            const { doc_name, doc_created, doc_desc, doc_format, doc_id, doct_parentid } =
                adjustment;
            setSelectedDocument({
                doc_name,
                doc_created,
                doc_desc,
                doc_format,
                doc_id,
                doct_parentid,
            } as Butlerr.Document.File);
        }
    }, [adjustment]);

    const [picker, openPicker] = useModal(DocumentSelectModal, {
        asset: asset,
        onFileSelect: setSelectedDocument,
        className: 'modal-layer-1',
        backdropClassName: 'modal-layer-1',
        initialFolder: asset.doct_leas_id,
    });

    const download = useDownloadWithPostToken();

    return (
        <Formik
            enableReinitialize
            initialValues={{
                ladj_from: convertDateToYYYYMMDD(adjustment?.ladj_from) ?? '',
                ladj_to: convertDateToYYYYMMDD(adjustment?.ladj_to) ?? '',
                ladj_amt: adjustment?.ladj_amt ?? 0,
            }}
            validationSchema={schema}
            onSubmit={handleSubmit}
        >
            {({ values, handleSubmit }) => (
                <tr className="align-top">
                    <td style={{ maxWidth: '213px' }}>
                        {isEditing ? (
                            <BootstrapInput
                                id="ladj_from"
                                type="date"
                                className={styles.leaseAdjustmentForm}
                            />
                        ) : (
                            <div>{formatDate(values.ladj_from) ?? '-'}</div>
                        )}
                    </td>
                    <td style={{ maxWidth: '213px' }}>
                        {isEditing ? (
                            <BootstrapInput
                                id="ladj_to"
                                type="date"
                                className={styles.leaseAdjustmentForm}
                            />
                        ) : (
                            <div>{formatDate(values.ladj_to) ?? '-'}</div>
                        )}
                    </td>
                    <td style={{ minWidth: '110px' }}>
                        {isEditing ? (
                            <BootstrapInput
                                id="ladj_amt"
                                type="number"
                                className={styles.leaseAdjustmentForm}
                            />
                        ) : (
                            <span>{'$' + adjustment?.ladj_amt}</span>
                        )}
                    </td>
                    <td style={{ minWidth: '137px', maxWidth: '137px' }}>
                        <Row className="d-flex align-items-center">
                            <Col>
                                {(() => {
                                    const fileName = !selectedDocument
                                        ? isEditing
                                            ? 'Select Document'
                                            : '-'
                                        : `${selectedDocument.doc_name}${
                                              !selectedDocument.doc_format
                                                  ? ''
                                                  : '.' + selectedDocument.doc_format
                                          }`;

                                    return (
                                        <LineClampText
                                            maxLines={isEditing ? 2 : 1}
                                            role={isEditing ? 'button' : undefined}
                                            onClick={() => {
                                                if (isEditing) {
                                                    return openPicker();
                                                }
                                                if (selectedDocument) {
                                                    download('DOCUMENTS', selectedDocument.doc_id);
                                                }
                                            }}
                                            className={classNames({
                                                'mb-0 small': true,
                                                'link-primary cursor-pointer': !isEditLoading,
                                                'link-secondary pe-none': isEditLoading,
                                            })}
                                            title={
                                                isEditing
                                                    ? fileName
                                                    : selectedDocument
                                                    ? 'Download'
                                                    : undefined
                                            }
                                        >
                                            {fileName}
                                        </LineClampText>
                                    );
                                })()}
                            </Col>
                            {isEditing && (
                                <Col>
                                    {selectedDocument !== undefined && (
                                        <span title="Unlink document">
                                            <DeleteOutline
                                                className={classNames({
                                                    'link-primary cursor-pointer': !isEditLoading,
                                                    'link-secondary pe-none': isEditLoading,
                                                })}
                                                fontSize="small"
                                                onClick={() => setSelectedDocument(undefined)}
                                            />
                                        </span>
                                    )}
                                </Col>
                            )}
                        </Row>
                    </td>

                    <td className="py-0 align-middle tcol-actions">
                        {asset.status !== 'AR' && (
                            <div className="d-flex justify-content-center">
                                <IconButton
                                    transparent
                                    title="Edit"
                                    className="me-2"
                                    disabled={isEditLoading}
                                    Icon={
                                        isEditing || adjustment === undefined ? Save : EditOutlined
                                    }
                                    onClick={
                                        isEditing
                                            ? handleSubmit.bind(null, undefined)
                                            : () => setIsEditing((prev) => !prev)
                                    }
                                />
                                <IconButton
                                    transparent
                                    title="Delete"
                                    disabled={isDeleting}
                                    Icon={DeleteOutline}
                                    onClick={onDelete}
                                />
                            </div>
                        )}
                    </td>

                    {picker}
                </tr>
            )}
        </Formik>
    );
}

export default AssetLeasesModal;
