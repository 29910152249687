import React, { useMemo, useState, forwardRef, ForwardedRef } from 'react';
import { Form, Dropdown, DropdownProps } from 'react-bootstrap';
import { Butlerr } from "../../types/butlerr";
import { DropdownToggleProps } from 'react-bootstrap/esm/DropdownToggle';
import { useDebouncedCallback } from "use-debounce";
import { SearchUserOptions, useUserSearch } from "../../services/user.service";
import styles from './utils.module.css';
import useModal from "../../hooks/useModal";
import InviteUsersModal from "./InviteUsersModal";
import UserAvatar from '../social/UserAvatar';
import InfiniteScroller from "../ui/InfiniteScroller"

type TUser = Pick<Butlerr.User, 'user_id' | 'user_socialhandle'> & Partial<Pick<Butlerr.User, 'user_profile'>>;

interface BaseDropdownProps<T> extends DropdownToggleProps {
    /**
     * `onSelect` will be called whenever the list of selectedUsers has been changed
     */
    selectedUsers: T[];
    setSelectedUsers: (newState: T[]) => void;

    limit?: number;
    limitBehaviour?: 'disable' | 'replace';

    dropdownProps?: Omit<DropdownProps, 'children'>;

    inviteOption?: boolean;

    User?: (user: T) => JSX.Element;
    searchPlaceholderText?: string;
}

interface SearchDropdownProps<T> extends BaseDropdownProps<T> {
    users?: never;
    searchOptions?: Omit<SearchUserOptions, 'query'>;
}
interface FilterDropdownProps<T> extends BaseDropdownProps<T> {
    /**
     * If users is passed, the search will just filter the users array locally.
     */
    users: T[];
    searchOptions?: never;
}

type UserDropdownButtonProps<T> =
|   FilterDropdownProps<T>
|   SearchDropdownProps<T>

const UserDropdownButton = forwardRef(<T extends TUser = TUser>({
    selectedUsers,
    setSelectedUsers,
    inviteOption = false,
    limit,
    limitBehaviour = 'replace',
    users,
    dropdownProps,
    User,
    searchPlaceholderText = "Search...",
    searchOptions,
    ...props
} : UserDropdownButtonProps<T>, ref : ForwardedRef<HTMLButtonElement>) => {

    //user selection handler
    const handleUserSelection = (user: T) => {
        const newState = (() => {
            const isSelected = selectedUsers.findIndex(u => u.user_id === user.user_id) !== -1;
            if (isSelected) {
                return selectedUsers.filter(u => u.user_id !== user.user_id);
            }

            const isLimitReached = limit !== undefined && selectedUsers.length >= limit;
            // check if there is limit
            if (isLimitReached) {
                if (limitBehaviour === 'disable') return undefined; // not needed, but just in case
                // remove last user from the array and add selected user
                return [
                    ...selectedUsers.slice(0, selectedUsers.length - 1), user
                ];
            }
            return [
                ...selectedUsers, user
            ];
        })();
        
        if (newState) setSelectedUsers(newState);
    }

    //input handler
    const [ searchValue, setSearchValue ] = useState('');

    const handleSearchValueChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const val = ev.currentTarget.value;
        //update input state
        setSearchValue(val)
        //remove url & query key to disable the query
        setQueryEnabled(false);

        //if network search, handle refetching
        if (users === undefined) {
            //cancel previous refetch cb
            handleRefetch.cancel();

            if (val) {
                //call debounced refetch
                handleRefetch();
            }
        }
    }

    //disable the query to fetch with delay
    const [ queryEnabled, setQueryEnabled ] = useState(true);
    //query
    const { isLoading, data: _searchedUsers, hasNextPage, fetchNextPage } = useUserSearch(
        {
            ...searchOptions,
            query: searchValue
        },
        (queryEnabled && !!searchValue && users === undefined)
    )
    const searchedUsers = useMemo(() => _searchedUsers?.pages.flatMap(p => p.result) ?? [], [_searchedUsers]);

    //debounced cb for refetching
    const handleRefetch = useDebouncedCallback(() => {
        setQueryEnabled(true);
    }, 500);

    //filtered users
    const usersToDisplay = useMemo(() => {
        return (
            //if list of users is passed
            users !== undefined ? [
                ...users.filter(u =>
                    //Either no search value OR string includes seach value
                    !searchValue || u.user_socialhandle.toLowerCase().includes(searchValue.toLowerCase())
                )
            ] : (
                [
                    //show selected users (minus ones already in search results)
                    ...selectedUsers.filter(u => searchedUsers.findIndex(u2 => u2.user_id === u.user_id) === -1),
                    ...searchedUsers
                ]
            )
        )
    }, [users, selectedUsers, searchedUsers, searchValue])

    const UserDropdownMap = (users: T[]) => {
        return users.map((u) => {
            const isUserSelected = selectedUsers.findIndex(u2 => u2.user_id === u.user_id) !== -1;

            return (
                <Dropdown.Item
                    key={u.user_id}
                    className="p-2 d-flex align-items-center"
                    onClick={() => handleUserSelection(u)}
                    disabled={!isUserSelected && limit !== undefined && limitBehaviour === 'disable' && limit <= selectedUsers.length}
                >
                    <Form.Check type="checkbox" className="d-flex align-items-center">
                        <Form.Check.Input
                            type="checkbox"
                            name="msge_cc"
                            checked={isUserSelected}
                            className="opacity-100 pe-none"
                        />
                        <Form.Check.Label className="opacity-100 cursor-pointer user-select-none">
                            {
                                User !== undefined ? User(u) : (
                                    <>
                                        <UserAvatar
                                            user={{
                                                ...u,
                                                user_profile: u.user_profile ?? null
                                            }}
                                            width={30}
                                            height={30}
                                            style={{ boxShadow: '0 0 2px black' }}
                                            className="ms-2"
                                            roundedCircle
                                        />
                                        <span className="ms-2">{u.user_socialhandle}</span>
                                    </>
                                )
                            }
                        </Form.Check.Label>
                    </Form.Check>
                </Dropdown.Item>
            )
        })
    }

    const [ modal, invite ] = useModal(InviteUsersModal, {
        initialEmail: searchValue
    })

    //Invite dropdown item
    const inviteItem = inviteOption && (
        <Dropdown.Item onClick={invite}>
            Invite new user
        </Dropdown.Item>
    )

    return (
        <Dropdown autoClose="outside" align="end" {...dropdownProps}>
            <Dropdown.Toggle {...props} ref={ref} />

            <Dropdown.Menu className={styles.userDropdownMenu}>
                <Form.Control
                    autoFocus
                    className="mx-3 my-2"
                    style={{ width: "calc(100% - 2rem)" }}
                    placeholder={searchPlaceholderText}
                    onChange={handleSearchValueChange}
                    value={searchValue}
                />

                {
                    (isLoading || handleRefetch.isPending()) ? (
                        <>
                            { UserDropdownMap(selectedUsers) }
                            <UserSkeleton />
                            <UserSkeleton />
                            <UserSkeleton />
                        </>
                    ) :
                    usersToDisplay.length === 0 ? (
                        <>
                            <Dropdown.Item className="py-2" disabled>
                                {
                                    //if searching online & nothing types, different message
                                    (users === undefined && !searchValue) ? (
                                        "Enter something to search"
                                    ) : "No users available"
                                }
                            </Dropdown.Item>

                            { inviteItem }
                        </>
                    ) : (
                        <InfiniteScroller
                            hasNextPage={hasNextPage}
                            fetchNextPage={fetchNextPage}
                            isLoading={isLoading || handleRefetch.isPending()}
                            loadingEle={(
                                <>
                                    <UserSkeleton />
                                    <UserSkeleton />
                                    <UserSkeleton />
                                </>
                            )}
                        >
                            { UserDropdownMap(usersToDisplay as T[]) }
                            { inviteItem }
                        </InfiniteScroller>
                    )
                }
                { modal }
            </Dropdown.Menu>
        </Dropdown>
    )
}) as <T extends TUser = TUser>(
    props: UserDropdownButtonProps<T> & { ref?: ForwardedRef<HTMLButtonElement> }
) => JSX.Element;

export default UserDropdownButton;

function UserSkeleton() {
    return (
        <div className="p-2 d-flex align-items-center">
            <div
                className="skeleton-box rounded-circle flex-shrink-0 me-2"
                style={{ width: 30, height: 30 }}
            >
            </div>
            <div className="flex-grow-1 align-self-stretch d-flex flex-column justify-content-around">
                <div
                    className="skeleton-box w-100"
                    style={{ height: '10px' }}
                ></div>
                <div
                    className="skeleton-box"
                    style={{ width: '60%', height: '9px' }}
                ></div>
            </div>
        </div>
    )
}