import { useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { Row, Col, Form, InputGroup } from 'react-bootstrap';
import * as yup from 'yup';
import { Apartment, LocalOffer, LocationOn } from '@mui/icons-material';
import classNames from 'classnames';

import styles from '../asset.module.css';
import { BootstrapInput } from '../../utils/FormikBootstrapInputs';
import { Butlerr } from '../../../types/butlerr';
import {
    formatGoogleAddress,
    initialiseAssetObject,
    useAssetMutations,
} from '../../../services/asset.service';
import { AssetInfoEditButton, AssetInfoProps } from './AssetInfo';
import LineClampText from '../../utils/LineClampText';
import RouterPrompt from '../../utils/RouterPrompt';

interface FormValues {
    asst_name?: string;
    asst_code?: string | null;
    asst_estatename?: string | null;
    asst_desc?: string | null;
}

const AssetInfoHeader = ({ asset, isPartner = false }: AssetInfoProps) => {
    const { mutate: editAsset } = useAssetMutations('EDIT');

    // form state
    const [editing, setEditing] = useState(false);

    // Formik props
    const initialiseInitialValues = (asset: Butlerr.Asset) => {
        return {
            asst_name: asset.asst_name,
            asst_code: asset.asst_code ?? '',
            asst_estatename: asset.asst_estatename ?? '',
            asst_desc: asset.asst_desc ?? '',
        };
    };

    let initialValues: FormValues = initialiseInitialValues(asset);

    const validationSchema = yup.object({
        asst_name: yup.string().max(80, 'Maximum 80 characters').nullable(),
        asst_code: yup.string().max(5, 'Maximum 5 characters').nullable(),
        asst_estatename: yup.string().max(30, 'Maximum 30 characters').nullable(),
        asst_desc: yup.string().max(200, 'Maximum 200 characters').nullable(),
    });

    const closeForm = (actions: FormikHelpers<FormValues>) => {
        setEditing(false);
        actions.resetForm({ values: initialValues });
    };

    const onSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) => {
        // check if any field updated
        let fieldUpdated: boolean = false;
        Object.keys(initialValues).forEach((key) => {
            let value = values[key as keyof typeof values];
            if (initialValues[key as keyof typeof initialValues] !== value) {
                fieldUpdated = true;
            }
        });

        if (fieldUpdated) {
            editAsset(
                {
                    ...initialiseAssetObject(asset),
                    ...values,
                },
                {
                    onSuccess: (_, updatedAsset) => {
                        initialValues = initialiseInitialValues(updatedAsset);
                        closeForm(actions);
                    },
                    onError: (error) => {
                        closeForm(actions);
                        alert(error.message);
                    },
                }
            );
        } else {
            closeForm(actions);
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({ handleSubmit, isSubmitting, resetForm, dirty }) => {
                return (
                    <Form noValidate onSubmit={handleSubmit}>
                        <RouterPrompt isBlocking={dirty} />

                        <div className="pb-4">
                            <Row className={classNames({ 'mb-2': editing })}>
                                <Col>
                                    <Row
                                        className={classNames(
                                            'd-flex justify-content-start align-items-start',
                                            !editing ? 'mb-1' : ''
                                        )}
                                    >
                                        {/* asset name */}
                                        <Col
                                            title="Asset Name"
                                            className="pe-lg-0"
                                            xs={editing ? undefined : 'auto'}
                                        >
                                            {editing ? (
                                                <BootstrapInput
                                                    id="asst_name"
                                                    placeholder="Asset Name"
                                                    aria-label="Asset Name"
                                                    aria-describedby="assetName"
                                                    disabled={isSubmitting}
                                                />
                                            ) : (
                                                <LineClampText
                                                    maxLines={1}
                                                    className="h4 mb-0"
                                                    style={{ maxWidth: '809px' }}
                                                >
                                                    {asset.asst_name}
                                                </LineClampText>
                                            )}
                                        </Col>

                                        {/* asset status */}
                                        {/* <Col className="p-lg-0 mt-2 mt-lg-0" xs="12" md="auto">
                                            <Badge
                                                pill
                                                bg={AssetStatusMap[asset.asst_status ?? 'A']}
                                                onClick={handleStatusClick}
                                                role="button"
                                                aria-disabled={isStatusUpdating}
                                                className="fw-normal"
                                                title="Change status"
                                            >
                                                {asset.asst_status
                                                    ? ButlerrAssetStatus[asset.asst_status]
                                                    : '-'}
                                            </Badge>
                                        </Col> */}
                                    </Row>
                                </Col>
                                {isPartner === false && asset.status !== 'AR' && (
                                    <Col
                                        xs={{
                                            span: editing ? '12' : '1',
                                            order: editing ? 'first' : undefined,
                                        }}
                                        lg={{ span: 'auto', order: editing ? 'last' : undefined }}
                                    >
                                        <AssetInfoEditButton
                                            editing={editing}
                                            isSubmitting={isSubmitting}
                                            setEditing={setEditing}
                                            resetForm={resetForm}
                                        />
                                    </Col>
                                )}
                            </Row>

                            <Row
                                className={classNames(
                                    'd-flex align-items-center',
                                    editing ? 'mb-2' : 'mb-3'
                                )}
                            >
                                {/* address */}
                                <Col
                                    title="Address"
                                    className="d-flex align-items-start pe-0"
                                    xs="12"
                                    md="auto"
                                >
                                    <span
                                        className={styles.secondaryTitle}
                                        style={{ marginTop: '0.19rem' }}
                                    >
                                        <LocationOn fontSize="inherit" />
                                    </span>
                                    <span className={styles.secondaryTitle}>
                                        {formatGoogleAddress(asset).full}
                                    </span>
                                </Col>

                                {/* estate name */}
                                <Col
                                    title="Project Name"
                                    className="d-flex align-items-center pe-0"
                                    xs="12"
                                    lg="auto"
                                >
                                    {editing ? (
                                        <BootstrapInput
                                            id="asst_estatename"
                                            placeholder="Project Name"
                                            aria-label="Project Name"
                                            disabled={isSubmitting}
                                        />
                                    ) : (
                                        <>
                                            <span className={styles.secondaryTitle}>
                                                <Apartment fontSize="inherit" />
                                            </span>
                                            <span className={styles.secondaryTitle}>
                                                {asset.asst_estatename ?? '-'}
                                            </span>
                                        </>
                                    )}
                                </Col>

                                {/* asset code */}
                                <Col
                                    title="Asset Tag"
                                    className="d-flex align-items-center"
                                    xs="12"
                                    lg="auto"
                                >
                                    {editing ? (
                                        <InputGroup>
                                            <InputGroup.Text id="assetCode">#</InputGroup.Text>
                                            <BootstrapInput
                                                id="asst_code"
                                                placeholder="Tag"
                                                aria-label="Tag"
                                                aria-describedby="assetCode"
                                                disabled={isSubmitting}
                                            />
                                        </InputGroup>
                                    ) : (
                                        <>
                                            <span className={styles.secondaryTitle}>
                                                <LocalOffer fontSize="inherit" />
                                            </span>
                                            <span className={styles.secondaryTitle}>
                                                {asset.asst_code ?? '-'}
                                            </span>
                                        </>
                                    )}
                                </Col>
                            </Row>

                            {/* main_description */}
                            <div title="Description">
                                {editing ? (
                                    <Form.Group>
                                        <BootstrapInput
                                            as="textarea"
                                            id="asst_desc"
                                            placeholder="Description"
                                            aria-label="Description"
                                            disabled={isSubmitting}
                                            style={{ height: '5.7rem' }}
                                        />
                                    </Form.Group>
                                ) : asset.asst_desc ? (
                                    asset.asst_desc
                                ) : (
                                    'No description available.'
                                )}
                            </div>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default AssetInfoHeader;
