import { useCallback, useMemo } from 'react';
import { Column } from 'react-table';
import { Butlerr } from '../../../types/butlerr';
import { formatCurrency, formatDate } from '../../utils/HelperFunctions';
import LineClampText from '../../utils/LineClampText';
import ReactTable from '../../utils/ReactTable';

interface AssetLoansTableInterface {
    assetId: number;
    loans: Butlerr.Asset.Loan[];
    isLoading: boolean;
    onModalOpen: (loan?: Butlerr.Asset.Loan | undefined) => void;
}

const AssetLoansTable = ({ assetId, loans, isLoading, onModalOpen }: AssetLoansTableInterface) => {
    // edit modal
    const onEdit = useCallback(
        (loan: Butlerr.Asset.Loan) => {
            onModalOpen(loan);
        },
        [onModalOpen]
    );

    // columns
    const columns = useMemo<Column<Butlerr.Asset.Loan>[]>(
        () => [
            {
                Header: 'Lender',
                accessor: 'loan_lender',
                minWidth: 110,
                Cell: ({ value }) => {
                    return <>{value ?? '-'}</>;
                },
            },
            {
                Header: 'description',
                accessor: 'loan_desc',
                minWidth: 157,
                Cell: ({ value }) => {
                    return (
                        <LineClampText className="mb-0" title={value ?? undefined} maxLines={1}>
                            {value ?? '-'}
                        </LineClampText>
                    );
                },
            },
            {
                Header: 'start date',
                accessor: 'loan_start',
                minWidth: 147,
                Cell: ({ value }) => {
                    return <>{formatDate(value?.toString())}</>;
                },
            },
            {
                Header: 'tenure',
                accessor: 'loan_tenure',
                minWidth: 128,
                Cell: ({ value }) => {
                    return (
                        <>
                            {value ?? '-'} {value ? (value > 1 ? ' months' : ' month') : ''}
                        </>
                    );
                },
            },
            {
                Header: 'principal',
                accessor: 'loan_principal',
                minWidth: 153,
                Cell: ({ row }) => {
                    const loan = row.original;
                    return (
                        <>
                            {formatCurrency(loan.loan_principal, {
                                currency: loan.loan_currency,
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                            })}
                        </>
                    );
                },
            },
            {
                Header: 'status',
                accessor: 'loan_termdate',
                minWidth: 113,
                Cell: ({ row }) => {
                    const loan = row.original;
                    const terminated = loan.loan_termdate;
                    return (
                        <span className={terminated ? 'text-danger' : 'text-success'}>
                            {terminated ? 'Terminated' : 'Active'}
                        </span>
                    );
                },
            },
        ],
        []
    );

    return (
        <>
            <ReactTable
                data={loans}
                columns={columns}
                isLoading={isLoading}
                Row={(props, data) => (
                    <tr
                        {...props}
                        className="cursor-pointer"
                        onClick={onEdit.bind(undefined, data)}
                    />
                )}
            />
        </>
    );
};

export default AssetLoansTable;
