import { CloseRounded } from "@mui/icons-material";
import { Modal } from "react-bootstrap";
import NewPostContainer from "../../social/NewPostContainer";
import IconButton from "../../utils/IconButton";

interface ShareArticleModalProps {
    show: boolean;
    onClose: () => void;
    content: string;
    link: string;
}

export function ShareArticleModal({ show, onClose, content, link } : ShareArticleModalProps) {

    link = new URL(link, window.location.origin).toString();

    return (
        <Modal show={show} centered onHide={onClose} size="lg">
            <Modal.Header>
                <Modal.Title>Share the article via posts</Modal.Title>
                <IconButton
                    Icon={CloseRounded}
                    title="Close"
                    className="ms-auto"
                    onClick={onClose}
                />
            </Modal.Header>
            <Modal.Body className="p-0">
                <NewPostContainer
                    onSuccess={onClose}
                    initialPostMessage={content}
                    initialPostUrl={link}
                    initialHashtags={['article']}
                />
            </Modal.Body>
        </Modal>
    )
}