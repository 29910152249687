import { AddRounded, MoreVert, PersonOffOutlined, PersonOutlineRounded } from '@mui/icons-material';
import IconButton from '../../utils/IconButton';
import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import { Dropdown, ListGroup, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Butlerr } from '../../../types/butlerr';
import { createSocialRoute, SocialRoutes } from '../../social/SocialHome';
import UserAvatar from '../../social/UserAvatar';
import LineClampText from '../../utils/LineClampText';
import styles from '../../assets/asset.module.css';
import SocialChatsModal from './ChatsModal';
import { useButlerrUser } from '../../../services/user.service';
import DestructiveModal from '../../utils/DestructiveModal';
import {
    SocialChatQueries,
    useSocialChatMutations,
} from '../../../services/chat.service';
import { useQueryClient } from 'react-query';
import useQuery from '../../../hooks/useQuery';
import GroupLogo from '../../../assets/icons/GroupLogo.png'

interface SocialChatsSidebarProps {
    chats: Butlerr.Social.Chats[];
    selectedChatId: number | undefined;
    setSelectedChatId: React.Dispatch<
        React.SetStateAction<number | undefined>
    >;
}

const SocialChatsSidebar = ({
    chats,
    selectedChatId,
    setSelectedChatId,
}: SocialChatsSidebarProps) => {

    const { data: dbUser } = useButlerrUser();

    const [selectedChatIdOptions, setSelectedChatIdOptions] = useState<number>(-1);

    const queryClient = useQueryClient();
    const onSelect = (chat: Butlerr.Social.Chats | undefined) => {
        setSelectedChatId(chat?.ascv_id ?? undefined);

        // update chats to retrieve last updated
        queryClient.invalidateQueries(SocialChatQueries.CHATS());
    };

    const search = useQuery();

    const params = useMemo(() => {
        const params: { query?: string } = {}
        const query = decodeURIComponent(search.get("q") || "");
        if (query) params.query = query;
        return params
    }, [search])

    const filteredChats = useMemo(
        () =>
            chats.filter((chat) => {
                let value = ''
                if (params.query !== undefined) {
                    value = params.query
                }
                return (
                    chat.ascv_subject.toLowerCase().includes(value.toLowerCase()) ||
                    chat.members.some((user) =>
                        user.user_socialhandle.toLowerCase().includes(value.toLowerCase())
                    )
                );
            }),
        [chats, params]
    );

    //chat modal
    const [showModal, setShowModal] = useState(false);
    const [selectedModalChat, setSelectedModalChat] =
        useState<Butlerr.Social.Chats>();

    // destructive modal
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [leaveChats, setLeaveChats] = useState<boolean | null>(null); //true = Leave, false = Delete

    const { mutateAsync: leaveChat } = useSocialChatMutations('LEAVE');
    const { mutateAsync: deleteChat } = useSocialChatMutations('DELETE');

    const handleLeave = (convoId: number) => {
        setShowDeleteModal(true);
        setLeaveChats(true);
        setSelectedChatIdOptions(convoId)
    };

    const handleDelete = (convoId: number) => {
        setShowDeleteModal(true);
        setLeaveChats(false);
        setSelectedChatIdOptions(convoId)
    };

    const onDeleteClose = () => {
        setShowDeleteModal(false);
    };

    // add modal states
    const [showAddModal, setShowAddModal] = useState(false);

    const onAddModalOpen = () => {
        setShowAddModal(true);
    };

    const onAddModalClose = () => {
        setShowAddModal(false);
    };

    const onDeleteConfirm = async () => {
        if (leaveChat === null) return;

        if (leaveChats === true) {
            await leaveChat({ chatId: selectedChatIdOptions });
        } else if (leaveChats === false) {
            await deleteChat(
                { chatId: selectedChatIdOptions },
                {
                    onSuccess: () => {
                        onSelect(undefined);
                    },
                }
            );
        }
    };

    return (
        <>
            <ListGroup variant="flush">
                <ListGroup.Item
                    as="div"
                    action
                    // onClick={() => {
                    //     createChat()
                    // }}
                    className={classNames({
                        'cursor-pointer': true,
                        [styles.conversationsSidebarItem]: true
                    })}
                    onClick={() => onAddModalOpen()}
                >
                    <div className="d-flex align-items-center p-1">
                        <AddRounded
                            className='mx-auto'
                            style={{ width: 35, height: 35, alignSelf: 'center', maxHeight: '2rem' }}
                        />
                    </div>
                </ListGroup.Item>
                {filteredChats.map((chat) => (
                    <ChatSidebarItem
                        key={chat.ascv_id}
                        chat={chat}
                        selectedChatId={selectedChatId!}
                        onSelect={onSelect}
                        dbUser={dbUser}
                        handleEdit={() => {
                            setShowModal(true);
                            setSelectedModalChat(chat);
                        }}
                        handleDelete={() => handleDelete(chat.ascv_id)}
                        handleLeave={() => handleLeave(chat.ascv_id)}
                    />
                ))}
            </ListGroup>

            <SocialChatsModal
                show={showModal}
                onClose={() => setShowModal(false)}
                initialValue={selectedModalChat}
            />
            <DestructiveModal
                show={showDeleteModal}
                onClose={onDeleteClose}
                onConfirm={onDeleteConfirm}
                title={leaveChats ? `Leave the chat?` : `Delete the chat?`}
                description={
                    leaveChats
                        ? `You will lose all access and records related to this chat. Are you sure you want to leave?`
                        : `You will lose all access and records related to this chat. Are you sure you want to delete?`
                }
                confirmBtnText={`Confirm`}
            />
            <SocialChatsModal
                show={showAddModal}
                onClose={onAddModalClose}
            />
        </>
    );
};

interface ChatSidebarItemProps {
    chat: Butlerr.Social.Chats;
    selectedChatId: number;
    onSelect: (chat: Butlerr.Social.Chats | undefined) => void;
    dbUser: Butlerr.User | undefined;
    handleEdit: () => void;
    handleDelete: () => void;
    handleLeave: () => void;
}

const ChatSidebarItem = ({
    chat,
    selectedChatId,
    onSelect,
    dbUser,
    handleEdit,
    handleDelete,
    handleLeave,
}: ChatSidebarItemProps) => {
    // sort the members array so that the chat creator is the first item in the array
    chat.members.sort((a) => (a.user_id === chat.user_id ? -1 : 0));

    const [canBold, setCanBold] = useState(true);

    const ChatStarter = useMemo(
        () =>
            dbUser?.user_id === chat.user_id,
        [chat.user_id, dbUser?.user_id]
    );

    return (
        <>
            <ListGroup.Item
                as="div"
                key={chat.ascv_id}
                action
                onClick={() => {
                    onSelect(chat);
                    setCanBold(false);
                }}
                className={classNames({
                    'cursor-pointer': true,
                    [styles.conversationsSidebarItem]: true
                })}
                style={{
                    backgroundColor:
                        chat.ascv_id === selectedChatId
                            ? 'var(--bs-gray-200)'
                            : undefined,
                }}
            >
                <div className="d-flex align-items-start p-1">
                    <div style={{ zIndex: 0 }}>
                        {
                            chat.acmb_leave === null ? (
                                chat.members.length === 1 ? (
                                    <div title='There are no one else in the chat'>
                                        <PersonOutlineRounded
                                            style={{ width: 35, height: 35, objectFit: 'contain' }}
                                            className={
                                                styles.conversationMemberIcon
                                            }
                                        />
                                    </div>
                                ) : (
                                    chat.members.length > 2 ? (
                                        <>
                                            <Image
                                                src={GroupLogo}
                                                style={{ width: 35, height: 35, objectFit: 'contain' }}
                                                className={
                                                    styles.conversationMemberIcon
                                                }
                                                title='Group chat'
                                            />
                                        </>
                                    ) : (
                                        chat.members.map((member) => (
                                            member.user_id !== dbUser?.user_id ? (
                                                <Link
                                                    key={member.user_id}
                                                    to={createSocialRoute(SocialRoutes.USER_PROFILE, {
                                                        id: member.user_id,
                                                    })}
                                                    className={styles.conversationMemberIconContainer}
                                                    onClick={(ev) => ev.stopPropagation()}
                                                >
                                                    <UserAvatar
                                                        roundedCircle
                                                        width={35}
                                                        height={35}
                                                        className={
                                                            'shadow-sm border-secondary ' +
                                                            styles.conversationMemberIcon
                                                        }
                                                        style={{ objectFit: 'cover' }}
                                                        user={member}
                                                        title={member.user_socialhandle}
                                                    />
                                                </Link>
                                            ) : (
                                                null
                                            )
                                        ))
                                    )
                                )
                            ) : (
                                <div title='You have left the chat' >
                                    <PersonOffOutlined
                                        style={{ width: 35, height: 35, objectFit: 'contain' }}
                                        className={
                                            styles.conversationMemberIcon
                                        }
                                    />
                                </div>
                            )
                        }
                    </div>

                    <LineClampText
                        maxLines={2}
                        title={chat.ascv_subject}
                        className={
                            'mb-0 small ms-2 ' +
                            ((chat.latestMessage.acms_datetime >
                                chat.acla_lastAccess ||
                                chat.acla_lastAccess === null) &&
                                canBold === true
                                ? 'fw-bold'
                                : '')
                        }
                    >
                        {chat.ascv_subject}
                    </LineClampText>

                    {!chat.acmb_leave && (
                        <Dropdown
                            className={styles.dropdown}
                            onToggle={(_, meta) => {
                                meta.originalEvent?.stopPropagation();
                                meta.originalEvent?.preventDefault();
                            }}
                            style={{ zIndex: 10, position: 'absolute', right: 10, top: 10 }}
                        >
                            <Dropdown.Toggle as="span" bsPrefix=" ">
                                <IconButton
                                    transparent
                                    border={false}
                                    title="More"
                                    Icon={MoreVert}
                                    iconHtmlColor="var(--bs-gray)"
                                />
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="p-0">
                                {ChatStarter ? (
                                    <>
                                        <Dropdown.Item onClick={() => handleEdit()}>
                                            Edit
                                        </Dropdown.Item>
                                        {/* <Dropdown.Item onClick={handleDelete}>Delete</Dropdown.Item> */}
                                    </>
                                ) : (
                                    <Dropdown.Item onClick={handleLeave}>Leave</Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                    )}
                </div>
                {/* TODO: put unread count */}
                {/* <small>
                        <Badge bg="secondary" pill>
                            3
                        </Badge>
                        <span className="visually-hidden">unread messages</span>
                    </small> */}
            </ListGroup.Item>
        </>
    );
};

export default SocialChatsSidebar;
