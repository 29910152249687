import React, { useRef, useState, useEffect, useMemo } from "react";
import DestructiveModal from "../utils/DestructiveModal";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { createSocialRoute, SocialRoutes } from "./SocialHome";
import { usePostComments, usePostCommentMutations } from "../../services/social.service";
import styles from "./social.module.css"
import IconButton from "../utils/IconButton";
import { MoreVert, HighlightOff, CloseRounded } from "@mui/icons-material";
import { formatDate, formatDateByTimeDifference } from "../utils/HelperFunctions";
import { Butlerr } from "../../types/butlerr";
import { useButlerrUser } from '../../services/user.service';
import { Card, Form, Dropdown, Modal } from "react-bootstrap";
import BootstrapSpinner from "../utils/BootstrapSpinner";
import UserAvatar from "./UserAvatar";

interface PostCommentsProps {
    postId: number;
    show: boolean;
    onClose: () => void;
}
export default function PostComments({ postId, show, onClose } : PostCommentsProps) {

    const { data: comments } = usePostComments(postId, undefined, show);

    const [ commentToEdit, setCommentToEdit ] = useState<Butlerr.Social.Comment>()
    const [ commentToReply, setCommentToReply ] = useState<Butlerr.Social.Comment>()

    const newCommentInputRef = useRef<HTMLDivElement>(null)

    //reply stuff
    const [ shownReplies, setShownReplies ] = useState<number[]>([])

    const toggleShownReplies = (commentId: number, toggle?: boolean) => {
        setShownReplies(prev => {
            const currShown = prev.includes(commentId);
            //if already in desired state, return
            if (toggle === currShown) return prev;
            else if (toggle === undefined) toggle = !currShown;

            if (toggle === true) {
                return [...prev, commentId]
            }
            return prev.filter(c => c !== commentId)
        })
    }

    const handleCommentPosted = async (changed = false) => {
        if (changed) {
            const commentId = commentToEdit?.comm_parentcommid ?? commentToReply?.comm_id //comment id to expand, if hidden
            if (commentId) toggleShownReplies(commentId, true)
        }
        setCommentToEdit(undefined);
        setCommentToReply(undefined);
    }

    const commentCount = useMemo(() => {
        if (!comments) return 0;
        
        return comments.reduce((total, curr) => {
            return total + (curr.replies ?? 0);
        }, comments.length)
    }, [comments])

    return (
        <Modal
            show={show}
            onHide={onClose}
            size="lg"
            centered
            scrollable
            contentClassName={styles.commentModal}
        >
            <Modal.Header>
                <Modal.Title>
                    Comments { commentCount ? `(${commentCount})` : '' }
                </Modal.Title>
                <IconButton
                    Icon={CloseRounded}
                    title="Close"
                    className="ms-auto"
                    onClick={onClose}
                />
            </Modal.Header>
            <Modal.Body className="px-4 py-3">
                {
                    comments === undefined ? (
                        Array<null>(5).fill(null).map((_, idx) => (
                            <CommentSkeleton key={idx} />
                        ))
                    ) :
                    comments.length === 0 ? (
                        <p className="mb-0 text-center">
                            Be the first to comment!
                        </p>
                    ) : (
                        comments.map((comment) => (
                            <Comment
                                key={comment.comm_id}
                                comment={comment}
                                onCommentEdit={(c) => {
                                    setCommentToReply(undefined)
                                    setCommentToEdit(c)
                                    /* scroll to the input field & focus */
                                    newCommentInputRef.current?.scrollIntoView(true);
                                    newCommentInputRef.current?.querySelector('textarea')?.focus();
                                }}
                                onCommentReply={(c) => {
                                    setCommentToEdit(undefined)
                                    setCommentToReply(c)
                                    /* scroll to the input field & focus */
                                    newCommentInputRef.current?.scrollIntoView(true);
                                    newCommentInputRef.current?.querySelector('textarea')?.focus();
                                }}

                                showReplies={shownReplies.includes(comment.comm_id)}
                                toggleShowReplies={(t) => toggleShownReplies(comment.comm_id, t)}
                            />
                        ))
                    )
                }
            </Modal.Body>
            <Modal.Footer className="px-4 py-3">
                <NewCommentInputContainer
                    ref={newCommentInputRef}
                    postId={postId}
                    commentToEdit={commentToEdit}
                    commentToReply={commentToReply}
                    onInputDone={handleCommentPosted}
                    className="w-100 m-0"
                />
            </Modal.Footer>
        </Modal>
    )
}

interface NewCommentInputContainerProps extends React.HTMLAttributes<HTMLDivElement> {
    postId: number;
    commentToEdit?: Butlerr.Social.Comment;
    commentToReply?: Butlerr.Social.Comment;
    onInputDone: (changed?: boolean) => Promise<void>;
}

const NewCommentInputContainer = React.forwardRef<HTMLDivElement, NewCommentInputContainerProps>(({ postId, commentToEdit, commentToReply, onInputDone, ...props }, ref) => {

    const [ text, setText ] = useState('');

    const inputRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
        const comment = commentToEdit
        setText(comment?.comm_text.trimStart() ?? '');

        //manually trigger set input value & trigger on `change` event to recalculate rows
        if (inputRef.current) {
            const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLTextAreaElement.prototype, "value")?.set;
            nativeInputValueSetter?.call(inputRef.current, comment?.comm_text ?? '');
            
            const event = new Event('input', { bubbles: true });
            inputRef.current.dispatchEvent(event);
        }
    }, [ commentToEdit ])

    const calculateTextAreaRows = (ele: HTMLTextAreaElement) => {
        const textareaLineHeight = 24; // set in css (px)
        const minRows = 1;
        const maxRows = 5;

        const prevRows = ele.rows;
  	    ele.rows = minRows; // reset number of rows in textarea
		
		let currentRows = ~~(ele.scrollHeight / textareaLineHeight);
		
		if (currentRows > maxRows) {
			currentRows = maxRows;
			if (currentRows !== prevRows) ele.scrollTop = ele.scrollHeight;
		}
        ele.rows = currentRows
	};

    const { mutate: edit, isLoading: isEditing, error: editError } = usePostCommentMutations('EDIT');
    const { mutate: add, isLoading: isAdding, error: addError } = usePostCommentMutations('CREATE');

    const isLoading = isEditing || isAdding;
    const error = editError ?? addError;

    const onCommentPost = () => {
        const onSuccess = () => {
            setText('');
            onInputDone(true);

            //manually trigger set input value & trigger on `change` event to recalculate rows
            if (inputRef.current) {
                calculateTextAreaRows(inputRef.current);
            }
        }

        if (commentToEdit !== undefined) {
            //edit comment
            edit({
                postId,
                commentId: commentToEdit.comm_id,
                parentCommentId: commentToEdit.comm_parentcommid ?? undefined,
                comm_text: text
            }, { onSuccess })
        }
        else {
            //create comment
            add({
                postId,
                parentCommentId: commentToReply?.comm_id,
                comm_text: text
            }, { onSuccess })
        }
    }

    return (
        <div ref={ref} {...props}>
            {
                (commentToEdit !== undefined || commentToReply !== undefined) && (
                    <div className={classNames("px-1 py-0 mb-1 shadow-sm d-flex align-items-center rounded", styles.commentCard)}>
                        <IconButton
                            transparent
                            border={false}
                            className="p-0 me-2 text-dark"
                            Icon={HighlightOff}
                            onClick={() => onInputDone()}
                        />
                        <small>
                            { commentToEdit !== undefined ? (
                                "Editing comment"
                            ) : (
                                `Replying to ${commentToReply?.user_socialhandle}`
                            )}
                        </small>
                    </div>
                )
            }
            <Form.Control
                as="textarea"
                ref={inputRef}
                autoFocus={true}
                rows={1}
                className={styles.commentInput}
                disabled={isLoading}
                placeholder={`Write your ${commentToReply ? 'reply' : `comment`}...`}
                value={text}
                onChange={(ev) => {
                    setText(ev.target.value.trimStart().slice(0, 200))
                    calculateTextAreaRows(ev.target as HTMLTextAreaElement)
                }}
                // Change behavior of textarea to input
                onKeyPress={(e) => {
                    if (e.key === 'Enter' || e.keyCode === 13) {
                        e.preventDefault()
                    }
                }}
                onKeyUp={(e) => {
                    if (text && (e.key === 'Enter' || e.keyCode === 13)) {
                        onCommentPost()
                    }
                }}
            />
            {
                error !== null ? (
                    <small className="text-danger">{error.message}</small>
                ) : text.length > 0 && (
                    <small className="text-muted">
                        Press enter to { commentToEdit ? 'save' : 'post' }
                    </small>
                )
            }
        </div>
    )
})

interface BaseCommentProps {
    comment: Butlerr.Social.Comment;
    onCommentEdit: (comment: Butlerr.Social.Comment) => void;
}

interface ParentCommentProps {
    onCommentReply: (comment: Butlerr.Social.Comment) => void;

    showReplies: boolean;
    toggleShowReplies: (toggle?: boolean) => void;
}
interface ReplyCommentProps {
    onCommentReply?: undefined;
    replies?: undefined;
    fetchReplies?: undefined;
    showReplies?: undefined;
    toggleShowReplies?: undefined;
}

type CommentProps = (BaseCommentProps & ReplyCommentProps) | (BaseCommentProps & ParentCommentProps)

function Comment({ comment, onCommentEdit, onCommentReply, showReplies, toggleShowReplies } : CommentProps) {

    const { data: user } = useButlerrUser();

    const [ showDeleteModal, setShowDeleteModal ] = useState(false);

    const { mutate: deleteComment } = usePostCommentMutations('DELETE');

    const onCommentDelete = () => deleteComment({
        postId: comment.post_id,
        commentId: comment.comm_id,
        parentCommentId: comment.comm_parentcommid ?? undefined
    });
    
    return (
        <div className="position-relative">
            <div className="my-2 d-flex">
                <Link to={createSocialRoute(SocialRoutes.USER_PROFILE, { id: comment.user_id })}>
                    <UserAvatar
                        width={32}
                        height={32}
                        className="me-2"
                        user={comment}
                    />
                </Link>
                <div>
                    <Card className={classNames("px-2 py-1 border-0 shadow-sm small", styles.commentCard)}>
                        <div className="d-flex align-items-center">
                            <Link
                                to={createSocialRoute(SocialRoutes.USER_PROFILE, { id: comment.user_id })}
                                className={classNames("fw-bold text-muted small", styles.linkUnderline)}
                            >
                                {comment.user_socialhandle}
                            </Link>
                            <Link
                                to={createSocialRoute(SocialRoutes.USER_PROFILE, { id: comment.user_id })}
                                className={classNames("mx-3 text-muted small", styles.linkUnderline)}
                                title={formatDate(comment.comm_updated ?? comment.comm_created, 'long', 'long', true) ?? undefined}
                            >
                                {formatDateByTimeDifference(comment.comm_updated ?? comment.comm_created)}
                                {
                                    comment.comm_updated !== null && (
                                        <span> &bull; Edited</span>
                                    )
                                }
                            </Link>
                            {
                                user?.user_id === comment.user_id && (
                                    <Dropdown className={classNames("ms-auto", styles.optionsBtn)}>
                                        <Dropdown.Toggle
                                            as="span"
                                            bsPrefix=" "
                                            variant="clear"
                                        >
                                            <IconButton
                                                transparent
                                                border={false}
                                                Icon={MoreVert}
                                                iconHtmlColor="var(--bs-gray)"
                                            />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className="p-0">
                                            <Dropdown.Item onClick={() => onCommentEdit(comment)}>
                                                Edit
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={() => setShowDeleteModal(true)}>
                                                Delete
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                )
                            }
                        </div>
                        <p className="mb-0 text-break">
                            {comment.comm_text}
                        </p>
                    </Card>
                    {
                        onCommentReply !== undefined && (
                            <div className="d-flex justify-content-between">
                                <button
                                    className={classNames(styles.linkUnderline, "ms-2 fw-bold text-primary border-0 p-0 bg-transparent")}
                                    onClick={() => {
                                        onCommentReply(comment)
                                    }}
                                >
                                    <small>Reply</small>
                                </button>

                                {
                                    comment.replies !== undefined && comment.replies > 0 && (
                                        <button
                                            className={classNames(styles.linkUnderline, "me-2 fw-bold text-primary border-0 p-0 bg-transparent")}
                                            onClick={() => {
                                                toggleShowReplies?.()
                                            }}
                                        >
                                            <small>{comment.replies} replies</small>
                                        </button>
                                    )
                                }
                            </div>
                        )
                    }
                </div>
            </div>
            {
                showReplies === true && (
                    <CommentReplies
                        postId={comment.post_id}
                        parentCommentId={comment.comm_id}
                        onCommentEdit={onCommentEdit}
                        hideReplies={() => toggleShowReplies?.(false)}
                    />
                )
            }
            <DestructiveModal
                show={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
                title="Delete comment?"
                description="The replies to the comment will not be deleted"
                onConfirm={onCommentDelete}
                backdropClassName="modal-layer-1"
                className="modal-layer-1"
            />
        </div>
    )
}

interface CommentRepliesProps {
    postId: number;
    parentCommentId: number;
    onCommentEdit: (comment: Butlerr.Social.Comment) => void;
    hideReplies: () => void;
}

function CommentReplies({ postId, parentCommentId, onCommentEdit, hideReplies } : CommentRepliesProps) {

    const { data: replies, isFetching } = usePostComments(postId, parentCommentId);

    if (!isFetching && replies !== undefined && replies.length === 0) {
        hideReplies();
    }
    
    return (
        <div className="d-flex flex-row">
            <div
                style={{
                    width: 2,
                    background: 'var(--bs-gray-300)',
                    //absolute to entire comment container
                    top: 30,
                    bottom: 30
                }}
                className="rounded-pill my-2 mx-3 position-absolute"
            >
            </div>
            <div className="w-100" style={{ marginLeft: '2.5rem' }}>
                {
                    replies === undefined ? (
                        <BootstrapSpinner />
                    ) : (
                        replies.map((comment) => (
                            <Comment
                                key={comment.comm_id}
                                comment={comment}
                                onCommentEdit={() => onCommentEdit(comment)}
                            />
                        ))
                    )
                }
            </div>
        </div>
    )
}

function CommentSkeleton() {
    return (
        <div className="d-flex mb-2">
            <div
                className="skeleton-box rounded-circle me-2"
                style={{ height: '32px', width: '32px' }}
            ></div>
            <div className={classNames("w-100 px-2 py-2 border-0 shadow-sm small", styles.commentCard)}>
                <div
                    className="skeleton-box mb-2"
                    style={{ width: '30%', height: '13px' }}
                ></div>
                <div
                    className="skeleton-box"
                    style={{ width: '100%', height: '20px' }}
                ></div>
            </div>
        </div>
    )
}