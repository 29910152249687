import { InfiniteData } from "react-query";
import { Butlerr } from "../types/butlerr";
import { PartnerQueries } from "./partner.service";
import { SocialQueries } from "./social.service";
import useButlerrAPI, { ButlerrMutationOptions, useButlerrMutation, useButlerrPaginatedQuery, ButlerrPaginatedResult } from "./useButlerrAPI"

type TSocialChannel = InfiniteData<ButlerrPaginatedResult<Butlerr.Social.Channel[]>>
type TSocialPost = InfiniteData<ButlerrPaginatedResult<Butlerr.Social.Post[]>>

export interface FetchChannelOptions {
    editable_only?: boolean;
    latest_only?: boolean;
    following_only?: boolean;
    sort?: "top_followers" | "top_contributors" | "trending";
    query?: string;

    partner?: number;
    channel?: number;

    limit?: number;
}

export const SocialChannelsQueries = {
    CHANNELS: (params: FetchChannelOptions) => ['social', 'channels', params] as const,
}

export function useChannelEditors(channelId: number) {
    return useButlerrAPI<Butlerr.Social.ChannelEditors[]>(
        PartnerQueries.CHANNEL_EDITORS(channelId),
        `/api/social/channels/${channelId}/editors`
    );
}

export function useSocialChannels(params: FetchChannelOptions = {}, enabled = true) {

    //set defaults (to keep query keys more accurate)
    const { limit = 20, ...rest } = params;

    const options = {
        limit, ...rest
    }

    const searchParams = new URLSearchParams();
    for (const key in options) {
        const value = options[key as keyof typeof options]
        if (value !== undefined) {
            searchParams.set(key, String(value))
        }
    }

    return useButlerrPaginatedQuery<Butlerr.Social.Channel[]>(
        SocialChannelsQueries.CHANNELS(options),
        `/api/social/channels?${searchParams.toString()}`,
        true,
        { enabled }
    );
}

type ChannelForm = {
    chan_id: number;
    chan_name: string;
    chan_desc: string;
    chan_partid: number;
    chan_profile?: Blob;
    users?: Pick<Butlerr.UserInfo, 'user_id' | 'user_socialhandle' | 'user_profile'>[];
}

const ChannelsMutations = {
    CREATE: (body: Omit<ChannelForm, 'chan_id'>) : ButlerrMutationOptions => ({
        url: "/api/social/channels",
        method: "POST",
        requestBody: {
            ...body,
            users: body.users?.map(u => u.user_id)
        },
        useFormData: true,
        updater: {
            queryKey: SocialChannelsQueries.CHANNELS({}),
            partialMatch: true,
            action: 'refetch'
        }
    }),
    EDIT: ({ chan_id, users, ...body }: Omit<ChannelForm, 'chan_partid'>) : ButlerrMutationOptions => ({
        url: `/api/social/channels/${chan_id}`,
        method: "PUT",
        requestBody: {
            ...body,
            users: users?.map(u => u.user_id)
        },
        useFormData: true,
        queryKey: SocialChannelsQueries.CHANNELS({}),
        refetchData: 'all',
        updater: [{
            queryKey: SocialChannelsQueries.CHANNELS({}),
            partialMatch: true,
            optimistic: true,
            action: ({ pages, pageParams } : TSocialChannel) => ({
                pageParams,
                pages: pages.map(({result, ...p}) => ({
                    ...p,
                    result: result.map(c => c.chan_id !== chan_id ? c : ({
                        ...c,
                        chan_name: body.chan_name,
                        chan_desc: body.chan_desc
                    }))
                }))
            })
        }, {
            queryKey: SocialQueries.POSTS({}),
            partialMatch: true,
            optimistic: true,
            action: ({ pages, pageParams } : TSocialPost) => ({
                pageParams,
                pages: pages.map(({result, ...p}) => ({
                    ...p,
                    result: result.map(p => p.post_chanid !== chan_id ? p : ({
                        ...p,
                        chan_name: body.chan_name
                    }))
                }))
            })
        }, {
            queryKey: PartnerQueries.CHANNEL_EDITORS(chan_id),
            action: () => users?.map((u) => ({
                ...u, chan_id
            })) ?? []
        }]
    }),
    DELETE: (chan_id: number) : ButlerrMutationOptions => ({
        url: `/api/social/channels/${chan_id}`,
        method: "DELETE",
        updater: [{
            queryKey: SocialChannelsQueries.CHANNELS({}),
            partialMatch: true,
            action: ({ pages, pageParams } : TSocialChannel) => ({
                pageParams,
                pages: pages.map(({result, ...p}) => ({
                    ...p,
                    result: result.filter(c => c.chan_id !== chan_id)
                }))
            })
        }, {
            queryKey: SocialQueries.POSTS({}),
            partialMatch: true,
            action: ({ pages, pageParams } : TSocialPost) => ({
                pageParams,
                pages: pages.map(({result, ...p}) => ({
                    ...p,
                    result: result.filter(p => p.post_chanid !== chan_id)
                }))
            })
        }]
    }),
    FOLLOW: (channelId: number) : ButlerrMutationOptions<TSocialChannel> => ({
        url: `/api/social/channels/${channelId}/following`,
        method: "POST",
        //refresh your feed
        queryKey: SocialQueries.POSTS({ following_only: true }),
        refetchData: true,
        updater: [{
            queryKey: SocialChannelsQueries.CHANNELS({}),
            partialMatch: true,
            optimistic: true,
            action: ({ pages, pageParams }) => ({
                pageParams,
                pages: pages.map(({result, ...p}) => ({
                    ...p,
                    result: result.map(c => c.chan_id !== channelId ? c : ({
                        ...c,
                        user_follow: 1,
                        followers: c.followers + 1
                    }))
                }))
            })
        }, {
            queryKey: SocialQueries.POSTS({ following_only: true }),
            action: 'refetch',
            partialMatch: true
        }]
    }),
    UNFOLLOW: (channelId: number) : ButlerrMutationOptions<TSocialChannel> => ({
        url: `/api/social/channels/${channelId}/following`,
        method: "DELETE",
        //refresh your feed
        queryKey: SocialQueries.POSTS({ following_only: true }),
        refetchData: true,
        updater: [{
            queryKey: SocialChannelsQueries.CHANNELS({}),
            partialMatch: true,
            optimistic: true,
            action: ({ pages, pageParams }) => ({
                pageParams,
                pages: pages.map(({result, ...p}) => ({
                    ...p,
                    result: result.map(c => c.chan_id !== channelId ? c : ({
                        ...c,
                        user_follow: 0,
                        followers: c.followers - 1
                    }))
                }))
            })
        }, {
            queryKey: SocialQueries.POSTS({ following_only: true }),
            action: 'refetch',
            partialMatch: true
        }]
    })
}

export function useSocialChannelMutations<Key extends keyof typeof ChannelsMutations>(key: Key) {
    //You won't get type suggestions in the mutate function without this assertion
    const mutationFn = ChannelsMutations[key] as (params: Parameters<typeof ChannelsMutations[Key]>[0]) => ButlerrMutationOptions;
    return useButlerrMutation(mutationFn);
}