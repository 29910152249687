import { CloseRounded, DeleteOutlineRounded, PhotoRounded, PersonAddAlt1Rounded, SlowMotionVideoRounded } from "@mui/icons-material"
import { useState, useRef, useEffect } from "react"
import { Modal, Form } from "react-bootstrap"
import { useSocialMutations } from "../../services/social.service"
import { Butlerr } from "../../types/butlerr"
import IconButton from "../utils/IconButton"
import styles from "./social.module.css"

import UserDropdownButton from "../utils/UserDropdownButton"
import ButlerrImage from "../ui/ButlerrImage"
import EmojiPickerButton from "../ui/EmojiPicker"
import { getStringLengthWNewLine } from "../utils/HelperFunctions"

const POST_MAX_CHAR = 2048;

interface EditPostModalProps {
    show: boolean;
    onClose: () => void;
    post: Butlerr.Social.Post;
}

export default function EditPostModal({ show, onClose, post } : EditPostModalProps) {

    const [err, setErr] = useState('')

    const [form, setForm] = useState({
        post_msg: '',
        post_url: '',
        post_hashtags: ''
    })

    const [hashtags, setHashtags] = useState<string[]>([])
    const [tagged, setTagged] = useState<Omit<Butlerr.UserInfo, 'user_profile'>[]>([])

    const extractHashtags = () => {
        return new Promise<string[]>((res) => {
            setForm((state) => {
                setHashtags((oldTags) => {
                    const tag = state.post_hashtags.replace(/\W/g, '').toLowerCase()

                    const tags = (tag && !oldTags.includes(tag)) ? [
                        ...oldTags, tag
                    ] : oldTags

                    res(tags)
                    return tags
                })
                return {
                    ...state, post_hashtags: ''
                }
            })
        })
    }

    const [images, setImages] = useState<Butlerr.Social.Post['media']>([])

    // Image drag & drop handlers
    const onImageDrag = (e: React.DragEvent<HTMLImageElement>, index: number) => {
        e.dataTransfer.setData('text/plain', index.toString())
        e.dataTransfer.setDragImage(e.currentTarget, 0, 0)
    }

    const onImageDrop = (e: React.DragEvent<HTMLLIElement>, newIndex: number) => {
        cancelDefault(e)
        // get new and old index
        let oldIndex = parseInt(e.dataTransfer.getData('text/plain'))
        
        setImages((oldImages) => {
            oldImages.splice(newIndex, 0, oldImages.splice(oldIndex, 1)[0])
            return [...oldImages]
        })
    }

    const cancelDefault = (e: React.DragEvent<HTMLLIElement>) => {
        e.preventDefault()
        e.stopPropagation()
        return false
    }

    //stuff for uploading new images
    //ref for input file button
    const addFileRef = useRef<HTMLInputElement>(null)

    //file as well as base64 url
    type MediaFile = {
        url: string;
        name?: undefined;
        file: File;
    } | {
        url?: undefined;
        name: string;
        file: File;
    }
    const [files, setFiles] = useState<MediaFile[]>([])
    
    // convert file to a base64 url
    const getFileWithUrl = (file: File) : Promise<MediaFile> => {
        return new Promise((res, rej) => {
            if (file.type.startsWith('image')) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    if (e.target?.result) {
                        res({
                            file,
                            url: e.target.result as string
                        })
                    }
                    else rej()
                }
                reader.onerror = (e) => rej(e);
                reader.readAsDataURL(file);
            }
            else if (file.type.startsWith('video')) {
                res({
                    file,
                    name: file.name
                })
            }
            else rej();
        });
    };

    useEffect(() => {
        //update state when post is changed or modal is opened
        setForm({
            post_msg: post.post_msg ?? '',
            post_url: post.post_url ?? '',
            post_hashtags: ''
        })
        if (post.post_hashtags) setHashtags(post.post_hashtags.split(','))
        setTagged(post.tagged)
        setImages(post.media)
        setFiles([])

        if (msgInputRef.current) calculateTextAreaRows(msgInputRef.current)

    }, [ show, post ])

    //user context
    const { mutate: editPost, isLoading, error } = useSocialMutations('EDIT');

    const onPostEdit = async () => {
        setErr('')
        if (getStringLengthWNewLine(form.post_msg) > POST_MAX_CHAR) {
            return setErr(`Post can't contain more than ${POST_MAX_CHAR} characters`);
        }
        
        const hashtags = await extractHashtags()
        if (hashtags.length > 100) {
            return setErr("Hashtags can't contain more than 100 characters")
        }
        if (form.post_url.length > 384) {
            return setErr("URL can't contain more than 384 characters")
        }
        editPost({
            post_id: post.post_id,
            post_msg: form.post_msg,
            post_url: form.post_url, //do not send url if collapsed
            post_hashtags: hashtags.join(','),
            tagged,
            pimg_id: images.map(i => i.pimg_id),
            post_images: files.map(f => f.file),
            url_changed: post.post_url !== form.post_url
        }, {
            onSuccess: onClose
        })
    }

    const msgInputRef = useRef<HTMLTextAreaElement>(null);
    const calculateTextAreaRows = (ele: HTMLTextAreaElement) => {
        const textareaLineHeight = 24; // set in css (px)
        const minRows = 3;
        const maxRows = 10;

        const prevRows = ele.rows;
  	    ele.rows = minRows; // reset number of rows in textarea
		
		let currentRows = ~~(ele.scrollHeight / textareaLineHeight);
		
		if (currentRows > maxRows) {
			currentRows = maxRows;
            if (currentRows !== prevRows) ele.scrollTop = ele.scrollHeight;
		}
        ele.rows = currentRows
	};

    return (
        <Modal show={show} size='lg' centered backdrop={isLoading ? "static" : undefined} keyboard={!isLoading} onHide={onClose}>
            <Modal.Header>
                <Modal.Title>Edit post</Modal.Title>
                <IconButton
                    title="Close modal"
                    Icon={CloseRounded}
                    className="ms-auto"
                    disabled={isLoading}
                    onClick={onClose}
                />
            </Modal.Header>
            <div className={styles.newPostContainer + " p-4"}>
                <Form.Control
                    as="textarea"
                    className={styles.postInput + " mb-2"}
                    placeholder="Content"
                    value={form.post_msg}
                    ref={msgInputRef}
                    onChange={(e) => {
                        const val = e.target.value;
                        setForm(prev => ({
                            ...prev,
                            post_msg: val
                        }));
                        calculateTextAreaRows(e.target as HTMLTextAreaElement);
                    }}
                />
                {(() => {
                    const msgLength = getStringLengthWNewLine(form.post_msg);
                    if (msgLength === 0) return false;
                    return (
                        <p className={`small mb-1 text-end ${msgLength > POST_MAX_CHAR ? 'text-danger' : 'text-muted'}`}>
                            {msgLength} / {POST_MAX_CHAR} characters
                        </p>
                    )
                })()}
                <Form.Control
                    placeholder="Link"
                    className={styles.postInput + " mt-2"}
                    value={form.post_url}
                    onChange={(e) => setForm(prev => ({ ...prev, post_url: e.target.value }))}
                />
                <Form.Control
                    placeholder="Hashtags"
                    className={styles.postInput + " mt-2 form-control"}
                    value={form.post_hashtags}
                    onChange={(e) => setForm(prev => ({ ...prev, post_hashtags: e.target.value }))}
                    onKeyUp={(e) => {
                        //extract hashtags on space or enter key hit
                        if (e.key === ' ' || e.key === 'Enter') {
                            extractHashtags()
                        }
                    }}
                />
                {
                    hashtags.length > 0 && (
                        <div className="mt-3 d-flex flex-wrap">
                            {
                                hashtags.map((str) => (
                                    <mark
                                        key={str}
                                        className={[styles.hashtag, "me-2 mb-1"].join(' ')}
                                        onClick={() => setHashtags(state => state.filter(tag => tag !== str))}
                                    >
                                        {str}
                                    </mark>
                                ))
                            }
                        </div>
                    )
                }
                {
                    tagged.length > 0 && (
                        <div className="mt-3 d-flex flex-wrap">
                            {
                                tagged.map((tag) => (
                                    <span
                                        key={tag.user_id}
                                        className={[styles.personTag, "me-2 mb-1"].join(' ')}
                                        onClick={() => setTagged(state => state.filter(str => str !== tag))}
                                    >
                                        {tag.user_socialhandle}
                                    </span>
                                ))
                            }
                        </div>
                    )
                }
                <ul className="d-flex p-0 overflow-auto mt-2">
                    {
                        images.map((img, index) => (
                            <li
                                key={img.pimg_id}
                                className={styles.imagePreview + " px-2 position-relative"}
                                draggable={true}
                                onDrop={(e) => onImageDrop(e, index)}
                                onDragEnter={cancelDefault}
                                onDragOver={cancelDefault}
                            >
                                {
                                    img.pimg_type === 'image' ? (
                                        <ButlerrImage
                                            url={`/api/social/post/${post.post_id}/image/${img.pimg_id}`}
                                            alt=""
                                            onDragStart={(e) => onImageDrag(e, index)}
                                        />
                                    ) : (
                                        <div className="p-2 text-wrap text-light fw-bold small text-center d-flex align-items-center justify-content-center">
                                            <SlowMotionVideoRounded style={{ fontSize: '2rem' }} />
                                        </div>
                                    )
                                }
                                
                                <button
                                    className={styles.removeBtn + " d-flex justify-content-center align-items-center"}
                                    onClick={() => {
                                        setImages(oldImages => oldImages.filter((_, i) => i !== index))
                                    }}
                                >
                                    <DeleteOutlineRounded />
                                </button>
                            </li>
                        ))
                    }
                    {
                        files.map((file, index) => (
                            <li
                                key={file.file.name + file.file.size}
                                className={styles.imagePreview + " px-2 position-relative"}
                            >
                                {
                                    file.url !== undefined ? (
                                        <img
                                            src={file.url}
                                            alt=""
                                            onDragStart={(e) => onImageDrag(e, index)}
                                            className="border"
                                            style={{ cursor: 'auto' }}
                                        ></img>
                                    ) : (
                                        <div className="p-2 text-wrap text-light fw-bold small text-center d-flex align-items-center">
                                            { file.name }
                                        </div>
                                    )
                                }
                                <button
                                    className={styles.removeBtn + " d-flex justify-content-center align-items-center"}
                                    onClick={() => {
                                        setFiles(oldFiles => oldFiles.filter((_, i) => i !== index))
                                    }}
                                >
                                    <DeleteOutlineRounded />
                                </button>
                            </li>
                        ))
                    }
                </ul>
                {
                    (err !== undefined || error !== null) && <p className="text-danger w-100">{err ?? error?.message}</p>
                }
                <div className="d-flex align-items-center">
                    <input
                        ref={addFileRef}
                        type="file"
                        className="d-none"
                        accept="image/*,video/*"
                        multiple={true}
                        onChange={async (e) => {
                            if (e.target.files !== null) {
                                const files = Array.from(e.target.files)
                                //turn files into ImageFile objects
                                const images = await Promise.allSettled(
                                    files.map((f) => getFileWithUrl(f))
                                )
                                setFiles((prev) => {
                                    return [
                                        ...prev,
                                        ...images
                                            .flatMap(i => i.status === 'fulfilled' ? i.value : [])
                                            .filter(i => prev.findIndex(p => p.url === i.url) === -1)
                                    ].slice(0, 5)
                                })
                            }
                            else {
                                setFiles([])
                            }
                            return false //don't store file in input (to allow readding the same file)
                        }}
                    ></input>
                    <EmojiPickerButton
                        onEmojiSelect={(em) => {
                            setForm(f => ({
                                ...f,
                                post_msg: f.post_msg + em.native
                            }))
                        }}
                    />
                    <IconButton
                        transparent
                        Icon={PhotoRounded}
                        title="Add Photo"
                        disabled={isLoading || (images.length + files.length) >= 5}
                        // iconHtmlColor="var(--black)"
                        className="text-dark ms-2"
                        onClick={() => addFileRef.current?.click()}
                    />

                    <UserDropdownButton
                        className="ms-2 d-block"
                        bsPrefix=" "
                        as="span"
                        limit={10}
                        selectedUsers={tagged}
                        setSelectedUsers={setTagged}
                        searchOptions={{
                            is_taggable: true
                        }}
                    >
                        <IconButton
                            transparent
                            Icon={PersonAddAlt1Rounded}
                            title="Tag people"
                            className="text-dark"
                        />
                    </UserDropdownButton>
                    <IconButton
                        disabled={isLoading}
                        onClick={onPostEdit}
                        className="ms-auto"
                        label='Save'
                    />
                </div>
            </div>
        </Modal>
    )
}