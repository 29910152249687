import { Fragment, useEffect, useMemo } from "react"
import { useSocialPosts, FetchPostOptions } from "../../services/social.service"
import NewPostContainer from "./NewPostContainer"
import Post from "./Post"
import ErrorPage from "../utils/ErrorPage";
import EmptyMessage from "../utils/EmptyMessage";
import styles from "./social.module.css";
import { useHistory } from "react-router-dom"
import useQuery from "../../hooks/useQuery"
import IconButton from "../utils/IconButton";
import { GroupsOutlined, BookmarkBorderRounded, TimelineRounded, TimelapseRounded, StarRounded, PublicRounded, CheckOutlined, CollectionsBookmarkRounded } from "@mui/icons-material";
import classNames from "classnames"
import { Dropdown } from "react-bootstrap"
import ToggleButtonGroup, { ToggleItem } from "../ui/ToggleButtonGroup"
import InfiniteScroller from "../ui/InfiniteScroller"

type KeysWithType<T, V> = {[K in keyof T]-?: T[K] extends V ? K : never}[keyof T];
//Maps for item keys in URL search
type SortKeys = 'l' | 't' | 'tr' | 'bd';
const SORT_MAP : Record<SortKeys, FetchPostOptions['sort']> = {
    l: "latest",
    t: "trending",
    tr: "top_reactions",
    bd: "bookmark_date"
} as const;

type FilterKeys = 'b' | 'f' | 'a';
/**
 * If undefined, we select `following_only` by default.
 * If null, we select public posts (no filter).
 */
const FILTER_MAP : Record<FilterKeys, KeysWithType<Required<FetchPostOptions>, boolean> | null> = {
    f: "following_only",
    a: null,
    b: "bookmarked_only"
} as const;

interface PostListProps {
    emptyMessage?: string;

    /* Post ID */
    post?: number;
    user?: number;
    channel?: number;
}
export default function PostList({ emptyMessage, post, user, channel }: PostListProps) {

    const search = useQuery();
    const history = useHistory();

    const params = useMemo(() => {
        const params : FetchPostOptions = {};

        //if post, user or channel id is specified, ignore everything else
        if (post !== undefined) {
            params.post = post;
        }
        else if (user !== undefined) {
            params.user = user;
        }
        else if (channel !== undefined) {
            params.channel = channel;
        }
        else {
            //Note: `t` search key is used in SocialSearch component to determine which tab the search is in.
            const query = decodeURIComponent(search.get("q") || "");
            const hashtags = decodeURIComponent(search.get("h") || "");
    
            if (query) params.query = query;
            if (hashtags) params.hashtags = hashtags;

            const filterKey = FILTER_MAP[decodeURIComponent(search.get("f") || "") as FilterKeys];
            //if null, no filter (all posts)
            if (filterKey !== null) {
                //set matching filter key to true;
                //if undefined, show following only
                params[filterKey ?? 'following_only'] = true;
            }

            const sortKey = SORT_MAP[decodeURIComponent(search.get("s") || "") as SortKeys] ?? "latest";

            //If sort key specified is 'bookmark_date' & it's not `bookmarked_only`, use `latest` instead
            if (sortKey === 'bookmark_date' && !params.bookmarked_only) {
                params.sort = 'latest';
                //replace in url
                search.set('s', 'l');
                history.replace({
                    search: search.toString()
                })
            }
            else params.sort = sortKey;
        }

        return params;
    }, [channel, history, post, search, user])

    const { data, isLoading, error, refetch, hasNextPage, fetchNextPage } = useSocialPosts(params);
    
    // Speical case: Always refetch when bookmarked_only becomes `true`
    useEffect(() => {
        if (params.bookmarked_only) {
            refetch();
        }
    }, [params.bookmarked_only, refetch]);

    const posts = useMemo(() => data?.pages.flatMap(p => p.result).filter(p => p !== undefined), [data]);

    let content: JSX.Element;

    if (isLoading) {
        content = (
            <>{
                Array<null>(5).fill(null).map((_, idx) => (
                    <PostSkeleton key={idx} />
                ))
            }</>
        )
    }
    else if (posts === undefined || posts.length === 0) {
        if (error) {
            content = <ErrorPage status={error.status} message={error.message} handleRetry={refetch} />;
        }
        content = (
            <EmptyMessage
                message={emptyMessage ?? (params.following_only ? "Follow some people to populate your feed" : "Nothing to see here")}
                className="fs-5 p-4"
            />
        )
    }
    else {
        content = (
            <>
                <InfiniteScroller
                    hasNextPage={hasNextPage}
                    fetchNextPage={fetchNextPage}
                    isLoading={isLoading}
                    loadingEle={(
                        <>
                            <PostSkeleton />
                            <PostSkeleton />
                            <PostSkeleton />
                        </>
                    )}
                >
                    <div className="d-flex flex-column">
                        {
                            posts.map((post) => (
                                <Post
                                    key={post.post_id}
                                    post={post}
                                />
                            ))
                        }
                    </div>
                </InfiniteScroller>
            </>
        )
    }

    return (
        <div>
            {
                //hide new post container, if Post ID or User ID is specified or is searching
                post === undefined && user === undefined && params.hashtags === undefined && params.query === undefined && (
                    <NewPostContainer className="mb-3" initialChannelId={channel} />
                )
            }
            {
                //hide sort & filters, if Post ID is specified
                post === undefined && user === undefined && channel === undefined && (
                    <SortFilterBar />
                )
            }
            {content}
        </div>
    )
}

function PostSkeleton() {
    return (
        <div className={styles.postContainer + " p-3 mb-2 shadow-sm"}>
            <div className={styles.postHeader + " d-flex flex-row align-items-center mb-3"}>
                <div    
                    className="skeleton-box rounded-circle flex-shrink-0"
                    style={{ width: '50px', height: '50px' }}
                ></div>
                <div className="w-100 align-self-stretch ms-3 d-flex flex-column justify-content-around">
                    <div
                        className="skeleton-box"
                        style={{ width: '75%', height: '18px' }}
                    ></div>
                    <div
                        className="skeleton-box"
                        style={{ width: '30%', height: '18px' }}
                    ></div>
                </div>
            </div>
            <div>
                <div
                    className="skeleton-box rounded"
                    style={{ width: '100%', height: '70px' }}
                ></div>
            </div>
        </div>
    )
}

const SortFilterBar = () => {

    const search = useQuery();
    const history = useHistory();

    const handleFilterChange = (filter: FilterKeys) => {
        search.set('f', filter);
        history.replace({
            search: search.toString()
        })

        //when switching to `bookmarked_only`, set default sort
        if (filter === 'b') {
            handleSortChange('bd');
        }
        //when switching away AND the active sort is bookmarked date, change to latest
        else if (activeSort === 'bd') {
            handleSortChange('l');
        }
    }
    const handleSortChange = (sort: SortKeys) => {
        search.set('s', sort);
        history.replace({
            search: search.toString()
        })
    }

    type Item = Omit<ToggleItem, 'active' | 'render'>

    let activeFilter = decodeURIComponent(search.get('f') || "") as FilterKeys;
    if (!(activeFilter in FILTER_MAP)) activeFilter = 'f';

    const filterItems : Record<FilterKeys, Item> = {
        f: {
            Icon: GroupsOutlined,
            label: "Following"
        },
        a: {
            Icon: PublicRounded,
            label: "All Posts"
        },
        b: {
            Icon: BookmarkBorderRounded,
            label: "Bookmarked"
        }
    }

    let activeSort = decodeURIComponent(search.get('s') || "") as SortKeys;
    if (!(activeSort in SORT_MAP)) activeSort = 'l';

    const sortItems : Record<SortKeys, Item> = {
        bd: {
            Icon: CollectionsBookmarkRounded,
            label: "Latest bookmarks"
        },
        l: {
            Icon: TimelapseRounded,
            label: "Latest posts",
        },
        t: {
            Icon: TimelineRounded,
            label: "Trending recently"
        },
        tr: {
            Icon: StarRounded,
            label: "All-time popular",
        }
    };

    return (
        <div className="mb-2 d-flex align-items-center justify-content-between flex-wrap">
            <ToggleButtonGroup
                className="d-flex my-1"
                items={Object.entries(filterItems).map(([ key, item ]) => ({
                    ...item,
                    key: key as FilterKeys,
                    active: key === activeFilter
                }))}
                onSelect={(item) => {
                    handleFilterChange(item.key)
                }}
            />
            <div className="d-flex my-1 ms-auto">
                <Dropdown align="end">
                    <Dropdown.Toggle
                        as="span"
                        bsPrefix=" "
                    >
                        <IconButton
                            transparent
                            Icon={ sortItems[activeSort].Icon }
                            title="Sort"
                            label=""
                            className="rounded-circle p-2 bg-light text-primary"
                            iconHtmlColor="var(--primary)"
                            iconStyles={{ fontSize: '1.5rem' }}
                        />
                    </Dropdown.Toggle>
                    
                    <Dropdown.Menu>
                        {
                            Object.entries(sortItems).map(([ key, { Icon, label } ]) => {
                                if (key === 'bd' && activeFilter !== 'b') return <Fragment key={key} />

                                const isActive = key === activeSort;
                                return (
                                    <Dropdown.Item
                                        key={key}
                                        disabled={isActive}
                                        className={classNames("p-2 d-flex align-items-center", isActive && "text-primary")}
                                        onClick={() => handleSortChange(key as SortKeys)}
                                    >
                                        <Icon fontSize="small" className="me-2" />
                                        {label}

                                        { isActive && (
                                            <CheckOutlined fontSize="small" className="text-primary ms-auto" />
                                        ) }
                                    </Dropdown.Item>
                                )
                            })
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    )
}