import { WorkOutline } from '@mui/icons-material';
import { useState } from 'react';
import { style } from '@mui/system';

import { useWorkProfile, usePartnerMutations } from '../../../services/partner.service';
import BootstrapSpinner from '../../utils/BootstrapSpinner';
import ErrorPage from '../../utils/ErrorPage';
import ButlerrCard from '../../utils/ButlerrCard';
import PartnerDetails from './PartnerDetails';
import DestructiveModal from '../../utils/DestructiveModal';
import { Alert, Button } from 'react-bootstrap';

interface WorkProfileInfoProps {
    wkpfId: number;
}

const WorkProfileInfo = ({ wkpfId }: WorkProfileInfoProps) => {
    const { data: workProfile, isLoading, error } = useWorkProfile(Number(wkpfId));

    // staff: cancel pending request
    const { mutateAsync: cancelRequest, isLoading: isCancelLoading } =
        usePartnerMutations('CANCEL_REQUEST_JOIN');

    const [showCancelRequestModal, setShowCancelRequestModal] = useState(false);
    const onCancelRequestClose = () => {
        setShowCancelRequestModal(false);
    };
    const onCancelRequestConfirm = async () => {
        await cancelRequest({ wkpf_id: wkpfId });
    };

    // staff: accept/reject invitation
    const { mutate: acceptRequest, isLoading: isAcceptLoading } =
        usePartnerMutations('ACCEPT_REQUEST');
    const { mutate: rejectRequest, isLoading: isRejectLoading } =
        usePartnerMutations('REJECT_REQUEST');

    const handleAccept = () => {
        acceptRequest(
            { wkpf_id: wkpfId },
            {
                onError: (error) => {
                    alert(error.message || 'Something went wrong. Please try again later.');
                },
            }
        );
    };
    const handleReject = () => {
        rejectRequest(
            { wkpf_id: wkpfId },
            {
                onError: (error) => {
                    alert(error.message || 'Something went wrong. Please try again later.');
                },
            }
        );
    };

    if (isLoading || !workProfile) return <BootstrapSpinner />;

    if (error !== null) return <ErrorPage status={error.status} message={error.message} />;

    return (
        <div>
            <ButlerrCard
                items={{ Icon: WorkOutline, title: 'Work Profile' }}
                style={{
                    borderRadius: '24px',
                    ...style,
                }}
                className="border-0 mb-3"
            >
                {workProfile.wpst_status === 0 && (
                    <>
                        {workProfile.wpst_requestedby === 'S' ? (
                            <>
                                <Alert
                                    variant="warning"
                                    className="d-flex justify-content-between align-items-center"
                                >
                                    <span>
                                        Your request to join this work profile is pending approval.
                                    </span>
                                    <Button
                                        variant="danger"
                                        onClick={() => setShowCancelRequestModal(true)}
                                        disabled={isCancelLoading}
                                    >
                                        Cancel request
                                    </Button>
                                </Alert>
                                <DestructiveModal
                                    show={showCancelRequestModal}
                                    onClose={onCancelRequestClose}
                                    onConfirm={onCancelRequestConfirm}
                                    title="Cancel request?"
                                    description="Are you sure you want to cancel your request to join this company?"
                                    cancelBtnText="No"
                                    confirmBtnText="Yes"
                                />
                            </>
                        ) : (
                            <Alert
                                variant="warning"
                                className="d-flex justify-content-between align-items-center"
                            >
                                <span>
                                    You have been invited to this company
                                    {workProfile.wpst_position && (
                                        <span>
                                            &nbsp;as <b>{workProfile.wpst_position}</b>
                                        </span>
                                    )}
                                    .
                                </span>

                                <div>
                                    <Button
                                        className="me-2"
                                        variant="danger"
                                        onClick={handleReject}
                                        disabled={isAcceptLoading || isRejectLoading}
                                    >
                                        Reject
                                    </Button>
                                    <Button
                                        onClick={handleAccept}
                                        disabled={isAcceptLoading || isRejectLoading}
                                    >
                                        Accept
                                    </Button>
                                </div>
                            </Alert>
                        )}
                    </>
                )}

                <PartnerDetails partner={workProfile} />
            </ButlerrCard>
        </div>
    );
};

export default WorkProfileInfo;
