import { Row, Col } from 'react-bootstrap';
import { Done, Close, InfoOutlined, EditTwoTone } from '@mui/icons-material';

import IconButton from '../../utils/IconButton';
import { Butlerr } from '../../../types/butlerr';
import { AssetTabContent } from '../Asset';
import PageHeader from '../../utils/PageHeader';

import AssetInfoHeader from './AssetInfoHeader';
import AssetInfoMedia from './AssetInfoMedia';
import AssetInfoValuation from './AssetInfoValuation';
import AssetInfoConfiguration from './AssetInfoConfiguration';
import AssetInfoDetails from './AssetInfoDetails';
import AssetInfoFeatures from './AssetInfoFeatures';
import AssetInfoRoles from './AssetInfoRoles';
import AssetInfoNotes from './AssetInfoNotes';
import { useLocation } from 'react-router-dom';
import { useEffect, useRef } from 'react';

export interface AssetInfoProps {
    asset: Butlerr.Asset;
    isPartner: boolean;
    isCoowner: boolean;
}

const AssetInfo = ({ asset, isPartner = false, isCoowner = false }: AssetInfoProps) => {
    return (
        <>
            {/* tab header */}
            <PageHeader Icon={InfoOutlined} title="Asset Info" />

            {/* tab content */}
            <AssetTabContent>
                <AssetInfoCardContent asset={asset} isPartner={isPartner} isCoowner={isCoowner} />
            </AssetTabContent>
        </>
    );
};

export const AssetInfoCardContent = ({ asset, isPartner, isCoowner }: AssetInfoProps) => {

    const { state } = useLocation<{to?: string} | null>();

    useEffect(()=> {
        if (state?.to === "Teams") {
            teamsContainerRef.current?.scrollIntoView()
        }
    }, [state?.to])

    const teamsContainerRef = useRef<HTMLDivElement>(null);

    return (
        <>
            {/* Header */}
            <AssetInfoHeader asset={asset} isPartner={isPartner} isCoowner={isCoowner} />

            {/* Media */}
            <AssetInfoMedia asset={asset} isPartner={isPartner} isCoowner={isCoowner} />

            {/* Valuation/Purchase Price */}
            <Row className="d-flex align-items-center mb-4">
                <Col>
                    <AssetInfoValuation asset={asset} />
                </Col>
            </Row>

            {/* Configuration */}
            <AssetInfoConfiguration asset={asset} isPartner={isPartner} isCoowner={isCoowner} />

            {/* Details */}
            <AssetInfoDetails asset={asset} isPartner={isPartner} isCoowner={isCoowner} />

            {/* Features */}
            <AssetInfoFeatures asset={asset} isPartner={isPartner} isCoowner={isCoowner} />

            {/* Roles */}
            <div ref={teamsContainerRef}></div>
            <AssetInfoRoles asset={asset} isPartner={isPartner} isCoowner={isCoowner} />

            {/* Remarks/Notes */}
            <AssetInfoNotes asset={asset} isPartner={isPartner} isCoowner={isCoowner} />
        </>
    )
}

interface AssetInfoEditButtonInterface {
    editing: boolean;
    isSubmitting: boolean;
    setEditing: (value: boolean) => void;
    resetForm: () => void;
}

export const AssetInfoEditButton = ({
    editing,
    isSubmitting,
    setEditing,
    resetForm,
}: AssetInfoEditButtonInterface) => {
    return (
        <div className="d-flex justify-content-end">
            {editing ? (
                <>
                    <IconButton
                        key="cancel"
                        transparent
                        title="Cancel"
                        className="me-2"
                        Icon={Close}
                        iconHtmlColor="var(--danger)"
                        disabled={isSubmitting}
                        onClick={() => {
                            setEditing(false);
                            resetForm();
                        }}
                    />
                    <IconButton
                        key="save"
                        transparent
                        title="Save"
                        Icon={Done}
                        iconHtmlColor="var(--success)"
                        disabled={isSubmitting}
                        type="submit"
                    />
                </>
            ) : (
                <IconButton
                    key="edit"
                    transparent
                    title="Edit"
                    Icon={EditTwoTone}
                    disabled={isSubmitting}
                    onClick={setEditing.bind(undefined, true)}
                />
            )}
        </div>
    );
};

export const isImage = (filename: string) => {
    let ext = filename.split('.').pop();
    switch (ext?.toLowerCase()) {
        case 'jpg':
        case 'jpeg':
        case 'gif':
        case 'bmp':
        case 'png':
        case 'webp':
            //etc
            return true;
    }
    return false;
};

export default AssetInfo;
