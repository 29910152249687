import { Butlerr } from '../types/butlerr';
import useButlerrAPI from './useButlerrAPI';

const baseRoute = '/api/conversations';

export const ConversationQueries = {
    ASSETS_UNREAD_COUNT: ['conversations', 'assets', 'unread', 'count'] as const,
    SOCIAL_UNREAD_COUNT: ['chats', 'social', 'unread', 'count'] as const,
};

export function useConversationsAssetsUnreadCount() {
    return useButlerrAPI<Butlerr.Header.Conversation[]>(
        ConversationQueries.ASSETS_UNREAD_COUNT,
        `${baseRoute}/assets/unread/count`,
        true,
        {
            staleTime: 0, // Data is always outdated
            refetchInterval: 1000 * 30, // Refetch every 30 seconds
            refetchOnMount: 'always', // Refetch whenever user comes to the page
        }
    );
}

export function useChatsSocialUnreadCount() {
    return useButlerrAPI<Butlerr.Header.Conversation[]>(
        ConversationQueries.SOCIAL_UNREAD_COUNT,
        `${baseRoute}/social/unread/count`,
        true,
        {
            staleTime: 0, // Data is always outdated
            refetchInterval: 1000 * 30, // Refetch every 30 seconds
            refetchOnMount: 'always', // Refetch whenever user comes to the page
        }
    );
}
