import { Butlerr } from '../types/butlerr';
import useButlerrAPI, { ButlerrMutationOptions, useButlerrMutation, useButlerrPaginatedQuery } from './useButlerrAPI';

interface SearchWorkProfileOptions {
    search: string;
}

export const PartnerQueries = {
    WORK_PROFILE_SEARCH: (params: SearchWorkProfileOptions) => ['work', 'profiles', 'search', params] as const,

    PARTNERS: ['work', 'profiles'] as const,

    PARTNER: (partnerId: number) => ['work', 'profile', partnerId] as const,
    PARTNER_STAFF: (partnerId: number) => ['work', 'profile', partnerId, 'staff'] as const,

    CHANNEL_EDITORS: (channelId: number) => ['work', 'channels', 'editors', channelId] as const,

    STAFF_ASSETS: ['work', 'staff', 'assets'] as const,
};

export const partnerBaseRoute = '/api/work';

export function useAllWorkProfiles(search = '', enabled = true) {
    const options = {
        search,
    };

    const searchParams = new URLSearchParams();
    for (const key in options) {
        const value = options[key as keyof typeof options];
        if (value !== undefined) {
            searchParams.set(key, String(value));
        }
    }

    let url = `/api/work/all`;

    if (search) {
        url += `?${searchParams.toString()}`;
    }

    return useButlerrAPI<Butlerr.Work.WorkProfile[]>(
        PartnerQueries.WORK_PROFILE_SEARCH(options),
        url,
        true,
        {
            cacheTime: 60 * 1000, //cache for 1 min
            enabled: enabled,
        }
    );
}

export function useWorkProfiles() {
    return useButlerrAPI<Butlerr.Work.WorkProfile[]>(
        PartnerQueries.PARTNERS,
        `${partnerBaseRoute}`,
        true,
        {
            refetchOnMount: 'always',
        }
    );
}

export function useWorkProfile(workProfileId: number, enabled = true) {
    return useButlerrAPI<Butlerr.Work.WorkProfile>(
        PartnerQueries.PARTNER(workProfileId),
        `${partnerBaseRoute}/${workProfileId}`,
        true,
        { enabled }
    );
}

export function useWorkProfileStaff(partnerId: number, enabled = true) {
    return useButlerrAPI<Butlerr.Work.WorkProfileStaff[]>(
        PartnerQueries.PARTNER_STAFF(partnerId),
        `${partnerBaseRoute}/${partnerId}/staffs`,
        true,
        { enabled, refetchOnMount: 'always' }
    )
}

export function initialisePartnerObject(partner: Butlerr.Work.WorkProfile) {
    const requiredFields = [
        'wkpf_id',
        'wkpf_name',
        'wkpf_address',
        'wkpf_country',
        'wkpf_region',
        'wkpf_city',
        'wkpf_postcode',
        'wkpf_phone',
        'wkpf_email',
        'wkpf_url',
        'wkpf_development',
        'wkpf_legal',
        'wkpf_conveyancing',
        'wkpf_management',
        'wkpf_accounting',
        'wkpf_financing',
        'wkpf_fundtransfer',
        'wkpf_projects',
        'wkpf_interior',
        'wkpf_valuation',
        'wkpf_surveyance',
        'wkpf_inspection',
        'wkpf_salesagent',
        'wkpf_advisory',
        'wkpf_insurance',
        'wkpf_homestaging',
        'wkpf_serviceregions',
        'wkpf_status',
        'wkpf_statuschange',
        'part-desc',
    ];
    const originalPartner: Butlerr.Work.WorkProfile = requiredFields.reduce(
        (accumulator, currentValue) => {
            return {
                ...accumulator,
                ...{
                    [currentValue]: partner[currentValue as keyof typeof partner],
                },
            };
        },
        {} as Butlerr.Work.WorkProfile
    );

    return originalPartner;
}

type PartnerForm = Butlerr.Work.WorkProfile;

type CreatePartnerForm = Omit<
    Butlerr.Work.WorkProfile,
    'wkpf_id' | 'wkpf_owner' | 'wkpf_status' | 'wkpf_statuschange' | 'wkpf_profile' | 'isOwner' | 'isAdmin' | 'wpst_requestedby' | 'wpst_position' | 'wpst_status' | 'owner_user_id' | 'owner_user_socialhandle' | 'owner_user_profile'
> & { wkpf_profile: File | null }

interface PartnerStaffForm extends Pick<Butlerr.Work.WorkProfileStaff, 'wpst_position' | 'wpst_depart' | 'wkpf_id' | 'user_id' | 'wpst_admin'> {
    oldUserId: number;
    editedBy: number;
}

interface EditPartnerPhoto {
    wkpf_id: number;
    image: File;
}

const PartnerMutations = {
    CREATE: ({
        ...partner
    }: CreatePartnerForm): ButlerrMutationOptions => ({
        url: `${partnerBaseRoute}`,
        method: 'POST',
        requestBody: partner,
        useFormData: true,
        queryKey: PartnerQueries.PARTNERS,
    }),

    EDIT: ({ wkpf_id, ...partner }: PartnerForm): ButlerrMutationOptions => ({
        url: `${partnerBaseRoute}/${wkpf_id}`,
        method: 'PUT',
        requestBody: partner,
        updater: [
            {
                queryKey: PartnerQueries.PARTNER(wkpf_id),
                action: (prev?: Butlerr.Work.WorkProfile) => {
                    if (prev !== undefined) {
                        prev.wkpf_name = partner.wkpf_name;
                        prev.wkpf_address = partner.wkpf_address;
                        prev.wkpf_country = partner.wkpf_country;
                        prev.wkpf_region = partner.wkpf_region;
                        prev.wkpf_city = partner.wkpf_city;
                        prev.wkpf_postcode = partner.wkpf_postcode;
                        prev.wkpf_phone = partner.wkpf_phone;
                        prev.wkpf_email = partner.wkpf_email;
                        prev.wkpf_url = partner.wkpf_url;
                        prev.wkpf_development = partner.wkpf_development;
                        prev.wkpf_legal = partner.wkpf_legal;
                        prev.wkpf_conveyancing = partner.wkpf_conveyancing;
                        prev.wkpf_management = partner.wkpf_management;
                        prev.wkpf_accounting = partner.wkpf_accounting;
                        prev.wkpf_financing = partner.wkpf_financing;
                        prev.wkpf_fundtransfer = partner.wkpf_fundtransfer;
                        prev.wkpf_projects = partner.wkpf_projects;
                        prev.wkpf_interior = partner.wkpf_interior;
                        prev.wkpf_valuation = partner.wkpf_valuation;
                        prev.wkpf_surveyance = partner.wkpf_surveyance;
                        prev.wkpf_inspection = partner.wkpf_inspection;
                        prev.wkpf_salesagent = partner.wkpf_salesagent;
                        prev.wkpf_advisory = partner.wkpf_advisory;
                        prev.wkpf_insurance = partner.wkpf_insurance;
                        prev.wkpf_homestaging = partner.wkpf_homestaging;
                        prev.wkpf_media = partner.wkpf_media;
                        prev.wkpf_training = partner.wkpf_training;
                        prev.wkpf_renovation = partner.wkpf_renovation;
                        prev.wkpf_repair = partner.wkpf_repair;
                        prev.wkpf_research = partner.wkpf_research;
                        prev.wkpf_serviceregions = partner.wkpf_serviceregions;
                        prev.wkpf_desc = partner.wkpf_desc;
                    }
                    return prev;
                },
            },
            {
                queryKey: PartnerQueries.PARTNERS,
                action: (prev?: Butlerr.Work.WorkProfile[]) =>
                    prev?.map((prevPartner) => {
                        if (prevPartner.wkpf_id === wkpf_id) {
                            prevPartner.wkpf_name = partner.wkpf_name;
                            prevPartner.wkpf_address = partner.wkpf_address;
                            prevPartner.wkpf_country = partner.wkpf_country;
                            prevPartner.wkpf_region = partner.wkpf_region;
                            prevPartner.wkpf_city = partner.wkpf_city;
                            prevPartner.wkpf_postcode = partner.wkpf_postcode;
                            prevPartner.wkpf_phone = partner.wkpf_phone;
                            prevPartner.wkpf_email = partner.wkpf_email;
                            prevPartner.wkpf_url = partner.wkpf_url;
                            prevPartner.wkpf_development = partner.wkpf_development;
                            prevPartner.wkpf_legal = partner.wkpf_legal;
                            prevPartner.wkpf_conveyancing = partner.wkpf_conveyancing;
                            prevPartner.wkpf_management = partner.wkpf_management;
                            prevPartner.wkpf_accounting = partner.wkpf_accounting;
                            prevPartner.wkpf_financing = partner.wkpf_financing;
                            prevPartner.wkpf_fundtransfer = partner.wkpf_fundtransfer;
                            prevPartner.wkpf_projects = partner.wkpf_projects;
                            prevPartner.wkpf_interior = partner.wkpf_interior;
                            prevPartner.wkpf_valuation = partner.wkpf_valuation;
                            prevPartner.wkpf_surveyance = partner.wkpf_surveyance;
                            prevPartner.wkpf_inspection = partner.wkpf_inspection;
                            prevPartner.wkpf_salesagent = partner.wkpf_salesagent;
                            prevPartner.wkpf_advisory = partner.wkpf_advisory;
                            prevPartner.wkpf_insurance = partner.wkpf_insurance;
                            prevPartner.wkpf_homestaging = partner.wkpf_homestaging;
                            prevPartner.wkpf_media = partner.wkpf_media;
                            prevPartner.wkpf_training = partner.wkpf_training;
                            prevPartner.wkpf_renovation = partner.wkpf_renovation;
                            prevPartner.wkpf_repair = partner.wkpf_repair;
                            prevPartner.wkpf_research = partner.wkpf_research;
                            prevPartner.wkpf_serviceregions = partner.wkpf_serviceregions;
                            prevPartner.wkpf_desc = partner.wkpf_desc;
                            return prevPartner;
                        } else {
                            return prevPartner;
                        }
                    }),
            },
        ],
    }),

    EDIT_PHOTO: ({ wkpf_id, image }: EditPartnerPhoto): ButlerrMutationOptions => ({
        url: `${partnerBaseRoute}/${wkpf_id}/profile`,
        method: 'PUT',
        requestBody: {
            wkpf_profile: image,
        },
        queryKey: PartnerQueries.PARTNER(wkpf_id),
        useFormData: true,
    }),

    DELETE: ({ wkpf_id }: Pick<Butlerr.Work.WorkProfile, "wkpf_id">): ButlerrMutationOptions => ({
        url: `${partnerBaseRoute}/${wkpf_id}`,
        method: 'DELETE',
        queryKey: PartnerQueries.PARTNERS,
    }),

    CREATE_STAFF: ({
        wkpf_id,
        ...PartnerStaff
    }: Omit<PartnerStaffForm, 'oldUserId' | 'editedBy'>): ButlerrMutationOptions => ({
        url: `${partnerBaseRoute}/${wkpf_id}/staffs`,
        method: 'POST',
        requestBody: PartnerStaff,
        queryKey: PartnerQueries.PARTNER_STAFF(wkpf_id),
    }),

    EDIT_STAFF: ({
        wkpf_id,
        editedBy,
        ...PartnerStaff
    }: PartnerStaffForm): ButlerrMutationOptions => ({
        url: `${partnerBaseRoute}/${wkpf_id}/staffs`,
        method: 'PUT',
        requestBody: PartnerStaff,
        queryKey: PartnerQueries.PARTNER_STAFF(wkpf_id),
        refetchData: true,
        updater: [
            {
                queryKey: PartnerQueries.CHANNEL_EDITORS(-1).slice(0, -1),
                partialMatch: true,
                action: (prev: Butlerr.Social.ChannelEditors[]) => {
                    if (PartnerStaff.oldUserId === PartnerStaff.user_id) return prev;
                    return prev.map((e) => e.user_id !== PartnerStaff.oldUserId ? e : ({
                        ...e, user_id: PartnerStaff.user_id
                    }));
                },
            },
        ],
    }),

    DELETE_STAFF: ({
        wkpf_id,
        user_id,
        newStaffId,
        newStaffProfile = '',
        newStaffHandle = ''
    }: Pick<Butlerr.Work.WorkProfileStaff, 'wkpf_id'> & {
        user_id: number;
        newStaffId: number;
        newStaffProfile?: string;
        newStaffHandle?: string;
    }): ButlerrMutationOptions => ({
        url: `${partnerBaseRoute}/${wkpf_id}/staffs/${user_id}?newStaffId=${newStaffId}`,
        method: 'DELETE',
        updater: [
            {
                queryKey: PartnerQueries.PARTNER_STAFF(wkpf_id),
                action: (prev: Butlerr.Work.WorkProfileStaff[]) => prev.filter(s => s.user_id !== user_id)
            },
            {
                queryKey: PartnerQueries.CHANNEL_EDITORS(-1).slice(0, -1),
                partialMatch: true,
                action: (prev: Butlerr.Social.ChannelEditors[]) => {
                    //console.log(prev) is chan id + user id + user socialhandle (array of objects)
                    return prev.filter((e) => e.user_id !== user_id);
                },
            },
            {
                queryKey: PartnerQueries.STAFF_ASSETS,
                action: (prev: Butlerr.staffAsset[]) => {
                    return prev.forEach((asset) => asset.staff.filter((staff) => staff.staff_id !== user_id))
                }
            }
        ],
    }),

    ACCEPT_STAFF: ({
        wkpf_id, user_id
    }: Pick<Butlerr.Work.WorkProfileStaff, 'wkpf_id' | 'user_id'>) => ({
        url: `${partnerBaseRoute}/${wkpf_id}/staffs/${user_id}`,
        method: 'POST',
        updater: {
            queryKey: PartnerQueries.PARTNER_STAFF(wkpf_id),
            action: (prev: Butlerr.Work.WorkProfileStaff[]) => prev.map(s => s.user_id !== user_id ? s : ({
                ...s, wpst_status: 1
            }))
        }
    }),

    REQUEST_JOIN: ({ wkpf_id, ...body }: Pick<Butlerr.Work.WorkProfileStaff, "wkpf_id" | "wpst_position" | "wpst_depart">): ButlerrMutationOptions => ({
        url: `${partnerBaseRoute}/${wkpf_id}/staffs/request`,
        method: 'POST',
        requestBody: body,
        queryKey: PartnerQueries.PARTNERS,
    }),

    CANCEL_REQUEST_JOIN: ({ wkpf_id }: Pick<Butlerr.Work.WorkProfileStaff, "wkpf_id">): ButlerrMutationOptions => ({
        url: `${partnerBaseRoute}/${wkpf_id}/staffs/request`,
        method: 'DELETE',
        queryKey: PartnerQueries.PARTNERS,
    }),

    ACCEPT_REQUEST: ({ wkpf_id }: Pick<Butlerr.Work.WorkProfileStaff, "wkpf_id">): ButlerrMutationOptions => ({
        url: `${partnerBaseRoute}/${wkpf_id}/staffs/invitation`,
        method: 'PUT',
        updater: [
            {
                queryKey: PartnerQueries.PARTNERS,
                optimistic: true,
                action: (prev: Butlerr.Work.WorkProfile[]) => prev.map(wp => {
                    if (wp.wkpf_id !== wkpf_id) return wp;
                    return {
                        ...wp,
                        wpst_status: 1,
                    }
                })
            },
            {
                queryKey: PartnerQueries.PARTNER(wkpf_id),
                optimistic: true,
                action: (prev: Butlerr.Work.WorkProfile) => ({
                    ...prev,
                    wpst_status: 1,
                })
            }
        ]
    }),

    REJECT_REQUEST: ({ wkpf_id }: Pick<Butlerr.Work.WorkProfileStaff, "wkpf_id">): ButlerrMutationOptions => ({
        url: `${partnerBaseRoute}/${wkpf_id}/staffs/invitation`,
        method: 'DELETE',
        queryKey: PartnerQueries.PARTNERS,
    }),
};

export function usePartnerMutations<Key extends keyof typeof PartnerMutations>(key: Key) {
    //You won't get type suggestions in the mutate function without this assertion
    const mutationFn = PartnerMutations[key] as (
        params: Parameters<typeof PartnerMutations[Key]>[0]
    ) => ButlerrMutationOptions;
    return useButlerrMutation(mutationFn);
}

export interface FetchStaffAssetsOptions {
    All?: boolean;
    Client?: boolean;
    Address?: boolean;
    Staff?: boolean;
    limit?: number;
    searchVal?: string;
}

export function useStaffAssets(wkpf_id: number, params: FetchStaffAssetsOptions = {}, enabled = true) {
    const { limit = 20, ...rest } = params;

    const options = {
        limit, ...rest
    }

    const searchParams = new URLSearchParams();
    for (const key in options) {
        const value = options[key as keyof typeof options]
        if (value !== undefined) {
            searchParams.set(key, String(value))
        }
    }

    return useButlerrPaginatedQuery<Butlerr.staffAsset[]>(
        PartnerQueries.STAFF_ASSETS,
        `${partnerBaseRoute}/owner/${wkpf_id}/staffasset?${searchParams.toString()}`,
        true,
        {
            refetchOnMount: 'always',
        }
    );
}