import { Card, Row, Col, Dropdown } from 'react-bootstrap';
import { Butlerr } from '../../../types/butlerr';
import DestructiveModal from '../../utils/DestructiveModal';
import styles from '../asset.module.css';
import { AssetRoleTypeKey, ButlerrAssetRoleType } from '../../../types/butlerr-enums';
import LineClampText from '../../utils/LineClampText';
import { useAssetRoleMutations, useAssetRoles } from '../../../services/assetrole.service';
import UserAvatar from '../../social/UserAvatar';
import { AddRounded, MoreVert } from '@mui/icons-material';
import IconButton from '../../utils/IconButton';
import { useCallback, useEffect, useState } from 'react';
import AssetInfoRolesModal from './AssetInfoRolesModal';
import { Link } from 'react-router-dom';
import { SectionHeader } from '../../utils/SectionHeader';
import { createSocialRoute, SocialRoutes } from '../../social/SocialHome';
import { AssetInfoProps } from './AssetInfo';

const AssetInfoRole = ({ asset, isPartner = false }: AssetInfoProps) => {   //mini AssetLeases.tsx

    const { data: roles = [], isLoading } = useAssetRoles(asset.asst_id);

    // This is needed for role CRUD.
    useEffect(() => {
        setSelectedRole((prev) => {
            //if previously selected, select new copy of the lease from data
            if (prev) {
                return roles.find((l) => l.asro_id === prev.asro_id);
            }
            return prev;
        });
    }, [roles]);

    //modal states
    const [selectedRole, setSelectedRole] = useState<Butlerr.Asset.Role>();
    const [showAddModal, setShowAddModal] = useState(false);

    const onModalOpen = (role?: Butlerr.Asset.Role) => {
        setSelectedRole(role);
        setShowAddModal(true);
    };

    const onModalClose = () => {
        setShowAddModal(false);
    };

    return (
        <div className="mb-4">
            <SectionHeader
                title="Team"
            />
            <div>
                <Row className="gy-3 gy-lg-4" xs="1" lg="3">
                    {
                        isLoading === true ? (
                            <>
                                <Col>
                                    <Card
                                        className={'border-0 shadow-sm skeleton-box w-100 '}
                                        style={{ height: '76px' }}
                                    >
                                    </Card>
                                </Col>
                                <Col>
                                    <Card
                                        className={'border-0 shadow-sm skeleton-box w-100 '}
                                        style={{ height: '76px' }}
                                    >
                                    </Card>
                                </Col>
                            </>
                        ) : (
                            <>
                                {roles.length !== 0 ? (
                                    <>
                                        {roles.map((role) => (
                                            <Col key={role.asro_id}>
                                                <CardItem
                                                    role={role}
                                                    isLoading={isLoading}
                                                    onModalOpen={onModalOpen}
                                                    assetId={asset.asst_id}
                                                    isPartner={isPartner}
                                                />

                                            </Col>
                                        ))}
                                    </>
                                ) : null}
                            </>
                        )
                    }
                    {
                        isPartner === false && asset.status !== 'AR' ? (
                            <Col>
                                <Card className={'border-0 shadow-sm cursor-pointer ' + styles.assetsListCard} style={{ backgroundColor: 'whitesmoke' }} onClick={onModalOpen.bind(undefined, undefined)}>
                                    <Card.Body className="p-2 d-flex flex-row cursor-pointer mx-auto" as={Row}>
                                        <AddRounded
                                            className={styles.assetsListCardImage + "h-auto mx-auto"}
                                            style={{ width: '70%', height: 60, alignSelf: 'center' }}
                                        />
                                    </Card.Body>
                                </Card>
                            </Col>
                        ) : undefined
                    }
                </Row>
            </div>
            <AssetInfoRolesModal
                show={showAddModal}
                onModalClose={onModalClose}
                assetId={asset.asst_id}
                initialValue={selectedRole}
                asset={asset}
            />
        </div >
    );
};

interface CardItemInterface {
    role: Butlerr.Asset.Role;
    isLoading: boolean;
    onModalOpen: (role?: Butlerr.Asset.Role | undefined) => void;
    assetId: number;
    isPartner?: boolean;
}

export const CardItem = ({ //AssetLeasesTable
    role,
    onModalOpen,
    assetId,
    isPartner = false,
}: CardItemInterface) => {

    const { mutateAsync: deleteRole } = useAssetRoleMutations('DELETE', assetId);

    const editRoleForm = useCallback(
        (role: Butlerr.Asset.Role) => {
            onModalOpen(role);
        },
        [onModalOpen]
    );

    // delete modal
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedRoleId, setSelectedRoleId] = useState<number>();

    const onDelete = (roleId: number) => {
        setSelectedRoleId(roleId);
        setShowDeleteModal(true);
    };

    const onDeleteClose = () => {
        setShowDeleteModal(false);
    };

    const onDeleteConfirm = async () => {
        await deleteRole(
            { asst_id: assetId, asro_id: selectedRoleId ?? -1 }
        );
    };

    return (
        <>
            <Card
                className={'border-1 shadow-sm ' + styles.roleCard}
            >
                <Card.Body className="p-2 d-flex justify-content-center align-items-center flex-row">
                    <Col xs="auto" className="me-4">
                        <Link
                            to={createSocialRoute(SocialRoutes.USER_PROFILE, {
                                id: role.user_id,
                            })}
                            onClick={(ev) => ev.stopPropagation()}
                        >
                            <UserAvatar
                                user={{
                                    user_id: role.user_id,
                                    user_socialhandle: role.user_socialhandle,
                                    user_profile: role.user_profile
                                }}
                                width={50}
                                height={50}
                                className="cursor-pointer shadow"
                            />
                        </Link>
                    </Col>
                    <Col>
                        <div className="d-flex justify-content-between align-items-center">
                            <span className="text-left fw-bold">
                                {ButlerrAssetRoleType[role.asro_type]}
                            </span>
                            
                            {
                                isPartner === false ? (
                                    <Dropdown className={styles.dropdown}>
                                        <Dropdown.Toggle as="span" bsPrefix=" ">
                                            <IconButton
                                                transparent
                                                border={false}
                                                Icon={MoreVert}
                                                iconHtmlColor="var(--bs-gray)"
                                            />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className="p-0">
                                            <Dropdown.Item onClick={() => editRoleForm(role)}>Edit</Dropdown.Item>
                                            <Dropdown.Item onClick={() => onDelete(role.asro_id)}>Delete</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                ) : undefined
                            }
                        </div>
                        
                        <LineClampText
                            maxLines={1}
                            title={`${role.user_socialhandle}`}
                            className={'mb-0 small fw-semibold'}
                        >
                            {role.user_socialhandle}
                        </LineClampText>
                        <LineClampText
                            maxLines={1}
                            className={'mb-0 small '}
                        >
                            {/* Work profile name will not be null in this case. But just to be safe. */}
                            {role.wkpf_name ?? 'No work profile name available.'}
                        </LineClampText>
                    </Col>
                </Card.Body>
            </Card>
            <DestructiveModal
                show={showDeleteModal}
                onClose={onDeleteClose}
                onConfirm={onDeleteConfirm}
                title={`Remove role?`}
                description={`Please confirm the removal of ${role.user_socialhandle}'s role as ${ButlerrAssetRoleType[role.asro_type as AssetRoleTypeKey]}.`}
                confirmBtnText="Confirm"
            />
        </>
    );
};



export default AssetInfoRole;
