import { Butlerr } from '../types/butlerr';
import { assetBaseRoute, AssetQueries } from './asset.service';
import useButlerrAPI, { ButlerrMutationOptions, useButlerrMutation } from './useButlerrAPI';

export const RoleQueries = {
    ROLES: (assetId: number) => ['asset', assetId, 'roles'] as const,
};

export function useAssetRoles(assetId: number) {
    return useButlerrAPI<Butlerr.Asset.Role[]>(
        RoleQueries.ROLES(assetId),
        `${assetBaseRoute}/${assetId}/roles/`
    );
}

type CreateRoleForm = Omit<Butlerr.Asset.Role, 'asro_id' | 'asro_appointed' | 'user_socialhandle' | 'user_profile' | 'wkpf_name'>;
type EditRoleForm = Omit<Butlerr.Asset.Role, 'asro_appointed'>;
type DeleteRoleForm = Pick<Butlerr.Asset.Role, 'asro_id' | 'asst_id'>;

const RoleMutations = {
    CREATE: ({
        asst_id,
        ...role
    }: CreateRoleForm): ButlerrMutationOptions<Butlerr.Asset.Role[]> => ({
        url: `${assetBaseRoute}/${asst_id}/roles/`,
        method: 'POST',
        requestBody: role,
    }),

    EDIT: ({
        asst_id,
        asro_id,
        user_socialhandle,
        user_profile,
        wkpf_name,
        ...role
    }: EditRoleForm): ButlerrMutationOptions<Butlerr.Asset.Role[]> => ({
        url: `${assetBaseRoute}/${asst_id}/roles/${asro_id}`,
        method: 'PUT',
        requestBody: role,
        updater: {
            action: (prev) => prev?.map((r) => {
                if (r.asro_id !== asro_id) return r;
                return {
                    asst_id: r.asst_id,
                    asro_appointed: r.asro_appointed,
                    asro_desc: role.asro_desc,
                    asro_id: r.asro_id,
                    asro_type: role.asro_type,
                    user_id: role.user_id,
                    user_socialhandle,
                    user_profile,
                    wkpf_name,
                }
            }) ?? [],
            optimistic: true
        }
    }),

    DELETE: ({
        asst_id,
        asro_id,
    }: DeleteRoleForm): ButlerrMutationOptions<Butlerr.Asset.Role[]> => ({
        url: `${assetBaseRoute}/${asst_id}/roles/${asro_id}`,
        method: 'DELETE',
        updater: {
            action: (prev) => prev?.filter((r) => r.asro_id !== asro_id) ?? [],
            optimistic: true,
        },
    }),

    DELETEOWN: ({
        asst_id,
        asro_id,
    }: DeleteRoleForm): ButlerrMutationOptions <Butlerr.ClientAsset[]>=> ({
        url: `${assetBaseRoute}/${asst_id}/roles/${asro_id}/work/partner`,
        method: 'DELETE',
        updater: {
            queryKey: AssetQueries.PARTNERASSETS,
            action: (prev) => {
                const assetIndex = prev.findIndex((a) => a.asst_id === asst_id);

                if (assetIndex !== -1) {
                    prev[assetIndex].roles = prev[assetIndex].roles.filter((r) => r.asro_id !== asro_id);
                }

                if (prev[assetIndex].roles.length > 0) {
                    return prev
                } else {
                    return prev.filter((r, index) => index !== assetIndex)
                }
            },
            optimistic: true,
        },
    }),
};

export function useAssetRoleMutations<Key extends keyof typeof RoleMutations>(
    key: Key,
    assetId: number
) {
    //You won't get type suggestions in the mutate function without this assertion
    const mutationFn = RoleMutations[key] as (
        params: Parameters<typeof RoleMutations[Key]>[0]
    ) => ButlerrMutationOptions;
    return useButlerrMutation(mutationFn, RoleQueries.ROLES(assetId));
}