import { useEffect, useState } from 'react';
import { AddCircle } from '@mui/icons-material';

import { ReactComponent as AssetLoanIcon } from '../../../assets/icons/asset-loan.svg';
import { Butlerr } from '../../../types/butlerr';
import ErrorPage from '../../utils/ErrorPage';
import PageHeader from '../../utils/PageHeader';
import { AssetTabContent } from '../Asset';
import AssetLoansTable from './AssetLoansTable';
import AssetLoansKPI from './AssetLoansKPI';
import AssetLoansModal from './AssetLoansModal';
import { useLoans } from '../../../services/assetloan.service';
import IconButton from '../../utils/IconButton';

interface AssetLoansProps {
    asset: Butlerr.Asset;
}

const AssetLoans = ({ asset }: AssetLoansProps) => {
    const assetId = asset.asst_id;

    const { data: loans = [], isLoading, isError, error } = useLoans(assetId);

    // This is needed for loan adjustment CRUD.
    useEffect(() => {
        // if loans get updated, update the selected loan as well
        setSelectedLoan((prev) => {
            if (prev) {
                return loans.find((l) => l.loan_id === prev.loan_id);
            }
            return prev;
        });
    }, [loans]);

    //modal states
    const [selectedLoan, setSelectedLoan] = useState<Butlerr.Asset.Loan>();
    const [showModal, setShowModal] = useState(false);

    const onModalOpen = (loan?: Butlerr.Asset.Loan) => {
        setSelectedLoan(loan);
        setShowModal(true);
    };
    const onModalClose = () => {
        setShowModal(false);
    };

    return (
        <>
            {/* tab header */}
            <PageHeader Icon={AssetLoanIcon} title="Loans" />

            <AssetTabContent>
                {isError ? (
                    <ErrorPage status={error?.status} message={error?.message} />
                ) : (
                    <>
                        <AssetLoansKPI
                            loans={loans.filter((l) => (l.loan_termdate ? false : true))}
                            isLoading={isLoading}
                            asset={asset}
                        />

                        {asset.status !== 'AR' && (
                            <div className="mb-3 d-flex justify-content-end">
                                <IconButton
                                    Icon={AddCircle}
                                    title="Add A Loan"
                                    onClick={() => onModalOpen()}
                                />
                            </div>
                        )}

                        <AssetLoansTable
                            assetId={assetId}
                            loans={loans}
                            isLoading={isLoading}
                            onModalOpen={onModalOpen}
                        />
                    </>
                )}
            </AssetTabContent>

            <AssetLoansModal
                show={showModal}
                onModalClose={onModalClose}
                assetId={assetId}
                initialValue={selectedLoan}
                asset={asset}
            />
        </>
    );
};

export default AssetLoans;
