import loading from '../assets/loading.svg';

const Loading = () => (
  <div className="d-flex flex-column justify-content-center align-items-center h-100">
    <img alt="logo" src="/logo_text.png" width={100} className="position-absolute" />
    <img src={loading} alt="loading" width={250} height={250} />
  </div>
);

export default Loading;
