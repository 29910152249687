import {
    Navbar,
    Container,
    Nav,
    NavLink,
    Dropdown,
    Button,
    OverlayTrigger,
    Tooltip,
} from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import { NavLink as RouterNavLink, useLocation } from 'react-router-dom';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
import HomeWork from '@mui/icons-material/HomeWork';
import HomeWorkOutlined from '@mui/icons-material/HomeWorkOutlined';
import DateRangeRounded from '@mui/icons-material/DateRangeRounded';
import StoreRounded from '@mui/icons-material/StoreRounded';

import { useEffect, useState } from 'react';

import { useButlerrUser } from '../../services/user.service';
import UserAvatar from '../social/UserAvatar';
import { NotificationsButton } from './Notifications';
// import { ConversationsButton } from './header/Conversations';
import OnboardingModal from './onboarding/OnboardingModal';
import useModal from '../../hooks/useModal';
import {
    BusinessCenterOutlined,
    ConnectWithoutContactOutlined,
    ConnectWithoutContactRounded,
    LogoutRounded,
    SettingsOutlined,
    TopicOutlined,
} from '@mui/icons-material';
import { MessagesButton } from './conversations/ConversationChatNotifications';

const Header = () => {
    // auth0
    const { isAuthenticated, user, loginWithRedirect } = useAuth0();

    //update contact for hubspot
    // useEffect(() => {
    //     if (isAuthenticated && user?.email) {
    //         const _hsq = ((window as any)._hsq = (window as any)._hsq || []);
    //         _hsq.push([
    //             'identify',
    //             {
    //                 email: user.email,
    //             },
    //         ]);
    //     }
    // }, [isAuthenticated, user]);

    // react router dom
    let location = useLocation();
    const currentUrl = '/' + location.pathname.split('/')[1];

    // nav items
    const navItems = [
        {
            to: '/home',
            Icon:
                currentUrl === '/home'
                    ? ConnectWithoutContactRounded
                    : ConnectWithoutContactOutlined,
            name: 'Home',
        },
        {
            to: '/assets',
            Icon: currentUrl === '/assets' ? HomeWork : HomeWorkOutlined,
            name: 'Manage',
        },
    ];

    const disabledNavItems = [
        {
            Icon: QueryStatsRoundedIcon,
            name: 'Plan',
        },
        {
            Icon: DateRangeRounded,
            name: 'Events',
        },
        {
            Icon: StoreRounded,
            name: 'Marketplace',
        },
    ];

    const { data: dbUser, isLoading: isUserLoading, error } = useButlerrUser();
    const [modal, openModal] = useModal(OnboardingModal, {
        email: user?.email ?? '',
    });

    useEffect(() => {
        if (isAuthenticated === true && dbUser === undefined && error?.status === 404) {
            openModal();
        }
    }, [dbUser, isUserLoading, error, isAuthenticated, openModal]);
    // for testing
    // useEffect(() => {
    //     if (isAuthenticated === true && dbUser !== undefined) {
    //         openModal();
    //     }
    // }, [dbUser, isUserLoading, error, isAuthenticated, openModal]);

    //nav bar props
    const [ expanded, setExpanded ] = useState(false);
    
    const hideNavbar = () => {
        setTimeout(() => {
            setExpanded(false)
        }, 50)
    }

    return (
        <>
            <Navbar
                style={{ height: 'var(--navbar-height)' }}
                expand="lg"
                bg="white"
                variant="light"
                fixed="top"
                expanded={expanded}
                onToggle={(v) => setExpanded(v)}
            >
                <Container fluid className="navbar-wrapper h-100 bg-white">
                    <Navbar.Brand className="ms-3 ms-lg-4 h-100 p-0">
                        <NavLink
                            as={RouterNavLink}
                            to="/"
                            className="p-0 h-100 d-flex align-items-center"
                        >
                            <img
                                alt="logo brand"
                                src="/logo192.png"
                                className="logo-name flex-shrink-0 user-select-none"
                                height={40}
                                width={40}
                                draggable={false}
                            />
                            {/* <img
                                alt="logo text"
                                src="/logo_text.png"
                                className="logo-name flex-shrink-0 user-select-none"
                                height={30}
                                draggable={false}
                            /> */}
                        </NavLink>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" className="me-2" />
                    <Navbar.Collapse id="responsive-navbar-nav" className="bg-white px-2 pe-lg-3">
                        <div className="d-flex flex-column flex-lg-row flex-grow-1">
                            <Nav className="me-lg-auto">
                                {isAuthenticated &&
                                    navItems.map(({ to, Icon, name }, idx) => (
                                        <NavLink
                                            key={idx}
                                            as={RouterNavLink}
                                            to={to}
                                            activeClassName="active"
                                            className="mx-1 p-2"
                                            style={{
                                                color:
                                                    currentUrl === to
                                                        ? 'var(--primary)'
                                                        : 'var(--bs-gray-600)',
                                            }}
                                            onClick={hideNavbar}
                                        >
                                            <div className="d-flex align-items-center text-uppercase">
                                                <Icon fontSize="small" />
                                                {name && <span className="ms-1">{name}</span>}
                                            </div>
                                        </NavLink>
                                    ))}
                                {isAuthenticated &&
                                    disabledNavItems.map(({ Icon, name }, idx) => (
                                        <OverlayTrigger
                                            key={idx}
                                            overlay={<Tooltip>Coming soon...</Tooltip>}
                                            placement="bottom"
                                        >
                                            {({ ref, ...triggerHandler }) => (
                                                <div {...triggerHandler} ref={ref}>
                                                    <NavLink
                                                        className="mx-1 p-2"
                                                        style={{
                                                            pointerEvents: 'none',
                                                            opacity: '0.5',
                                                        }}
                                                    >
                                                        <div className="d-flex align-items-center text-uppercase">
                                                            <Icon fontSize="small" />
                                                            {name && (
                                                                <span className="ms-1">{name}</span>
                                                            )}
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            )}
                                        </OverlayTrigger>
                                    ))}
                            </Nav>
                            <Nav className="ms-lg-auto">
                                {isAuthenticated ? (
                                    <>
                                        <MessagesButton />
                                        <NotificationsButton />
                                        <div className="d-none d-lg-block rounded-pill border-end my-2" />
                                        <UserDropdown onSelect={hideNavbar} />
                                    </>
                                ) : (
                                    <Button onClick={() => loginWithRedirect()}>Login</Button>
                                )}
                            </Nav>
                        </div>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            {modal}
        </>
    );
};

export default Header;

const UserDropdown = ({ onSelect } : { onSelect: () => void }) => {
    //states
    const { data: dbUser } = useButlerrUser();
    const { logout } = useAuth0();

    // on logout click
    const logoutUser = () => {
        logout({ returnTo: window.location.origin });
    };

    const links = [
        ['Settings', SettingsOutlined, '/profile'],
        ['Documents', TopicOutlined, '/documents'],
        ['Work', BusinessCenterOutlined, '/work'],
    ] as const;

    return (
        <Dropdown align="end" className="mx-2">
            <Dropdown.Toggle as={NavLink} className="d-flex align-items-center p-2">
                <UserAvatar user={dbUser} width={25} height={25} className="shadow-none" />
                <span className="ms-1">{dbUser?.user_socialhandle || dbUser?.user_email}</span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {links.map(([label, Icon, url], idx) => (
                    <Dropdown.Item
                        key={idx}
                        as={RouterNavLink}
                        to={url}
                        className="d-flex align-items-center nav-link py-2"
                        onClick={onSelect}
                    >
                        <Icon fontSize="small" className="me-2" />
                        {label}
                    </Dropdown.Item>
                ))}
                <Dropdown.Divider />
                <Dropdown.Item onClick={logoutUser} className="py-2 nav-link">
                    <LogoutRounded fontSize="small" className="me-2" />
                    Logout
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};
