import { useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Form, Formik, FormikHelpers } from 'formik';
import * as yup from 'yup';
import { Image } from 'react-bootstrap';
import styles from '../../assets/asset.module.css';
import partnerStyles from '../partner.module.css';
import socialStyles from '../../social/social.module.css';
import { Butlerr } from '../../../types/butlerr';
import {
    initialisePartnerObject,
    usePartnerMutations,
} from '../../../services/partner.service';
import {
    BootstrapCheckbox,
    BootstrapInput,
    BootstrapInputPhone,
} from '../../utils/FormikBootstrapInputs';
import RouterPrompt from '../../utils/RouterPrompt';
import { CountryDropdown, CountryRegionData, RegionDropdown } from 'react-country-region-selector';
import IconButton from '../../utils/IconButton';
import {
    AddBusinessOutlined,
    ArchitectureOutlined,
    CalculateOutlined,
    ChairOutlined,
    EditOutlined,
    FileOpenOutlined,
    HealthAndSafetyOutlined,
    HouseOutlined,
    Language,
    MapsHomeWorkOutlined,
    LocalPoliceOutlined,
    MailOutlined,
    ManageAccountsOutlined,
    MonetizationOnOutlined,
    PhoneOutlined,
    PhotoRounded,
    PolicyOutlined,
    SupportAgentOutlined,
    SvgIconComponent,
    SwapHorizOutlined,
    AutoStoriesOutlined,
    CarpenterOutlined,
    RateReviewOutlined,
    EngineeringOutlined,
    SupervisedUserCircleOutlined,
    BedroomParentOutlined,
    QueryStatsOutlined,
    Close,
    Done,
} from '@mui/icons-material';
import PartnerAvatar from './PartnerAvatar';
import { getFileWithUrl } from '../../assets/conversations/AssetConversationsContent';
import { convertBooleanToBinary, extractFileSize } from '../../utils/HelperFunctions';
import { isUserAdmin } from './AllPartnersPage';

interface PartnerEditButtonInterface {
    editing: boolean;
    isSubmitting: boolean;
    setEditing: (value: boolean) => void;
    resetForm: () => void;
}

export const PartnerEditButton = ({
    editing,
    isSubmitting,
    setEditing,
    resetForm,
}: PartnerEditButtonInterface) => {
    return (
        <div className="d-flex justify-content-end">
            {editing ? (
                <>
                    <IconButton
                        key="cancel"
                        transparent
                        Icon={Close}
                        disabled={isSubmitting}
                        iconHtmlColor="var(--danger)"
                        className="me-2"
                        onClick={() => {
                            setEditing(false);
                            resetForm();
                        }}
                    />
                    <IconButton
                        key="save"
                        transparent
                        Icon={Done}
                        iconHtmlColor="var(--success)"
                        disabled={isSubmitting}
                        type="submit"
                    />
                </>
            ) : (
                <IconButton
                    key="edit"
                    transparent
                    Icon={EditOutlined}
                    disabled={isSubmitting}
                    onClick={setEditing.bind(undefined, true)}
                    title="Edit"
                />
            )}
        </div>
    );
};

interface FormValues {
    wkpf_name: string;
    wkpf_address: string | null;
    wkpf_country: string | null;
    wkpf_region: string | null;
    wkpf_city: string | null;
    wkpf_postcode: string | null;
    wkpf_phone: string | null;
    wkpf_email: string | null;
    wkpf_url: string | null;
    wkpf_serviceregions: string | null;
    wkpf_desc: string | null;
    wkpf_development?: boolean | null;
    wkpf_legal?: boolean | null;
    wkpf_conveyancing?: boolean | null;
    wkpf_management?: boolean | null;
    wkpf_accounting?: boolean | null;
    wkpf_financing?: boolean | null;
    wkpf_fundtransfer?: boolean | null;
    wkpf_projects?: boolean | null;
    wkpf_interior?: boolean | null;
    wkpf_valuation?: boolean | null;
    wkpf_surveyance?: boolean | null;
    wkpf_inspection?: boolean | null;
    wkpf_salesagent?: boolean | null;
    wkpf_advisory?: boolean | null;
    wkpf_insurance?: boolean | null;
    wkpf_homestaging?: boolean | null;
    wkpf_media?: boolean | null;
    wkpf_training?: boolean | null;
    wkpf_renovation?: boolean | null;
    wkpf_repair?: boolean | null;
    wkpf_research?: boolean | null;
}

interface PartnerDetailsInterface {
    partner: Butlerr.Work.WorkProfile;
}

const PartnerDetails = ({ partner }: PartnerDetailsInterface) => {
    const { mutate: editPartner } = usePartnerMutations('EDIT');

    const isAdmin = isUserAdmin(partner);
    // form state
    const [editing, setEditing] = useState(false);

    // Formik props
    const initialiseInitialValues = (partner: Butlerr.Work.WorkProfile) => {
        return {
            wkpf_name: partner.wkpf_name,
            wkpf_address: partner.wkpf_address ?? undefined,
            wkpf_country: partner.wkpf_country ?? undefined,
            wkpf_region: partner.wkpf_region ?? undefined,
            wkpf_city: partner.wkpf_city ?? undefined,
            wkpf_postcode: partner.wkpf_postcode ?? undefined,
            wkpf_phone: partner.wkpf_phone ?? undefined,
            wkpf_email: partner.wkpf_email ?? undefined,
            wkpf_url: partner.wkpf_url ?? undefined,
            wkpf_serviceregions: partner.wkpf_serviceregions ?? '',
            wkpf_desc: partner.wkpf_desc ?? '',
            wkpf_development: Boolean(partner.wkpf_development),
            wkpf_legal: Boolean(partner.wkpf_legal),
            wkpf_conveyancing: Boolean(partner.wkpf_conveyancing),
            wkpf_management: Boolean(partner.wkpf_management),
            wkpf_accounting: Boolean(partner.wkpf_accounting),
            wkpf_financing: Boolean(partner.wkpf_financing),
            wkpf_fundtransfer: Boolean(partner.wkpf_fundtransfer),
            wkpf_projects: Boolean(partner.wkpf_projects),
            wkpf_interior: Boolean(partner.wkpf_interior),
            wkpf_valuation: Boolean(partner.wkpf_valuation),
            wkpf_surveyance: Boolean(partner.wkpf_surveyance),
            wkpf_inspection: Boolean(partner.wkpf_inspection),
            wkpf_salesagent: Boolean(partner.wkpf_salesagent),
            wkpf_advisory: Boolean(partner.wkpf_advisory),
            wkpf_insurance: Boolean(partner.wkpf_insurance),
            wkpf_homestaging: Boolean(partner.wkpf_homestaging),
            wkpf_media: Boolean(partner.wkpf_media),
            wkpf_training: Boolean(partner.wkpf_training),
            wkpf_renovation: Boolean(partner.wkpf_renovation),
            wkpf_repair: Boolean(partner.wkpf_repair),
            wkpf_research: Boolean(partner.wkpf_research),
        } as FormValues;
    };

    let initialValues: FormValues = initialiseInitialValues(partner);

    const validationSchema = yup.object({
        wkpf_name: yup.string().max(80).required(),
        wkpf_address: yup.string().max(80).default(null).nullable(),
        wkpf_country: yup.string().max(2).default(null).nullable(),
        wkpf_region: yup.string().max(30).default(null).nullable(),
        wkpf_city: yup.string().max(30).default(null).nullable(),
        wkpf_postcode: yup.string().max(8).default(null).nullable(),
        wkpf_phone: yup.string().max(15).default(null).nullable(),
        wkpf_email: yup.string().email('invalid email').default(null).max(80).nullable(),
        wkpf_url: yup.string().url('invalid url').max(60).default(null).nullable(),
        wkpf_serviceregions: yup.string().max(100).default(null).nullable(),
        wkpf_desc: yup.string().max(160).default(null).nullable(),
        wkpf_development: yup.boolean().nullable(),
        wkpf_legal: yup.boolean().nullable(),
        wkpf_conveyancing: yup.boolean().nullable(),
        wkpf_management: yup.boolean().nullable(),
        wkpf_accounting: yup.boolean().nullable(),
        wkpf_financing: yup.boolean().nullable(),
        wkpf_fundtransfer: yup.boolean().nullable(),
        wkpf_projects: yup.boolean().nullable(),
        wkpf_interior: yup.boolean().nullable(),
        wkpf_valuation: yup.boolean().nullable(),
        wkpf_surveyance: yup.boolean().nullable(),
        wkpf_inspection: yup.boolean().nullable(),
        wkpf_salesagent: yup.boolean().nullable(),
        wkpf_advisory: yup.boolean().nullable(),
        wkpf_insurance: yup.boolean().nullable(),
        wkpf_homestaging: yup.boolean().nullable(),
        wkpf_media: yup.boolean().nullable(),
        wkpf_training: yup.boolean().nullable(),
        wkpf_renovation: yup.boolean().nullable(),
        wkpf_repair: yup.boolean().nullable(),
        wkpf_research: yup.boolean().nullable(),
    });

    const closeForm = (actions: FormikHelpers<FormValues>) => {
        setEditing(false);
        actions.resetForm({ values: initialValues });
    };

    const onSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) => {
        onImageUpload(file?.file ?? undefined);

        // check if any field updated
        let fieldUpdated: boolean = false;
        Object.keys(initialValues).forEach((key) => {
            let value = values[key as keyof typeof values];
            if (initialValues[key as keyof typeof initialValues] !== value) {
                fieldUpdated = true;
            }
        });

        if (fieldUpdated) {
            const binaryFields = [
                'wkpf_development',
                'wkpf_legal',
                'wkpf_conveyancing',
                'wkpf_management',
                'wkpf_accounting',
                'wkpf_financing',
                'wkpf_fundtransfer',
                'wkpf_projects',
                'wkpf_interior',
                'wkpf_valuation',
                'wkpf_surveyance',
                'wkpf_inspection',
                'wkpf_salesagent',
                'wkpf_advisory',
                'wkpf_insurance',
                'wkpf_homestaging',
                'wkpf_media',
                'wkpf_training',
                'wkpf_renovation',
                'wkpf_repair',
                'wkpf_research',
            ];

            let binaryConvertedValues: any = binaryFields.reduce((accumulator, currentValue) => {
                return {
                    ...accumulator,
                    ...{
                        [currentValue]: convertBooleanToBinary(
                            Boolean(values[currentValue as keyof typeof values])
                        ),
                    },
                };
            }, values);

            editPartner(
                {
                    ...initialisePartnerObject(partner),
                    ...binaryConvertedValues,
                },
                {
                    onSuccess: (_, updatedPartnerDetails) => {
                        initialValues = initialiseInitialValues(updatedPartnerDetails);
                        closeForm(actions);
                    },
                    onError: (error) => {
                        closeForm(actions);
                        alert(error.message);
                    },
                }
            );
        } else {
            closeForm(actions);
        }
    };

    type PartnerTypesInterface = [number | null | undefined, string, SvgIconComponent, string];
    const partnerInfo: PartnerTypesInterface[] = [
        [partner.wkpf_development, 'wkpf_development', EngineeringOutlined, 'Property Development'],
        [partner.wkpf_management, 'wkpf_management', ManageAccountsOutlined, 'Property Management'],
        [partner.wkpf_valuation, 'wkpf_valuation', AddBusinessOutlined, 'Property Valuation'],
        [partner.wkpf_surveyance, 'wkpf_surveyance', PolicyOutlined, 'Land Surveying'],
        [partner.wkpf_projects, 'wkpf_projects', MapsHomeWorkOutlined, 'Project Marketing'],
        [partner.wkpf_salesagent, 'wkpf_salesagent', SupportAgentOutlined, 'Sales Agent'],
        [partner.wkpf_homestaging, 'wkpf_homestaging', HouseOutlined, 'Home Staging'],
        [partner.wkpf_renovation, 'wkpf_renovation', BedroomParentOutlined, 'Renovation'],
        [partner.wkpf_interior, 'wkpf_interior', ChairOutlined, 'Interior Design'],
        [partner.wkpf_repair, 'wkpf_repair', CarpenterOutlined, 'Repair/Maintenance'],
        [partner.wkpf_inspection, 'wkpf_inspection', ArchitectureOutlined, 'Home Inspection'],
        [
            partner.wkpf_advisory,
            'wkpf_advisory',
            SupervisedUserCircleOutlined,
            'Portfolio Advisory',
        ],
        [partner.wkpf_insurance, 'wkpf_insurance', HealthAndSafetyOutlined, 'Insurance Provider'],
        [partner.wkpf_legal, 'wkpf_legal', LocalPoliceOutlined, 'Legal Services'],
        [partner.wkpf_conveyancing, 'wkpf_conveyancing', FileOpenOutlined, 'Legal Conveyance'],
        [partner.wkpf_fundtransfer, 'wkpf_fundtransfer', SwapHorizOutlined, 'Fund Transfer'],
        [partner.wkpf_financing, 'wkpf_financing', MonetizationOnOutlined, 'Financing'],
        [partner.wkpf_accounting, 'wkpf_accounting', CalculateOutlined, 'Accounting/Tax'],
        [partner.wkpf_media, 'wkpf_media', AutoStoriesOutlined, 'Media/Publication'],
        [partner.wkpf_training, 'wkpf_training', RateReviewOutlined, 'Training/Education'],
        [partner.wkpf_research, 'wkpf_research', QueryStatsOutlined, 'Market Research'],
    ];

    const { mutate: editPhoto } = usePartnerMutations('EDIT_PHOTO');
    const [imageUpdate, setImageUpdate] = useState(0);

    const onImageUpload = async (ev: File | undefined) => {
        if (ev === undefined) {
            return;
        }
        const file = ev;

        if (file) {
            editPhoto(
                { wkpf_id: partner.wkpf_id, image: file },
                {
                    //to rerender image component
                    onSuccess: () => setImageUpdate((prev) => prev + 1),
                }
            );
        }
    };

    interface AttachmentFile {
        url: string;
        file: File;
    }
    const [file, setFile] = useState<AttachmentFile>();
    const inputRef = useRef<HTMLInputElement>(null);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({ handleSubmit, isSubmitting, setFieldValue, values, resetForm, dirty }) => {
                return (
                    <Form noValidate onSubmit={handleSubmit}>
                        <RouterPrompt isBlocking={dirty} />
                        <Row className="d-flex">
                            {editing ? (
                                <>
                                    <input
                                        hidden
                                        ref={inputRef}
                                        type="file"
                                        accept="image/*"
                                        onChange={async (e) => {
                                            if (e.target.files?.[0]) {
                                                getFileWithUrl(e.target.files[0]).then(setFile);
                                            } else {
                                                setFile(undefined);
                                            }
                                            // reset value to allow reading the same file
                                            if (inputRef.current) {
                                                inputRef.current.value = '';
                                            }
                                        }}
                                    ></input>

                                    {file !== undefined ? (
                                        <Col
                                            className="d-flex flex-column flex-grow-0 flex-shrink-1"
                                            lg={2}
                                        >
                                            <Image
                                                src={file.url}
                                                width={140}
                                                title={
                                                    file.file.name +
                                                    ' ' +
                                                    extractFileSize(file.file.size)
                                                }
                                                roundedCircle
                                                className={'shadow-none ' + socialStyles.userAvatar}
                                                style={{
                                                    maxWidth: '100%',
                                                    aspectRatio: '1/1',
                                                    objectFit: 'cover'
                                                }}
                                            />
                                            <IconButton
                                                transparent
                                                Icon={PhotoRounded}
                                                label="Upload"
                                                title="Add photo"
                                                onClick={(e) => inputRef.current?.click()}
                                                style={{
                                                    width: 140,
                                                    maxWidth: '100%',
                                                }}
                                                className=" text-dark mt-2"
                                            />
                                        </Col>
                                    ) : (
                                        <Col
                                            className="d-flex flex-column flex-grow-0 flex-shrink-1"
                                            lg={2}
                                        >
                                            <PartnerAvatar
                                                key={imageUpdate}
                                                partner={{
                                                    wkpf_id: partner.wkpf_id,
                                                    wkpf_name: partner.wkpf_name,
                                                    wkpf_profile: partner.wkpf_profile,
                                                }}
                                                width={140}
                                                className="shadow-none"
                                                style={{
                                                    maxWidth: '100%',
                                                    aspectRatio: '1/1',
                                                }}
                                            />
                                            <IconButton
                                                transparent
                                                Icon={PhotoRounded}
                                                label="Upload"
                                                title="Add photo"
                                                onClick={(e) => inputRef.current?.click()}
                                                style={{
                                                    width: 140,
                                                    maxWidth: '100%',
                                                }}
                                                className=" text-dark mt-2"
                                            />
                                        </Col>
                                    )}
                                </>
                            ) : (
                                <Col className="text-center" lg={2} xl={2}>
                                    <PartnerAvatar
                                        partner={{
                                            wkpf_id: partner.wkpf_id,
                                            wkpf_name: partner.wkpf_name,
                                            wkpf_profile: partner.wkpf_profile,
                                        }}
                                        width={140}
                                        className="shadow-none"
                                        style={{
                                            maxWidth: '100%',
                                            aspectRatio: '1/1',
                                        }}
                                    />
                                </Col>
                            )}
                            <Col className="d-flex flex-column justify-content-between">
                                {editing ? (
                                    <>
                                        <div className="d-flex">
                                            <div className="flex-shrink-0 flex-grow-1">
                                                <PartnerEditButton
                                                    editing={editing}
                                                    isSubmitting={isSubmitting}
                                                    setEditing={setEditing}
                                                    resetForm={resetForm}
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flex flex-grow-1">
                                            <div className="mb-2 d-flex flex-column flex-grow-1">
                                                <div>
                                                    <small className="text-uppercase text-muted">
                                                        Organisation Name
                                                    </small>
                                                    <span className="text-danger">*</span>
                                                </div>
                                                <BootstrapInput
                                                    id="wkpf_name"
                                                    required
                                                    placeholder="Organisation Name"
                                                    aria-label="Organisation Name"
                                                    disabled={isSubmitting}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <small className="text-uppercase text-muted">
                                                    Description
                                                </small>
                                            </div>
                                            <BootstrapInput
                                                id="wkpf_desc"
                                                as="textarea"
                                                rows="2"
                                                required={false}
                                                placeholder="Organisation Profile Description"
                                                aria-label="Organisation Profile Description"
                                                disabled={isSubmitting}
                                                className="col-xs-4"
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <h1 className="mb-3">{values.wkpf_name}</h1>
                                )}
                                {!editing && (
                                    <>
                                        <small className="text-muted text-uppercase">
                                            Description
                                        </small>
                                        <span>
                                            {values.wkpf_desc?.length === 0
                                                ? 'Information not available'
                                                : values.wkpf_desc ?? 'Information not available'}
                                        </span>
                                        <small className="text-muted d-block text-uppercase mt-3">
                                            Address
                                        </small>
                                        <div>
                                            {values.wkpf_address && (
                                                <div title="Address" className="d-inline-block">
                                                    {values.wkpf_address}
                                                </div>
                                            )}

                                            {values.wkpf_postcode && values.wkpf_address ? (
                                                <div title="Postcode" className="d-inline-block">
                                                    {', ' + values.wkpf_postcode}
                                                </div>
                                            ) : (
                                                values.wkpf_postcode && (
                                                    <div
                                                        title="Postcode"
                                                        className="d-inline-block"
                                                    >
                                                        {values.wkpf_postcode}
                                                    </div>
                                                )
                                            )}
                                        </div>

                                        {!(
                                            values.wkpf_city ||
                                            CountryRegionData.find(
                                                ([, key]) => key === values.wkpf_country
                                            )?.[2]
                                                .split('|')
                                                .map((r) => {
                                                    return r.split('~');
                                                })
                                                ?.find(
                                                    (region) => region[1] === values.wkpf_region
                                                )?.[0] ||
                                            CountryRegionData.find(
                                                ([, key]) => key === values.wkpf_country
                                            )?.[0] ||
                                            values.wkpf_postcode
                                        ) && !values.wkpf_address ? (
                                            <p className="m-0 d-inline-block">
                                                Information not available
                                            </p>
                                        ) : (
                                            (values.wkpf_city ||
                                                CountryRegionData.find(
                                                    ([, key]) => key === values.wkpf_country
                                                )?.[2]
                                                    .split('|')
                                                    .map((r) => {
                                                        return r.split('~');
                                                    })
                                                    ?.find(
                                                        (region) => region[1] === values.wkpf_region
                                                    )?.[0] ||
                                                CountryRegionData.find(
                                                    ([, key]) => key === values.wkpf_country
                                                )?.[0] ||
                                                values.wkpf_postcode) && (
                                                <div>
                                                    <div title="Region" className="d-inline-block">
                                                        {values.wkpf_region
                                                            ? CountryRegionData.find(
                                                                  ([, key]) =>
                                                                      key === values.wkpf_country
                                                              )?.[2]
                                                                  .split('|')
                                                                  .map((r) => {
                                                                      return r.split('~');
                                                                  })
                                                                  ?.find(
                                                                      (region) =>
                                                                          region[1] ===
                                                                          values.wkpf_region
                                                                  )?.[0]
                                                            : ''}
                                                    </div>
                                                    <div title="City" className="d-inline-block">
                                                        {values.wkpf_city && values.wkpf_region
                                                            ? ', ' + values.wkpf_city
                                                            : values.wkpf_city ?? ''}
                                                    </div>
                                                    <div title="Country" className="d-inline-block">
                                                        {CountryRegionData.find(
                                                            ([, key]) => key === values.wkpf_country
                                                        )?.[0] &&
                                                        (values.wkpf_city ||
                                                            CountryRegionData.find(
                                                                ([, key]) =>
                                                                    key === values.wkpf_country
                                                            )?.[2]
                                                                .split('|')
                                                                .map((r) => {
                                                                    return r.split('~');
                                                                })
                                                                ?.find(
                                                                    (region) =>
                                                                        region[1] ===
                                                                        values.wkpf_region
                                                                )?.[0])
                                                            ? ', ' +
                                                              CountryRegionData.find(
                                                                  ([, key]) =>
                                                                      key === values.wkpf_country
                                                              )?.[0]
                                                            : CountryRegionData.find(
                                                                  ([, key]) =>
                                                                      key === values.wkpf_country
                                                              )?.[0] ?? ''}
                                                    </div>
                                                </div>
                                            )
                                        )}

                                        <Row className="pt-3">
                                            <Col xs={12}>
                                                <small className="text-muted d-block text-uppercase">
                                                    Contact Information
                                                </small>
                                                <div>
                                                    <span className="me-2 d-inline-block">
                                                        <PhoneOutlined
                                                            titleAccess="Phone Number"
                                                            color="disabled"
                                                            fontSize="small"
                                                            className="me-1"
                                                        />
                                                        <label>
                                                            {values.wkpf_phone ?? 'No number'}{' '}
                                                        </label>
                                                    </span>
                                                    <span className="me-2 d-inline-block">
                                                        <MailOutlined
                                                            titleAccess="Email Address"
                                                            color="disabled"
                                                            fontSize="small"
                                                            className="me-1"
                                                        />{' '}
                                                        {values.wkpf_email ?? 'No email'}{' '}
                                                    </span>
                                                    <span className="me-2 d-inline-block">
                                                        {values.wkpf_url !== undefined &&
                                                        values.wkpf_url !== null &&
                                                        values.wkpf_url[0] !== undefined ? (
                                                            <div className="d-flex">
                                                                <div
                                                                    onClick={() => {
                                                                        window.open(
                                                                            values.wkpf_url ?? ''
                                                                        );
                                                                    }}
                                                                    className={
                                                                        partnerStyles.partnerLink
                                                                    }
                                                                >
                                                                    <Language
                                                                        style={{
                                                                            cursor: 'default',
                                                                        }}
                                                                        className="text-secondary me-1"
                                                                        fontSize="small"
                                                                    />
                                                                    &nbsp;
                                                                    {values.wkpf_url}
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <>
                                                                <Language
                                                                    className="text-secondary me-1"
                                                                    fontSize="small"
                                                                />
                                                                {'No website'}
                                                            </>
                                                        )}
                                                    </span>
                                                </div>
                                            </Col>
                                        </Row>
                                    </>
                                )}
                            </Col>
                            {isAdmin && !editing && (
                                <>
                                    <Col className="d-flex flex-column flex-grow-0">
                                        <PartnerEditButton
                                            editing={editing}
                                            isSubmitting={isSubmitting}
                                            setEditing={setEditing}
                                            resetForm={resetForm}
                                        />
                                    </Col>
                                </>
                            )}
                        </Row>

                        {editing && (
                            <>
                                <Row className="pt-3">
                                    <Col xs={0} lg={2} xl={2}></Col>
                                    <Col xs={12} lg={10} xl={10}>
                                        <Row>
                                            <Col lg={9} xl={9}>
                                                <small className="text-muted d-block text-uppercase">
                                                    Address
                                                </small>
                                                <BootstrapInput
                                                    id="wkpf_address"
                                                    placeholder="Address"
                                                    aria-label="Address"
                                                    disabled={isSubmitting}
                                                />
                                            </Col>
                                            <Col xs={12} lg={3} xl={3}>
                                                <div className={styles.colItem}>
                                                    <small className="text-muted d-block text-uppercase">
                                                        Postal Code
                                                    </small>
                                                    <BootstrapInput
                                                        id="wkpf_postcode"
                                                        placeholder="Postal Code"
                                                        aria-label="Postal Code"
                                                        disabled={isSubmitting}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="pt-3">
                                    <Col xs={0} lg={2} xl={2}></Col>
                                    <Col>
                                        <div className={styles.colItem}>
                                            <small className="text-muted d-block text-uppercase">
                                                City
                                            </small>
                                            <BootstrapInput
                                                id="wkpf_city"
                                                placeholder="City"
                                                aria-label="City"
                                                disabled={isSubmitting}
                                            />
                                        </div>
                                    </Col>
                                    <Col>
                                        <small className="text-muted d-block text-uppercase">
                                            Country
                                        </small>
                                        <CountryDropdown
                                            name="wkpf_country"
                                            valueType="short"
                                            onChange={(val: string) =>
                                                setFieldValue('wkpf_country', val)
                                            }
                                            classes="form-select"
                                            value={values.wkpf_country ?? ''}
                                        />
                                    </Col>
                                    <Col>
                                        <div className={styles.colItem}>
                                            <small className="text-muted d-block text-uppercase">
                                                region
                                            </small>
                                            <RegionDropdown
                                                blankOptionLabel="Select a country first"
                                                name="wkpf_region"
                                                valueType="short"
                                                countryValueType="short"
                                                country={values.wkpf_country ?? ''}
                                                value={values.wkpf_region ?? ''}
                                                onChange={(val: string) =>
                                                    setFieldValue('wkpf_region', val)
                                                }
                                                classes="form-select"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        )}

                        {editing && (
                            <Row className="pt-3">
                                <Col xs={0} lg={2} xl={2}></Col>
                                <Col>
                                    <div className={styles.colItem}>
                                        <small className="text-muted d-block text-uppercase">
                                            Contact Number
                                        </small>
                                        <BootstrapInputPhone
                                            id="wkpf_phone"
                                            placeholder="Contact Number"
                                            disabled={isSubmitting}
                                            value={values.wkpf_phone ?? ''}
                                            onChange={(val) => {
                                                setFieldValue('wkpf_phone', val);
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col>
                                    <div className={styles.colItem}>
                                        <small className="text-muted d-block text-uppercase">
                                            Email
                                        </small>
                                        <BootstrapInput
                                            id="wkpf_email"
                                            placeholder="Email"
                                            aria-label="Email"
                                            disabled={isSubmitting}
                                        />
                                    </div>
                                </Col>
                                <Col>
                                    <small className="text-muted d-block text-uppercase">
                                        Website URL
                                    </small>
                                    <BootstrapInput
                                        id="wkpf_url"
                                        placeholder="https://example.com"
                                        aria-label="Website"
                                        disabled={isSubmitting}
                                    />
                                </Col>
                            </Row>
                        )}

                        <div className="pt-3">
                            {!editing ? (
                                <>
                                    <Row>
                                        <Col xs={0} lg={2} xl={2}></Col>
                                        <Col xs={9}>
                                            <small className="text-muted d-block text-uppercase">
                                                We provide our service(s) in these following
                                                region(s):
                                            </small>
                                            <span>
                                                {values.wkpf_serviceregions?.length === 0
                                                    ? 'Information not available'
                                                    : values.wkpf_serviceregions ??
                                                      'Information not available'}
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row className="pt-3">
                                        <Col xs={0} lg={2} xl={2}></Col>
                                        <Col lg={8} xl={8}>
                                            <small className="text-muted d-block text-uppercase">
                                                Services
                                            </small>
                                            <Row className="d-flex align-content-start justify-content-start">
                                                {partnerInfo.reduce((prev, curr) => {
                                                    if (curr[0]) {
                                                        prev.push(curr);
                                                    }
                                                    return prev;
                                                }, [] as PartnerTypesInterface[]).length !== 0 ? (
                                                    <>
                                                        {partnerInfo.map(
                                                            ([field, , Icon, label], key) =>
                                                                field === 1 ? (
                                                                    <TypeLabel
                                                                        editing={editing}
                                                                        key={key}
                                                                        Icon={Icon}
                                                                        label={label}
                                                                    />
                                                                ) : (
                                                                    <div hidden key={key}></div>
                                                                )
                                                        )}
                                                    </>
                                                ) : (
                                                    <div>Information not available</div>
                                                )}
                                            </Row>
                                        </Col>
                                    </Row>
                                </>
                            ) : (
                                editing && (
                                    <>
                                        <Row className="pt-2">
                                            <Col xs={0} lg={2} xl={2}></Col>
                                            <Col lg={10} xl={10}>
                                                <hr className="text-secondary pt-0 mb-3 mt-0"></hr>
                                                <small className="text-muted d-block text-uppercase">
                                                    We provide our service(s) in these following
                                                    region(s):
                                                </small>
                                                <BootstrapInput
                                                    id="wkpf_serviceregions"
                                                    placeholder="Countries, states, cities..."
                                                    aria-label="Service Regions"
                                                    disabled={isSubmitting}
                                                    as="textarea"
                                                />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col xs={0} lg={2} xl={2}></Col>
                                            <Col lg={10} xl={10}>
                                                <small className="text-muted mt-3 d-block text-uppercase">
                                                    Services
                                                </small>
                                                <Row xs={1} sm={2} md={2} lg={2} xl={3}>
                                                    {partnerInfo.map(
                                                        ([field, id, Icon, label], index) => (
                                                            <Service
                                                                key={index}
                                                                editing={editing}
                                                                isSubmitting={isSubmitting}
                                                                field={field}
                                                                id={id}
                                                                Icon={Icon}
                                                                label={label}
                                                            />
                                                        )
                                                    )}
                                                </Row>
                                            </Col>
                                        </Row>
                                    </>
                                )
                            )}
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};

interface ServiceInterface {
    editing: boolean;
    isSubmitting: boolean;
    field: number | null | undefined;
    id: string;
    Icon: SvgIconComponent;
    label: string;
}

export const Service = ({ editing, isSubmitting, field, id, Icon, label }: ServiceInterface) => {
    return (
        <>
            {editing ? (
                <Col className={styles.featureColItem + 'pb-0'}>
                    <BootstrapCheckbox
                        id={id}
                        label={
                            <Col>
                                <TypeLabel Icon={Icon} label={label} editing={true} />
                            </Col>
                        }
                        disabled={isSubmitting}
                    />
                </Col>
            ) : (
                <>{field ? <TypeLabel Icon={Icon} label={label} editing={false} /> : undefined}</>
            )}
        </>
    );
};

interface TypeslabelInterface {
    Icon: SvgIconComponent;
    label: string;
    editing: boolean;
}

const TypeLabel = ({ Icon, label, editing }: TypeslabelInterface) => {
    return (
        <>
            {editing ? (
                <div>
                    <Icon color="disabled" titleAccess={label}>
                        {Icon}
                    </Icon>
                    <span className="ps-2">{label}</span>
                </div>
            ) : (
                <div style={{ width: 30 }}>
                    <Icon color="disabled" titleAccess={label}>
                        {Icon}
                    </Icon>
                </div>
            )}
        </>
    );
};

export default PartnerDetails;
