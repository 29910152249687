import React, { useEffect, useState } from 'react';
import { Butlerr } from '../../../types/butlerr';
import { ButlerrInsuranceType, InsuranceTypeKey } from '../../../types/butlerr-enums';
import {
    convertDateToYYYYMMDD,
    formCloseHandler,
    removeNumberFormatting,
} from '../../utils/HelperFunctions';
import * as yup from 'yup';
import { Transition } from 'react-transition-group';
import { Formik } from 'formik';
import { Col, Container, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import {
    BootstrapInput,
    BootstrapInputPrice,
    BootstrapSelect,
} from '../../utils/FormikBootstrapInputs';
import IconButton from '../../utils/IconButton';
import { Add, Done, LinkOff, PostAdd } from '@mui/icons-material';
//import { DocumentPickerModal } from '../../documents/DocumentsLink';
import { InsuranceQueries, useInsuranceMutations } from '../../../services/assetinsurance.service';
import DestructiveModal from '../../utils/DestructiveModal';
import { useCashflowMutations } from '../../../services/assetcashflow.service';
import useModal from '../../../hooks/useModal';
import { DocumentSelectModal } from '../../documents/DocsModal';
import { useQueryClient } from 'react-query';
import BootstrapModalFooter from '../../utils/BootstrapModalFooter';

type InsuranceForm = {
    insu_type: InsuranceTypeKey | ''; // Allow '' to allow the user to select
    insu_carrier: string;
    insu_policyno: string;
    insu_from: string;
    insu_period: number;
    insu_premium: string;
    insu_notes: string;
    docu_name?: string;
};

interface InsuranceModalProps {
    show: boolean;
    onModalClose: () => void;
    initialValue?: Butlerr.Asset.Insurance;
    asset: Butlerr.Asset;
}

const AssetInsurancesModal = ({ show, onModalClose, initialValue, asset }: InsuranceModalProps) => {
    const assetId = asset.asst_id;
    const {
        mutate: createInsurance,
        isLoading: isCreateLoading,
        error: createError,
    } = useInsuranceMutations('CREATE');
    const {
        mutate: editInsurance,
        isLoading: isEditLoading,
        error: editError,
    } = useInsuranceMutations('EDIT');
    const { mutate: createCashflow } = useCashflowMutations('CREATE');

    const { mutateAsync: deleteInsurance } = useInsuranceMutations('DELETE');

    // delete modal
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedInsuranceId, setSelectedInsuranceId] = useState<number>();

    const onDelete = (insuranceId: number) => {
        setSelectedInsuranceId(insuranceId);
        setShowDeleteModal(true);
    };

    const onDeleteClose = () => {
        setShowDeleteModal(false);
    };

    const onDeleteConfirm = async () => {
        await deleteInsurance({ asst_id: assetId, insu_id: selectedInsuranceId ?? -1 });
        formCloseHandler(handleClose);
    };

    const isLoading = initialValue === undefined ? isCreateLoading : isEditLoading;
    const error = initialValue === undefined ? createError : editError;

    // modal
    const handleClose = () => {
        if (error !== null) error.message = '';
        if (!isLoading) onModalClose();
    };

    // formik stuff
    const allowedKeys = {
        insu_type: Object.keys(ButlerrInsuranceType),
    };
    const allowedLabels = {
        insu_type: Object.values(ButlerrInsuranceType),
    };

    const initialValues: InsuranceForm = {
        insu_type: initialValue?.insu_type ?? '',
        insu_carrier: initialValue?.insu_carrier ?? '',
        insu_policyno: initialValue?.insu_policyno ?? '',
        insu_from: convertDateToYYYYMMDD(initialValue?.insu_from) ?? '',
        insu_period: initialValue?.insu_period ?? 0,
        insu_premium:
            initialValue?.insu_premium !== undefined
                ? Number(initialValue.insu_premium).toLocaleString()
                : '',
        insu_notes: initialValue?.insu_notes ?? '',
    };

    const validationSchema = yup.object().shape({
        insu_type: yup.string().required('Required').oneOf(allowedKeys.insu_type),
        insu_carrier: yup
            .string()
            .required('Required')
            .max(60, ({ max }) => `Maximum ${max} characters`),
        insu_policyno: yup
            .string()
            .required('Required')
            .max(20, ({ max }) => `Maximum ${max} characters`),
        insu_from: yup.date().required('Required'),
        insu_period: yup
            .number()
            .required('Required')
            .min(1, ({ min }) => `Minimum ${min}`),
        insu_premium: yup.string().required('Required'),
        insu_notes: yup.string().max(128, ({ max }) => `Maximum ${max} characters`),
    });

    const onSubmit = (values: InsuranceForm) => {
        const { docu_name, insu_type, insu_premium, ...data } = values;
        const [premiumAmount] = removeNumberFormatting(String(insu_premium));

        const form = {
            ...data,
            insu_type: insu_type as keyof typeof ButlerrInsuranceType,
            insu_premium: Number(premiumAmount),
            asst_id: assetId,
            insu_id: initialValue?.insu_id,
            insu_doclink: selectedDocument?.doc_id ?? null,
        };

        if (initialValue === undefined) {
            createInsurance(form, {
                onSuccess: () => {
                    formCloseHandler(handleClose);

                    //ask if want to save to cashflow
                    if (
                        window.confirm(
                            'Do you want to add this insurance record into cashflow?'
                        ) === true
                    ) {
                        //save to db
                        createCashflow(
                            {
                                asst_id: assetId,
                                ascf_type: 'IN',
                                ascf_date: form.insu_from,
                                ascf_amt: -form.insu_premium,
                                ascf_desc: `Premium amount for ${
                                    ButlerrInsuranceType[form.insu_type]
                                } insurance, policy number: ${form.insu_policyno}`,
                                ascf_currency: asset.asst_currency,
                                ascf_doclink: null,
                                ascf_status: 1, //verified by default
                            },
                            {
                                onSuccess: () => {
                                    alert('Cashflow created successfully.');
                                },
                            }
                        );
                    }
                },
            });
        } else {
            editInsurance(form as typeof form & { insu_id: number }, {
                onSuccess: () => {
                    formCloseHandler(handleClose);
                },
            });
        }
    };

    // document picker
    const [selectedDocument, setSelectedDocument] = useState<Butlerr.Document.File>();

    useEffect(() => {
        if (initialValue?.insu_doclink) {
            setSelectedDocument({
                doc_name: initialValue?.doc_name,
                doc_created: initialValue?.doc_created,
                doc_desc: initialValue?.doc_desc,
                doc_format: initialValue?.doc_format,
                doc_id: initialValue?.doc_id,
                doct_parentid: initialValue?.doct_parentid,
            } as Butlerr.Document.File);
        } else {
            setSelectedDocument(undefined);
        }
    }, [
        initialValue?.insu_doclink,
        initialValue?.doc_name,
        initialValue?.doc_created,
        initialValue?.doc_desc,
        initialValue?.doc_format,
        initialValue?.doc_id,
        initialValue?.doct_parentid,
    ]);

    const [picker, openPicker] = useModal(DocumentSelectModal, {
        asset: asset,
        onFileSelect: setSelectedDocument,
        className: 'modal-layer-1',
        backdropClassName: 'modal-layer-1',
        initialFolder: asset.doct_insu_id,
    });

    /**
     * Refresh all cashflows when the modal is opened, if it's an edit modal
     * The documents might have been changed
     */
    const qc = useQueryClient();
    useEffect(() => {
        if (initialValue?.asst_id !== undefined && show) {
            qc.invalidateQueries(InsuranceQueries.INSURANCES(initialValue.asst_id));
        }
    }, [initialValue?.asst_id, qc, show]);

    return (
        <>
            <Transition in={show} timeout={{ exit: 300 }} unmountOnExit>
                {() => (
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        {({ submitForm, setFieldValue, values, dirty }) => {
                            return (
                                <Form noValidate>
                                    <Modal
                                        show={show}
                                        onHide={formCloseHandler.bind(
                                            undefined,
                                            handleClose,
                                            dirty
                                        )}
                                        scrollable
                                        size="lg"
                                        backdrop={isLoading ? 'static' : undefined}
                                        keyboard={!isLoading}
                                        centered
                                    >
                                        <Modal.Header>
                                            <Container>
                                                <Row>
                                                    <Modal.Title>
                                                        {initialValue === undefined
                                                            ? 'Add '
                                                            : 'Edit '}
                                                        Insurance Details
                                                    </Modal.Title>
                                                </Row>
                                            </Container>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <Container>
                                                <Row className="mb-3 g-3">
                                                    <Form.Group as={Col} lg>
                                                        <BootstrapSelect
                                                            id="insu_type"
                                                            label="Policy Type"
                                                            placeholder="Type"
                                                            aria-label="Type"
                                                            disabled={
                                                                asset.status === 'AR' || isLoading
                                                            }
                                                            allowedKeys={allowedKeys.insu_type}
                                                            allowedLabels={allowedLabels.insu_type}
                                                        />
                                                    </Form.Group>

                                                    <Form.Group as={Col} lg>
                                                        <BootstrapInput
                                                            id="insu_carrier"
                                                            label="Policy Carrier"
                                                            placeholder="Carrier"
                                                            aria-label="Carrier"
                                                            disabled={
                                                                asset.status === 'AR' || isLoading
                                                            }
                                                        />
                                                    </Form.Group>

                                                    <Form.Group as={Col} lg>
                                                        <BootstrapInput
                                                            id="insu_policyno"
                                                            label="Policy Number"
                                                            placeholder="Policy Number"
                                                            aria-label="Policy Number"
                                                            disabled={
                                                                asset.status === 'AR' || isLoading
                                                            }
                                                        />
                                                    </Form.Group>
                                                </Row>

                                                <Row className="mb-3 g-3">
                                                    <Form.Group as={Col} lg>
                                                        <BootstrapInputPrice
                                                            id="insu_premium"
                                                            label="Premium Amount"
                                                            placeholder="Premium amount"
                                                            aria-label="Premium amount"
                                                            disabled={
                                                                asset.status === 'AR' || isLoading
                                                            }
                                                            inputGroupText={asset.asst_currency}
                                                        />
                                                    </Form.Group>

                                                    <Form.Group as={Col} lg>
                                                        <BootstrapInput
                                                            id="insu_from"
                                                            label="Start Date"
                                                            type="date"
                                                            placeholder="Start Date"
                                                            aria-label="Start Date"
                                                            disabled={
                                                                asset.status === 'AR' || isLoading
                                                            }
                                                        />
                                                    </Form.Group>

                                                    <Form.Group as={Col} lg>
                                                        <BootstrapInput
                                                            id="insu_period"
                                                            label="Months Covered"
                                                            type="number"
                                                            placeholder="Months"
                                                            aria-label="Months"
                                                            disabled={
                                                                asset.status === 'AR' || isLoading
                                                            }
                                                            value={values.insu_period.toString()} // Needed to remove leading zeros
                                                        />
                                                    </Form.Group>
                                                </Row>

                                                <Row className="mb-3 g-3">
                                                    <Form.Group as={Col} lg>
                                                        <BootstrapInput
                                                            as="textarea"
                                                            id="insu_notes"
                                                            label="Notes"
                                                            placeholder="Notes"
                                                            disabled={
                                                                asset.status === 'AR' || isLoading
                                                            }
                                                            required={false}
                                                        />
                                                    </Form.Group>
                                                </Row>

                                                <Row className="mb-3 g-3">
                                                    <Form.Group as={Col} lg>
                                                        <InputGroup>
                                                            <Form.Control
                                                                readOnly
                                                                value={
                                                                    selectedDocument
                                                                        ? `${
                                                                              selectedDocument.doc_name
                                                                          }${
                                                                              selectedDocument.doc_format
                                                                                  ? '.' +
                                                                                    selectedDocument.doc_format
                                                                                  : ''
                                                                          }`
                                                                        : ''
                                                                }
                                                                placeholder="No document linked"
                                                            />
                                                            <InputGroup.Text>
                                                                {selectedDocument !== undefined ? (
                                                                    <IconButton
                                                                        transparent
                                                                        border={false}
                                                                        iconHtmlColor="var(--grey)"
                                                                        Icon={LinkOff}
                                                                        title="Unlink document"
                                                                        onClick={() =>
                                                                            setSelectedDocument(
                                                                                undefined
                                                                            )
                                                                        }
                                                                        disabled={
                                                                            asset.status === 'AR' ||
                                                                            isLoading
                                                                        }
                                                                    />
                                                                ) : (
                                                                    <IconButton
                                                                        transparent
                                                                        border={false}
                                                                        iconHtmlColor="var(--grey)"
                                                                        Icon={PostAdd}
                                                                        title="Link a document"
                                                                        onClick={openPicker}
                                                                        disabled={
                                                                            asset.status === 'AR' ||
                                                                            isLoading
                                                                        }
                                                                    />
                                                                )}
                                                            </InputGroup.Text>
                                                        </InputGroup>
                                                    </Form.Group>
                                                </Row>
                                            </Container>
                                        </Modal.Body>
                                        <BootstrapModalFooter
                                            error={error}
                                            deleteBtnProps={{
                                                onClick: () => onDelete(initialValue?.insu_id!),
                                                disabled: asset.status === 'AR' || isLoading,
                                                className:
                                                    initialValue === undefined ? 'd-none' : '',
                                            }}
                                            cancelBtnProps={{
                                                onClick: () => formCloseHandler(handleClose, dirty),
                                                disabled: isLoading,
                                            }}
                                            confirmBtnProps={{
                                                Icon: initialValue === undefined ? Add : Done,
                                                label:
                                                    initialValue === undefined
                                                        ? isLoading
                                                            ? 'Adding...'
                                                            : 'Add'
                                                        : isLoading
                                                        ? 'Saving...'
                                                        : 'Save',
                                                onClick:
                                                    initialValue === undefined
                                                        ? submitForm
                                                        : !dirty &&
                                                          selectedDocument?.doc_id ===
                                                              initialValue.insu_doclink
                                                        ? handleClose
                                                        : submitForm,
                                                disabled: asset.status === 'AR' || isLoading,
                                            }}
                                        />
                                    </Modal>
                                    {picker}
                                </Form>
                            );
                        }}
                    </Formik>
                )}
            </Transition>
            <DestructiveModal
                show={showDeleteModal}
                onClose={onDeleteClose}
                onConfirm={onDeleteConfirm}
                title="Delete insurance?"
                description="Any document linked to this insurance will not be affected."
                className="modal-layer-1"
                backdropClassName="modal-layer-1"
            />
        </>
    );
};

export default AssetInsurancesModal;
