import { useCallback, useMemo } from 'react';
import { Column } from 'react-table';
import { Butlerr } from '../../../types/butlerr';
import { formatCurrency, formatDate } from '../../utils/HelperFunctions';
import ReactTable from '../../utils/ReactTable';

interface AssetValuationsTableInterface {
    asset: Butlerr.Asset;
    valuations: Butlerr.Asset.Valuation[];
    isLoading: boolean;
    onModalOpen: (valuation?: Butlerr.Asset.Valuation | undefined) => void;
}

const AssetValuationsTable = ({
    asset,
    valuations,
    isLoading,
    onModalOpen,
}: AssetValuationsTableInterface) => {
    // edit modal
    const onEdit = useCallback(
        (valuation: Butlerr.Asset.Valuation) => {
            onModalOpen(valuation);
        },
        [onModalOpen]
    );

    // columns
    const columns = useMemo<Column<Butlerr.Asset.Valuation>[]>(
        () => [
            {
                Header: 'date',
                accessor: 'valu_date',
                minWidth: 95,
                Cell: ({ value }) => {
                    return <>{formatDate(value.toString())}</>;
                },
            },
            {
                Header: 'valuer',
                accessor: 'valu_valuer',
                minWidth: 91,
                Cell: ({ value }) => {
                    return <>{value || '-'}</>;
                },
            },
            {
                Header: 'price',
                accessor: 'valu_value',
                Cell: ({ value }) => {
                    return (
                        <>
                            {formatCurrency(value, {
                                currency: asset.asst_currency,
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                            })}
                        </>
                    );
                },
            },
            // {
            //     Header: 'document',
            //     accessor: 'valu_doclink',
            //     minWidth: 165,
            //     Cell: ({ row }) => {
            //         let name = row.original.doc_name;
            //         if (!name) return "-"

            //         if (row.original.doc_format) {
            //             name += `.${row.original.doc_format}`
            //         }
            //         return name;
            //     },
            // },
        ],
        [asset.asst_currency]
    );

    return (
        <>
            <ReactTable
                data={valuations}
                columns={columns}
                isLoading={isLoading}
                Row={(props, data) => (
                    <tr
                        {...props}
                        className="cursor-pointer"
                        onClick={onEdit.bind(undefined, data)}
                    />
                )}
            />
        </>
    );
};

export default AssetValuationsTable;
