/* old partners module components */

import { useEffect, useState } from "react";
import { Button, Card, CardProps, Col, Container, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useButlerrUser } from "../../../services/user.service";
import { Butlerr } from "../../../types/butlerr";
import EmptyMessage from "../../utils/EmptyMessage";
import assetStyles from "../../assets/asset.module.css"
import { AddBusinessOutlined, AddRounded, ArchitectureOutlined, AutoStoriesOutlined, BedroomParentOutlined, CalculateOutlined, CarpenterOutlined, ChairOutlined, EngineeringOutlined, FileOpenOutlined, GroupsOutlined, HealthAndSafetyOutlined, HouseOutlined, LocalPoliceOutlined, ManageAccountsOutlined, MapsHomeWorkOutlined, MonetizationOnOutlined, PersonOutlined, QueryStatsOutlined, RateReviewOutlined, SupervisedUserCircleOutlined, SupportAgentOutlined, SvgIconComponent, SwapHorizOutlined } from "@mui/icons-material";
import CreatePartnerModal from "./AddPartnerModal";
import styles from "../partner.module.css"
import { createPartnerRoute, PartnerRoutes } from "../Work";
import PartnerAvatar from "./PartnerAvatar";
import LineClampText from "../../utils/LineClampText";
import { CountryRegionData } from "react-country-region-selector";

interface AllPartnersPageProps {
    partners: Butlerr.Work.WorkProfile[];
}
// work profiles component (redering cards)
const AllPartnersPage = ({ partners }: AllPartnersPageProps) => {
    const [showModal, setShowModal] = useState(false);

    const onModalOpen = () => {
        setShowModal(true);
    };

    const onModalClose = () => {
        setShowModal(false);
    };

    const { state } = useLocation<{ fromOnboardingModal: boolean }>();
    const fromOnboardingModal = state?.fromOnboardingModal;

    useEffect(() => {
        if (fromOnboardingModal === true) {
            setShowModal(true);
        }
    }, [fromOnboardingModal]);

    const { data: dbUser } = useButlerrUser();

    return (
        <Container className="p-0">
            {partners.length === 0 ? ( // if there are no work profiles
                <div className="justify-content-center d-flex align-items-center flex-column">
                    <EmptyMessage message="You currently do not have any work profile." />
                    <br></br>
                    <Button onClick={onModalOpen} size="sm">
                        Create a Work Profile
                    </Button>
                </div>
            ) : (
                //if there are work profiles
                <div>
                    {partners.map((partner, key) => {
                        return (
                            <CardItem
                                key={key}
                                partner={partner}
                                owner={partner.wkpf_owner === dbUser?.user_id ?? 0}
                            ></CardItem>
                        );
                    })}
                    <Card // add work profile card
                        className={
                            'border-0 shadow-sm cursor-pointer ' + assetStyles.assetsListCard
                        }
                        style={{
                            backgroundColor: 'whitesmoke',
                        }}
                        onClick={() => {
                            onModalOpen();
                        }}
                    >
                        <Card.Body className="p-4 d-flex flex-row cursor-pointer" as={Row}>
                            <AddRounded
                                className="mx-auto"
                                style={{
                                    width: '70%',
                                    height: 80,
                                    alignSelf: 'center',
                                }}
                            />
                        </Card.Body>
                    </Card>
                </div>
            )}
            <CreatePartnerModal show={showModal} onModalClose={onModalClose} />
        </Container>
    );
};

interface CardItemInterface {
    partner: Butlerr.Work.WorkProfile;
    owner: Boolean;
}

type PartnerTypesInterface = [number | null | undefined, string, SvgIconComponent, string];

//card item for work profile card
export const CardItem = ({ partner, owner }: CardItemInterface) => {
    const partnerInfo: PartnerTypesInterface[] = [
        [partner.wkpf_development, 'wkpf_development', EngineeringOutlined, 'Property Development'],
        [partner.wkpf_management, 'wkpf_management', ManageAccountsOutlined, 'Property Management'],
        [partner.wkpf_valuation, 'wkpf_valuation', AddBusinessOutlined, 'Property Valuation'],
        [partner.wkpf_surveyance, 'wkpf_surveyance', ArchitectureOutlined, 'Land Surveying'],
        [partner.wkpf_projects, 'wkpf_projects', MapsHomeWorkOutlined, 'Project Marketing'],
        [partner.wkpf_salesagent, 'wkpf_salesagent', SupportAgentOutlined, 'Sales Agent'],
        [partner.wkpf_homestaging, 'wkpf_homestaging', HouseOutlined, 'Home Staging'],
        [partner.wkpf_renovation, 'wkpf_renovation', BedroomParentOutlined, 'Renovation'],
        [partner.wkpf_interior, 'wkpf_interior', ChairOutlined, 'Interior Design'],
        [partner.wkpf_repair, 'wkpf_repair', CarpenterOutlined, 'Repair/Maintenance'],
        [partner.wkpf_inspection, 'wkpf_inspection', ArchitectureOutlined, 'Home Inspection'],
        [
            partner.wkpf_advisory,
            'wkpf_advisory',
            SupervisedUserCircleOutlined,
            'Portfolio Advisory',
        ],
        [partner.wkpf_insurance, 'wkpf_insurance', HealthAndSafetyOutlined, 'Insurance Provider'],
        [partner.wkpf_legal, 'wkpf_legal', LocalPoliceOutlined, 'Legal Services'],
        [partner.wkpf_conveyancing, 'wkpf_conveyancing', FileOpenOutlined, 'Legal Conveyance'],
        [partner.wkpf_fundtransfer, 'wkpf_fundtransfer', SwapHorizOutlined, 'Fund Transfer'],
        [partner.wkpf_financing, 'wkpf_financing', MonetizationOnOutlined, 'Financing'],
        [partner.wkpf_accounting, 'wkpf_accounting', CalculateOutlined, 'Accounting/Tax'],
        [partner.wkpf_media, 'wkpf_media', AutoStoriesOutlined, 'Media/Publication'],
        [partner.wkpf_training, 'wkpf_training', RateReviewOutlined, 'Training/Education'],
        [partner.wkpf_research, 'wkpf_research', QueryStatsOutlined, 'Market Research'],
    ];

    interface TypeslabelInterface {
        Icon: SvgIconComponent;
        label: string;
    }
    const TypeLabel = ({ Icon, label }: TypeslabelInterface) => {
        return (
            <span className={styles.partnerIconWrapper}>
                <Icon titleAccess={label}>{Icon}</Icon>
            </span>
        );
    };

    const isAdmin = isUserAdmin(partner);

    return (
        <Card className={'border-0 shadow cursor-pointer ps-1 mb-3 ' + assetStyles.assetsListCard}>
            <Card.Body className="p-3">
                <div
                    style={{
                        position: 'absolute',
                        right: 10,
                        top: 10,
                    }}
                >
                    {owner === true ? (
                        <PersonOutlined titleAccess="Owner" className="text-secondary" />
                    ) : isAdmin === true ? (
                        <ManageAccountsOutlined titleAccess="Admin" className="text-secondary" />
                    ) : (
                        <GroupsOutlined titleAccess="Staff" className="text-secondary" />
                    )}
                </div>
                <Link
                    to={createPartnerRoute(PartnerRoutes.WORK_PROFILE).replace(
                        ':wkpfId',
                        String(partner.wkpf_id)
                    )}
                    className="text-decoration-none p-0 cursor-pointer"
                >
                    <Row>
                        <Col
                            className="text-center col-lg-2 d-flex align-items-start justify-content-center"
                            lg={2}
                        >
                            <PartnerAvatar partner={partner} width={120} height={120} />
                        </Col>

                        <Col lg={10}>
                            <div className="d-flex justify-content-start">
                                <LineClampText
                                    maxLines={1}
                                    title={'Name'}
                                    className={'card-subtitle fw-semibold h2'}
                                    style={{ color: '#505050' }}
                                >
                                    {partner.wkpf_name}
                                </LineClampText>
                            </div>
                            <div className="d-flex justify-content-start">
                                <LineClampText
                                    maxLines={1}
                                    title="Location (Country, Region, City)"
                                    className="text-secondary mb-2"
                                >
                                    {CountryRegionData.find(
                                        ([, key]) => key === partner.wkpf_country
                                    )?.[0]
                                        ? CountryRegionData.find(
                                              ([, key]) => key === partner.wkpf_country
                                          )?.[0].toUpperCase()
                                        : ''}
                                    {CountryRegionData.find(
                                        ([, key]) => key === partner.wkpf_country
                                    )?.[0] && partner.wkpf_region
                                        ? ', ' + partner.wkpf_region?.toUpperCase()
                                        : partner.wkpf_region
                                        ? partner.wkpf_region?.toUpperCase()
                                        : ''}
                                    {(CountryRegionData.find(
                                        ([, key]) => key === partner.wkpf_country
                                    )?.[0] ||
                                        partner.wkpf_region) &&
                                    partner.wkpf_city
                                        ? ', ' + partner.wkpf_city?.toUpperCase()
                                        : partner.wkpf_city
                                        ? partner.wkpf_city?.toUpperCase()
                                        : ''}
                                </LineClampText>
                            </div>
                            <hr className="m-0 mb-2 text-secondary"></hr>
                            <div className="d-flex justify-content-start">
                                <LineClampText
                                    maxLines={2}
                                    title={'description'}
                                    className={'text-secondary mb-3'}
                                >
                                    {partner.wkpf_desc ?? 'Description not available'}
                                </LineClampText>
                            </div>
                            <Row className="">
                                <LineClampText
                                    maxLines={1}
                                    className="text-sm text-secondary d-flex align-items-center text-uppercase"
                                >
                                    services provided:&nbsp;
                                    {partnerInfo.reduce((prev, curr) => {
                                        if (curr[0]) {
                                            prev.push(curr);
                                        }
                                        return prev;
                                    }, [] as PartnerTypesInterface[]).length !== 0 ? (
                                        <>
                                            {partnerInfo.map(([field, , Icon, label], key) =>
                                                field === 1 ? (
                                                    <TypeLabel
                                                        key={key}
                                                        Icon={Icon}
                                                        label={label}
                                                    />
                                                ) : (
                                                    <span hidden key={key}></span>
                                                )
                                            )}
                                        </>
                                    ) : (
                                        <span>Information not available</span>
                                    )}
                                </LineClampText>
                            </Row>
                            <Row>
                                <LineClampText
                                    maxLines={2}
                                    title={'Service Regions'}
                                    className={'text-secondary mb-0'}
                                >
                                    SERVICE REGIONS:&nbsp;
                                    {partner.wkpf_serviceregions ?? 'Information not available'}
                                </LineClampText>
                            </Row>
                        </Col>
                    </Row>
                </Link>
            </Card.Body>
            {/*  */}
        </Card>
    );
};

interface PartnerTabContentInterface extends CardProps {
    cardBodyClassName?: string;
    style?: React.CSSProperties;
    children: React.ReactNode;
}

export const PartnerTabContent = ({
    cardBodyClassName,
    style,
    children,
    ...props
}: PartnerTabContentInterface) => {
    return (
        <Card
            style={{ borderRadius: '24px', borderTopLeftRadius: 0, ...style }}
            className="border-0 shadow"
            {...props}
        >
            <Card.Body className={cardBodyClassName}>{children}</Card.Body>
        </Card>
    );
};

export function isUserAdmin(
    workProfile: Butlerr.Work.WorkProfile
) {
    return workProfile.isOwner === 1 || workProfile.isAdmin === 1;
}

export default AllPartnersPage;
