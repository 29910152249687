import { SvgIconComponent, Done } from "@mui/icons-material";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import LineClampText from "./LineClampText";

export interface ButlerrCardItem {
    content?:
    |   React.ReactNode
    |   (() => React.ReactNode);
    title: string;
    Icon?: React.FC<React.SVGProps<SVGSVGElement>> | SvgIconComponent;
}

export interface ButlerrCardProps<I extends ButlerrCardItem> extends React.HTMLAttributes<HTMLDivElement> {
    items:
    |   I
    |   I[];

    toggleType?: 'dropdown' | 'tabs';
    
    rightSide?:
    |   React.ReactNode
    |   ((activeItem: I) => React.ReactNode);

    children?:
    |   React.ReactNode
    |   ((activeItem: I) => React.ReactNode);

    activeIndex?: number;
    onActiveItemChange?: (activeItem: I, index: number) => void;

    cardProps?:
    |   React.HTMLAttributes<HTMLDivElement>
    |   ((activeItem: I) => React.HTMLAttributes<HTMLDivElement>);
}

export default function ButlerrCard<I extends ButlerrCardItem>({
    items: _items,
    activeIndex: _activeIndex,
    rightSide,
    cardProps,
    children,
    onActiveItemChange,
    toggleType = 'dropdown',
    ...props
} : ButlerrCardProps<I>) {

    const items = Array.isArray(_items) ? _items: [_items];

    const [ activeIndex, setActiveIndex ] = useState(0);

    useEffect(() => {
        if (_activeIndex !== undefined && _activeIndex !== -1) {
            setActiveIndex(_activeIndex);
        }
    }, [_activeIndex])

    if (!items[activeIndex]) {
        return <></>
    }

    const { Icon, title, content } = items[activeIndex];

    const headerClasses = "h-100 me-4 page-header d-flex align-items-center justify-content-start text-white position-relative";

    const bodyProps = typeof cardProps === 'function' ?
        cardProps(items[activeIndex])
        : cardProps;

    return (
        <div {...props}>
            <div className="d-flex justify-content-between align-items-center text-break">
                {
                    items.length === 1 ? (
                        <div className={headerClasses}>
                                {Icon !== undefined && (
                                <Icon
                                    className="me-2"
                                    fontSize="small"
                                ></Icon>
                            )}
                            <LineClampText className="mb-0 text-uppercase fw-bold" maxLines={1}>{title}</LineClampText>
                        </div>
                    ) : toggleType === 'dropdown' ? (
                        <Dropdown className={headerClasses}>
                            <Dropdown.Toggle className="bg-transparent border-0 p-0 text-uppercase fw-bold">
                                {Icon !== undefined && (
                                    <Icon
                                        className="me-2"
                                        fontSize="small"
                                    ></Icon>
                                )}
                                <span>{title}</span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {
                                    items.map(({ Icon, title }, idx) => (
                                        <Dropdown.Item key={idx} onClick={() => {
                                            onActiveItemChange?.(items[idx], idx);
                                            setActiveIndex(idx);
                                        }}>
                                            {Icon !== undefined && (
                                                <Icon
                                                    className="me-2"
                                                    fontSize="small"
                                                ></Icon>
                                            )}
                                            <span>{title}</span>
                                            {
                                                idx === activeIndex && (
                                                    <Done
                                                        fontSize="small"
                                                        className="ms-2 text-success"
                                                    />
                                                )
                                            }
                                        </Dropdown.Item>
                                    ))
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    ) : (
                        <div className="d-flex flex-nowrap justify-content-start align-items-end">
                            {
                                items.map(({ Icon, title }, idx) => (
                                    <button
                                        key={idx}
                                        onClick={() => {
                                            onActiveItemChange?.(items[idx], idx);
                                            setActiveIndex(idx);
                                        }}
                                        className={classNames(
                                            headerClasses,
                                            "border-0 text-start",
                                            idx !== activeIndex && 'inactive',
                                        )}
                                        style={{
                                            //first item doesn't need to shift
                                            marginLeft: idx === 0 ? 0 :'-1.5rem',
                                            //if active, on top, else show first ones on top
                                            zIndex: idx !== activeIndex ? items.length - idx : items.length + 1,
                                            //shadow on right edge to see separate tabs better
                                            filter: idx !== activeIndex ? "drop-shadow(1px 0px 0px var(--bs-gray-400))" : undefined
                                        }}
                                    >
                                            {Icon !== undefined && (
                                            <Icon
                                                className="me-2"
                                                fontSize="small"
                                            ></Icon>
                                        )}
                                        <LineClampText className="mb-0 text-uppercase fw-bold" maxLines={1}>{title}</LineClampText>
                                    </button>
                                ))
                            }
                        </div>
                    )
                }

                {rightSide !== undefined && (
                    <div className="h-100 flex-shrink-0">
                        { typeof rightSide === 'function' ? rightSide(items[activeIndex]) : rightSide }
                    </div>
                )}
            </div>
            
            <div
                {...bodyProps}
                className={classNames("shadow-sm", bodyProps?.className)}
                style={{
                    borderRadius: '12px',
                    borderTopLeftRadius: 0,
                    padding: "1rem",
                    background: 'var(--white)',
                    ...bodyProps?.style
                }}
            >
                { children !== undefined &&
                    typeof children === 'function' ?
                        children(items[activeIndex])
                        : children
                }
                { typeof content === 'function' ? content() : content }
            </div>
        </div>
    )
}