import { Butlerr } from "../types/butlerr";
import useButlerrAPI, { ButlerrMutationOptions, useButlerrMutation } from "./useButlerrAPI";

//Helper functions
export const folderFromUser = (user: Pick<Butlerr.User, 'doct_id' | 'user_id' | 'user_socialhandle'>) : Butlerr.Document.Folder => ({
    doct_id: user.doct_id,
    asst_id: null,
    user_id: user.user_id,
    doct_name: user.user_socialhandle,
    doct_sys: 1,
    doct_parentid: null
});
export const folderFromAsset = (asset: Pick<Butlerr.Asset, 'doct_id' | 'asst_id' | 'user_id' | 'asst_name'>) : Butlerr.Document.Folder => ({
    doct_id: asset.doct_id,
    asst_id: asset.asst_id,
    user_id: asset.user_id,
    doct_name: asset.asst_name,
    doct_sys: 1,
    doct_parentid: null
});

export type Doc = {
    folders: Butlerr.Document.Folder[],
    files: Butlerr.Document.File[]
}

export const DocumentQueries = {
    DOCUMENTS: (parentTreeId: number) => ['documents', parentTreeId] as const,
    ASSET_IMAGES: (assetId: number) => ['documents', 'assets', assetId, 'images'] as const
}

export function useDocuments(parentTreeId: number, enabled = true) {
    const url = `/api/documents/folders/${parentTreeId}`;
    return useButlerrAPI<Doc>(DocumentQueries.DOCUMENTS(parentTreeId), url, true, {
        enabled
    });
}

export function useAssetImages(assetId: number, enabled = true) {
    return useButlerrAPI<Butlerr.Document.File[]>(DocumentQueries.ASSET_IMAGES(assetId), `/api/documents/assets/${assetId}/images`, true, {
        enabled,
        refetchOnMount: 'always'
    });
}

type FolderForm = {
    doct_parentid: number;
    doct_name: string;
}

type CreateDocumentForm =
&   Partial<Pick<Butlerr.Document.File, 'doc_name' | 'doc_desc'>>
&   { document: Blob }

type CreateAssetImageForm =
&   Pick<Butlerr.Asset, 'asst_id'>
&   { document: Blob }

type EditFileForm = Pick<Butlerr.Document.File, 'doc_id' | 'doct_parentid'> & Partial<Pick<Butlerr.Document.File, 'doc_name' | 'doc_desc'>>

export const DocumentMutations = {
    CREATE_ASSET_IMAGE: ({ asst_id, ...body } : CreateAssetImageForm) : ButlerrMutationOptions => ({
        url: `/api/documents/assets/${asst_id}/images`,
        method: "POST",
        requestBody: body,
        useFormData: true,
        queryKey: DocumentQueries.ASSET_IMAGES(asst_id)
    }),
    CREATE: (doc: CreateDocumentForm & Pick<Butlerr.Document.File, 'doct_parentid'>) : ButlerrMutationOptions => ({
        url: '/api/documents',
        method: 'POST',
        requestBody: doc,
        useFormData: true,
        queryKey: DocumentQueries.DOCUMENTS(doc.doct_parentid),
        refetchData: true,
        updater: {
            partialMatch: true,
            queryKey: DocumentQueries.DOCUMENTS(-1).slice(0, -1),
            action: (prev: Doc | Butlerr.Document.File[]) => {
                if (Array.isArray(prev)) return prev;
                return {
                    ...prev,
                    folders: prev.folders.map((folder) => folder.doct_id !== doc.doct_parentid ? folder : ({
                        ...folder,
                        items: (folder.items ?? 0) + 1
                    }))
                }
            }
        }
    }),
    CREATE_FOLDER: (body: FolderForm) : ButlerrMutationOptions => ({
        url: '/api/documents/folders',
        method: 'POST',
        requestBody: body,
        queryKey: DocumentQueries.DOCUMENTS(body.doct_parentid),
        refetchData: true,
        updater: {
            partialMatch: true,
            queryKey: DocumentQueries.DOCUMENTS(-1).slice(0, -1),
            action: (prev: Doc | Butlerr.Document.File[]) => {
                if (Array.isArray(prev)) return prev;
                return {
                    ...prev,
                    folders: prev.folders.map((folder) => folder.doct_id !== body.doct_parentid ? folder : ({
                        ...folder,
                        items: (folder.items ?? 0) + 1
                    }))
                }
            }
        }
    }),
    DOWNLOAD: (documentId: number) : ButlerrMutationOptions => ({
        url: `/api/documents/content/${documentId}`,
        method: 'POST'
    }),
    MOVE_FILE: ({ file, destinationFolder } : { file: Butlerr.Document.File; destinationFolder: number }) : ButlerrMutationOptions => ({
        url: `/api/documents/${file.doc_id}/folders`,
        method: "PUT",
        requestBody: { doct_parentid: destinationFolder },
        updater: [
            {
                optimistic: true,
                //remove from old folder
                queryKey: DocumentQueries.DOCUMENTS(file.doct_parentid),
                action: (d: Doc) => ({
                    ...d,
                    files: d.files.filter(f => f.doc_id !== file.doc_id)
                })
            },
            {
                optimistic: true,
                //add to new folder
                queryKey: DocumentQueries.DOCUMENTS(destinationFolder),
                action: (d: Doc) => ({
                    ...d,
                    files: [
                        ...d.files,
                        {
                            ...file,
                            doct_parentid: destinationFolder
                        }
                    ]
                })
            },
            {
                partialMatch: true,
                queryKey: DocumentQueries.DOCUMENTS(-1).slice(0, -1),
                action: (prev: Doc | Butlerr.Document.File[]) => {
                    if (Array.isArray(prev)) return prev;
                    return {
                        ...prev,
                        folders: prev.folders.map((folder2) => ({
                            ...folder2,
                            items: (folder2.items ?? 0) + (
                                folder2.doct_id === file.doct_parentid ? (-1)
                                : folder2.doct_id === destinationFolder ? (1) : 0
                            )
                        }))
                    }
                }
            }
        ]
    }),
    MOVE_FOLDER: ({ folder, destinationFolder } : { folder: Butlerr.Document.Folder; destinationFolder: number }) : ButlerrMutationOptions => ({
        url: `/api/documents/folders/${folder.doct_id}/folders`,
        method: "PUT",
        requestBody: { doct_parentid: destinationFolder },
        updater: [
            {
                optimistic: true,
                //remove from old folder
                queryKey: DocumentQueries.DOCUMENTS(folder.doct_parentid ?? -1),
                action: (d: Doc) => ({
                    ...d,
                    folders: d.folders.filter(f => f.doct_id !== folder.doct_id)
                })
            },
            {
                optimistic: true,
                //add to new folder
                queryKey: DocumentQueries.DOCUMENTS(destinationFolder),
                action: (d: Doc) => ({
                    ...d,
                    folders: [
                        ...d.folders,
                        {
                            ...folder,
                            doct_parentid: destinationFolder
                        }
                    ]
                })
            },
            {
                partialMatch: true,
                queryKey: DocumentQueries.DOCUMENTS(-1).slice(0, -1),
                action: (prev: Doc | Butlerr.Document.File[]) => {
                    if (Array.isArray(prev)) return prev;
                    return {
                        ...prev,
                        folders: prev.folders.map((folder2) => ({
                            ...folder2,
                            items: (folder2.items ?? 0) + (
                                folder2.doct_id === folder.doct_parentid ? (-1)
                                : folder2.doct_id === destinationFolder ? (1) : 0
                            )
                        }))
                    }
                }
            }
        ]
    }),
    EDIT_FOLDER: ({ doct_id, doct_parentid,  ...body }: Pick<Butlerr.Document.Folder, 'doct_id' | 'doct_parentid' | 'doct_name'>) : ButlerrMutationOptions<Doc> => ({
        url: `/api/documents/folders/${doct_id}`,
        method: "PUT",
        requestBody: body,
        updater: {
            optimistic: true,
            queryKey: DocumentQueries.DOCUMENTS(doct_parentid ?? -1),
            action: (d) => ({
                ...d,
                folders: d.folders.map(f => f.doct_id !== doct_id ? f : ({ ...f, ...body }))
            })
        }
    }),
    EDIT_FILE: ({ doc_id, doct_parentid, ...body }: EditFileForm) : ButlerrMutationOptions<Doc> => ({
        url: `/api/documents/${doc_id}`,
        method: "PUT",
        requestBody: body,
        updater: {
            optimistic: true,
            queryKey: DocumentQueries.DOCUMENTS(doct_parentid),
            action: (d) => ({
                ...d,
                files: d.files.map(f => f.doc_id !== doc_id ? f : ({ ...f, ...body }))
            })
        }
    }),
    DELETE_FOLDER: ({ doct_id, doct_parentid } : Pick<Butlerr.Document.Folder, 'doct_id' | 'doct_parentid'>) : ButlerrMutationOptions => ({
        url: `/api/documents/folders/${doct_id}`,
        method: "DELETE",
        updater: [{
            queryKey: DocumentQueries.DOCUMENTS(doct_parentid ?? -1),
            action: (d: Doc) => ({
                ...d,
                folders: d.folders.filter(f => f.doct_id !== doct_id)
            })
        }, {
            partialMatch: true,
            queryKey: DocumentQueries.DOCUMENTS(-1).slice(0, -1),
            action: (prev: Doc | Butlerr.Document.File[]) => {
                if (Array.isArray(prev)) return prev;
                return {
                    ...prev,
                    folders: prev.folders.map((folder) => folder.doct_id !== doct_parentid ? folder : ({
                        ...folder,
                        items: (folder.items ?? 0) - 1
                    }))
                }
            }
        }]
    }),
    DELETE_FILE: ({ doc_id, doct_parentid } : Pick<Butlerr.Document.File, 'doc_id' | 'doct_parentid'>) : ButlerrMutationOptions => ({
        url: `/api/documents/${doc_id}`,
        method: "DELETE",
        updater: [{
            queryKey: DocumentQueries.DOCUMENTS(doct_parentid),
            action: (d: Doc) => ({
                ...d,
                files: d.files.filter(f => f.doc_id !== doc_id)
            })
        }, {
            partialMatch: true,
            queryKey: DocumentQueries.DOCUMENTS(-1).slice(0, -1),
            action: (prev: Doc | Butlerr.Document.File[]) => {
                if (Array.isArray(prev)) return prev;
                return {
                    ...prev,
                    folders: prev.folders.map((folder) => folder.doct_id !== doct_parentid ? folder : ({
                        ...folder,
                        items: (folder.items ?? 0) - 1
                    }))
                }
            }
        }]
    })
};

export function useDocumentMutations<Key extends keyof typeof DocumentMutations>(key: Key) {
    //You won't get type suggestions in the mutate function without this assertion
    const mutationFn = DocumentMutations[key] as (params: Parameters<typeof DocumentMutations[Key]>[0]) => ButlerrMutationOptions;
    return useButlerrMutation(mutationFn, undefined, key === 'CREATE') //track progress for upload
}
