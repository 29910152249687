import { useEffect } from 'react';

const appName = String(process.env.REACT_APP_NAME);

export default function useAppTitle(title: string, condition = true) {
  useEffect(() => {
    const prevTitle = document.title;
    if (condition) {
      document.title = `${title} | ${appName}`;
    }
    return () => {
      document.title = prevTitle;
    };
  }, [title, condition]);
}
