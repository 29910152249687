import { Badge } from 'react-bootstrap';

interface SectionHeaderInterface {
    title: string;
    rightSide?: JSX.Element;
}

export const SectionHeader = ({ title, rightSide }: SectionHeaderInterface) => {
    return (
        <div
            className="d-flex justify-content-between align-items-center"
            style={{ marginBottom: '1rem' }}
        >
            <p className="mb-0 fw-semibold">
                <Badge
                    bg="primary-dark"
                    className="text-uppercase"
                    style={{ padding: '0.375rem 0.75rem' }}
                >
                    {title}
                </Badge>
            </p>

            {rightSide}
        </div>
    );
};
