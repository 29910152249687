import { Butlerr } from '../../../types/butlerr';
import { dayDiff, formatCurrency, getCurrencySign, monthDiff } from '../../utils/HelperFunctions';
import AssetKPI, { KPICardInterface } from '../AssetKPI';

interface AssetLeasesKPIInterface {
    leases: Butlerr.Asset.Lease[];
    isLoading: boolean;
    asset: Butlerr.Asset;
}

const AssetLeasesKPI = ({ leases, isLoading, asset }: AssetLeasesKPIInterface) => {
    const calculateAnnualIncome = () => {
        // 1. Filter leases that are active with end date greater than today
        const activeLeases = leases.filter(
            (lease) =>
                lease.leas_status === 1 &&
                new Date(lease.leas_start).setHours(0, 0, 0, 0) <=
                    new Date().setHours(0, 0, 0, 0) &&
                new Date(
                    lease.leas_end ??
                        new Date(
                            new Date().getFullYear(),
                            new Date().getMonth(),
                            new Date().getDate() + 1
                        ).setHours(0, 0, 0, 0)
                ).setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0)
        );
        //console.log(activeLeases);

        // 2. Calculate annual income for all the leases
        const annualIncome = activeLeases.reduce((total, lease) => {
            const periodAmount = lease.leas_amt;
            let annualAmount = 0;
            switch (lease.leas_amtperiod) {
                case 'W':
                    annualAmount = periodAmount * 52;
                    break;
                case 'M':
                    annualAmount = periodAmount * 12;
                    break;
                case 'Q':
                    annualAmount = periodAmount * 4;
                    break;
                case 'A':
                    annualAmount = periodAmount * 1;
                    break;
                case 'E':
                    annualAmount =
                        (periodAmount * 365) /
                        (dayDiff(
                            new Date(lease.leas_start ?? new Date()),
                            new Date(lease.leas_end ?? new Date())
                        ) +
                            1);
            }
            total += annualAmount;
            return total;
        }, 0);
        //console.log(annualIncome);
        return annualIncome;
    };
    const calculateGrossYieldPercentage = () => {
        // 3. Calculate yield %
        const grossYieldPercentage = (calculateAnnualIncome() / asset.asst_purprice) * 100;
        //console.log(grossYieldPercentage);

        return `${grossYieldPercentage.toFixed(2)}%`;
    };
    const calculateGrossYieldMoney = () => {
        return `${getCurrencySign(asset.asst_currency)}${formatCurrency(calculateAnnualIncome())}`;
    };
    const calculateLast12MonthOccupancyPercentage = () => {
        if (leases.length === 0 || asset.asst_settledate === null) return '0.00%';

        const assetPurchaseDate = new Date(new Date(asset.asst_settledate).setHours(0, 0, 0, 0));

        // 1. Calculate the number of months of ownership
        const ownershipMonths = monthDiff(
            assetPurchaseDate,
            new Date(new Date().setHours(0, 0, 0, 0))
        );
        //console.log(ownershipMonths);

        // 2. Calculate the range
        const today = new Date();
        const rangeStartDate = new Date(
            today.getFullYear(),
            today.getMonth() - (ownershipMonths < 12 ? ownershipMonths : 12),
            ownershipMonths < 12 ? assetPurchaseDate.getDate() : 1
        );
        const rangeEndDate = new Date(today.getFullYear(), today.getMonth(), 0);
        //console.log(rangeStartDate);
        //console.log(rangeEndDate);

        // 3. Filter leases that are in the range & sort leases by start date
        const filteredLeases = leases
            .filter((lease) => {
                // Check overlap: (@E2 >= S1 AND @S2 <= E1)
                if (
                    new Date(lease.leas_end ?? new Date()).setHours(0, 0, 0, 0) >=
                        rangeStartDate.setHours(0, 0, 0, 0) &&
                    new Date(lease.leas_start).setHours(0, 0, 0, 0) <=
                        rangeEndDate.setHours(0, 0, 0, 0)
                ) {
                    return true;
                }
                return false;
            })
            .sort((a, b) => {
                return (
                    new Date(a.leas_start).setHours(0, 0, 0, 0) -
                    new Date(b.leas_start).setHours(0, 0, 0, 0)
                );
            });
        //console.log(filteredLeases);

        const totalFilteredLeases = filteredLeases.length;
        if (totalFilteredLeases === 0) return '0.00%';

        // 4. Calculate the number of days vacant within the range
        let totalVacantDays = 0;
        let previousLeaseEndDate = null;

        for (let i = 0; i < totalFilteredLeases; i++) {
            const lease = filteredLeases[i];
            const leaseStartDateInMs = new Date(lease.leas_start).setHours(0, 0, 0, 0);
            // If first lease item,
            // check if lease start date is before or on the rangeStartDate date.
            // Else,
            // check if lease start date is before or on the previous lease end date.
            if (
                previousLeaseEndDate === null
                    ? leaseStartDateInMs <= rangeStartDate.setHours(0, 0, 0, 0)
                    : leaseStartDateInMs <= previousLeaseEndDate
            ) {
                // Break out of the loop if lease end date is greater or equal to rangeEndDate.
                if (
                    new Date(lease.leas_end ?? new Date()).setHours(0, 0, 0, 0) >=
                    rangeEndDate.setHours(0, 0, 0, 0)
                ) {
                    break;
                } else {
                    // Else, update previous lease end date variable.
                    previousLeaseEndDate = new Date(lease.leas_end ?? new Date()).setHours(
                        0,
                        0,
                        0,
                        0
                    );
                    // Check if last item in the array
                    if (i === totalFilteredLeases - 1) {
                        // Calculate the number of vacant days between the lease end date and rangeEndDate date
                        const vacantDays = dayDiff(
                            new Date(previousLeaseEndDate),
                            new Date(rangeEndDate.setHours(0, 0, 0, 0))
                        );
                        totalVacantDays += vacantDays;
                    }
                }
            } else {
                // If first lease item,
                // calculate the number of vacant days between rangeStartDate date and lease start date.
                // Else,
                // calculate the number of vacant days between previous lease end day and current lease start date.
                const start =
                    previousLeaseEndDate === null ? rangeStartDate : new Date(previousLeaseEndDate);
                const end = new Date(lease.leas_start);
                const vacantDays = dayDiff(start, end) - (previousLeaseEndDate === null ? 0 : 1);
                // If first lease item, 'start' date is considered 'vacant' due to the 'if' check on line 122.
                // Thus, we do not need to minus an extra day.
                // For example:
                //  assetPurchaseDate:   02 Nov 2021
                //  Lease start:         01 Dec 2021
                //  Lease end:           30 Nov 2022
                //  Today:               (any date in Jan 2022)
                //  rangeStartDate:      02 Nov 2021
                //  rangeEndDate:        31 Dec 2021
                //  vacantDays:          29 days (02 Nov 2021 to 30 Nov 2021)
                //
                // We only need to minus an extra day if there was a previous lease
                // and we know that the tenant stayed until the lease end date.
                // In this case, the lease end date is considered 'occupied'.
                // Thus, we need to minus an extra day.

                // console.log(start);
                // console.log(end);
                // console.log(vacantDays);
                //console.log('----------');
                totalVacantDays += vacantDays;

                // Set the previous lease end date
                previousLeaseEndDate = new Date(lease.leas_end ?? new Date()).setHours(0, 0, 0, 0);

                // If the current lease end date is greater or equal to rangeEndDate's date,
                // break the loop since there is no need to calculate vacant days anymore.
                if (
                    new Date(lease.leas_end ?? new Date()).setHours(0, 0, 0, 0) >=
                    rangeEndDate.setHours(0, 0, 0, 0)
                ) {
                    break;
                }

                // Check if last item in the array
                if (i === totalFilteredLeases - 1) {
                    // Calculate the number of vacant days between the lease end date and rangeEndDate date
                    const vacantDays = dayDiff(
                        new Date(previousLeaseEndDate),
                        new Date(rangeEndDate.setHours(0, 0, 0, 0))
                    );
                    totalVacantDays += vacantDays;
                }
            }
        }
        //console.log(totalVacantDays + ' vacant days');
        //console.log('previous lease end date: ' + new Date(previousLeaseEndDate ?? ''));

        // 5. Calculate the number of days from purchase date to rangeEndDate
        const ownershipDays = dayDiff(assetPurchaseDate, rangeEndDate) + 1;
        //console.log(ownershipDays);

        // 6. Calculate occupancy percentage
        const occupancyPercentage =
            (1 - totalVacantDays / (ownershipMonths < 12 ? ownershipDays : 365)) * 100;
        //console.log(totalVacantDays);

        //console.log(occupancyPercentage + '%');
        return `${occupancyPercentage.toFixed(2)}%`;
    };
    const kpis: KPICardInterface[] = [
        {
            title: 'Indicative Gross Yield %',
            data: isLoading ? undefined : calculateGrossYieldPercentage(),
            tooltipText:
                "Calculated as total annual rental income received from a property before tax, expressed as a percentage of the property's purchase cost. The higher the yield, the better.",
        },
        {
            title: 'Indicative Gross Yield $',
            data: isLoading ? undefined : calculateGrossYieldMoney(),
            tooltipText:
                'Calculated as total annual rental income received from a property before tax.',
        },
        {
            title: 'Last 12 Mth Occupancy %',
            data: isLoading ? undefined : calculateLast12MonthOccupancyPercentage(),
            tooltipText:
                'Calculated as a percentage, based on the number of months occupied (excluding current month if incomplete month), of the preceding 12 months. Where the ownership is less than 12 months, it is based on the percentage of the number of months occupied over number of ownership month. ',
        },
    ];
    return <AssetKPI data={kpis} xs={1} lg={2} xl={3} />;
};

export default AssetLeasesKPI;
