import { useAuth0 } from '@auth0/auth0-react';
import { Formik } from 'formik';
import { Button, Col, Form, Row } from 'react-bootstrap';
import * as yup from 'yup';
import { BootstrapRadio } from '../../utils/FormikBootstrapInputs';
import { ProfileForm, useProfileCompeletion } from './OnboardingModal';

export type UserPurposeKey = keyof typeof UserPurpose;
enum UserPurpose {
    WORK = 'Interact and service my clients on propcircle.co!',
    SOCIAL = 'Socialise and learn about property investing!',
    MANAGE = 'Manage my property assets!',
    SHARE = 'Share my experience and knowledge about property!',
}

const WelcomePage = () => {
    const { logout } = useAuth0();

    const { email, profileForm, setProfileForm, goNextPage, isLoading } = useProfileCompeletion();

    const allowedKeys = {
        purpose: Object.keys(UserPurpose),
    };

    const allowedLabels = {
        purpose: Object.values(UserPurpose),
    };

    const initialValues: Pick<ProfileForm, 'purpose'> = {
        purpose: profileForm.purpose,
    };

    const validationSchema = yup.object().shape({
        purpose: yup.string().oneOf(allowedKeys.purpose).required('Required'),
    });

    const onSubmit = (values: Pick<ProfileForm, 'purpose'>) => {
        setProfileForm((prev) => ({
            ...prev,
            ...values,
        }));

        goNextPage();
    };

    return (
        <>
            <Row>
                <h1 className="text-muted">
                    Welcome to&nbsp;
                    <span className="text-dark">prop</span>
                    <span className="text-primary fs-3">CIRCLE</span>
                    <span className="fw-normal fs-3">.CO</span>
                </h1>

                <p>
                    The complete cloud platform to plan, manage and learn your property portfolio.
                    <br />
                    You are currently logged in as <span className="fw-semibold">{email}</span>.
                </p>
            </Row>

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({ handleSubmit }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <p className="mb-1 fs-5">
                            I'm excited to use <b>propcircle.co</b> to...
                        </p>

                        <Row className="mb-3 g-3">
                            <Form.Group as={Col} lg>
                                <BootstrapRadio
                                    name="purpose"
                                    values={allowedKeys.purpose}
                                    labels={allowedLabels.purpose}
                                    disabled={isLoading}
                                />
                            </Form.Group>
                        </Row>

                        <Button
                            variant="outline-primary"
                            className="me-2"
                            onClick={() => logout({ returnTo: window.location.origin })}
                            disabled={isLoading}
                        >
                            Cancel
                        </Button>

                        <Button type="submit" disabled={isLoading}>
                            Let's Start!
                        </Button>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default WelcomePage;
