import { Butlerr } from '../types/butlerr';
import { assetBaseRoute } from './asset.service';
import useButlerrAPI, { ButlerrMutationOptions, useButlerrMutation } from './useButlerrAPI';

export const CashflowQueries = {
    CASHFLOWS: (assetId: number) => ['assets', assetId, 'cashflows'] as const,
};

export function useCashflows(assetId: number) {
    return useButlerrAPI<Butlerr.Asset.Cashflow[]>(
        CashflowQueries.CASHFLOWS(assetId),
        `${assetBaseRoute}/${assetId}/cashflows/`
    );
}

type CashflowMutationInterface = Omit<Butlerr.Asset.Cashflow, keyof Butlerr.Document.File>

const CashflowMutations = {
    CREATE: ({
        asst_id,
        ...cashflow
    }: Omit<
        CashflowMutationInterface,
        'ascf_id' | 'ascf_refid'
    >): ButlerrMutationOptions => ({
        url: `${assetBaseRoute}/${asst_id}/cashflows/`,
        method: 'POST',
        requestBody: cashflow,
        queryKey: CashflowQueries.CASHFLOWS(asst_id),
    }),

    EDIT: ({
        asst_id,
        ascf_id,
        ...cashflow
    }: Omit<CashflowMutationInterface, 'ascf_refid'>): ButlerrMutationOptions => ({
        url: `${assetBaseRoute}/${asst_id}/cashflows/${ascf_id}`,
        method: 'PUT',
        requestBody: cashflow,
        queryKey: CashflowQueries.CASHFLOWS(asst_id),
    }),

    DELETE: ({
        asst_id,
        ascf_id,
    }: Pick<CashflowMutationInterface, 'asst_id' | 'ascf_id'>): ButlerrMutationOptions => ({
        url: `${assetBaseRoute}/${asst_id}/cashflows/${ascf_id}`,
        method: 'DELETE',
        queryKey: CashflowQueries.CASHFLOWS(asst_id),
    }),
};

export function useCashflowMutations<Key extends keyof typeof CashflowMutations>(
    key: Key
) {
    //You won't get type suggestions in the mutate function without this assertion
    const mutationFn = CashflowMutations[key] as (
        params: Parameters<typeof CashflowMutations[Key]>[0]
    ) => ButlerrMutationOptions;
    return useButlerrMutation(mutationFn);
}
