import classNames from "classnames";
import { ReactComponent as Placeholder } from "../../assets/placeholder_table.svg";

interface EmptyMessageProps extends React.HTMLAttributes<HTMLDivElement> {
    message: string | JSX.Element;
}
export default function EmptyMessage({ message, className, ...props } : EmptyMessageProps) {
    return (
        <div className={classNames("d-flex justify-content-center align-items-center flex-column", className)} {...props}>
            <Placeholder />
            <div className="mt-3">{ message }</div>
        </div>
    )
}