import { DeleteOutlineRounded, DeleteSweepRounded, DownloadOutlined, DriveFileMoveOutlined, DriveFileRenameOutlineOutlined, EditOutlined, FolderOpen, MoreVert } from "@mui/icons-material";
import classNames from "classnames";
import { Dropdown } from "react-bootstrap";
import { Doc, DocumentQueries, useDocumentMutations } from "../../services/doc.service";
import { useDownloadWithPostToken } from "../../services/useButlerrAPI";
import IconButton from "../utils/IconButton";
import folderIcon from "../../assets/icons/folder.png";
import fileIcon from "../../assets/icons/file.png";
import styles from "./documents.module.css";
import { useState, useRef, useEffect } from "react";
import useModal from "../../hooks/useModal";
import { EditModal, DocumentSelectModal } from "./DocsModal";
import { Butlerr } from "../../types/butlerr";
import DestructiveModal from "../utils/DestructiveModal";
import { useDocumentsContext } from "./Docs";
import { useQueryClient } from "react-query";

export interface FolderItemProps extends React.HTMLAttributes<HTMLDivElement> {
    name: string;
    isEditing: boolean;
    onEditDone: (value: string, source: 'key' | 'blur') => void;

    onEditStart?: () => void;
    folder?: Butlerr.Document.Folder;
}

export function GridFolderItem({ name: initialName, className, isEditing = false, onEditDone, onEditStart, folder, ...props } : FolderItemProps) {

    const { selection, selectedRoot, setTree } = useDocumentsContext();

    const [ name, setName ] = useState(initialName);

    useEffect(() => {
        setName(p => !p ? initialName : p);
    }, [ initialName, isEditing ])
    useEffect(() => {
        setName(initialName);
    }, [initialName])

    const inputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        //focus on input & select text on edit
        if (isEditing) {
            inputRef.current?.focus();
            inputRef.current?.setSelectionRange(0, inputRef.current.value.length);
        }
    }, [isEditing])

    //dropdown state
    const [ show, setShow ] = useState(false);

    const handleRightClickDropdownToggle = (ev : React.MouseEvent<HTMLDivElement>) => {
        if (selection !== undefined) return;
        setShow(state => {
            //if already toggled on, close the dropdown & continue browser default
            if (!state) {
                ev.preventDefault();
            }
            return !state;
        })
    }

    //delete state
    const { mutateAsync: deleteFolder } = useDocumentMutations('DELETE_FOLDER');
    const [ deleteModal, openDeleteModal ] = useModal(DestructiveModal, {
        title: <span>Delete <i>{folder?.doct_name ?? ''}</i>?</span>,
        description: "This will delete all the files & subfolders under this directory",
        onConfirm: () => deleteFolder({
            doct_id: folder?.doct_id ?? -1,
            doct_parentid: folder?.doct_parentid ?? -1
        }),
        textToEnterToConfirm: "DELETE",
        className: "modal-layer-2",
        backdropClassName: "modal-layer-2"
    })

    //move state
    //move states
    const { mutate: moveFolder } = useDocumentMutations('MOVE_FOLDER');
    const { mutate: moveFile } = useDocumentMutations('MOVE_FILE');

    const [ moveModal, openMoveModal ] = useModal(DocumentSelectModal, {
        show: folder !== undefined,
        rootFolder: selectedRoot,
        selectBtnText: "Move",
        onFolderSelect: (destination: Butlerr.Document.Folder, tree: Butlerr.Document.Folder[]) => {
            moveFolder({
                folder: folder!,
                destinationFolder: destination.doct_id
            });
            setTree(tree);
        },
        foldersToHide: folder?.doct_id
    })

    const navigate = () => {
        if (folder !== undefined) {
            setTree(t => [ ...t, folder ])
        }
    }

    const qc = useQueryClient();

    const [ dragTarget, setDragTarget ] = useState(false);

    return (
        <div className={styles.itemContainer}>
            <div
                onContextMenu={handleRightClickDropdownToggle}
                title={!folder ? undefined : 
                    `${folder.doct_name}${folder.items === undefined ? ''
                        : `\n${!folder.items ? 'No' : folder.items} item${folder.items === 1 ? '' : 's'}`}`
                }
                onClick={navigate}
                className={classNames(
                    styles.item,
                    "small cursor-pointer",
                    dragTarget && styles.dragTarget,
                    className
                )}
                //Drag & Drop
                draggable={selection === undefined && folder !== undefined && folder.doct_sys !== 1}
                onDragStart={!folder ? undefined : (ev) => {
                    if (ev.dataTransfer) {
                        setShow(false);
                        ev.dataTransfer.setData("text/doct_id", String(folder?.doct_id));
                        ev.dataTransfer.setData("text/doct_parentid", String(folder?.doct_parentid));
                        /**
                         * We will add this one, just so we can test if the folder being dragged over is itself (in onDragOver)
                         * We can't use `getData` method in `onDragOver`, because the event is in "protected" mode
                         */
                        ev.dataTransfer.setData(`text/doct_id_${folder.doct_id}`, '-');

                        ev.dataTransfer.effectAllowed = "copy";
                    }
                }}
                onDragOver={!folder ? undefined : (ev) => {
                    ev.preventDefault();
                    //If the folder is its own, return
                    if (ev.dataTransfer.types.includes(`text/doct_id_${folder.doct_id}`)) {
                        return;
                    }
                    if (ev.dataTransfer.types.includes('text/doc_id') || ev.dataTransfer.types.includes('text/doct_id')) {
                        ev.dataTransfer.dropEffect = "copy";
                        setDragTarget(true);
                    }
                }}
                onDragLeave={!folder ? undefined : () => setDragTarget(false)}
                onDrop={!folder ? undefined : (ev) => {
                    ev.preventDefault();
                    setDragTarget(false);

                    //get parentid so we can get the query & the source file or folder from it
                    if (ev.dataTransfer.types.includes('text/doct_parentid')) {
                        const parentFolderId = Number(ev.dataTransfer.getData('text/doct_parentid'));
                        if (parentFolderId === folder.doct_id) return;

                        const data = qc.getQueryData<Doc>(DocumentQueries.DOCUMENTS(parentFolderId));
                        
                        if (ev.dataTransfer.types.includes('text/doc_id')) {
                            const fileId = Number(ev.dataTransfer.getData('text/doc_id'));
                            
                            const fileToMove = data?.files.find(f => f.doc_id === fileId);
                            if (fileToMove) {
                                moveFile({
                                    file: fileToMove,
                                    destinationFolder: folder.doct_id
                                })
                            }
                        }
                        else if (ev.dataTransfer.types.includes('text/doct_id')) {
                            const folderId = Number(ev.dataTransfer.getData('text/doct_id'));
                            if (folderId === folder.doct_id) return;

                            const folderToMove = data?.folders.find(f => f.doct_id === folderId);
                            if (folderToMove) {
                                moveFolder({
                                    folder: folderToMove,
                                    destinationFolder: folder.doct_id
                                })
                            }
                        }
                    }
                }}
                {...props}
            >
                <img src={folderIcon} className="p-2" alt={`${name} folder`} draggable={false}></img>
                {
                    !isEditing ? <span title={name} style={{ border: '2px solid transparent' }}>{name}</span> : (
                        <input
                            ref={inputRef}
                            value={name}
                            className="w-100 text-center"
                            onChange={(e) => setName(e.target.value)}
                            onBlur={() => {
                                onEditDone(name, 'blur');
                            }}
                            onKeyUp={(e) => {
                                if (e.key === 'Enter') {
                                    onEditDone(name, 'key');
                                }
                            }}
                        />
                    )
                }
            </div>
            { deleteModal }
            { moveModal }
            {
                selection === undefined && onEditStart !== undefined && (
                    <Dropdown
                        className={styles.dropdown}
                        show={show}
                        onToggle={setShow}
                    >
                        <Dropdown.Toggle
                            as="span"
                            bsPrefix=" "
                            variant="clear"
                        >
                            <IconButton
                                transparent
                                border={false}
                                title="More"
                                Icon={MoreVert}
                                iconHtmlColor="var(--black)"
                                className="fw-normal"
                            />
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="p-0">
                            <Dropdown.Item className="small" onClick={props.onClick ?? navigate}>
                                <FolderOpen fontSize="small" className="me-2" />
                                <span>Open</span>
                            </Dropdown.Item>
                            {
                                folder?.doct_sys !== 1 && (
                                    <>
                                        <Dropdown.Item className="small" onClick={onEditStart}>
                                            <DriveFileRenameOutlineOutlined fontSize="small" className="me-2" />
                                            <span>Rename</span>
                                        </Dropdown.Item>
                                        <Dropdown.Item className="small" onClick={openMoveModal}>
                                            <DriveFileMoveOutlined fontSize="small" className="me-2" />
                                            <span>Move</span>
                                        </Dropdown.Item>
                                        <Dropdown.Item className="small text-danger" onClick={openDeleteModal}>
                                            <DeleteSweepRounded fontSize="small" className="me-2" />
                                            <span>Delete</span>
                                        </Dropdown.Item>
                                    </>
                                )
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                )
            }
        </div>
    )
}

interface FileItemProps extends React.HTMLAttributes<HTMLDivElement> {
    document: Butlerr.Document.File;
    name: string;
    thumbnail: React.ReactNode;
}

export function GridFileItem({ document, name, thumbnail, className, onClick, ...props } : FileItemProps) {

    const { selectedRoot, setTree, selection } = useDocumentsContext();

    const download = useDownloadWithPostToken();
    const onDownload = () => {
        download('DOCUMENTS', document.doc_id);
    };

    const [ editModal, edit ] = useModal(EditModal, {
        document: document,
        className: "modal-layer-2",
        backdropClassName: "modal-layer-2"
    });

    //delete state
    const { mutateAsync: deleteFile } = useDocumentMutations('DELETE_FILE');
    const [ deleteModal, openDeleteModal ] = useModal(DestructiveModal, {
        title: <span>Delete <i>{document.doc_name}</i>?</span>,
        description: "This file will be permanently deleted",
        onConfirm: () => deleteFile({
            doct_parentid: document.doct_parentid,
            doc_id: document.doc_id
        }),
        className: "modal-layer-2",
        backdropClassName: "modal-layer-2"
    })

    //move states
    const { mutate: moveFile } = useDocumentMutations('MOVE_FILE');
    const [ moveModal, openMoveModal ] = useModal(DocumentSelectModal, {
        rootFolder: selectedRoot,
        selectBtnText: "Move",
        onFolderSelect: (folder: Butlerr.Document.Folder, tree: Butlerr.Document.Folder[]) => {
            moveFile({
                file: document,
                destinationFolder: folder.doct_id
            });
            setTree(tree);
        },
    })

    //dropdown state
    const [ show, setShow ] = useState(false);

    const toggleDropdown = (ev : React.MouseEvent<HTMLDivElement>) => {
        if (selection !== undefined) return;
        setShow(state => {
            //if already toggled on, close the dropdown & continue browser default
            if (!state) {
                ev.preventDefault();
            }
            return !state;
        })
    }

    return (
        <div className={styles.itemContainer}>
            <div
                onContextMenu={toggleDropdown}
                onClick={onClick ?? edit}
                className={classNames(styles.item, "position-relative cursor-pointer", className)}
                //Drag & Drop
                draggable={selection === undefined}
                onDragStart={(ev) => {
                    if (ev.dataTransfer) {
                        setShow(false);
                        ev.dataTransfer.setData("text/doc_id", String(document.doc_id));
                        ev.dataTransfer.setData("text/doct_parentid", String(document.doct_parentid));
                        ev.dataTransfer.effectAllowed = "copy";
                    }
                }}
                {...props}
            >
                {thumbnail}
                <span title={name}>{name}</span>
            </div>
            { editModal }
            { deleteModal }
            { moveModal }

            {
                selection === undefined && (
                    <>
                        <Dropdown
                            className={styles.dropdown}
                            show={show}
                            onToggle={setShow}
                        >
                            <Dropdown.Toggle
                                as="span"
                                bsPrefix=" "
                                variant="clear"
                            >
                                <IconButton
                                    transparent
                                    border={false}
                                    title="More"
                                    Icon={MoreVert}
                                    iconHtmlColor="var(--black)"
                                    className="fw-normal"
                                />
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="p-0">
                                <Dropdown.Item className="small" onClick={onDownload}>
                                    <DownloadOutlined fontSize="small" className="me-2" />
                                    <span>Download</span>
                                </Dropdown.Item>
                                <Dropdown.Item className="small" onClick={edit}>
                                    <EditOutlined fontSize="small" className="me-2" />
                                    <span>Edit</span>
                                </Dropdown.Item>
                                <Dropdown.Item className="small" onClick={openMoveModal}>
                                    <DriveFileMoveOutlined fontSize="small" className="me-2" />
                                    <span>Move</span>
                                </Dropdown.Item>
                                <Dropdown.Item className="small text-danger" onClick={openDeleteModal}>
                                    <DeleteOutlineRounded fontSize="small" className="me-2" />
                                    <span>Delete</span>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </>
                )
            }
        </div>
    )
}

export interface ThumbnailProps {
    format: string;
    id: number;
}

export function DocumentThumbnail({ id, format }: ThumbnailProps) {
    //just return placeholder for now
    return <DocumentThumbnailPlaceholder format={format} />

    // return (
    //     <img src={src} className={styles.thumbnail} draggable={false} alt=""></img>
    // )
}

export function DocumentThumbnailPlaceholder({ format, ...props }: Pick<ThumbnailProps, 'format'> & React.HTMLAttributes<HTMLDivElement>) {
    return (
        <div {...props} className={classNames(props.className, styles.placeholder)}>
            <img className={styles.placeholderImage} src={fileIcon} draggable={false} alt=""></img>
            <div>
                {format.toUpperCase()}
            </div>
        </div>
    )
}

export function GridSkeleton() {
    return (
        <>
            {Array<null>(5).fill(null).map((_, idx) => (
                <div
                    key={idx}
                    className={styles.item}
                    style={{
                        pointerEvents: 'none'
                    }}
                >
                    <img
                        alt=""
                        src={folderIcon}
                        width={72}
                        height={60}
                        className="p-2 pe-none"
                        style={{
                            filter: 'grayscale(1) contrast(1.5)',
                        }}
                    />
                    <div
                        style={{ width: 'calc(72px - 1rem)' }}
                        className="skeleton-box mx-2"
                    ></div>
                </div>
            ))}
        </>
    )
}