import { AttachmentFile } from '../../types/custom';

export const serverErrorObject = {
	status: 500,
	message: 'Something went wrong. Please try again later.',
};

export async function _fetch<T = any>(url: RequestInfo, init?: RequestInit) {
	const fetchResult = await fetch(url, init);

	if (!fetchResult.ok) {
		const errorMessage = await fetchResult.text();
		throw new Error(errorMessage || String(fetchResult.status));
	}

	const contentType = fetchResult.headers.get('Content-Type');
	if (contentType === 'application/json' || contentType === 'application/json; charset=utf-8') {
		return (await fetchResult.json()) as T;
	} else if (contentType === 'text/html' || contentType === 'text/html; charset=utf-8') {
		return await fetchResult.text();
	}
}

export function handleCatchBlockError(error: unknown) {
	let errorMessage = serverErrorObject.message;
	if (error instanceof Error && error.message) {
		errorMessage = error.message;
	}
	return errorMessage;
}

export function convertBooleanToBinary(field: boolean | null | undefined) {
	return field ? (field ? 1 : 0) : null;
}

export function convertBinaryToBoolean(field: number) {
	return Boolean(field);
}

export function convertDateToYYYYMMDD(dateString: string | Date | undefined) {
	if (!dateString) return undefined;
	
	let date = typeof dateString === 'string' ? new Date(dateString) : dateString;
	const offset = date.getTimezoneOffset();
	date = new Date(date.getTime() - offset * 60 * 1000);
	return date.toISOString().split('T')[0];
}

export const localizeDate = (date: string | undefined | null) => {
	if (!date) return undefined;
	return new Intl.DateTimeFormat().format(new Date(date));
};

export const formatDate = (str?: string | null, month: 'short' | 'long' = 'short', year: 'short' | 'long' | false = 'long', time = false) => {
	if (!str) {
		return str
	}
	const date = new Date(str);
    if (isNaN(date.getTime())) {
        //invalid date str
        return null
    }

	let value = date.getDate() + ' ' + date.toLocaleDateString('en-US', { month: month })
	if (year !== false) {
		value += " " + date.toLocaleDateString('en-US', { year: year === 'short' ? '2-digit' : 'numeric' });
	}

	if (time) {
		value += " at "
			+ String(date.getHours()).padStart(2, '0')
			+ ":"
			+ String(date.getMinutes()).padStart(2, '0')
			+ ":"
			+ String(date.getSeconds()).padStart(2, '0')
	}

    return value;
}

export const formatDateByTimeDifference = (str: string) => {
	const date = new Date(str);
	if (isNaN(date.getTime())) {
        //invalid date str
        return null
    }

	let diff = (new Date().valueOf() - date.valueOf()) / 1000 // in seconds
	//for less than 1 minute
	if (diff < 60) {
		return "Just now"
	}
	diff /= 60 // in minutes
	
	const steps = [
		[60, 'min'],
		[24, 'h'],
		[30, 'd'],
		[12, 'mth'],
		[NaN, 'y']
	] as const;

	for (let idx = 0; idx < steps.length; idx++) {
		const [ limit, unit ] = steps[idx];

		//if not greater than the limit for current unit, return in current unit
		if (!(diff > limit)) { // not greater will return true for NaN
			return parseInt(diff.toString()) + ' ' + unit + ' ago';
		}
		//else divide by limit to convert to next unit
		diff /= limit;
	}
	//just for typescript, unreachable since the loop will always return at the last iteration
	return null;
}

export function formatCurrency(
	price: string | number,
	options?: {
		currency?: string;
		minimumFractionDigits?: number;
		maximumFractionDigits?: number;
	}
) {
	const num = Number(price);
	const formatOptions = {
		style: options?.currency ? "currency" : undefined,
		currency: options?.currency,
		minimumFractionDigits: options?.minimumFractionDigits ?? 2,
		maximumFractionDigits: options?.maximumFractionDigits ?? 2
	}
	try {
		return num.toLocaleString(undefined, formatOptions);
	}
	//RangeError is thrown for invalid currency
	catch(err) {
		//return without currency
		formatOptions.style = undefined;
		formatOptions.currency = undefined;
		return num.toLocaleString(undefined, formatOptions);
	}
}

/**
 * Remove number formatting, such as `,' and `.`
 * @param formattedString String to remove formatting from
 * @returns [`cleaned string`, `thousand separator`, `decimal separator`]
 */
export function removeNumberFormatting(formattedString: string) {
	const thousandSeparator = Intl.NumberFormat().formatToParts(11111)[1].value;
	const decimalSeparator = Intl.NumberFormat().formatToParts(1.1)[1].value;

	return [
		formattedString
			.replace(new RegExp('\\' + thousandSeparator, 'g'), '')
			.replace(new RegExp('\\' + decimalSeparator), '.'),
		thousandSeparator,
		decimalSeparator
	]
}

export function getCurrencySign(currency: string) {
	try {
		return new Intl.NumberFormat(undefined, {
			style: 'currency',
			currency: currency,
			maximumFractionDigits: 0
		})
		//Format for `0`
		.format(0)
		//Formatted value would be `sign + 0`, slice & remove last `0`
		.slice(0, -1)
		.trimEnd()
	}
	catch(err) {
		//If currency code can't be found, return the code
		return currency;
	}
}

// Sequence generator function (commonly referred to as "range", e.g. Clojure, PHP etc)
export const range = (start: number, stop: number, step: number) =>
	Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

export function dayDiff(start: Date, end: Date) {
	const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
	const diffDays = Math.round(Math.abs((end.setHours(0,0,0,0) - start.setHours(0,0,0,0)) / oneDay));
	return diffDays;
}

export function monthDiff(start: Date, end: Date) {
    let months;
    months = (end.getFullYear() - start.getFullYear()) * 12;
    months -= start.getMonth();
    months += end.getMonth();
    return months <= 0 ? 0 : months;
}

export const formCloseHandler = (handler: () => void, dirty?: boolean) => {
	if (dirty) {
		const leave = window.confirm(
			'You have unsaved changes. Are you sure you want to leave?'
		);
		if (leave) {
			handler();
		}
	}
	else {
		handler();
	}
};

export const extractFileNameFromPath = (path: string) => {
	if (path.slice(0, 12) === "C:\\fakepath\\")
	  return path.slice(12); // modern browser
	let x;
	x = path.lastIndexOf('/');
	if (x >= 0) // Unix-based path
	  return path.slice(x+1);
	x = path.lastIndexOf('\\');
	if (x >= 0) // Windows-based path
	  return path.slice(x+1);
	return path; // just the filename
}

export const extractFileSize = (size: number) => {
	size = size/1024
	if (size < 1024) return size.toFixed(2) + ' KB'
	return (size/1024).toFixed(2) + ' MB'
}

export const extractFileName = (name: string) => {
	const nameArray = name.split('.');
	return String(nameArray.slice(0, nameArray.length - 1).join('.'))
}

export const extractFileExtension = (name: string) => {
	return String(name.split('.').slice(-1))
}

export const imageThumbnailParse = (file?: File) => {
	return new Promise<{ file: File, url: string }>((res, rej) => {
		if (!file) {
			return rej(new Error("Couldn't read image"));
		}
		if (!file.type.startsWith('image')) {
			return rej(new Error("Invalid image file"));
		}

		const reader = new FileReader();
		reader.onload = (e) => {
			if (!e.target?.result) {
				return rej(new Error("Couldn't read file"));
			}
			res({
				file,
				url: e.target.result as string
			})
		}
		reader.onerror = rej;

		reader.readAsDataURL(file);
	})
}

// convert file to a base64 url
export const getFileWithUrl = (file: File): Promise<AttachmentFile> => {
    return new Promise((res, rej) => {
        // dont convert if not image
        if (!file.type.startsWith('image/')) {
            return res({ file, url: '' });
        }

        const reader = new FileReader();
        reader.onload = (e) => {
            if (e.target?.result) {
                res({
                    file,
                    url: e.target.result as string,
                });
            } else rej();
        };
        reader.onerror = (e) => rej(e);
        reader.readAsDataURL(file);
    });
};

// `string.length` property in JavaScript doesn't count newline characters.
// Use this function instead, for counting length for strings with newlines.
export const getStringLengthWNewLine = (str: string) => {
	return str.length + (str.match(/\n/g)?.length ?? 0)
}

export const convertSquareMetreToSquareFoot = (value: number) => {
	return value * 10.764;
}
export const convertSquareFootToSquareMetre = (value: number) => {
	return value / 10.764;
}