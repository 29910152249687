import { Modal } from "react-bootstrap";
import { BaseModalProps } from "../../../hooks/useModal";
import { useValuationMutations } from "../../../services/assetvaluation.service";
import IconButton from "../../utils/IconButton";
import { Add, Close, CloseRounded, PriceCheckOutlined } from "@mui/icons-material";

interface AddToValuationModalProps extends BaseModalProps {
    assetId: number;

    valuationDate: string;
    currency: string;
    valuation: number;
    valuer: string;
}

export function AddToValuationModal({ assetId, currency, valuationDate, valuation, valuer, onClose, ...props }: AddToValuationModalProps) {

    const { mutate: add, isLoading, error } = useValuationMutations('CREATE');

    const handleConfirm = () => {
        add({
            valu_date: valuationDate,
            valu_valuer: valuer,
            valu_value: valuation,
            valu_note: `This valutaion is retrieved from ${valuer}`,
            valu_doclink: null,
            asst_id: assetId
        }, {
            onSuccess: onClose
        })
    }

    return (
        <Modal
            centered
            keyboard={!isLoading}
            backdrop={isLoading ? "static" : undefined}
            onHide={onClose}
            {...props}
        >
            <Modal.Header>
                <Modal.Title>Add Estimate to <PriceCheckOutlined /> Valuations</Modal.Title>
                <IconButton
                    Icon={CloseRounded}
                    title="Close"
                    className="ms-auto"
                    onClick={onClose}
                    disabled={isLoading}
                />
            </Modal.Header>
            <Modal.Body>
                Do you want to add{' '}
                <b>{currency}{valuation.toLocaleString()}</b>
                {' '}to your valuations record?
            </Modal.Body>
            <Modal.Footer>
                { error !== null && <p className="text-danger text-truncate w-100">{error.message}</p> }

                <IconButton
                    transparent
                    Icon={Close}
                    iconHtmlColor="var(--primary)"
                    className="px-4 border border-primary text-primary me-3"
                    onClick={onClose}
                    disabled={isLoading}
                    label="Cancel"
                />
                <IconButton
                    Icon={Add}
                    className="px-4"
                    onClick={handleConfirm}
                    disabled={isLoading}
                    label={isLoading ? 'Adding...' : 'Add'}
                />
            </Modal.Footer>
        </Modal>
    )
}