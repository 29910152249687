import { GroupsRounded, PeopleOutlineRounded, MailRounded } from "@mui/icons-material";
import { useState, useEffect } from "react";
import useModal from "../../../hooks/useModal";
import { useFollowers, useFollowing } from "../../../services/user.service";
import { Butlerr } from "../../../types/butlerr";
import ToggleButtonGroup from "../../ui/ToggleButtonGroup";
import IconButton from "../../utils/IconButton";
import InviteUsersModal from "../../utils/InviteUsersModal";
import HallOfFame from "./HallOfFame";
import UserList from "./UserList";

export default function Network() {
    return (
        <div>
            <HallOfFame />
            <hr />
            <FollowList />
        </div>
    )
}

function FollowList() {

    const [ activeTab, setActiveTab ] = useState<typeof items[number]['label']>('Following');

    useEffect(() => {
        //clearing out users. This useEffect needs to come before useEffect to set users, so the list of users is cleared first
        setUsers([]);
    }, [activeTab])

    const items = [
        { label: "Following" as const, Icon: PeopleOutlineRounded },
        { label: "Followers" as const, Icon: GroupsRounded }
    ];

    const [ modal, invite ] = useModal(InviteUsersModal)

    const query1 = useFollowers(activeTab === 'Followers');
    const query2 = useFollowing(activeTab === 'Following');

    const query = activeTab === 'Followers' ? query1 : query2;

    const { data: _users, isLoading, hasNextPage, fetchNextPage } = query;

    const [ users, setUsers ] = useState<Required<Butlerr.UserInfo>[]>();
    useEffect(() => {
        const newListOfUsers = _users?.pages.flatMap(p => p.result);
        //We want to add the new list of users with old list of users, & remove duplicates
        //To avoid immediately removing unfollowed users until unmount
        setUsers(prevListOfUsers => {
            if (!prevListOfUsers || !newListOfUsers) return newListOfUsers ?? prevListOfUsers;
            return [
                ...prevListOfUsers,
                ...newListOfUsers.filter((u) => prevListOfUsers.findIndex(u2 => u2.user_id === u.user_id) === -1)
            ]
        })

    }, [_users]);

    return (
        <div className="mb-2">
            <div className="mb-3 d-flex align-items-center justify-content-between flex-wrap">
                <ToggleButtonGroup
                    items={items.map(i => ({ ...i, active: activeTab === i.label }))}
                    onSelect={({ label }) => setActiveTab(label)}
                />
                <IconButton
                    label="Invite"
                    title="Invite"
                    Icon={MailRounded}
                    className="rounded-4"
                    onClick={invite}
                />
            </div>
            <UserList
                emptyMessage={activeTab === 'Following' ? "You're not following anyone yet" : "Share your experience and gain a following"}
                isLoading={isLoading}
                hasNextPage={hasNextPage}
                fetchNextPage={fetchNextPage}
                users={users}
            />

            { modal }
        </div>
    )
}