// #region Social
export type SocialPostReactionKey = keyof typeof ButlerrSocialPostReactions;
export enum ButlerrSocialPostReactions {
	O = 'Love',
	L = 'Like',
	D = 'Dislike',
	H = 'High Five',
	C = 'Clap'
}
// #endregion

// #region Asset
export type AssetTypeKey = keyof typeof ButlerrAssetType;
export enum ButlerrAssetType {
	AP = 'Apt/Flat',
	HO = 'House',
	TR = 'Tce/Townhome',
	SD = 'Semi-Detached',
	CM = 'Commercial',
	ID = 'Industrial',
	SA = 'Student Accom',
	HT = 'Hotel',
	LA = 'Land',
	OT = 'Others',
}

export type AssetQualityKey = keyof typeof ButlerrAssetQuality;
export enum ButlerrAssetQuality {
	V = 'Very high',
	H = 'High',
	A = 'Average',
	B = 'Below Average',
	O = 'Original Condition',
}

export type AssetStatusKey = keyof typeof ButlerrAssetStatus;
export enum ButlerrAssetStatus {
	AR = 'Archived',
	AC = 'Active',
	FL = 'Flipped',
	OP = 'Off Plan',
}

/* leases */
export type LeaseAmountPeriodKey = keyof typeof ButlerrLeaseAmountPeriod;
export enum ButlerrLeaseAmountPeriod {
	M = 'Monthly',
	W = 'Weekly',
}

export type LeaseDueDayKey = typeof ButlerrLeaseDueDay[keyof typeof ButlerrLeaseDueDay];
export const ButlerrLeaseDueDay: Map = {
	0: 'Start of billing cycle',
	99: 'End of billing cycle',
} as const;

export type InsuranceTypeKey = keyof typeof ButlerrInsuranceType;
export enum ButlerrInsuranceType {
	FF = 'Fire',
	LL = 'Landlord',
	FL = 'Flood',
	EQ = 'Earthquake',
	HC = 'Home Content',
	BL = 'Building',
	OT = 'Others',
}

/* loans */
export type LoanTypeKey = keyof typeof ButlerrLoanType;
export enum ButlerrLoanType {
	I = 'Interest Only',
	P = 'Principal + Interest',
}

export type LoanPayPeriodKey = keyof typeof ButlerrLoanPayPeriod;
export enum ButlerrLoanPayPeriod {
	A = 'Annually',
	Q = 'Quarterly',
	M = 'Monthly',
}
export type CurrencyKey = keyof typeof Currency;
export enum Currency {
	AED = "United Arab Emirates dirham",
	AFN = "Afghan Afghani",
	ALL = "Albanian lek",
	AMD = "Armenian dram",
	ANG = "Netherlands Antillean guilder",
	ARS = "Argentine peso",
	AUD = 'Australian Dollar',
	AZN = "Azerbaijani manat",
	BBD = "Barbadian dollar",
	BDT = "Bangladeshi taka",
	BGN = 'Bulgarian Lev',
	BHD = "Bahraini dinar",
	BIF = "Burundian franc",
	BMD = "Bermudian dollar",
	BND = "Brunei dollar",
	BOB = "Bolivian boliviano",
	BRL = 'Brazilian Real',
	BSD = "Bahamian dollar",
	BWP = "Botswana pula",
	BYR = "Belarusian ruble",
	BZD = "Belize dollar",
	CAD = 'Canadian Dollar',
	CDF = "Congolese franc",
	CHF = 'Swiss Franc',
	CLP = "Chilean peso",
	CNY = 'Chinese Yuan Renminbi',
	COP = "Colombian peso",
	CRC = "Costa Rican colón",
	CUP = "Cuban peso",
	CVE = "Cape Verdean escudo",
	CZK = 'Czech Koruna',
	DJF = "Djiboutian franc",
	DKK = 'Danish Krone',
	DOP = "Dominican peso",
	DZD = "Algerian dinar",
	EGP = "Egyptian pound",
	ETB = "Ethiopian birr",
	EUR = 'Euro',
	FJD = "Fijian dollar",
	FKP = "Falkland Islands pound",
	GBP = 'British Pound Sterline',
	GEL = "Georgian lari",
	GIP = "Gibraltar pound",
	GMD = "Gambian dalasi",
	GNF = "Guinean franc",
	GTQ = "Guatemalan quetzal",
	GYD = "Guyanese dollar",
	HKD = 'Hong Kong Dollar',
	HNL = "Honduran lempira",
	HRK = 'Croatian Kuna',
	HTG = "Haitian gourde",
	HUF = 'Hungarian Forint',
	IDR = 'Indonesian Rupiah',
	ILS = 'Israeli Shekel',
	INR = 'Indian Rupee',
	IQD = "Iraqi dinar",
	IRR = "Iranian rial",
	ISK = 'Icelandic Krona',
	JMD = "Jamaican dollar",
	JOD = "Jordanian dinar",
	JPY = 'Japanese Yen',
	KES = "Kenyan shilling",
	KGS = "Kyrgyz som",
	KHR = "Cambodian riel",
	KMF = "Comorian franc",
	KPW = "North Korean won",
	KRW = 'South Korean Won',
	KWD = "Kuwaiti dinar",
	KYD = "Cayman Islands dollar",
	KZT = "Kazakhstani tenge",
	LAK = "Lao kip",
	LBP = "Lebanese pound",
	LKR = "Sri Lankan rupee",
	LRD = "Liberian dollar",
	LSL = "Lesotho loti",
	LYD = "Libyan dinar",
	MAD = "Moroccan dirham",
	MDL = "Moldovan leu",
	MGA = "Malagasy ariary",
	MKD = "Macedonian denar",
	MMK = "Burmese kyat",
	MNT = "Mongolian tögrög",
	MOP = "Macanese pataca",
	MUR = "Mauritian rupee",
	MVR = "Maldivian rufiyaa",
	MWK = "Malawian kwacha",
	MXN = 'Mexican Peso',
	MYR = 'Malaysian Ringgit',
	MZN = "Mozambican metical",
	NAD = "Namibian dollar",
	NGN = "Nigerian naira",
	NIO = "Nicaraguan córdoba",
	NOK = 'Norwegian Krone',
	NPR = "Nepalese rupee",
	NZD = 'New Zealand Dollar',
	OMR = "Omani rial",
	PAB = "Panamanian balboa",
	PEN = "Peruvian sol",
	PGK = "Papua New Guinean kina",
	PHP = 'Philippine Peso',
	PKR = "Pakistani rupee",
	PLN = 'Polish Zloty',
	PYG = "Paraguayan guaraní",
	QAR = "Qatari riyal",
	RON = 'Romanian Leu',
	RUB = 'Russian Rouble',
	RWF = "Rwandan franc",
	SAR = "Saudi riyal",
	SBD = "Solomon Islands dollar",
	SCR = "Seychellois rupee",
	SDG = "Sudanese pound",
	SEK = 'Swedish Krona',
	SGD = 'Singapore Dollar',
	SLL = "Sierra Leonean leone",
	SOS = "Somali shilling",
	SRD = "Surinamese dollar",
	STN = "São Tomé and Príncipe dobra",
	SYP = "Syrian pound",
	SZL = "Swazi lilangeni",
	THB = 'Thai Baht',
	TJS = "Tajikistani somoni",
	TMT = "Turkmenistan manat",
	TND = "Tunisian dinar",
	TOP = "Tongan paʻanga",
	TRY = 'Turkish Lira',
	TTD = "Trinidad and Tobago dollar",
	TWD = "New Taiwan dollar",
	TZS = "Tanzanian shilling",
	UAH = "Ukrainian hryvnia",
	UGX = "Ugandan shilling",
	USD = 'US Dollar',
	UYU = "Uruguayan peso",
	UZS = "Uzbekistani soʻm",
	VEF = "Venezuelan Bolívar",
	VND = "Vietnamese đồng",
	VUV = "Vanuatu vatu",
	XAF = "Central African CFA franc",
	XCD = "Eastern Caribbean dollar",
	XOF = "West African CFA franc",
	XPF = "CFP franc",
	YER = "Yemeni rial",
	ZAR = 'South African Rand',
	ZMK = "Zambian kwacha",
	ZWD = "Zimbabwean dollar",
}

/* valuations */
export type ValuationConfidenceKey = keyof typeof ButlerrValuationConfidence;
export enum ButlerrValuationConfidence {
	H = 'High',
	L = 'Low',
	M = 'Medium',
	S = 'Sold',
	V = 'Valuer',
}

/* calendar */
export type CalendarsReminderKey = keyof typeof ButlerrCalendarsReminder;
export enum ButlerrCalendarsReminder {
	E = 'Email',
	W = 'Whatsapp',
}

export type CalendarsStatusKey = keyof typeof ButlerrCalendarsStatus;
export enum ButlerrCalendarsStatus {
	C = 'Complete',
	P = 'Progress',
}

/* cashflow */
export type AssetCashflowTypeKey = keyof typeof ButlerrAssetCashflowType;
export enum ButlerrAssetCashflowType {
	AQ = 'Acquisition',
	GO = 'Government',
	CC = 'Council',
	TN = 'Tenancy',
	MT = 'Maintenance',
	UT = 'Utilities',
	EM = 'Estate Management',
	IN = 'Insurance',
	LN = 'Loan/Mortgage',
	PF = 'Professional Services',
	PM = 'Property Management',
	LQ = 'Liquidation',
	OT = 'Others',
}

// reminders
export type AssetRemindersTypeKey = keyof typeof ButlerrAssetRemindersType;
export enum ButlerrAssetRemindersType {
	T = 'Task/Deadline',
	P = 'Phone Call',
	V = 'Video Conference'
}

export type AssetRemindersPriorityKey = keyof typeof ButlerrAssetRemindersPriority;
export enum ButlerrAssetRemindersPriority {
	N = 'Normal',
	H = 'High',
	U = 'Urgent',
}

/* role */
export type AssetRoleTypeKey = keyof typeof ButlerrAssetRoleType;
export enum ButlerrAssetRoleType {
	CO = 'Co-owner',
	DV = 'Developer',
	BD = 'Builder',
	CV = 'Conveyancer',
	FI = 'Financier',
	PM = 'Property Manager',
	TA = 'Tax Accountant',
	AG = 'Agent',
	IA = 'Investment Advisor',
	VL = 'Valuer',
	SV = 'Surveyor',
	IS = 'Inspection Agent',
	MA = 'Mortgage Advisor'
}

// #endregion

// #region User
type Map = {
	[key in string | number]: string;
};

export type UserUSDIncomeKey = typeof ButlerrUserUSDIncome[keyof typeof ButlerrUserUSDIncome];
export const ButlerrUserUSDIncome: Map = {
	1: 'USD 50k or less',
	2: 'USD 100k',
	3: 'USD 150k',
	4: 'USD 250k',
	5: 'USD 300k or more',
} as const;

export type UserGenderKey = keyof typeof ButlerrUserGender;
export enum ButlerrUserGender {
	M = "Male",
	F = "Female",
	U = "Unspecified",
}

export type CountriesKey = keyof typeof ButlerrCountries;
export enum ButlerrCountries {
	AF = "Afghanistan",
	AL = "Albania",
	DZ = "Algeria",
	AS = "American Samoa",
	AD = "Andorra",
	AI = "Anguilla",
	AG = "Antigua and Barbuda",
	AR = "Argentina",
	AM = "Armenia",
	AW = "Aruba",
	AU = "Australia",
	AT = "Austria",
	AZ = "Azerbaijan",
	BS = "Bahamas",
	BH = "Bahrain",
	BD = "Bangladesh",
	BB = "Barbados",
	BY = "Belarus",
	BE = "Belgium",
	BZ = "Belize",
	BJ = "Benin",
	BM = "Bermuda",
	BT = "Bhutan",
	BO = "Bolivia",
	BW = "Botswana",
	BV = "Bouvet Island",
	BR = "Brazil",
	IO = "British Indian Ocean Territory",
	BN = "Brunei Darussalam",
	BG = "Bulgaria",
	BF = "Burkina Faso",
	BI = "Burundi",
	KH = "Cambodia",
	CM = "Cameroon",
	CA = "Canada",
	CV = "Cape Verde",
	KY = "Cayman Islands",
	CF = "Central African Republic",
	TD = "Chad",
	CL = "Chile",
	CN = "China",
	CX = "Christmas Island",
	CC = "Cocos (Keeling) Islands",
	CO = "Colombia",
	KM = "Comoros",
	CG = "Congo Republic of the Democratic",
	CD = "Congo-Brazzaville",
	CK = "Cook Islands",
	CR = "Costa Rica",
	HR = "Croatia (Hrvatska)",
	CU = "Cuba",
	CY = "Cyprus",
	CZ = "Czech Republic",
	DK = "Denmark",
	DJ = "Djibouti",
	DM = "Dominica",
	DO = "Dominican Republic",
	TP = "East Timor",
	EC = "Ecuador",
	EG = "Egypt",
	SV = "El Salvador",
	GQ = "Equatorial Guinea",
	ER = "Eritrea",
	EE = "Estonia",
	ET = "Ethiopia",
	FK = "Falkland Islands (Malvinas)",
	FO = "Faroe Islands",
	FJ = "Fiji",
	FI = "Finland",
	FR = "France",
	GF = "French Guiana",
	PF = "French Polynesia",
	TF = "French Southern Territories",
	GA = "Gabon",
	GM = "Gambia",
	GE = "Georgia",
	DE = "Germany",
	GI = "Gibraltar",
	GR = "Greece",
	GL = "Greenland",
	GD = "Grenada",
	GP = "Guadeloupe",
	GU = "Guam",
	GT = "Guatemala",
	GN = "Guinea",
	GW = "Guinea-Bissau",
	GY = "Guyana",
	HT = "Haiti",
	HM = "Heard and Mc Donald Islands",
	HN = "Honduras",
	HK = "Hong Kong",
	HU = "Hungary",
	IS = "Iceland",
	IN = "India",
	ID = "Indonesia",
	IR = "Iran (Islamic Republic of)",
	IQ = "Iraq",
	IE = "Ireland",
	IL = "Israel",
	IT = "Italy",
	CI = "Ivory Coast",
	JM = "Jamaica",
	JP = "Japan",
	JE = "Jersey",
	JO = "Jordan",
	KZ = "Kazakhstan",
	KE = "Kenya",
	KI = "Kiribati",
	KP = "Korea North",
	KR = "Korea South",
	KW = "Kuwait",
	KG = "Kyrgyzstan",
	LA = "Lao PeopleÕs Democratic Republic",
	LV = "Latvia",
	LB = "Lebanon",
	LS = "Lesotho",
	LR = "Liberia",
	LY = "Libyan Arab Jamahiriya",
	LI = "Liechtenstein",
	LT = "Lithuania",
	LU = "Luxembourg",
	MO = "Macau",
	MK = "Macedonia",
	MG = "Madagascar",
	MW = "Malawi",
	MY = "Malaysia",
	MV = "Maldives",
	ML = "Mali",
	MT = "Malta",
	MH = "Marshall Islands",
	MQ = "Martinique",
	MR = "Mauritania",
	MU = "Mauritius",
	YT = "Mayotte",
	MX = "Mexico",
	FM = "Micronesia Federated States of",
	MD = "Moldova Republic of",
	MC = "Monaco",
	MN = "Mongolia",
	MS = "Montserrat",
	MA = "Morocco",
	MZ = "Mozambique",
	MM = "Myanmar",
	NA = "Namibia",
	NR = "Nauru",
	NP = "Nepal",
	NL = "Netherlands",
	AN = "Netherlands Antilles",
	NC = "New Caledonia",
	NZ = "New Zealand",
	NI = "Nicaragua",
	NE = "Niger",
	NG = "Nigeria",
	NU = "Niue",
	NF = "Norfolk Island",
	MP = "Northern Mariana Islands",
	NO = "Norway",
	OM = "Oman",
	PK = "Pakistan",
	PW = "Palau",
	PA = "Panama",
	PG = "Papua New Guinea",
	PY = "Paraguay",
	PE = "Peru",
	PH = "Philippines",
	PN = "Pitcairn",
	PL = "Poland",
	PT = "Portugal",
	PR = "Puerto Rico",
	QA = "Qatar",
	RE = "Reunion",
	RO = "Romania",
	RU = "Russian Federation",
	RW = "Rwanda",
	KN = "Saint Kitts",
	LC = "Saint Lucia",
	VC = "Saint Vincent Grenadines",
	WS = "Samoa",
	SM = "San Marino",
	ST = "Sao Tome and Principe",
	SA = "Saudi Arabia",
	SN = "Senegal",
	SC = "Seychelles",
	SL = "Sierra Leone",
	SG = "Singapore",
	SK = "Slovakia (Slovak Republic)",
	SI = "Slovenia",
	SB = "Solomon Islands",
	SO = "Somalia",
	ZA = "South Africa",
	GS = "South Georgia and the South Sandwich Islands",
	ES = "Spain",
	LK = "Sri Lanka",
	SD = "Sudan",
	SR = "Suriname",
	SJ = "Svalbard and Jan Mayen Islands",
	SZ = "Swaziland",
	SE = "Sweden",
	CH = "Switzerland",
	SY = "Syrian Arab Republic",
	TW = "Taiwan",
	TJ = "Tajikistan",
	TZ = "Tanzania",
	TH = "Thailand",
	TG = "Togo",
	TK = "Tokelau",
	TO = "Tonga",
	TT = "Trinidad and Tobago",
	TN = "Tunisia",
	TR = "Turkey",
	TM = "Turkmenistan",
	TC = "Turks and Caicos Islands",
	TV = "Tuvalu",
	UG = "Uganda",
	UA = "Ukraine",
	AE = "United Arab Emirates",
	GB = "United Kingdom",
	US = "United States",
	UM = "United States Minor Outlying Islands",
	UY = "Uruguay",
	UZ = "Uzbekistan",
	VU = "Vanuatu",
	VA = "Vatican City State (Holy See)",
	VE = "Venezuela",
	VN = "Vietnam",
	VG = "Virgin Islands (British)",
	VI = "Virgin Islands (US)",
	WF = "Wallis and Futuna Islands",
	EH = "Western Sahara",
	YE = "Yemen",
	ZM = "Zambia",
	ZW = "Zimbabwe"
}

export type UserPrefCurrKey = keyof typeof ButlerrUserPrefCurr;
export enum ButlerrUserPrefCurr {
	NAT = 'Native',
	AED = "United Arab Emirates dirham",
	AFN = "Afghan Afghani",
	ALL = "Albanian lek",
	AMD = "Armenian dram",
	ANG = "Netherlands Antillean guilder",
	ARS = "Argentine peso",
	AUD = 'Australian Dollar',
	AZN = "Azerbaijani manat",
	BBD = "Barbadian dollar",
	BDT = "Bangladeshi taka",
	BGN = 'Bulgarian Lev',
	BHD = "Bahraini dinar",
	BIF = "Burundian franc",
	BMD = "Bermudian dollar",
	BND = "Brunei dollar",
	BOB = "Bolivian boliviano",
	BRL = 'Brazilian Real',
	BSD = "Bahamian dollar",
	BWP = "Botswana pula",
	BYR = "Belarusian ruble",
	BZD = "Belize dollar",
	CAD = 'Canadian Dollar',
	CDF = "Congolese franc",
	CHF = 'Swiss Franc',
	CLP = "Chilean peso",
	CNY = 'Chinese Yuan Renminbi',
	COP = "Colombian peso",
	CRC = "Costa Rican colón",
	CUP = "Cuban peso",
	CVE = "Cape Verdean escudo",
	CZK = 'Czech Koruna',
	DJF = "Djiboutian franc",
	DKK = 'Danish Krone',
	DOP = "Dominican peso",
	DZD = "Algerian dinar",
	EGP = "Egyptian pound",
	ETB = "Ethiopian birr",
	EUR = 'Euro',
	FJD = "Fijian dollar",
	FKP = "Falkland Islands pound",
	GBP = 'British Pound Sterline',
	GEL = "Georgian lari",
	GIP = "Gibraltar pound",
	GMD = "Gambian dalasi",
	GNF = "Guinean franc",
	GTQ = "Guatemalan quetzal",
	GYD = "Guyanese dollar",
	HKD = 'Hong Kong Dollar',
	HNL = "Honduran lempira",
	HRK = 'Croatian Kuna',
	HTG = "Haitian gourde",
	HUF = 'Hungarian Forint',
	IDR = 'Indonesian Rupiah',
	ILS = 'Israeli Shekel',
	INR = 'Indian Rupee',
	IQD = "Iraqi dinar",
	IRR = "Iranian rial",
	ISK = 'Icelandic Krona',
	JMD = "Jamaican dollar",
	JOD = "Jordanian dinar",
	JPY = 'Japanese Yen',
	KES = "Kenyan shilling",
	KGS = "Kyrgyz som",
	KHR = "Cambodian riel",
	KMF = "Comorian franc",
	KPW = "North Korean won",
	KRW = 'South Korean Won',
	KWD = "Kuwaiti dinar",
	KYD = "Cayman Islands dollar",
	KZT = "Kazakhstani tenge",
	LAK = "Lao kip",
	LBP = "Lebanese pound",
	LKR = "Sri Lankan rupee",
	LRD = "Liberian dollar",
	LSL = "Lesotho loti",
	LYD = "Libyan dinar",
	MAD = "Moroccan dirham",
	MDL = "Moldovan leu",
	MGA = "Malagasy ariary",
	MKD = "Macedonian denar",
	MMK = "Burmese kyat",
	MNT = "Mongolian tögrög",
	MOP = "Macanese pataca",
	MUR = "Mauritian rupee",
	MVR = "Maldivian rufiyaa",
	MWK = "Malawian kwacha",
	MXN = 'Mexican Peso',
	MYR = 'Malaysian Ringgit',
	MZN = "Mozambican metical",
	NAD = "Namibian dollar",
	NGN = "Nigerian naira",
	NIO = "Nicaraguan córdoba",
	NOK = 'Norwegian Krone',
	NPR = "Nepalese rupee",
	NZD = 'New Zealand Dollar',
	OMR = "Omani rial",
	PAB = "Panamanian balboa",
	PEN = "Peruvian sol",
	PGK = "Papua New Guinean kina",
	PHP = 'Philippine Peso',
	PKR = "Pakistani rupee",
	PLN = 'Polish Zloty',
	PYG = "Paraguayan guaraní",
	QAR = "Qatari riyal",
	RON = 'Romanian Leu',
	RUB = 'Russian Rouble',
	RWF = "Rwandan franc",
	SAR = "Saudi riyal",
	SBD = "Solomon Islands dollar",
	SCR = "Seychellois rupee",
	SDG = "Sudanese pound",
	SEK = 'Swedish Krona',
	SGD = 'Singapore Dollar',
	SLL = "Sierra Leonean leone",
	SOS = "Somali shilling",
	SRD = "Surinamese dollar",
	STN = "São Tomé and Príncipe dobra",
	SYP = "Syrian pound",
	SZL = "Swazi lilangeni",
	THB = 'Thai Baht',
	TJS = "Tajikistani somoni",
	TMT = "Turkmenistan manat",
	TND = "Tunisian dinar",
	TOP = "Tongan paʻanga",
	TRY = 'Turkish Lira',
	TTD = "Trinidad and Tobago dollar",
	TWD = "New Taiwan dollar",
	TZS = "Tanzanian shilling",
	UAH = "Ukrainian hryvnia",
	UGX = "Ugandan shilling",
	USD = 'US Dollar',
	UYU = "Uruguayan peso",
	UZS = "Uzbekistani soʻm",
	VEF = "Venezuelan Bolívar",
	VND = "Vietnamese đồng",
	VUV = "Vanuatu vatu",
	XAF = "Central African CFA franc",
	XCD = "Eastern Caribbean dollar",
	XOF = "West African CFA franc",
	XPF = "CFP franc",
	YER = "Yemeni rial",
	ZAR = 'South African Rand',
	ZMK = "Zambian kwacha",
	ZWD = "Zimbabwean dollar",

	// not in db
    // AWG = "Aruban florin",
    // SHP = "Saint Helena pound",
    // BYN = "Belarusian ruble",
    // BTN = "Bhutanese ngultrum",
    // BAM = "Bosnia and Herzegovina convertible mark",
    // ERN = "Eritrean nakfa",
    // GHS = "Ghanaian cedi",
    // WST = "Samoan tālā",
    // RSD = "Serbian dinar",
    // SSP = "South Sudanese pound",
    // VES = "Venezuelan bolívar soberano",
    // ZMW = "Zambian kwacha",
}

export type UserPrefSomKey = keyof typeof ButlerrUserPrefSom;
export enum ButlerrUserPrefSom {
	M = 'Metric',
	I = 'Imperial',
}

export type UserTypeKey = keyof typeof ButlerrUserType;
export enum ButlerrUserType {
	U = 'NormalUser',
	A = 'SystemAdmin',
	P = 'Partner',
}

export type UserSelfDescribeKey = keyof typeof ButlerrUserSelfDescribe;
export enum ButlerrUserSelfDescribe {
	D = 'Newbie',
	C = 'Experienced',
	B = 'Seasoned',
	A = 'Expert',
}

export type UserStatusKey = keyof typeof ButlerrUserStatus;
export enum ButlerrUserStatus {
	A = 'Active',
	I = 'Inactive',
	R = 'UnderReview',
	C = 'Suspended',
	D = 'Deactivated',
}
// #endregion

// #region Work
export type WorkProfileStatusKey = keyof typeof ButlerrWorkProfileStatus;
export enum ButlerrWorkProfileStatus {
	P = 'Pending',
	A = 'Active',
	S = 'Suspended',
	I = 'Inactive',
	T = 'Terminated',
}

export type WorkProfileRequestedByKey = keyof typeof ButlerrWorkProfileStaffRequestedBy;
export enum ButlerrWorkProfileStaffRequestedBy {
	C = "Company",
	S = "Staff",
}
// #endregion