import DOMPurify from "dompurify";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom"
import { articleQueries, FetchPublishedArticlesOptions, useArticleById, useArticleMutations } from "../../../services/partnerArticle.service"
import BootstrapSpinner from "../../utils/BootstrapSpinner";
import { convertImages } from "./ArticleForm";
import { Image } from "react-bootstrap";
import socialStyles from '../../social/social.module.css';
import imgPlaceHolder from '../../../assets/img_placeholder.jpg'
import { formatDate } from "../../utils/HelperFunctions";
import EmptyMessage from "../../utils/EmptyMessage";
import { ThumbUp, ThumbUpOutlined, ThumbDown, ThumbDownOutlined, Undo } from "@mui/icons-material";
import IconButton from "../../utils/IconButton";
import { useQueryClient } from "react-query";
import useQuery from '../../../hooks/useQuery';
import { useEffect, useMemo } from "react";

type KeysWithType<T, V> = {[K in keyof T]-?: T[K] extends V ? K : never}[keyof T];

type FilterKeys = 'a' | 'f'
const FILTER_MAP : Record<FilterKeys, KeysWithType<Required<FetchPublishedArticlesOptions>, boolean | null>> = {
    a: "all",
    f: "following_only",
} as const;
type SortKeys = 'n' | 't' | 'a'
const SORT_MAP : Record<SortKeys, FetchPublishedArticlesOptions['sort']> = {
    n: "newest",
    t: "trending",
    a: "popular"
} as const;

export const Article = () => {
    const { articleId } = useParams<{ articleId: string }>();
    const {data: article, isLoading} = useArticleById(parseInt(articleId))
    const {mutate: react, isLoading: isReactLoading} = useArticleMutations('REACT_ARTICLE');
    const {mutate: deleteReact, isLoading: isDeleteReactLoading} = useArticleMutations('DELETE_REACT_ARTICLE');
    const queryClient = useQueryClient();
    const {mutate: addViewArticle} = useArticleMutations('ADD_VIEW_ARTICLE');

    const history = useHistory();
    const search = useQuery()

    const params = useMemo(() => {
        const params : FetchPublishedArticlesOptions = {};

        const query = decodeURIComponent(search.get("q") || "");

        if (query) params.search = query;

        const sortKey = SORT_MAP[decodeURIComponent(search.get("s") || "") as SortKeys] ?? "newest"; //if there are no sorts, default to newest
        params.sort = sortKey;

        const filterKey = FILTER_MAP[decodeURIComponent(search.get("f") || "all") as FilterKeys];
        //if null, no filter (all posts)
        if (filterKey !== null) {
            //set matching filter key to true;
            //if undefined, default to show `all` but only if not searching
            if (filterKey !== undefined || !query) {
                params[filterKey ?? 'all'] = true;
            }
        }
        return params;
    }, [search])

    useEffect(() => {
        setTimeout(() => {
            addViewArticle(parseInt(articleId), {onSuccess: () => {queryClient.invalidateQueries(articleQueries.PUBLISHED(params))}})
        }, 0);
    }, [addViewArticle, articleId, params, queryClient])
     
    const handleLikeClick = () => {
        if (!article) {
            return
        }
        if (article.userReaction === 1) {
            deleteReact({
                arti_id: article.arti_id
            }, {
                onSuccess: () => {
                    queryClient.invalidateQueries(articleQueries.PUBLISHED(params))
                }
            })
        } else {
            react({
                arti_id: article.arti_id,
                atra_reaction: 1
            }, {
                onSuccess: () => {
                    queryClient.invalidateQueries(articleQueries.PUBLISHED(params))
                }
            })
        }
    }

    const handleDislikeClick = () => {
        if (!article) {
            return
        }
        if (article.userReaction === 0) {
            deleteReact({
                arti_id: article.arti_id
            }, {
                onSuccess: () => {
                    queryClient.invalidateQueries(articleQueries.PUBLISHED(params))
                }
            })
        } else {
            react({
                arti_id: article.arti_id,
                atra_reaction: 0
            }, {
                onSuccess: () => {
                    queryClient.invalidateQueries(articleQueries.PUBLISHED(params))
                }
            })
        }
    }

    while (isLoading) {
        return <BootstrapSpinner />
    }

    if (article?.arti_text === undefined) {
        return <EmptyMessage message="Article does not or no longer exists." />
    }

    if (article?.arti_status === 0) {
        return <EmptyMessage message="The author or an admin has privated this article." />
    }
    
    return (
        <>
        
        <Container className='p-5'>
                <Card style={{padding: "4rem"}}>
                    <div className="position-absolute" style={{top: 20, left: 30}}>
                        <IconButton Icon={Undo} onClick={() => {
                            if(window.history.length > 2) {
                                if (search.toString() !== null && search.toString() !== undefined) {
                                    history.goBack()
                                }
                            } else {
                                history.push('/home/posts')
                            }
                            }} size="sm" label="Go back" />
                    </div>
                    {article.arti_templateID === 2 ? (
                    <>
                        <Row className="mb-3">
                        <Col md={4}>
                        {article?.arti_imagekey ? (<div className="w-100 h-100 text-center">
                            <Image className={'shadow-none rounded border border-secondary w-100 h-100 ' + socialStyles.userAvatar}
                                style={{ width: '100%', maxWidth: '100%',aspectRatio: '153/100', objectFit: 'cover' }} src={article?.arti_imagekey ?? ''}></Image>
                        </div>) : (
                            <Image src={imgPlaceHolder}
                                className={'shadow-none rounded border border-secondary w-100 h-100 ' + socialStyles.userAvatar}
                                style={{ width: '100%', maxWidth: '100%',aspectRatio: '153/100', objectFit: 'cover' }}
                                title='Image placeholder'
                            />
                        )}
                        </Col>
                        <Col className="d-flex flex-column">
                        <h1 className="fw-bold">{article?.arti_title?.length === 0 && article?.arti_title !== null ? ('Untitled') : (article?.arti_title)}</h1>
                        <span className="fst-italic">{article?.arti_synopsis?.length === 0 ? ('No synopsis available') : (article?.arti_synopsis)}</span>
                        <small className="mt-2 fw-bold">{article?.user_socialhandle}-{article.arti_lastedited
                                    ? 'Modified: ' +
                                    formatDate(article.arti_lastedited)
                                    : formatDate(article.arti_date)}</small>
                        </Col>
                    </Row>
                    <Row className="mt-3" dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(convertImages(article?.arti_text ?? '')),
                                }}>

                    </Row>
                    </>
                    ) : (article.arti_templateID === 1 ? ( // image on the left and text on the right
                    <>
                        <Row className="d-flex mb-3">
                            <Col md={4}>
                                {article?.arti_imagekey ? (<div className="w-100 h-100 text-center">
                                    <Image className={'shadow-none rounded border border-secondary ' + socialStyles.userAvatar}
                                        style={{ width: '100%', maxWidth: '100%',aspectRatio: '153/100', objectFit: 'cover' }} 
                                        src={article?.arti_imagekey ?? ''} />
                                </div>) : (
                                    <Image src={imgPlaceHolder}
                                        className={'shadow-none rounded border border-secondary ' + socialStyles.userAvatar}
                                        style={{ width: '100%', maxWidth: '100%',aspectRatio: '153/100', objectFit: 'cover' }}
                                        title='Image placeholder'
                                    />
                                )}
                            </Col>
                            <Col className="d-flex flex-column ">
                                <h1 className="fw-bold">{article?.arti_title?.length === 0 && article?.arti_title !== null ? ('Untitled') : (article?.arti_title)}</h1>
                                <span className="fst-italic">{article?.arti_synopsis?.length === 0 ? ('No synopsis available') : (article?.arti_synopsis)}</span>
                                <small className="mt-2 fw-bold">{article?.user_socialhandle}-{article.arti_lastedited
                                    ? 'Modified: ' +
                                    formatDate(article.arti_lastedited)
                                    : formatDate(article.arti_date)}</small>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col md={4} />
                            <Col>
                            <div
                                    dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(convertImages(article?.arti_text ?? '')),
                                }}>
                            </div>
                            </Col>
                        </Row>
                    </>
                    ): (
                        article.arti_templateID === 0 && ( //image in the middle
                        <>
                            <Row className="d-flex flex-column justify-content-center align-items-center">
                                <Col>
                                    {article?.arti_imagekey ? (<div className="w-100 h-100 text-center">
                                    <Image className={'shadow-none rounded border border-secondary w-100 h-100 ' + socialStyles.userAvatar}
                                        style={{ maxHeight: '500', width: '100%', objectFit: 'cover' }} 
                                        src={article?.arti_imagekey ?? ''} />
                                    </div>) : (
                                    <Image src={imgPlaceHolder}
                                        className={'shadow-none rounded border border-secondary w-100 h-100 ' + socialStyles.userAvatar}
                                        style={{ height: '100%', maxWidth: '500', objectFit: 'cover' }}
                                        title='Image placeholder'
                                    />
                                    )}
                                    
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <h1 className="fw-bold text-center mt-3">{article?.arti_title?.length === 0 && article?.arti_title !== null ? ('Untitled') : (article?.arti_title)}</h1>
                                <span className="fst-italic text-center">{article?.arti_synopsis?.length === 0 ? ('No synopsis available') : (article?.arti_synopsis)}</span>
                                <small className="text-center fw-bold mt-3">{article?.user_socialhandle}-{article.arti_lastedited
                                    ? 'Modified: ' +
                                    formatDate(article.arti_lastedited)
                                    : formatDate(article.arti_date)}</small>
                                    
                            </Row>
                            <div className="mt-3"
                            dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(convertImages(article?.arti_text ?? '')),
                            }}>
                            </div>
                        </>
                        )
                    ))}
                    <Row>
                        <Col className="pe-0" md={1}>
                            <IconButton
                                transparent
                                border={false}
                                onClick={() => {handleLikeClick()}}
                                disabled={isReactLoading || isDeleteReactLoading}
                                Icon={(article.userReaction === 1 && article.userReaction !== null ? (ThumbUp) : (ThumbUpOutlined))}
                                iconHtmlColor="var(--primary)"
                                title={(article.userReaction === 1 && article.userReaction !== null ? ("Like") : ("Unlike"))}
                                className="w-100"
                                style={{ borderRadius: 12, height: "34px" }}
                                label={article.likeCount.toString()}
                                />
                                </Col>
                                <Col className="ps-0" md={1}>
                            <IconButton
                                transparent
                                border={false}
                                onClick={() => {handleDislikeClick()}}
                                disabled={isReactLoading || isDeleteReactLoading}
                                Icon={(article.userReaction === 0 && article.userReaction !== null ? (ThumbDown) : (ThumbDownOutlined))}
                                iconHtmlColor="var(--primary)"
                                title={(article.userReaction === 0 && article.userReaction !== null ? ("Dislike") : ("Undislike"))}
                                className="w-100"
                                style={{ borderRadius: 12, height: "34px" }}
                                label={article.dislikeCount.toString()}
                            />
                        </Col>
                    </Row>
                </Card>
            </Container>
        </>
    )
}