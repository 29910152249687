import { useEffect, useState } from 'react';
import { AddCircle } from '@mui/icons-material';

import { ReactComponent as AssetLeaseIcon } from '../../../assets/icons/asset-lease.svg';
import { useLeases } from '../../../services/assetlease.service';
import { Butlerr } from '../../../types/butlerr';
import { AssetTabContent } from '../Asset';
import AssetLeasesKPI from './AssetLeasesKPI';
import AssetLeasesTable from './AssetLeasesTable';
import PageHeader from '../../utils/PageHeader';
import ErrorPage from '../../utils/ErrorPage';
import AssetLeasesModal from './AssetLeasesModal';
import IconButton from '../../utils/IconButton';

interface AssetLeasesProps {
    asset: Butlerr.Asset;
}

export default function AssetLeases({ asset }: AssetLeasesProps) {
    const assetId = asset.asst_id;

    const { data: leases = [], isLoading, isError, error } = useLeases(assetId);

    // This is needed for lease adjustment CRUD.
    useEffect(() => {
        setSelectedLease((prev) => {
            //if previously selected, select new copy of the lease from data
            if (prev) {
                return leases.find((l) => l.leas_id === prev.leas_id);
            }
            return prev;
        });
    }, [leases]);

    //modal states
    const [selectedLease, setSelectedLease] = useState<Butlerr.Asset.Lease>();

    const [showAddModal, setShowAddModal] = useState(false);

    const onModalOpen = (lease?: Butlerr.Asset.Lease) => {
        setSelectedLease(lease);
        setShowAddModal(true);
    };
    const onModalClose = () => {
        setShowAddModal(false);
    };

    return (
        <>
            {/* tab header */}
            <PageHeader Icon={AssetLeaseIcon} title="Leases" />

            {/* tab content */}
            <AssetTabContent>
                {isError ? (
                    <ErrorPage status={error?.status} message={error?.message} />
                ) : (
                    <>
                        <AssetLeasesKPI leases={leases} isLoading={isLoading} asset={asset} />

                        {asset.status !== 'AR' && (
                            <div className="mb-3 d-flex justify-content-end">
                                <IconButton
                                    Icon={AddCircle}
                                    title="Add A Lease"
                                    onClick={() => onModalOpen()}
                                />
                            </div>
                        )}

                        <AssetLeasesTable
                            asset={asset}
                            leases={leases}
                            isLoading={isLoading}
                            onModalOpen={onModalOpen}
                        />
                    </>
                )}
            </AssetTabContent>

            <AssetLeasesModal
                show={showAddModal}
                onModalClose={onModalClose}
                assetId={assetId}
                initialValue={selectedLease}
                asset={asset}
            />
        </>
    );
}
