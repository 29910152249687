import { Picker, BaseEmoji } from 'emoji-mart';
import IconButton, { ButtonWithIconProps } from '../utils/IconButton';
import { InsertEmoticonRounded } from '@mui/icons-material';
import { Dropdown, DropdownProps } from 'react-bootstrap';
import styles from './ui.module.scss';
import 'emoji-mart/css/emoji-mart.css'

export interface EmojiPickerButtonProps extends Partial<ButtonWithIconProps> {
	onEmojiSelect: (emoji: BaseEmoji) => void;
	dropdownProps?: Omit<DropdownProps, 'children'>;
}

const EmojiPickerButton = ({ onEmojiSelect, dropdownProps, ...props }: EmojiPickerButtonProps) => {
    return (
		<Dropdown {...dropdownProps}>
			<Dropdown.Toggle bsPrefix=" " as="span">
				<IconButton
					transparent={true}
					border={true}
					Icon={InsertEmoticonRounded}
					{...props}
				/>
			</Dropdown.Toggle>
			<Dropdown.Menu className={styles['emoji-picker'] + " shadow"}>
				<Picker
					onSelect={onEmojiSelect}
					color="var(--primary)"
					native={true}
					showPreview={false}
					showSkinTones={false}
				/>
			</Dropdown.Menu>
		</Dropdown>
	)
}
export default EmojiPickerButton;