import { useEffect, useMemo, useState } from "react";
import { Modal, Card, Container, Row, Col, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { BaseModalProps } from "../../../hooks/useModal";
import { useChannels, useChannelMutations } from "../../../services/news.service";
import { AddCircleOutline, RemoveCircleOutline, CloseRounded, FormatListBulletedRounded, GridViewRounded } from "@mui/icons-material";
import { Butlerr } from "../../../types/butlerr";
import BootstrapSpinner from "../../utils/BootstrapSpinner";
import EmptyMessage from "../../utils/EmptyMessage";
import ErrorPage from "../../utils/ErrorPage";
import classNames from "classnames";
import IconButton from "../../utils/IconButton";
import styles from "./news.module.css";

export enum ChannelViews {
    LIST, GRID
}

export default function ChannelsModal({ show, onClose } : BaseModalProps) {

    const query = useChannels();
    const { data: channels, isLoading, error, refetch } = query;

    const [view, setView] = useState(ChannelViews.GRID);
    const [ searchVal, setSearchVal ] = useState("");

    //memoize filter results
    const filteredChannels = useMemo(() => {
        if (!channels) return undefined;

        const unsubscribed = [] as Butlerr.News.Channel[];
        const subscribed = channels.filter((ch) => {
            //If search value is present & no match, return false immediately
            const val = searchVal.toLowerCase();

            if (
                searchVal &&
                !ch.ncha_name.toLowerCase().includes(val) &&
                !ch.country?.toLowerCase().startsWith(val) &&
                !ch.ncha_country?.toLowerCase().startsWith(val)
            ) {
                return false;
            }

            if (ch.user_subscribe === 1) {
                return true;
            }
            unsubscribed.push(ch);
            return false;
        });
        return [ subscribed, unsubscribed ] as const

    }, [channels, searchVal]);


    //Use placeholders for search suggestions
    const [ placeholder, setPlaceholder ] = useState<string>();
    useEffect(() => {
        if (!searchVal) {
            return setPlaceholder(undefined);
        }
        const val = searchVal.toLowerCase();

        let match: string | undefined = undefined;
        for (const ch of channels ?? []) {
            if (ch.country?.toLowerCase().startsWith(val)) {
                match = ch.country;
                break;
            }
            if (ch.ncha_name.toLowerCase().startsWith(val)) {
                match = ch.ncha_name;
                break;
            }
        }
        if (!match) {
            return setPlaceholder(undefined);
        }

        //Match case of placeholder with entered text
        const placeholder = searchVal + match.slice(searchVal.length);
        setPlaceholder(placeholder);
    }, [channels, searchVal])

    return (
        <Modal
            show={show}
            onHide={onClose}
            size="lg"
            scrollable={true}
        >
            <Modal.Header>
                <Modal.Title>
                    Manage subscriptions
                </Modal.Title>
                <IconButton
                    Icon={CloseRounded}
                    title="Close"
                    className="ms-auto"
                    disabled={isLoading}
                    onClick={onClose}
                />
            </Modal.Header>
            <Modal.Body as={Container} className="px-3 px-lg-5">
                <Row className="mb-3">
                    <Col className="d-flex align-items-center justify-content-between">
                        <div className="position-relative w-100" style={{ maxWidth: '300px' }}>
                            <Form.Control
                                value={searchVal}
                                onKeyDown={(e) => {
                                    //if the user presses, right arrow at the end of str, set value to placeholder
                                    if (e.key === "ArrowRight" && placeholder && searchVal.length === e.currentTarget.selectionEnd) {
                                        setSearchVal(placeholder);
                                    }
                                }}
                                onChange={(e) => setSearchVal(e.target.value)}
                                className="rounded-pill position-relative bg-transparent"
                                style={{ zIndex: 1 }}
                            />
                            <span
                                className="position-absolute text-truncate w-100"
                                style={{
                                    top: '0',
                                    padding: 'calc(0.375rem + 1px) calc(0.75rem + 1px)',
                                    zIndex: 0,
                                    opacity: 0.5,
                                    color: 'currentColor'
                                }}
                            >
                                {placeholder ?? (! searchVal && "Search publication names, countries...")}
                            </span>
                        </div>
                        <div className="d-flex">
                            <IconButton
                                transparent
                                border={false}
                                Icon={FormatListBulletedRounded}
                                title="List view"
                                className={classNames(view === ChannelViews.LIST ? "text-black" : "text-secondary")}
                                onClick={() => setView(ChannelViews.LIST)}
                            />
                            {/* separator */}
                            <div
                                style={{
                                    width: '1px'
                                }}
                                className="mx-2 my-1 align-self-stretch bg-secondary"
                            ></div>
                            <IconButton
                                transparent
                                border={false}
                                Icon={GridViewRounded}
                                title="Grid view"
                                className={classNames(view === ChannelViews.GRID ? "text-black" : "text-secondary")}
                                onClick={() => setView(ChannelViews.GRID)}
                            />
                        </div>
                    </Col>
                </Row>
                {
                    filteredChannels === undefined ? (
                        isLoading ? <BootstrapSpinner /> :
                        error ? <ErrorPage message={error.message} handleRetry={refetch} /> :
                        <EmptyMessage message="Nothing to see here" />
                    )
                    : (
                        <>
                            {
                                filteredChannels[0].length > 0 && (
                                    <ChannelList
                                        header={<p className="mb-0 small fw-bold text-muted text-uppercase">Your feed subscriptions</p>}
                                        channels={filteredChannels[0]}
                                        view={view}
                                        emptyMessage={searchVal ? "No results" : undefined}
                                    />
                                )
                            }
                            {
                                filteredChannels[1].length > 0 && (
                                    <ChannelList
                                        header={<p className="mb-0 small fw-bold text-muted text-uppercase">Other feeds</p>}
                                        channels={filteredChannels[1]}
                                        view={view}
                                        emptyMessage={searchVal ? "No results" : undefined}
                                    />
                                )
                            }
                        </>
                    )
                }
            </Modal.Body>
        </Modal>
    )
}

interface ChannelListProps {
    channels: Butlerr.News.Channel[];
    view: ChannelViews;
    header?: React.ReactNode;
    emptyMessage?: string;
}

export function ChannelList({ header, emptyMessage, channels, view } : ChannelListProps) {

    const { mutate: subscribeChannel, isLoading: sL } = useChannelMutations('SUBSCRIBE');
    const { mutate: unsubscribeChannel, isLoading: usL } = useChannelMutations('UNSUBSCRIBE');

    const handleSubscribe = async (channel: Butlerr.News.Channel) => {
        if (channel.user_subscribe === 1) {
            unsubscribeChannel(channel.ncha_id);
        }
        else {
            subscribeChannel(channel.ncha_id);
        }
    }

    return (
        <>
            { header !== undefined && <Row className="mb-2">{header}</Row> }

            <Row className={classNames({
                [styles.list]: view === ChannelViews.LIST,
                [styles.grid]: view === ChannelViews.GRID,
                "mb-3": true
            })}>
                {
                    channels.length === 0 && (
                        <EmptyMessage message={emptyMessage ?? "Nothing to see here"} />
                    )
                }

                {
                    view === ChannelViews.LIST && (
                        channels.map((ch) => (
                            <ChannelListItem
                                key={ch.ncha_id}
                                channel={ch}
                                handleSubscribe={() => handleSubscribe(ch)}
                                isLoading={sL || usL}
                            />
                        ))
                    )
                }
                {
                    view === ChannelViews.GRID && (
                        channels.map((ch) => (
                            <ChannelGridItem
                                key={ch.ncha_id}
                                channel={ch}
                                handleSubscribe={() => handleSubscribe(ch)}
                                isLoading={sL || usL}
                            />
                        ))
                    )
                }
            </Row>
        </>
    )
}

interface ChannelViewItem {
    channel: Butlerr.News.Channel;
    handleSubscribe: () => void;
    isLoading: boolean;
}

function ChannelListItem({ channel, handleSubscribe, isLoading } : ChannelViewItem) {
    return (
        <Col xs={12} className="mb-2">
            <Card className={classNames(styles.channel, "shadow-sm")}>
                <Card.Body as={Container} className="p-3">
                    <Row>
                        <Col xs={2}>
                            <div className={classNames(styles.imageContainer, "p-2 shadow-sm rounded")}>
                                <img
                                    alt=""
                                    className={classNames(styles.image)}
                                    src={channel.ncha_imageurl}
                                />
                            </div>
                        </Col>
                        <Col xs={8} className="d-flex flex-column justify-content-center">
                            <p className="mb-2 fw-bold">
                                {channel.ncha_name}
                            </p>
                            <span className="text-muted">{channel.subscribe_count} Subscribers</span>
                        </Col>
                        <Col xs={2} className="d-flex justify-content-end align-items-center text-truncate" >
                            <IconButton
                                transparent
                                Icon={channel.user_subscribe === 0 ? AddCircleOutline : RemoveCircleOutline}
                                onClick={handleSubscribe}
                                iconHtmlColor="var(--dark)"
                                title={channel.user_subscribe === 0 ? "Subscribe" : "Remove subscription"}
                                disabled={isLoading}
                            />
                        </Col>
                    </Row>
                    {
                        channel.ncha_desc !== null && (
                            <Row className="mt-4">
                                <p className="mb-0">
                                    {channel.ncha_desc}
                                </p>
                            </Row>
                        )
                    }
                </Card.Body>
            </Card>
        </Col>
    )
}

function ChannelGridItem({ channel, handleSubscribe, isLoading } : ChannelViewItem) {
    return (
        <Col xs={6} lg={3}>
            <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>{channel.ncha_desc}</Tooltip>}
            >
                {({ ref, ...props }) => (
                    <Card ref={ref} {...props} className={classNames(styles.channel, "mb-2 me-2 shadow-sm position-relative")}>
                        <div className={classNames(styles.imageContainer, "px-3 py-2 shadow-sm")}>
                            <img
                                alt=""
                                className={classNames(styles.image)}
                                src={channel.ncha_imageurl}
                                title={channel.ncha_name}
                            />
                        </div>
        
                        <Card.Body className="p-3 d-flex flex-column">
                                <span className="small fw-bold text-truncate" title={channel.ncha_name}>
                                    {channel.ncha_name}
                                </span>
                                <span className="small text-muted text-truncate" style={{ fontSize: '0.7rem' }}>
                                    {channel.subscribe_count} Subscribers
                                </span>
                            <IconButton
                                transparent
                                Icon={channel.user_subscribe === 0 ? AddCircleOutline : RemoveCircleOutline}
                                onClick={handleSubscribe}
                                className="ms-auto te-corner"
                                iconHtmlColor="var(--dark)"
                                title={channel.user_subscribe === 0 ? "Subscribe" : "Remove subscription"}
                                disabled={isLoading}
                            />
                        </Card.Body>
                    </Card>
                )}
            </OverlayTrigger>
        </Col>
    )
}