import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import RemoveIcon from '@mui/icons-material/Remove';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

import { Butlerr } from '../../../types/butlerr';
import { dayDiff, formatCurrency, getCurrencySign } from '../../utils/HelperFunctions';
import AssetKPI, { KPICardInterface } from '../AssetKPI';
import LineClampText from '../../utils/LineClampText';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface AssetValuationsKPIInterface {
    asset: Butlerr.Asset;
    valuations: Butlerr.Asset.Valuation[];
    isLoading: boolean;
}

const AssetValuationsKPI = ({ asset, valuations, isLoading }: AssetValuationsKPIInterface) => {
    const kpis: KPICardInterface[] = [
        {
            title: 'Latest Valuation',
            data: isLoading ? undefined : <LatestValuation valuations={valuations} asset={asset} />,
            tooltipText: 'Display latest valuation against purchase price.',
        },
        {
            title: 'Total Growth',
            data: isLoading ? undefined : calculateTotalGrowthPercentage(valuations, asset),
            tooltipText:
                'Difference of latest valuation over the purchase price, expressed as a percentage.',
        },
        {
            title: 'CAGR',
            data: isLoading ? undefined : calculateCAGR(valuations, asset),
            tooltipText:
                'Compound Annual Growth Rate is a measure of an investment’s annual growth rate over time, with the effect of compounding taken into account and removes annual volatility over a multi-year forecast period. This calculation takes into account the latest valuation record available (assuming that the latest valuation price still holds true today), purchase price and the time from the date of settlement. ',
        },
    ];

    return <AssetKPI data={kpis} xs={1} lg={2} xl={3} />;
};

interface LatestValuationInterface {
    valuations: Butlerr.Asset.Valuation[];
    asset: Butlerr.Asset;
}

const LatestValuation = ({ valuations, asset }: LatestValuationInterface) => {
    if (valuations.length === 0) return <>NA</>;

    const latestValuation = valuations[0].valu_value;
    const purchasePrice = asset.asst_purprice;
    const growth = latestValuation - purchasePrice;

    return (
        <div className="d-flex justify-content-center align-items-center">
            {(() => {
                const displayText = `${getCurrencySign(asset.asst_currency)}${formatCurrency(
                    latestValuation,
                    {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                    }
                )}`;
                return (
                    <LineClampText maxLines={1} title={displayText} className="mb-0">
                        {displayText}
                    </LineClampText>
                );
            })()}

            <div className="my-1 d-inline-flex justify-content-center align-items-center">
                {Math.sign(growth) === 1 && (
                    <ArrowDropUpIcon fontSize="small" className="text-success" />
                )}
                {Math.sign(growth) === -1 && (
                    <ArrowDropDownIcon fontSize="small" className="text-danger" />
                )}
                {Math.sign(growth) === 0 && <RemoveIcon fontSize="small" className="text-muted" />}
                <LineClampText maxLines={1} className="text-muted fs-6 mb-0" title="Growth">
                    {`${getCurrencySign(asset.asst_currency)}${formatCurrency(Math.abs(growth), {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                    })}`}
                </LineClampText>
            </div>
        </div>
    );
};

const calculateTotalGrowthPercentage = (
    valuations: Butlerr.Asset.Valuation[],
    asset: Butlerr.Asset
) => {
    if (valuations.length === 0) return 'NA';

    const latestValuation = valuations[0].valu_value;
    const purchasePrice = asset.asst_purprice;
    const growth = latestValuation - purchasePrice;
    const growthPercentage = (growth / purchasePrice) * 100;

    return `${growthPercentage.toFixed(2)}%`;
};

const calculateCAGR = (valuations: Butlerr.Asset.Valuation[], asset: Butlerr.Asset) => {
    if (valuations.length === 0) return 'NA';

    const latestValuation = valuations[0].valu_value;
    const purchasePrice = asset.asst_purprice;
    const timeInYears = dayDiff(new Date(asset.asst_settledate ?? new Date()), new Date()) / 365;

    const CAGR = (Math.pow(latestValuation / purchasePrice, 1 / timeInYears) - 1) * 100;

    return `${CAGR.toFixed(2)}%`;
};

export default AssetValuationsKPI;
