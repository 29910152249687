import { PriceCheckOutlined, KingBedOutlined, RefreshRounded, MonetizationOnOutlined } from "@mui/icons-material";
import { ProgressBar, Table, OverlayTrigger, Tooltip, Container, Row, Col } from "react-bootstrap";
import useModal from "../../../hooks/useModal";
import { API } from "../../../types/api";
import ButlerrCard, { ButlerrCardItem } from "../../utils/ButlerrCard";
import { AddToValuationModal } from "./Modals";
import IconButton from "../../utils/IconButton";
import { Butlerr } from "../../../types/butlerr";
import { useValuations } from "../../../services/assetvaluation.service";
import { Fragment, useMemo, useRef, useState } from "react";
import { Doughnut, Line } from "react-chartjs-2";
import { KPICard } from "../AssetKPI";
import { formatDate } from "../../utils/HelperFunctions";
import { useInsightsRefresh } from "../../../services/asset.service";
import domainLogo from "../../../assets/logo_domain.png";

const CHART_COLORS = ["#ffa822", "#134e6f", "#ff6150", "#1ac0c6", "#f9b4ab", "#fdebd3", "#679186", "#bbd4ce", "#efeeb4"];

interface DomainInsightsProps {
    asset: Butlerr.Asset;
    insights: API.Domain.Insights;
    insightDate: string;
}

type DomainInsightsItem<Keys extends keyof DomainInsightsProps['insights']> = Pick<DomainInsightsProps['insights'], Keys>;

type DomainInsightsSectionProps =
    & React.HTMLAttributes<HTMLDivElement>
    & Omit<DomainInsightsProps, 'insights'>   // minus entire insights object
    

export default function DomainInsights({ insights, ...insightProps }: DomainInsightsProps) {

    const { demographics, priceEstimate, suburbStats, locationProfile } = insights;

    return (
        <>  
            <PriceEstimate
                priceEstimate={priceEstimate}
                className="mb-3"
                {...insightProps}
            />

            {locationProfile?.data?.propertyCategories !== undefined && locationProfile.data.propertyCategories.length !== 0 && (
                <LocationProfileTable locationProfile={locationProfile} className="mb-3" {...insightProps} />
            )}
            {suburbStats?.series?.seriesInfo !== undefined && suburbStats.series.seriesInfo.length !== 0 && (
                <SalesRentalTable suburbStats={suburbStats} className="mb-3" {...insightProps} />
            )}
            {locationProfile?.data?.propertyCategories !== undefined && locationProfile.data.propertyCategories.length !== 0 && (
                <LocationProfileCharts locationProfile={locationProfile} className="mb-3" {...insightProps} />
            )}
            <Demographics demographics={demographics} locationProfile={locationProfile} className="mb-3" {...insightProps} />
        </>
    )
}

type PriceEstimateProps = DomainInsightsSectionProps & DomainInsightsItem<'priceEstimate'>;
export function PriceEstimate({ priceEstimate, insightDate, asset, ...props }: PriceEstimateProps) {

    let { midPrice, lowerPrice, upperPrice, priceConfidence } = priceEstimate ?? {};

    const [modal, openModal] = useModal(AddToValuationModal, {
        valuation: midPrice ?? -1,
        valuer: "Domain API",
        valuationDate: insightDate,
        currency: "A$",
        assetId: asset.asst_id
    })

    const transformCamelCase = (text: string) => {
        const result = text.replace(/([A-Z])/g, " $1");
        return result.charAt(0).toUpperCase() + result.slice(1);
    }

    const { data: valuations } = useValuations(asset.asst_id);

    //if valuation with the same valuer (API name) & amount & date exists
    const isValuationAlreadyAdded = useMemo(() => (
        valuations?.some((val) => (
            val.valu_valuer === "Domain API" && val.valu_value === midPrice &&
            //convert to a fix format before string compare
            new Date(val.valu_date).toDateString() === new Date(insightDate).toDateString()
        )) ?? true
    ), [insightDate, midPrice, valuations])

    //insights refresh
    const { refresh, isLoading } = useInsightsRefresh();
    //time since last fetch greater than 30 days
    const canRefresh = (new Date().valueOf() - new Date(insightDate).valueOf()) > (30 * 24 * 60 * 60 * 1000);

    return (
        <div {...props}>
            <ButlerrCard
                items={{ title: "Price Estimate", Icon: MonetizationOnOutlined }}
                rightSide={(
                    <div className="d-flex align-items-center">
                        <img
                            className="me-3"
                            width={115}
                            src={domainLogo}
                            alt="powered by domain"
                        />
                        <span className="text-secondary me-3">
                            Last Refreshed: {formatDate(insightDate, 'long', 'long')}
                        </span>
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                asset.status === 'AR' ? (
                                    <Tooltip>
                                        You have already sold the asset.
                                    </Tooltip>
                                ) : !canRefresh ? (
                                    <Tooltip>
                                        You can refresh your insights once a month
                                    </Tooltip>
                                ) : <></>
                            }
                        >
                            {({ ref, ...triggerHandler }) => (
                                <span ref={ref} {...triggerHandler}>
                                    <IconButton
                                        className="rounded-4 mb-1"
                                        Icon={RefreshRounded}
                                        label="Refresh"
                                        disabled={asset.status === 'AR' || isLoading || !canRefresh}
                                        onClick={() => refresh(asset.asst_id)}
                                    />
                                </span>
                            )}
                        </OverlayTrigger>
                    </div>
                )}
            >
                {
                    !midPrice ? (
                        <h4 className="my-2 text-center">Estimated value not available</h4>
                    ) : (
                        <>
                            <div className="d-flex align-items-center justify-content-between">
                                <div style={{ width: '20%' }}></div>
                                <div className="d-flex flex-column text-center fs-4 fw-bold">
                                    <span>A${midPrice?.toLocaleString() ?? '-'}</span>
                                    <span>Property Estimate</span>
                                </div>
                                <IconButton
                                    style={{ minWidth: '20%' }}
                                    className="mb-1 rounded-4"
                                    Icon={PriceCheckOutlined}
                                    label="Add to valuation"
                                    title="Add to valuation"
                                    onClick={openModal}
                                    disabled={asset.status === 'AR' || isValuationAlreadyAdded || midPrice === undefined}
                                />
                            </div>
                            <div className="mt-3">
                                <ProgressBar>
                                    <ProgressBar variant="danger" now={33} />
                                    <ProgressBar variant="info" now={33} />
                                    <ProgressBar variant="success" now={34} />
                                </ProgressBar>
                            </div>
                            <br />
                            <div className="d-flex">
                                <span className="text-start fs-4" style={{ width: '33%' }}>
                                    {lowerPrice !== undefined && (
                                        <>
                                            Low
                                            <br />
                                            A${lowerPrice.toLocaleString()}
                                        </>
                                    )}
                                </span>
                                <span className="text-center fs-4" style={{ width: '33%' }}>
                                    {priceConfidence !== undefined && (
                                        <>
                                            Estimate Accuracy
                                            <br />
                                            <span>{transformCamelCase(priceConfidence)}</span>
                                        </>
                                    )}  
                                </span>
                                <span className="text-end fs-4" style={{ width: '33%' }}>
                                    {upperPrice !== undefined && (
                                        <>
                                            High
                                            <br />
                                            A${upperPrice.toLocaleString()}
                                        </>
                                    )}
                                </span>
                            </div>
                        </>
                    )
                }
            </ButlerrCard>

            {modal}
        </div>
    )
}

type LocationProfileProps = DomainInsightsSectionProps & Required<DomainInsightsItem<'locationProfile'>>;
export function LocationProfileTable({ locationProfile, insightDate, asset, ...props }: LocationProfileProps) {

    const cardItems: ButlerrCardItem[] = [
        {
            title: "Property Market in " + locationProfile.suburbName + " Suburb",
            content: () => (
                <Table striped bordered hover className='mb-0 text-center'>
                    <thead>
                        <tr className='text-secondary'>
                            <th>Bedroom</th>
                            <th>Type</th>
                            <th>Median Price</th>
                            <th>Avg Days On Market</th>
                            <th>Clearance Rate</th>
                            <th>Sold This Year</th>
                        </tr>
                    </thead>
                    <tbody>
                        {locationProfile?.data?.propertyCategories?.map((item, idx) => (
                            <tr key={idx}>
                                <td>
                                    {item.bedrooms}
                                    <KingBedOutlined />
                                </td>
                                <td>{item.propertyCategory}</td>
                                <td>
                                    {item.medianSoldPrice ? 'A$' + item.medianSoldPrice.toLocaleString() : '-'}
                                </td>
                                <td>{item.daysOnMarket || '-'}</td>
                                <td>
                                    {item.auctionClearanceRate ? Math.round(item.auctionClearanceRate * 100) + '%' : '-'}
                                </td>
                                <td>{item.numberSold || '-'}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )
        }
    ];

    const salesGrowthForSameBedrooms = locationProfile?.data?.propertyCategories
        ?.filter((item) => item.bedrooms === asset.asst_nobed);

    if (salesGrowthForSameBedrooms !== undefined && salesGrowthForSameBedrooms.length !== 0) {
        cardItems.push({
            title: "Sales Growth in " + locationProfile.suburbName + " Suburb",
            content: () => (
                <Table striped bordered hover className='mb-0 text-center'>
                    <thead>
                        <tr className='text-secondary'>
                            <th>Year</th>
                            <th>Bedrooms</th>
                            <th>Type</th>
                            <th>Median Sold Price</th>
                            <th>Annual Growth Percentage</th>
                            <th>Sold This Year</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            salesGrowthForSameBedrooms.map((item, idx) => (
                                <Fragment key={idx}>
                                    {
                                        item.salesGrowthList.map((sales, idx) => (
                                            <tr key={idx}>
                                                <td>{sales.year}</td>
                                                <td>{item.bedrooms}<KingBedOutlined /></td>
                                                <td>{item.propertyCategory}</td>
                                                <td>
                                                    {sales.medianSoldPrice ? 'A$' + sales.medianSoldPrice.toLocaleString() : '-'}
                                                </td>
                                                <td>
                                                    {sales.annualGrowth ? Math.round(sales.annualGrowth * 100) + '%' : '-'}
                                                </td>
                                                <td>{sales.numberSold}</td>
                                            </tr>
                                        ))
                                    }
                                </Fragment>
                            ))
                        }
                    </tbody>
                </Table>
            )
        })
    }

    return (
        <div {...props}>
            <ButlerrCard
                items={cardItems}
            />
        </div>
    )
}

type SuburbStatsProps = DomainInsightsSectionProps & Required<DomainInsightsItem<'suburbStats'>>;
export function SalesRentalTable({ suburbStats, insightDate, asset, ...props }: SuburbStatsProps) {
    return (
        <div {...props}>
            <ButlerrCard
                items={{
                    title: "Sales & Rental",
                    content: () => (
                        <div className="d-flex flex-wrap justify-content-center" style={{ gridGap: '1rem' }}>
                            <Table striped bordered hover className='mb-0 text-center flex-fill' style={{ width: '400px' }}>
                                <thead>
                                    <tr className="text-secondary">
                                        <th rowSpan={2}>Year</th>
                                        <th colSpan={4} scope="colgroup" style={{ borderBottomWidth: 1 }}>Sales</th>
                                    </tr>
                                    <tr className='text-secondary'>
                                        <th scope="col" style={{ borderRadius: 0 }}>Highest Price</th>
                                        <th scope="col">Lowest Price</th>
                                        <th scope="col">Median Price</th>
                                        <th scope="col" style={{ borderRadius: 0 }}>Number Of Listing</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        suburbStats.series?.seriesInfo?.map((series, idx) => series.values !== undefined && (
                                            <tr key={idx}>
                                                <td>{series.year || '-'}</td>
                                                <td>
                                                    {series.values.highestSaleListingPrice ? 'A$' + series.values.highestSaleListingPrice.toLocaleString() : '-'}
                                                </td>
                                                <td>
                                                    {series.values.lowestSaleListingPrice ? 'A$' + series.values.lowestSaleListingPrice.toLocaleString() : '-'}
                                                </td>
                                                <td>
                                                    {series.values.medianSaleListingPrice ? 'A$' + series.values.medianSaleListingPrice.toLocaleString() : '-'}
                                                </td>
                                                <td>{series.values.numberSaleListing || '-'}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                            <Table striped bordered hover className='mb-0 text-center flex-fill' style={{ width: '400px' }}>
                                <thead>
                                    <tr className="text-secondary">
                                        <th rowSpan={2}>Year</th>
                                        <th colSpan={4} scope="colgroup" style={{ borderBottomWidth: 1 }}>Rental</th>
                                    </tr>
                                    <tr className='text-secondary'>
                                        <th scope="col" style={{ borderRadius: 0 }}>Highest Price</th>
                                        <th scope="col">Lowest Price</th>
                                        <th scope="col">Median Price</th>
                                        <th scope="col" style={{ borderRadius: 0 }}>Number Of Listing</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        suburbStats.series?.seriesInfo?.map((series, idx) => series.values !== undefined && (
                                            <tr key={idx}>
                                                <td>{series.year || '-'}</td>
                                                <td>
                                                    {series.values.highestRentListingPrice ? 'A$' + series.values.highestRentListingPrice.toLocaleString() : '-'}
                                                </td>
                                                <td>
                                                    {series.values.lowestRentListingPrice ? 'A$' + series.values.lowestRentListingPrice.toLocaleString() : '-'}
                                                </td>
                                                <td>
                                                    {series.values.medianRentListingPrice ? 'A$' + series.values.medianRentListingPrice.toLocaleString() : '-'}
                                                </td>
                                                <td>{series.values.numberRentListing || '-'}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </div>
                    )
                }}
            />
        </div>
    )
}

export function LocationProfileCharts({ locationProfile, insightDate, asset, ...props }: LocationProfileProps) {

    const getLineProps = (title: typeof cardItems[number]['title']) => {

        const labels = locationProfile.data?.propertyCategories
            ?.flatMap((cat) => cat.salesGrowthList.map(sale => sale.year))
            .filter((year, idx, arr): year is number => year !== undefined && arr.findIndex(y => y === year) === idx)
            .sort((a, b) => b - a) ?? [];

        const datasets = locationProfile.data?.propertyCategories?.map((cat, idx) => {

            const data = title === "Sales Growth" ? (
                labels.map((year) => (
                    cat.salesGrowthList.find(sale => sale.year === year)?.medianSoldPrice ?? 0
                ))
            ) : title === "Annual Growth Percentage" ? (
                labels.map((year) => (
                    Math.round((cat.salesGrowthList.find(sale => sale.year === year)?.annualGrowth ?? 0) * 100)
                ))
            ) : (
                labels.map((year) => (
                    cat.salesGrowthList.find(sale => sale.year === year)?.numberSold ?? 0
                ))
            );

            return {
                label: cat.propertyCategory + " with " + cat.bedrooms + " bedrooms",
                data,
                fill: true,
                backgroundColor: CHART_COLORS[idx] + "80",
                borderColor: CHART_COLORS[idx],
                pointBorderColor: "#404040",
                pointBorderWidth: 4,
                pointRadius: 2,
                tension: 0.3
            }
        }) ?? []

        const options = {
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    position: 'top' as const,
                    display: true
                },
                title: {
                    display: false,
                },
            }
        };

        return {
            data: { labels, datasets },
            options
        }
    }

    const cardItems = [
        {
            title: "Sales Growth" as const
        },
        {
            title: "Annual Growth Percentage" as const,
        },
        {
            title: "Number sold" as const,
        }
    ];

    return (
        <div {...props}>
            <ButlerrCard
                items={cardItems}
            >
                {({ title }) => (
                    <div style={{ height: '400px' }}>
                        <Line {...getLineProps(title)} />
                    </div>
                )}
            </ButlerrCard>
        </div>
    )
}

type DemographicsProps = DomainInsightsSectionProps
&   DomainInsightsItem<'demographics'>
&   DomainInsightsItem<'locationProfile'>;

type DemographicsTypes =
|   "Household Income (A$)"
|   "Rent Per Week (A$)"
|   "Employment Status"
|   "Occupation"
|   "Marital Status"
|   "Nature Of Occupancy"
|   "Age Group"
|   "Transport To Work"
|   "Education Attendance"
|   "Country Of Birth";

interface DemographicsChartItem {
    /**
     * key returned by API as `type` property
     */
    key: string;
    /**
     * If the data exists for this chart
     */
    title: DemographicsTypes;
    /**
     * labels array for chart or predicate with list of items
     * If unspecified, will take the `label` property from the item
     */
    labels?:
    |   string[]
    |   ((items: NonNullable<API.Domain.Demographics['items']>) => string[]);
    /**
     * values array for chart or predicate with list of items
     * If unspecified, will take the `value` property from the item
     */
    values?:
    |   number[]
    |   ((items: NonNullable<API.Domain.Demographics['items']>) => number[]);
}

export function Demographics({ demographics, locationProfile, insightDate, asset, ...props }: DemographicsProps) {

    const chartItems : DemographicsChartItem[] = [
        {
            key: "HouseholdIncome",
            title: "Household Income (A$)",
            labels: [
                "0 - 499",
                "500 - 999",
                "1000 - 1999",
                "2000 - 2999",
                "3000+",
                "Others"
            ],
            values: (items) => {
                const ranges = [
                    [0, 499],
                    [500, 999],
                    [1000, 1999],
                    [2000, 2999],
                    [3000]
                ];
                return items.reduce((prev, curr) => {
                    if (!curr.value) return prev;

                    if (!curr.label) {
                        //if value exists, add to `Not stated` & return
                        prev[5] += curr.value;
                    }
                    else {
                        const split = curr.label.split(" to ");
                        if (split.length === 2) {
                            //it's a range
                            //find the first range where the lower ceiling is more than the lower ceiling of our range &
                            // AND ( upper ceiling is less than the upper ceiling of our range OR there's no up, meaning it's our last range)
                            const idx = ranges.findIndex(([low, up]) => Number(split[0]) >= low && (Number(split[1]) <= up || up === undefined));

                            if (idx !== -1) {
                                prev[idx] += curr.value;
                            }
                        }
                        else if (curr.label.endsWith("+")) {
                            //it's a highest range
                            prev[4] += curr.value;
                        }
                        else {
                            prev[5] += curr.value;
                        }
                    }
                    return prev;
                }, Array<number>(6).fill(0));
            }
        }, {
            key: "Rent",
            title: "Rent Per Week (A$)",
            labels: [
                "0 - 274",
                "275 - 449",
                "450 - 649",
                "650 - 849",
                "850+",
                "Not Stated"
            ],
            values: (items) => {
                const ranges = [
                    [0, 274],
                    [275, 449],
                    [450, 649],
                    [650, 849],
                    [850]
                ];
                return items.reduce((prev, curr) => {
                    if (!curr.value) return prev;

                    if (!curr.label) {
                        //if value exists, add to `Not stated` & return
                        prev[5] += curr.value;
                    }
                    else {
                        const split = curr.label.split(" to ");
                        if (split.length === 2) {
                            //it's a range
                            //find the first range where the lower ceiling is more than the lower ceiling of our range &
                            // AND ( upper ceiling is less than the upper ceiling of our range OR there's no up, meaning it's our last range)
                            const idx = ranges.findIndex(([low, up]) => Number(split[0]) >= low && (Number(split[1]) <= up || up === undefined));

                            if (idx !== -1) {
                                prev[idx] += curr.value;
                            }
                        }
                        else if (curr.label.endsWith("+")) {
                            //it's a highest range
                            prev[4] += curr.value;
                        }
                        else {
                            prev[5] += curr.value;
                        }
                    }
                    return prev;
                }, Array<number>(6).fill(0));
            }
        }, {
            key: "NonSchoolQualification",
            title: "Employment Status",
        }, {
            key: "Occupation",
            title: "Occupation",
        }, {
            key: "MaritalStatus",
            title: "Marital Status",
        }, {
            key: "NatureOfOccupancy",
            title: "Nature Of Occupancy",
        }, {
            key: "AgeGroupOfPopulation",
            title: "Age Group",
            labels: (items) => items.map(i => i.label?.replace(" to ", " - ") ?? "-")
        }, {
            key: "TransportToWork",
            title: "Transport To Work",
            labels: [
                "Public",
                "Private",
                "No Transport"
            ],
            values: (items) => {
                return items.reduce((prev, curr) => {
                    switch (curr.label) {
                        case "Car (Pas.)":
                        case "Train":
                        case "Ferry":
                        case "Taxi":
                        case "Truck":
                        case "Tram":
                        case "Bus":
                        case "Bus & Car (Pas.)":
                        case "Bus & Tram":
                        case "Bus & Ferry":
                        case "Bus & Other":
                        case "Bus & other two":
                        case "Train & Tram":
                        case "Train & Ferry":
                        case "Train & Other":
                        case "Train & Car (Pas.)":
                        case "Train & Bus":
                        case "Train & other two":
                        case "Other three":
                        case "Other two":
                        case "Other":
                            prev[0] += curr.value ?? 0;
                            break;
                        case "Car (driver)":
                        case "Bicycle":
                        case "Motorbike/scooter":
                        case "Bus & Car (driver)":
                        case "Train & Car (driver)":
                            prev[1] += curr.value ?? 0;
                            break;
                        case "Worked at home":
                        case "Walked only":
                        case "Did not go to work":
                        case "Not Stated":
                        default:
                            prev[2] += curr.value ?? 0;
                            break;
                    }
                    return prev;
                }, Array<number>(3).fill(0))
            }
        }, {
            key: "EducationAttendance",
            title: "Education Attendance",
        }, {
            key: "CountryOfBirth",
            title: "Country Of Birth",
            labels: (items) => {
                //sort descending & take first 10 items
                return items.sort((a, b) => (b.value ?? 0) - (a.value ?? 0)).map(i => i.label ?? "-").slice(0, 10)
            },
            values: (items) => {
                //sort descending & take first 10 items
                return items.sort((a, b) => (b.value ?? 0) - (a.value ?? 0)).map(i => i.value ?? 0).slice(0, 10)
            }
        }
    ];

    const chartDataExists = (key: string) => {
        return demographics?.demographics?.find(d => d.type === key)?.items ?? false;
    }

    const [ selectedCharts, setSelectedCharts ] = useState<DemographicsChartItem[]>(() => {
        return chartItems.filter(c => chartDataExists(c.key)).slice(0, 4)
    });

    if (demographics === undefined && locationProfile === undefined) {
        return <></>
    }

    const generateCharts = (charts: DemographicsChartItem[], startingIndex = 0) => {
        return charts.map((currChart, index) => {
            const idx = index + startingIndex;

            //title must be current chart or not be in the selectedCharts at all & chart data must exist
            const items = chartItems
                .filter(({ key, title }) =>
                    (title === currChart.title || selectedCharts.findIndex(c => c.title === title) === -1)
                    && chartDataExists(key)
                )
                .map(c => ({ label: c.title, value: c.title }))

            return (
                <Col
                    xs={12}
                    lg={6}
                    key={idx}
                >
                    <KPICard
                        dropdownItems={items}
                        dropdownValue={currChart.title}
                        title={currChart.title}
                        style={{ maxHeight: '400px' }}
    
                        dropdownChange={(newTitle: string) => {
                            const newChart = chartItems.find(i => i.title === newTitle);
                            if (!newChart) return;
    
                            setSelectedCharts(prev => {
                                prev.splice(idx, 1, newChart);
                                return [ ...prev ];
                            })
                        }}
                        data={() => {
                            const { items } = demographics?.demographics?.find(d => d.type === currChart.key) ?? {};
    
                            //shouldn't happen as items are already filtered
                            if (!items) return <></>;
    
                            const { labels: _labels, values: _values } = currChart;
    
                            const labels = _labels === undefined ?
                                //if not specified, map to `label` property
                                items.map(i => i.label ?? '-') :
                                typeof _labels === 'function' ?
                                //if function, call predicate with array of items
                                _labels(items) :
                                _labels;
    
                            const values = _values === undefined ?
                                //if not specified, map to `value` property
                                items.map(i => i.value ?? 0) :
                                typeof _values === 'function' ?
                                //if function, call predicate with array of items
                                _values(items) :
                                _values;
    
                            return (
                                <DemographicsChart labels={labels} values={values} />
                            )
                        }}
                    />
                </Col>
            )
        })
    }

    return (
        <div {...props}>
            <ButlerrCard items={{ title: "Suburb Demographics" }}>
                {
                    locationProfile?.data !== undefined && (
                        <div className="d-flex flex-wrap justify-content-stretch" style={{ gridGap: '1rem' }}>
                            {
                                Number(locationProfile.data.population) > 0 && (
                                    <KPICard
                                        className="flex-grow-1 mb-4"
                                        title="Population"
                                        data={Number(locationProfile.data.population).toLocaleString()}
                                    />
                                )
                            }
                            {
                                Number(locationProfile.data.mostCommonAgeBracket) > 0 && (
                                    <KPICard
                                        className="flex-grow-1 mb-4"
                                        title="Most Common Age"
                                        data={locationProfile.data.mostCommonAgeBracket}
                                    />
                                )
                            }
                            {
                                Number(locationProfile.data.ownerOccupierPercentage) > 0 && (
                                    <KPICard
                                        className="flex-grow-1 mb-4"
                                        title="Renter"
                                        data={Math.round(locationProfile.data.ownerOccupierPercentage! * 100) + "%"}
                                    />
                                )
                            }
                            {
                                Number(locationProfile.data.marriedPercentage) > 0 && (
                                    <KPICard
                                        className="flex-grow-1 mb-4"
                                        title="Family"
                                        data={Math.round(locationProfile.data.marriedPercentage! * 100) + "%"}
                                    />
                                )   
                            }
                        </div>
                    )
                }
                <Container className="p-0">
                    <Row className="d-flex flex-wrap justify-content-center mb-2">
                        {generateCharts(selectedCharts.slice(0,2))}
                    </Row>
                    <Row className="d-flex flex-wrap justify-content-center">
                        {generateCharts(selectedCharts.slice(2,4), 2)}
                    </Row>
                </Container>
            </ButlerrCard>
        </div>
    )
}

interface DemographicsChartProps {
    labels: string[];
    values: number[];
}

export function DemographicsChart({ labels, values } : DemographicsChartProps) {
    
    const _labels : string[] = [];
    const _values : number[] = [];

    for (let idx = 0; idx < values.length; idx++) {
        //filter out entries that are 0;
        if (values[idx]) {
            _values.push(values[idx]);
            _labels.push(labels[idx]);
        }
    }

    const data = {
        labels: _labels,
        datasets: [
            {
                label: '',
                data: _values,
                fill: true,
                backgroundColor: CHART_COLORS
            }
        ]
    };

    const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

    //info for tooltip tuple of [ text , left position, top position ]
    const [ legendTooltip, setLegendTooltip ] = useState<[ string, number, number ]>();

    return (
        <div className="position-relative">
            <Doughnut
                data={data}
                options={{
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            position: 'right' as const,
                            display: true,
                            onHover: function(event, legendItem) {
                                /**
                                 * The type suggests the index exists as `datasetIndex` but it is `index`
                                 */
                                const index = legendItem.datasetIndex ?? (legendItem as any).index;
                                const text = _labels[index];

                                const x = (event.x ?? 0);
                                const y = (event.y ?? 0) + 5;

                                if (text !== undefined) {
                                    //cancel any previous callbacks
                                    if (timeoutRef.current) clearTimeout(timeoutRef.current);

                                    timeoutRef.current = setTimeout(() => {
                                        setLegendTooltip([ text, x, y ])
                                    }, 500);
                                }
                            },
                            onLeave: function() {
                                //cancel any previous callbacks
                                if (timeoutRef.current) clearTimeout(timeoutRef.current);
                                
                                timeoutRef.current = setTimeout(() => {
                                    setLegendTooltip(undefined)
                                }, 100)
                            },
                            labels: {
                                //Show first 6 legends
                                filter: (legendItem) => {
                                    /**
                                     * The type suggests the index exists as `datasetIndex` but it is `index`
                                     */
                                    const index = legendItem.datasetIndex ?? (legendItem as any).index;
                                    return index < 6;
                                }
                            }
                        }
                    },
                    scales: {
                        x: {
                            display: false,
                        },
                        y: {
                            display: false,
                        }
                    }
                }}
            />
            {legendTooltip !== undefined && (
                <div
                    className="position-absolute rounded-1 small text-light text-nowrap"
                    style={{
                        background: 'rgba(0,0,0,0.8)',
                        padding: '0.1rem 0.35rem',
                        fontSize: '0.7rem',
                        zIndex: 1000,
                        fontFamily: 'sans-serif',
                        left: legendTooltip[1],
                        top: legendTooltip[2],
                    }}
                >
                    {legendTooltip[0]}
                </div>
            )}
        </div>
    )
}