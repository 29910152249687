import { useState, useMemo } from "react";
import { Container, Row, Col  } from "react-bootstrap";
import { formatDate } from "../../utils/HelperFunctions";
import { Card } from 'react-bootstrap';
 
import { Bookmark, BookmarkBorder, ShareRounded } from "@mui/icons-material";
import IconButton from "../../utils/IconButton";
import BootstrapSpinner from "../../utils/BootstrapSpinner";
import { useNewsHistory, useNewsMutations } from "../../../services/news.service";
import { ShareNewsModal } from "./News";
import ErrorPage from "../../utils/ErrorPage";
import EmptyMessage from "../../utils/EmptyMessage";
import { Butlerr } from "../../../types/butlerr";

export default function NewsHistory({ searchValue }  : { searchValue: string }) {

    const { history, query: { isLoading, error, refetch } } = useNewsHistory();
    //memoize filter results
    const filteredNews = useMemo(() => {
        if (!history || history.length === 0) return undefined;

        let arr : Butlerr.News.History[];
        if (!searchValue) {
            arr = history;
        }
        else {
            const searchFn = (val: string, item: Butlerr.News.History) => {
                //filter by search
                let dateSearch = false;

                if (item.nclk_datetime) {
                    const clickDate = new Date(item.nclk_datetime);
                    const day = clickDate.getDate().toString();
                    const year = clickDate.getFullYear().toString();
                    const month = clickDate.toLocaleString('en-US', { month: 'long' }).toLowerCase();

                    dateSearch = day.includes(val) || year.includes(val) || month.includes(val);
                }

                return dateSearch || (
                    item.news_title.toLowerCase().includes(val) ||
                    item.ncha_name.toLowerCase().includes(val)
                );
            }

            const searchQuery = searchValue.toLowerCase().split(" ");
            arr = history.filter((item) => {
                return searchQuery.every(val => searchFn(val, item))
            })
        }
        
        return arr.reduce((accu, curr) => {
            const date = formatDate(curr.nclk_datetime)!
            if (accu[date] === undefined) {
                //setup key for date
                accu[date] = []
            }
            accu[date].push(curr);
            return accu
        }, {} as Record<string, Butlerr.News.History[]>)
        
    }, [history, searchValue])

    if (filteredNews === undefined) {
        if (isLoading) {
            return <BootstrapSpinner />
        }
        else if (error) {
            return <ErrorPage message={error.message} handleRetry={refetch} />
        }
        return (
            <EmptyMessage
                className="fs-5"
                message={searchValue ? "No results" : "Nothing to see here"}
            />
        );
    }

    return (
        <Container fluid={true}>
            {
                Object.entries(filteredNews).map(([ date, news ]) => (
                    <Row className="my-2">
                        <Col xs={12} key={date + news.length}>
                            <h4 className="mb-2">{date}</h4>
                            {
                                news.map((item, idx) => (
                                    <HistoryItem
                                        key={`${item.news_id}_${idx}`}
                                        newsItem={item}
                                    />
                                ))
                            }
                        </Col>
                    </Row>
                ))
            }
        </Container>
    )
}

interface HistoryItemProps {
    newsItem: Butlerr.News.History;
}
function HistoryItem ({ newsItem }: HistoryItemProps) {

    const { mutate: bookmark } = useNewsMutations('BOOKMARK');
    const { mutate: removeBookmark } = useNewsMutations('REMOVE_BOOKMARK');

    const handleNewsBookmark = async () => {
        if (newsItem.bookmarked) {
            removeBookmark(newsItem.news_id)
        }
        else {
            bookmark(newsItem.news_id)
        }
    }

    //share modal
    const [ showShare, setShowShare ] = useState(false);

    return (
        <Card as={Container} className="rounded my-2 p-0">
            <Card.Body as={Row} className="py-3 align-items-center">
                <Col xs={10}>
                    <p className="mb-0 text-truncate overflow-hidden">
                        <a
                            className="text-start fw-bold"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: 'black' }}
                            href={newsItem.news_url}
                        >
                            {newsItem.news_title}
                        </a>
                    </p>
                    <small className="text-muted">
                        {newsItem.ncha_name}
                    </small>
                </Col>
                <Col xs={2} className="d-flex justify-content-end">
                    <IconButton
                        transparent
                        Icon={newsItem.bookmarked ? Bookmark : BookmarkBorder}
                        onClick={handleNewsBookmark}
                        className="ms-auto me-2 text-dark"
                        title="Bookmark"
                    />
                    <IconButton
                        transparent
                        Icon={ShareRounded}
                        onClick={() => setShowShare(true)}
                        className="text-dark"
                        title="Share"
                    />
                </Col>
            </Card.Body>

            <ShareNewsModal
                show={showShare}
                onClose={() => setShowShare(false)}
                content={newsItem.news_title}
                link={newsItem.news_url}
            />
        </Card>
    )
}
