import { Butlerr } from '../types/butlerr';
import useButlerrAPI, { ButlerrMutationOptions, handleAxiosError, useButlerrMutation } from './useButlerrAPI';
import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useState } from 'react';
import axios from 'axios';
import { useQueryClient } from 'react-query';

export function formatGoogleAddress(asset: Butlerr.Asset) {
	const googleAddress = asset.asst_googleaddress;
	const floor = asset.asst_floor;
	const unitNo = asset.asst_unitno;

	let full = googleAddress;

	switch (asset.asst_country) {
		case 'AU':
			// check if '/' is present
			if (!googleAddress.includes('/')) {
				// if not present, add unit number
				full = `${unitNo ? unitNo + '/' : ''}${googleAddress}`;
			}
			break;
		case 'SG':
			const googleAddressArray = googleAddress.split(',');
			const firstLine = googleAddressArray.shift();
			full = `${firstLine}, ${floor && unitNo ? `#${floor}-${unitNo}, ` : floor ? floor + ', ' : unitNo ? unitNo + ', ' : ''}${googleAddressArray}`;
			break;
		default:
			full = `${floor ? 'Floor ' + floor + ', ' : ''}${unitNo ? 'Unit ' + unitNo + ', ' : ''}${googleAddress}`;
			break;
	}

	const firstLine = full.split(',')[0];

	return { firstLine, full };
}

export function initialiseAssetObject(asset: Butlerr.Asset) {
	const requiredFields = [
		'asst_id',
		'asst_name',
		'asst_code',
		//'asst_datasourceid', // system controlled field
		'asst_type',
		'asst_googleaddress',
		'asst_floor',
		'asst_unitno',
		//'asst_streetno', // system controlled field (?)
		'asst_streetname', // required by backend validator
		'asst_estatename',
		'asst_country', // required by backend validator
		//'asst_region', // system controlled field (?)
		'asst_suburb', // to be removed later
		//'asst_city', // system controlled field (?)
		//'asst_postcode', // system controlled field (?)
		'asst_desc',
		'asst_landsize',
		'asst_sitecover',
		'asst_intsize',
		'asst_extsize',
		'asst_nolevel',
		'asst_yrbuilt',
		'asst_contractdate',
		'asst_purprice',
		'asst_settledate',
		'asst_nobed',
		'asst_nobath',
		'asst_nopowder',
		'asst_nokitchen',
		'asst_nostudy',
		'asst_nostore',
		'asst_nodining',
		'asst_noliving',
		'asst_nocarspace',
		'asst_noensuite',
		'asst_pool',
		'asst_tennis',
		'asst_backyard',
		'asst_frontyard',
		'asst_airconditioning',
		'asst_heating',
		'asst_fireplace',
		'asst_solarpower',
		//'asst_geoloc', // system controlled field
		'asst_pmgrid',
		'asst_status',
	];
	const originalAsset: Butlerr.Asset = requiredFields.reduce((accumulator, currentValue) => {
		return { ...accumulator, ...{ [currentValue]: asset[currentValue as keyof typeof asset] } };
	}, {} as Butlerr.Asset);

	return originalAsset;
}

// google maps reverse geocoding api (get address from lat lng)
export async function getAddressFromLatLng(location: { lat: number; lng: number }) {
	const geocoder = new google.maps.Geocoder();
	const response = await geocoder.geocode({ location });
	if (response.results[0]) {
		return response.results[0];
	} else {
		throw new Error('No results found');
	}
}
export async function getLatLngFromAddress(address: string) {
	if (!address) throw new Error('Address cannot be empty');

	const geocoder = new google.maps.Geocoder();
	const response = await geocoder.geocode({ address });

	if (!response.results?.[0]?.geometry?.location) {
		throw new Error("No results found");
	}
	return response.results[0].geometry.location

	/*
	const encodedAddress = encodeURIComponent(address);
	const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&key=${String(
		process.env.REACT_APP_GOOGLE_MAPS_API_KEY
	)}`;
	try {
		const response = await _fetch(url, {
			method: 'GET',
		});
		return response;
	} catch (error) {
		throw error;
	}
	*/
}

export interface AssetExternalDetailsParams {
	unit_no?: string;
	postal_code?: string;
	address?: string;
}

export const AssetQueries = {
	ASSETS: ['assets'] as const,
	ASSET: (assetId: number) => ['asset', assetId] as const,
	ASSET_EXTERNAL: (country: string, params: AssetExternalDetailsParams) => ['asset', 'external', country, params ] as const,
	COUNTRY_CURRENCY: (country: string) => ['asset', 'currency', country] as const,
	COUNTRIES: ['asset', 'countries'] as const,
	PARTNERASSETS: ['asset', 'userId', 'partner'] as const,
	ASSET_INSIGHTS: (assetId: number) => ['asset', assetId, 'insights'] as const
}

export const assetBaseRoute = '/api/assets'

export function usePartnerAssets(enabled = true) {
	return useButlerrAPI<Butlerr.ClientAsset[]>(AssetQueries.PARTNERASSETS, `${assetBaseRoute}/work/partner`, true, {
		enabled
	});
}

export function useAssets() {
	return useButlerrAPI<Butlerr.Asset[]>(AssetQueries.ASSETS, assetBaseRoute);
}

export function useAsset(assetId: number) {
	return useButlerrAPI<Butlerr.Asset>(AssetQueries.ASSET(assetId), `${assetBaseRoute}/${assetId}`)
}

export function useButlerrCountries() {
	return useButlerrAPI<Butlerr.Country[]>(AssetQueries.COUNTRIES, `${assetBaseRoute}/details/countries`);
}

export const AssetMutations = {
	CREATE: (asset: Partial<Butlerr.Asset>) => ({
		url: assetBaseRoute,
		method: 'POST',
		requestBody: asset,
		queryKey: AssetQueries.ASSETS,
	}),
	EDIT: ({ asst_id, ...asset }: Butlerr.Asset): ButlerrMutationOptions => ({
		url: `${assetBaseRoute}/${asst_id}`,
		method: 'PUT',
		requestBody: asset,
		updater: [
			{
				queryKey: AssetQueries.ASSET(asst_id),
				optimistic: true,
				action: (prev) => ({
					...prev,
					...asset
				} as Butlerr.Asset),
			},
			{
				queryKey: AssetQueries.ASSETS,
				optimistic: true,
				action: (prev) => prev?.map((a: Butlerr.Asset) => {
					if (a.asst_id !== asst_id) return a;
					return {
						...a,
						...asset,
					}
				}),
			}
		]
	}),
	EDIT_PROFILE: ({ asst_id, ...body }: Pick<Butlerr.Asset, 'asst_id'> & Pick<Butlerr.Document.File, 'doc_id'>) => ({
		url: `${assetBaseRoute}/${asst_id}/profile`,
		method: 'PUT',
		requestBody: body,
		updater: [
			{
				queryKey: AssetQueries.ASSET(asst_id),
				optimistic: true,
				action: (prev: Butlerr.Asset) => ({
					...prev,
					asst_profilepic: body.doc_id
				})
			},
			{
				queryKey: AssetQueries.ASSETS,
				optimistic: true,
				action: (prev?: Butlerr.Asset[]) => prev?.map(a => a.asst_id !== asst_id ? a : ({
					...a, asst_profilepic: body.doc_id
				})) ?? []
			}
		]
	}),
	SELL: ({ asst_id, ...asset }: Pick<Butlerr.Asset, 'asst_id' | 'asst_selldate' | 'asst_sellprice'>): ButlerrMutationOptions => ({
		url: `${assetBaseRoute}/${asst_id}/sale`,
		method: 'PUT',
		requestBody: asset,
		queryKey: AssetQueries.ASSET(asst_id), // refetch to let the backend calculate the asset status
	}),
	DELETE: (assetId: number): ButlerrMutationOptions => ({
		url: `${assetBaseRoute}/${assetId}`,
		method: 'DELETE',
		queryKey: AssetQueries.ASSETS,
	}),
}

export function useAssetMutations<Key extends keyof typeof AssetMutations>(key: Key) {
	//You won't get type suggestions in the mutate function without this assertion
	const mutationFn = AssetMutations[key] as (params: Parameters<typeof AssetMutations[Key]>[0]) => ButlerrMutationOptions;
	return useButlerrMutation(mutationFn)
}

export function useAssetExternalSearch(
	country: string,
	params: AssetExternalDetailsParams,
	options?: {
		enabled?: boolean;
	}
) {

	const searchParams = new URLSearchParams();
	searchParams.set("country", country);

    for (const key in params) {
        const value = params[key as keyof typeof params]
        if (value !== undefined) {
            searchParams.set(key, String(value));
        }
    }

	return useButlerrAPI<Partial<Butlerr.Asset>[]>(AssetQueries.ASSET_EXTERNAL(country, params), `${assetBaseRoute}/details/external/search?${searchParams.toString()}`, true, {
		...options,
		cacheTime: 1000 * 60,
		retry: false,
		refetchOnMount: false,
		refetchOnWindowFocus: false,

	});
}

export function useCountryCurrency(
	country: string,
	options?: {
		enabled?: boolean;
	}
) {
	return useButlerrAPI<Pick<Butlerr.Asset, 'asst_currency'>>(AssetQueries.COUNTRY_CURRENCY(country), `${assetBaseRoute}/details/currency/${country}`, true, {
		...options,
		cacheTime: 1000 * 60,
		retry: false,
		refetchOnMount: false,
		refetchOnWindowFocus: false
	})
}

export function useAssetInsights(assetId: number) {
	return useButlerrAPI<Butlerr.Asset.Insights>(AssetQueries.ASSET_INSIGHTS(assetId), `${assetBaseRoute}/${assetId}/insights`);
}

export function useInsightsRefresh() {
	const { getAccessTokenSilently } = useAuth0();

	const [ isLoading, setIsLoading ] = useState(false);

	const queryClient = useQueryClient();

	const refresh = useCallback(async (assetId: number) => {
		try {
			setIsLoading(true);

			const token = await getAccessTokenSilently();
			const response = await axios.get(`${assetBaseRoute}/${assetId}/insights?forced=true`, {
				headers: {
					'authorization': `Bearer ${token}`
				},
				responseType: 'json',
			})
			//update cache with new data
			queryClient.setQueryData(AssetQueries.ASSET_INSIGHTS(assetId), () => response.data);
		}
		catch(err) {
			try {
				handleAxiosError(err);
			} catch(err) {
				alert(err.message);
			}
		}
		finally {
			setIsLoading(false);
		}

	}, [getAccessTokenSilently, queryClient])

	return {
		refresh,
		isLoading
	}
}