import { Form, Modal } from "react-bootstrap";
import { BaseModalProps } from "../../hooks/useModal";
import * as yup from "yup";
import { CloseRounded, CheckCircleOutlineRounded, Close, Email } from "@mui/icons-material";
import { Formik } from "formik";
import { BootstrapInput } from "./FormikBootstrapInputs";
import { formCloseHandler } from "./HelperFunctions";
import IconButton from "./IconButton";
import { useButlerrUser, useUserMutations } from "../../services/user.service";
import { useEffect, useState } from "react";

interface InviteUsersModalProps extends BaseModalProps {
    initialEmail?: string;
}

export default function InviteUsersModal({ initialEmail, show, onClose } : InviteUsersModalProps) {

    const schema = yup.object().shape({
        inv_email: yup.string().required("Please enter an email").max(100, "Email must be less than 100 characters").email("Invalid email"),
        inv_msg: yup.string().max(160, ({max}) => `Invitation message must be less than ${max} characters`),
    });

    const { mutate: invite, isLoading, isSuccess, reset } = useUserMutations('INVITE_USER');

    const [ error, setError ] = useState<string>();

    useEffect(() => {
        reset();
    }, [show, reset])

    const { data: currUser } = useButlerrUser();

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                inv_email: initialEmail ?? "",
                inv_msg: "",
            }}
            validationSchema={schema}
            onSubmit={(val) => {
                invite({
                    inv_email: [val.inv_email],
                    inv_msg: val.inv_msg
                }, {
                    onSettled: (data, err) => {
                        const error = (err?.message ?? data?.response.data?.[0]?.error) || undefined;
                        setError(error);
                    }
                })
            }}
        >
            {({ setFieldValue, handleSubmit, dirty }) => (
                <Modal
                    centered
                    show={show}
                    onHide={() => formCloseHandler(onClose, dirty)}
                    keyboard={!isLoading}
                    backdrop={isLoading ? "static" : undefined}
                    className="modal-layer-1"
                    backdropClassName="modal-layer-1"
                >
                    <Form noValidate onSubmit={handleSubmit}>
                        <Modal.Header>
                            <Modal.Title>Invite user</Modal.Title>
                            <IconButton
                                title="Close modal"
                                Icon={CloseRounded}
                                className="ms-auto"
                                disabled={isLoading}
                                onClick={() => formCloseHandler(onClose, dirty)}
                            />
                        </Modal.Header>
                        <Modal.Body className="position-relative">
                            <div className="mb-3">
                                <BootstrapInput
                                    id="inv_email"
                                    placeholder="Email"
                                    label="Invite Email"
                                    onChange={(e) => {
                                        setFieldValue('inv_email', e.target.value);
                                        setError(undefined);
                                    }}
                                    disabled={isLoading}
                                />
                            </div>
                            <div className="mb-3">
                                <BootstrapInput
                                    as="textarea"
                                    id="inv_msg"
                                    label="Invite message"
                                    required={false}
                                    placeholder={"You've been invited to join propcircle.co" + (!currUser ? "" : ` by ${currUser.user_socialhandle}`)}
                                    disabled={isLoading}
                                />
                            </div>
                            {
                                isSuccess && error === undefined && (
                                    <div
                                        className="position-absolute w-100 h-100 bg-white d-flex flex-column align-items-center justify-content-center"
                                        style={{ top: 0, left: 0 }}
                                    >
                                        <CheckCircleOutlineRounded
                                            color="success"
                                            style={{ fontSize: '50px' }}
                                        />
                                        <h4 className="mt-3 mb-0">Invite sent</h4>
                                    </div>
                                )
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            {
                                error !== undefined && <p className="text-danger text-truncate w-100">{error}</p>
                            }
                            {
                                isSuccess && error === undefined ? (
                                    <IconButton
                                        Icon={Close}
                                        className="mx-auto px-4"
                                        label="Close"
                                        onClick={() => onClose()}
                                    />
                                ) : (
                                    <>
                                        <IconButton
                                            transparent
                                            Icon={Close}
                                            iconHtmlColor="var(--primary)"
                                            className="border border-primary text-primary"
                                            onClick={() => formCloseHandler(onClose, dirty)}
                                            label="Cancel"
                                            disabled={isLoading}
                                        />
                                        <IconButton
                                            Icon={Email}
                                            type="submit"
                                            disabled={isLoading}
                                            label={isLoading ? 'Loading...' : 'Invite'}
                                        />
                                    </>
                                )
                            }
                        </Modal.Footer>
                    </Form>
                </Modal>
            )}
        </Formik>
    )
}