import { useCallback, useMemo } from 'react';
import { Column } from 'react-table';
import { Butlerr } from '../../../types/butlerr';
import { formatCurrency, formatDate, getCurrencySign } from '../../utils/HelperFunctions';
import ReactTable from '../../utils/ReactTable';

interface AssetLeasesTableInterface {
    asset: Butlerr.Asset;
    leases: Butlerr.Asset.Lease[];
    isLoading: boolean;
    onModalOpen: (lease?: Butlerr.Asset.Lease | undefined) => void;
}

const AssetLeasesTable = ({ asset, leases, isLoading, onModalOpen }: AssetLeasesTableInterface) => {
    // edit modal
    const onEdit = useCallback(
        (lease: Butlerr.Asset.Lease) => {
            onModalOpen(lease);
        },
        [onModalOpen]
    );

    // table
    const columns = useMemo<Column<Butlerr.Asset.Lease>[]>(
        () => [
            {
                Header: 'tenant',
                accessor: 'leas_tenantname',
                minWidth: 114,
                Cell: ({ value }) => <span className="text-primary">{value}</span>,
            },
            {
                Header: 'start',
                accessor: 'leas_start',
                minWidth: 152,
                Cell: ({ value }) => <>{formatDate(value) ?? '-'}</>,
            },
            {
                Header: 'end',
                accessor: 'leas_end',
                minWidth: 134,
                Cell: ({ value }) => <>{formatDate(value) ?? '-'}</>,
            },
            {
                Header: 'rental',
                accessor: 'leas_amt',
                minWidth: 154,
                Cell: ({ row }) => {
                    const lease = row.original;
                    let amount = lease.active_adj?.toString() ?? lease.leas_amt.toString();
                    
                    //format currency
                    if (amount) {
                        amount = formatCurrency(amount, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                        });
                    }
                    //add amount period
                    switch (lease.leas_amtperiod) {
                        case 'A':
                            amount += ' / yr';
                            break;
                        case 'Q':
                            amount += ' / qtr';
                            break;
                        case 'M':
                            amount += ' / mth';
                            break;
                        case 'W':
                            amount += ' / wk';
                            break;
                        default:
                            break;
                    }
                    return (
                        <>{`${getCurrencySign(asset.asst_currency)} ${amount || '--'}`}</>
                    );
                },
            },
            // {
            //     Header: 'status',
            //     accessor: 'leas_status',
            //     minWidth: 113,
            //     Cell: ({ row }) => {
            //         const lease: Butlerr.Asset.Lease = row.original;
            //         return (
            //             <span
            //                 className={lease.leas_status === 1 ? 'text-success' : 'text-secondary'}
            //             >
            //                 {lease.leas_status === 1 ? 'Active' : 'Inactive'}
            //             </span>
            //         );
            //     },
            // },
        ],
        [asset.asst_currency]
    );

    return (
        <>
            <ReactTable
                data={leases}
                columns={columns}
                isLoading={isLoading}
                Row={(props, data) => (
                    <tr
                        {...props}
                        className="cursor-pointer"
                        onClick={onEdit.bind(undefined, data)}
                    />
                )}
            />
        </>
    );
};

export default AssetLeasesTable;
