import { Formik } from 'formik';
import { Form, InputGroup, Modal } from 'react-bootstrap';
import { BaseModalProps } from '../../../hooks/useModal';
import * as yup from 'yup';
import { useSocialChannelMutations } from '../../../services/social-channels.service';
import {
    formCloseHandler,
    imageThumbnailParse,
} from '../../utils/HelperFunctions';
import IconButton from '../../utils/IconButton';
import { BootstrapInput } from '../../utils/FormikBootstrapInputs';
import ChannelAvatar from './ChannelAvatar';
import { useEffect, useState, useRef, useMemo } from 'react';
import { Add, Close, CloseRounded, PhotoRounded } from '@mui/icons-material';
import UserDropdownButton from '../../utils/UserDropdownButton';
import { useWorkProfileStaff } from '../../../services/partner.service';
import { Butlerr } from "../../../types/butlerr";

interface ChannelCreateProps extends BaseModalProps {
    partnerId: number;
}

export default function ChannelCreate({
    partnerId,
    show,
    onClose,
}: ChannelCreateProps) {
    useEffect(() => {
        setFile(undefined);
    }, [show]);

    const schema = yup.object().shape({
        chan_name: yup
            .string()
            .required('Channel name is required')
            .max(80, ({ max }) => `Name cannot be more than ${max} characters`),
        chan_desc: yup
            .string()
            .max(
                200,
                ({ max }) => `Description cannot be more than ${max} characters`
            ),
        users: yup.array()
    });

    const { mutate, isLoading, error } = useSocialChannelMutations('CREATE');

    interface AttachmentFile {
        url: string;
        file: File;
    }
    const [file, setFile] = useState<AttachmentFile>();
    const inputRef = useRef<HTMLInputElement>(null);

    const { data: _staffs } = useWorkProfileStaff(partnerId);

    const staffs = useMemo(() => _staffs?.filter(s => s.wpst_status === 1), [ _staffs ]);
    
    return (
        <Formik
            initialValues={{
                chan_name: '',
                chan_desc: '',
                users: [] as Pick<Butlerr.UserInfo, 'user_id' | 'user_socialhandle' | 'user_profile'>[],
            }}
            validationSchema={schema}
            onSubmit={(val, { resetForm }) => {
                mutate(
                    {
                        ...val,
                        chan_partid: partnerId,
                        chan_profile: file?.file
                    },
                    {
                        onSuccess: () => {
                            onClose();
                            resetForm();
                        },
                    }
                );
            }}
        >
            {({ dirty, values, handleSubmit, setFieldValue }) => (
                <Modal
                    show={show}
                    onHide={() => formCloseHandler(onClose, dirty)}
                    backdrop={isLoading ? 'static' : undefined}
                    keyboard={!isLoading}
                >
                    <form onSubmit={handleSubmit}>
                        <Modal.Header>
                            <Modal.Title>Add channel</Modal.Title>
                            <IconButton
                                title="Close modal"
                                Icon={CloseRounded}
                                className="ms-auto"
                                disabled={isLoading}
                                onClick={() => formCloseHandler(onClose, dirty)}
                            />
                        </Modal.Header>
                        <Modal.Body>
                            <div className="mb-2 d-flex flex-column align-items-center">
                                <input
                                    hidden
                                    ref={inputRef}
                                    accept="image/*"
                                    type="file"
                                    onChange={(e) => {
                                        imageThumbnailParse(
                                            e.target.files?.[0]
                                        ).then(setFile);
                                        return false;
                                    }}
                                />
                                <ChannelAvatar
                                    channel={{
                                        chan_id: -1,
                                        chan_name:
                                            values.chan_name ?? 'Channel',
                                        chan_profile: null
                                    }}
                                    width={100}
                                    height={100}
                                    src={file?.url}
                                    className="mb-3 shadow-sm"
                                />
                                <IconButton
                                Icon={PhotoRounded}
                                    transparent
                                    className='text-black'
                                    title="Add image"
                                    label="Upload"
                                    onClick={() => inputRef.current?.click()}
                                />
                            </div>
                            <div className="mb-2">
                                <BootstrapInput
                                    id="chan_name"
                                    label="Channel Name"
                                    placeholder="Name"
                                    required
                                />
                            </div>
                            <div className="mb-2">
                                <BootstrapInput
                                    id="chan_desc"
                                    label="Description"
                                    placeholder="Description"
                                    required={false}
                                    as="textarea"
                                    rows="3"
                                />
                            </div>
                            <label
                                style={{ fontSize: 12.96 }}
                                className="text-secondary mb-2 fw-bold text-uppercase"
                            >
                                Editors
                            </label>
                            {staffs?.length !== 0 ? (
                                <InputGroup className="mb-2">
                                    <Form.Control
                                        placeholder="Select user to add to Editor"
                                        readOnly
                                        value={values.users.map((u)=> u.user_socialhandle)}
                                    />
                                    <UserDropdownButton
                                        selectedUsers={values.users}
                                        setSelectedUsers={(selectedUsers) => {
                                            setFieldValue(
                                                'users',
                                                selectedUsers
                                            );
                                        }}
                                        users={staffs ?? undefined}
                                        dropdownProps={{ placement: 'top' }}
                                    />
                                </InputGroup>
                             ) : ( 
                                 <p className='text-secondary'>
                                    There are no Staff(s) in the current work
                                    profile
                                </p> 
                             )} 
                        </Modal.Body>
                        <Modal.Footer>
                            {error !== null && (
                                <p className="text-danger text-truncate w-100">
                                    {error.message}
                                </p>
                            )}
                            <IconButton
                                transparent
                                Icon={Close}
                                iconHtmlColor="var(--primary)"
                                className="border border-primary text-primary"
                                label="Cancel"
                                title="Cancel"
                                disabled={isLoading}
                                onClick={() => formCloseHandler(onClose, dirty)}
                            />
                            <IconButton
                                Icon={Add}
                                label={isLoading ? 'Adding...' : 'Add'}
                                disabled={isLoading}
                                title="Add"
                                type="submit"
                            />
                        </Modal.Footer>
                    </form>
                </Modal>
            )}
        </Formik>
    );
}
