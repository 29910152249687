import { Assignment, LocalPhone, Videocam, CircleOutlined, CheckCircleOutlined, Circle } from '@mui/icons-material';
import { useCallback, useMemo } from 'react';
import { Column } from 'react-table';
import { Butlerr } from '../../../types/butlerr';
import { formatDate } from '../../utils/HelperFunctions';
import ReactTable from '../../utils/ReactTable';
import { ReminderModes } from './PartnerReminders';
import LineClampText from '../../utils/LineClampText';
import { ButlerrAssetRemindersPriority, ButlerrAssetRemindersType } from "../../../types/butlerr-enums";
import { Col, Row } from 'react-bootstrap';

interface AssetRemindersTableInterface {
    assetId: number;
    reminders: Butlerr.Asset.Reminder[];
    isLoading: boolean;
    onModalOpen: (reminder?: Butlerr.Asset.Reminder | undefined) => void;
    mode: keyof typeof ReminderModes;
}

const AssetRemindersTable = ({
    assetId,
    reminders,
    isLoading,
    onModalOpen,
    mode,
}: AssetRemindersTableInterface) => {

    // edit modal
    const onEdit = useCallback(
        (reminder: Butlerr.Asset.Reminder) => {
            onModalOpen(reminder);
        },
        [onModalOpen]
    );

    // table
    const columns = useMemo<Column<Butlerr.Asset.Reminder>[]>(
        () => [
            {
                Header: 'date',
                accessor: 'remi_date',
                Cell: ({ value }) => <>{formatDate(value) ?? '-'}</>,
                minWidth: 130
            },
            {
                Header: 'message',
                accessor: 'remi_msg',
                disableSortBy: true,
                minWidth: 400,
                Cell: ({ row }) => {
                    const reminder = row.original;
                    const msg = reminder.remi_msg;
                    return (
                        <LineClampText
                            className="mb-0"
                            title={msg}
                            maxLines={1}
                        >{msg}</LineClampText>
                    )
                }
            },
            {
                Header: 'from',
                accessor: 'user_socialhandle',
                minWidth: 130
            },
            {
                Header: "",
                accessor: 'remi_type',
                disableSortBy: true,
                minWidth: 150,
                Cell: ({ row }) => {
                    const reminder = row.original;
                    return (
                        <Row>
                            <Col><ReminderTypeIcon reminder={reminder} /></Col>
                            <Col><ReminderPriorityIcon reminder={reminder} /></Col>
                            <Col><ReminderCompletedIcon completed={reminder.remi_completed === 1} /></Col>
                        </Row>
                    )
                }
            },
        ],
        []
    );

    return (
        <Row>
            <ReactTable
                data={reminders}
                columns={columns}
                isLoading={isLoading}
                placeholderMessage={`No${mode === 'U' ? ' upcoming' : ''} reminders.`}
                Row={(props, data) => (
                    <tr
                        {...props}
                        className="cursor-pointer"
                        onClick={ onEdit.bind(undefined, data) }
                    />
                )}
            />
        </Row>
    );
};

export const ReminderTypeIcon = ({ reminder }: { reminder: Pick<Butlerr.Asset.Reminder, "remi_type"> }) => {
    const typeIconConfig = {
        'P': [LocalPhone],
        'V': [Videocam, 'primary'],
        'T': [Assignment]
    } as const;

    if (!reminder.remi_type) return <></>

    const [TypeIcon, typeColor] = typeIconConfig[reminder.remi_type];

    return (
        <TypeIcon color={typeColor} titleAccess={ButlerrAssetRemindersType[reminder.remi_type]} />
    )
}


export const ReminderPriorityIcon = ({ reminder }: {reminder: Pick<Butlerr.Asset.Reminder, "remi_priority"> }) => {

    const priorityIconConfig = {
        'N': [Circle, 'success'],
        'H': [Circle, 'warning'],
        'U': [Circle, 'error']
    } as const;

    if (!reminder.remi_priority) return <></>

    const [PriorityIcon, priorityColor] = priorityIconConfig[reminder.remi_priority];

    return (
        <PriorityIcon color={priorityColor} titleAccess={ButlerrAssetRemindersPriority[reminder.remi_priority]} />
    )
}

export const ReminderCompletedIcon = ({ completed }: { completed: boolean }) => {

    const completedIconConfig = {
        'true': [CheckCircleOutlined, 'action'],
        'false': [CircleOutlined, 'disabled']
    } as const;

    const [CompletedIcon, completedColor] = completedIconConfig[completed ? 'true' : 'false'];

    return (
        <CompletedIcon color={completedColor} titleAccess={completed ? 'Completed' : 'Pending'} />
    )
}

export default AssetRemindersTable;