import { useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { ForumOutlined, ForumRounded } from "@mui/icons-material";
import { Dropdown, NavLink } from "react-bootstrap";
import classNames from "classnames";
import styles from "../header.module.css";
import IconButton from "../../utils/IconButton";
import { useChatsSocialUnreadCount, useConversationsAssetsUnreadCount } from "../../../services/conversation.service";

export function MessagesButton() {

    //control open state ourselves
    const [isOpen, setIsOpen] = useState(false);

    const Icon = isOpen ? ForumRounded : ForumOutlined;

    const { data: AssetCounts = [] } = useConversationsAssetsUnreadCount();
    const { data: SocialCounts = [] } = useChatsSocialUnreadCount();

    //Filter toggle
    const views = ['Chats', 'Conversations'] as const;
    const [selectedView, setSelectedView] = useState<typeof views[number]>('Chats');

    const displayNotifications = selectedView === 'Chats' ? SocialCounts : AssetCounts;

    return (
        <Dropdown
            align="end"
            autoClose="outside"
            className="mx-1"
            show={isOpen}
            onToggle={(val) => setIsOpen(val)}
        >
            <Dropdown.Toggle
                as={NavLink}
                className="d-flex align-items-center p-2 position-relative"
                //overwrite bootstrap class to remove dropdown arrow
                bsPrefix=" "
                title="Messages"
            >
                <Icon />
                <span className="d-lg-none ms-1">Messages</span>
                {
                    (AssetCounts.length !== 0 || SocialCounts.length !== 0) && (
                        <div className={classNames("rounded-pill text-center bg-danger px-1 text-white", styles.unreadContainer)}>
                            {AssetCounts.reduce((total, c) => (total += c.unread_count), 0) + SocialCounts.reduce((total, c) => (total += c.unread_count), 0)}
                        </div>
                    )
                }
            </Dropdown.Toggle>

            <Dropdown.Menu className="shadow rounded-4 position-fixed" style={{ top: 'var(--navbar-height)', right: '1rem' }}>
                <div
                    className="d-flex flex-column"
                    style={{
                        width: 'min(400px, calc(100vw - 2rem))',
                        maxHeight: 'calc(90vh - var(--navbar-height))'
                    }}
                >
                    <div className="h5 mb-0 px-3 fw-bold d-flex justify-content-between align-items-center">
                        <span className="mt-3 mb-2">Messages</span>
                    </div>
                    <div className="d-flex px-2 pb-2 shadow-sm">
                        {views.map((label, idx) => (
                            <Fragment key={idx}>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'right'
                                    }}
                                >
                                    <IconButton
                                        key={label}
                                        label={label}
                                        transparent={selectedView !== label}
                                        className={classNames("px-3 py-1 rounded-pill me-2 border border-primary", selectedView !== label && "text-primary fw-normal", idx === 0 && "ms-1")}
                                        onClick={() => setSelectedView(label)}
                                    />
                                    {
                                        (AssetCounts.length !== 0 && label === 'Conversations') && (
                                            <div
                                                className={classNames("rounded-pill text-center bg-danger px-1 text-white")}
                                                style={{
                                                    height: '14px',
                                                    minWidth: '14px',
                                                    position: 'absolute',
                                                    fontSize: '10px'
                                                }}
                                            >
                                                {AssetCounts.reduce((total, c) => (total += c.unread_count), 0)}
                                            </div>
                                        )
                                    }
                                    {
                                        (SocialCounts.length !== 0 && label === 'Chats') && (
                                            <div
                                                className={classNames("rounded-pill text-center bg-danger px-1 text-white")}
                                                style={{
                                                    height: '14px',
                                                    minWidth: '14px',
                                                    position: 'absolute',
                                                    fontSize: '10px'
                                                }}
                                            >
                                                {SocialCounts.reduce((total, c) => (total += c.unread_count), 0)}
                                            </div>
                                        )
                                    }
                                </div>
                            </Fragment>
                        ))}
                    </div>
                    <div className="overflow-auto">
                        {
                            displayNotifications === undefined ? <NotificationSkeleton />
                                : displayNotifications.length === 0 ? (
                                    <div className="p-3">
                                        <h6 className="text-center">No messages yet!</h6>
                                    </div>
                                ) : (
                                    selectedView === 'Conversations' ? (
                                        displayNotifications.map((c) => {
                                            const url =
                                                c.owned === 1
                                                    ? `/assets/${c.asst_id}/conversations`
                                                    : `/work/assets/${c.asst_id}/conversations`;
                                            return (
                                                <Dropdown.Item
                                                    key={c.asst_id}
                                                    as={Link}
                                                    to={url}
                                                    className="text-decoration-none text-dark"
                                                    style={{ whiteSpace: 'normal' }}
                                                    onClick={() => setIsOpen(false)}
                                                >
                                                    You have <b>{c.unread_count}</b> unread message
                                                    {c.unread_count > 1 && 's'} in <i>{c.asst_name}</i>.
                                                </Dropdown.Item>
                                            );
                                        })
                                    ) : (
                                        displayNotifications.map((c) => {
                                            const url = `/home/chats/${c.ascv_id}`;
                                            return (
                                                <Dropdown.Item
                                                    key={c.ascv_id}
                                                    as={Link}
                                                    to={url}
                                                    className="text-decoration-none text-dark"
                                                    style={{ whiteSpace: 'normal' }}
                                                    onClick={() => setIsOpen(false)}
                                                >
                                                    You have <b>{c.unread_count}</b> unread message
                                                    {c.unread_count > 1 && 's'} in <i>{c.ascv_subject}</i>.
                                                </Dropdown.Item>
                                            );
                                        })
                                    )
                                )
                        }
                    </div>
                </div>
            </Dropdown.Menu>
        </Dropdown>
    )
}

function NotificationSkeleton() {
    return (
        <>
            {
                Array(3).fill(null).map((_, idx) => (
                    <div key={idx} className="p-3 d-flex align-items-center w-100">
                        <div
                            className="skeleton-box rounded-circle flex-shrink-0 me-3"
                            style={{ width: '40px', height: '40px' }}
                        ></div>
                        <div className="w-100 align-self-stretch d-flex flex-column justify-content-between">
                            <div
                                className="skeleton-box"
                                style={{ width: '100%' }}
                            ></div>
                            <div
                                className="skeleton-box"
                                style={{ width: '40%' }}
                            ></div>
                        </div>
                    </div>
                ))
            }
        </>
    )
}