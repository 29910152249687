import React, { useEffect } from 'react';
import { Button, Row } from 'react-bootstrap';
import { useSocialChannels } from '../../../services/social-channels.service';
import ChannelItem from '../../social/channels/Channel';
import { ChannelSkeleton } from '../../social/channels/ChannelList';
import EmptyMessage from '../../utils/EmptyMessage';
import ErrorPage from '../../utils/ErrorPage';
import { useProfileCompeletion } from './OnboardingModal';

export const SocialChannelsPage = () => {
    const { goPrevPage, goNextPage } = useProfileCompeletion();

    const {
        data: socialChannels,
        isLoading,
        error,
        refetch,
    } = useSocialChannels({ sort: 'top_followers', limit: 3 });

    // skip this page if there is no data
    useEffect(() => {
        if (socialChannels !== undefined && socialChannels.pages[0].result.length === 0) {
            goNextPage();
        }
    }, [socialChannels, goNextPage]);

    return (
        <>
            <Row>
                <h1 className="text-muted">
                    Follow <span className="text-primary">Social Channels!</span>
                </h1>

                <p>
                    Here are some of the more popular social channels that you might be keen to
                    follow to see their posts!
                </p>
            </Row>

            <div className="mb-3">
                {socialChannels === undefined ? (
                    isLoading ? (
                        <ChannelSkeleton />
                    ) : error ? (
                        <ErrorPage message={error.message} handleRetry={refetch} />
                    ) : (
                        <EmptyMessage message="Nothing to see here" />
                    )
                ) : (
                    <>
                        {socialChannels.pages
                            .flatMap((p) => p.result)
                            .map((ch) => (
                                <ChannelItem key={ch.chan_id} channel={ch} className="mb-2" allowFollowerModal={false} />
                            ))}
                    </>
                )}
            </div>

            <div>
                <Button variant="outline-primary" className="me-2" onClick={() => goPrevPage()}>
                    Back
                </Button>

                <Button onClick={() => goNextPage()}>Next</Button>
            </div>
        </>
    );
};

export default SocialChannelsPage;
