import { Image, ImageProps } from "react-bootstrap";
import styles from "./social.module.css";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { Butlerr } from "../../types/butlerr";
import UserIcon from "../../assets/icons/person.png";

interface UserAvatarProps extends ImageProps {
    user?: Pick<Butlerr.UserInfo, "user_id" | "user_socialhandle" | "user_profile"> | null;
}

/**
 * If `src` is passed in props, it will always take precedence.
 */
export default function UserAvatar({
    user,
    className,
    alt = "Avatar",
    roundedCircle = true,
    title,
    onError,
    style,
    src: srcPassedInProps,
    ...props
}: UserAvatarProps) {

    const [ src, setSrc ] = useState<string | null>(user?.user_profile ?? null);

    useEffect(() => {
        setSrc(user?.user_profile ?? null);
    }, [user?.user_profile])

    return (
        <Image
            className={classNames(
                styles.userAvatar,
                className,
                (!srcPassedInProps && !src) && "bg-secondary p-1"
            )}
            alt={alt}
            roundedCircle={roundedCircle}
            src={
                (srcPassedInProps || src) ?? UserIcon
            }
            title={title ?? user?.user_socialhandle}
            onError={onError ?? (() => {
                setSrc(null);
            })}
            style={{
                objectFit: 'cover',
                ...style
            }}
            {...props}
        />
    )
}