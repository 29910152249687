import { Redirect, useHistory } from "react-router-dom";
import useQuery from "../../hooks/useQuery";
import ErrorPage from "../utils/ErrorPage";
import PostList from "./PostList";
import { SocialRoutes } from "./SocialHome";
import { useUserSearch } from "../../services/user.service";
import ChannelList from "./channels/ChannelList";
import NewsList from "./news/News";
import SocialChats from "./chats/Chats";
import { ArticlesList } from "./ArticlesList";
import { useMemo } from "react";
import UserList from "./network/UserList";

type TabKeys = keyof typeof TAB_MAP;
export const TAB_MAP = {
    p: "keywords, users, #",
    u: "name, handle, email",
    c: "org, channel name",
    a: "keywords, feed",
    l: "subject, users",
    ar: "keywords, title, user",
} as const;

export default function SocialSearch() {

    const search = useQuery();
    const history = useHistory();

    const searchTerm = decodeURIComponent(search.get("q") || "") || null;
    const hashtags = decodeURIComponent(search.get("h") || "") || null;

    const _tab = decodeURIComponent(search.get("t") || "");
    let selectedTab = TAB_MAP[_tab as TabKeys];
    if (!selectedTab) {
        search.set('t', 'p');
        history.replace({
            search: search.toString()
        })
    }

    let content: JSX.Element;
    if (_tab === "p") {
        if (searchTerm === null && hashtags === null) {
            content = <Redirect to={SocialRoutes.POSTS} />
        }
        else {
            //PostList will get seach values from query params
            content = <PostList emptyMessage="No results" />
        }
    }
    else if (searchTerm === null) {
        content = (
            <Redirect
                to={
                    _tab === 'c' ? (SocialRoutes.CHANNELS) :
                    _tab === 'a' ? (SocialRoutes.NEWS) :
                    _tab === 'l' ? (SocialRoutes.CHATS) :
                    _tab === 'ar' ? (SocialRoutes.ARTICLES + "?t=ar&page=1") :
                    (SocialRoutes.NETWORK)
                }
            />
        )
    }
    else if (_tab === "c") {
        content = <ChannelList emptyMessage="No results" />
    }
    else if (_tab === "a") {
        content = <NewsList emptyMessage="No results" />
    }
    else if (_tab === "l") {
        content = <SocialChats  />
    }
    else if (_tab === "ar") {
        content = <ArticlesList emptyMessage="No results" />
    }
    else {
        content = <UserSearchResults query={searchTerm} />
    }

    return (
        <div>
            <p className="mb-1 fw-semibold text-muted small text-uppercase">Searching for {selectedTab}</p>
            { content }
        </div>
    )
}

function UserSearchResults({ query }: { query: string }) {

    const { data: _results, error, isLoading, refetch, hasNextPage, fetchNextPage } = useUserSearch({ query });
    const results = useMemo(() => _results?.pages.flatMap(p => p.result), [_results]);

    if (error !== null) {
        return <ErrorPage status={error.status} message={error.message} handleRetry={refetch} />
    }

    return (
        <div>
            <UserList
                users={results}
                isLoading={isLoading}
                hasNextPage={hasNextPage}
                fetchNextPage={fetchNextPage}
            />
        </div>
    )
}