import { useState } from 'react';
import { Form, Col, InputGroup, Row, FormControlProps } from 'react-bootstrap';
import { Formik, FormikHelpers } from 'formik';
import * as yup from 'yup';

import styles from '../asset.module.css';
import { Butlerr } from '../../../types/butlerr';
import {
    AssetQualityKey,
    AssetTypeKey,
    ButlerrAssetQuality,
    ButlerrAssetType,
} from '../../../types/butlerr-enums';
import {
    convertDateToYYYYMMDD,
    convertSquareFootToSquareMetre,
    convertSquareMetreToSquareFoot,
    formatCurrency,
    formatDate,
    removeNumberFormatting,
} from '../../utils/HelperFunctions';
import { initialiseAssetObject, useAssetMutations } from '../../../services/asset.service';
import { AssetInfoEditButton, AssetInfoProps } from './AssetInfo';
import {
    BootstrapInput,
    BootstrapInputPrice,
    BootstrapSelect,
} from '../../utils/FormikBootstrapInputs';
import RouterPrompt from '../../utils/RouterPrompt';
import { SectionHeader } from '../../utils/SectionHeader';

interface FormValues {
    asst_type: AssetTypeKey;
    asst_landsize: number | '';
    asst_sitecover: number | '';
    asst_intsize: number | '';
    asst_balsize: number | '';
    asst_garsize: number | '';
    asst_nolevel: number;
    asst_yrbuilt: string | '';
    asst_contractdate: string;
    asst_purprice: number;
    asst_settledate: string | '';
    asst_quality: AssetQualityKey | '';
    asst_pmgrid: number | null;

    asst_landsize2: number | '';
    asst_intsize2: number | '';
    asst_balsize2: number | '';
    asst_garsize2: number | '';
}

const AssetInfoDetails = ({ asset, isPartner = false }: AssetInfoProps) => {
    const { mutate: editAsset } = useAssetMutations('EDIT');

    // form state
    const [editing, setEditing] = useState(false);

    const allowedLabels = {
        asst_type: Object.values(ButlerrAssetType),
        asst_quality: Object.values(ButlerrAssetQuality),
    };
    const allowedKeys = {
        asst_type: Object.keys(ButlerrAssetType),
        asst_quality: Object.keys(ButlerrAssetQuality),
    };

    // Formik props
    const initialiseInitialValues = (asset: Butlerr.Asset) => {
        return {
            asst_type: asset.asst_type,
            asst_landsize: asset.asst_landsize ?? '',
            asst_sitecover: asset.asst_sitecover ?? '',
            asst_intsize: asset.asst_intsize ?? '',
            asst_balsize: asset.asst_balsize ?? '',
            asst_garsize: asset.asst_garsize ?? '',
            asst_nolevel: asset.asst_nolevel,
            asst_yrbuilt: asset.asst_yrbuilt ?? String(new Date().getFullYear()),
            asst_contractdate: asset.asst_contractdate
                ? convertDateToYYYYMMDD(asset.asst_contractdate)
                : '',
            asst_purprice: Number(asset.asst_purprice),
            asst_settledate: asset.asst_settledate
                ? convertDateToYYYYMMDD(asset.asst_settledate)
                : '',
            asst_quality: asset.asst_quality ?? '',
            asst_pmgrid: asset.asst_pmgrid, // TODO: add new field

            asst_garsize2: asset.asst_garsize
                ? convertSquareMetreToSquareFoot(asset.asst_garsize)
                : '',
            asst_landsize2: asset.asst_landsize
                ? convertSquareMetreToSquareFoot(asset.asst_landsize)
                : '',
            asst_intsize2: asset.asst_intsize
                ? convertSquareMetreToSquareFoot(asset.asst_intsize)
                : '',
            asst_balsize2: asset.asst_balsize
                ? convertSquareMetreToSquareFoot(asset.asst_balsize)
                : '',
        } as FormValues;
    };

    let initialValues: FormValues = initialiseInitialValues(asset);

    const validationSchema = yup.object({
        asst_name: yup.string().max(80, 'Maximum 80 characters').nullable(),
        asst_code: yup.string().max(5, 'Maximum 5 characters').nullable(),
        asst_type: yup.string().oneOf(allowedKeys.asst_type),
        asst_floor: yup.string().max(5, 'Maximum 5 characters').nullable(),
        asst_unitno: yup.string().max(10, 'Maximum 10 characters').nullable(),
        asst_estatename: yup.string().max(30, 'Maximum 30 characters').nullable(),
        asst_desc: yup.string().max(200, 'Maximum 200 characters').nullable(),
        asst_landsize: yup.number().integer().min(0, 'Minimum 0').nullable(),
        asst_sitecover: yup.number().integer().min(0, 'Minimum 0').nullable(),
        asst_intsize: yup.number().integer().min(0, 'Minimum 0').nullable(),
        asst_balsize: yup.number().integer().min(0, 'Minimum 0').nullable(),
        asst_garsize: yup.number().integer().min(0, 'Minimum 0').nullable(),
        asst_nolevel: yup.number().integer().positive('Minimum 1').required('Required'),
        asst_yrbuilt: yup.string().max(4, 'Maximum 4 characters').nullable(),
        asst_contractdate: yup.date().required('Required'),
        asst_purprice: yup.string().required('Required'),
        asst_settledate: yup.date().nullable(),
        asst_pool: yup.boolean().nullable(),
        asst_tennis: yup.boolean().nullable(),
        asst_backyard: yup.boolean().nullable(),
        asst_frontyard: yup.boolean().nullable(),
        asst_airconditioning: yup.boolean().nullable(),
        asst_heating: yup.boolean().nullable(),
        asst_fireplace: yup.boolean().nullable(),
        asst_solarpower: yup.boolean().nullable(),
        asst_quality: yup.string().oneOf(allowedKeys.asst_quality).nullable(),
        asst_pmgrid: yup.number().integer().min(0).nullable(),

        asst_landsize2: yup.number().min(0, 'Minimum 0').nullable(),
        asst_intsize2: yup.number().min(0, 'Minimum 0').nullable(),
        asst_balsize2: yup.number().min(0, 'Minimum 0').nullable(),
        asst_garsize2: yup.number().min(0, 'Minimum 0').nullable(),
    });

    const closeForm = (actions: FormikHelpers<FormValues>) => {
        setEditing(false);
        actions.resetForm({ values: initialValues });
    };

    const onSubmit = (
        { asst_purprice, ..._values }: FormValues,
        actions: FormikHelpers<FormValues>
    ) => {
        const [purchasePrice] = removeNumberFormatting(String(asst_purprice));

        const values = { ..._values, asst_purprice: Number(purchasePrice) };

        // check if any field updated
        let fieldUpdated: boolean = false;
        Object.keys(initialValues).forEach((key) => {
            let value = values[key as keyof typeof values];
            if (initialValues[key as keyof typeof initialValues] !== value) {
                fieldUpdated = true;
            }
        });

        if (fieldUpdated) {
            const {
                asst_contractdate,
                asst_settledate,
                asst_yrbuilt,
                asst_sitecover,
                asst_garsize,
                asst_landsize,
                asst_intsize,
                asst_balsize,
                asst_quality,
                asst_landsize2,
                asst_intsize2,
                asst_balsize2,
                asst_garsize2,
                ...rest
            } = values;

            const form = {
                ...initialiseAssetObject(asset),
                ...rest,
                // convert dates to ISOString
                asst_contractdate: asst_contractdate
                    ? new Date(String(asst_contractdate)).toISOString()
                    : '',
                asst_settledate: asst_settledate
                    ? new Date(String(asst_settledate)).toISOString()
                    : null,
                // convert to string
                asst_yrbuilt: String(asst_yrbuilt),
                // convert empty string to null
                asst_sitecover: asst_sitecover ? asst_sitecover : null,
                asst_garsize: asst_garsize ? asst_garsize : null,
                asst_landsize: asst_landsize ? asst_landsize : null,
                asst_intsize: asst_intsize ? asst_intsize : null,
                asst_balsize: asst_balsize ? asst_balsize : null,
                asst_quality: asst_quality ? asst_quality : null,
            };

            editAsset(form, {
                onSuccess: (_, updatedAsset) => {
                    initialValues = initialiseInitialValues(updatedAsset);
                    closeForm(actions);
                },
                onError: (error) => {
                    closeForm(actions);
                    alert(error.message);
                },
            });
        } else {
            closeForm(actions);
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({
                isSubmitting,
                dirty,
                values,
                touched,
                errors,
                setFieldValue,
                handleBlur,
                handleSubmit,
                resetForm,
            }) => {
                return (
                    <Form noValidate onSubmit={handleSubmit}>
                        <RouterPrompt isBlocking={dirty} />

                        <SectionHeader
                            title="Details"
                            rightSide={
                                isPartner === false && asset.status !== 'AR' ? (
                                    <AssetInfoEditButton
                                        editing={editing}
                                        isSubmitting={isSubmitting}
                                        setEditing={setEditing}
                                        resetForm={resetForm}
                                    />
                                ) : undefined
                            }
                        />
                        <Row>
                            {/* Column 1 */}
                            <Col xs={6} xl={3} className={styles.col}>
                                {/* Date Of Purchase */}
                                <div className={styles.colItem}>
                                    <small className="text-muted d-block">Date Of Purchase</small>
                                    {editing ? (
                                        <BootstrapInput
                                            id="asst_contractdate"
                                            type="date"
                                            placeholder="Date Of Purchase"
                                            aria-label="Date Of Purchase"
                                            disabled={isSubmitting}
                                            size="sm"
                                        />
                                    ) : (
                                        <span>{formatDate(asset.asst_contractdate) ?? '-'}</span>
                                    )}
                                </div>
                                {/* Purchase Price */}
                                <div className={styles.colItem}>
                                    <small className="text-muted d-block">Purchase Price</small>
                                    {editing ? (
                                        <BootstrapInputPrice
                                            id="asst_purprice"
                                            placeholder="Purchase Price"
                                            aria-label="Purchase Price"
                                            disabled={isSubmitting}
                                            inputGroupText={asset.asst_currency}
                                            inputGroupSize="sm"
                                        />
                                    ) : (
                                        <span>
                                            {asset.asst_purprice
                                                ? formatCurrency(asset.asst_purprice, {
                                                      currency: asset.asst_currency,
                                                      minimumFractionDigits: 0,
                                                      maximumFractionDigits: 0,
                                                  })
                                                : '-'}
                                        </span>
                                    )}
                                </div>
                                {/* Date Of Settlement */}
                                <div className={styles.colItem}>
                                    <small className="text-muted d-block">Date Of Settlement</small>
                                    {editing ? (
                                        <BootstrapInput
                                            id="asst_settledate"
                                            type="date"
                                            placeholder="Date Of Settlement"
                                            aria-label="Date Of Settlement"
                                            disabled={isSubmitting}
                                            size="sm"
                                        />
                                    ) : (
                                        <span>{formatDate(asset.asst_settledate) ?? '-'}</span>
                                    )}
                                </div>
                            </Col>

                            {/* Column 2 */}
                            <Col xs={6} xl={3} className={styles.col}>
                                {/* Asset Type */}
                                <div className={styles.colItem}>
                                    <small className="text-muted d-block">Asset Type</small>
                                    {editing ? (
                                        <BootstrapSelect
                                            id="asst_type"
                                            disabled={isSubmitting}
                                            size="sm"
                                            allowedKeys={allowedKeys.asst_type}
                                            allowedLabels={allowedLabels.asst_type}
                                            placeholderOption={false}
                                        />
                                    ) : (
                                        <span>
                                            {asset.asst_type
                                                ? ButlerrAssetType[asset.asst_type]
                                                : '-'}
                                        </span>
                                    )}
                                </div>
                                {/* Garden Size */}
                                <ToggleableInput
                                    isEditing={editing}
                                    isSubmitting={isSubmitting}
                                    displayValue={asset.asst_garsize}
                                    label="Garden Size"
                                    id="asst_garsize"
                                    placeholder="Garden Size"
                                    value={values.asst_garsize ?? ''}
                                    touched={touched.asst_garsize}
                                    error={errors.asst_garsize}
                                    onChange={(e) => {
                                        const value = e.target.value;

                                        setFieldValue('asst_garsize', value);
                                        setFieldValue('asst_garsize2', getSecondaryUnit(value));
                                    }}
                                    onBlur={handleBlur}
                                    id2="asst_garsize2"
                                    value2={values.asst_garsize2}
                                    touched2={touched.asst_garsize2}
                                    error2={errors.asst_garsize2}
                                    onChange2={(e) => {
                                        const value = e.target.value;

                                        setFieldValue('asst_garsize2', value);
                                        setFieldValue('asst_garsize', getMainUnit(value));
                                    }}
                                />

                                {/* No of Levels */}
                                <div className={styles.colItem}>
                                    <small className="text-muted d-block">No of Levels</small>
                                    {editing ? (
                                        <BootstrapInput
                                            id="asst_nolevel"
                                            type="number"
                                            min="1"
                                            placeholder="No of Levels"
                                            aria-label="No of Levels"
                                            disabled={isSubmitting}
                                            size="sm"
                                        />
                                    ) : (
                                        <span>{asset.asst_nolevel ?? '-'}</span>
                                    )}
                                </div>
                            </Col>

                            {/* Column 3 */}
                            <Col xs={6} xl={3} className={styles.col}>
                                {/* Floor/Unit No */}
                                {/* <div className={styles.colItem}>
                                        <small className="text-muted d-block">
                                            Floor/Unit No
                                        </small>
                                        {editing ? (
                                            <>
                                                <BootstrapInput
                                                    id="asst_floor"
                                                    placeholder="Floor"
                                                    aria-label="Floor"
                                                    disabled={isSubmitting}
                                                    size="sm"
                                                />
                                                <span className="mx-1">/</span>
                                                <BootstrapInput
                                                    id="asst_unitno"
                                                    placeholder="Unit No"
                                                    aria-label="Unit No"
                                                    disabled={isSubmitting}
                                                    size="sm"
                                                />
                                            </>
                                        ) : (
                                            <span>
                                                {asset.asst_floor ?? '-'}/
                                                {asset.asst_unitno ?? '-'}
                                            </span>
                                        )}
                                    </div> */}
                                {/* Land Size */}
                                <ToggleableInput
                                    isEditing={editing}
                                    isSubmitting={isSubmitting}
                                    displayValue={asset.asst_landsize}
                                    label="Land Size"
                                    id="asst_landsize"
                                    placeholder="Land Size"
                                    value={values.asst_landsize ?? ''}
                                    touched={touched.asst_landsize}
                                    error={errors.asst_landsize}
                                    onChange={(e) => {
                                        const value = e.target.value;

                                        setFieldValue('asst_landsize', value);
                                        setFieldValue('asst_landsize2', getSecondaryUnit(value));
                                    }}
                                    onBlur={handleBlur}
                                    id2="asst_landsize2"
                                    value2={values.asst_landsize2}
                                    touched2={touched.asst_landsize2}
                                    error2={errors.asst_landsize2}
                                    onChange2={(e) => {
                                        const value = e.target.value;

                                        setFieldValue('asst_landsize2', value);
                                        setFieldValue('asst_landsize', getMainUnit(value));
                                    }}
                                />

                                {/* Internal Size */}
                                <ToggleableInput
                                    isEditing={editing}
                                    isSubmitting={isSubmitting}
                                    displayValue={asset.asst_intsize}
                                    label="Internal Size"
                                    id="asst_intsize"
                                    placeholder="Internal Size"
                                    value={values.asst_intsize ?? ''}
                                    touched={touched.asst_intsize}
                                    error={errors.asst_intsize}
                                    onChange={(e) => {
                                        const value = e.target.value;

                                        setFieldValue('asst_intsize', value);
                                        setFieldValue('asst_intsize2', getSecondaryUnit(value));
                                    }}
                                    onBlur={handleBlur}
                                    id2="asst_intsize2"
                                    value2={values.asst_intsize2}
                                    touched2={touched.asst_intsize2}
                                    error2={errors.asst_intsize2}
                                    onChange2={(e) => {
                                        const value = e.target.value;

                                        setFieldValue('asst_intsize2', value);
                                        setFieldValue('asst_intsize', getMainUnit(value));
                                    }}
                                />

                                {/* Balcony Size */}
                                <ToggleableInput
                                    isEditing={editing}
                                    isSubmitting={isSubmitting}
                                    displayValue={asset.asst_balsize}
                                    label="Balcony Size"
                                    id="asst_balsize"
                                    placeholder="Balcony Size"
                                    value={values.asst_balsize ?? ''}
                                    touched={touched.asst_balsize}
                                    error={errors.asst_balsize}
                                    onChange={(e) => {
                                        const value = e.target.value;

                                        setFieldValue('asst_balsize', value);
                                        setFieldValue('asst_balsize2', getSecondaryUnit(value));
                                    }}
                                    onBlur={handleBlur}
                                    id2="asst_balsize2"
                                    value2={values.asst_balsize2}
                                    touched2={touched.asst_balsize2}
                                    error2={errors.asst_balsize2}
                                    onChange2={(e) => {
                                        const value = e.target.value;

                                        setFieldValue('asst_balsize2', value);
                                        setFieldValue('asst_balsize', getMainUnit(value));
                                    }}
                                />
                            </Col>

                            {/* Column 4 */}
                            <Col xs={6} xl={3} className={styles.col}>
                                {/* Site Cover */}
                                <div className={styles.colItem}>
                                    <small className="text-muted d-block">Site Cover</small>
                                    {editing ? (
                                        <BootstrapInput
                                            id="asst_sitecover"
                                            type="number"
                                            min="0"
                                            placeholder="Site Cover"
                                            aria-label="Site Cover"
                                            disabled={isSubmitting}
                                            inputGroupText={<span>%</span>}
                                            inputGroupSize="sm"
                                        />
                                    ) : (
                                        <span>
                                            {asset.asst_sitecover
                                                ? `${asset.asst_sitecover}%`
                                                : '- '}
                                        </span>
                                    )}
                                </div>
                                {/* Asset Quality */}
                                <div className={styles.colItem}>
                                    <small className="text-muted d-block">Build Quality</small>
                                    {editing ? (
                                        <BootstrapSelect
                                            id="asst_quality"
                                            disabled={isSubmitting}
                                            size="sm"
                                            allowedKeys={allowedKeys.asst_quality}
                                            allowedLabels={allowedLabels.asst_quality}
                                        />
                                    ) : (
                                        <span>
                                            {asset.asst_quality
                                                ? ButlerrAssetQuality[asset.asst_quality]
                                                : '-'}
                                        </span>
                                    )}
                                </div>
                                {/* Year Built */}
                                <div className={styles.colItem}>
                                    <small className="text-muted d-block">Year Built</small>
                                    {editing ? (
                                        <BootstrapInput
                                            id="asst_yrbuilt"
                                            type="number"
                                            placeholder="Year Built"
                                            aria-label="Year Built"
                                            disabled={isSubmitting}
                                            size="sm"
                                        />
                                    ) : (
                                        <span>{asset.asst_yrbuilt ?? '-'}</span>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </Form>
                );
            }}
        </Formik>
    );
};

interface ToggleableInputProps extends FormControlProps {
    isEditing: boolean;
    isSubmitting: boolean;
    displayValue: number | null;
    label: string;
    value: number | '';
    touched: boolean | undefined;
    error: string | undefined;

    id2: string;
    value2: number | '';
    touched2: boolean | undefined;
    error2: string | undefined;
    onChange2: React.ChangeEventHandler<HTMLInputElement>;
}

const ToggleableInput = ({
    isEditing,
    isSubmitting,
    displayValue,
    label,
    id,
    placeholder,
    value,
    touched,
    error,
    onChange,
    id2,
    value2,
    touched2,
    error2,
    onChange2,
}: ToggleableInputProps) => {
    const [squareFoot, setSquare] = useState(false);
    return (
        <div className={styles.colItem}>
            <small className="text-muted d-block">{label}</small>
            {isEditing ? (
                <>
                    <InputGroup size="sm">
                        {squareFoot ? (
                            <Form.Control
                                id={id2}
                                type="number"
                                min="0"
                                placeholder={placeholder}
                                aria-label={placeholder}
                                disabled={isSubmitting}
                                isInvalid={touched2 && !!error2}
                                value={value2}
                                onChange={onChange2}
                            />
                        ) : (
                            <Form.Control
                                id={id}
                                type="number"
                                min="0"
                                placeholder={placeholder}
                                aria-label={placeholder}
                                disabled={isSubmitting}
                                isInvalid={touched && !!error}
                                value={value}
                                onChange={onChange}
                            />
                        )}

                        <InputGroup.Text
                            title="Change unit"
                            className="cursor-pointer"
                            onClick={() => setSquare((prev) => !prev)}
                        >
                            <span>
                                {squareFoot ? (
                                    <>
                                        ft<sup>2</sup>
                                    </>
                                ) : (
                                    <>
                                        m<sup>2</sup>
                                    </>
                                )}
                            </span>
                        </InputGroup.Text>
                    </InputGroup>

                    {squareFoot ? (
                        touched && error !== undefined ? (
                            <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
                        ) : (
                            <></>
                        )
                    ) : touched2 && error2 !== undefined ? (
                        <Form.Control.Feedback type="invalid">{error2}</Form.Control.Feedback>
                    ) : (
                        <></>
                    )}
                </>
            ) : (
                <span>
                    {displayValue ? (
                        <>
                            {Number(displayValue)} m<sup>2</sup>
                        </>
                    ) : (
                        '-'
                    )}
                </span>
            )}
        </div>
    );
};

function getSecondaryUnit(value: string): string {
    return value === '' ? '' : String(Math.round(convertSquareMetreToSquareFoot(Number(value))));
}

function getMainUnit(value: string): string {
    return value === '' ? '' : String(Math.round(convertSquareFootToSquareMetre(Number(value))));
}

export default AssetInfoDetails;
