import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Form, Formik, FormikHelpers } from 'formik';
import * as yup from 'yup';

import styles from '../asset.module.css';
import { Butlerr } from '../../../types/butlerr';
import { convertBooleanToBinary } from '../../utils/HelperFunctions';
import { initialiseAssetObject, useAssetMutations } from '../../../services/asset.service';
import { AssetInfoEditButton, AssetInfoProps } from './AssetInfo';
import { BootstrapCheckbox } from '../../utils/FormikBootstrapInputs';
import RouterPrompt from '../../utils/RouterPrompt';
import { SectionHeader } from '../../utils/SectionHeader';

import { ReactComponent as SwimmingPool } from '../../../assets/icons/feature-swimming-pool.svg';
import { ReactComponent as Tennis } from '../../../assets/icons/feature-tennis.svg';
import { ReactComponent as Backyard } from '../../../assets/icons/feature-backyard.svg';
import { ReactComponent as Frontyard } from '../../../assets/icons/feature-frontyard.svg';
import { ReactComponent as AirConditioner } from '../../../assets/icons/feature-air-conditioner.svg';
import { ReactComponent as Heater } from '../../../assets/icons/feature-heater.svg';
import { ReactComponent as Fireplace } from '../../../assets/icons/feature-fireplace.svg';
import { ReactComponent as SolarPanel } from '../../../assets/icons/feature-solar-panel.svg';

interface FormValues {
    asst_pool?: boolean | null;
    asst_tennis?: boolean | null;
    asst_backyard?: boolean | null;
    asst_frontyard?: boolean | null;
    asst_airconditioning?: boolean | null;
    asst_heating?: boolean | null;
    asst_fireplace?: boolean | null;
    asst_solarpower?: boolean | null;
}

const AssetInfoFeatures = ({ asset, isPartner = false }: AssetInfoProps) => {
    const { mutate: editAsset } = useAssetMutations('EDIT');

    // form state
    const [editing, setEditing] = useState(false);

    // Formik props
    const initialiseInitialValues = (asset: Butlerr.Asset) => {
        return {
            asst_pool: Boolean(asset.asst_pool),
            asst_tennis: Boolean(asset.asst_tennis),
            asst_backyard: Boolean(asset.asst_backyard),
            asst_frontyard: Boolean(asset.asst_frontyard),
            asst_airconditioning: Boolean(asset.asst_airconditioning),
            asst_heating: Boolean(asset.asst_heating),
            asst_fireplace: Boolean(asset.asst_fireplace),
            asst_solarpower: Boolean(asset.asst_solarpower),
        };
    };

    let initialValues: FormValues = initialiseInitialValues(asset);

    const validationSchema = yup.object({
        asst_pool: yup.boolean().nullable(),
        asst_tennis: yup.boolean().nullable(),
        asst_backyard: yup.boolean().nullable(),
        asst_frontyard: yup.boolean().nullable(),
        asst_airconditioning: yup.boolean().nullable(),
        asst_heating: yup.boolean().nullable(),
        asst_fireplace: yup.boolean().nullable(),
        asst_solarpower: yup.boolean().nullable(),
    });

    const closeForm = (actions: FormikHelpers<FormValues>) => {
        setEditing(false);
        actions.resetForm({ values: initialValues });
    };

    const onSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) => {
        // check if any field updated
        let fieldUpdated: boolean = false;
        Object.keys(initialValues).forEach((key) => {
            let value = values[key as keyof typeof values];
            if (initialValues[key as keyof typeof initialValues] !== value) {
                fieldUpdated = true;
            }
        });

        if (fieldUpdated) {
            // convert boolean to binary
            const binaryFields = [
                'asst_pool',
                'asst_tennis',
                'asst_backyard',
                'asst_frontyard',
                'asst_airconditioning',
                'asst_heating',
                'asst_fireplace',
                'asst_solarpower',
            ];
            let binaryConvertedValues: any = binaryFields.reduce((accumulator, currentValue) => {
                return {
                    ...accumulator,
                    ...{
                        [currentValue]: convertBooleanToBinary(
                            Boolean(values[currentValue as keyof typeof values])
                        ),
                    },
                };
            }, values);

            editAsset(
                {
                    ...initialiseAssetObject(asset),
                    ...binaryConvertedValues,
                },
                {
                    onSuccess: (_, updatedAsset) => {
                        initialValues = initialiseInitialValues(updatedAsset);
                        closeForm(actions);
                    },
                    onError: (error) => {
                        closeForm(actions);
                        alert(error.message);
                    },
                }
            );
        } else {
            closeForm(actions);
        }
    };

    type assetFeaturesInterface = [
        number | null | undefined,
        string,
        React.FunctionComponent<
            React.SVGProps<SVGSVGElement> & {
                title?: string | undefined;
            }
        >,
        string
    ];

    const assetFeatures: assetFeaturesInterface[] = [
        [asset.asst_pool, 'asst_pool', SwimmingPool, 'Pool'],
        [asset.asst_tennis, 'asst_tennis', Tennis, 'Tennis'],
        [asset.asst_backyard, 'asst_backyard', Backyard, 'Backyard'],
        [asset.asst_frontyard, 'asst_frontyard', Frontyard, 'Frontyard'],
        [asset.asst_airconditioning, 'asst_airconditioning', AirConditioner, 'Air Conditioning'],
        [asset.asst_heating, 'asst_heating', Heater, 'Heating'],
        [asset.asst_fireplace, 'asst_fireplace', Fireplace, 'Fireplace'],
        [asset.asst_solarpower, 'asst_solarpower', SolarPanel, 'Solar Power'],
    ];

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({ handleSubmit, isSubmitting, resetForm, dirty }) => {
                return (
                    <Form noValidate onSubmit={handleSubmit} className="mb-4">
                        <RouterPrompt isBlocking={dirty} />

                        <SectionHeader
                            title="Features"
                            rightSide={
                                isPartner === false && asset.status !== 'AR' ? (
                                    <AssetInfoEditButton
                                        editing={editing}
                                        isSubmitting={isSubmitting}
                                        setEditing={setEditing}
                                        resetForm={resetForm}
                                    />
                                ) : undefined
                            }
                        />
                        {assetFeatures.reduce((prev, curr) => {
                            if (curr[0]) {
                                prev.push(curr);
                            }
                            return prev;
                        }, [] as assetFeaturesInterface[]).length !== 0 || editing ? (
                            <Row xs={1} sm={2} lg={3} xl={4}>
                                {assetFeatures.map(([field, id, Icon, label], index) => (
                                    <Feature
                                        key={index}
                                        editing={editing}
                                        isSubmitting={isSubmitting}
                                        field={field}
                                        id={id}
                                        Icon={Icon}
                                        label={label}
                                    />
                                ))}
                            </Row>
                        ) : (
                            <div>No feature available.</div>
                        )}
                    </Form>
                );
            }}
        </Formik>
    );
};

interface FeatureInterface {
    editing: boolean;
    isSubmitting: boolean;
    field: number | null | undefined;
    id: string;
    Icon: React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & {
            title?: string | undefined;
        }
    >;
    label: string;
}

const Feature = ({ editing, isSubmitting, field, id, Icon, label }: FeatureInterface) => {
    return (
        <>
            {editing ? (
                <Col className={styles.featureColItem}>
                    <BootstrapCheckbox
                        id={id}
                        label={<FeatureLabel Icon={Icon} label={label} />}
                        disabled={isSubmitting}
                    />
                </Col>
            ) : (
                <>{field ? <FeatureLabel Icon={Icon} label={label} /> : undefined}</>
            )}
        </>
    );
};

interface FeaturelabelInterface {
    Icon: React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & {
            title?: string | undefined;
        }
    >;
    label: string;
}
const FeatureLabel = ({ Icon, label }: FeaturelabelInterface) => {
    return (
        <Col className={styles.featureColItem}>
            <div className={styles.assetIconWrapper}>
                <Icon className={styles.assetIcon} title={label} />
                <span>{label}</span>
            </div>
        </Col>
    );
};

export default AssetInfoFeatures;
