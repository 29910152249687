import { Formik } from 'formik';
import React, { useState } from 'react';
import { Col, Container, Dropdown, Form, Modal, Row } from 'react-bootstrap';
import { Transition } from 'react-transition-group';
import * as yup from 'yup';
import classNames from 'classnames';
import { Add, Done } from '@mui/icons-material';

import { useReminderMutations } from '../../../services/assetreminder.service';
import { Butlerr } from '../../../types/butlerr';
import { AssetRemindersTypeKey, AssetRemindersPriorityKey, ButlerrAssetRemindersPriority, ButlerrAssetRemindersType } from '../../../types/butlerr-enums';
import { BootstrapFormLabel, BootstrapInput, BootstrapSwitch } from '../../utils/FormikBootstrapInputs';
import { convertBinaryToBoolean, convertBooleanToBinary, convertDateToYYYYMMDD, formCloseHandler } from '../../utils/HelperFunctions';
import { ReminderPriorityIcon, ReminderTypeIcon } from "./AssetRemindersTable"
import DestructiveModal from '../../utils/DestructiveModal';
import BootstrapModalFooter from '../../utils/BootstrapModalFooter';

type ReminderForm = {
    remi_date: string;
    remi_msg: string;
    remi_type: AssetRemindersTypeKey | "";
    remi_priority: AssetRemindersPriorityKey | "";
    remi_completed: boolean;
};

const allowedKeys = {
    remi_type: Object.keys(ButlerrAssetRemindersType) as AssetRemindersTypeKey[],
    remi_priority: Object.keys(ButlerrAssetRemindersPriority) as AssetRemindersPriorityKey[],
};

const allowedLabels = {
    remi_type: Object.values(ButlerrAssetRemindersType),
    remi_priority: Object.values(ButlerrAssetRemindersPriority),
};

const allowedIcons = {
    remi_priority: allowedKeys.remi_priority.map((i, index) => <ReminderPriorityIcon key={i} reminder={{remi_priority: allowedKeys.remi_priority[index]}}/>),
    remi_type: allowedKeys.remi_type.map((i,index) => <ReminderTypeIcon key={i} reminder={{remi_type: allowedKeys.remi_type[index]}}/>)
}

interface AssetRemindersModalProps {
    show: boolean;
    onModalClose: () => void;
    initialValue?: Butlerr.Asset.Reminder;
    asset: Butlerr.Asset;
}

const AssetRemindersModal = ({
    show,
    onModalClose,
    initialValue,
    asset,
}: AssetRemindersModalProps) => {
    const assetId = asset.asst_id;

    const {
        mutate: createReminder,
        isLoading: isCreateLoading,
        error: createError,
    } = useReminderMutations('CREATE', assetId);
    const {
        mutate: editReminder,
        isLoading: isEditLoading,
        error: editError,
    } = useReminderMutations('EDIT', assetId);
    
    const { mutateAsync: deleteReminder } = useReminderMutations('DELETE', assetId);

    const isLoading = initialValue === undefined ? isCreateLoading : isEditLoading;
    const error = initialValue === undefined ? createError : editError;

    const handleClose = () => {
        if (error !== null) error.message = '';
        if (!isLoading) onModalClose();
    };

    const validationSchema = yup.object().shape({
        remi_date: yup.date().required('Required').min(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1), "Date selected cannot be in the past."),
        remi_msg: yup
            .string()
            .required('Required')
            .max(255, ({ max }) => `Maximum ${max} characters`),
        remi_type: yup
            .string()
            .oneOf(allowedKeys.remi_type),
        remi_priority: yup
            .string()
            .oneOf(allowedKeys.remi_priority),
        remi_completed: yup.boolean().required().default(false)
    });

    const onSubmit = (values: ReminderForm) => {
        let complete = convertBooleanToBinary(values.remi_completed)
        if (initialValue === undefined) {
            createReminder(
                { assetId, ...values, remi_completed: complete ?? 0 },
                { onSuccess: () => formCloseHandler(handleClose) }
            );
        } else {
            editReminder(
                { assetId, reminderId: initialValue.remi_id!, ...values, remi_completed: complete ?? 0 },
                {
                    onSuccess: () => formCloseHandler(handleClose),
                }
            );
        }
    };

    // delete modal
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedReminderId, setSelectedReminderId] = useState<number>();

    const onDelete = (reminderId: number) => {
        setSelectedReminderId(reminderId);
        setShowDeleteModal(true);
    };

    const onDeleteClose = () => {
        setShowDeleteModal(false);
    };

    const onDeleteConfirm = async () => {
        await deleteReminder({ assetId, reminderId: selectedReminderId ?? -1 });
        formCloseHandler(handleClose);
    };

    const initialValues: ReminderForm = {
        remi_date: convertDateToYYYYMMDD(initialValue?.remi_date) ?? '',
        remi_msg: initialValue?.remi_msg ?? '',
        remi_type: initialValue?.remi_type ?? '',
        remi_priority: initialValue?.remi_priority ?? '',
        remi_completed: convertBinaryToBoolean(initialValue?.remi_completed ?? 0) ?? false,
    };

    return (
        <>
            <Transition in={show} timeout={{ exit: 300 }} unmountOnExit>
                {() => (
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        {({ submitForm, dirty, values, setFieldValue }) => ( 
                            <Form noValidate>
                                <Modal
                                    show={show}
                                    onHide={formCloseHandler.bind(undefined, handleClose, dirty)}
                                    scrollable
                                    size="lg"
                                    backdrop={isLoading ? 'static' : undefined}
                                    keyboard={!isLoading}
                                    centered
                                >
                                    <Modal.Header>
                                        <Container>
                                            <Row>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <Modal.Title>
                                                        {initialValue === undefined ? 'Add ' : 'Edit '}
                                                        Reminder Details
                                                    </Modal.Title>
                                                </div>
                                            </Row>
                                        </Container>
                                    </Modal.Header>

                                    <Modal.Body>
                                        <Container>
                                            <Row className="mb-3">
                                                <Form.Group as={Col} lg>
                                                    <BootstrapInput
                                                        type="date"
                                                        id="remi_date"
                                                        label="Date"
                                                        placeholder="Date"
                                                        aria-label="Date"
                                                        disabled={asset.status === 'AR' || isLoading}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} lg>
                                                    <BootstrapFormLabel htmlFor="remi_type">
                                                        Type
                                                    </BootstrapFormLabel>

                                                    <Dropdown>
                                                        <Dropdown.Toggle bsPrefix=" " as={CustomToggle} className={classNames('w-100', {'disabled-with-bg': asset.status === 'AR' || isLoading})}>
                                                            {allowedIcons.remi_type[allowedKeys.remi_type.indexOf(values.remi_type as AssetRemindersTypeKey)]}
                                                            {values.remi_type ? ButlerrAssetRemindersType[values.remi_type as AssetRemindersTypeKey] : '-'}
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                            {
                                                                allowedKeys.remi_type.map((i, index) => 
                                                                    <Dropdown.Item onClick={() => 
                                                                        setFieldValue(
                                                                            'remi_type', 
                                                                            allowedKeys.remi_type[index]
                                                                        )} 
                                                                        key={i}
                                                                        value={allowedKeys.remi_type[index]}>
                                                                        {allowedIcons.remi_type[index]}
                                                                        {allowedLabels.remi_type[index]}
                                                                    </Dropdown.Item>)
                                                            }
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </Form.Group>
                                                <Form.Group as={Col} lg>
                                                    <BootstrapFormLabel htmlFor="remi_priority">
                                                        Priority
                                                    </BootstrapFormLabel>

                                                    <Dropdown>
                                                        <Dropdown.Toggle bsPrefix=" " as={CustomToggle} className={classNames('w-100', {'disabled-with-bg': asset.status === 'AR' || isLoading})}>
                                                            {allowedIcons.remi_priority[allowedKeys.remi_priority.indexOf(values.remi_priority as AssetRemindersPriorityKey)]}
                                                            {values.remi_priority ? ButlerrAssetRemindersPriority[values.remi_priority as AssetRemindersPriorityKey] : '-'}
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                            {
                                                                allowedKeys.remi_priority.map((i, index) => 
                                                                    <Dropdown.Item onClick={() => 
                                                                        setFieldValue(
                                                                            'remi_priority', 
                                                                            allowedKeys.remi_priority[index]
                                                                        )} 
                                                                        key={i}
                                                                        value={allowedKeys.remi_priority[index]}>
                                                                        {allowedIcons.remi_priority[index]}
                                                                        {allowedLabels.remi_priority[index]}
                                                                    </Dropdown.Item>)
                                                            }
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </Form.Group>
                                            </Row>

                                            <Row className="mb-3">
                                                <Form.Group as={Col} lg>
                                                    <BootstrapInput
                                                        as="textarea"
                                                        rows="4"
                                                        id="remi_msg"
                                                        label="Message"
                                                        placeholder="Message"
                                                        aria-label="Message"
                                                        disabled={asset.status === 'AR' || isLoading}
                                                    />
                                                </Form.Group>
                                            </Row>
                                            <Row>
                                            <Form.Group>
                                                {/* <Form.Label className='text-secondary text-uppercase'>{values.remi_completed ? ("Complete"):("Pending")}</Form.Label> */}
                                                <BootstrapSwitch
                                                    id="remi_completed"
                                                    className='text-uppercase text-secondary fw-bold m-0'
                                                    label={values.remi_completed ? ("Complete"):("Pending")}
                                                    style={{ fontSize: 12.96 }}
                                                />
                                    </Form.Group>
                                            </Row>
                                        </Container>
                                    </Modal.Body>

                                    <BootstrapModalFooter
                                        error={error}
                                        deleteBtnProps={{
                                            onClick: () => onDelete(initialValue?.remi_id!),
                                            disabled: asset.status === 'AR' || isLoading,
                                            className:
                                                initialValue === undefined ? 'd-none' : '',
                                        }}
                                        cancelBtnProps={{
                                            onClick: () => formCloseHandler(handleClose, dirty),
                                            disabled: isLoading,
                                        }}
                                        confirmBtnProps={{
                                            Icon: initialValue === undefined ? Add : Done,
                                            label:
                                                initialValue === undefined
                                                    ? isLoading
                                                        ? 'Adding...'
                                                        : 'Add'
                                                    : isLoading
                                                        ? 'Saving...'
                                                        : 'Save',
                                            onClick:
                                                initialValue === undefined
                                                    ? submitForm
                                                    : !dirty
                                                    ? handleClose
                                                    : submitForm,
                                            disabled: asset.status === 'AR' || isLoading,
                                        }}
                                    />

                                    
                                </Modal>
                            </Form>
                        )}
                    </Formik>
                )}
            </Transition>
            <DestructiveModal
                show={showDeleteModal}
                onClose={onDeleteClose}
                onConfirm={onDeleteConfirm}
                title="Delete reminder?"
                description="This action is irreversible!"
                className="modal-layer-1"
                backdropClassName="modal-layer-1"
            />
        </>
    );
};

interface CustomToggleInterface extends React.HTMLAttributes<HTMLDivElement> {
    onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

const CustomToggle = React.forwardRef<HTMLDivElement, CustomToggleInterface>(({ onClick, className, children }, ref) => (
    <div
      className={classNames("form-select", className && className)}
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </div>
));

export default AssetRemindersModal;
