import { useHistory, useParams } from "react-router"
import { useButlerrUser, UserDetails, useUserDetails, useUserMutations } from "../../services/user.service";
import { Modal, Form } from "react-bootstrap";
import { useEffect, useRef, useState, forwardRef } from "react";
import { formatDate, formatDateByTimeDifference } from "../utils/HelperFunctions";
import BootstrapSpinner from "../utils/BootstrapSpinner";
import IconButton from "../utils/IconButton";
import { EditOutlined, CloseRounded, Close, Done } from "@mui/icons-material";
import { Butlerr } from "../../types/butlerr";
import { ButlerrUserSelfDescribe, UserSelfDescribeKey } from "../../types/butlerr-enums";
import { Formik } from 'formik';
import { BootstrapInput, BootstrapSelect } from "../utils/FormikBootstrapInputs";
import * as yup from "yup";
import PostList from "./PostList";
import UserAvatar from "./UserAvatar";
import styles from "./social.module.css";
import useModal from "../../hooks/useModal";
import { CreateChatWSubjectModal } from "./chats/ChatsModal";
import { SocialRoutes } from "./SocialHome";
import { FollowListModal } from "./network/UserListModal";
import classNames from "classnames";

export default function SocialProfile() {

    const { id } = useParams<{id?: string}>()
    const { data: ownUser } = useButlerrUser()

    const isOwnUser = id === undefined || ownUser?.user_id === Number(id);

    const { data: userDetails } = useUserDetails(Number(id), !isOwnUser);

    const [ user, setUser ] = useState<UserDetails>();

    useEffect(() => {
        if (isOwnUser) {
            if (!ownUser) {
                return setUser(undefined);
            }
            setUser({
                user_id: ownUser.user_id,
                user_socialhandle: ownUser.user_socialhandle,
                user_socialoneliner: ownUser.user_socialoneliner,
                user_socialbio: ownUser.user_socialbio,
                user_socialselfdescribe: ownUser.user_socialselfdescribe,
                user_joined: ownUser.user_joined,
                user_lastaccess: ownUser.user_lastaccess,
                follower: ownUser.follower.length,
                following: ownUser.following.length,
                user_profile: ownUser.user_profile,
                user_email: ownUser.user_privacyemail === 1 ? ownUser.user_email : null,
                user_mobileno: ownUser.user_privacymobile === 1 ? ownUser.user_mobileno : null
            })
        }
        else {
            if (Number(id) === userDetails?.user_id) {
                return setUser(userDetails);
            }
            setUser(undefined);
        }
        
    }, [id, isOwnUser, ownUser, userDetails])

    return (
        <>
            <ProfileHeader
                isOwnUser={isOwnUser}
                user={user}
            />
            {(user === undefined) ? (
                <BootstrapSpinner />
            ) : (
                <PostList
                    user={user.user_id}
                />
            )}
        </>
    )
}

interface ProfileHeaderProps {
    isOwnUser: boolean;
    user: UserDetails | undefined;
}

export const ProfileHeader = forwardRef<HTMLDivElement, ProfileHeaderProps>(({ isOwnUser, user }, ref) => {

    const history = useHistory();

    const { data: ownUser } = useButlerrUser();
    const { id } = useParams<{id?: string}>()

    useEffect(() => {
        //reset when url param changes
        setShowEditModal(false);
    }, [id])

    const { mutate: follow, isLoading: followLoading } = useUserMutations('FOLLOW');
    const { mutate: unfollow, isLoading: unfollowLoading } = useUserMutations('UNFOLLOW');

    const isLoading = followLoading || unfollowLoading;

    const handleUserFollowUpdate = (userId: number) => {
        if (ownUser?.following.includes(userId)) {
            unfollow(userId)
        }
        else {
            follow(userId)
        }
    }

    //edit profile stuff
    const [ showEditModal, setShowEditModal ] = useState(false)

    const [ chatModal, startChat ] = useModal(CreateChatWSubjectModal, {
        user,
        onClose: (chatId?: number) => {
            if (chatId) history.push(SocialRoutes.CHATS + "/" + chatId);
        }
    })

    //follower & following modals
    const [ followerModal, openFollowers ] = useModal(FollowListModal, {
        initialSelectedView: "Follower" as const,
        user_id: user?.user_id,
        follower: user?.follower,
        following: user?.following
    })
    const [ followingModal, openFollowing ] = useModal(FollowListModal, {
        initialSelectedView: "Following" as const,
        user_id: user?.user_id,
        follower: user?.follower,
        following: user?.following
    })

    if (!user) {
        return (
            <div ref={ref} className="d-flex flex-row justify-content-center align-items-center p-4 mb-4 bg-light shadow rounded-4">
                <BootstrapSpinner />
            </div>
        )
    }

    const isFollowing = ownUser?.following.includes(user.user_id) === true;
    const isFollower = ownUser?.follower.includes(user.user_id) === true;

    return (
        <div ref={ref} className="d-flex flex-column align-items-center p-4 mb-4 bg-light shadow rounded-4">
            <div className="d-flex flex-row align-items-center align-self-stretch">
                <UserAvatar
                    user={user}
                    style={{ aspectRatio: '1 / 1', width: "min(20%, 100px)", height: 'auto' }}
                    className="me-4 flex-shrink-0"
                />
                <div className="d-flex flex-column flex-grow-1">
                    <div className="mb-1 d-flex flex-row align-items-center fw-bold w-100">
                        <h2 className="mb-0 text-break">{user.user_socialhandle}</h2>
                        {
                            isOwnUser ? (
                                <IconButton
                                    transparent
                                    Icon={EditOutlined}
                                    title="Edit"
                                    className="ms-4"
                                    onClick={() => setShowEditModal(true)}
                                />
                            ) : isFollower && (
                                <small className="text-muted ms-2">Follows You</small>
                            )
                        }
                        
                    </div>
                    <span className="text-muted">
                        {
                            user.user_lastaccess !== null && (
                                <small>Last seen: {formatDateByTimeDifference(user.user_lastaccess)} &bull; </small>
                            )
                        }
                        <small> Joined: {formatDate(user.user_joined)}</small>
                    </span>
                    <span>
                        <button
                            className="p-0 border-0 text-start bg-transparent link-underline"
                            onClick={openFollowers}
                        >
                            {user.follower} Follower{user.follower > 1 && 's'}
                        </button>
                        {' '}&bull;{' '}
                        <button
                            className="p-0 border-0 text-start bg-transparent link-underline"
                            onClick={openFollowing}
                        >
                            {user.following} Following
                        </button>
                        { followerModal } { followingModal }
                    </span>
                    {
                        (user.user_email !== null || user.user_mobileno !== null) && (
                            <div className="d-flex flex-wrap small mt-2">
                                {
                                    user.user_email !== null && (
                                        <span>
                                            <span className="fw-semibold">Email:</span> {user.user_email}
                                        </span>
                                    )
                                }
                                {
                                    user.user_mobileno !== null && (
                                        <span>
                                            { user.user_email !== null && <span>&nbsp;&bull; </span> }
                                            <span className="fw-semibold">Mobile:</span> {user.user_mobileno}
                                        </span>
                                    )
                                }
                            </div>
                        )
                    }
                </div>
                {
                    !isOwnUser && (
                        <div className="d-flex flex-column align-items-stretch flex-shrink-0">
                            <IconButton
                                className={classNames(
                                    'py-0 mb-1 border-2 border-primary rounded-pill',
                                    styles.followBtn,
                                    !isFollowing && `bg-transparent ${styles.grey}`,
                                )}
                                label={ isFollowing ? 'Following' : 'Follow' }
                                title={ isFollowing ? 'Following' : 'Follow' }
                                disabled={isLoading || !ownUser}
                                onClick={() => handleUserFollowUpdate(user.user_id)}
                            />
                            <IconButton
                                title="Message"
                                label="Message"
                                className={'py-0 border-2 border-primary rounded-pill ' + styles.followBtn}
                                onClick={startChat}
                            />
                            { chatModal }
                        </div>
                    )
                }
            </div>
            <div className="w-100 align-self-stretch d-flex flex-column text-break">
                {
                    (user.user_socialoneliner !== null || user.user_socialselfdescribe !== null) && (
                        <>
                            <p className="mt-2 mb-0 mx-n2">
                                {
                                    user.user_socialoneliner !== null && (
                                        <small>
                                            {user.user_socialoneliner}
                                            {/* Bullet separator */}
                                            { user.user_socialselfdescribe !== null && (
                                                <span> &bull; </span>
                                            )}
                                        </small>
                                    )
                                }
                                {
                                    user.user_socialselfdescribe !== null && (
                                        <small>{ButlerrUserSelfDescribe[user.user_socialselfdescribe]}</small>
                                    )
                                }
                            </p>
                            { user.user_socialbio !== null && <hr className="mt-2 mb-0" /> }
                        </>
                    )
                }
                {
                    user.user_socialbio !== null && (
                        <p className="mt-2 mb-0">{user.user_socialbio}</p>
                    )
                }
            </div>
            {
                isOwnUser && ownUser !== null && ownUser !== undefined && (
                    <SocialProfileEditModal
                        show={showEditModal}
                        onClose={() => setShowEditModal(false)}
                        user={ownUser}
                    />
                )
            }
        </div>
    )
})

interface SocialProfileEditModalProps {
    show: boolean;
    onClose: () => void;

    user: Butlerr.User;
}

function SocialProfileEditModal({ show, onClose, user } : SocialProfileEditModalProps) {

    const schema = yup.object().shape({
        user_socialhandle: yup.string().required("Please enter a display name").min(5, ({ min }) => `Minimum ${min} characters`).max(30, ({max}) => `Maximum ${max} characters`),
        user_socialoneliner: yup.string().max(60, "60 character limit"),
        user_socialselfdescribe: yup.string().oneOf(Object.keys(ButlerrUserSelfDescribe), "Invalid experience level"),
        user_socialbio: yup.string().max(255, "255 character limit")
    })

    const { mutate: editUser, isLoading: isLoadingDetails, error: errorDetails } = useUserMutations('EDIT_SOCIAL');

    type SocialProfileForm = Required<Pick<Butlerr.UserInfo, 'user_socialbio' | 'user_socialhandle' | 'user_socialoneliner'>> & {
        user_socialselfdescribe: UserSelfDescribeKey | '';
    }
    const handleEdit = async (values: SocialProfileForm) => {
        const editUserDetails = (imageUpdated: boolean) => {
            editUser(values, {
                onSuccess: () => {
                    if (imageUpdated) window.location.reload();
                    else onClose();
                }
            })
        }

        if (!image) {
            editUserDetails(false);
        }
        else {
            editPhoto(image.file, {
                onSuccess: () => editUserDetails(true)
            })
        }
    }

    //Image states
    const { mutate: editPhoto, isLoading: isLoadingPhoto, error: errorPhoto } = useUserMutations('EDIT_PHOTO');

    const [ isReadingPhoto, setIsReadingPhoto ] = useState(false);
    const [ imageReadError, setImageReadError ] = useState<string>();

    const [image, setImage] = useState<{ file: File, url: string }>();
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setImage(undefined)
    }, [show])

    const handleImageSelect = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const file = ev.target.files?.[0];

        if (!file) {
            return setImageReadError("Couldn't read image");
        }
        if (!file.type.startsWith('image')) {
            return setImageReadError("Invalid image file");
        }

        setIsReadingPhoto(true);

        const reader = new FileReader();
        reader.onload = (e) => {
            setIsReadingPhoto(false);

            if (e.target?.result) {
                setImage({
                    file,
                    url: e.target.result as string
                })
            }
        }
        reader.onerror = (err) => {
            console.error(err);
            setIsReadingPhoto(false);
        }
        reader.readAsDataURL(file);
        /**
         * Return false in event handler, don't change the value of underlying input
         */
        return false;
    }

    const isLoading = isLoadingPhoto || isLoadingDetails || isReadingPhoto;

    return (
        <Modal
            show={show}
            centered
            onHide={onClose}
            backdrop={isLoading ? 'static' : undefined}
            keyboard={!isLoading}
        >
            <Modal.Header className="p-3">
                <Modal.Title className="fw-normal">
                    Edit community Profile
                </Modal.Title>
                <IconButton
                    Icon={CloseRounded}
                    title="Close"
                    className="ms-auto"
                    disabled={isLoading}
                    onClick={onClose}
                />
                <input
                    hidden
                    ref={inputRef}
                    type="file"
                    accept="image/*"
                    onChange={handleImageSelect}
                ></input>
            </Modal.Header>
            <Formik
                initialValues={{
                    user_socialhandle: user.user_socialhandle,
                    user_socialoneliner: user.user_socialoneliner ?? '',
                    user_socialselfdescribe: user.user_socialselfdescribe ?? '',
                    user_socialbio: user.user_socialbio ?? ''
                }}
                onSubmit={handleEdit}
                validationSchema={schema}
            >
                {({ handleSubmit }) => (
                    <Form onSubmit={handleSubmit}>
                        <Modal.Body className="small px-4 py-3">
                            <div className="mb-3 d-flex align-items-center">
                                <UserAvatar
                                    width={100}
                                    height={100}
                                    user={user}
                                    className="me-4"
                                    style={{ objectFit: "cover" }}
                                    src={image?.url}
                                />
                                <IconButton
                                    label="Upload photo"
                                    onClick={() => inputRef.current?.click()}
                                />
                            </div>
                            <div className="mb-2">
                                <BootstrapInput
                                    id="user_socialhandle"
                                    placeholder="Display Name"
                                    label="Display Name"
                                    required={false}
                                />
                            </div>
                            <div className="mb-2">
                                <BootstrapInput
                                    id="user_socialoneliner"
                                    placeholder="Tagline"
                                    label="Tagline"
                                    required={false}
                                />
                            </div>
                            <div className="mb-2">
                                <BootstrapSelect
                                    id="user_socialselfdescribe"
                                    placeholder="Title"
                                    placeholderOption="-- Select your title --"
                                    allowedKeys={Object.keys(ButlerrUserSelfDescribe)}
                                    allowedLabels={Object.values(ButlerrUserSelfDescribe)}
                                    label="Title"
                                    required={false}
                                />
                            </div>
                            <div className="mb-2">
                                <BootstrapInput
                                    id="user_socialbio"
                                    placeholder="Input your bio"
                                    label="Bio"
                                    required={false}
                                    as="textarea"
                                    style={{ minWidth: '50px' }}
                                />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            {
                                (imageReadError || errorPhoto || errorDetails) && (
                                    <p className="w-100 text-danger">
                                        {
                                            imageReadError !== undefined ? imageReadError : (
                                                `${errorPhoto !== null ? 'There was an error updating your photo: ' :
                                                    errorDetails !== null ? 'We updated your photo but there was an error updating your details: ' : false
                                                }
                                                ${errorPhoto?.message || errorDetails?.message}`
                                            )
                                        }
                                    </p>
                                )
                            }
                            <IconButton
                                Icon={Close}
                                iconHtmlColor="var(--primary)"
                                disabled={isLoading}
                                label="Cancel"
                                transparent
                                className="text-primary border-primary px-4 me-3"
                                onClick={() => onClose()}
                            />
                            <IconButton
                                Icon={Done}
                                disabled={isLoading}
                                type="submit"
                                label="Update"
                                className="px-4"
                            />
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}